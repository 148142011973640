import {
  getRelativeFontSize,
  boldFont,
  mediumFont,
  regularFont,
  pureWhiteColor,
  primaryBlackColor,
} from "utils/styles";
import whatsAppChatBackground from "assets/images/whatsAppChatBackground.svg";

export const WhatsAppMessagePreviewStyles = {
  mainPreviewBox: {
    border: "2px solid #cfcfcf",
    borderRadius: "20px",
    width: "100%",
    maxWidth: "350px",
    maxHeight: "570px",
  },
  previewHeaderStack: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 0",
    alignItems: "center",
    background: "#f5f5f5",
    borderRadius: "20px 20px 0 0",
  },
  previewHeaderText: {
    ...boldFont,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  previewBodyText: {
    margin: "10px",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  previewFooterText: {
    ...mediumFont,
    color: "#00000666",
    fontSize: "13px",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  leftArrowIcon: { fontSize: getRelativeFontSize(25), color: "#0092ff" },
  whatsAppAccountNameContainerStyles: {
    display: "flex",
    alignItems: "center",
  },
  whatsAppAccountNameStyles: {
    ...boldFont,
    fontSize: getRelativeFontSize(4),
  },
  verifiedIconStyles: {
    color: "#25d366",
    fontSize: "18px",
  },
  previewHeaderLeftIcons: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  videoCallIcon: {
    fontSize: getRelativeFontSize(18),
    color: "#0092ff",
    margin: "0 5px",
  },
  callIcon: {
    fontSize: getRelativeFontSize(10),
    color: "#0092ff",
    margin: "5px 10px",
  },
  previewBodyContainer: {
    backgroundImage: `url(${whatsAppChatBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "430px",
    height: "430px",
    overflowY: "auto",
  },
  currentDateContainer: {
    background: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  currentDateBox: {
    background: "#cfcfcf",
    borderRadius: "5px",
    padding: "3px 10px",
    margin: "8px 0",
  },
  currentDateText: { fontSize: "12px", ...regularFont },
  speechBubbleContainer: {
    marginTop: "10px",
    background: "transparent",
    display: "flex",
    position: "relative",
    alignItems: "center",
    paddingLeft: "20px",
  },
  speechBubbleCard: {
    backgroundColor: pureWhiteColor,
    minHeight: "50px",
    width: "80%",
    borderRadius: "0 6px 6px 6px",
    marginBottom: "20px",
  },
  speechBubbleArrowBackground: {
    backgroundColor: "transparent",
    position: "absolute",
    height: "1px",
    width: "1px",
    borderRadius: "5px 0 0 0",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid rgba(0,0,0,0.1)",
    borderTop: "10px solid rgba(0,0,0,0.1)",
    borderBottom: "10px solid transparent",
    top: "-1px",
    left: "-1px",
  },
  mainSpeechBubbleArrow: {
    position: "absolute",
    height: "0px",
    width: "0px",
    borderRadius: "5px 0 0 0",
    borderLeft: "10px solid transparent !important",
    borderRight: `10px solid ${pureWhiteColor}`,
    borderTop: `10px solid ${pureWhiteColor}`,
    borderBottom: "9px solid transparent !important",
    top: 0,
    left: "1px",
  },
  previewFooterContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 10px ",
    alignItems: "center",
    background: "#f5f5f5",
  },
  addIcon: { fontSize: getRelativeFontSize(20), color: "#0092ff" },
  previewFooterTextInputBox: {
    width: "60%",
    backgroundColor: pureWhiteColor,
    border: "1px solid #e7e7e8",
    borderRadius: "50px",
    padding: "5px 10px",
    display: "flex",
    justifyContent: "flex-end",
  },
  FileOpenIcon: { fontSize: getRelativeFontSize(10), color: "#0092ff" },
  cameraIcon: {
    fontSize: getRelativeFontSize(13),
    color: "#0092ff",
    margin: "0 10px",
  },
  micIcon: {
    fontSize: getRelativeFontSize(13),
    color: "#0092ff",
    marginRight: "10px",
  },
  previewSpeakerContainer: {
    display: "flex",
    justifyContent: "center",
    background: "#f5f5f5",
    paddingBottom: "13px",
    borderRadius: "0 0 20px 20px",
  },
  previewSpeaker: {
    backgroundColor: primaryBlackColor,
    width: "40%",
    height: "6px",
    borderRadius: "30px",
  },
  footerTimeStampContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: " 10px",
    paddingBottom: "5px",
  },
  footerTimeStampText: { ...mediumFont, color: "#00000666", fontSize: "13px" },
} as const;

export default WhatsAppMessagePreviewStyles;
