import { Box } from "@mui/material";
import React from "react";
import {
  CartesianGrid,
  Legend,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from "recharts";

const PerformanceReportChart = (props: any) => {
  return (
    <>
      <Box>
        <ResponsiveContainer aspect={0} minHeight={"300px"}>
          <LineChart width={500} height={300} data={props?.performanceData}>
            <CartesianGrid strokeWidth={1} />
            <XAxis dataKey={"Time"} tick={{ fontSize: 14 }} />
            <YAxis
              tick={{ fontSize: 14 }}
              tickFormatter={(tick) => (Number.isInteger(tick) ? tick : "")}
            />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Opened" stroke="#13B4CA" />
            <Line type="monotone" dataKey="Clicked" stroke="#462682" />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default PerformanceReportChart;
