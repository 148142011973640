import { useState, useEffect } from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import CampaignDetailsSectionsStyles from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/StepTwo/CampaignDetailsSections/CampaignDetailsSection.styles";
import { isTruthy } from "helpers/methods";
import { CustomButton, CustomContactNumberInput } from "global/components";
import { PhoneNumberUtil } from "google-libphonenumber";
interface CustomProps {
  campaignData: any;
  setCampaignData: Function;
}

const ToSection = (props: CustomProps) => {
  const classes = CampaignDetailsSectionsStyles;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [toPhoneNumberArray, setToPhoneNumberArray] = useState<string[]>([
    ...props.campaignData.to.value,
  ]);
  const [toPhoneNumber, setToPhoneNumber] = useState<any>({
    value: "",
    error: "",
  });

  useEffect(() => {
    if (props.campaignData.to.error) {
      setIsEditMode(true);
      setToPhoneNumber({
        ...toPhoneNumber,
        error: props.campaignData.to.error,
      });
    }
  }, [props.campaignData.to.error]);

  const onChangeHandler = (phone: string) => {
    setToPhoneNumber({
      value: phone,
      error: props.campaignData.to.error ? props.campaignData.to.error : "",
    });
    props.setCampaignData({
      ...props.campaignData,
      to: {
        ...props.campaignData.to,
        error: "",
      },
    });
  };

  const addNumberButtonHandler = () => {
    let tempPhoneArray: string[] = [...toPhoneNumberArray];
    if (isToPhoneNumberValid(tempPhoneArray)) {
      tempPhoneArray.push(toPhoneNumber.value);
      setToPhoneNumberArray(tempPhoneArray);
      setToPhoneNumber({ value: "", error: "" });
    }
  };

  const deletePhoneNumberHandler = (index: number) => {
    const tempPhoneArray: string[] = [...toPhoneNumberArray];
    const resultArray: string[] = tempPhoneArray.filter(
      (item: string, itemIndex: number) => itemIndex !== index
    );
    setToPhoneNumberArray([...resultArray]);
  };

  const isPhoneValid = (phone: string) => {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error: any) {
      return false;
    }
  };

  const isPhoneNumberArrayValid = () => {
    let isValid: boolean = true;
    if (!isTruthy(toPhoneNumberArray)) {
      setToPhoneNumber({
        ...toPhoneNumber,
        error: "Please at-least add one phone number.",
      });
      isValid = false;
    }
    return isValid;
  };

  const isToPhoneNumberValid = (tempArray: string[]) => {
    let isValid = true;

    if (!isPhoneValid(toPhoneNumber.value)) {
      setToPhoneNumber({
        ...toPhoneNumber,
        error: "Please enter a valid phone number.",
      });
      isValid = false;
    }
    if (tempArray.includes(toPhoneNumber.value)) {
      setToPhoneNumber({
        ...toPhoneNumber,
        error: "Duplicate numbers cannot be added.",
      });
      isValid = false;
    }

    return isValid;
  };

  const onSaveHandler = () => {
    if (isPhoneNumberArrayValid()) {
      props.setCampaignData({
        ...props.campaignData,
        to: {
          value: toPhoneNumberArray,
          error: "",
        },
      });
      setIsEditMode(false);
    }
  };

  const onCancelHandler = () => {
    setToPhoneNumber({ value: "", error: "" });
    setToPhoneNumberArray([...props.campaignData.to.value]);
    props.setCampaignData({
      ...props.campaignData,
      to: { ...props.campaignData.to, error: "" },
    });
    setIsEditMode(false);
  };

  const getEditModeHeading = () => {
    return (
      <>
        <Typography sx={classes.headerLabel}>To</Typography>
        <Typography sx={classes.headerSubDescription}>
          Who will receive this WhatsApp message?
        </Typography>
      </>
    );
  };

  const getViewModeHeading = () => {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Typography sx={classes.headerLabel}>To</Typography>
          <Typography ml={0.4} sx={classes.star}>
            *
          </Typography>
        </Box>
        {isTruthy(props.campaignData.to.value) ? (
          props.campaignData.to.value.map((value: string, index: number) => (
            <Chip
              key={index}
              label={value}
              sx={classes.addTagChip}
              variant="filled"
            />
          ))
        ) : (
          <Typography sx={classes.headerSubDescription}>
            The number that will receive message.
          </Typography>
        )}
      </>
    );
  };

  const getEditToButton = () => {
    return (
      <CustomButton
        id="whatsapp_campaign_to_edit_button"
        label={
          isTruthy(props.campaignData.to.value)
            ? "Edit Numbers"
            : "Enter Numbers"
        }
        onClick={() => setIsEditMode(true)}
        customClasses={classes.buttonStyles}
      />
    );
  };

  const getEditModeInput = () => {
    return (
      <CustomContactNumberInput
        required
        label="WhatsApp Number"
        onChange={onChangeHandler}
        value={toPhoneNumber.value}
        labelStyles={classes.customContactInputLabel}
        id="whatsapp_to_contact_number_input"
        placeHolder="Enter the number you want to send message to"
        error={toPhoneNumber.error}
      />
    );
  };

  const getAddButtonAndNumberChips = () => {
    return (
      <Stack sx={classes.toPhoneNumberStack}>
        <Box sx={classes.toPhoneNumberAddButtonContainer}>
          <CustomButton
            id="whatsapp_to_add_number_button"
            label="Add"
            onClick={addNumberButtonHandler}
            customClasses={classes.buttonStyles}
          />
        </Box>
        <Box>
          {toPhoneNumberArray.map((value: string, index: number) => (
            <Chip
              key={index}
              label={value}
              sx={classes.addTagChip}
              variant="filled"
              onDelete={() => deletePhoneNumberHandler(index)}
            />
          ))}
        </Box>
      </Stack>
    );
  };

  const getSaveButton = () => {
    return (
      <CustomButton
        id="whatsapp_to_save_button"
        label="Save"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onSaveHandler();
        }}
      />
    );
  };

  const getCancelButton = () => {
    return (
      <CustomButton
        id="whatsapp_from_cancel_button"
        label="Cancel"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onCancelHandler();
        }}
      />
    );
  };

  const getEditModeView = () => {
    return (
      <>
        <Box mb={1}>{getEditModeHeading()}</Box>
        <Box sx={classes.inputFieldContainer}>
          {getEditModeInput()}
          {getAddButtonAndNumberChips()}
        </Box>
        <Stack flexDirection="row" gap={1} mt={1}>
          {getSaveButton()}
          {getCancelButton()}
        </Stack>
      </>
    );
  };

  const getViewModeView = () => {
    return (
      <Box mb={1} sx={classes.viewModeBox}>
        <Box>{getViewModeHeading()}</Box>
        <Box>{getEditToButton()}</Box>
      </Box>
    );
  };

  return <Box>{isEditMode ? getEditModeView() : getViewModeView()}</Box>;
};

export default ToSection;
