import urls from "global/constants/UrlConstants";
import { getCallParams, makeCall } from "utils/service";

export const getSalesforceAuthenticationUrl = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.getSalesforceIntegration,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const authUserToSalesforce = async (code: string) => {
  try {
    const url = `${urls.salesforceUserAuthenticated}/${code}`;
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSalesforceIntegrationStatus = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.checkSalesforceIntegration,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const disconnectSalesforce = async (appName: string) => {
  try {
    const url: string = `${urls.disconnectSalesforce}/${appName}`;
    const callParams = await getCallParams("DELETE");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
