import strings from "global/constants/StringConstants";
import urls from "global/constants/UrlConstants";
import {
  getCallParams,
  getFileCallParams,
  getSearchedCallParams,
  getSortingSearchedCallParams,
  makeCall,
  makeCallFile,
} from "utils/service";

export const getContactGroupTypes = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.contactGroupTypes, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const uploadContactGroups = async (
  file: any,
  groupType: string,
  isAsyncUpload: boolean
) => {
  try {
    const regularUploadUrl = `${urls.uploadContactGroup}/type/${groupType}`;
    const asyncUploadUrl = `${urls.uploadContactGroup}/type/${groupType}/async`;
    const callParams = await getFileCallParams(file);
    const response = await makeCall(
      isAsyncUpload ? asyncUploadUrl : regularUploadUrl,
      callParams,
      strings.CUSTOM_API_TIMEOUT_LIMIT
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignGroups = async (
  pageNumber: number,
  dropdownValue: string,
  groupType: string,
  limit: number,
  sortingParams: any
) => {
  try {
    const url = `${urls.contactGroups}/${dropdownValue}/groupType/${groupType}/${pageNumber}/false/limit/${limit}`;
    const callParams = await getCallParams("POST", sortingParams);
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignGroupsCount = async (dropdownValue: string , groupType: string) => {
  try {
    const url = `${urls.contactGroups}/${dropdownValue}/groupType/${groupType}/count/false`;
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getContactsByGroupIdCount = async (groupId: string) => {
  try {
    const callParams = await getCallParams("GET");
    const url = `${urls.campaignsRecipientsByGroupIdCount}/${groupId}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
export const getContactsByGroupId = async (
  groupId: any,
  pageNumber: number,
  limit: number,
  sortingParams: any
) => {
  try {
    const callParams = await getCallParams("POST", sortingParams);
    const url = `${urls.campaignsRecipientsByGroupId}/${groupId}/page/${pageNumber}/limit/${limit}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSearchedContactsByGroupIdCount = async (
  groupId: any,
  searchText: string
) => {
  try {
    const callParams = await getSearchedCallParams("POST", searchText);
    const url = `${urls.campaignsRecipientsSearchByGroupIdCount}/${groupId}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSearchedContactsByGroupId = async (
  searchText: string,
  groupId: any,
  pageNumber: number,
  limit: number,
  entitySorters: any
) => {
  try {
    const body = { entitySorters, searchText };
    const callParams = await getSortingSearchedCallParams("POST", body);
    const url = `${urls.campaignsRecipientsSearchByGroupId}/${groupId}/page/${pageNumber}/limit/${limit}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSearchedCampaignGroupsCount = async (
  searchText: string,
  dropdownValue: any,
  groupType: string
) => {
  try {
    const callParams = await getSearchedCallParams("POST", searchText);
    const url = `${urls.contactGroups}/${dropdownValue}/groupType/${groupType}/count/search/false`;
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSearchedCampaignGroups = async (
  searchText: string,
  dropdownValue: any,
  groupType: string,
  pageNumber: any,
  limit: number,
  entitySorters: any
) => {
  try {
    const body = { entitySorters, searchText };
    const callParams = await getSortingSearchedCallParams("POST", body);
    const url = `${urls.contactGroups}/${dropdownValue}/groupType/${groupType}/search/page/${pageNumber}/false/limit/${limit}`;
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const deleteGroupRequest = async (requestId: any) => {
  try {
    const callParams = await getCallParams("DELETE");
    const url = `${urls.deleteCampaignGroup}/${requestId}`;
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSubordinatesList = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.getSubordinates, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const downloadContactList = async (requestId: any) => {
  try {
    const callParams = await getCallParams("GET");
    const url = `${urls.downloadCampaignGroups}/download/${requestId}`;
    const response = await makeCallFile(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateContactListDocument = async (file: any, GroupId: any) => {
  try {
    const url = `${urls.updateContactGroup}/${GroupId}`;
    const callParams = await getFileCallParams(file);
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getFileColumnType = async (file: any) => {
  try {
    const callParams = await getFileCallParams(file);
    const response = await makeCall(
      urls.FETCH_COLUMN_TYPE,
      callParams,
      strings.CUSTOM_API_TIMEOUT_LIMIT
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const uploadContactGroupsCsvParsing = async (
  groupType: string,
  file: any
) => {
  try {
    const url = `${urls.COLUMN_TYPE_CSV_ADD}/group/type/${groupType}/by/csv`;
    const callParams = await getFileCallParams(file);
    const response = await makeCall(
      url,
      callParams,
      strings.CUSTOM_API_TIMEOUT_LIMIT
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
