import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import CustomSimpleTable from "global/components/CustomSimpleTable/CustomSimpleTable";
import DomainListStyles from "screens/Settings/Domains/DomainsList/DomainList.styles";
import { domainsListTableHeader } from "screens/Settings/Domains/DomainsHelpers";
import viewIcon from "assets/images/view.svg";

interface CustomProps {
  domains: any[];
  domainViewButtonHandler: Function;
  isLoading: boolean;
}

const DomainList = (props: CustomProps) => {
  const classes = DomainListStyles;
  const [formattedDomainsRows, setFormattedDomainsRows] = useState<any[]>([]);

  useEffect(() => {
    getFormattedTableRow(props.domains);
  }, [props.domains]);

  const getFormattedTableRow = (domains: any[]) => {
    const formattedRows = domains.map((domainItem: any) => {
      return {
        name: (
          <Box>
            <Typography
              sx={classes.domainNameTextStyles}
              onClick={() => {
                props.domainViewButtonHandler(domainItem.name);
              }}
            >
              {domainItem.name}
            </Typography>
          </Box>
        ),
        action: (
          <Box sx={classes.domainViewButtonContainer}>
            <Button
              sx={classes.ActionBtn}
              onClick={() => {
                props.domainViewButtonHandler(domainItem.name);
              }}
              id="domains_view_button"
            >
              <Box component={"img"} src={viewIcon} /> <span>View</span>
            </Button>
          </Box>
        ),
      };
    });
    setFormattedDomainsRows(formattedRows);
  };
  const getDomainsList = () => {
    return (
      <Box sx={classes.domainsListContainer}>
        <CustomSimpleTable
          tableHeader={domainsListTableHeader}
          tableRows={formattedDomainsRows}
          emptyTableMessage={"Sorry, we don't have any domains to show."}
          isLoading={props.isLoading}
        />
      </Box>
    );
  };

  return getDomainsList();
};

export default DomainList;
