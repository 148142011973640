import {
  regularFont,
  purpleThemedSelectComponent,
  pureWhiteColor,
  whiteThemedMenuItems,
} from "utils/styles";

const CustomCheckboxDropDownStyles = {
  dropdown: {
    ...regularFont,
    ...purpleThemedSelectComponent,
    height: "47px",
    width: "180px",
    backgroundColor: pureWhiteColor,
    borderRadius: "8px",
    color: "#22222C",
    border: 0,
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.03)",
    "&:hover": {
      border: 0,
    },
  },
  dropdownOptions: {
    ...whiteThemedMenuItems,
    ...regularFont,
    color: "#222222",
    width: "180px",
    padding: "2px",
  },
  downloadBtn: {
    height: "40px",
    color: "#000000",
    border: "1px solid #E7E7E7",
    background: "#F1F1F1",
    "&:hover": {
      color: "#000000",
      background: "#FFFFFF",
    },
  },
};

export default CustomCheckboxDropDownStyles;
