import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import fbInstaIcon from "assets/icons/fb_insta.svg";
import { CustomButton } from "global/components";
import IntegrationStyles from "screens/Integration/Integration.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import strings from "global/constants/StringConstants";
import {
  deleteUnexecutedCampaigns,
  getSocialCampaignsCount,
  disconnectFacebook,
} from "screens/Integration/component/FacebookService";
import {
  getFacebookRedirectionURL,
  isTruthy,
  openErrorNotification,
  openInfoNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import history from "utils/history";
import urls from "global/constants/UrlConstants";
import { hasAccessTo } from "utils/AuthorizationManager";
import { regularFont } from "utils/styles";
import { useDispatch } from "react-redux";
import {
  changeFacebookPageAction,
  changeIntegrationAppNameAction,
} from "redux/persistSlice";
import UnexecutedCampaignsAlertModal from "screens/Integration/component/UnexecutedCampaignsAlertModal";
import CustomLoader from "global/components/CustomLoader/CustomLoader";

interface FacebookProps {
  previousStepHandler: Function;
  isFacebookIntegrated?: boolean;
  isLoggedInSocialMedia?: Function;
  setIsFacebookIntegrated?: Function | undefined;
  unexecutedCampaigns: number;
  setUnexecutedCampaigns: Function;
}

const Facebook = (props: FacebookProps) => {
  const classes = IntegrationStyles;
  const dispatch = useDispatch();
  const [showDialogModal, setShowDialogModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    props.setUnexecutedCampaigns(0);
  }, []);

  const facebook = () => {
    window.location.href = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${
      strings.FACEBOOK_APP_ID
    }&redirect_uri=${getFacebookRedirectionURL(
      window.location.hostname
    )}&scope=pages_show_list%2Cpages_read_engagement%2Cpages_read_user_content%2Cpages_manage_posts%2Cinstagram_basic%2Cinstagram_content_publish&state=123456789`;
  };

  const hasSocialAddAccess = hasAccessTo(strings.social, strings.addPermission);

  const hasSocialDeleteAccess = hasAccessTo(
    strings.social,
    strings.deletePermission
  );

  const onConnectHandler = () => {
    // openInfoNotification("Currently, we are in under maintenance!");
    dispatch(changeIntegrationAppNameAction(strings.FACEBOOK));
    facebook();
  };

  const logoutSocialMediaApiHandler = async () => {
    try {
      setIsLoading(true);
      if (props.unexecutedCampaigns > 0) {
        await deleteUnexecutedCampaigns();
      }
      dispatch(
        changeFacebookPageAction({
          facebookPageName: "",
          facebookPageId: undefined,
        })
      );
      props.setUnexecutedCampaigns(0);
      const response = await disconnectFacebook(strings.FACEBOOK);
      openSuccessNotification(response?.message);
      props.isLoggedInSocialMedia && props.isLoggedInSocialMedia();
      history.push(urls.integrationViewPath);
      props.setIsFacebookIntegrated?.(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error?.message) ? error?.message : notifiers?.GENERIC_ERROR
      );
    } finally {
      setShowDialogModal(false);
      setIsLoading(false);
    }
  };

  const onDisconnectHandler = async () => {
    try {
      setIsLoading(true);
      const unExecutedCampaigns = await getSocialCampaignsCount();
      if (isTruthy(unExecutedCampaigns) && unExecutedCampaigns > 0) {
        props.setUnexecutedCampaigns(unExecutedCampaigns);
        return setShowDialogModal(true);
      }
      await logoutSocialMediaApiHandler();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error?.errorMessage)
          ? error?.errorMessage
          : notifiers?.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getHeading = () => {
    return (
      <Box sx={classes.integrationsWrapper}>
        <IconButton
          id="facebook_back_button"
          onClick={() => {
            props.previousStepHandler();
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={classes.integrationsTitle}>Back to Channels</Typography>
      </Box>
    );
  };

  const getFeatures = () => {
    return (
      <>
        <Typography fontWeight="bold" fontSize={23} mt={6}>
          Before you start
        </Typography>
        <Box ml={7}>
          <List sx={classes.listStyle}>
            <ListItem sx={{ ...classes.listItemStyle, width: "70%" }}>
              <span style={{ fontWeight: "bold" }}>Facebook:</span> To access
              the features of this integration, you’ll need an active Facebook
              account that is an admin of at least one Facebook Page. This
              integration doesn’t post to your personal Facebook profile.
            </ListItem>

            <ListItem sx={{ ...classes.listItemStyle, width: "70%" }}>
              <span style={{ fontWeight: "bold" }}>Instagram:</span> You’ll need
              an Instagram business account, as well as an active Facebook
              account that manages at least one Facebook Page.
            </ListItem>
          </List>
        </Box>
        <Typography fontWeight="bold" fontSize={23} mt={1}>
          Features
        </Typography>
        <Box ml={7}>
          <List sx={classes.listStyle}>
            <ListItem sx={classes.listItemStyle}>
              <span style={{ fontWeight: "bold" }}>
                Streamline your social media management:
              </span>{" "}
              No more logging in and out of multiple platforms. Keep track of
              all your Facebook interactions in one place.
            </ListItem>

            <ListItem sx={classes.listItemStyle}>
              <span style={{ fontWeight: "bold" }}>
                Create ad campaigns directly:
              </span>{" "}
              Say goodbye to manual data transfer. Create and manage ad
              campaigns directly within Mailzzy.
            </ListItem>
            <ListItem sx={classes.listItemStyle}>
              <span style={{ fontWeight: "bold" }}>Real-time tracking:</span>{" "}
              Stay on top of customer interactions and respond quickly,
              improving communication and customer satisfaction.
            </ListItem>
          </List>
        </Box>
      </>
    );
  };

  const getBody = () => {
    return (
      <Box sx={classes.connectWrapper}>
        <Box
          component="img"
          src={fbInstaIcon}
          height={80}
          width={80}
          ml={1}
        />
        <Box sx={classes.connectContent}>
          <Typography sx={classes.socialTitle}>Facebook / Instagram</Typography>
          <Typography sx={classes.connectPara}>
            Unlock the full potential of your Facebook presence with Mailzzy! 🚀
          </Typography>
          <Typography sx={classes.connectPara}>
            Connect your Facebook account and start experiencing the benefits of
            Mailzzy's features.
          </Typography>
          {!hasSocialDeleteAccess && (
            <Typography
              variant="body1"
              sx={{
                color: "#ff4244",
                ...regularFont,
              }}
            >
              * You don't have permission to disconnect channel. Please contact
              your account administrator.
            </Typography>
          )}

          {!hasSocialAddAccess && (
            <Typography
              variant="body1"
              sx={{
                color: "#ff4244",
                ...regularFont,
              }}
            >
              * You don't have permission to connect channel. Please contact
              your account administrator.
            </Typography>
          )}

          <Box mt={2} width={{ sm: "100%", lg: "20%" }}>
            {props.isFacebookIntegrated
              ? hasSocialDeleteAccess && (
                  <CustomButton
                    label="Disconnect"
                    onClick={() => onDisconnectHandler()}
                    id="facebook_disconnect_button"
                  />
                )
              : hasSocialAddAccess && (
                  <CustomButton
                    label="Connect"
                    onClick={() => onConnectHandler()}
                    id="facebook_connect_button"
                  />
                )}
          </Box>
        </Box>
      </Box>
    );
  };

  const getMainBodyContent = () => {
    return (
      <>
        <Grid>
          <Grid item>{getHeading()}</Grid>
          <Grid item mt={1}>
            {getBody()}
          </Grid>
          <Grid item>{getFeatures()}</Grid>
        </Grid>
      </>
    );
  };

  const getConfirmationDialogModal = () => {
    return (
      <UnexecutedCampaignsAlertModal
        showDialogModal={showDialogModal}
        setShowDialogModal={setShowDialogModal}
        unexecutedCampaigns={props.unexecutedCampaigns}
        setUnexecutedCampaigns={props.setUnexecutedCampaigns}
        handleDisconnect={logoutSocialMediaApiHandler}
      />
    );
  };

  return (
    <>
      <Box>
        {getMainBodyContent()}
        {getConfirmationDialogModal()}
      </Box>
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default Facebook;
