import { TooltipRenderProps } from "react-joyride";
import { Box, Grid, Typography } from "@mui/material";
import { CustomButton } from "global/components";
import UserTourStyles from "utils/UserTour/UserTourStyles";

const UserTourTooltip = (props: TooltipRenderProps) => {
  const {
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    skipProps,
    size,
  } = props;
  const classes = UserTourStyles;
  return (
    <>
      <Grid container>
        <Grid item sx={classes.gridItemContainer}>
          <Box sx={classes.circularBox}>
            <Typography sx={classes.stepsTextStyles}>{`${
              index + 1
            } / ${size}`}</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box {...tooltipProps} sx={classes.tooltipBoxStyles}>
            <Grid container spacing={2}>
              <Grid item xl={12} sx={classes.gridItemContainer}>
                <Typography sx={classes.tooltipContentStyles}>
                  {step.content}
                </Typography>
              </Grid>
              <Grid item xl={12} sx={classes.buttonGridItem}>
                {index > 0 && (
                  <CustomButton
                    label="Prev"
                    customClasses={classes.purpleButtonStyles}
                    {...backProps}
                  />
                )}
                {continuous ? (
                  <CustomButton
                    label={isLastStep ? "Close" : "Next"}
                    customClasses={classes.purpleButtonStyles}
                    {...primaryProps}
                  />
                ) : (
                  <CustomButton
                    label="close"
                    customClasses={classes.purpleButtonStyles}
                    {...closeProps}
                  />
                )}
                {!isLastStep && (
                  <CustomButton
                    label="Skip"
                    customClasses={classes.skipButtonStyles}
                    {...skipProps}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UserTourTooltip;
