import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StepFourStyle from "screens/CreateCampaign/StepFour/StepFour.styles";
import SearchIcon from "@mui/icons-material/Search";
import {
  debounceEventHandler,
  getFormattedStatsCount,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import {
  getCampaignGroupsBySearchTextAllTeamMy,
  getCampaignGroupsCountBySearchTextAllTeamMy,
  getGroupsAllMyTeamCountGroupsTeamCount,
  getGroupsAllMyTeam,
} from "screens/CreateCampaign/createCampaignServices";
import {
  campaignGroupOptionAdmin,
  campaignGroupOptionUser,
  campaignerGroupHeader,
} from "screens/CreateCampaign/CreateCampaignData";
import { CustomInput, CustomTable } from "global/components";
import strings from "global/constants/StringConstants";
import { selectIsManager } from "redux/authSlice";
import { useAppSelector } from "utils/hooks";
import { hasAccessTo } from "utils/AuthorizationManager";
import { regularFont } from "utils/styles";
import { SortingConfig } from "models/interfaces";
import { getContactGroupTypes } from "screens/CampaignGroups/CampaignGroups.service";

interface stepFourTableProps {
  stateData: any;
  getSelectedRow?: Function;
  finalSubmitGroups?: Function;
  selectedRowCheckData?: any;
  isSelectedRow?: any;
  singleSelectCheckBox?: Function;
  selectAllcheckBox?: Function;
  test?: any;
}
const StepFourTable = (props: stepFourTableProps) => {
  const classes = StepFourStyle;
  const [searchText, setSearchText] = useState("");
  const isManager = useAppSelector(selectIsManager);
  const [selectedDropdownValue, setSelectedDropdownValue] =
    useState<string>("all");
  const [selectedGroupType, setSelectedGroupType] = useState<string>("All");
  const [groupTypes, setGroupTypes] = useState<string[]>(["All"]);
  const [countSelectedEmail, setCountSelectedEmail] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [tableData, setTableData] = useState<string[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [dataLoader, setDataLoader] = useState<boolean>(false);
  const [perPageData, setPerPageData] = useState(10);
  const [sortConfig, setSortConfig] = useState<SortingConfig[]>([]);

  const hasCampaignerFetchAccess = hasAccessTo(
    strings.campaigner,
    strings.fetchPermission
  );

  useEffect(() => {
    fetchEmailGroups();
  }, [pageNumber]);

  useEffect(() => {
    if (props.stateData.checkedListId.length === 0) {
      setCountSelectedEmail(0);
    }
  }, [props.stateData.checkedListId]);

  const handlePerPageData = (event: any) => {
    setPageNumber(1);
    setPerPageData(event.target.value);
  };

  useEffect(() => {
    setPageNumber(1);
  }, [searchText.trim(), selectedDropdownValue, perPageData,selectedGroupType]);

  useEffect(() => {
    getGroupTypes();
    if (isTruthy(searchText.trim())) {
      handleSearch(searchText.trim());
    } else {
      fetchEmailGroups();
    }
  }, [
    pageNumber,
    searchText.trim(),
    selectedDropdownValue,
    selectedGroupType,
    perPageData,
    sortConfig,
  ]);
  // input box onChange method
  const handleOnchange = (e: any) => {
    setSearchText(e.target.value);
    setPerPageData(10);
  };
  // dropdown box onChange method
  const handleDropdownOnchange = (event: any) => {
    setSelectedDropdownValue(event.target.value);
  };

  const handleGroupTypeChange = (event: any) => {
    setSelectedGroupType(event.target.value);
  };

  const getGroupTypes = async () => {
    try {
      setLoader(true);
      const groupType = await getContactGroupTypes();
      setGroupTypes(["All", ...groupType]);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  //   handle table common method for table data api and seraching api
  const handleTableData = async (emailGroup: any) => {
    setLoader(true);
    const data: any = await Promise.all(
      emailGroup.map(async (eachRecord: any) => {
        return {
          id: eachRecord.id,
          key: eachRecord.id,
          name: eachRecord.name,
          groupType: eachRecord.type,
          owner: eachRecord.owner,
          recipientCount: eachRecord.recipientCount,
        };
      })
    );
    setTableData(data);
    setLoader(false);
  };
  // main table data api method
  const fetchEmailGroups = async () => {
    try {
      setDataLoader(true);
      const [emailGroups, count] = await Promise.all([
        getGroupsAllMyTeam(
          pageNumber,
          selectedDropdownValue,
          selectedGroupType,
          perPageData,
          sortConfig
        ),
        getGroupsAllMyTeamCountGroupsTeamCount(
          selectedDropdownValue,
          selectedGroupType
        ),
      ]);
      handleTableData(emailGroups);
      setCount(count);
      setDataLoader(false);
    } catch (error: any) {
      setDataLoader(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  //   searching method
  const handleSearch = async (searchText: any) => {
    setDataLoader(true);
    if (searchText?.trim() !== "") {
      if (!isTruthy(searchText.trim())) {
        setPageNumber(1);
      }
      try {
        const [count, contactGroup] = await Promise.all([
          getCampaignGroupsCountBySearchTextAllTeamMy(
            searchText,
            selectedDropdownValue,
            selectedGroupType
          ),
          getCampaignGroupsBySearchTextAllTeamMy(
            searchText.trim(),
            pageNumber,
            selectedDropdownValue,
            selectedGroupType,
            perPageData,
            sortConfig
          ),
        ]);
        setCount(count);
        handleTableData(contactGroup);
        setDataLoader(false);
      } catch (error: any) {
        setDataLoader(false);
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
      }
    } else {
      setDataLoader(false);
      return await fetchEmailGroups();
    }
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    pageNumber: number
  ) => {
    setPageNumber(pageNumber);
  };

  // search and dropdown method
  const searchInputAndDropdown = () => {
    return (
      <>
        <Grid container>
          <Grid item mb={2}>
            <CustomInput
              id="step4_groups_search_field"
              placeHolder="Search text"
              // value={searchText}
              onChange={debounceEventHandler(
                handleOnchange,
                strings.SEARCH_TIME_OUT
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Select
              id="step4_groups_category_dropdown"
              sx={classes.dropDownStyle}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={selectedDropdownValue}
              onChange={(e) => handleDropdownOnchange(e)}
            >
              {isManager &&
                campaignGroupOptionAdmin?.map((optionAdmin: any) => (
                  <MenuItem
                    sx={classes.menuItemsStyles}
                    key={optionAdmin.id}
                    value={optionAdmin.value}
                  >
                    {optionAdmin.label}
                  </MenuItem>
                ))}
              {!isManager &&
                campaignGroupOptionUser?.map((optionUser: any) => (
                  <MenuItem
                    sx={classes.menuItemsStyles}
                    key={optionUser.id}
                    value={optionUser.value}
                  >
                    {optionUser.label}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Box>
            <FormControl>
              <Select
                id="groups_category_groupType"
                sx={{ ...classes.dropDownStyle, width: "150px" }}
                value={selectedGroupType}
                onChange={(e) => handleGroupTypeChange(e)}
                displayEmpty
                renderValue={
                  selectedGroupType !== ""
                    ? undefined
                    : () => " Select Group Type"
                }
              >
                {groupTypes.map((data) => (
                  <MenuItem value={data} sx={classes.menuItemsStyles}>
                    {data}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </>
    );
  };
  const onSelectAllClick = (event: any) => {
    props.selectAllcheckBox && props.selectAllcheckBox(event, tableData);
  };
  const handleClick = (event: any, row: any) => {
    props.singleSelectCheckBox && props.singleSelectCheckBox(event, row);
  };

  // step four table method
  const tableContent = () => {
    return (
      <>
        <Box sx={{ minWidth: "300px", width: "100%", overflow: "auto" }}>
          <CustomTable
            headers={campaignerGroupHeader}
            rows={tableData}
            handlePageChange={handlePageChange}
            paginationCount={count}
            checkboxSelection={true}
            handleClick={handleClick}
            isSelected={props.isSelectedRow}
            onSelectAllClick={onSelectAllClick}
            isSelectAll={props.selectedRowCheckData}
            isLoading={loader}
            handlePerPageData={handlePerPageData}
            setPage={setPageNumber}
            perPageData={perPageData}
            rowsPerPage={perPageData}
            pageNumber={pageNumber}
            sortConfig={sortConfig}
            setSortConfig={setSortConfig}
            sortingEntity={"ContactGroup"}
          />
        </Box>
      </>
    );
  };

  const getTableStepFour = () => {
    return (
      <>
        <Box>
          <Box mt={3} mb={2}>
            {hasCampaignerFetchAccess ? (
              <Typography className="padding-count">
                {getFormattedStatsCount(
                  props.stateData.checkedListId.length > 0
                    ? props.stateData.countSelectedEmail
                    : countSelectedEmail
                )}{" "}
                selected out of {""}
                {getFormattedStatsCount(props.stateData.allowedEmailCount)}{" "}
                available emails.
              </Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  color: "#ff4244",
                  ...regularFont,
                }}
              >
                * You don't have permission to view allowed emails count. Please
                contact your account administrator.
              </Typography>
            )}
          </Box>
          <Box>
            {searchInputAndDropdown()}
            {tableContent()}
          </Box>
        </Box>
        <CustomLoader isLoading={loader || dataLoader} />
      </>
    );
  };

  return getTableStepFour();
};

export default StepFourTable;
