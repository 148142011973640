import {
  Box,
  Card,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ViewTicketStyles from "../ViewTicket.styles";
import { mediumFont, regularFont } from "utils/styles";
import { formatDateInLongDateTimeFormat, formateRelativeTimeFormat } from "helpers/methods";

interface CustomProps {
  ticketDetails: any;
  isLoading: boolean;
}

const TicketDetails = (props: CustomProps) => {
  const classes = ViewTicketStyles;

  const getColorBasedOnPriority = (priority: string) => {
    switch (priority) {
      case "High": {
        return "#FA6565";
      }
      case "Medium": {
        return "#946f00";
      }
      case "Low": {
        return "#01CA52";
      }
    }
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <Card
        sx={{
          ...classes.billingDetail,
          p: 3,
          "&.MuiCard-root": {
            height: "100% !important",
            maxHeight: "100% !important",
          },
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={0.7}>
            <Typography sx={{ ...classes.heading, color: "#242A3A" }}>
              Details
            </Typography>
          </Stack>
        </Stack>
        <List>
          <ListItem divider>
            <ListItemText
              disableTypography
              primary={
                <Typography sx={{ ...classes.heading, color: "#242A3A" }}>
                  Status
                </Typography>
              }
              secondary={
                <>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mt={1}
                  >
                    <Typography
                      sx={{ ...regularFont, wordBreak: "break-word" }}
                      color="#6C737F"
                    >
                      {props.ticketDetails?.status}
                    </Typography>
                  </Stack>
                </>
              }
            />
          </ListItem>
          <ListItem divider>
            <ListItemText
              disableTypography
              primary={
                <Typography sx={{ ...classes.heading, color: "#242A3A" }}>
                  Assignees
                </Typography>
              }
              secondary={
                <>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mt={1}
                  >
                    <Grid container spacing={1}>
                      <Grid item display="flex" flexWrap="wrap" gap={1}>
                        {props.ticketDetails?.hasOwnProperty("assigneeName") ? (
                          props.ticketDetails?.assigneeName
                            ?.split(",")
                            .map((data: any) => {
                              return (
                                <Chip
                                  label={data}
                                  key={data}
                                  size="small"
                                  sx={{
                                    ...regularFont,
                                    backgroundColor: "#EBEFFF",
                                    fontSize: "13px",
                                  }}
                                />
                              );
                            })
                        ) : (
                          <Chip
                            label="Unassigned"
                            size="small"
                            sx={{
                              ...regularFont,
                              backgroundColor: "#EBEFFF",
                              fontSize: "13px",
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              }
            />
          </ListItem>

          <ListItem divider>
            <ListItemText
              disableTypography
              primary={
                <Typography sx={{ ...classes.heading, color: "#242A3A" }}>
                  Reporter
                </Typography>
              }
              secondary={
                <>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mt={1}
                  >
                    <Typography
                      sx={{ ...regularFont, wordBreak: "break-word" }}
                      color="#6C737F"
                    >
                      {props.ticketDetails?.assignorName}
                    </Typography>
                  </Stack>
                </>
              }
            />
          </ListItem>

          <ListItem divider>
            <ListItemText
              disableTypography
              primary={
                <Typography sx={{ ...classes.heading, color: "#242A3A" }}>
                  Topic
                </Typography>
              }
              secondary={
                <>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mt={1}
                  >
                    <Typography
                      sx={{ ...regularFont, wordBreak: "break-word" }}
                      color="#6C737F"
                    >
                      {props.ticketDetails?.process}
                    </Typography>
                  </Stack>
                </>
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              disableTypography
              primary={
                <Typography sx={{ ...classes.heading, color: "#242A3A" }}>
                  Priority
                </Typography>
              }
              secondary={
                <>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mt={1}
                  >
                    <Chip
                      size="small"
                      sx={{
                        ...regularFont,
                        background: getColorBasedOnPriority(
                          props.ticketDetails?.priority
                        ),
                        color: "#FFFFFF",
                        fontSize: "13px",
                        p: 1,
                      }}
                      label={props.ticketDetails?.priority}
                    />
                  </Stack>
                </>
              }
            />
          </ListItem>
        </List>
      </Card>
      {!props.isLoading && (
        <Stack spacing={1} mt={2}>
          <Typography
            sx={{
              ...mediumFont,
            }}
            fontSize="14px"
            color="#BABEC3"
            pl={2}
          >
            Created {formatDateInLongDateTimeFormat(props.ticketDetails?.activationDate)}
          </Typography>
          <Typography
            sx={{
              ...mediumFont,
            }}
            fontSize="14px"
            color="#BABEC3"
            pl={2}
          >
            Updated {formateRelativeTimeFormat(props.ticketDetails?.updatedOn)}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default TicketDetails;
