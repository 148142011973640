import urls from "global/constants/UrlConstants";
import { getCallParams, getSearchedCallParams, makeCall } from "utils/service";

export const fetchResources = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.GET_RESOURCES, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateRole = async (data: any) => {
  try {
    const callParams = await getCallParams("POST", data);
    const res = await makeCall(urls.UPDATE_ROLE, callParams);
    return res;
  } catch (error) {
    throw error;
  }
};

export async function addRole(data: any) {
  try {
    const callParams = await getCallParams("POST", data);
    const response: any = await makeCall(urls.ADD_ROLE, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function fetchRoles(page: number, limit: number) {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.GET_ROLES}/limit/${limit}/page/${page}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function fetchRolesCount() {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.GET_ROLES_COUNT, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const fetchSearchRoles = async (
  searchText: any,
  page: number,
  limit: number
) => {
  try {
    const callParams = await getSearchedCallParams("POST", searchText);
    const res = await makeCall(
      `${urls.GET_ROLES_SEARCH}/limit/${limit}/page/${page}`,
      callParams
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const fetchSearchRolesCount = async (searchText: any) => {
  try {
    const callParams = await getCallParams("POST", searchText);
    const res = await makeCall(`${urls.GET_ROLES_SEARCH_COUNT}`, callParams);
    return res;
  } catch (error) {
    throw error;
  }
};
