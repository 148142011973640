import {
  boldFont,
  regularFont,
  getRelativeFontSize,
  pureWhiteColor,
  primaryHeadingColor,
  theme,
  mediumFont,
  pinkDarkColor,
  centerItemFlex,
  buttonWhiteBg,
  purplePrimaryColor,
  purpleThemedSelectComponent,
  pinkThemedMenuItems,
} from "utils/styles";

export const ViewContactStyles = {
  contactTableContainer: {
    margin: "0px 20px",
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    margin: "8px 0",
    gap: "10px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  paperStyle: {
    gap: "10px",
    borderRadius: "0",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
  },
  campaignerHeading: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(0),
    },
  },
  inputSearch: {
    marginTop: "20px",
  },
  deleteModalTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(13),
    textAlign: "center",
  },
  recipientsSummaryTitle: {
    fontSize: "20px",
    marginLeft: "10px",
    ...boldFont,
  },
  dropdown: {
    minWidth: "150px",
    height: "47px",
    borderRadius: "15px",
    background: pureWhiteColor,
    ...regularFont,
    ...purpleThemedSelectComponent,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  optionStyle: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  emailColor: {
    color: pinkDarkColor,
    ...mediumFont,
    fontSize: "14px",
  },
  summaryBoxWrapper: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
  },
  summaryBoxStyle: {
    minHeight: "90px",
    width: "350px",
  },
  getTypeName: {
    height: "10vh",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  type: {
    ...boldFont,
    wordWrap: "break-word",
    width: "250px",
  },
  deleteGroupBodyStyle: {
    display: "flex",
    justifyContent: "center",
    ...mediumFont,
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  textBold: {
    ...boldFont,
  },
  fontText: {
    ...regularFont,
    paddingLeft: "5px",
  },
  deleteIconStyle: {
    marginLeft: "20px",
  },
  dialogContent: {
    testAlign: "center",
  },
  deleteCloseStyle: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    gap: "10px",
    paddingBottom: "20px",
    "& button": {
      width: "120px",
    },
  },
  modalTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(13),
    textAlign: "center",
  },
  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  headerContent: {
    display: "flex",
    "& img": {
      width: "100%",
    },
  },
  recipientTableTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(13),
    textAlign: "center",
  },
  recipientCampaignName: { wordBreak: "break-all", fontSize: "14px" },
  emailRecipientTableStyles: {
    minWidth: "600px",
    width: "100%",
    overflow: "hidden",
  },
  confirmModalText: {
    ...regularFont,
    ...centerItemFlex,
  },
  cancelButtonStyle: {
    color: "#212121 !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E7E7E7",
    "&:hover": {
      background: "none",
    },
  },
  centerItemFlex: {
    ...centerItemFlex,
    paddingBottom: "20px",
  },
  ChipStyle: {
    ...boldFont,
    textTransform: "uppercase",
    padding: "5px 10px",
  },
  dialogFooter: {
    display: "flex",
    gap: "10px",
    ...centerItemFlex,
    width: "100%",
    justifyContent: "center",
    "& button": {
      width: "120px",
    },
  },
  headerBackgroundColor: {
    backgroundColor: "#fcf5ff",
    padding: "28px 25px",
  },
  BootstrapInput: {
    border: "1px solid",
    borderRadius: "15px",
    ".MuiInputBase-root": {
      borderRadius: "15px",
    },
    "& .MuiInputLabel-root ": {
      color: "red !important",
    },
    "&:focus": {
      color: "red !important",
    },
    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: "red !important",
    },
  },
  boldFonts: {
    ...boldFont,
    fontSize: getRelativeFontSize(13),
    textAlign: "center",
  },
  addTagChip: {
    marginLeft: "5px",
    marginTop: "8px",
    borderRadius: "5px",
    ...mediumFont,
    fontSize: "15px",
    backgroundColor: "#fcf5ff",
  },
  addBtnStyle: {
    width: "100%",
    whiteSpace: "nowrap",
  },
  tagsInputWrapper: {
    display: "flex",
    alignItems: "end",
  },
  addTagStyle: {
    width: "100%",
    marginTop: "30px",
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(0),
    },
  },
  clickedUrlTextStyles: {
    cursor: "pointer",
    display: "inline-block",
    wordBreak: "break-word",
    maxWidth: "250px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100px",
    },
  },
} as const;
