import {
  Avatar,
  Box,
  Stack,
  Link,
  Typography,
  Grid,
  Chip,
  Breadcrumbs,
} from "@mui/material";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import {
  convertESTtoUserLocalDateAndTime,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
  setFileName,
} from "helpers/methods";
import React, { useCallback, useState } from "react";
import { selectName } from "redux/authSlice";
import { useAppSelector } from "utils/hooks";
import { mediumFont, regularFont } from "utils/styles";
import CommentEditor, { parseHTML } from "./CommentEditor";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { doAddComment } from "screens/Tickets/ViewTicket/ViewTicketService";
import notifiers from "global/constants/NotificationConstants";
import { downloadDocument } from "screens/Tickets/ticketService";

interface CustomProps {
  ticketDetails: any;
  isLoading: boolean;
  setIsLoading: Function;
  ticketId: string;
  fetchTicketById: Function;
}

const Comments = (props: CustomProps) => {
  const userName = useAppSelector(selectName);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [addComment, setAddComment] = useState({
    content: "",
    showEditor: false,
    selectedCommentType: "",
  });
  const [replyComment, setReplyComment] = useState({
    showEditor: false,
    content: "",
    commentId: 0,
  });
  const [expandedComments, setExpandedComments] = useState<{
    [key: number]: boolean;
  }>({});

  const getInitials = (name: string) => {
    const nameArray = name?.split(" ");
    const initials = nameArray.map((n) => n[0]).join("");
    return initials.toUpperCase();
  };

  const handleReplyClick = (commentId: number) => {
    setReplyComment({
      showEditor: true,
      content: "",
      commentId: commentId,
    });
  };

  const handleToggleReplies = (commentId: number) => {
    setExpandedComments((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId],
    }));
  };

  const handleReplyContentChange = useCallback((content: string) => {
    setReplyComment((prevState) => ({
      ...prevState,
      content,
    }));
  }, []);

  const handleOnContentChange = useCallback((content: string) => {
    setAddComment((prevState) => ({
      ...prevState,
      content,
    }));
  }, []);

  const handleAddComment = async () => {
    if (addComment.content === "") {
      return openErrorNotification("Comment text is required");
    }
    try {
      setIsLoading(true);
      const body = {
        text: addComment.content,
        type: "External",
        referenceId: props.ticketId,
        parentId: 0,
        docUrls: files,
      };
      await doAddComment(body);
      props.fetchTicketById();
      setAddComment((prevState) => ({
        ...prevState,
        content: "",
        showEditor: false,
        selectedCommentType: "",
      }));

      setFiles([]);
      openSuccessNotification("Comment added successfully");
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleReplyComment = async (comment: any) => {
    if (replyComment.content === "") {
      return openErrorNotification("Comment text is required");
    }
    try {
      setIsLoading(true);
      const body = {
        text: replyComment.content,
        type: comment.type,
        referenceId: props.ticketId,
        parentId: comment.id,
        docUrls: files,
      };
      await doAddComment(body);
      props.fetchTicketById();
      setReplyComment({
        showEditor: false,
        content: "",
        commentId: 0,
      });
      setAddComment((prevState) => ({
        ...prevState,
        content: "",
        showEditor: false,
        selectedCommentType: "",
      }));
      setFiles([]);
      openSuccessNotification("Comment added successfully");
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleDownloadDocument = async (path: string) => {
    try {
      setIsLoading(true);
      const body = {
        path,
      };
      const data = await downloadDocument(body);
      const fileName = path.split("/").pop();
      let file = document.createElement("a");
      // @ts-ignore
      file.style = "display:none";
      let url = window.URL.createObjectURL(data);
      file.href = url;
      file.download = `${fileName}`;
      file.click();
      // @ts-ignore
      window.URL.revokeObjectURL(url);
      file.remove();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getComments = (comments: any[], depth: number) => {
    return comments?.map((comment: any) => (
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing={2}
        key={comment.id}
        sx={{
          background: "none",
          borderRadius: "",
        }}
        mt={1}
        pl={`${depth * 10}px`}
        pr={0}
        pt={0}
        pb={0}
      >
        <Box pl={2}>
          <Avatar
            sx={{
              fontSize: 14,
              fontWeight: 600,
              letterSpacing: 0,
              color: "black",
              background: "#E5E7EB",
            }}
          >
            {getInitials(comment?.byName ?? comment?.byId)}
          </Avatar>
        </Box>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              sx={{
                ...mediumFont,
              }}
              color="#5E6979"
            >
              {comment?.byName ?? comment?.byId}
            </Typography>
            <Typography
              sx={{
                ...mediumFont,
              }}
              fontSize="0.8rem"
              color="#BABEC3"
            >
              {convertESTtoUserLocalDateAndTime(
                comment?.timestamp,
                "MMMM D, YYYY [at] h:mm A",
                "MM/DD/YYYY HH:mm:ss"
              )}
            </Typography>
          </Stack>
          <Typography sx={{ ...regularFont }} color="#6C737F">
            {parseHTML(comment.text ?? "")}
          </Typography>

          <Grid container direction="row" gap={1} spacing={1} pr={1}>
            {comment?.docUrls &&
              comment?.docUrls.map((file: string) => {
                return (
                  <Chip
                    label={setFileName(file)}
                    size="small"
                    sx={{
                      p: 1.5,
                      ...mediumFont,
                      backgroundColor: "#EBEFFF",
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "#EBEFFF",
                      },
                      maxWidth: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    icon={<DownloadIcon />}
                    deleteIcon={<VisibilityIcon />}
                    onClick={() => handleDownloadDocument(file)}
                  />
                );
              })}
          </Grid>
          <Breadcrumbs separator="•" color="#00359E" aria-label="breadcrumb">
            {comment?.reply.length > 0 && (
              <Link
                color="#5E6979"
                sx={{ alignItems: "center", display: "inline-flex" }}
                underline="hover"
                component="button"
                onClick={() => handleToggleReplies(comment.id)}
              >
                {expandedComments[comment.id] ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
                <Typography sx={{ ...mediumFont }} color="#5E6979">
                  {comment?.reply.length}
                </Typography>
              </Link>
            )}
            <Link
              color="#5E6979"
              sx={{ alignItems: "center", display: "inline-flex" }}
              underline="hover"
              component="button"
              onClick={() => handleReplyClick(comment.id)}
            >
              <Typography sx={{ ...mediumFont }} color="#5E6979">
                Reply
              </Typography>
            </Link>
          </Breadcrumbs>
          {replyComment.showEditor && replyComment.commentId === comment.id && (
            <Box mt={2}>
              <CommentEditor
                content={replyComment.content}
                onChange={handleReplyContentChange}
                handleCancel={() => {
                  setReplyComment({
                    showEditor: false,
                    content: "",
                    commentId: 0,
                  });
                  setFiles([]);
                }}
                handleAddComment={() => handleReplyComment(comment)}
                ticketId={props.ticketId}
                files={files}
                setFiles={setFiles}
              />
            </Box>
          )}
          <Box>
            {comment.reply.length > 0 &&
              expandedComments[comment.id] &&
              getComments(comment.reply, depth + 1)}
          </Box>
        </Stack>
      </Stack>
    ));
  };

  const getCommentBody = () => (
    <Stack direction="column" spacing={1} mt={2}>
      <Stack direction="row" spacing={2} pl={2} pb={1}>
        <Avatar
          sx={{
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            color: "black",
            background: "#E5E7EB",
          }}
        >
          {getInitials(userName)}
        </Avatar>
        <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
          <Box
            sx={{
              cursor: "pointer",
              p: 1,
              background: "#FFFFFF",
              border: "0.5px solid #E9E9E9",
              borderRadius: "20px",
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Link
                color={"#0000ff"}
                sx={{
                  ...mediumFont,
                }}
                onClick={() => {
                  setAddComment((prevState) => ({
                    ...prevState,
                    selectedCommentType: "Add External Note",
                    showEditor: !prevState.showEditor,
                  }));
                }}
              >
                Add Comment
              </Link>
            </Stack>
          </Box>
          {addComment.showEditor && (
            <Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box mt={2}>
                  <CommentEditor
                    content={addComment.content}
                    onChange={handleOnContentChange}
                    handleCancel={() => {
                      setAddComment({
                        showEditor: false,
                        content: "",
                        selectedCommentType: "",
                      });
                      setFiles([]);
                    }}
                    handleAddComment={handleAddComment}
                    ticketId={props.ticketId}
                    files={files}
                    setFiles={setFiles}
                  />
                </Box>
              </Stack>
            </Box>
          )}
        </Stack>
      </Stack>
      {getComments(props.ticketDetails?.comments, 0)}
    </Stack>
  );
  return (
    <>
      {getCommentBody()}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default Comments;
