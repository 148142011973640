import { Box, Typography, Grid } from "@mui/material";
import { CustomButton, CustomDialog } from "global/components";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import { ViewContactStyles } from "screens/Contact/ViewContact.styles";
import { deleteContactFromGroups } from "screens/Contact/ViewContactService";
import campaignDeleteModal from "assets/images/campaignDeleteModal.svg";
import notifiers from "global/constants/NotificationConstants";
import { useState } from "react";
import CustomLoader from "global/components/CustomLoader/CustomLoader";

interface CustomProps {
  openConfirmModal: boolean;
  setOpenConfirmModal: (openConfirmModal: boolean) => void;
  setOpenDeleteModal: (deleteModal: boolean) => void;
  email: string;
  selected: {};
  getSearchTableData: Function;
}

const ConfirmModal = (props: CustomProps) => {
  const classes = ViewContactStyles;
  const [loading, setLoading] = useState<boolean>(false);

  const confirmModelSubTitle = () => {
    return (
      <Typography sx={classes.confirmModalText}>
        Are you sure you want to delete?
      </Typography>
    );
  };

  const closeConfirmModel = () => {
    props.setOpenConfirmModal(false);
  };

  const deleteConfirmModel = async () => {
    try {
      setLoading(true);
      const response = await deleteContactFromGroups(
        props.email,
        props.selected
      );
      props.setOpenConfirmModal(false);
      props.setOpenDeleteModal(false);
      openSuccessNotification(response.message);
      props.getSearchTableData && props.getSearchTableData();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setLoading(false);
    }
  };
  const confirmDeleteModelFooter = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            customClasses={classes.cancelButtonStyle}
            label="Cancel"
            onClick={closeConfirmModel}
            id="cancel_model_button"
          />
          <CustomButton
            label="Delete"
            onClick={deleteConfirmModel}
            id="upload_model_button"
          />
        </Box>
      </Grid>
    );
  };

  const addUserHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={campaignDeleteModal} alt="image not found" />
      </Box>
    );
  };

  return (
    <>
      <CustomDialog
        isDialogOpen={props.openConfirmModal}
        closable
        closeButtonVisibility
        width="350px"
        dialogHeaderContent={addUserHeaderContent()}
        dialogBodyContent={confirmModelSubTitle()}
        dialogFooterContent={confirmDeleteModelFooter()}
        handleDialogClose={closeConfirmModel}
      />
      <CustomLoader isLoading={loading} />
    </>
  );
};

export default ConfirmModal;
