import strings from "global/constants/StringConstants";
import urls from "global/constants/UrlConstants";
import {
  getCallParams,
  getFileCallParams,
  getSearchedCallParams,
  getSortingSearchedCallParams,
  makeCall,
} from "utils/service";

export const getLoggedInEmailUser = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.userEmailAccount, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const customDomain = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.customDomains, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSubordinateEmailsUsers = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.getSubordinates, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export async function campaignExistsCheck(name: string) {
  try {
    const callParams = await getSearchedCallParams("POST", name);
    const response: any = await makeCall(
      `${urls.campaignExistsCheck}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function subjectLineGeneration(emailData: any) {
  try {
    const callParams = await getCallParams("POST", emailData);
    const response: any = await makeCall(urls.subjectLines, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const getAllTemplates = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.formatCampaignTemplate,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

// dropzone upload file or image api method
export const attachFileUpload = async (formData: any) => {
  try {
    const callParams = await getFileCallParams(formData);
    const response: any = await makeCall(urls.attachFileUpload, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSpamPrediction = async (emailData: any) => {
  try {
    const callParams = await getCallParams("POST", emailData);
    const response: any = await makeCall(urls.spamDetect, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignGroupsBySearchTextAllTeamMy = async (
  searchText: string,
  pageNumber: any,
  dropDownValue: any,
  groupType: string,
  limit: number,
  entitySorters: any
) => {
  try {
    const body = { entitySorters, searchText };
    const callParams = await getSortingSearchedCallParams("POST", body);
    const response: any = await makeCall(
      `${urls.groupAllMyTeam}/${dropDownValue}/groupType/${groupType}/search/page/${pageNumber}/false/limit/${limit}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignGroupsCountBySearchTextAllTeamMy = async (
  searchText: string,
  dropDownValue: any,
  groupType: string
) => {
  try {
    const callParams = await getSearchedCallParams("POST", searchText);
    const response: any = await makeCall(
      `${urls.groupAllMyTeam}/${dropDownValue}/groupType/${groupType}/count/search/false`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getGroupsAllMyTeam = async (
  pageNumber: any,
  dropDownValue: any,
  groupType: string,
  limit: number,
  sortConfig: any
) => {
  try {
    const callParams = await getCallParams("POST", sortConfig);
    const response: any = await makeCall(
      urls.groupAllMyTeam +
        "/" +
        dropDownValue +
        "/" +
        "groupType/" +
        groupType +
        "/" +
        pageNumber +
        `/false` +
        "/" +
        "limit/" +
        limit,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getGroupsAllMyTeamCountGroupsTeamCount = async (
  dropDownValue: any,
  groupType: string
) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.groupAllMyTeamCount + `/${dropDownValue}/groupType/${groupType}/count/false`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export async function getSearchTagCount(search: any) {
  try {
    const callParams = await getSearchedCallParams("POST", search);
    const url = `${urls.getTagSearchCount}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getSearchTagData(
  searchText: any,
  pageNumber: any,
  limit: number,
  entitySorters: any
) {
  try {
    const body = { searchText, entitySorters };
    const callParams = await getSortingSearchedCallParams("POST", body);
    const url = `${urls.getTagSearchCountData}/page/${pageNumber}/limit/${limit}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const getTags = async (
  pageNumber: number,
  limit: number,
  sortConfig: any
) => {
  try {
    const callParams = await getCallParams("POST", sortConfig);
    const response: any = await makeCall(
      `${urls.getTags}/${pageNumber}/limit/${limit}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getTagsCount = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(`${urls.getTagsCount}`, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getValidDistinctEmailCountByGroups = async (
  groupIds: string[]
) => {
  try {
    const callParams = await getCallParams("POST", groupIds);
    const response: any = await makeCall(
      urls.validDistinctEmailCount,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export async function getValidDistinctEmailCountByTags(tagId: string[]) {
  try {
    const callParams = await getCallParams("POST", tagId);
    const response: any = await makeCall(urls.tagsCount, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const sendCampaigns = async (campaignsData: any[]) => {
  try {
    const callParams = await getCallParams("POST", campaignsData);
    const response: any = await makeCall(
      urls.sendSequentialEmail,
      callParams,
      strings.CUSTOM_API_TIMEOUT_LIMIT
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
