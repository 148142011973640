import { Box, Typography, Card, Stack } from "@mui/material";
import socialStyles from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/Social.styles";
import instagramHeartIcon from "assets/icons/insta-heart.png";
import instagramCommentIcon from "assets/icons/insta-comment.png";
import instagramTagIcon from "assets/icons/insta-tag.png";
import instagramSaveIcon from "assets/icons/insta-save.png";
import instagramRound from "assets/icons/instagram-round.png";
import instagramLayout from "assets/icons/instagram-layout.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomImageCarousal from "global/components/CustomImageCarousal/CustomImageCarousal";
import { isTruthy } from "helpers/methods";
import strings from "global/constants/StringConstants";
import { boldFont, getRelativeFontSize, regularFont } from "utils/styles";

interface instagramCustomProps {
  selectedAppsList?: string;
  isCardClickable: boolean;
  socialAppCardClickHandler?: Function;
  postContent?: string;
  imageArray?: string[];
  carousalIndex?: number;
  setCarousalIndex?: Function;
  instagramProfilePicture?: string;
  instagramAccountName?: string;
  facebookPage: any;
}

const InstagramCard = (props: instagramCustomProps) => {
  const classes = socialStyles;

  const shouldShowInstagramCard = () => {
    const shouldShowCard = props?.facebookPage?.metaData?.pageDetails?.some(
      (pageDetail: any) =>
        pageDetail.hasOwnProperty(strings.instagramBusinessAccountKey)
    );
    return shouldShowCard;
  };

  const filterInstagramBusinessAccounts = (pageDetails: any) => {
    if (isTruthy(pageDetails))
      return pageDetails?.filter(
        (page: any) => page.instagram_business_account
      );
  };

  const filteredPages = filterInstagramBusinessAccounts(
    props?.facebookPage?.metaData?.pageDetails
  );

  const onClickHandler = () => {
    if (props.isCardClickable && shouldShowInstagramCard()) {
      props.socialAppCardClickHandler?.(strings.INSTAGRAM);
    }
  };

  const getInstagramAccountName = () => {
    if (isTruthy(props.instagramAccountName)) {
      return props.instagramAccountName;
    } else if (filteredPages?.length < 2) {
      if (filteredPages[0]) {
        return (
          filteredPages[0]?.instagram_business_account?.username || "Instagram"
        );
      }
      return "Instagram";
    } else {
      return "Instagram";
    }
  };

  const getInstagramProfileImg = () => {
    if (isTruthy(props.instagramProfilePicture)) {
      return props.instagramProfilePicture;
    } else if (filteredPages?.length < 2) {
      if (filteredPages[0]) {
        return (
          filteredPages[0]?.instagram_business_account
            ?.profile_picture_url || instagramRound
        );
      }
      return instagramRound;
    } else {
      return instagramRound;
    }
  };

  return (
    <Box sx={classes.socialCardContainer} onClick={onClickHandler}>
      <Card
        sx={[
          classes.socialCard,
          ...(props.selectedAppsList?.includes(strings.INSTAGRAM) &&
          shouldShowInstagramCard()
            ? [classes.socialCardSelected]
            : []),
          ...(props.isCardClickable && shouldShowInstagramCard()
            ? [classes.socialCardClickable]
            : []),
        ]}
      >
        <Box sx={classes.socialCardHeader}>
          <Box sx={classes.socialCardHeaderLeft}>
            <Box>
              <img
                style={{ width: "48px", borderRadius: "50%" }}
                src={getInstagramProfileImg()}
                alt="fb-icon"
              />
            </Box>
            <Box>
              <Typography sx={classes.profileName}>
                {getInstagramAccountName()}
              </Typography>
            </Box>
          </Box>
          <Box>
            <MoreVertIcon />
          </Box>
        </Box>
        <CustomImageCarousal
          imageArray={props.imageArray}
          noImageLayout={instagramLayout}
          displayImageIndex={props.carousalIndex}
          setDisplayImageIndex={props.setCarousalIndex}
          customStyle={{ ...classes.socialCardLayout, marginTop: "10px" }}
        />
        <Box sx={classes.socialCardFooter}>
          <Box sx={classes.instagramShareOptions}>
            <Box sx={classes.instagramOptionWrapper}>
              <Box sx={classes.instagramOption}>
                <img src={instagramHeartIcon} alt="icon" />
              </Box>

              <Box sx={classes.instagramOption}>
                <img src={instagramCommentIcon} alt="icon" />
              </Box>

              <Box sx={classes.instagramOption}>
                <img src={instagramTagIcon} alt="icon" />
              </Box>
            </Box>
            <Box>
              <Box sx={classes.instagramOption}>
                <img src={instagramSaveIcon} alt="icon" />
              </Box>
            </Box>
          </Box>
          <Stack direction="row">
            <Typography sx={classes.instagramProfileTitle}>
              {getInstagramAccountName()}
            </Typography>
            <Typography sx={classes.publishPost}>
              {props.postContent ? (
                <>
                  {props.postContent.substring(0, 30)}
                  {props.postContent.length > 30 && "..."}
                </>
              ) : (
                "Publish Your Post On Instagram..."
              )}
            </Typography>
          </Stack>
        </Box>
        {!shouldShowInstagramCard() && (
          <Stack sx={classes.disabledStackItem} spacing={3}>
            <Box sx={{ padding: "0 15px" }}>
              <Typography
                sx={{
                  textAlign: "justify",
                  ...boldFont,
                  fontSize: getRelativeFontSize(4),
                }}
              >
                You have either not selected Instagram pages or not granted
                proper permissions for the Instagram pages while integrating.
              </Typography>
            </Box>
            <Box sx={{ padding: "0 15px" }}>
              <Typography
                sx={{
                  textAlign: "justify",
                  ...regularFont,
                }}
              >
                Please disconnect the Meta from Integration tab and then grant
                proper permission while reconnecting.
              </Typography>
            </Box>
          </Stack>
        )}
      </Card>
    </Box>
  );
};

export default InstagramCard;
