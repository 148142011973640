import { getRelativeFontSize, boldFont, mediumFont } from "utils/styles";

const CreateWhatsAppCampaignStyles = {
  dialogTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "10px",
    textAlign: "center",
  },
  titleLeft: {
    color: "#faad14",
  },
  titleRight: {
    color: " rgba(0,0,0,.85)",
    ...boldFont,
    fontSize: getRelativeFontSize(14),
  },
  dialogContent: {
    fontSize: getRelativeFontSize(2),
    ...mediumFont,
  },
  dialogFooter: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "10px",
    "& button": {
      width: "120px",
    },
  },
} as const;

export default CreateWhatsAppCampaignStyles;
