import React from "react";
import { Box, Typography } from "@mui/material";
import CampaignsStyles from "screens/CampaignsNew/Campaigns/Campaigns.styles";
import { CustomButton, CustomDialog } from "global/components";
import cardImage from "assets/images/upgradePlan.svg";
import moment from "moment";
import { useAppSelector } from "utils/hooks";
import { selectName } from "redux/authSlice";

interface CustomProps {
  setOpenModal: Function;
  openModal: boolean;
  handleConfirm: Function;
  planDetail: any;
}

const UpdatePlanModal = (props: CustomProps) => {
  const classes = CampaignsStyles;
  const userName = useAppSelector(selectName);

  const handleCloseModel = () => {
    props.setOpenModal(false);
  };

  const dialogTitleContent = () => {
    return (
      <Box sx={classes.dialogTitleWrapper}>
        <Box sx={classes.titleRight}>Update Plan</Box>
      </Box>
    );
  };

  const getPlanDetail = () => {
    return props.planDetail.appPlanLimitList
      ?.filter((item: any) => item.entity !== "entity")
      ?.map((item: any) => {
        const subtractionResult = item.limit - (item.current ?? 0);
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
                margin: "10px",
                fontSize: "16px",
                fontWeight: 550,
              }}
            >
              {item.entity} {subtractionResult}
            </span>
            |
          </>
        );
      });
  };

  const dialogBody = () => {
    const todayDate = moment();
    const targetDate = moment(props.planDetail.validTill);
    const daysDifference = targetDate.diff(todayDate, "days");
    return (
      <Box>
        <Typography
          sx={{ fontSize: "20px", color: "#212121", fontWeight: 700 }}
        >
          Hey {userName}!
        </Typography>
        <br />
        <Typography
          sx={{
            color: "#666666",
            fontSize: "16px",
            lineHeight: "26px",
            fontWeight: 400,
            fontFamily: "Source Sans 3",
          }}
        >
          You can update your plan as per your requirement.
          <br />
          However, we would like to inform you that upon upgrading, your current
          plan as show below, will be deactivated with immediate effect and your
          new plan will be activated.
        </Typography>
        <br />
        <Typography
          color={"#000000"}
          fontSize={"16px"}
          sx={{ fontWeight: 550 }}
        >
          <span
            style={{
              color: "#BB0070",
              fontSize: "18px",
              fontWeight: 700,
              marginRight: "10px",
            }}
          >
            Remaining:
          </span>{" "}
          Days {daysDifference} | {getPlanDetail()}
        </Typography>
      </Box>
    );
  };

  const dialogContent = () => {
    return (
      <Box sx={classes.dialogFooter}>
        <CustomButton
          label="No"
          customClasses={classes.buttonWhiteBg}
          onClick={() => handleCloseModel()}
          id="update_plan_modal_no_button"
        />
        <CustomButton
          label="Yes"
          onClick={() => props.handleConfirm()}
          id="update_plan_modal_yes_button"
        />
      </Box>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={cardImage} alt="delete Image" />
      </Box>
    );
  };

  return (
    <CustomDialog
      dialogHeaderContent={dialogHeaderContent()}
      isDialogOpen={props.openModal}
      closable
      closeButtonVisibility
      handleDialogClose={handleCloseModel}
      dialogTitleContent={dialogTitleContent()}
      dialogBodyContent={dialogBody()}
      dialogFooterContent={dialogContent()}
      width="500px"
    />
  );
};

export default UpdatePlanModal;
