import strings from "global/constants/StringConstants";
import urls from "global/constants/UrlConstants";
import { CampaignTemplateType } from "models/interfaces";
import { getCallParams, makeCall } from "utils/service";

export async function getCampaignTemplate() {
  try {
    const callParams = await getCallParams("GET");
    const getCampaign = await makeCall(urls.getCampaignTemplates, callParams);
    return getCampaign;
  } catch (error: any) {
    throw error;
  }
}

export async function getCampaignTemplateNew(tabName: string) {
  try {
    const callParams = await getCallParams("GET");
    const getCampaign = await makeCall(
      tabName == strings.allTemplates
        ? urls.formatCampaignTemplate
        : urls.getCampaignTemplates,
      callParams
    );
    return getCampaign;
  } catch (error: any) {
    throw error;
  }
}

export async function getCampaignTemplateById(id: string) {
  try {
    const callParams = await getCallParams("GET");
    const getTemplate = await makeCall(
      urls.getCampaignTemplateById + `/${id}`,
      callParams
    );
    return getTemplate;
  } catch (error: any) {
    throw error;
  }
}

export async function getFooterTemplate() {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.getDefaultFooterTemplate, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function addCampaignTemplate(
  campaignTemplate: CampaignTemplateType
) {
  try {
    const callParams = await getCallParams("POST", campaignTemplate);
    const addCampaign = await makeCall(urls.addCampaignTemplate, callParams);
    return addCampaign;
  } catch (error: any) {
    throw error;
  }
}

export async function updateCampaignTemplate(
  campaignTemplate: CampaignTemplateType
) {
  try {
    const callParams = await getCallParams("PATCH", campaignTemplate);
    const updateCampaign = await makeCall(
      urls.updateCampaignTemplate,
      callParams
    );
    return updateCampaign;
  } catch (error: any) {
    throw error;
  }
}

export const updateCampaignTemplatePermission = async (
  users: any,
  name: any
) => {
  try {
    const body = {
      users,
      name,
    };
    const callParams = await getCallParams("POST", body);
    const updateCampaign = await makeCall(
      urls.updateCampaignTemplatePermission,
      callParams
    );
    return updateCampaign;
  } catch (error: any) {
    throw error;
  }
};

export const deleteCampaignTemplate = async (campaignTemplateId: any) => {
  try {
    const callParams = await getCallParams("DELETE");
    const url = `${urls.deleteCampaignTemplate}/${campaignTemplateId}`;
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
