import strings from "global/constants/StringConstants";

export const validateSocialCampaignsStatusQuery = (statusFromUrl: any) => {
  if (
    ![strings.Completed, strings.All, strings.Submitted].includes(statusFromUrl)
  ) {
    return strings.Completed;
  }
  return statusFromUrl;
};
