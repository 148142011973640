import {
  activeMenuColor,
  boldFont,
  buttonWhiteBg,
  centerItemFlex,
  getRelativeFontSize,
  lightPinkColor,
  mediumFont,
  pinkDarkColor,
  pinkThemedMenuItems,
  primaryHeadingColor,
  pureWhiteColor,
  purplePrimaryColor,
  purpleThemedSelectComponent,
  regularFont,
  textLightColor,
  theme,
} from "utils/styles";

const StepThreeStyles = {
  headerGridContainer: {
    my: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      direction: "column",
    },
  },
  subjectLineGridItem: { [theme.breakpoints.down("md")]: { margin: "10px 0" } },
  headerSection: {
    padding: "20px",
  },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  },
  headerButtonStack: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  headerButtonStyle: { margin: "5px", width: "140px" },
  headerButton: { width: "100%", whiteSpace: "nowrap" },
  dropdown: {
    ...regularFont,
    ...purpleThemedSelectComponent,
    height: "40px",
    width: "200px",
    backgroundColor: pureWhiteColor,
    borderRadius: "8px",
    color: "#22222C",
    border: 0,
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.03)",
    "&:hover": {
      border: 0,
    },
  },
  dropdownOptions: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  //Subject Line Modal Styles
  boldFonts: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    textAlign: "center",
  },
  subjectLineBox: {
    background: pureWhiteColor,
    margin: 0,
    borderRadius: "10px",
    padding: "15px",
    cursor: "pointer",
    "&:hover": { backgroundColor: lightPinkColor },
  },
  centerItemFlex: {
    ...centerItemFlex,
    paddingBottom: "20px",
  },
  dialogFooter: {
    display: "flex",
    gap: "10px",
    ...centerItemFlex,
    width: "100%",
    justifyContent: "center",
    "& button": {
      width: "120px",
    },
  },
  //Spam Box Styles
  noSpam: {
    ...centerItemFlex,
    padding: 2,
  },
  message: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    textAlign: "center",
  },
  wordChip: {
    ...regularFont,
    mr: 1,
    height: "40px",
    padding: "0 8px",
    mb: 2,
    color: "#212121",
    fontSize: getRelativeFontSize(1),
    textTransform: "capitalize",
  },
  spamImageWrapper: { display: "flex", justifyContent: "center" },
  chartWrapper: { display: "flex", justifyContent: "center" },
  spamWarningWrapper: { display: "flex" },
  spamWaringTextStyle: {
    ...regularFont,
    fontSize: "14px",
    marginLeft: "5px",
  },
  spamTextStyle: {
    ...boldFont,
    fontSize: getRelativeFontSize(4),
    textAlign: "center",
  },
  spamWordsTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(3),
    marginTop: "10px",
  },
  colorSquare: {
    width: "12px",
    height: "12px",
    mr: 1,
    [theme.breakpoints.down("sm")]: {
      mr: "2px",
    },
  },
  colorIndicatorsBox: { display: "flex", alignItems: "center" },
  severityFonts: {
    ...regularFont,
    fontSize: "12px",
  },
  //Dialog Box styles
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    ...boldFont,
    fontSize: getRelativeFontSize(14),
  },
  footerWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    padding: "10px 0",
    "& button": {
      whiteSpace: "noWrap",
      width: "120px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  buttonGrayBg: {
    background: buttonWhiteBg,
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  contentStyle: {
    fontSize: getRelativeFontSize(4),
    ...mediumFont,
    color: textLightColor,
  },
  //Templates Modal
  templateGridItem: {
    height: "350px",
    borderRadius: "10px",
    position: "relative",
    background: "#ecf0f1",
  },
  ribbon: {
    width: "150px",
    height: "150px",
    overflow: "hidden",
    position: "absolute",
    top: "-10px",
    left: "-10px",
    "&::before": {
      position: "absolute",
      content: "''",
      display: "block",
      border: "5px solid #9b146c",
      top: "0",
      right: "30px",
    },
    "&::after": {
      position: "absolute",
      content: "''",
      display: "block",
      border: "5px solid #9b146c",
      bottom: "30px",
      left: "0",
    },
  },
  ribbonText: {
    position: "absolute",
    width: "250px",
    padding: "5px 0",
    backgroundColor: pinkDarkColor,
    color: pureWhiteColor,
    textAlign: "center",
    transform: "rotate(-45deg)",
    top: "30px",
    right: "-25px",
    zIndex: "1",
  },
  templateCard: {
    height: "350px",
    overflow: "hidden",
  },
  templateImgWrapper: {
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
  templateHover: {
    ...boldFont,
    fontSize: getRelativeFontSize(4),
    position: "absolute",
    inset: "0",
    background: "rgba(255,255,255,0.9)",
    border: "1px solid #ecf0f1",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chooseTemplateBtn: {
    width: "60%",
    color: pureWhiteColor,
    background: activeMenuColor,
    marginTop: "30px",
    "&:hover": {
      background: pinkDarkColor,
      color: pureWhiteColor,
    },
  },
  templateModalHeading: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    mb: 2,
  },
} as const;

export default StepThreeStyles;
