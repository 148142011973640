import moment from "moment";
import { store } from "utils/store";
import strings from "global/constants/StringConstants";

export const stepOneFormDataInitialState = (data?: any) => {
  return {
    campaignName: {
      value: data?.name ?? "",
      error: "",
    },
    from: {
      value: store.getState()?.auth?.email ?? data?.owner,
      error: "",
    },
    displayName: {
      value: store.getState().auth.userName ?? data?.displayName,
      error: "",
    },
    subject: {
      value: data?.subject ?? "",
      error: "",
    },
    scheduleTime: {
      value: data?.scheduleTime ?? "",
      error: "",
    },
    content: "",
    domain: {
      value: data?.data?.domainName ?? "",
      error: "",
    },
  };
};

export const validateForStepOne = (stepOneFormData: any, data: any) => {
  let errors = stepOneFormData;
  let isValid = true;
  const from = stepOneFormData?.from?.value;
  const campaignName = stepOneFormData?.campaignName?.value;
  const displayName = stepOneFormData?.displayName?.value;
  const subject = stepOneFormData?.subject?.value;
  const scheduleTime = stepOneFormData?.scheduleTime?.value;
  const domain = stepOneFormData?.domain?.value;
  if (!campaignName && !subject && !displayName) {
    // Set the fields as error true
    errors.campaignName.error = "Please enter campaign name";
    errors.displayName.error = "Please enter display name";
    errors.subject.error = "Please enter subject";
    errors.scheduleTime.error = "Please enter scheduleTime";
    errors.from.error = "Please select from dropdown";
    isValid = false;
  }
  if (!campaignName || campaignName.length >= strings.CAMPAIGN_NAME_SIZE) {
    errors.campaignName.error = !campaignName
      ? "Please enter campaign name"
      : `Campaign name cannot be more than ${strings.CAMPAIGN_NAME_SIZE} characters`;
    isValid = false;
  }
  if (!from) {
    errors.from.error = "Please select from dropdown";
    isValid = false;
  }
  if (!displayName) {
    errors.displayName.error = "Please enter display name";
    isValid = false;
  }
  if (!subject || subject.length > strings.SUBJECT_LINE_SIZE) {
    errors.subject.error = !subject
      ? "Please enter subject"
      : `Subject line cannot be more than ${strings.SUBJECT_LINE_SIZE} characters`;
    isValid = false;
  }
  if (data?.length > 0 && data?.length !== 1) {
    if (!domain) {
      errors.domain.error = "Please select the Domain Name";
      isValid = false;
    }
  }

  return { isValid, errors };
};

export const tagsTableField = () => {
  return {
    groupName: {
      value: "",
      error: "",
    },
    groupType: {
      value: "",
      error: "",
    },
  };
};
export const uploadGroupValidation = (uploadGroupData: any) => {
  const errors = uploadGroupData;
  let isValid = true;
  const groupName = uploadGroupData.groupName.value;
  const groupType = uploadGroupData.groupType.value;

  if (!groupName) {
    errors.groupName.error = "Please enter group name";
    isValid = false;
  }
  if (!groupType) {
    errors.groupType.error = "Please select group type";
    isValid = false;
  }

  return { isValid, errors };
};
