import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Stack,
  Chip,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import CampaignDetailsSectionsStyles from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/StepTwo/CampaignDetailsSections/CampaignDetailsSection.styles";
import { CustomButton } from "global/components";
import { isTruthy } from "helpers/methods";
import strings from "global/constants/StringConstants";

interface CustomProps {
  campaignData: any;
  setCampaignData: Function;
  whatsAppFromDetails: any[];
}

const FromSection = (props: CustomProps) => {
  const classes = CampaignDetailsSectionsStyles;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [fromPhoneNumber, setFromPhoneNumber] = useState(
    props.campaignData.from
  );

  useEffect(() => {
    if (props.campaignData.from.error) {
      setIsEditMode(true);
      setFromPhoneNumber(props.campaignData.from);
    }
  }, [props.campaignData.from.error]);

  const getCustomizedFromDetailItem = (
    name: string,
    phoneNumber: string,
    qualityRating: string
  ) => {
    return (
      <Box sx={classes.fromDetailItemContainer}>
        <Box sx={classes.fromNameAndPhoneContainer}>
          <Typography sx={classes.fromNameTextStyles}>{name}</Typography>
          <Box sx={classes.fromPhoneNumber}>
            <PhoneIcon sx={classes.phoneIconStyles} />
            <Typography sx={classes.phoneTextStyles}>{phoneNumber}</Typography>
          </Box>
        </Box>
        {getFormattedQualityRating(qualityRating)}
      </Box>
    );
  };

  const getFormattedQualityRating = (rating: string) => {
    let chipText: string;
    let chipBackgroundColor: string;
    switch (rating) {
      case strings.highQualityRating:
        chipText = "High quality rating";
        chipBackgroundColor = "#D9EFE8";
        break;
      case strings.mediumQualityRating:
        chipText = "Medium quality rating";
        chipBackgroundColor = "#DBD9F7";
        break;
      default:
        chipText = "Low quality rating";
        chipBackgroundColor = "#FFD6DC";
        break;
    }
    return (
      <Chip label={chipText} sx={{ backgroundColor: chipBackgroundColor }} />
    );
  };

  const changeHandler = (event: any) => {
    props.setCampaignData({
      ...props.campaignData,
      from: {
        ...props.campaignData.from,
        error: "",
      },
    });
    setFromPhoneNumber({
      value: event.target.value,
      error: "",
    });
  };

  const isFromSectionValid = () => {
    let { value } = fromPhoneNumber;
    let isValid = true;
    if (!isTruthy(value)) {
      setFromPhoneNumber({
        ...fromPhoneNumber,
        error: "Please select the WhatsApp number",
      });
      isValid = false;
    }
    return isValid;
  };

  const onSaveHandler = () => {
    if (isFromSectionValid()) {
      props.setCampaignData({
        ...props.campaignData,
        from: {
          value: fromPhoneNumber.value,
          error: "",
        },
      });
      setIsEditMode(false);
    }
  };

  const onCancelHandler = () => {
    const campaignDataFromObject = { ...props.campaignData.from, error: "" };
    setFromPhoneNumber(campaignDataFromObject);
    props.setCampaignData({
      ...props.campaignData,
      from: campaignDataFromObject,
    });
    setIsEditMode(false);
  };

  const getSaveButton = () => {
    return (
      <CustomButton
        id="whatsapp_from_save_button"
        label="Save"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onSaveHandler();
        }}
      />
    );
  };

  const getCancelButton = () => {
    return (
      <CustomButton
        id="whatsapp_from_cancel_button"
        label="Cancel"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onCancelHandler();
        }}
      />
    );
  };

  const getEditModeHeading = () => {
    return (
      <>
        <Typography sx={classes.headerLabel}>From</Typography>
        <Typography sx={classes.headerSubDescription}>
          Who is sending this WhatsApp message?
        </Typography>
      </>
    );
  };

  const getViewModeHeading = () => {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Typography sx={classes.headerLabel}>From</Typography>
          <Typography ml={0.4} sx={classes.star}>
            *
          </Typography>
        </Box>
        {isTruthy(props.campaignData.from.value) ? (
          <Typography sx={classes.headerSubDescription}>
            {props.campaignData.from.value.verified_name} &#x2022;{" "}
            <Typography sx={{ wordBreak: "break-word" }} component={"span"}>
              {props.campaignData.from.value.display_phone_number}
            </Typography>
          </Typography>
        ) : (
          <Typography sx={classes.headerSubDescription}>
            The WhatsApp Business account from which the message will be sent.
          </Typography>
        )}
      </>
    );
  };

  const getEditFromButton = () => {
    return (
      <CustomButton
        id="whatsapp_campaign_from_edit_button"
        label={
          isTruthy(props.campaignData.from.value)
            ? "Edit Number"
            : "Select Number"
        }
        onClick={() => setIsEditMode(true)}
        customClasses={classes.buttonStyles}
      />
    );
  };

  const getEditModeInput = () => {
    return (
      <>
        <InputLabel
          id="whatsapp_phone_number_selection"
          sx={classes.inputLabel}
          shrink
        >
          WhatsApp Number{" "}
          <Box ml={0.4} sx={classes.star}>
            *
          </Box>
        </InputLabel>
        <Select
          id="whatsapp_from_select_input"
          name="from"
          value={fromPhoneNumber.value}
          error={fromPhoneNumber.error}
          renderValue={(value) =>
            isTruthy(value) ? (
              getCustomizedFromDetailItem(
                fromPhoneNumber.value.verified_name,
                fromPhoneNumber.value.display_phone_number,
                fromPhoneNumber.value.quality_rating
              )
            ) : (
              <Typography sx={classes.placeholderText}>
                Select WhatsApp number
              </Typography>
            )
          }
          onChange={changeHandler}
          sx={classes.inputFieldStyles}
        >
          {props.whatsAppFromDetails?.map((item: any) => (
            <MenuItem sx={classes.optionStyle} value={item}>
              {getCustomizedFromDetailItem(
                item.verified_name,
                item.display_phone_number,
                item.quality_rating
              )}
            </MenuItem>
          ))}
        </Select>
        {isTruthy(fromPhoneNumber.error) && (
          <FormHelperText error sx={classes.errorStyles}>
            {fromPhoneNumber.error}
          </FormHelperText>
        )}
      </>
    );
  };

  const getEditModeView = () => {
    return (
      <>
        <Box mb={1}>{getEditModeHeading()}</Box>
        <Box sx={classes.inputFieldContainer}>{getEditModeInput()}</Box>
        <Stack flexDirection="row" gap={1} mt={1}>
          {getSaveButton()}
          {getCancelButton()}
        </Stack>
      </>
    );
  };

  const getViewModeView = () => {
    return (
      <Box mb={1} sx={classes.viewModeBox}>
        <Box>{getViewModeHeading()}</Box>
        <Box>{getEditFromButton()}</Box>
      </Box>
    );
  };

  return <Box>{isEditMode ? getEditModeView() : getViewModeView()}</Box>;
};

export default FromSection;
