import {
  Checkbox,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CampaignsStyles from "screens/CampaignsNew/Campaigns/Campaigns.styles";
import { useHistory } from "react-router-dom";
import { CustomButton, CustomDialog, CustomInput } from "global/components";
import {
  getRetargetedGroupId,
  grtFollowUpGroupId,
} from "screens/CampaignsNew/Campaigns/Campaign.services";
import { Box } from "@mui/system";
import { useAppDispatch } from "utils/hooks";
import urls from "global/constants/UrlConstants";
import {
  insertRetargetingField,
  validateAddUserForm,
} from "screens/CampaignsNew/Campaigns/campaignTypeAndValidation";
import { isTruthy, openErrorNotification } from "helpers/methods";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import RetargetingImg from "assets/images/Retargeting.svg";
import notifiers from "global/constants/NotificationConstants";
import strings from "global/constants/StringConstants";
import { centerItemFlex } from "utils/styles";
import CustomInfoTooltip from "global/components/CustomInfoTooltip/CustomInfoTooltip";

interface RetargetProps {
  openedCount: number;
  clickedCount: number;
  isFollowUp: boolean;
  id: any;
}

const RetargetModal = (props: RetargetProps) => {
  const classes = CampaignsStyles;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [retargetingData, setRetargetingData] = useState(
    insertRetargetingField()
  );

  useEffect(() => {
    insertRetargetingField();
  }, [isModalVisible]);

  const groupType = retargetingData?.notOpened.value ? "FollowUp" : "Retarget";

  const handleClickedEmails = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRetargetingData({
      ...retargetingData,
      click: {
        ...retargetingData.click,
        value: e.target.checked,
        error: "",
      },
      notOpened: {
        ...retargetingData.notOpened,
        value: e.target.checked ? false : retargetingData.notOpened.value,
        error: "",
      },
    });
  };

  const handleOpenedEmails = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRetargetingData({
      ...retargetingData,
      open: {
        ...retargetingData.open,
        value: e.target.checked,
        error: "",
      },
      notOpened: {
        ...retargetingData.notOpened,
        value: e.target.checked ? false : retargetingData.notOpened.value,
        error: "",
      },
    });
  };

  const handleNotOpenedEmails = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRetargetingData({
      ...retargetingData,
      notOpened: {
        ...retargetingData.notOpened,
        value: e.target.checked,
        error: "",
      },
      open: {
        ...retargetingData.open,
        value: e.target.checked ? false : retargetingData.open.value,
        error: "",
      },
      click: {
        ...retargetingData.click,
        value: e.target.checked ? false : retargetingData.click.value,
        error: "",
      },
    });
  };
  const openCloseModalHandler = () => {
    setIsModalVisible(!isModalVisible);
    emptyState();
  };
  const handleGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (strings.groupNameRegex.test(e.target.value)) {
      setRetargetingData({
        ...retargetingData,
        [e.target.name]: {
          ...retargetingData[e.target.name],
          value: e.target.value,
          error: "",
        },
      });
    }
  };
  const OnFinish = async () => {
    try {
      if (handleValidation() && retargetingData.groupName.value.length < 50) {
        setLoading(true);
        const updatedRetargetingData = {
          ...retargetingData,
          groupType: {
            value: groupType,
          },
        };
        const response = retargetingData?.notOpened.value
          ? await grtFollowUpGroupId(
              props.id,
              retargetingData?.groupName.value.trim(),
              retargetingData?.notOpened.value
            )
          : await getRetargetedGroupId(
              props.id,
              retargetingData?.groupName.value.trim(),
              retargetingData?.open.value
            );
        setRetargetingData({ ...updatedRetargetingData, id: response.id });
        history.push(urls.createCampaignViewPath, {
          parentId: props.id,
          groupId: response.message,
          groupName: updatedRetargetingData.groupName.value,
          groupType: updatedRetargetingData.groupType.value,
          opened: updatedRetargetingData?.open.value,
        });
        setIsModalVisible(false);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      setIsModalVisible(true);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const emptyState = () => {
    setRetargetingData({
      groupName: {
        value: "",
        error: "",
      },
      groupType: {
        value: groupType,
        error: "",
      },
      open: {
        value: false,
        error: "",
      },
      click: {
        value: false,
        error: "",
      },
      notOpened: {
        value: false,
        error: "",
      },
    });
  };
  const getModalTitle = () => {
    return (
      <>
        <Box sx={classes.titleWrapper}>
          <Typography fontSize={25} fontWeight="bold">
            Retargeting / Follow-Up
          </Typography>
        </Box>
      </>
    );
  };

  const handleValidation = () => {
    const { isValid, errors }: { isValid: boolean; errors: any } =
      validateAddUserForm(retargetingData);
    setRetargetingData({ ...errors });
    return isValid;
  };

  const getBodyContent = () => {
    return (
      <>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box mb={3}>
            <CustomInput
              id="retarget_group_name_field"
              name="groupName"
              required
              placeHolder="Group name"
              label="Group Name"
              value={retargetingData.groupName.value}
              onChange={handleGroupName}
              propsToInputElement={{ maxLength: 50 }}
              error={
                !isTruthy(retargetingData.groupName.value) &&
                retargetingData.groupName.error
              }
            />
            {isTruthy(retargetingData.groupName.value) &&
              retargetingData.groupName.value.length >= 50 && (
                <FormHelperText error sx={classes.errorStyle}>
                  Group name must be less than 50 characters
                </FormHelperText>
              )}
          </Box>
        </Grid>
        {props.openedCount > 0 && (
          <Box>
            <Stack display={"flex"} direction={"row"}>
              <Box
                sx={{
                  ...centerItemFlex,
                  textAlign: "center",
                }}
              >
                <Typography mt={1} sx={classes.labelStyle}>
                  Target Audience <Box sx={classes.star}>*</Box>
                </Typography>
              </Box>
              <Box
                ml={1}
                pt={"5px"}
                sx={{
                  ...centerItemFlex,
                  textAlign: "center",
                }}
              >
                <CustomInfoTooltip
                  placement="top"
                  infoMessage="Reach out to those recipients who took an action on your previous campaign, such as clicking or opening"
                />
              </Box>
            </Stack>
            <Grid container>
              {props.clickedCount > 0 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"20px"}
                  >
                    <Box sx={classes.targetAudienceMainWrapper}>
                      <Box sx={classes.targetAudienceWrapper}>
                        <Checkbox
                          onChange={handleOpenedEmails}
                          name="open"
                          checked={retargetingData.open.value}
                        />
                        <Typography>Opened</Typography>
                      </Box>
                    </Box>
                    <Box sx={classes.targetAudienceMainWrapper}>
                      <Box sx={classes.targetAudienceWrapper}>
                        <Checkbox
                          onChange={handleClickedEmails}
                          name="click"
                          checked={retargetingData.click.value}
                        />
                        <Typography onChange={handleOpenedEmails}>
                          Clicked
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    container
                    spacing={2}
                    display="flex"
                    flexDirection={"column"}
                  >
                    <Box sx={classes.targetAudienceOpenWrapper} width="50%">
                      <Checkbox
                        onChange={handleOpenedEmails}
                        name="open"
                        checked={retargetingData.open.value}
                      />
                      <Typography>Opened</Typography>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )}
        {props.isFollowUp && (
          <>
            <Stack display={"flex"} direction={"row"}>
              <Box
                sx={{
                  ...centerItemFlex,
                  textAlign: "center",
                }}
              >
                <Typography mt={2} sx={classes.labelStyle}>
                  Follow-Up Audience <Box sx={classes.star}>*</Box>
                </Typography>
              </Box>
              <Box
                ml={1}
                pt={"8px"}
                sx={{
                  ...centerItemFlex,
                  textAlign: "center",
                }}
              >
                <CustomInfoTooltip
                  placement="bottom"
                  infoMessage="Send a follow-up campaign to recipients who did not open your previous campaign"
                />
              </Box>
            </Stack>

            <Grid container spacing={2} display="flex" flexDirection={"column"}>
              <Box sx={classes.targetAudienceOpenWrapper} width="48%">
                <Checkbox
                  onChange={handleNotOpenedEmails}
                  name="notOpened"
                  checked={retargetingData.notOpened.value}
                />
                <Typography>Not-Opened</Typography>
              </Box>
            </Grid>
          </>
        )}
        <Box marginLeft={"15px"}>
          <FormHelperText error sx={classes.errorStyle}>
            {retargetingData.open.value === false
              ? retargetingData.open.error
              : ""}
          </FormHelperText>
        </Box>
      </>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={RetargetingImg} alt="" />
      </Box>
    );
  };

  const getFooter = () => {
    return (
      <>
        <Box sx={classes.footerWrapper}>
          <CustomButton
            id="retarget_cancel_button"
            onClick={openCloseModalHandler}
            customClasses={classes.buttonWhiteBg}
            label="Cancel"
          />
          <CustomButton
            id="retarget_submit_button"
            onClick={OnFinish}
            label="Submit"
            disabled={isLoading}
            loading={isLoading}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Box>
            <CustomButton
              id="stats_retarget_button"
              label="Retarget"
              onClick={openCloseModalHandler}
              customClasses={{ width: "100px" }}
            />
          </Box>
        </Grid>
        <CustomDialog
          dialogHeaderContent={dialogHeaderContent()}
          isDialogOpen={isModalVisible}
          width="500px"
          handleDialogClose={openCloseModalHandler}
          dialogTitleContent={getModalTitle()}
          dialogBodyContent={getBodyContent()}
          dialogFooterContent={getFooter()}
          closable
          closeButtonVisibility
        />
      </Grid>

      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default RetargetModal;
