import strings from "global/constants/StringConstants";
import { GlobalInterval } from "models/interfaces";

export const getInitialCampaignFilterState = (
  campaignCategory: string,
  status: string,
  page: number,
  searchText: string,
  perPageData: number,
  interval: GlobalInterval
) => {
  return {
    status,
    interval,
    campaignCategory,
    searchText,
    perPageData,
    page,
  };
};

export const options = [
  {
    id: 0,
    label: "Today",
    value: 0,
  },
  {
    id: 2,
    label: "Yesterday",
    value: 1,
  },
  {
    id: 3,
    label: "Last 4 Days",
    value: 4,
  },
  {
    id: 4,
    label: "Last 1 Week",
    value: 7,
  },
  {
    id: 5,
    label: "Last 2 Weeks",
    value: 14,
  },
  {
    id: 6,
    label: "Last 1 Month",
    value: 30,
  },
  {
    id: 7,
    label: "Last 45 days",
    value: 45,
  },
];

export const campaignTourSteps = [
  {
    target: "#campaigns_status_tab",
    content:
      "This section compiles the statuses of various campaign stages. Users can tailor their view according to their needs: \n\n1. Completed: Lists all successfully finished campaigns. \n2. Submitted: Campaigns that have been executed initially appear here, transitioning to the completed stage after a certain duration.\n3. Draft: Houses saved and pending campaigns.\n4. All: List of all campaigns",
    disableOverlayClose: true,
  },
  {
    target: "#campaigns_interval_dropdown",
    content: "This is the same interval as present in the Dashboard",
    disableOverlayClose: true,
  },
  {
    target: "#campaigns_category_dropdown",
    content:
      "Users can opt to view their individual campaigns, their team's campaigns, or all campaigns collectively",
    disableOverlayClose: true,
  },
  {
    target: "#campaigns_search_Field_container",
    content: "Campaigns can be searched here",
    disableOverlayClose: true,
  },
];

export const campaignStatusOption = [
  {
    id: 0,
    label: "All Campaigns",
    value: "all",
  },

  {
    id: 2,
    label: "My Campaigns",
    value: "my",
  },
  {
    id: 3,
    label: "Team's Campaign",
    value: "team",
  },
];

export const campaignerHeader = [
  {
    name: "Campaign Name",
    field: "name",
    sorting: true,
  },
  {
    name: "Audience",
    field: "CampaignGroups",
    sorting: false,
  },
  {
    name: "Campaign Subject",
    field: "subject",
    sorting: true,
  },
  {
    name: "Submitted By",
    field: "owner",
    sorting: true,
  },

  {
    name: "Sent On",
    field: "SentOn",
    sorting: false,
  },

  {
    name: "Campaign Stats",
    field: "CampaignStats",
    align: "center",
    sorting: false,
  },
];

export const campaignerHeaderSubmitted = [
  {
    name: "Campaign Name",
    field: "name",
    sorting: true,
  },
  {
    name: "Audience",
    field: "CampaignGroups",
    sorting: false,
  },
  {
    name: "Campaign Subject",
    field: "subject",
    sorting: true,
  },
  {
    name: "Submitted By",
    field: "owner",
    sorting: true,
  },

  {
    name: "Scheduled On",
    field: "ScheduledOn",
    sorting: false,
  },
  {
    name: "Action",
    field: "Action",
    align: "center",
    sorting: false,
  },
];

export const campaignerHeaderDraft = [
  {
    name: "Campaign Name",
    field: "name",
    sorting: true,
  },
  {
    name: "Audience",
    field: "CampaignGroups",
    sorting: false,
  },
  {
    name: "Campaign Subject",
    field: "subject",
    sorting: true,
  },
  {
    name: "Submitted By",
    field: "owner",
    sorting: false,
  },
  {
    name: "Action",
    field: "Action",
    align: "center",
    sorting: false,
  },
];

export const campaignerHeaderAll = [
  {
    name: "Campaign Name",
    field: "name",
    sorting: true,
  },
  {
    name: "Audience",
    field: "CampaignGroups",
    sorting: false,
  },
  {
    name: "Campaign Subject",
    field: "subject",
    sorting: true,
  },
  {
    name: "Submitted By",
    field: "owner",
    sorting: true,
  },

  {
    name: "Created On",
    field: "SentOn",
    sorting: false,
  },
  {
    name: "Campaign Status",
    field: "status",
    sorting: true,
  },
  {
    name: "Action",
    field: "Action",
    align: "center",
    sorting: false,
  },
];

export const campaignStatusOptionUser = [
  {
    id: 0,
    label: "All Campaigns",
    value: "all",
  },
  {
    id: 2,
    label: "My Campaigns",
    value: "my",
  },
];

export const campaignTabContent = [
  {
    label: strings.Completed,
    count: 0,
  },
  {
    label: strings.Submitted,
    count: 0,
  },
  {
    label: strings.Draft,
    count: 0,
  },
  {
    label: strings.All,
    count: 0,
  },
];
