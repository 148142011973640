import strings from "global/constants/StringConstants";

export const formatTextHandler = (
  text: string,
  markdownSymbol: string,
  startIndex: number,
  endIndex: number,
  setEndIndex: Function
) => {
  const leftString = text.substring(0, startIndex);
  const middleString = text.substring(startIndex, endIndex);
  const rightString = text.substring(endIndex, text.length);
  const manipulatedString = `${leftString}${markdownSymbol}${middleString}${markdownSymbol}${rightString}`;
  setEndIndex((prevIndex: number) => prevIndex + 2);
  return manipulatedString;
};

export const textFormationData = [
  {
    formattingType: strings.bold,
    markdownSymbol: strings.boldMarkdownSymbol,
  },
  {
    formattingType: strings.italic,
    markdownSymbol: strings.italicMarkdownSymbol,
  },
  {
    formattingType: strings.strikethrough,
    markdownSymbol: strings.strikethroughMarkdownSymbol,
  },
];
