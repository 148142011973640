import { Typography } from "@mui/material";
import React from "react";
import { boldFont } from "utils/styles";
import Comments from "screens/Tickets/ViewTicket/Components/Comments";

interface CustomProps {
  ticketDetails: any;
  isLoading: boolean;
  setIsLoading: Function;
  ticketId: string;
  fetchTicketById: Function;
}

const Activity = (props: CustomProps) => {
  const getActivityBody = () => {
    return (
      <>
        <Typography
          sx={{
            ...boldFont,
          }}
          pl={2}
        >
          Comments
        </Typography>
        <Comments
          ticketDetails={props.ticketDetails}
          isLoading={props.isLoading}
          setIsLoading={props.setIsLoading}
          ticketId={props.ticketId}
          fetchTicketById={props.fetchTicketById}
        />
      </>
    );
  };

  return <>{getActivityBody()}</>;
};

export default Activity;
