import { Box, Typography } from "@mui/material";
import { NewCustomButton } from "global/components";
import campaignDetailsSectionsStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/CampaignDetailsSections/CampaignDetailsSections.style";
import { MailingListTable } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/MailingListTable";
import { useState } from "react";

interface CustomProps {
  setCampaignData: Function;
  campaignData: any;
  parentId: string;
  setActiveStep: Function;
}

const DesignSection = (props: CustomProps) => {
  const classes = campaignDetailsSectionsStyles;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const onChangeHandler = (event: any) => {
    props.setCampaignData({
      ...props.campaignData,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const editModeView = () => {
    return (
      <>
        <Box mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography sx={classes.headerLabel}>Design</Typography>
            <Typography sx={classes.headerSubDescription}>
              Design the Content for your email.
            </Typography>
          </Box>
        </Box>
        {!props.parentId && (
          <MailingListTable
            campaignData={props.campaignData}
            setCampaignData={props.setCampaignData}
            allowedEmailCount={1}
          />
        )}
      </>
    );
  };

  const viewModeView = () => {
    return (
      <Box mb={1} sx={classes.viewModeBox}>
        <Box>
          <Typography sx={classes.headerLabel}>Design</Typography>
          <Typography sx={classes.headerSubDescription}></Typography>
        </Box>
        <Box>
          <NewCustomButton
            id="design_edit_button"
            label="Edit Design"
            onClick={() =>
              props.setActiveStep((prevStep: number) => prevStep - 1)
            }
            customClasses={classes.buttonStyles}
          />
        </Box>
      </Box>
    );
  };

  return <Box>{isEditMode ? editModeView() : viewModeView()}</Box>;
};

export default DesignSection;
