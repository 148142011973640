import {
  Box,
  Grid,
  Typography,
  InputAdornment,
  Stack,
  Card,
  Popover,
} from "@mui/material";
import { CustomAppHeader, CustomButton, CustomInput } from "global/components";
import classes from "screens/Templates/Template.styles";
import {
  convertESTtoUserLocalDateAndTime,
  debounceEventHandler,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import strings from "global/constants/StringConstants";
import { GridSearchIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CustomTabs from "global/components/CustomTabs/CustomTabs";
import {
  deleteCampaignTemplate,
  getCampaignTemplateNew,
  updateCampaignTemplatePermission,
} from "screens/Templates/TemplatePageService";
import notifiers from "global/constants/NotificationConstants";
import createNewTemplate from "assets/icons/createNewTemplate.svg";
import DeleteTemplateModel from "screens/Templates/components/DeleteTemplateModel";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { HandleTemplateType } from "models/interfaces";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTitle } from "utils/UseTitle";
import urls from "global/constants/UrlConstants";
import history from "utils/history";
import { useAppSelector } from "utils/hooks";
import { selectEmail } from "redux/authSlice";
import NoData from "assets/images/tableNoData.svg";

const templateTabContent = [
  {
    label: strings.allTemplates,
    count: 0,
  },
  {
    label: strings.myTemplates,
    count: 0,
  },
];

const TemplatesNew = () => {
  useTitle(strings.TemplatesTitle);
  const userId = useAppSelector(selectEmail);
  const [searchText, setSearchText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(strings.allTemplates);
  const [displayTemplates, setDisplayTemplates] = useState<
    HandleTemplateType[]
  >([]);
  const [fixData, setFixData] = useState<HandleTemplateType[]>([]);
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [deleteTemplateInfo, setDeleteTemplateInfo] = useState({
    templateId: "",
    TemplateName: "",
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOnHoverDisplay, setIsOnHoverDisplay] = useState<number>(-1);

  useEffect(() => {
    filterTemplatesBySearchKeywords(fixData);
  }, [searchText.trim()]);

  useEffect(() => {
    fetchCampaignTemplate();
  }, [status]);

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOnHoverDisplay(-1);
  };
  const fetchCampaignTemplate = async () => {
    try {
      setIsLoading(true);
      const templateResult = await getCampaignTemplateNew(status);
      setFixData(templateResult);
      filterTemplatesBySearchKeywords(templateResult);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const filterTemplatesBySearchKeywords = (templates: HandleTemplateType[]) => {
    const result = templates.filter((item: any) => {
      return item.name.toLowerCase().includes(searchText.trim().toLowerCase());
    });
    setDisplayTemplates(result);
  };

  const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value.trim());
  };

  const handleTabs = (value: string) => {
    setStatus(value);
  };

  const templateCategoryTabs = () => {
    return (
      <Stack
        sx={classes.tabsStack}
        direction={{ lg: "row", xs: "column" }}
        justifyContent="space-between"
      >
        <CustomTabs
          changeValue={handleTabs}
          selected={status}
          tabConfig={templateTabContent}
        />
      </Stack>
    );
  };

  const deleteTemplate = async (campaignTemplateId: any) => {
    try {
      setIsLoading(true);
      const response = await deleteCampaignTemplate(campaignTemplateId);
      openSuccessNotification(response.message);
      setOpenModel(false);
      await fetchCampaignTemplate();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteModel = (templateId: any, TemplateName: any) => {
    setDeleteTemplateInfo({
      templateId,
      TemplateName,
    });
    setAnchorEl(null);
    setOpenModel(true);
  };

  const handlePermission = async (isPublic: boolean, index: number) => {
    setIsLoading(true);
    let users: string[] = [];
    if (!isPublic) {
      users = ["ALL"];
    } else {
      users = [];
    }
    let template = displayTemplates[index];
    template.users = users;
    try {
      setAnchorEl(null);
      await updateCampaignTemplatePermission(users, template.name);
      await fetchCampaignTemplate();
      openSuccessNotification(
        `Template visibility has been changed to ${
          !isPublic ? "Public" : "Private"
        }!`
      );
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getAddTemplateCard = () => {
    return (
      <Grid id="templates_add_card" item xs={12} sm={6} lg={3}>
        <Box m={3} sx={classes.addTemplateGridItem}>
          <Stack
            direction="column"
            alignItems="center"
            height="100%"
            justifyContent="center"
          >
            <Box mb={2} component="img" src={createNewTemplate} alt="" />
            <CustomButton
              label="New Template"
              onClick={() => {
                history.push(`${urls.templateViewPath}/add`);
              }}
              customClasses={classes.newTemplateBtn}
              id="template_add_button"
            />
          </Stack>
        </Box>
      </Grid>
    );
  };

  const templatesComparisonFunction = (
    a: HandleTemplateType,
    b: HandleTemplateType
  ) => {
    if (a.category === "System" && b.category === "User") {
      return -1;
    } else if (b.category === "System" && a.category === "User") {
      return 1;
    } else {
      return 0;
    }
  };

  const getTemplatesCard = () => {
    return (
      <>
        {displayTemplates
          .sort(templatesComparisonFunction)
          .map((template: HandleTemplateType, index: number) => {
            let isPublic =
              template.hasOwnProperty("users") &&
              template?.users?.includes("ALL");
            let isUserTemplate = template.owner === userId;
            return (
              <Grid item xs={12} sm={6} lg={3}>
                <Box
                  m={3}
                  sx={classes.templateGridItem}
                  onMouseEnter={() => {
                    setIsOnHoverDisplay(index);
                  }}
                  onMouseLeave={() => {
                    setIsOnHoverDisplay(-1);
                  }}
                  onTouchStart={() => {
                    setIsOnHoverDisplay(index);
                  }}
                  onTouchCancel={() => {
                    setIsOnHoverDisplay(-1);
                  }}
                >
                  {(isPublic || template.category === "System") && (
                    <Box sx={classes.ribbon}>
                      <Box component="span" sx={classes.ribbonText}>
                        {template.category === "System" ? "FREE" : "PUBLIC"}
                      </Box>
                    </Box>
                  )}
                  <Box key={index} sx={classes.templateCard}>
                    <Box sx={classes.templateCardHeader}>
                      {template.category !== "System" && isUserTemplate && (
                        <>
                          <Box
                            component={"button"}
                            sx={classes.moreButton}
                            onClick={handleMoreClick}
                            id={`popover${index}`}
                          >
                            <MoreHorizIcon fontSize="medium" />
                          </Box>
                          <Popover
                            open={
                              anchorEl
                                ? anchorEl?.id == `popover${index}`
                                : false
                            }
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <Card sx={classes.cardToggleContent}>
                              <Typography
                                sx={classes.ToggleContent}
                                onClick={() =>
                                  handleDeleteModel(template.id, template.name)
                                }
                              >
                                Delete
                              </Typography>
                              <Box
                                onClick={() => {
                                  handlePermission(isPublic, index);
                                }}
                              >
                                <Typography
                                  sx={[
                                    classes.ToggleContent,
                                    classes.ToggleContentColor,
                                  ]}
                                >
                                  {!isPublic
                                    ? "Mark as Public"
                                    : "Mark as Private"}
                                </Typography>
                              </Box>
                            </Card>
                          </Popover>
                        </>
                      )}
                    </Box>
                    <Box px={2} sx={classes.templateImgWrapper}>
                      <img src={template.image} />
                    </Box>
                  </Box>
                  {isOnHoverDisplay === index && (
                    <Box sx={classes.templateHover}>
                      <Typography sx={classes.templateNameStyles}>
                        {template.name.substring(0, 20)}
                        {template.name.length > 20 && "..."}
                      </Typography>
                      {template?.createdOn && (
                        <Typography sx={classes.createdOnStyles}>
                          Created On:{" "}
                          {convertESTtoUserLocalDateAndTime(
                            template?.createdOn,
                            "LL"
                          )}
                        </Typography>
                      )}
                      {template?.updatedOn && (
                        <Typography sx={classes.updatedOnStyles}>
                          Updated On:{" "}
                          {convertESTtoUserLocalDateAndTime(
                            template?.updatedOn,
                            "LL"
                          )}
                        </Typography>
                      )}
                      <CustomButton
                        id="templates_edit_button"
                        label="Edit"
                        onClick={() => {
                          history.push(
                            `${urls.templateViewPath}/edit?id=${template.id}`
                          );
                        }}
                        customClasses={classes.editBtn}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            );
          })}
      </>
    );
  };

  const noTemplatesMessageBox = () => {
    return (
      <Box textAlign="center" sx={classes.noTemplatesMainBox}>
        <Box
          component="img"
          src={NoData}
          overflow="auto"
          height="120px"
          width="100%"
        />
        <Typography sx={classes.mediumFonts}>
          We've got nothing for you, sorry!
        </Typography>
      </Box>
    );
  };

  const templatePage = () => {
    return (
      <Box>
        <CustomAppHeader className={classes.headerStyle}>
          <Grid container sx={classes.headerGridContainer} mt={5} mb={2}>
            <Grid
              item
              xs={12}
              sm={8}
              xl={10}
              style={{
                textAlign: "center",
                paddingTop: "0px",
              }}
            >
              <Typography sx={classes.mainHeading}>Templates</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              xl={2}
              display="flex"
              justifyContent={{ md: "flex-end" }}
            >
              <CustomInput
                id="templates_search_field"
                placeHolder="Search template"
                onChange={debounceEventHandler(
                  handleSearchOnChange,
                  strings.SEARCH_TIME_OUT
                )}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GridSearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </CustomAppHeader>
        <Box>
          <Box
            id="templates_category_tabs"
            sx={{
              paddingLeft: "10px",
            }}
            justifyContent={{
              xs: "center",
              sm: "flex-end",
            }}
          >
            {templateCategoryTabs()}
          </Box>
          <Grid id="templates_display_area" container px={4}>
            {status === strings.allTemplates && getAddTemplateCard()}
            {getTemplatesCard()}
          </Grid>
          {status === strings.myTemplates &&
            displayTemplates.length === 0 &&
            noTemplatesMessageBox()}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <DeleteTemplateModel
        openModel={openModel}
        setOpenModel={setOpenModel}
        deleteTemplate={deleteTemplate}
        deleteTemplateInfo={deleteTemplateInfo}
      />
      {templatePage()}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};
export default TemplatesNew;
