export class EmailRquest {
  fromId: string = "";
  application: string = "";
  host: string = "";
  env: string = "";
  mailTo: string[] = [];
  mailCc: string[] = [];
  mailBcc: string[] = [];
  subject: string = "";
  content: string | undefined;
  template: string = "";
  contentType: string = "";
  data: Object | undefined;
  displayName: string = "";
  scheduleTime: string = "";
}

export class BulkEmailRequest extends EmailRquest {
  groups: any;
  requestCount: number | undefined;
  successCount: number | undefined;
  sentCount: number | undefined;
  failureCount: number | undefined;
  openCount: number | undefined;
  clickCount: number | undefined;
  unsubscribedCount: number | undefined;
  unconfirmedCount: number | undefined;
  status: string = "";
  id: string | undefined;
  owner: string = "";
  attachments: Object | undefined;
  name: string | undefined;
  parentId: string | undefined;
  target: string | undefined;
  testIds: Array<string> = [];
}

export interface Groups {
  id: string;
  name: string;
}

export interface CampaignCounts {
  Clicked: 0;
  Failed: 0;
  InProgress: 0;
  Opened: 0;
  Requested: 0;
  Success: 0;
  Unsubscribed: 0;
  Unconfirmed: 0;
}
