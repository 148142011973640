import {
  boldFont,
  buttonWhiteBg,
  getRelativeFontSize,
  infoTextColor,
  mediumFont,
  pinkDarkColor,
  primaryHeadingColor,
  pureWhiteColor,
  purplePrimaryColor,
  regularFont,
  theme,
} from "utils/styles";

const BillingUsageStyles = {
  heading: {
    ...boldFont,
    fontSize: getRelativeFontSize(8),
    color: "#113311",
  },
  dialogTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "10px",
    textAlign: "center",
  },
  titleRight: {
    color: " rgba(0,0,0,.85)",
    ...boldFont,
    fontSize: getRelativeFontSize(14),
  },
  dialogContent: {
    fontSize: getRelativeFontSize(2),
    ...mediumFont,

    "& span": {
      ...boldFont,
    },
  },
  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  defaultCardTerms: {
    ...regularFont,
    fontSize: getRelativeFontSize(),
    margin: "0 10px",
    wordWrap: "break-word",
  },
  dialogFooter: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "10px",
    "& button": {
      width: "120px",
    },
  },
  pricingDescriptionText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#666666",
  },
  mainCard: {
    width: "auto",
    borderRadius: "10px",
    height: "100px",
    border: "1px solid #E7E7E7",
    cursor: "pointer",
    "&.MuiCard-root": {
      boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.07)",
    },
    padding: "5px",
  },
  mainCardContent: {
    color: "#5B5B5B",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "16px",
    textTransform: "capitalize",
  },
  mainCardContentSection: {
    color: "#434343",
    fontSize: { xl: "23px", lg: "17px" },
    display: "flex",
    alignItems: "center",
    lineHeight: "13px",
    fontWeight: 700,
    fontStyle: "normal",
    width: "100%",
  },
  billingDetail: {
    background: "#FFFFFF",
    boxShadow: " 0px 8px 30px rgba(0, 0, 0, 0.07)",
    borderRadius: "10px",
    width: "auto",
    padding: "5px",
    maxHeight: "465px",
    height: "465px",
    overflow: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  billingDetailBox: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    padding: "18px",
  },
  cardPrimary: {
    color: pinkDarkColor,
    fontSize: "14px",
    padding: " 0",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    marginLeft: "10px",
    fontWeight: "bold",
  },
  actionTab: {
    border: "1px solid",
    borderColor: infoTextColor,
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: infoTextColor,
    marginRight: "10px",
    background: pureWhiteColor,
    cursor: "pointer",
  },
  cardBox: {
    padding: "15px 30px",
    gap: "5px",
    display: "flex",
    flexDirection: "column",
  },
  planContainer1: {
    background: "#FFFFFF",
    boxShadow: " 0px 8px 30px rgba(0, 0, 0, 0.07)",
    borderRadius: "10px",
    height: "100%",
  },
  planCard: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "9px 9px 1px 0px",
    textAlign: "center",
    background: "#ADC804",
    color: "#1A0224",
  },
  yourPlan: {
    fontSize: "15px",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "25px",
  },
  planName: {
    ...boldFont,
    fontSize: getRelativeFontSize(4),
    textAlign: "center",
  },
  contentHeading: {
    ...mediumFont,
    textAlign: "center",
    color: "#666666",
  },
  featureHeading: {
    ...mediumFont,
    textAlign: "center",
    color: "#666666",
  },
  featureContent: {
    display: "flex",
    gap: "10px",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  featureContentData: {
    ...mediumFont,
    fontSize: { xl: "16px", lg: "14px", md: "16px", sm: "16px", xs: "11px" },
    color: "#666666",
  },
  noPaymentText: {
    fontFamily: "Source Sans 3",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "23px",
    textAlign: "center",
    color: "#545454",
    marginBottom: "5px",
  },
  noPaymentTextSubContent: {
    fontFamily: "Source Sans 3",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "23px",
    textAlign: "center",
    color: "#ADADAD",
  },
  purpleHeadingText: {
    color: "#462682",
    fontSize: getRelativeFontSize(10),
    ...boldFont,
  },
  mediumText: {
    color: "#212121",
    fontSize: getRelativeFontSize(6),
    ...mediumFont,
    justifyContent: "center",
    textAlign: "center",
  },
  addTagChip: {
    marginLeft: "5px",
    marginTop: "8px",
    borderRadius: "5px",
    ...mediumFont,
    fontSize: "15px",
    backgroundColor: "#fcf5ff",
  },
  mainContainer: {
    marginTop: "20px",
    width: "100%",
  },
  gridItemStyles: {
    padding: "20px",
  },
  borderBox: {
    padding: "20px 30px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #B7B7B7",
    borderRadius: "10px",
  },
  buttonContainer: { margin: "30px 0" },
  buttonStyles: { width: "100%" },
  inputLabels: {
    margin: "10px 0",
  },
  cardElementStyle1: {
    marginTop: "10px",
    border: "#dad3dd solid 1px",
    borderRadius: "9px",
    padding: "12px",
    backgroundColor: "#fff",
  },
  headingContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: { flexDirection: "column" },
  },
  backIconBox: { marginLeft: "-12px", display: "flex", alignItems: "center" },
  pageTitle: {
    fontSize: getRelativeFontSize(6),
    ...boldFont,
    display: "flex",
    alignItems: "center",
    color: primaryHeadingColor,
  },
} as const;

export default BillingUsageStyles;
