import { Box, Grid, Typography, InputAdornment } from "@mui/material";
import StepOneStyles from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/StepOne/StepOne.styles";
import { CustomAppHeader, CustomButton, CustomInput } from "global/components";
import { stepOneValidations } from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/CreateWhatsAppCampaignHelpers";

interface CustomProps {
  setActiveStep: Function;
  campaignData: any;
  setCampaignData: Function;
}

const StepOne = (props: CustomProps) => {
  const classes = StepOneStyles;

  const nextStepHandler = () => {
    const { isValid, errorLadenState } = stepOneValidations(props.campaignData);
    props.setCampaignData({ ...errorLadenState });
    if (isValid) {
      props.setActiveStep(2);
    }
  };

  const onChangeHandler = (event: any) => {
    props.setCampaignData({
      ...props.campaignData,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const getStepOneForm = () => {
    return (
      <>
        <CustomInput
          id="whatsapp_create_campaign_name_field"
          placeHolder="Enter WhatsApp Campaign Name"
          label="Campaign Name"
          name="name"
          type="text"
          required
          propsToInputElement={{ maxLength: 60 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span>{props.campaignData.name.value.length} / 60</span>
              </InputAdornment>
            ),
          }}
          onChange={onChangeHandler}
          value={props.campaignData.name.value}
          error={props.campaignData.name.error}
        />
      </>
    );
  };

  const getNextButton = () => {
    return (
      <Box width="150px">
        <CustomButton
          id="whatsapp_campaign_step1_next_button"
          label="Next"
          onClick={() => nextStepHandler()}
        />
      </Box>
    );
  };

  const getStepOneContent = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box my={2}>
            <Typography sx={classes.regularFonts}>
              Maintain subscriber involvement by conveying your latest updates,
              showcasing your top-selling items, or disclosing an impending
              event by creating a WhatsApp campaign.
            </Typography>
          </Box>
          <Box mb={2}>{getStepOneForm()}</Box>
          <Box mb={2}>{getNextButton()}</Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <CustomAppHeader className={classes.headerSection}>
        <Typography sx={classes.pageTitle}>Create WhatsApp Campaign</Typography>
      </CustomAppHeader>
      <Box sx={classes.mainContainer}>{getStepOneContent()}</Box>
    </>
  );
};

export default StepOne;
