import urls from "global/constants/UrlConstants";
import { GlobalInterval } from "models/interfaces";
import {
  getCallParams,
  makeCall,
  getSearchedCallParams,
  makeCallFile,
  getSortingSearchedCallParams,
} from "utils/service";

export const getRetargetedCampaigns = async (id: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(
      `${urls.campaignActionByCampaignId}/${id}`,
      callParams
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCampaignsChildByParentId = async (id: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(`${urls.campaignsChild}/${id}`, callParams);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRetargetedGroupId = async (
  campaignId: string,
  groupName: string,
  opened: boolean
) => {
  try {
    const callParams = await getCallParams("POST", groupName);
    const response = await makeCall(
      `${urls.retargetedGroup}/${campaignId}/group/Retarget/${opened}`,
      callParams
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const grtFollowUpGroupId = async (
  campaignId: string,
  groupName: string,
  followUp: boolean
) => {
  try {
    const callParams = await getCallParams("POST", groupName);
    const response = await makeCall(
      `${urls.retargetedGroup}/${campaignId}/group/FollowUp/${followUp}`,
      callParams
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getClickActionDetails = async (campaignId: string) => {
  try {
    const callParams = await getCallParams("POST");
    const response = await makeCall(
      `${urls.campaignHistoryEmailEvent}/action/details/${campaignId}`,
      callParams
    );
    return response;
  } catch (error) {
    throw error;
  }
};
// Campaigns page
export const deleteEmailRequest = async (requestId: any) => {
  try {
    const callParams = await getCallParams("DELETE");
    const url = urls.deleteBulkEmailRequest + `/${requestId}`;
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getBulkEmailEventsById = async (requestId: any) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.campaignActionByCampaignId}/${requestId}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCamapignActionsByStatus = async (
  campaignId: string,
  action: string,
  pageNumber: number,
  limit: number
) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.campaignActionByStatus}/${campaignId}/${action}/page/${pageNumber}/limit/${limit}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCamapignActionsByStatusCount = async (
  campaignId: string,
  action: string
) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.campaignActionByStatus}/${campaignId}/${action}/count`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignFailDataForCampaignCount = async (
  campaignId: string
) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.campaignActionByStatus}/fail/${campaignId}/count`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignFailDataForCampaign = async (
  campaignId: string,
  pageNumber: number,
  limit: number
) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.campaignActionByStatus}/fail/${campaignId}/page/${pageNumber}/limit/${limit}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const downloadReport = async (requestId: any) => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCallFile(
      `${urls.DOWNLOAD_REPORT}/${requestId}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const downloadReportOpenedAndClicked = async (
  requestId: any,
  actionType: any
) => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCallFile(
      `${urls.campaignActionByStatusExport}/${requestId}/${actionType}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const downloadOpenedClickReport = async (
  requestId: any,
  userTimeZone: any,
  actions: string[]
) => {
  try {
    const body = {
      userTimeZone: userTimeZone,
      userActions: actions,
    };
    const callParams = await getCallParams("POST", body);
    const response = await makeCall(
      `${urls.downloadOpenAndClickedReport}/${requestId}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignsCountByDates = async (
  status: any,
  interval: GlobalInterval,
  campaigns: any
) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.campaignsCountInterval}/${campaigns}/${status}/count/dates/${interval.fromDate}/${interval.toDate}`,
      callParams
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignsByDates = async (
  status: any,
  interval: GlobalInterval,
  campaigns: any,
  page: number,
  limit?: number,
  sortConfig?: any
) => {
  try {
    const callParams = await getCallParams("POST", sortConfig);
    const response: any = await makeCall(
      urls.bulkEmailRequestsByStatusAndInterval +
        "/" +
        campaigns +
        "/" +
        "status" +
        "/" +
        status +
        "/dates" +
        "/" +
        interval.fromDate +
        "/" +
        interval.toDate +
        "/" +
        "page/" +
        page +
        "/" +
        "limit/" +
        limit,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSearchedCampaignsCountsByDates = async (
  campaigns: any,
  status: string,
  interval: GlobalInterval,
  searchText: string
) => {
  try {
    const callParams = await getSearchedCallParams("POST", searchText);

    const response: any = await makeCall(
      urls.bulkEmailRequestsByStatusAndInterval +
        `/${campaigns}/count/status/` +
        status +
        `/dates/` +
        interval.fromDate +
        "/" +
        interval.toDate +
        `/search`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSearchedCampaignsByDates = async (
  campaigns: any,
  status: string,
  interval: GlobalInterval,
  searchText: string,
  page: number,
  limit: number,
  entitySorters?: any
) => {
  try {
    const body = { entitySorters, searchText };
    const callParams = await getSortingSearchedCallParams("POST", body);
    const response: any = await makeCall(
      urls.bulkEmailRequestsByStatusAndInterval +
        "/" +
        campaigns +
        "/status/" +
        status +
        "/dates" +
        "/" +
        interval.fromDate +
        "/" +
        interval.toDate +
        "/" +
        "search/page/" +
        page +
        "/" +
        "limit/" +
        limit,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const downloadReportCampaignDetailByDates = async (
  status: any,
  interval: GlobalInterval,
  campaigns: any
) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.bulkEmailRequestsByStatusAndInterval}/download` +
        "/" +
        campaigns +
        "/" +
        "status" +
        "/" +
        status +
        "/dates" +
        "/" +
        interval.fromDate +
        "/" +
        interval.toDate,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const downloadReportCampaignSearchDetailByDates = async (
  status: any,
  interval: GlobalInterval,
  campaigns: any,
  searchText: string
) => {
  try {
    const callParams = await getSearchedCallParams("POST", searchText);
    const response: any = await makeCall(
      `${urls.bulkEmailRequestsByStatusAndInterval}/download/search` +
        "/" +
        campaigns +
        "/" +
        "status" +
        "/" +
        status +
        "/dates" +
        "/" +
        interval.fromDate +
        "/" +
        interval.toDate,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignsPerformanceData = async (campaignId: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.campaignPerformanceData}/${campaignId}`,
      callParams
    );

    return response;
  } catch (error: any) {
    throw error;
  }
};
