import CustomButton from "global/components/CustomButton/CustomButton";
import { Box } from "@mui/material";
import CustomDialog from "global/components/CustomDialog/CustomDialog";
import DeleteImg from "assets/images/DeleteImg.svg";
import SocialCampaignsStyles from "screens/CampaignsNew/SocialCampaigns/SocialCampaigns.styles";

interface customProps {
  showDeleteDialog: boolean;
  setShowDeleteDialog: Function;
  handleConfirmDelete: Function;
}

const DeleteSocialCampaignModal = (props: customProps) => {
  const classes = SocialCampaignsStyles;

  const handleCloseModel = () => {
    props.setShowDeleteDialog(false);
  };

  const dialogTitleContent = () => {
    return (
      <>
        <Box sx={classes.dialogTitleWrapper}>
          <Box sx={classes.titleRight}>Delete Social Campaign</Box>
        </Box>
      </>
    );
  };

  const dialogBody = () => (
    <Box sx={classes.dialogContent}>
      Are you sure you want to delete this Social Campaign?
    </Box>
  );

  const dialogContent = () => {
    return (
      <>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            label="Cancel"
            customClasses={classes.buttonWhiteBg}
            onClick={() => handleCloseModel()}
          />
          <CustomButton
            label="Delete"
            onClick={() => props.handleConfirmDelete()}
          />
        </Box>
      </>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={DeleteImg} alt="delete Image" />
      </Box>
    );
  };

  return (
    <>
      <CustomDialog
        dialogHeaderContent={dialogHeaderContent()}
        isDialogOpen={props.showDeleteDialog}
        closable
        closeButtonVisibility
        handleDialogClose={handleCloseModel}
        dialogTitleContent={dialogTitleContent()}
        dialogBodyContent={dialogBody()}
        dialogFooterContent={dialogContent()}
        width="450px"
      />
    </>
  );
};

export default DeleteSocialCampaignModal;
