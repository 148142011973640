import urls from "global/constants/UrlConstants";
import { SortingConfig } from "models/interfaces";
import {
  getCallParams,
  makeCall,
  makeCallFile,
  makeCallUserMailCheck,
} from "utils/service";

export const getCampaigners = async (
  pageNumber: number,
  limit: number,
  searchText: string,
  sortingParams: SortingConfig[]
) => {
  const body = {
    searchText: searchText,
    entitySorters: sortingParams,
  };
  try {
    const callParams = await getCallParams("POST", body);
    const response = await makeCall(
      `${urls.campaigners}/${pageNumber}/limit/${limit}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
export const getReport = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(
      `${urls.campaignersReport}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
export const getCampaignersCount = async (searchText: string) => {
  try {
    const body = {
      searchText: searchText,
    };
    const callParams = await getCallParams("POST",body);
    const response = await makeCall(`${urls.campaignersCount}`, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
export const getUserRoles = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.GET_USERS_ROLES, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const emailCheck = async (emailId: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCallUserMailCheck(
      urls.userEmail + "/" + emailId,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const editCampaigner = async (rowData: any) => {
  try {
    const callParams = await getCallParams("POST", rowData);
    const response = await makeCall(urls.updateCampaigner, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const insertCampaigner = async (formFields: any) => {
  try {
    const callParams = await getCallParams("POST", formFields);
    const response = await makeCall(urls.addCampaigner, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
export const getSearchUsers = async (
  page: number,
  search: string,
  limit: number,
  sortingParams: SortingConfig[]
) => {
  try {
    const callParams = await getCallParams("POST", sortingParams);
    const response = await makeCall(
      `${urls.userSearch}/${page}/search/${search}/limit/${limit}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
export const getSearchUsersCounts = async (search: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(
      `${urls.userSearchCount}/${search}/count`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getActiveCampaigner = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(`${urls.activeCampaigner}`, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
export const unlockUser = async (emailId: string) => {
  try {
    const url = `${urls.unlockUser}/${emailId}`;
    const callParams = await getCallParams("PATCH");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const disableMFA = async (emailId: string) => {
  try {
    const url = `${urls.disableMFA}/${emailId}`;
    const callParams = await getCallParams("PATCH");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}
