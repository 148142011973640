import {
  centerItemFlex,
  getRelativeFontSize,
  regularFont,
  theme,
  purpleThemedSelectComponent,
  pinkThemedMenuItems,
} from "../../../utils/styles";

const StepFourStyle = {
  dropDownStyle: {
    backgroundColor: "#FFFFFF",
    height: "47px",
    padding: "2px",
    width: " 150px",
    borderRadius: "12px",
    marginLeft: "10px",
    ...regularFont,
    ...purpleThemedSelectComponent,
    [theme.breakpoints.down("md")]: {
      margin: "0",
      marginBottom: "10px",
    },
  },
  menuItemsStyles: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  uploadFileStyle: {
    marginLeft: "5px",
    marginTop: "8px",
  },
  colorIndicatorsBox: { display: "flex", alignItems: "center" },
  severityFonts: {
    ...regularFont,
    fontSize: getRelativeFontSize(),
  },
  mainBox: {
    padding: 2,
    width: "40vw",
    [theme.breakpoints.down("lg")]: {
      width: "auto",
    },
  },
  noSpam: {
    ...centerItemFlex,
    padding: 2,
  },
  message: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
  },
} as const;

export default StepFourStyle;
