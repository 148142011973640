import urls from "global/constants/UrlConstants";
import { User, paidValidate } from "models/interfaces";
import {
  getAccessToken,
  getCallParams,
  getNoAuthCallParams,
  makeCall,
} from "utils/service";

export async function getUserDetails(authToken: string) {
  try {
    const requestBody = {
      authToken,
    };
    const callParams = getNoAuthCallParams("POST", requestBody);
    const response: any = await makeCall(
      urls.verifyTempPwdActivate,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getPlans(account: string) {
  try {
    const callParams = getNoAuthCallParams("POST", account);
    const response: any = await makeCall(urls.viewPlans, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function activateUser(user: User, authToken: string) {
  try {
    const callParams = await getAccessToken("POST", user, authToken);
    const response: any = await makeCall(urls.activateUser, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const getUserRegisterPaid = async (objectData: paidValidate) => {
  try {
    const url = urls.userRegisterPaid;
    const callParams = await getCallParams("POST", objectData);
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getPlanFeatures = async (
  accountName: string,
  authToken: string
) => {
  try {
    const url: string = `${urls.featurePlan}/${accountName}`;
    const callParams = await getAccessToken("GET", null, authToken);
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
