import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomButton, CustomDialog, CustomTable } from "global/components";
import { isTruthy, openErrorNotification } from "helpers/methods";
import ConfirmModal from "screens/Contact/ConfirmModal";
import { ViewContactStyles } from "screens/Contact/ViewContact.styles";
import { getTableDeleteModal } from "screens/Contact/ViewContactService";
import { deleteModalHeader } from "screens/Contact/ContactListData";
import contactDeleteIconImg from "assets/images/contactDeleteIconImg.svg";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import notifiers from "global/constants/NotificationConstants";

interface CustomProps {
  deleteModal: boolean;
  setOpenDelete: (deleteModal: boolean) => void;
  email: string;
  fetchAllContacts: Function;
}

const DeleteModal = (props: CustomProps) => {
  const classes = ViewContactStyles;
  const [deleteModalTableData, setDeleteModalTableData] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [perPageData, setPerPageData] = useState(10);
  const pageVisited = pageNumber * perPageData;
  const displayRows = deleteModalTableData.slice(
    pageVisited,
    pageVisited + perPageData
  );

  useEffect(() => {
    tableInDeleteModel();
  }, [props.deleteModal]);

  const handlePerPageData = (event: any) => {
    setPerPageData(event.target.value);
  };

  const tableInDeleteModel = async () => {
    try {
      setIsLoading(true);
      const deleteTable = await getTableDeleteModal(props.email);
      setDeleteModalTableData(deleteTable);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const openConfirmModel = () => {
    if (selected.length > 0) {
      setOpenConfirmModal(true);
    } else {
      openErrorNotification(
        "Please select group from you want to delete this contact"
      );
    }
  };

  const isSelected = (id: any) => {
    return selected.indexOf(id) !== -1;
  };

  const handleClick = (event: any, row: any) => {
    const selectedCheckBoxValue: any[] = [];
    if (isSelected(row.id)) {
      const removeSelectedArrayValue = selected?.filter((element: any) => {
        return element !== row.id;
      });
      setSelected(removeSelectedArrayValue);
    } else {
      if (event.target.checked) {
        selectedCheckBoxValue.push(...selected, row.id);
        setSelected(selectedCheckBoxValue);
      }
    }
  };

  const onSelectAllClick = (event: any) => {
    let checkBoxValue: any[] = [];
    if (event.target.checked) {
      const newSelecteds: any = deleteModalTableData.map(
        (items: any) => items.id
      );
      checkBoxValue.push(...selected, ...newSelecteds);
      setSelected(checkBoxValue);
      return;
    } else {
      const newSelecteds: any = deleteModalTableData.map(
        (items: any) => items.id
      );
      let unCheckSelectAll = selected?.filter(
        (item: any) => !newSelecteds.includes(item)
      );
      setSelected(unCheckSelectAll);
    }
  };

  const handleDeleteModal = () => {
    props.setOpenDelete(false);
  };

  const getDeleteModalTitle = () => {
    return (
      <Typography sx={classes.deleteModalTitle}>
        Delete Contact From Groups
      </Typography>
    );
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage - 1);
  };

  const modalDeleteBodyContent = () => {
    return (
      <Box sx={{ minWidth: "300px", width: "100%", overflow: "auto" }}>
        <CustomTable
          headers={deleteModalHeader}
          rows={displayRows}
          paginationCount={deleteModalTableData.length}
          checkboxSelection={true}
          handleClick={handleClick}
          onSelectAllClick={onSelectAllClick}
          isSelected={isSelected}
          handlePageChange={handleChangePage}
          pageNumber={pageNumber + 1}
          isSelectAll={selected}
          handlePerPageData={handlePerPageData}
          perPageData={perPageData}
          rowsPerPage={perPageData}
        />
      </Box>
    );
  };

  const onCancel = () => {
    props.setOpenDelete(false);
  };

  const deleteHeaderImg = () => {
    return (
      <Box display={"flex"}>
        <img src={contactDeleteIconImg} alt="image not found" />
      </Box>
    );
  };

  const addUserDialogFooter = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            label="Cancel"
            onClick={onCancel}
            customClasses={classes.cancelButtonStyle}
            id="cancel_model_button"
          />
          <CustomButton
            label="Delete"
            onClick={openConfirmModel}
            id="delete_model_button"
          />
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <CustomDialog
        width={"1080px"}
        isDialogOpen={props.deleteModal}
        closable
        closeButtonVisibility
        dialogHeaderContent={deleteHeaderImg()}
        handleDialogClose={handleDeleteModal}
        dialogTitleContent={getDeleteModalTitle()}
        dialogBodyContent={modalDeleteBodyContent()}
        dialogFooterContent={addUserDialogFooter()}
      />
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        setOpenDeleteModal={props.setOpenDelete}
        email={props.email}
        selected={selected}
        getSearchTableData={props.fetchAllContacts}
      />
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default DeleteModal;
