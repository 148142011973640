import notifiers from "global/constants/NotificationConstants";
import {
  convertResourceToObjectFormat,
  isTruthy,
  isValidOTP,
  openErrorNotification,
} from "helpers/methods";
import React, { useState } from "react";
import { loginAction, selectAuthenticated } from "redux/authSlice";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { verifyTOTP } from "screens/LandingPage/landingPageService";
import { Box, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import urls from "global/constants/UrlConstants";
import { useHistory } from "react-router-dom";
import loginStyles from "screens/LandingPage/components/Login.styles";
import { CustomButton } from "global/components";
import { changeIntervalAction, changeIsFirstNameMandatory } from "redux/persistSlice";
import strings from "global/constants/StringConstants";
import moment from "moment";

const OTP = () => {
  const classes = loginStyles;
  const dispatch = useAppDispatch();
  const history: any = useHistory();
  const email = history?.location?.state?.email ?? "";

  const isLoggedIn = useAppSelector(selectAuthenticated);
  const [payload, setPayload] = useState({
    email: email,
    otp: "",
  });
  const [processing, setProcessing] = useState<boolean>(false);

  const verifyOTP = async () => {
    try {
      if (!isValidOTP(payload.otp)) {
        return openErrorNotification("Please enter the code!");
      }
      setProcessing(true);
      const response = await verifyTOTP(payload.email, parseInt(payload.otp));
      const formattedResources = convertResourceToObjectFormat(
        response.resources
      );
      if (!isTruthy(formattedResources)) {
        openErrorNotification(notifiers.GENERIC_ERROR);
        setProcessing(false);
        return;
      }
      dispatch(
        changeIntervalAction({
          label: strings.thisWeek,
          fromDate: moment().startOf("isoWeek").format("MM-DD-yyyy"),
          toDate: moment().format("MM-DD-yyyy"),
        })
      );
      dispatch(changeIsFirstNameMandatory(true));
      dispatch(
        loginAction({
          email,
          authenticated: true,
          accessToken: response.authToken,
          userName: response.name,
          role: response.role,
          resources: formattedResources,
          account: response.account,
          isTrial: response.trial,
        })
      );
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      verifyOTP();
    }
  };

  const renderOTPScreen = () => {
    return (
      <Box sx={classes.getLoginScreen}>
        <Typography sx={classes.getHeading}>Authenticator Code</Typography>
        <Typography sx={classes.authenticatorDescription}>
          Please enter the authenticator code to securely login.
        </Typography>
        <Box mx={4} mt={2}>
          <MuiOtpInput
            length={6}
            onChange={(value: any) =>
              setPayload({
                ...payload,
                otp: value.replace(/[^0-9]/g, ""),
              })
            }
            sx={classes.otpInput}
            value={payload.otp}
            onKeyDown={handleKeypress}
          />

          <Box mt={2}>
            <CustomButton
              label="Verify"
              onClick={verifyOTP}
              loading={processing}
              customClasses={classes.signBtn}
              id="login_button"
            />
          </Box>
        </Box>
        <Box
          marginTop={2}
          width={"100%"}
          onClick={() => history.push(urls.loginViewPath)}
        >
          <Typography sx={classes.backToLogin}>Back to Login</Typography>
        </Box>
        <Box>
          <Typography sx={classes.OTPHelperMessage}>
            If you're unable to login, please reach out to admin to reset the
            multi-factor authentication.
          </Typography>
        </Box>
      </Box>
    );
  };

  if (isLoggedIn) {
    history.push(urls.dashboardViewPath);
    return null;
  }
  return renderOTPScreen();
};

export default OTP;
