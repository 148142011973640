import urls from "global/constants/UrlConstants";
import { getCallParams, makeCall } from "utils/service";

export const getAccountPlanDetail = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.getPlanDetail, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCard = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.getCardDetail, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const featurePlan = async (accountName: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.featurePlan}/${accountName}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const deleteCard = async (id: any) => {
  try {
    const callParams = await getCallParams("DELETE");
    const response: any = await makeCall(
      `${urls.deleteCard}/${id}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const addCard = async (id: any, defaultCard: any) => {
  try {
    const callParams = await getCallParams("POST");
    const url = `${urls.addCard}/${id}/${defaultCard}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const setDefaultCard = async (id: any) => {
  try {
    const callParams = await getCallParams("POST");
    const url = `${urls.setPrimaryCard}/${id}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const changePlanService = async (data: any) => {
  try {
    const callParams = await getCallParams("POST", data);
    const response: any = await makeCall(urls.changePlan, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updatePlan = async (id: any) => {
  try {
    const callParams = await getCallParams("POST");
    const response: any = await makeCall(
      `${urls.updatePlan}/${id}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const cancelSubscription = async () => {
  try {
    const callParams = await getCallParams("DELETE");
    const response: any = await makeCall(urls.cancelSubscription, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const checkActivePlan = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.isActiveCancelSubscription,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getInvoiceDetail = async (page: number, limit: number) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.getInvoiceDetail}/page/${page}/limit/${limit}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getInvoiceDetailCount = async () => {
  try {
    const callParams = await getCallParams("GET");
    const url = urls.getInvoiceCount;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getDailyUsedEmailCount = async () => {
  try {
    const callParams = await getCallParams("GET");
    const url = urls.getDailyEmailCount;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
