import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import strings from "global/constants/StringConstants";
import { hasAccessTo } from "utils/AuthorizationManager";
import {
  getLoggedInEmailUser,
  sendCampaigns,
} from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/CreateSequentialCampaignsServices";
import notifiers from "global/constants/NotificationConstants";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import StepOne from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepOne/StepOne";
import StepThree from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepThree/StepThree";
import {
  campaignNumberArray,
  commonCampaignDataInitialState,
  commonCampaignDataMapper,
  dynamicCampaignDataMapper,
  mapFormDataToValues,
} from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/CreateSequentialCampaignsHelpers";
import StepTwo from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/StepTwo";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { useTitle } from "utils/UseTitle";
import urls from "global/constants/UrlConstants";
import history from "utils/history";
import { getFooterTemplate } from "screens/Templates/TemplatePageService";
import { Design } from "react-email-editor";

const CreateSequentialCampaigns = () => {
  useTitle(strings.SequentialCampaignTitle);
  const [allowedEmailCount, setAllowedEmailCount] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stepThreeActiveIndex, setStepThreeActiveIndex] = useState<number>(0);
  const [commonCampaignData, setCommonCampaignData] = useState<any>(
    commonCampaignDataInitialState()
  );
  const [totalCampaigns, setTotalCampaigns] = useState(campaignNumberArray[0]);
  const [mainCampaignDataArray, setMainCampaignDataArray] = useState<any[]>([]);
  const [isValidArray, setIsValidArray] = useState<boolean[]>([]);
  const [isAccordionOpenArray, setIsAccordionOpenArray] = useState<boolean[]>(
    []
  );
  const [templateHtmlArray, setTemplateHtmlArray] = useState<string[]>([]);
  const [totalAttachmentsSizeArray, setTotalAttachmentsSizeArray] = useState<
    number[]
  >([]);
  const [attachmentFileSizeArray, setAttachmentFileSizeArray] = useState<any[]>(
    []
  );
  const [defaultTemplateFooter, setDefaultTemplateFooter] = useState<Design>();

  const hasCampaignerFetchAccess = hasAccessTo(
    strings.campaigner,
    strings.fetchPermission
  );

  useEffect(() => {
    hasCampaignerFetchAccess && getAllowedEmailCount();
    getDefaultFooterTemplate();
  }, []);

  const getAllowedEmailCount = async () => {
    try {
      const response = await getLoggedInEmailUser();
      setAllowedEmailCount(response?.allowedEmailCount);
    } catch (error: any) {
      const errorMessage = error?.message || notifiers.GENERIC_ERROR;
      openErrorNotification(errorMessage);
    }
  };

  const getDefaultFooterTemplate = async () => {
    try {
      setIsLoading(true);
      const response = await getFooterTemplate();
      setDefaultTemplateFooter(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const mainCampaignArrayConstructor = (
    temporaryArray: any[],
    index: number
  ) => {
    temporaryArray.push({
      ...commonCampaignDataMapper(index, commonCampaignData),
      ...dynamicCampaignDataMapper(mainCampaignDataArray[index] ?? undefined),
    });
  };

  const isValidArrayConstructor = (temporaryArray: boolean[]) => {
    temporaryArray.push(true);
  };

  const isAccordionOpenConstructor = (temporaryArray: boolean[]) => {
    temporaryArray.push(false);
  };

  const totalSizeArrayConstructor = (
    temporaryArray: number[],
    index: number
  ) => {
    temporaryArray.push(totalAttachmentsSizeArray[index] ?? 0);
  };

  const fileSizeAttachmentConstructor = (
    temporaryArray: any[],
    index: number
  ) => {
    temporaryArray.push(attachmentFileSizeArray[index] ?? []);
  };

  const htmlContentArrayConstructor = (
    temporaryArray: string[],
    index: number
  ) => {
    temporaryArray.push(templateHtmlArray[index] ?? "");
  };

  const campaignsArrayConstructor = (callback: any) => {
    let campaignsArray: any[] = [];
    let isCampaignValidArray: boolean[] = [];
    let isAccordionOpen: boolean[] = [];
    let totalSizeArray: number[] = [];
    let fileSizeAttachment: any[] = [];
    let htmlContentArray: string[] = [];
    for (let i = 0; i < totalCampaigns; i++) {
      mainCampaignArrayConstructor(campaignsArray, i);
      isValidArrayConstructor(isCampaignValidArray);
      isAccordionOpenConstructor(isAccordionOpen);
      totalSizeArrayConstructor(totalSizeArray, i);
      fileSizeAttachmentConstructor(fileSizeAttachment, i);
      htmlContentArrayConstructor(htmlContentArray, i);
    }
    setMainCampaignDataArray(campaignsArray);
    setIsValidArray(isCampaignValidArray);
    setIsAccordionOpenArray(isAccordionOpen);
    setTotalAttachmentsSizeArray(totalSizeArray);
    setAttachmentFileSizeArray(fileSizeAttachment);
    setTemplateHtmlArray(htmlContentArray);
    callback();
  };

  const getSequentialCampaignsPayload = () => {
    const payloadArray: any[] = [];
    for (let i = 0; i < mainCampaignDataArray.length; i++) {
      const payloadObject = mapFormDataToValues(
        mainCampaignDataArray[i],
        templateHtmlArray[i]
      );
      payloadArray.push(payloadObject);
    }
    return payloadArray;
  };

  const handleSendCampaign = async () => {
    try {
      setIsLoading(true);
      const payload = getSequentialCampaignsPayload();
      const response = await sendCampaigns(payload);
      openSuccessNotification(response?.message);
      redirectToCampaignsView(strings.Submitted);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const redirectToCampaignsView = (status: any) => {
    const searchParams = new URLSearchParams({ status }).toString();
    const viewPath = `${urls.campaignsListViewPath}?${searchParams}`;
    history.push(viewPath);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <StepOne
            commonCampaignData={commonCampaignData}
            setCommonCampaignData={setCommonCampaignData}
            setActiveStep={setActiveStep}
            allowedEmailCount={allowedEmailCount}
            totalCampaigns={totalCampaigns}
            setTotalCampaign={setTotalCampaigns}
            campaignNumberArray={campaignNumberArray}
            campaignsArrayConstructor={campaignsArrayConstructor}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        );
      case 2:
        return (
          <StepTwo
            mainCampaignDataArray={mainCampaignDataArray}
            setMainCampaignDataArray={setMainCampaignDataArray}
            setActiveStep={setActiveStep}
            attachmentFileSizeArray={attachmentFileSizeArray}
            setAttachmentFileSizeArray={setAttachmentFileSizeArray}
            totalAttachmentsSizeArray={totalAttachmentsSizeArray}
            setTotalAttachmentsSizeArray={setTotalAttachmentsSizeArray}
            templateHtmlArray={templateHtmlArray}
            setStepThreeActiveIndex={setStepThreeActiveIndex}
            isValidArray={isValidArray}
            setIsValidArray={setIsValidArray}
            isAccordionOpenArray={isAccordionOpenArray}
            setIsAccordionOpenArray={setIsAccordionOpenArray}
            handleSendCampaign={handleSendCampaign}
          />
        );
      case 3:
        return (
          <StepThree
            index={stepThreeActiveIndex}
            setActiveStep={setActiveStep}
            mainCampaignDataArray={mainCampaignDataArray}
            setMainCampaignDataArray={setMainCampaignDataArray}
            templateHtmlArray={templateHtmlArray}
            setTemplateHtmlArray={setTemplateHtmlArray}
            defaultTemplateFooter={defaultTemplateFooter}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <Box>{getStepContent()}</Box>
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default CreateSequentialCampaigns;
