import {
  boldFont,
  centerItemFlex,
  getRelativeFontSize,
  mediumFont,
  pureWhiteColor,
  regularFont,
  theme,
} from "utils/styles";

const CreateCampaignStyle = {
  mainContainer: {
    marginTop: "50px",
  },
  createCampaignInnerWrapper: {
    padding: "10px",
    // background: "#f6fcff",
    background: "none",
    height: "100%",
    minHeight: "90vh",
    margin: "0 10px",
  },
  dividerStyle: {
    marginTop: "30px",
  },
  spamBox: {
    width: "40vw",
    [theme.breakpoints.down("lg")]: {
      width: "auto",
    },
  },
  spamContent: {
    height: "100%",
    padding: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      padding: 2,
    },
  },

  statusHeadingStyle: {
    ...boldFont,
    fontSize: getRelativeFontSize(8),
  },

  spamTextStyle: {
    ...boldFont,
    fontSize: getRelativeFontSize(5),
    marginTop: "10px",
  },

  spamWaringTextStyle: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    margin: "10px 0",
  },
  dropdown: {
    minWidth: "150px",
    margin: "18px 0px 8px 0px",
    height: "47px",
    borderRadius: "10px",
    background: pureWhiteColor,
    ...regularFont,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  optionStyle: {
    ...regularFont,
  },
  centerItemFlex: {
    ...centerItemFlex,
    paddingBottom: "20px",
  },
  dialogFooter: {
    display: "flex",
    gap: "10px",
    ...centerItemFlex,
    width: "100%",
    justifyContent: "center",
    "& button": {
      width: "120px",
    },
  },
  cancelButtonStyle: {
    color: "#212121 !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E7E7E7",
    "&:hover": {
      background: "none",
    },
  },
  boldFonts: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    textAlign: "center",
  },
  addTagChip: {
    marginLeft: "5px",
    marginTop: "8px",
    borderRadius: "5px",
    ...mediumFont,
    fontSize: "15px",
    backgroundColor: "#fcf5ff",
  },
  scheduleTimeSubHeading: {
    textAlign: "center",
    color: "#666666",
    fontSize: "16px",
    lineHeight: "15px",
    fontWeight: "400",
    marginTop: "5px",
  },
  scheduleBtn: {
    width: "170px !important",
  },
  radioButtonlabel: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
    fontStyle: "normal",
    fontFamily: "Source Sans 3",
    color: "#666666",
  },
  divderResponsive: {
    width: "100px",
    borderColor: "#828282",
    margin: "10px",
    [theme.breakpoints.down("md")]: {
      width: "0px",
      margin: "0px",
    },
  },
} as const;

export default CreateCampaignStyle;
