import { useState, useEffect } from "react";
import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CampaignDetailsSectionsStyles from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/StepTwo/CampaignDetailsSections/CampaignDetailsSection.styles";
import { isTruthy } from "helpers/methods";
import { CustomButton } from "global/components";

interface CustomProps {
  campaignData: any;
  setCampaignData: Function;
  approvedTemplates: any[];
}

const ContentSection = (props: CustomProps) => {
  const classes = CampaignDetailsSectionsStyles;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>(props.campaignData.template);

  useEffect(() => {
    if (props.campaignData.template.error) {
      setIsEditMode(true);
      setTemplate(props.campaignData.template);
    }
  }, [props.campaignData.template.error]);

  const editViewButtonHandler = () => {
    setIsEditMode(true);
  };

  const onChangeHandler = (event: any) => {
    setTemplate({
      value: event.target.value,
      error: "",
    });
    props.setCampaignData({
      ...props.campaignData,
      template: { ...props.campaignData.template, error: "" },
    });
  };

  const isContentSectionValid = () => {
    let isValid = true;
    if (!isTruthy(template.value)) {
      setTemplate({
        ...template,
        error: "Please select a template form the dropdown.",
      });
      isValid = false;
    }
    return isValid;
  };

  const onSaveHandler = () => {
    if (isContentSectionValid()) {
      props.setCampaignData({
        ...props.campaignData,
        template: {
          value: template.value,
          error: "",
        },
      });
      setIsEditMode(false);
    }
  };

  const onCancelHandler = () => {
    const campaignDataContentObject = {
      ...props.campaignData.template,
      error: "",
    };
    setTemplate(campaignDataContentObject);
    props.setCampaignData({
      ...props.campaignData,
      template: campaignDataContentObject,
    });
    setIsEditMode(false);
  };

  const getSaveButton = () => {
    return (
      <CustomButton
        id="whatsapp_content_save_button"
        label="Save"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onSaveHandler();
        }}
      />
    );
  };

  const getCancelButton = () => {
    return (
      <CustomButton
        id="whatsapp_content_cancel_button"
        label="Cancel"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onCancelHandler();
        }}
      />
    );
  };

  const getTemplateSelectInput = () => {
    return (
      <Box>
        <InputLabel
          id="whatsapp_campaign_template_type_label"
          sx={classes.inputLabel}
          shrink
        >
          WhatsApp message template{" "}
          <Box ml={0.4} sx={classes.star}>
            *
          </Box>
        </InputLabel>
        <Select
          id="whatsapp_campaign_template_type_input"
          name="content"
          value={template.value}
          error={template.error}
          renderValue={(value) =>
            isTruthy(value) ? (
              value.templateName
            ) : (
              <Typography sx={classes.placeholderText}>
                Select WhatsApp template
              </Typography>
            )
          }
          onChange={onChangeHandler}
          sx={classes.inputFieldStyles}
        >
          {props.approvedTemplates.map((item: any) => (
            <MenuItem sx={classes.optionStyle} value={item}>
              {item.templateName}
            </MenuItem>
          ))}
        </Select>
        {isTruthy(template.error) && (
          <FormHelperText error sx={classes.errorStyles}>
            {template.error}
          </FormHelperText>
        )}
      </Box>
    );
  };

  const getEditModeHeading = () => {
    return (
      <>
        <Box>
          <Typography sx={classes.headerLabel}>Message Content</Typography>
          <Typography sx={classes.headerSubDescription}>
            Select an approved message template or create a new one.
          </Typography>
        </Box>
      </>
    );
  };

  const getEditModeView = () => {
    return (
      <Box>
        <Box mb={1} sx={classes.viewModeBox}>
          {getEditModeHeading()}
        </Box>
        <Box sx={classes.inputFieldContainer}>{getTemplateSelectInput()}</Box>
        <Stack flexDirection="row" gap={1} mt={1}>
          {getSaveButton()}
          {getCancelButton()}
        </Stack>
      </Box>
    );
  };

  const getViewModeView = () => {
    return (
      <Box sx={classes.viewModeBox}>
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={classes.headerLabel}>Message Content</Typography>
            <Typography ml={0.4} sx={classes.star}>
              *
            </Typography>
          </Box>
          {isTruthy(props.campaignData.template.value) ? (
            <Typography sx={classes.headerSubDescription}>
              {props.campaignData.template.value.templateName}
            </Typography>
          ) : (
            <Typography sx={classes.headerSubDescription}>
              The message content that will be sent to the users.
            </Typography>
          )}
        </Box>
        <Box>
          <CustomButton
            id="whatsapp_campaign_content_edit_button"
            label={
              isTruthy(props.campaignData.template.value)
                ? "Edit Template"
                : "Select Template"
            }
            onClick={editViewButtonHandler}
            customClasses={classes.buttonStyles}
          />
        </Box>
      </Box>
    );
  };

  return <Box>{isEditMode ? getEditModeView() : getViewModeView()}</Box>;
};

export default ContentSection;
