import { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  CustomButton,
  CustomContactNumberInput,
  CustomInput,
} from "global/components";
import ProfilePageStyles from "screens/Settings/Profile/ProfilePage.styles";
import {
  getProfileFormData,
  validateProfileForm,
  mapFormDataToValues,
} from "screens/Settings/Profile/ProfilePageHelpers";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { updateProfileDetails } from "screens/Settings/Profile/ProfilePageServices";
import { updateUserName } from "redux/authSlice";
import { useTitle } from "utils/UseTitle";
import strings from "global/constants/StringConstants";
import { useDispatch } from "react-redux";

interface CustomProps {
  profile: any;
  fetchProfile: Function;
}

export const ProfilePage = (props: CustomProps) => {
  useTitle(strings.ProfileTitle);
  const classes = ProfilePageStyles;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [profileFormData, setProfileFormData] = useState(
    getProfileFormData(props?.profile)
  );

  useEffect(() => {
    setProfileFormData(getProfileFormData(props?.profile));
  }, [props?.profile]);

  const onChangeHandler = (event: React.ChangeEvent<any>) => {
    setProfileFormData({
      ...profileFormData,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const handleValidation = () => {
    const { isValid, errors } = validateProfileForm(profileFormData);
    setProfileFormData({ ...errors });
    return isValid;
  };

  const saveProfileDetails = async () => {
    try {
      if (handleValidation()) {
        setLoading(true);
        const profilePayload = mapFormDataToValues(profileFormData);
        await updateProfileDetails(profilePayload);
        dispatch(updateUserName(profilePayload.name));
        setLoading(false);
        openSuccessNotification("Profile Details updated successfully");
      }
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getProfileHeader = () => {
    return (
      <Grid container alignItems="center" mt={2}>
        <Grid item xs={12} sm={10}>
          <Grid container alignItems="center">
            <Grid item>
              <Avatar
                alt={profileFormData.name.value ?? ""}
                src="/"
                sx={{ width: 80, height: 80 }}
              />
            </Grid>
            <Grid item ml={2}>
              <Typography sx={classes.profileHeaderName}>
                {profileFormData.name.value ?? ""}
              </Typography>
              <Typography sx={classes.profileHeaderEmail}>
                {profileFormData.email.value ?? ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getPersonalDetails = () => {
    return (
      <Grid container mt={2}>
        <Grid item xs={12} mb={2} ml={1.5}>
          <Typography sx={classes.pageSubtitle}>Personal Details</Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Box m={1.5}>
            <CustomInput
              required
              label="Name"
              id="profile_name_field"
              type="text"
              name="name"
              placeHolder="Enter Name"
              onChange={onChangeHandler}
              propsToInputElement={{ maxlength: 25 }}
              value={profileFormData.name.value}
              error={profileFormData.name.error}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Box m={1.5}>
            <CustomInput
              label="Email Address"
              id="profile_email_field"
              type="email"
              name="email"
              disabled={true}
              placeHolder="Enter Email Address"
              propsToInputElement={{ maxlength: 50 }}
              onChange={onChangeHandler}
              value={profileFormData.email.value}
              error={profileFormData.email.error}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} mt={1.2}>
          <CustomContactNumberInput
            label="Contact Number"
            id="profile_contact_no"
            value={profileFormData.contactNo.value}
            placeHolder="Enter Your Contact No"
            onChange={(phone: any) => {
              setProfileFormData({
                ...profileFormData,
                contactNo: {
                  value: phone,
                  error: "",
                },
              });
            }}
            error={profileFormData.contactNo.error}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Box m={1.5}>
            <CustomInput
              label="Role"
              id="profile_role_field"
              type="text"
              name="role"
              disabled={true}
              placeHolder="Enter Role"
              propsToInputElement={{ maxlength: 50 }}
              onChange={onChangeHandler}
              value={profileFormData.role.value}
              error={profileFormData.role.error}
            />
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={6} lg={4}>
          <Box m={1.5}>
            <Box mb={1}>
              <InputLabel required sx={classes.nameField}>
                Gender
              </InputLabel>
            </Box>
            <RadioGroup
              row
              name="gender"
              value={profileFormData.gender.value}
              onChange={onChangeHandler}
            >
              <FormControlLabel
                value="male"
                control={
                  <Radio  sx={classes.radioChecked} />
                }
                label="Male"
              />
              <FormControlLabel
                value="female"
                control={
                  <Radio  sx={classes.radioChecked} />
                }
                label="Female"
              />
              <FormControlLabel
                value="other"
                control={
                  <Radio  sx={classes.radioChecked} />
                }
                label="Other"
              />
            </RadioGroup>
          </Box>
        </Grid> */}
      </Grid>
    );
  };

  // const getOtherDetails = () => {
  //   return (
  //     <Grid container mt={4}>
  //       <Grid item xs={12} mb={2} ml={1.5}>
  //         <Typography sx={classes.profileDetailsTile}>Other Details</Typography>
  //       </Grid>
  //       <Grid item xs={12} sm={6} lg={4}>
  //         <Box m={1.5}>
  //           <CustomInput
  //             label="Company Name"
  //             id="profile_company_name_field"
  //             type="text"
  //             name="companyName"
  //             placeHolder="Enter Company Name"
  //             onChange={onChangeHandler}
  //             propsToInputElement={{ maxlength: 25 }}
  //             value={profileFormData.companyName.value}
  //             error={profileFormData.companyName.error}
  //           />
  //         </Box>
  //       </Grid>
  //       <Grid item xs={12} sm={6} lg={4}>
  //         <Box m={1.5}>
  //           <CustomInput
  //             label="Company Email"
  //             id="profile_company_email_field"
  //             type="email"
  //             name="companyEmail"
  //             placeHolder="Enter Company Email"
  //             onChange={onChangeHandler}
  //             propsToInputElement={{ maxlength: 50 }}
  //             value={profileFormData.companyEmail.value}
  //             error={profileFormData.companyEmail.error}
  //           />
  //         </Box>
  //       </Grid>
  //       <Grid item xs={12} sm={6} lg={4}>
  //         <Box m={1.5}>
  //           <CustomInput
  //             label="Address"
  //             id="profile_address_field"
  //             type="text"
  //             name="address"
  //             placeHolder="Enter Address"
  //             propsToInputElement={{ maxlength: 50 }}
  //             onChange={onChangeHandler}
  //             value={profileFormData.address.value}
  //             error={profileFormData.address.error}
  //           />
  //         </Box>
  //       </Grid>
  //       <Grid item xs={12} sm={6} lg={4}>
  //         <Box m={1.5}>
  //           <CustomInput
  //             label="City"
  //             id="profile_city_field"
  //             type="text"
  //             name="city"
  //             propsToInputElement={{ maxlength: 15 }}
  //             placeHolder="Enter City"
  //             onChange={onChangeHandler}
  //             value={profileFormData.city.value}
  //           />
  //         </Box>
  //       </Grid>
  //       <Grid item xs={12} sm={6} lg={4}>
  //         <Box m={1.5}>
  //           <CustomInput
  //             label="State"
  //             id="profile_state_field"
  //             type="text"
  //             name="state"
  //             placeHolder="Enter State"
  //             propsToInputElement={{ maxlength: 15 }}
  //             onChange={onChangeHandler}
  //             value={profileFormData.state.value}
  //             error={profileFormData.state.error}
  //           />
  //         </Box>
  //       </Grid>
  //       <Grid item xs={12} sm={6} lg={4}>
  //         <Box m={1.5}>
  //           <CustomInput
  //             label="Country"
  //             id="profile_country_field"
  //             type="text"
  //             name="country"
  //             placeHolder="Enter Country"
  //             propsToInputElement={{ maxlength: 50 }}
  //             onChange={onChangeHandler}
  //             value={profileFormData.country.value}
  //             error={profileFormData.country.error}
  //           />
  //         </Box>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const getProfileFooter = () => {
    return (
      <Box sx={classes.profileFooter}>
        <CustomButton
          id="profile_submit_button"
          customClasses={classes.saveBtnStyle}
          onClick={saveProfileDetails}
          label="Save"
        />
      </Box>
    );
  };

  return (
    <>
      <Container>
        {getProfileHeader()}
        <Divider sx={{ my: 2 }} />
        {getPersonalDetails()}
        {/* {getOtherDetails()} */}
        {getProfileFooter()}
      </Container>
      <CustomLoader isLoading={loading} />
    </>
  );
};
