import urls from "global/constants/UrlConstants";
import { getCallParams, makeCall } from "utils/service";

export const submitTemplateForApproval = async (templatePayload: any) => {
  try {
    const callParams = await getCallParams("POST", templatePayload);
    const response: any = await makeCall(
      urls.sendTemplateForApproval,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const isWhatsAppTemplateNameUnique = async (
  name: string,
  languageCode: string
) => {
  try {
    const url: string = `${urls.checkTemplateNameUniqueness}/name/${name}/languageCode/${languageCode}`;
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const checkWhatsAppIntegration = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.getIntegratedAppNames, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
