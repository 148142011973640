import { useState } from "react";
import { CustomButton, CustomDialog } from "global/components";
import cardImage from "assets/images/cardImage.svg";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import BillingUsageStyles from "screens/Settings/BillingUsage/BillingUsage.styles";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { getRelativeFontSize, mediumFont } from "utils/styles";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { addCard } from "screens/Settings/BillingUsage/BillingUsage.service";
import CustomLoader from "global/components/CustomLoader/CustomLoader";

interface CustomProps {
  setOpenModal: Function;
  openModal: boolean;
  fetchCardDetailHandler: any;
}

const cardStyle = {
  style: {
    base: {
      color: "#00000",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#000",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const AddCardModal = (props: CustomProps) => {
  const classes = BillingUsageStyles;
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultCardStatus, setDefaultCardStatus] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={cardImage} alt="delete Image" />
      </Box>
    );
  };

  const handleCloseModel = () => {
    setDefaultCardStatus(false);
    props.setOpenModal(false);
  };

  const handleSubmit = async (event: any) => {
    try {
      setLoading(true);
      if (stripe !== null && elements !== null) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement)!,
        });
        if (error) {
          openErrorNotification(
            isTruthy(error) ? error.message : notifiers.GENERIC_ERROR
          );
          setLoading(false);
        } else {
          await addCardHandler(paymentMethod?.id);
        }
      }
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const addCardHandler = async (id: any) => {
    try {
      setLoading(true);
      await addCard(id, defaultCardStatus);
      openSuccessNotification("Add Card Successfully");
      handleCloseModel();
      await props.fetchCardDetailHandler();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const dialogTitleContent = () => {
    return (
      <>
        <Box sx={classes.dialogTitleWrapper}>
          <Box
            sx={{
              color: "#474747",
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "28px",
            }}
          >
            Payment Method
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            color: "#747474",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: 400,
            alignContent: "center",
            justifyContent: "center",
          }}
          mt={1}
        >
          Required card details if you select Payment
        </Box>
      </>
    );
  };

  const dialogContent = () => {
    return (
      <Box sx={classes.dialogFooter}>
        <CustomButton
          label="Cancel"
          customClasses={classes.buttonWhiteBg}
          onClick={() => handleCloseModel()}
          id="add_card_modal_cancel_button"
        />
        <CustomButton
          label="Save"
          onClick={(e: any) => handleSubmit(e)}
          id="add_card_modal_save_button"
        />
      </Box>
    );
  };

  const dialogBody = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12} mt={2}>
          <Typography
            sx={{
              ...mediumFont,
              fontSize: getRelativeFontSize(4),
              color: "#131313",
            }}
          >
            Card Number
          </Typography>
          <Box
            sx={{
              marginTop: "10px",
              border: "#dad3dd solid 1px",
              borderRadius: "9px",
              padding: "12px",
              backgroundColor: "#fff",
            }}
          >
            <CardElement options={cardStyle} id="card_number" />
          </Box>
        </Grid>
        <Grid container xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              flexDirection: "row",
              marginTop: "10px",
              marginLeft: "10px",
            }}
          >
            <FormControlLabel
              value="male"
              control={
                <Checkbox
                  onChange={(e: any) => {
                    setDefaultCardStatus(e.target.checked);
                  }}
                  checked={defaultCardStatus}
                  sx={{
                    "&.Mui-checked": {
                      color: "#BB0070",
                    },
                  }}
                  id="set_default_card_checkbox"
                />
              }
              label="Set as Default Card"
            />
          </Box>
          <Box>
            <Typography sx={classes.defaultCardTerms}>
              By adding your card here and marking it as the default, you
              authorize the system to automatically charge this default card for
              any future payments.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <CustomDialog
        dialogHeaderContent={dialogHeaderContent()}
        isDialogOpen={props.openModal}
        closable
        closeButtonVisibility
        handleDialogClose={handleCloseModel}
        dialogTitleContent={dialogTitleContent()}
        dialogBodyContent={dialogBody()}
        dialogFooterContent={dialogContent()}
        width="450px"
      />
      <CustomLoader isLoading={loading} />
    </>
  );
};
export default AddCardModal;
