import React, { useEffect, useState } from "react";
import { CustomTable } from "global/components";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import DeleteModal from "screens/Contact/DeleteModal";
import { ViewContactStyles } from "screens/Contact/ViewContact.styles";
import GroupDeleteModal from "screens/Contact/GroupDeleteModal";
import { contactReportDownload } from "screens/Contact/ViewContactService";
import { contactTableHeader } from "screens/Contact/ContactListData";
import { mediumFont, primaryGreenColor } from "utils/styles";
import notifiers from "global/constants/NotificationConstants";
import UpdateContactModal from "screens/Contact/UpdateContactModal";
import AddRecipientModal from "screens/Contact/AddRecipientModal";
import { hasAccessTo, isAdmin } from "utils/AuthorizationManager";
import strings from "global/constants/StringConstants";
import { SortingConfig, contactsFilters } from "models/interfaces";
import EmailRecipientModal from "screens/Contact/EmailRecipientModal";

interface CustomProps {
  rawContactData: any[];
  contactsFilters: contactsFilters;
  contactCount: number;
  pageChangeHandler: Function;
  perPageRowsChangeHandler: Function;
  isLoading: boolean;
  setIsLoading: Function;
  fetchTableData: Function;
  sortConfig: SortingConfig[];
  setSortConfig: Function;
  urlId?: string | null;
  name?: string;
  addRecipientModal?: boolean;
  setAddRecipientModal?: Function;
  fetchGroupInfoById?: Function;
  hideActions?: boolean;
  dropDownValue?: string;
}

const ContactTable = (props: CustomProps) => {
  const classes = ViewContactStyles;
  const [deleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [groupDeleteModal, setGroupDeleteModal] = useState<boolean>(false);
  const [isRecipientModalOpen, setIsRecipientModalOpen] =
    useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [clickedEmailId, setClickedEmailId] = useState<string>("");
  const [updateContactModal, setUpdateContactModal] = useState<boolean>(false);
  const [updateRowData, setUpdateRowData] = useState<any>([]);
  const [formattedTableData, setFormattedTableData] = useState<any[]>([]);

  const hasContactDeleteAccess = hasAccessTo(
    strings.contact,
    strings.deletePermission
  );
  const hasContactUpdateAccess = hasAccessTo(
    strings.contact,
    strings.updatePermission
  );

  const shouldExportButtonDisplay = () => {
    const shouldShow: boolean =
      isTruthy(props.contactsFilters.searchText) || isTruthy(props.urlId);
    return !shouldShow;
  };

  useEffect(() => {
    getFormattedTableData(props.rawContactData);
  }, [props.rawContactData]);

  const getFormattedTableData = (rawContactsData: any[]) => {
    const resultantData = rawContactsData.map((item: any) => {
      return {
        contactNo: item.contactNo,
        emailId: (
          <Tooltip
            title="Click here to view the summary"
            placement="bottom-start"
          >
            <Typography
              sx={classes.emailColor}
              onClick={() => {
                openRecipientsModal(item.emailId);
              }}
            >
              {item.emailId}
            </Typography>
          </Tooltip>
        ),
        firstName: item.firstName,
        groups: item.groups,
        lastName: item.lastName,
        owners: item.owners,
        tags: (
          <Box display={"flex"}>
            {item.tags.length > 0 &&
              getAssigneeTableCell(item.tags, item.emailId)}
          </Box>
        ),
        action: !props.hideActions && (
          <Box sx={{ display: "flex" }}>
            {hasContactUpdateAccess && (
              <Tooltip title="Add / Edit Tags" placement="top" arrow>
                <EditIcon
                  htmlColor={"#1a0224"}
                  style={{ margin: "0px 8px -7px 17px" }}
                  onClick={() => {
                    updateContact(item);
                  }}
                />
              </Tooltip>
            )}
            {hasContactDeleteAccess && (
              <DeleteOutlineIcon
                onClick={() =>
                  props.urlId
                    ? openGroupDeleteModel(item.emailId)
                    : openDeleteModel(item.emailId)
                }
                htmlColor={primaryGreenColor}
              />
            )}
          </Box>
        ),
      };
    });
    setFormattedTableData(resultantData);
  };

  const getAssigneeTableCell = (assignees: any, emailId: any) => {
    const assigneesList = assignees.map((item: any) => item.name);
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Chip
          label={assigneesList[0]}
          sx={{
            borderRadius: "5px",
            ...mediumFont,
            fontSize: "15px",
            backgroundColor: "#fcf5ff",
          }}
        />
        {assigneesList?.length > 1 && (
          <Typography ml={1} sx={{ ...mediumFont }}>
            + {assigneesList.length - 1} more
          </Typography>
        )}
      </Box>
    );
  };

  const openDeleteModel = React.useCallback((email: string) => {
    setOpenDeleteModal(true);
    setEmail(email);
  }, []);

  const openGroupDeleteModel = React.useCallback((email: any) => {
    setGroupDeleteModal(true);
    setClickedEmailId(email);
  }, []);

  const openRecipientsModal = React.useCallback((email: any) => {
    setIsRecipientModalOpen(true);
    setClickedEmailId(email);
  }, []);

  const updateContact = React.useCallback((rowdata: any) => {
    setUpdateContactModal(true);
    setUpdateRowData(rowdata);
  }, []);

  const handleDownload = async () => {
    try {
      props.setIsLoading(true);
      const response = await contactReportDownload(
        props.dropDownValue ?? "all"
      );
      openSuccessNotification(response.message);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  return (
    <>
      <Box
        id="contact_display_table"
        sx={{ minWidth: "300px", width: "100%", overflow: "auto" }}
      >
        <CustomTable
          headers={contactTableHeader}
          rows={formattedTableData}
          paginationCount={props.contactCount}
          handlePageChange={props.pageChangeHandler}
          pageNumber={props.contactsFilters.page}
          isLoading={props.isLoading}
          handlePerPageData={props.perPageRowsChangeHandler}
          perPageData={props.contactsFilters.rowsPerPage}
          rowsPerPage={props.contactsFilters.rowsPerPage}
          sortConfig={props.sortConfig}
          setSortConfig={props.setSortConfig}
          sortingEntity={"Contact"}
          isExportCSV={isAdmin() && shouldExportButtonDisplay()}
          onClickExportCSV={handleDownload}
          noDataMessage={
            isTruthy(props.urlId)
              ? `It seems like ${props.name} group has no contacts.`
              : "Sorry, we have got no contacts for you!"
          }
        />
      </Box>
      {isRecipientModalOpen && (
        <EmailRecipientModal
          isRecipientModalOpen={isRecipientModalOpen}
          setIsRecipientModalOpen={setIsRecipientModalOpen}
          recipientEmailId={clickedEmailId}
        />
      )}
      {deleteModal && (
        <DeleteModal
          deleteModal={deleteModal}
          setOpenDelete={setOpenDeleteModal}
          email={email}
          fetchAllContacts={props.fetchTableData}
        />
      )}
      {groupDeleteModal && props.urlId && (
        <GroupDeleteModal
          setGroupDeleteModal={setGroupDeleteModal}
          groupDeleteModal={groupDeleteModal}
          groupEmail={clickedEmailId}
          name={props.name}
          groupId={props.urlId}
          table={props.fetchTableData}
          getGroupInfo={props.fetchGroupInfoById}
        />
      )}
      <UpdateContactModal
        updateUserDialogOpen={updateContactModal}
        handleUpdateDialogClose={() => setUpdateContactModal(false)}
        selectedRowData={updateRowData}
        tableData={props.fetchTableData}
        setIsLoading={props.setIsLoading}
      />
      {props.addRecipientModal && props.urlId && (
        <AddRecipientModal
          setIsLoading={props.setIsLoading}
          addRecipientModal={props.addRecipientModal}
          handleAddRecipientDialogClose={() =>
            props?.setAddRecipientModal?.(false)
          }
          groupId={props.urlId}
          getContacts={props.fetchTableData}
          getGroupInfo={props.fetchGroupInfoById}
        />
      )}
    </>
  );
};

export default ContactTable;
