import { Box, Typography } from "@mui/material";
import { CustomButton, CustomDialog } from "global/components";
import Confirmation from "assets/images/Confirmation.svg";
import IntegrationStyles from "screens/Integration/Integration.styles";

interface CustomProps {
  showDialogModal: boolean;
  setShowDialogModal: Function;
  unexecutedCampaigns: number;
  setUnexecutedCampaigns: Function;
  handleDisconnect: Function;
}

const UnexecutedCampaignsAlertModal = (props: CustomProps) => {
  const classes = IntegrationStyles;

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={Confirmation} alt="Confirmation Image" />
      </Box>
    );
  };

  const handleCloseModel = () => {
    props.setUnexecutedCampaigns(0);
    props.setShowDialogModal(false);
  };

  const dialogTitleContent = () => {
    return (
      <>
        <Box sx={classes.titleWrapper}>
          <Box sx={classes.titleStyle}>Confirmation</Box>
        </Box>
      </>
    );
  };

  const dialogBody = () => {
    return (
      <>
        <Box sx={classes.contentWrapper}>
          <Typography sx={classes.contentStyle}>
            {`You have ${props.unexecutedCampaigns} unexecuted campaigns. Clicking on disconnect would delete the unexecuted campaigns. Are you
            sure you want to disconnect?`}
          </Typography>
        </Box>
      </>
    );
  };

  const dialogFooterContent = () => {
    return (
      <>
        <Box sx={classes.footerWrapper}>
          <CustomButton
            label="Cancel"
            customClasses={classes.buttonWhiteBg}
            onClick={() => handleCloseModel()}
          />
          <CustomButton
            label="Disconnect"
            onClick={() => props.handleDisconnect()}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <CustomDialog
        isDialogOpen={props.showDialogModal}
        closable
        closeButtonVisibility
        dialogHeaderContent={dialogHeaderContent()}
        handleDialogClose={handleCloseModel}
        dialogTitleContent={dialogTitleContent()}
        dialogBodyContent={dialogBody()}
        dialogFooterContent={dialogFooterContent()}
        width="450px"
      />
    </>
  );
};

export default UnexecutedCampaignsAlertModal;
