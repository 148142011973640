import { useState, useEffect } from "react";
import { CustomButton, CustomDialog, CustomInput } from "global/components";
import { Box, Chip, Grid, Typography } from "@mui/material";
import CreateCampaignStyle from "screens/CreateCampaign/createCampaign.styles";
import strings from "global/constants/StringConstants";
import _ from "lodash";
import userUpdateImg from "assets/images/uploadUser.svg";
import { useAppSelector } from "utils/hooks";
import { selectEmail } from "redux/authSlice";

interface TestMailProps {
  setTestMail: Function;
  isTestMail: boolean;
  onMultipleTestMail: Function;
}

const TestMailModal = ({
  isTestMail,
  setTestMail,
  onMultipleTestMail,
}: TestMailProps) => {
  const classes = CreateCampaignStyle;
  const loggedInUserEmail = useAppSelector(selectEmail);
  const [addTestEmail, setTestEmail] = useState<any>({
    value: "",
    error: "",
  });
  const [finalTestEmail, setFinalTestEmail] = useState<string[]>([
    loggedInUserEmail,
  ]);

  useEffect(() => {
    setTestEmail({ value: "", error: "" });
    setFinalTestEmail([loggedInUserEmail]);
  }, [isTestMail]);

  const modalHeader = () => {
    return (
      <Box display="flex">
        <img src={userUpdateImg} alt="Image not found!" />
      </Box>
    );
  };

  const updateUserDialogTitle = () => {
    return (
      <Box>
        <Typography sx={classes.boldFonts}>
          Add emails to receive the test mail
        </Typography>
      </Box>
    );
  };

  const deleteEmailHandler = (indexValue: number) => {
    setFinalTestEmail(
      finalTestEmail.filter((item: any, index: number) => index !== indexValue)
    );
  };

  const validateEmail = () => {
    let isValid = true;
    const clonedAddTestEmailValue = _.cloneDeep(addTestEmail);
    const checkExistingValue = finalTestEmail?.some(
      (item: any) => item === (addTestEmail?.value).toLowerCase().trim()
    );
    if (checkExistingValue) {
      clonedAddTestEmailValue.error = "You cannot add duplicate emails.";
      isValid = false;
    }
    if (!strings.regex.test(addTestEmail.value)) {
      clonedAddTestEmailValue.error = "Please enter valid email";
      isValid = false;
    }
    if (finalTestEmail?.length > strings.TEST_MAIL_LIMIT) {
      clonedAddTestEmailValue.error = `Test emails can't be sent to more than ${
        strings.TEST_MAIL_LIMIT + 1
      } emails`;
      isValid = false;
    }
    setTestEmail({ ...clonedAddTestEmailValue });
    return isValid;
  };

  const addMultipleEmailHandler = () => {
    if (validateEmail()) {
      const bulkEmail: any[] = [];
      bulkEmail.push(...finalTestEmail, addTestEmail.value);
      const filterEmailValue = _.uniqWith(bulkEmail, _.isEqual);
      setFinalTestEmail(filterEmailValue);
      setTestEmail({ value: "", error: "" });
    }
  };

  const updateUserDialogBody = () => {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
            <CustomInput
              id="email"
              name="email"
              propsToInputElement={{ maxLength: strings.USER_EMAIL_LIMIT }}
              placeHolder="Enter Email"
              error={addTestEmail.error}
              onChange={(event: any) =>
                setTestEmail({ value: event.target.value, error: "" })
              }
              value={addTestEmail.value}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <CustomButton
              label="Add Email"
              onClick={() => addMultipleEmailHandler()}
              customClasses={{ width: "100%", marginTop: "6px" }}
              disabled={_.isEmpty(addTestEmail.value)}
            />
          </Grid>
        </Grid>
        <Box display={"flex"} flexWrap={"wrap"}>
          {finalTestEmail &&
            finalTestEmail?.map((item: string, index: number) => (
              <Chip
                key={index}
                label={item}
                sx={classes.addTagChip}
                variant="filled"
                onDelete={() => deleteEmailHandler(index)}
              />
            ))}
        </Box>
      </Box>
    );
  };

  const updateUserDialogFooter = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            id="test_mail_cancel_button"
            label="Cancel"
            onClick={() => setTestMail(false)}
            customClasses={classes.cancelButtonStyle}
          />
          <CustomButton
            id="test_mail_submit_button"
            label="Test Mail"
            onClick={() => {
              setTestMail(false);
              onMultipleTestMail(finalTestEmail);
            }}
            disabled={_.isEmpty(finalTestEmail)}
          />
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <CustomDialog
        isDialogOpen={!!isTestMail}
        closable
        closeButtonVisibility
        handleDialogClose={() => setTestMail(false)}
        dialogHeaderContent={modalHeader()}
        dialogTitleContent={updateUserDialogTitle()}
        dialogBodyContent={updateUserDialogBody()}
        dialogFooterContent={updateUserDialogFooter()}
        width="500px"
      />
    </>
  );
};

export default TestMailModal;
