import strings from "global/constants/StringConstants";
import regularCampaigns from "assets/images/regularCampaigns.svg";
import sequentialCampaigns from "assets/images/sequentialCampaigns.svg";
import socialCampaigns from "assets/images/socialCampaigns.svg";
import { hasAccessTo } from "utils/AuthorizationManager";

export const createCampaignTypes = () => [
  {
    title: "Regular Campaigns",
    icon: regularCampaigns,
    description:
      "Utilize Mailzzy's AI capabilities to generate captivating subject lines for creating spam-free and engaging email campaigns to effectively target your audience.",
    onClickText: strings.CREATECAMPAIGN,
    isUserPermitted: hasAccessTo(strings.campaign, strings.addPermission),
  },
  {
    title: "Sequential Campaigns",
    icon: sequentialCampaigns,
    description:
      "Create and schedule multiple email marketing campaigns, all at once at a single place for streamlined management.",
    onClickText: strings.SEQUENCES,
    isUserPermitted: hasAccessTo(strings.campaign, strings.addPermission),
  },
  {
    title: "Social Campaigns",
    icon: socialCampaigns,
    description:
      "Create and publish social media campaigns seamlessly across multiple social channels in one central platform.",
    onClickText: strings.SOCIAL,
    isUserPermitted:
      hasAccessTo(strings.campaign, strings.addPermission) &&
      hasAccessTo(strings.social, strings.addPermission),
  },
  // {
  //   title: "A/B Campaigns",
  //   icon: socialCampaigns,
  //   description:
  //     "Execute your campaigns by utilizing the powerful machine learning capabilities of Mailzzy.",
  //   onClickText: "abtesting",
  // },
];
