import { Box, Typography } from "@mui/material";
import { NewCustomButton } from "global/components";
import campaignDetailsSectionsStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/CampaignDetailsSections/CampaignDetailsSections.style";
import { MailingListTable } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/MailingListTable";
import { useState } from "react";
import _ from "lodash";
import { hasAccessTo } from "utils/AuthorizationManager";
import strings from "global/constants/StringConstants";

interface CustomProps {
  setCampaignData: Function;
  campaignData: any;
  parentId: string;
  allowedEmailCount: number;
  groupType: string;
}

const ToSection = (props: CustomProps) => {
  const classes = campaignDetailsSectionsStyles;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const hasContactFetchAccess = hasAccessTo(
    strings.contact,
    strings.fetchPermission
  );
  const hasCampaignerFetchAccess = hasAccessTo(
    strings.campaigner,
    strings.fetchPermission
  );

  const hasCampaignExecutionPermission = () => {
    return hasCampaignerFetchAccess && hasContactFetchAccess;
  };

  const editModeView = () => {
    return (
      <>
        <Box mb={1} sx={classes.viewModeBox}>
          <Box>
            <Typography sx={classes.headerLabel}>To</Typography>
            <Typography sx={classes.headerSubDescription}>
              Who are you sending this email to?
            </Typography>
          </Box>
          <Box>
            <NewCustomButton
              id={"to_edit_close_button"}
              label={"Close"}
              customClasses={classes.buttonStyles}
              onClick={() => setIsEditMode(false)}
            />
          </Box>
        </Box>
        {hasCampaignExecutionPermission() ? (
          !props.parentId && (
            <MailingListTable
              campaignData={props.campaignData}
              setCampaignData={props.setCampaignData}
              allowedEmailCount={props.allowedEmailCount}
            />
          )
        ) : (
          <Typography variant="body1" sx={classes.permissionMessage}>
            * You don't have permission to execute the campaigns. Please contact
            your account administrator.
          </Typography>
        )}
      </>
    );
  };

  const getSubDescription = () => {
    return props.parentId ? (
      <Typography sx={classes.headerSubDescription}>
        {props.groupType === strings.FollowUp
          ? "The email will be sent to those in the audience who have not opened the email of the selected campaign to retarget."
          : "The email will be sent to those in the audience who have clicked or opened the email of the selected campaign to retarget."}
      </Typography>
    ) : (
      props.campaignData.groups.map((mailingAudience: any) => {
        return (
          <Box>
            <Typography sx={classes.headerSubDescription}>
              {mailingAudience.type}:{" "}
              <Box component={"span"} sx={classes.headerSubDescription}>
                {mailingAudience.value
                  .map(
                    (audience: { id: string; name: string }) => audience.name
                  )
                  .join(", ")}
              </Box>
            </Typography>
          </Box>
        );
      })
    );
  };

  const viewModeView = () => {
    return (
      <Box mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box width="70%">
          <Typography sx={classes.headerLabel}>To</Typography>
          <Typography sx={classes.headerSubDescription}>
            {getSubDescription()}
          </Typography>
        </Box>
        {!props.parentId && (
          <Box>
            <NewCustomButton
              id="to_edit_button"
              label={
                !_.isEmpty(props.campaignData?.groups[0]["value"])
                  ? "Edit Recipients"
                  : "Add Recipients"
              }
              onClick={() => setIsEditMode(true)}
              customClasses={classes.buttonStyles}
            />
          </Box>
        )}
      </Box>
    );
  };

  return <Box>{isEditMode ? editModeView() : viewModeView()}</Box>;
};

export default ToSection;
