import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  SelectChangeEvent,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import uploadGroupStyles from "global/components/UploadGroups/UploadGroups.styles";
import {
  uploadContactGroups,
  getContactGroupTypes,
  getFileColumnType,
} from "screens/CampaignGroups/CampaignGroups.service";
import { DropzoneAreaBase } from "react-mui-dropzone";
import {
  getFileExtension,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import { CustomButton, CustomDialog, CustomInput } from "global/components";
import notifiers from "global/constants/NotificationConstants";
import {
  expectedColumnNames,
  uploadGroupDynamicCSVValidation,
  uploadGroupField,
  uploadGroupValidation,
} from "screens/CampaignGroups/UploadGroupTypesValidation";
import UploadGroupModal from "assets/images/UploadGroupModal.svg";
import { ReactComponent as dropZoneDropZone } from "assets/icons/dropZoneDropZone.svg";
import AddContactGroupWithCsv from "screens/CampaignGroups/Component/AddContactGroupWithCsv";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { isTrial } from "redux/authSlice";
import { useAppSelector } from "utils/hooks";
import strings from "global/constants/StringConstants";

interface CustomProps {
  showDialog: boolean;
  handleDialogClose: Function;
  tableData: Function;
}

const UploadGroup = (props: CustomProps) => {
  const classes = uploadGroupStyles;
  const [formFields, setFormFields] = useState<any>(uploadGroupField());
  const [groupTypes, setGroupTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [parsingCsvData, setParsingCsvData] = useState<any>({
    isOpen: false,
    csvData: {},
  });
  const [isColumnMatch, setColumnMatch] = useState<boolean>(false);
  const [columnMapperJson, setColumnMapperJson] = useState<any>();
  const isTrialAccount = useAppSelector(isTrial);

  useEffect(() => {
    getGroupTypes();
  }, []);

  useEffect(() => {
    setFormFields(uploadGroupField());
  }, [props.handleDialogClose]);

  const handleFieldChange = (fieldName: string, updatedValue: any) => {
    if (fieldName !== "groupCSV" && strings.groupNameRegex.test(updatedValue)) {
      setFormFields({
        ...formFields,
        [fieldName]: {
          value: updatedValue,
          error: "",
        },
      });
    }
    if (fieldName === "groupCSV") {
      setFormFields({
        ...formFields,
        [fieldName]: {
          value: updatedValue,
          error: "",
        },
      });
    }
  };

  const handleGroupNameOnChange = (event: React.ChangeEvent<any>) => {
    handleFieldChange(event.target.name, event.target.value);
  };

  const handleGroupTypeOnchange = (event: SelectChangeEvent) => {
    handleFieldChange(event.target.name, event.target.value);
  };

  const handleOnDropZoneFile = (event: any) => {
    handleFieldChange("groupCSV", event[0]);
  };

  const handleValidation = () => {
    const { errors, isValid } = uploadGroupValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const handleValidationDynamic = () => {
    const { errors, isValid } = uploadGroupDynamicCSVValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const handleDeleteDropZoneFile = () => {
    handleFieldChange("groupCSV", "");
    setColumnMatch(false);
  };

  const getGroupTypes = async () => {
    try {
      const groupType = await getContactGroupTypes();
      setGroupTypes(groupType);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const uploadContactMailingList = async (
    isAsyncUpload: boolean,
    contactGroupUpload?: any
  ) => {
    if (formFields.groupName.value.length <= 50 && handleValidation()) {
      try {
        setLoading(true);
        const data = new FormData();
        const file = formFields?.groupCSV?.value.file;
        data.append("file", file);
        data.append("groupName", formFields.groupName.value.trim());
        isAsyncUpload &&
          data.append("columnMapperJson", JSON.stringify(contactGroupUpload));
        const response = await uploadContactGroups(
          data,
          formFields.groupType.value,
          isAsyncUpload
        );
        openSuccessNotification(response.message);
        props.handleDialogClose();
        await props.tableData();
        setLoading(false);
      } catch (error: any) {
        // As using different way to call the api
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
        setLoading(false);
      }
    }
  };

  const getDialogTitle = () => {
    return <Box sx={classes.mainHeader}>Upload Group</Box>;
  };

  const checkExpectedColumnHaveSameElement = (
    expectedColumnNames: string[],
    actualColumnNames: { [key: string]: string }
  ) => {
    if (expectedColumnNames.length !== Object.keys(actualColumnNames).length) {
      return false;
    }

    return expectedColumnNames.every((column) =>
      actualColumnNames.hasOwnProperty(column)
    );
  };

  const getParsedCSVColumnNames = async (event: any) => {
    if (handleValidationDynamic()) {
      setLoading(true);
      try {
        const file = event[0].file;
        const formData = new FormData();
        formData.append("file", file);
        if (file.size > 85000000) {
          setLoading(false);
          return openErrorNotification(
            `The ${file.name} file exceeds the size limit of 85 MB.`
          );
        }
        if (formData !== undefined) {
          if (["csv"].includes(getFileExtension(file.name))) {
            const response = await getFileColumnType(formData);

            // Check if the actual column names match the expected column names
            const columnsMatch = checkExpectedColumnHaveSameElement(
              expectedColumnNames,
              response
            );

            if (columnsMatch) {
              setColumnMapperJson({
                EMAIL_ID: "emailId",
                FIRST_NAME: "firstName",
                LAST_NAME: "lastName",
                CONTACT_NO: "contactNo",
                TAGS: "tagName",
              });
              handleOnDropZoneFile(event);
              setColumnMatch(false);
            } else {
              setParsingCsvData({
                ...parsingCsvData,
                isOpen: true,
                csvData: response,
              });
              handleOnDropZoneFile(event);
              setColumnMatch(true);
            }
            setLoading(false);
          } else {
            openErrorNotification("Please upload document in csv format!");
            setLoading(false);
          }
        } else {
          openErrorNotification(
            "No document selected, please upload document again."
          );
          setLoading(false);
        }
      } catch (error: any) {
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
        setLoading(false);
      }
    }
  };

  const getDialogBody = () => {
    return (
      <Grid xs={12} sm={12} md={12} lg={12}>
        <Grid style={{ height: "300px" }}>
          <CustomInput
            required
            label="Group Name"
            id="groupname-input"
            placeHolder="Group name"
            name="groupName"
            value={formFields.groupName?.value}
            onChange={handleGroupNameOnChange}
            error={
              !isTruthy(formFields.groupName?.value) &&
              formFields.groupName?.error
            }
          />
          {isTruthy(formFields.groupName?.value) &&
            formFields.groupName?.value?.length > 50 && (
              <FormHelperText error sx={classes.errorStyle}>
                Group name should be less than or equal to 50 characters
              </FormHelperText>
            )}
          <Stack direction="column">
            <InputLabel id="simple-select" sx={classes.input} shrink>
              Group Type{" "}
              <Box ml={0.4} sx={classes.star}>
                *
              </Box>
            </InputLabel>
            <Select
              sx={classes.groupTypeStyle}
              id="groupType"
              value={formFields.groupType?.value}
              name="groupType"
              displayEmpty
              renderValue={
                formFields.groupType?.value !== ""
                  ? undefined
                  : () => " Select Group Type"
              }
              onChange={handleGroupTypeOnchange}
              error={
                formFields.groupType?.value?.length < 4 &&
                formFields.groupType?.error?.length !== 0
              }
            >
              {groupTypes.map((data) => (
                <MenuItem value={data} sx={classes.groupTypeOptions}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          {!isTruthy(formFields.groupType?.value) && (
            <FormHelperText error sx={classes.errorStyle}>
              {formFields.groupType?.error}
            </FormHelperText>
          )}

          <InputLabel sx={classes.input} shrink>
            Upload File{" "}
            <Box ml={0.4} sx={classes.star}>
              *
            </Box>
          </InputLabel>
          <Box sx={classes.dropZoneHeader}>
            <DropzoneAreaBase
              fileObjects={[]}
              dropzoneText={"Drag files here or select files to upload"}
              acceptedFiles={[".csv"]}
              onAdd={(event: any) => {
                getParsedCSVColumnNames(event);
              }}
              maxFileSize={200000000} //200 MB files limit to show the size limit error above 85 MB
              filesLimit={1}
              showPreviews={true}
              showAlerts={false}
              Icon={dropZoneDropZone}
            />
            <Typography sx={classes.warningContent}>
              * The file should be in the .csv format only and file size limit
              is 85 MB.
            </Typography>
            {formFields.groupCSV.error && (
              <FormHelperText error sx={classes.errorStyle}>
                {formFields.groupCSV.error}
              </FormHelperText>
            )}
            {isTruthy(formFields?.groupCSV?.value?.file) && (
              <Chip
                sx={classes.previewChip}
                label={formFields?.groupCSV?.value.file?.path}
                variant="filled"
                onDelete={handleDeleteDropZoneFile}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const getDialogFooter = () => {
    return (
      <>
        {!isColumnMatch && (
          <Box sx={classes.footerWrapper}>
            <CustomButton
              customClasses={classes.buttonWhiteBg}
              label="Cancel"
              onClick={() => {
                props.handleDialogClose();
              }}
              id="campaign_group_cancel_button"
            />
            <CustomButton
              label="Upload"
              loading={loading}
              onClick={() => {
                uploadContactMailingList(false);
              }}
              id="campaign_group_upload_button"
            />
            {!isTrialAccount && (
              <CustomButton
                id="campaign_group_async_upload_button"
                label="Large Upload"
                onClick={() => {
                  uploadContactMailingList(true, columnMapperJson);
                }}
                loading={loading}
                customClasses={classes.largeUploadButton}
              />
            )}
          </Box>
        )}
      </>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={UploadGroupModal} alt="" />
      </Box>
    );
  };

  const dynamicCsvParsingData = () => {
    return (
      <AddContactGroupWithCsv
        isOpenCsvModal={parsingCsvData}
        setIsOpenCsvModal={setParsingCsvData}
        formFields={formFields}
        handleDialogClose={props.handleDialogClose}
        handleValidation={handleValidation}
        setFormFields={setFormFields}
        setColumnMatch={setColumnMatch}
        uploadContactMailingList={uploadContactMailingList}
      />
    );
  };

  return (
    <>
      <CustomDialog
        dialogHeaderContent={dialogHeaderContent()}
        isDialogOpen={props.showDialog}
        closable
        handleDialogClose={props.handleDialogClose}
        dialogTitleContent={getDialogTitle()}
        dialogBodyContent={getDialogBody()}
        dialogFooterContent={getDialogFooter()}
        closeButtonVisibility={true}
        width="727px"
      />
      {dynamicCsvParsingData()}
      <CustomLoader isLoading={loading} />
    </>
  );
};

export default UploadGroup;
