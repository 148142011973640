import { useEffect, useState, useRef } from "react";
import {
  Chip,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  Box,
  Tooltip,
  Divider,
  List,
  ListItem,
  InputAdornment,
  TextField,
} from "@mui/material";
import { CustomAppHeader, CustomButton, CustomPaper } from "global/components";
import CampaignsStyles from "screens/CampaignsNew/Campaigns/Campaigns.styles";
import CachedIcon from "@mui/icons-material/Cached";
import { CustomTable } from "global/components";
import {
  campaignUserData,
  showViewPopoverType,
  campaignDetailTableType,
  SortingConfig,
  GlobalInterval,
} from "models/interfaces";
import {
  deleteEmailRequest,
  getCampaignsCountByDates,
  getCampaignsByDates,
  getSearchedCampaignsByDates,
  getSearchedCampaignsCountsByDates,
  downloadReportCampaignSearchDetailByDates,
  downloadReportCampaignDetailByDates,
} from "screens/CampaignsNew/Campaigns/Campaign.services";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  openErrorNotification,
  isTruthy,
  getFormattedStatsCount,
  translateFirstAndLastName,
  validatePageQuery,
  validateStatusQuery,
  validatePerPageQuery,
  validateCategoryQuery,
  debounceEventHandler,
  openSuccessNotification,
  convertESTtoUserLocalDateAndTime,
  handleIntervalChange,
} from "helpers/methods";
import RequestedIcon from "assets/icons/requested.png";
import SuccessIcon from "assets/icons/succes.png";
import SentIcon from "assets/icons/sent.svg";
import FailedIcon from "assets/icons/failed.png";
import UnsubscribeIcon from "assets/icons/unsubscribe.png";
import OpenIcon from "assets/icons/open.png";
import clickIcon from "assets/icons/click.png";
import viewIcon from "assets/images/view.svg";
import deleteIcon from "assets/images/Delete.svg";
import {
  campaignStatusOption,
  campaignerHeader,
  campaignerHeaderSubmitted,
  campaignerHeaderDraft,
  campaignStatusOptionUser,
  campaignerHeaderAll,
  campaignTabContent,
  getInitialCampaignFilterState,
} from "screens/CampaignsNew/Campaigns/CampaignsData";
import { darkPurpledColor, theme } from "utils/styles";
import strings from "global/constants/StringConstants";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import urls from "global/constants/UrlConstants";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import notifiers from "global/constants/NotificationConstants";
import { selectIsManager } from "redux/authSlice";
import { useAppSelector } from "utils/hooks";
import RetargetIcon from "assets/icons/retarget.svg";
import DeleteBulkEmailModel from "screens/CampaignsNew/Campaigns/components/DeleteBulkEmailModel";
import { useTitle } from "utils/UseTitle";
import CustomTabs from "global/components/CustomTabs/CustomTabs";
import { Stack } from "@mui/system";
import { hasAccessTo } from "utils/AuthorizationManager";
import { useDispatch } from "react-redux";
import {
  selectCampaignRetentionDuration,
  selectInterval,
} from "redux/persistSlice";
import { changeRun, selectIsTourActive } from "redux/tourSlice";
import { campaignFilters } from "screens/CampaignsNew/Campaigns/CampaignInterfaces";
import { getOptions } from "screens/Dashboard/DashboardData";
import CustomDatePicker from "global/components/CustomDatePicker/CustomDatePicker";

const popOverData = [
  {
    img: RequestedIcon,
    text: "Requested",
  },
  {
    img: SentIcon,
    text: "Sent",
  },
  {
    img: SuccessIcon,
    text: "Success",
  },
  {
    img: FailedIcon,
    text: "Failed", //Unconfirmed
  },
  {
    img: UnsubscribeIcon,
    text: "Unsubscribed",
  },
  {
    img: OpenIcon,
    text: "Opened", //Open
  },
  {
    img: clickIcon,
    text: "Clicked", //Click
  },
];

const getColors = {
  Completed: "#B2E1CA",
  Submitted: "#FFEA9A",
  Draft: "#F5DADF",
  Failed: "#FFA19D",
  InProgress: "#E0D6FF",
  Sent: "#E0D6FF",
  ConfirmationPending: "#E0D6FF",
} as { [key: string]: string };

const Campaigns = () => {
  useTitle(strings.CampaignsListTitle);
  const classes = CampaignsStyles;
  const history = useHistory();
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(useLocation().search);
  const pageFromUrl = validatePageQuery(urlParams.get("page"));
  const statusFromUrl = validateStatusQuery(urlParams.get("status"));
  const perPageFromUrl = validatePerPageQuery(urlParams.get("perPage"));
  const categoryFromUrl = validateCategoryQuery(urlParams.get("category"));
  const searchFromUrl = urlParams.get("search");

  const isManager = useAppSelector(selectIsManager);
  const interval = useAppSelector(selectInterval);
  const isTourActive = useAppSelector(selectIsTourActive);
  const [campaignFilters, setCampaignFilters] = useState<campaignFilters>(
    getInitialCampaignFilterState(
      categoryFromUrl,
      statusFromUrl,
      pageFromUrl,
      searchFromUrl ?? "",
      perPageFromUrl,
      interval
    )
  );
  const [formattedTableData, setFormattedTableData] = useState<
    campaignUserData[]
  >([]);
  const searchRef = useRef<any>("");

  const [count, setCount] = useState(0);
  const [headerData, setheaderData] = useState(campaignerHeader);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [campaignToDelete, setCampaignToDelete] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortingConfig[]>([]);
  const campaignRetention = useAppSelector(selectCampaignRetentionDuration);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  const hasCampaignDeleteAccess = hasAccessTo(
    strings.campaign,
    strings.deletePermission
  );

  useEffect(() => {
    if (isTourActive) {
      dispatch(changeRun(isLoading ? false : true));
    }
  }, [isLoading]);

  useEffect(() => {
    const { status, campaignCategory, searchText, perPageData, page } =
      campaignFilters;

    searchRef.current.value = searchText;
    window.history.replaceState(
      null,
      "",
      `?page=${page}&perPage=${perPageData}&status=${status}&category=${campaignCategory}&search=${searchText}`
    );
    setTableHeader(status);
    getTableDataAccordingCustomDate();
  }, [campaignFilters, sortConfig]);

  const handleCampaignsDropdown = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const campaignCategory = event.target.value;
    setCampaignFilters({
      ...campaignFilters,
      campaignCategory,
      page: 1,
    });
  };

  const handlePerPageData = (event: any) => {
    const perPageData = event.target.value;
    setCampaignFilters({
      ...campaignFilters,
      perPageData,
      page: 1,
    });
  };

  const handleTabs = (status: string) => {
    searchRef.current.value = "";
    setCampaignFilters({
      ...campaignFilters,
      searchText: "",
      page: 1,
      status,
    });
    setTableHeader(status);
    setSortConfig([]);
  };

  const setIntervalInCampaignFilter = (newInterval: GlobalInterval) => {
    setCampaignFilters({
      ...campaignFilters,
      interval: { ...newInterval },
      page: 1,
    });
  };

  const setTableHeader = (val: any) => {
    switch (val) {
      case strings.Completed:
        setheaderData(campaignerHeader);
        break;
      case strings.Submitted:
        setheaderData(campaignerHeaderSubmitted);
        break;
      case strings.Draft:
        setheaderData(campaignerHeaderDraft);
        break;
      case strings.All:
        setheaderData(campaignerHeaderAll);
        break;
      default:
    }
  };

  const refreshTableData = () => {
    getTableDataAccordingCustomDate();
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setCampaignFilters({
      ...campaignFilters,
      page,
    });
  };

  const deleteRequestBtn = (requestId: string) => {
    setCampaignToDelete(requestId);
    setOpenModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoading(true);
      setOpenModal(false);
      const response = await deleteEmailRequest(campaignToDelete);
      openSuccessNotification(response.message);
      getTableDataAccordingCustomDate();
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const showViewPopover = (record: any) => {
    let campaignCount: any = {};
    record.counts.map((eachCount: showViewPopoverType) => {
      campaignCount[eachCount?.name] = eachCount?.count;
    });
    return (
      <CustomPaper>
        <Box sx={classes.ViewPopOverWrapper}>
          <Typography sx={classes.popOverHeading}>Campaign Stats</Typography>
          <Divider />
          <Box sx={classes.popOverListItem}>
            <List sx={classes.popOverListItem}>
              {popOverData.map((item, index) => (
                <ListItem disablePadding sx={{ marginBottom: 1 }} key={index}>
                  <img src={item.img} alt="" />
                  <Typography variant="subtitle2" sx={{ ml: 1 }}>
                    {item.text}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ ml: 1 }}>
                    {getFormattedStatsCount(campaignCount[item.text] | 0)}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </CustomPaper>
    );
  };

  const customDialog = () => {
    return (
      <DeleteBulkEmailModel
        setOpenModal={setOpenModal}
        openModal={openModal}
        handleConfirmDelete={handleConfirmDelete}
      />
    );
  };

  const getCampaignName = (campaign: campaignDetailTableType) => {
    return (
      <>
        {campaign.status === strings.Submitted ||
        campaign.status === strings.InReview ? (
          <Typography sx={classes.campaignNameStyle}>
            {campaign.name}
          </Typography>
        ) : (
          <Typography
            onClick={() => {
              campaign.status === strings.Draft
                ? history.push(
                    `${urls.createCampaignViewPath}?id=` + campaign.id
                  )
                : history.push(
                    `${urls.campaignDetailsViewPath}?id=${campaign.id}`
                  );
            }}
            sx={classes.campaignNameStyle}
          >
            {campaign.name}
            {campaign.haveChildren && (
              <Tooltip title="Retargeted" placement="top">
                <img
                  src={RetargetIcon}
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "8px",
                  }}
                  alt=""
                />
              </Tooltip>
            )}
          </Typography>
        )}
      </>
    );
  };

  const getCampaignStats = (campaign: campaignDetailTableType) => {
    return (
      <Tooltip
        title={showViewPopover(campaign)}
        arrow
        placement="left"
        componentsProps={{
          tooltip: {
            sx: {
              background: "none",
            },
          },
        }}
      >
        <Button
          sx={classes.ActionBtn}
          onClick={() =>
            history.push(`${urls.campaignDetailsViewPath}?id=${campaign.id}`)
          }
          id="campaign_stats_button"
        >
          <Box component={"img"} src={viewIcon} /> <span>View</span>
        </Button>
      </Tooltip>
    );
  };

  const getActions = (campaign: campaignDetailTableType) => {
    return (
      <>
        {campaign.status === strings.Draft ||
        campaign.status === strings.Submitted ? (
          hasCampaignDeleteAccess && (
            <Button
              sx={classes.ActionBtn}
              onClick={() => deleteRequestBtn(campaign.id)}
              id="draft_delete_button"
            >
              <Box component={"img"} src={deleteIcon} /> <span>Delete</span>
            </Button>
          )
        ) : (
          <Tooltip
            title={showViewPopover(campaign)}
            arrow
            placement="left"
            componentsProps={{
              tooltip: {
                sx: {
                  background: "none",
                },
              },
            }}
          >
            <Button
              sx={classes.ActionBtn}
              onClick={() =>
                history.push(
                  `${urls.campaignDetailsViewPath}?id=${campaign.id}`
                )
              }
              disabled={campaign.status === strings.InReview}
              id="stats_delete_button"
            >
              <Box component={"img"} src={viewIcon} /> <span>View</span>
            </Button>
          </Tooltip>
        )}
      </>
    );
  };

  const getCampaignGroups = (campaign: campaignDetailTableType) => {
    return campaign.groups.map((data: any) => (
      <Typography style={{ maxWidth: "500px", wordBreak: "break-all" }}>
        {isTruthy(data.value)
          ? `${data.type}: ${data.value
              .map((value: any) => value.name)
              .join(",")}`
          : "No audience selected"}
      </Typography>
    ));
  };

  const getFormattedTableData = (rawCampaignsData: any[]) => {
    const formattedData = rawCampaignsData.map((campaign: any) => {
      return {
        CampaignGroups: getCampaignGroups(campaign),
        name: getCampaignName(campaign),
        subject: (
          <Typography>{translateFirstAndLastName(campaign.subject)}</Typography>
        ),
        SentOn: convertESTtoUserLocalDateAndTime(
          campaign.createdOn,
          "MMM DD, YYYY"
        ),
        owner: <Typography>{campaign.owner}</Typography>,
        CampaignStats: getCampaignStats(campaign),
        status: (
          <Chip
            label={campaign.status!}
            sx={[
              classes.chipStyle,
              {
                backgroundColor: getColors[campaign.status!] ?? "#ebebeb",
              },
            ]}
          />
        ),
        ScheduledOn: convertESTtoUserLocalDateAndTime(
          campaign.scheduleTime,
          "MMM DD, YYYY"
        ),
        Action: <Box sx={{ maxWidth: "250px" }}>{getActions(campaign)}</Box>,
      };
    });
    setFormattedTableData(formattedData);
  };

  const getTableDataAccordingCustomDate = async () => {
    try {
      const { status, campaignCategory, searchText, perPageData, page } =
        campaignFilters;
      setIsLoading(true);
      const campaignsCount = isTruthy(searchText)
        ? await getSearchedCampaignsCountsByDates(
            campaignCategory,
            status,
            interval,
            searchText
          )
        : await getCampaignsCountByDates(status, interval, campaignCategory);
      setCount(campaignsCount);
      await getTableDataAccordingToDates(
        campaignsCount,
        status,
        interval,
        campaignCategory,
        searchText,
        perPageData,
        page
      );
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getTableDataAccordingToDates = async (
    count: number,
    status: string,
    interval: GlobalInterval,
    campaignCategory: string,
    searchText: string,
    perPageData: number,
    page: number
  ) => {
    try {
      let tableData: any[] = [];
      const expectedPages = Math.ceil(count / perPageData);

      if (page <= expectedPages || count === 0) {
        tableData = isTruthy(searchText)
          ? await getSearchedCampaignsByDates(
              campaignCategory,
              status,
              interval,
              searchText,
              page,
              perPageData,
              sortConfig
            )
          : await getCampaignsByDates(
              status,
              interval,
              campaignCategory,
              page,
              perPageData,
              sortConfig
            );
        getFormattedTableData(tableData);
      } else {
        setCampaignFilters({
          ...campaignFilters,
          page: page - 1,
        });
      }
    } catch (error: any) {
      throw error;
    }
  };

  const campaignsDropDown = () => {
    return (
      <Stack
        direction="row"
        justifyContent={{ md: "left", lg: "center" }}
        alignItems="center"
        spacing={1}
        sx={{
          [theme.breakpoints.down("xl")]: {
            justifyContent: "flex-start",
            marginLeft: "5px",
          },
        }}
      >
        <Typography sx={classes.date}>
          {moment(interval.fromDate).format("LL")} to{" "}
          {moment(interval.toDate).format("LL")}
        </Typography>
        <CustomButton
          id="campaigns_refresh_button"
          onClick={refreshTableData}
          label={<CachedIcon htmlColor={darkPurpledColor} />}
          customClasses={classes.refreshBtn}
        />
        <FormControl sx={{ justifyContent: "center" }}>
          <Select
            id="campaigns_interval_dropdown"
            sx={classes.dropDownStyle}
            value={interval.label}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {getOptions(+campaignRetention)?.map((intervalData) => (
              <MenuItem
                value={intervalData.label}
                sx={classes.optionStyle}
                onClick={() =>
                  handleIntervalChange(
                    intervalData.label,
                    setIsDatePickerOpen,
                    setIntervalInCampaignFilter
                  )
                }
              >
                {intervalData.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    );
  };

  const campaignsTabs = () => {
    return (
      <>
        <Grid container xs={12} alignItems="center" sx={{ my: 1 }}>
          <Typography sx={classes.campaignTitle}>
            <span style={{ color: "#D7BBE2" }}>Campaigns</span> / Campaigns List
          </Typography>
        </Grid>
        <Stack
          direction={{
            xl: "row",
            lg: "column",
            md: "column",
            sm: "column",
            xs: "column",
          }}
          justifyContent="space-between"
        >
          <CustomTabs
            containerId="campaigns_status_tab"
            changeValue={(newValue: string) => {
              handleTabs(newValue);
            }}
            selected={campaignFilters.status}
            tabConfig={campaignTabContent}
          />
          <Stack
            direction={{
              xl: "row",
              lg: "column",
              md: "column",
              sm: "column",
              xs: "column",
            }}
            justifyContent="center"
            spacing={isManager ? 0 : 1}
          >
            {campaignsDropDown()}
            {CampaignsSearch()}
          </Stack>
        </Stack>
      </>
    );
  };

  const handleSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchText = event.target.value.trim();
    setCampaignFilters({
      ...campaignFilters,
      searchText,
      page: 1,
    });
  };

  const handleDownload = async () => {
    const { status, interval, campaignCategory } = campaignFilters;
    try {
      setIsLoading(true);
      const data = await downloadReportCampaignDetailByDates(
        status,
        interval,
        campaignCategory
      );
      openSuccessNotification(data.message);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleSearchDownload = async () => {
    const { status, interval, campaignCategory, searchText } = campaignFilters;
    try {
      setIsLoading(true);
      const data = await downloadReportCampaignSearchDetailByDates(
        status,
        interval,
        campaignCategory,
        searchText
      );
      openSuccessNotification(data.message);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const CampaignsSearch = () => {
    return (
      <Stack
        direction="row"
        spacing={1}
        sx={{ [theme.breakpoints.down("xl")]: { marginTop: "10px" } }}
      >
        {isManager && (
          <Box>
            <FormControl>
              <Select
                id="campaigns_category_dropdown"
                sx={{
                  ...classes.inputWrapperAddClasses,
                  ...classes.dropDownStyle,
                }}
                value={campaignFilters.campaignCategory}
                onChange={(e: any) => handleCampaignsDropdown(e)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {isManager &&
                  campaignStatusOption.map((data) => (
                    <MenuItem
                      key={data.id}
                      value={data.value}
                      sx={classes.optionStyle}
                    >
                      {data.label}
                    </MenuItem>
                  ))}
                {!isManager &&
                  campaignStatusOptionUser.map((campaignDropdownValue) => (
                    <MenuItem
                      key={campaignDropdownValue.id}
                      value={campaignDropdownValue.value}
                    >
                      {campaignDropdownValue.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Box id="campaigns_search_Field_container" sx={classes.searchWrapper}>
          <TextField
            id="campaigns_search_field"
            placeholder="Search text"
            sx={classes.searchInput}
            inputRef={searchRef}
            onChange={debounceEventHandler(
              handleSearchOnChange,
              strings.SEARCH_TIME_OUT
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Stack>
    );
  };

  const campaignsTable = () => {
    return (
      <Box
        id="campaigns_table"
        sx={{ minWidth: "300px", width: "100%", overflow: "auto" }}
      >
        <CustomTable
          headers={headerData}
          rows={formattedTableData}
          handlePageChange={handleChangePage}
          paginationCount={count}
          pageNumber={campaignFilters.page}
          isLoading={isLoading}
          handlePerPageData={handlePerPageData}
          perPageData={campaignFilters.perPageData}
          rowsPerPage={campaignFilters.perPageData}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
          sortingEntity={"Campaign"}
          isExportCSV={campaignFilters.status !== strings.Draft}
          onClickExportCSV={
            isTruthy(campaignFilters.searchText)
              ? handleSearchDownload
              : handleDownload
          }
        />
      </Box>
    );
  };

  return (
    <>
      <Grid>
        <CustomAppHeader className={classes.headerBackgroundColor}>
          {campaignsTabs()}
        </CustomAppHeader>
        <Box sx={classes.paperInnerWrapper}>
          {campaignsTable()}
          {customDialog()}
        </Box>
      </Grid>
      {isDatePickerOpen && (
        <CustomDatePicker
          pastMonths={+campaignRetention}
          isDatePickerOpen={isDatePickerOpen}
          setIsDatePickerOpen={setIsDatePickerOpen}
          callBackFunction={setIntervalInCampaignFilter}
        />
      )}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default Campaigns;
