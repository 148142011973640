import { useState, useRef, useEffect } from "react";
import { Grid, Stack, Typography, Box, InputAdornment } from "@mui/material";
import EmailEditor, { Design } from "react-email-editor";
import { CustomAppHeader, CustomButton, CustomInput } from "global/components";
import StepTwoStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepTwo/StepTwo.styles";
import { SpamBox } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepTwo/components/SpamBox";
import { isTruthy, openErrorNotification } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import {
  getAllTemplates,
  getSpamPrediction,
  subjectLineGeneration,
} from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignServices";
import { TemplatesModal } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepTwo/components/TemplatesModal";
import { handleStep3Validation } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignHelpers";
import SideBarRouterPrompt from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/component/SideBarRouterPrompt";
import { useAppSelector } from "utils/hooks";
import { isBlockingState } from "redux/blockingSlice";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import SubjectLinesModal from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepTwo/components/SubjectLinesModal";
import CreateCampaignStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignNew.styles";
import strings from "global/constants/StringConstants";
import urls from "global/constants/UrlConstants";

interface CustomProps {
  campaignData: any;
  setCampaignData: any;
  setActiveStep: any;
  parentId: any;
  setTemplateHtml: any;
  handleSaveAsDraft: any;
  isBlocking: boolean;
  setIsBlocking: Function;
  defaultFooterTemplate: Design | undefined;
  isRetargetOrFollowUp: boolean;
}

const classes = StepTwoStyles;
const createCampaignClasses = CreateCampaignStyles;

export const StepTwo = ({
  campaignData,
  setCampaignData,
  setActiveStep,
  parentId,
  setTemplateHtml,
  handleSaveAsDraft,
  isBlocking,
  setIsBlocking,
  defaultFooterTemplate,
  isRetargetOrFollowUp
}: CustomProps) => {
  const emailEditorRef = useRef<any>(null);
  const [spamPredictionValue, setSpamPredictionValue] = useState<number>(
    campaignData.data.spamPercentage ?? 0
  );
  const [color, setColor] = useState<string>("");
  const [spamText, setSpamText] = useState<string>("");
  const [topSpamKeywords, setTopSpamKeywords] = useState<any>({});
  const [spamLoading, setSpamLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chooseTemplateOpen, setChooseTemplateOpen] = useState<boolean>(false);
  const [templates, setTemplates] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [subjectLines, setSubjectLines] = useState<string[]>([]);
  const [isSubjectModalOpen, setIsSubjectModalOpen] = useState<boolean>(false);
  const [shouldCampaignDataUpdate, setShouldCampaignDataUpdate] =
    useState(false);
  const logoutBlockState = useAppSelector(isBlockingState);

  useEffect(() => {
    setIsBlocking(false);
  }, [logoutBlockState]);

  useEffect(() => {
    if (spamPredictionValue !== 0) {
      spanText(spamPredictionValue);
    }
  }, []);

  useEffect(() => {
    getTemplates();
    setIsBlocking(true);
  }, []);

  useEffect(() => {
    if (shouldCampaignDataUpdate) {
      setSubjectLines([]);
      setCampaignData({ ...campaignData, subject: { value: "", error: "" } });
    }
    setShouldCampaignDataUpdate(true);
  }, [campaignData.content]);

  const spamPredictionAPI = async () => {
    try {
      setSpamLoading(true);
      const data: any = await getEmailEditorHtml();
      const response = await getSpamPrediction(data.html);
      setTopSpamKeywords(response.topSpamKeywords);
      spanText(response.spamPrediction);
      setSpamPredictionValue(response.spamPrediction);
      setCampaignData((prevCampaignData: any) => ({
        ...prevCampaignData,
        data: {
          ...prevCampaignData.data,
          spamPercentage: response.spamPrediction,
        },
      }));
      setSpamLoading(false);
    } catch (error: any) {
      setSpamLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getEmailEditorHtml = () => {
    return new Promise((resolve, reject) => {
      emailEditorRef.current.editor?.exportHtml((data: any) => {
        if (data && data.html) {
          resolve(data);
        } else {
          reject(new Error("Export HTML failed"));
        }
      });
    });
  };

  const getTemplates = async () => {
    try {
      setIsLoading(true);
      const response = await getAllTemplates();
      setTemplates(response);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const spanText = (spamPredictionValue: number) => {
    let color = "#FF0000";
    let spamText = "This email will not end up in the inbox!";

    if (spamPredictionValue >= 0 && spamPredictionValue <= 40) {
      color = "#228B22";
      spamText = "Woohoo! This email will end up in the inbox!";
    } else if (spamPredictionValue <= 60) {
      color = "#FFA500";
      spamText = "Uh-huh! This email might end up in the inbox!";
    }

    setColor(color);
    setSpamText(spamText);
  };

  const loadDesign = () => {
    setDisabled(false);
    if (emailEditorRef.current) {
      if (campaignData.content) {
        // Draft Campaign
        emailEditorRef?.current?.editor?.loadDesign(
          JSON.parse(campaignData.content)
        );
      } else {
        // New Campaign with Default Mailzzy footer
        emailEditorRef?.current?.editor?.loadDesign(defaultFooterTemplate);
      }
    }
  };

  const onChooseTemplate = (jsonContent: string) => {
    setCampaignData({ ...campaignData, content: JSON.parse(jsonContent) });
    if (emailEditorRef.current && jsonContent) {
      emailEditorRef?.current?.editor?.loadDesign(JSON.parse(jsonContent));
    }
    setChooseTemplateOpen(false);
  };

  const handleValidation = () => {
    const { isValid, errors } = handleStep3Validation(campaignData);
    setCampaignData(errors);
    return isValid;
  };

  const exportHtml = (callback: any) => {
    emailEditorRef.current.editor?.exportHtml((data: any) => {
      const { design, html } = data;
      let tempCampaignData = {
        ...campaignData,
        content: JSON.stringify(design),
      };
      setCampaignData(tempCampaignData);
      setTemplateHtml(html);
      callback(tempCampaignData);
    });
  };

  const handleSave = async (isButtonNext: boolean) => {
    setShouldCampaignDataUpdate(false);
    if (!isButtonNext || handleValidation()) {
      exportHtml(() =>
        setActiveStep((prevStep: number) =>
          isButtonNext ? prevStep + 1 : prevStep - 1
        )
      );
      setSubjectLines([]);
    }
  };

  const handleStep3SaveDraft = () => {
    setShouldCampaignDataUpdate(false);
    exportHtml(handleSaveAsDraft);
  };

  const onChangeHandler = (event: any) => {
    setCampaignData({
      ...campaignData,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const getEmailEditor = () => {
    return (
      <Box style={{ minWidth: "100%", overflow: "auto", overflowY: "hidden" }}>
        <EmailEditor
          ref={emailEditorRef}
          onReady={loadDesign}
          projectId={strings.UNLAYER_PROJECT_ID}
          minHeight={800}
          options={{
            features: {
              textEditor: {
                tables: true,
              },
            },
            customJS: [urls.customSocialTool, urls.customVideoTool],
          }}
          style={{ display: "flex", flexWrap: "wrap" }}
        />
      </Box>
    );
  };

  const getHeaderButtons = () => (
    <Stack sx={createCampaignClasses.headerButtonStack}>
      <Box sx={createCampaignClasses.headerButtonStyle}>
        <CustomButton
          id="step_back_button"
          label="Back"
          onClick={() => {
            setIsBlocking(false);
            handleSave(false);
          }}
          disabled={disabled}
          customClasses={classes.headerButton}
        />
      </Box>
      <Box sx={createCampaignClasses.headerButtonStyle}>
        <CustomButton
          id="step3_choose_template_button"
          label="Choose Template"
          onClick={() => {
            setChooseTemplateOpen(true);
          }}
          disabled={disabled}
          customClasses={classes.headerButton}
        />
      </Box>
      {!parentId && (
        <Box sx={createCampaignClasses.headerButtonStyle}>
          <CustomButton
            id="step3_save_draft_button"
            label="Save as draft"
            disabled={disabled}
            onClick={() => {
              handleStep3SaveDraft();
              setIsBlocking(false);
            }}
            customClasses={classes.headerButton}
          />
        </Box>
      )}
      <Box sx={createCampaignClasses.headerButtonStyle}>
        <CustomButton
          id="step3_next_button"
          label="Next"
          disabled={disabled}
          onClick={() => {
            handleSave(true);
          }}
          customClasses={classes.headerButton}
        />
      </Box>
    </Stack>
  );

  const subjectLineGenerationHandler = async () => {
    try {
      setIsLoading(true);
      const data: any = await exportHtmlFromEditor();
      const {
        html,
        design: { body },
      } = data;

      const isEmpty = !checkIfContentsNonEmpty(body);

      if (!isEmpty) {
        if (subjectLines.length === 0 || isSubjectModalOpen) {
          const response = await subjectLineGeneration(html);
          setSubjectLines(response.subject);
        }
        setIsSubjectModalOpen(true);
      } else {
        openErrorNotification("Please choose a template or create one");
      }
    } catch (error) {
      handleExportError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportHtmlFromEditor = () => {
    return new Promise((resolve, reject) => {
      emailEditorRef.current.editor?.exportHtml(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  };

  const checkIfContentsNonEmpty = (body: any) => {
    return body?.rows?.some((row: any) =>
      row?.columns?.some((column: any) => column?.contents?.length > 0)
    );
  };

  const handleExportError = (error: any) => {
    setIsLoading(false);
    const errorMessage = error.message || notifiers.GENERIC_ERROR;
    openErrorNotification(errorMessage);
  };

  const getSubjectLineSection = () => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
          <CustomInput
            required
            id="step3_subject_field"
            label="Subject Line"
            name="subject"
            value={campaignData.subject?.value}
            onChange={onChangeHandler}
            placeHolder="Enter Subject Line"
            propsToInputElement={{ maxlength: 90 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span>{campaignData.subject?.value?.length} / 90</span>
                </InputAdornment>
              ),
            }}
            error={campaignData?.subject?.error}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
          <Box mt={{ lg: 4 }}>
            <CustomButton
              label="Generate Subject Line"
              onClick={() => {
                subjectLineGenerationHandler();
              }}
              disabled={disabled}
              customClasses={{ width: "200px" }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const getHeaderSection = () => (
    <Grid container mt={2} sx={classes.headerSection} px={2}>
      <Grid item md={4} xs={12} sx={classes.subjectLineGridItem}>
        {getSubjectLineSection()}
      </Grid>
    </Grid>
  );

  const getBodySection = () => (
    <Grid container mt={3}>
      <Grid item xs={12} lg={9.5}>
        {getEmailEditor()}
      </Grid>
      <Grid item xs={12} lg={2.5}>
        <SpamBox
          spamPredictionValue={spamPredictionValue}
          color={color}
          spamText={spamText}
          topSpamKeywords={topSpamKeywords}
          spamPredictionApi={spamPredictionAPI}
          spamLoading={spamLoading}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <CustomAppHeader className={createCampaignClasses.headerSection}>
        <Grid container xs={12} sx={createCampaignClasses.headerGridContainer}>
          <Typography sx={createCampaignClasses.pageTitle}>
            Designing
          </Typography>

          {getHeaderButtons()}
        </Grid>
      </CustomAppHeader>
      {getHeaderSection()}
      {getBodySection()}
      <TemplatesModal
        setChooseTemplateOpen={setChooseTemplateOpen}
        chooseTemplateOpen={chooseTemplateOpen}
        templates={templates}
        onChooseTemplate={onChooseTemplate}
      />
      <SideBarRouterPrompt
        isBlocking={isBlocking}
        showPrompt={showPrompt}
        setShowPrompt={setShowPrompt}
        setBlocking={setIsBlocking}
        draftSubmit={handleStep3SaveDraft}
        isRetargetOrFollowUp={isRetargetOrFollowUp}
      />
      {isSubjectModalOpen && (
        <SubjectLinesModal
          isSubjectModalOpen={isSubjectModalOpen}
          setIsSubjectModalOpen={setIsSubjectModalOpen}
          subjectLines={subjectLines}
          setCampaignData={setCampaignData}
          campaignData={campaignData}
          subjectLineGenerationHandler={subjectLineGenerationHandler}
        />
      )}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};
