import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";
import {
  Counts,
  CampaignRecipientCounts,
  RecentCampaignStats,
  CampaignCounts,
  Last3DaysCampaigns,
  getCampaignRecipientInitialStates,
  getOptions,
} from "screens/Dashboard/DashboardData";
import { useAppSelector } from "utils/hooks";
import { selectName } from "redux/authSlice";
import campaigns from "assets/images/dashboard/campaigns.svg";
import email from "assets/images/dashboard/emails.svg";
import { theme, regularFont } from "utils/styles";
import {
  convertESTtoUserLocalDateAndTime,
  convertESTtoUserLocalTime,
  getFormattedStatsCount,
  getSocialAppPostRedirection,
  handleIntervalChange,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import moment from "moment-timezone";
import {
  getLatestCampaignStats,
  getCampaignsMetricsByTypeAndDates,
  getCampaignRecipientsDataByDates,
  fetchCampaignBastedOnDates,
  fetchMySocialCampaignsListByDate,
} from "screens/Dashboard/DashboardService";
import dashboardStyles from "screens/Dashboard/DashboardStyles";
import RecentCampaignStatsChart from "screens/Dashboard/components/Chart/RecentCampaignStatschart";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import CampaignRecipientPieChart from "screens/Dashboard/components/Chart/CampaignRecipientPieChart";
import strings from "global/constants/StringConstants";
import urls from "global/constants/UrlConstants";
import history from "utils/history";
import norecentactivity from "assets/images/dashboard/norecentactivity.svg";
import { useTitle } from "utils/UseTitle";
import { hasAccessTo } from "utils/AuthorizationManager";
import {
  selectCampaignRetentionDuration,
  selectInterval,
} from "redux/persistSlice";
import { useDispatch } from "react-redux";
import { changeRun, selectIsTourActive } from "redux/tourSlice";
import FacebookIcon from "@mui/icons-material/Facebook";
import Dot from "assets/icons/dot.svg";
import CustomDatePicker from "global/components/CustomDatePicker/CustomDatePicker";
import InstagramIcon from "@mui/icons-material/Instagram";

const getColorBasedOnStatus = {
  Completed: "#ACC837",
  Submitted: "#FFA74E",
  Draft: "#C20C85",
  Failed: "rgb(175 76 76 / 57%)",
  InProgress: "rgb(76 119 175 / 57%)",
  Sent: "rgb(76 119 175 / 57%)",
  ConfirmationPending: "rgb(76 119 175 / 57%)",
} as { [key: string]: string };

const Dashboard = () => {
  useTitle(strings.DashboardTitle);
  const classes = dashboardStyles;
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const userName = useAppSelector(selectName);
  const interval = useAppSelector(selectInterval);
  const campaignRetention = useAppSelector(selectCampaignRetentionDuration);
  const isTourActive = useAppSelector(selectIsTourActive);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [myCampaign, setMyCampaign] = useState<Last3DaysCampaigns>(
    {} as Last3DaysCampaigns
  );
  const [stats, setStats] = useState({
    executed: {
      title: "Total Campaigns",
      value: 0,
      icon: campaigns,
      resource: strings.campaign,
      redirection: {
        pathname: urls.campaignsListViewPath,
      },
    },
    outbounds: {
      title: "Total Emails Sent",
      value: 0,
      icon: email,
      resource: strings.campaign,
      redirection: {},
    },
  });
  const [campaignRecipientStats, setCampaignRecipientStats] =
    useState<CampaignRecipientCounts>(getCampaignRecipientInitialStates());
  const [recentCampaignStats, setRecentCampaignStats] =
    useState<RecentCampaignStats>({
      id: "",
      name: "",
      createdOn: "",
      scheduleTime: new Date(),
      stats: {
        Requested: {
          name: "Requested",
          value: 0,
          fill: "#006BCE",
        },
        Success: {
          name: "Success",
          value: 0,
          fill: "#1E7D33",
        },
        Failed: {
          name: "Failed",
          value: 0,
          fill: "#BE2F38",
        },
        Unsubscribed: {
          name: "Unsubscribed",
          value: 0,
          fill: "#C3782E",
        },
      },
    });
  const [mySocialCampaigns, setMySocialCampaigns] =
    useState<Last3DaysCampaigns>({} as Last3DaysCampaigns);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const hasCampaignFetchAccess = hasAccessTo(
    strings.campaign,
    strings.fetchPermission
  );

  const hasSocialCampaignFetchAccess = hasAccessTo(
    strings.social,
    strings.fetchPermission
  );

  useEffect(() => {
    if (isTourActive) {
      dispatch(changeRun(isLoading ? false : true));
    }
  }, [isLoading]);

  useEffect(() => {
    dashboardInit();
  }, [interval]);

  const dashboardInit = async () => {
    try {
      setIsLoading(true);
      const [
        campaignMetrics,
        campaignRecipientCount,
        campaignsList,
        socialCampaigns,
        recentCampaign,
      ] = await Promise.all([
        hasCampaignFetchAccess
          ? getCampaignsMetricsByTypeAndDates(interval)
          : Promise.resolve([]),
        hasCampaignFetchAccess
          ? getCampaignRecipientsDataByDates(interval)
          : Promise.resolve([]),
        hasCampaignFetchAccess
          ? fetchCampaignBastedOnDates(interval)
          : Promise.resolve([]),
        hasSocialCampaignFetchAccess
          ? fetchMySocialCampaignsListByDate(interval)
          : Promise.resolve([]),
        hasCampaignFetchAccess
          ? await getLatestCampaignStats(strings.Completed)
          : Promise.resolve([]),
      ]);

      const { localCampaignRecipientStats, outboundCount } =
        fillCampaignRecipientState(campaignRecipientCount);
      setCampaignRecipientStats(localCampaignRecipientStats);

      if (isTruthy(recentCampaign.id)) {
        setRecentCampaignStats({
          id: recentCampaign.id,
          name: recentCampaign.name,
          createdOn: recentCampaign.createdOn,
          stats: fillRecentCampaignState(recentCampaign.counts),
          scheduleTime: recentCampaign.scheduleTime,
        });
      }

      setMyCampaign(fillMyCampaigns(campaignsList));
      setStats((prevStats) => ({
        ...prevStats,
        outbounds: {
          ...prevStats.outbounds,
          value: outboundCount,
        },
        executed: {
          ...prevStats.executed,
          value: updateExecutedCampaigns(campaignMetrics),
          redirection: {
            ...prevStats.executed.redirection,
            state: { interval },
          },
        },
      }));

      setMySocialCampaigns(fillMyCampaigns(socialCampaigns));
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const fillMyCampaigns = (last3DaysCampaignData: any) => {
    const sortedCampaigns = last3DaysCampaignData.sort(
      (a: any, b: any) =>
        moment(b.scheduleTime).valueOf() - moment(a.scheduleTime).valueOf()
    );
    return sortedCampaigns.reduce((acc: any, val: any) => {
      acc[moment(val.scheduleTime).format("MMMM D")] =
        acc[moment(val.scheduleTime).format("MMMM D")] || [];
      acc[moment(val.scheduleTime).format("MMMM D")].push(val);
      return acc;
    }, {});
  };

  const fillCampaignRecipientState = (campaignRecipientCount: Counts[]) => {
    const localCampaignRecipientStats = getCampaignRecipientInitialStates();
    let outboundCount = 0;
    campaignRecipientCount.forEach((stat: Counts) => {
      if (stat.name === "Requested") {
        localCampaignRecipientStats["Total"].value = stat.count;
        outboundCount = stat.count;
      }
      if (localCampaignRecipientStats.hasOwnProperty(stat.name)) {
        localCampaignRecipientStats[stat.name].value = stat.count;
      }
    });
    return { localCampaignRecipientStats, outboundCount };
  };

  const fillRecentCampaignState = (campaignStats: Counts[]) => {
    const localState = campaignStats;
    const returnObj: CampaignCounts = recentCampaignStats.stats;
    localState.forEach((stat) => {
      if (returnObj.hasOwnProperty(stat.name)) {
        returnObj[stat.name].value = stat.count;
      }
    });
    return returnObj;
  };

  const updateExecutedCampaigns = (campaignMetrics: Counts[]) => {
    let count = 0;
    campaignMetrics.forEach((data) => {
      if (data.name === "Completed" || data.name === "Failed") {
        count += data.count;
      }
    });
    return count;
  };

  const getUserName = () => {
    const name = userName.split(" ");
    if (name.length > 0) {
      return name[0];
    }
    return userName;
  };

  const getLastExecutedCampaignView = () => {
    if (!hasCampaignFetchAccess) {
      return (
        <Box minHeight="325px" display="flex">
          {getPermissionPlaceholder("Recent Campaigns")}
        </Box>
      );
    }
    if (!isTruthy(recentCampaignStats.id)) {
      return (
        <Box
          display="flex"
          minHeight={isDesktop ? "300px" : "250px"}
          height={isDesktop ? "310px" : "250px"}
        >
          {getNoActivityPlaceholder("No Campaigns executed!")}
        </Box>
      );
    }
    return (
      <RecentCampaignStatsChart
        graphData={Object.values(recentCampaignStats.stats)}
        total={recentCampaignStats.stats["Requested"].value}
      />
    );
  };

  const getDashboardHeader = () => {
    return (
      <Grid
        container
        sx={classes.header}
        xs={12}
        md={12}
        lg={12}
        xl={12}
        width="100%"
      >
        <Grid item xs={12} md={4} lg={6} sx={{ display: "flex" }}>
          <Typography variant="h5" sx={classes.heading}>
            Hello, {getUserName()}!
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            [theme.breakpoints.down("sm")]: { flexDirection: "column" },
            marginTop: "10px",
          }}
        >
          <Box sx={classes.dateBox}>
            <Typography sx={classes.date}>
              {moment(interval.fromDate).format("LL")} to{" "}
              {moment(interval.toDate).format("LL")}
            </Typography>
          </Box>
          <Box>
            <Select
              id="Dashboard_Interval_Dropdown"
              sx={classes.dropdown}
              value={interval.label}
            >
              {getOptions(+campaignRetention)?.map((data: any) => (
                <MenuItem
                  key={data.label}
                  value={data.label}
                  sx={classes.dropdownOptions}
                  onClick={() =>
                    handleIntervalChange(data.label, setIsDatePickerOpen)
                  }
                >
                  {data.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const getStatsCard = () => {
    return (
      <Grid container spacing={2}>
        {Object.values(stats).map((stat: any) => (
          <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              component={"div"}
              id="dashboard_stats"
              sx={{
                padding: "8px",
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.07)",
                cursor: isTruthy(stat.redirection) ? "pointer" : "auto",
              }}
              onClick={() =>
                isTruthy(stat.redirection)
                  ? history.push(stat.redirection)
                  : null
              }
            >
              <Box>
                <Typography sx={classes.statsTitle}>{stat.title}</Typography>
                <Typography sx={classes.statsValue}>
                  {hasAccessTo(stat.resource, strings.fetchPermission) ? (
                    getFormattedStatsCount(stat.value)
                  ) : (
                    <Typography sx={{ ...regularFont }}>
                      You don't have permission to view {stat.title}. Please
                      contact your account administrator.
                    </Typography>
                  )}
                </Typography>
              </Box>
              <Box>
                <img src={stat.icon} width={60} height={60} />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const getPermissionPlaceholder = (name: string) => {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center">
        <img src={norecentactivity} alt="forbidden" width="70%" />
        <Box sx={{ textAlign: "center", width: "100%" }} p={1}>
          <Typography sx={{ ...regularFont, textAlign: "center" }}>
            You don't have permission to view {name}. Please contact your
            account administrator.
          </Typography>
        </Box>
      </Stack>
    );
  };

  const getNoActivityPlaceholder = (title: string) => {
    return (
      <Stack
        display={"flex"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        width={"100%"}
        height={"100%"}
      >
        <img src={norecentactivity} alt={title} width="auto" />
        <Box sx={{ textAlign: "center" }}>
          <Typography sx={classes.noactivityheading}>{title}</Typography>
        </Box>
      </Stack>
    );
  };

  const getCampaignerRecipientsGraph = () => {
    return (
      <Box id="Dashboard_Campaign_Recipients" sx={classes.container}>
        <Typography sx={classes.containerTitle} gutterBottom>
          Campaign Recipients
        </Typography>
        {hasCampaignFetchAccess ? (
          <CampaignRecipientPieChart
            data={Object.values(campaignRecipientStats)}
          />
        ) : (
          getPermissionPlaceholder("Campaign Recipients")
        )}
      </Box>
    );
  };

  const getCampaignsList = () => {
    return (
      <Box id="Dashboard_My_Campaigns" sx={{ ...classes.container }}>
        <Typography
          sx={classes.containerTitle}
          mt={0}
          position="static"
          top="0"
        >
          Campaign Calendar
        </Typography>
        <Box
          minHeight={isDesktop ? "500px" : "250px"}
          height={{
            md: "775px",
            xs: "250px",
          }}
          sx={{
            ...classes.campaignCalender,
          }}
        >
          {hasCampaignFetchAccess ? (
            Object.keys(myCampaign)?.length === 0 ? (
              getNoActivityPlaceholder(`No Campaigns executed!`)
            ) : (
              <Box py={1} justifyContent="flex-start">
                {Object.keys(myCampaign)?.map((data: any) => {
                  return (
                    <>
                      <Typography sx={classes.campaignDate}>{data}</Typography>
                      {myCampaign[data].map((camp: any, index: number) => (
                        <Box display="flex" mb={2}>
                          <Box
                            sx={{
                              minWidth: "75px",
                              marginBottom: "8px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                          >
                            <Typography sx={classes.dateRangeText}>
                              {convertESTtoUserLocalTime(camp.scheduleTime)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              borderRight:
                                "4px solid " +
                                getColorBasedOnStatus[
                                  camp?.status ?? "rgb(76 119 175 / 57%)"
                                ],
                              borderRadius: "10px 0 0 10px",
                              marginBottom: "8px",
                            }}
                          ></Box>

                          <Box
                            ml={1}
                            onClick={() =>
                              (camp.status === strings.Completed ||
                                camp.status === strings.Failed) &&
                              history.push(
                                `${urls.campaignDetailsViewPath}?id=${camp.id}`
                              )
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            <Typography
                              sx={{
                                ...regularFont,
                                colo: "#1E1D1D",
                                fontSize: "14px",
                              }}
                            >
                              {camp.name}
                            </Typography>
                            <Typography
                              sx={{ ...regularFont, color: "#B1B1B1" }}
                            >
                              {camp.status}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </>
                  );
                })}
              </Box>
            )
          ) : (
            getPermissionPlaceholder("Campaigns")
          )}
        </Box>
      </Box>
    );
  };

  const getSocialAppIcon = (appName: string) => {
    switch (appName) {
      case strings.FACEBOOK: {
        return <FacebookIcon fontSize="small" />;
      }
      case strings.INSTAGRAM: {
        return <InstagramIcon fontSize="small" />;
      }
    }
  };

  const getRecentActivitiesCampaign = () => {
    const scheduleTimeString = moment(recentCampaignStats.scheduleTime).format(
      "YYYY-MM-DDTHH:mm:ss"
    );

    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          mt={{ sm: 2, xs: 2, md: 0, lg: 0, xl: 0 }}
          id="Dashboard_Last_Campaign"
          mb={{ md: 2, xs: 4 }}
        >
          <Box sx={{ ...classes.container, height: "100%" }}>
            <Typography sx={{ ...classes.containerTitle }}>
              <span>Last Executed Campaign</span>
              {isTruthy(recentCampaignStats.scheduleTime) && (
                <span
                  style={{
                    ...regularFont,
                    paddingLeft: 3,
                    color: "#787878",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  |{" "}
                  {convertESTtoUserLocalDateAndTime(
                    scheduleTimeString,
                    "MMM DD, YYYY"
                  )}
                </span>
              )}
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"row"}
              onClick={() =>
                history.push(
                  `${urls.campaignDetailsViewPath}?id=${recentCampaignStats.id}`
                )
              }
              sx={{ cursor: "pointer" }}
              py={1}
            >
              <Typography
                sx={{
                  ...regularFont,
                  color: "#787878",
                  paddingRight: "8px",
                  paddingLeft: 1,
                }}
              >
                {recentCampaignStats.name}
              </Typography>
              {isTruthy(recentCampaignStats.name) && (
                <>
                  <img src={Dot} alt="dot" />
                  <Typography
                    sx={{
                      ...regularFont,
                      color: "#787878",
                      paddingLeft: "2px",
                    }}
                  >
                    {recentCampaignStats.stats["Requested"].value} Emails
                  </Typography>
                </>
              )}
            </Box>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
            >
              {getLastExecutedCampaignView()}
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          alignItems="center"
          justifyContent="center"
          id="Dashboard_Social_Campaign_Calender"
          mb={2}
        >
          <Box sx={{ ...classes.container, height: "100%" }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={classes.containerTitle}>
                Social Campaign Calendar
              </Typography>
            </Stack>
            <Box
              display="flex"
              minHeight={isDesktop ? "300px" : "250px"}
              height={isDesktop ? "342px" : "250px"}
              sx={{
                overflow: "auto",
                // scrollbarWidth: "thin",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {hasSocialCampaignFetchAccess ? (
                Object.keys(mySocialCampaigns)?.length === 0 ? (
                  getNoActivityPlaceholder("No Campaigns executed!")
                ) : (
                  <Box py={1} justifyContent="flex-start">
                    {Object.keys(mySocialCampaigns)?.map((data: any) => {
                      return (
                        <>
                          <Typography sx={classes.campaignDate}>
                            {data}
                          </Typography>
                          {mySocialCampaigns[data].map(
                            (camp: any, index: number) => (
                              <Box display="flex" mb={2}>
                                <Box
                                  sx={{
                                    minWidth: "75px",
                                    marginBottom: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Typography sx={classes.dateRangeText}>
                                    {convertESTtoUserLocalTime(
                                      camp.scheduleTime
                                    )}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    borderRight:
                                      "4px solid " +
                                      getColorBasedOnStatus[
                                        camp?.status ?? "rgb(76 119 175 / 57%)"
                                      ],
                                    borderRadius: "10px 0 0 10px",
                                    marginBottom: "8px",
                                  }}
                                ></Box>

                                <Stack
                                  display={"flex"}
                                  direction={"column"}
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    camp.status === strings.Completed &&
                                    getSocialAppPostRedirection(camp)
                                  }
                                >
                                  <Stack
                                    display={"flex"}
                                    direction={"row"}
                                    ml={1}
                                  >
                                    {getSocialAppIcon(camp.socialAppName)}
                                    <Typography
                                      sx={{
                                        ...regularFont,
                                        colo: "#1E1D1D",
                                        fontSize: "14px",
                                        pl: 1,
                                      }}
                                    >
                                      {camp.name}
                                    </Typography>
                                  </Stack>

                                  <Box ml={1}>
                                    <Typography
                                      sx={{ ...regularFont, color: "#B1B1B1" }}
                                    >
                                      {camp.status}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Box>
                            )
                          )}
                        </>
                      );
                    })}
                  </Box>
                )
              ) : (
                getPermissionPlaceholder("Social Campaigns")
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const getDashboardBody = () => {
    return (
      <Grid
        container
        spacing={2}
        sx={{ padding: "0 16px", marginTop: "-60px" }}
        mb={2}
      >
        <Grid item xs={12} sm={12} xl={9} md={9} lg={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              {getStatsCard()}
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  {getCampaignerRecipientsGraph()}
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Box mb={2}>{getRecentActivitiesCampaign()}</Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} lg={3} xl={3}>
          {getCampaignsList()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      {getDashboardHeader()}
      {getDashboardBody()}
      <CustomLoader isLoading={isLoading} />
      {isDatePickerOpen && (
        <CustomDatePicker
          pastMonths={+campaignRetention}
          isDatePickerOpen={isDatePickerOpen}
          setIsDatePickerOpen={setIsDatePickerOpen}
        />
      )}
    </Box>
  );
};

export default Dashboard;
