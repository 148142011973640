import history from "utils/history";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import IntegrationStyles from "screens/Integration/Integration.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CustomButton } from "global/components";
import whatsappIcon from "assets/icons/whatsapp.svg";
import { hasAccessTo } from "utils/AuthorizationManager";
import strings from "global/constants/StringConstants";
import { regularFont } from "utils/styles";
import {
  getFacebookRedirectionURL,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import { disconnectWhatsapp } from "screens/Integration/component/WhatsAppServices";
import urls from "global/constants/UrlConstants";
import notifiers from "global/constants/NotificationConstants";
import { useDispatch } from "react-redux";
import { changeIntegrationAppNameAction } from "redux/persistSlice";

interface CustomProps {
  previousStepHandler: Function;
  isWhatsAppIntegrated?: boolean;
  setIsWhatsAppIntegrated?: Function | undefined;
  isLoggedInSocialMedia?: Function;
}

export const WhatsApp = (props: CustomProps) => {
  const classes = IntegrationStyles;
  const dispatch = useDispatch();

  const hasSocialDeleteAccess = hasAccessTo(
    strings.social,
    strings.deletePermission
  );

  const whatsApp = () => {
    window.location.href = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${
      strings.FACEBOOK_APP_ID
    }&redirect_uri=${getFacebookRedirectionURL(
      window.location.hostname
    )}&scope=business_management%2Cwhatsapp_business_management%2Cwhatsapp_business_messaging&state=123456789`;
  };

  const onConnectHandler = () => {
    dispatch(changeIntegrationAppNameAction(strings.WHATSAPP));
    whatsApp();
  };

  const logoutSocialMediaApiHandler = async () => {
    try {
      const response = await disconnectWhatsapp(strings.WHATSAPP);
      openSuccessNotification(response?.message);
      props.isLoggedInSocialMedia && props.isLoggedInSocialMedia();
      history.push(urls.integrationViewPath);
      props.setIsWhatsAppIntegrated?.(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error?.message) ? error?.message : notifiers?.GENERIC_ERROR
      );
    }
  };

  return (
    <Grid>
      <Grid item>
        <Box sx={classes.integrationsWrapper}>
          <IconButton id="whatsapp_back_button">
            <ArrowBackIcon
              onClick={() => {
                props.previousStepHandler();
              }}
            />
          </IconButton>
          <Typography sx={classes.integrationsTitle}>
            Back to Channels
          </Typography>
        </Box>
      </Grid>
      <Grid item mt={1}>
        <Box sx={classes.connectWrapper}>
          <Box
            component="img"
            src={whatsappIcon}
            height={80}
            width={80}
            mt={1}
            ml={2}
          />
          <Box sx={classes.connectContent}>
            <Typography sx={classes.socialTitle}>WhatsApp</Typography>
            <Typography sx={classes.connectPara}>
              Unlock the full potential of your WhatsApp presence with Mailzzy!
              🚀
            </Typography>
            <Typography sx={classes.connectPara}>
              Connect your WhatsApp account and start experiencing the benefits
              of Mailzzy's features.
            </Typography>
            {!hasSocialDeleteAccess && (
              <Typography
                variant="body1"
                sx={{
                  color: "#ff4244",
                  ...regularFont,
                }}
              >
                * You don't have permission to disconnect channel. Please
                contact your account administrator.
              </Typography>
            )}

            <Box mt={2} width={{ sm: "100%", lg: "20%" }}>
              {props.isWhatsAppIntegrated ? (
                hasSocialDeleteAccess && (
                  <CustomButton
                    label="Disconnect"
                    onClick={() => logoutSocialMediaApiHandler()}
                    id="whatsapp_disconnect_button"
                  />
                )
              ) : (
                <CustomButton
                  label="Connect"
                  onClick={() => {
                    onConnectHandler();
                  }}
                  id="whatsapp_connect_button"
                />
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Typography fontWeight="bold" fontSize={23} mt={6}>
          Features
        </Typography>
        <Box ml={7}>
          <List sx={classes.listStyle}>
            <ListItem sx={classes.listItemStyle}>
              <span style={{ fontWeight: "bold" }}>
                Streamline your social media management:
              </span>{" "}
              No more logging in and out of multiple platforms. Keep track of
              all your WhatsApp interactions in one place.
            </ListItem>

            <ListItem sx={classes.listItemStyle}>
              <span style={{ fontWeight: "bold" }}>
                Create ad campaigns directly:
              </span>{" "}
              Say goodbye to manual data transfer. Create and manage ad
              campaigns directly within Mailzzy.
            </ListItem>
            <ListItem sx={classes.listItemStyle}>
              <span style={{ fontWeight: "bold" }}>Real-time tracking:</span>{" "}
              Stay on top of customer interactions and respond quickly,
              improving communication and customer satisfaction.
            </ListItem>
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WhatsApp;
