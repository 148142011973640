import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import loginStyles from "screens/LandingPage/components/Login.styles";
import { useHistory } from "react-router-dom";
import { loginAction, selectAuthenticated } from "redux/authSlice";
import {
  convertResourceToObjectFormat,
  isTruthy,
  isValidOTP,
  openErrorNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import QRCode from "qrcode";
import {
  activateMFA,
  fetchPreAuthSecretKey,
  generateQRCodeForMFA,
} from "screens/LandingPage/landingPageService";
import urls from "global/constants/UrlConstants";
import { Box, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { CustomButton } from "global/components";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  boldFont,
  getRelativeFontSize,
  regularFont,
  theme,
} from "utils/styles";
import WarningIcon from "@mui/icons-material/Warning";
import {
  changeIntervalAction,
  changeIsFirstNameMandatory,
} from "redux/persistSlice";
import strings from "global/constants/StringConstants";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const SetupMFA = () => {
  const classes = loginStyles;
  const dispatch = useAppDispatch();
  const history: any = useHistory();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const email = history?.location?.state?.email ?? "";
  const password = history?.location?.state?.password ?? "";
  const token = history?.location.state?.token ?? "";
  const isLoggedIn = useAppSelector(selectAuthenticated);
  const [payload, setPayload] = useState({
    email: email,
    code: "",
    password: password,
  });
  const [processing, setProcessing] = useState<boolean>(false);
  const [QR, setQR] = useState<string>();
  const [keyCopied, setKeyCopied] = useState<boolean>(false);
  const [preAuthSecretKey, setPreAuthSecretKey] = useState<string>("");

  useEffect(() => {
    makeQRCode();
    getPreAuthSecretKey();
  }, []);

  const makeQRCode = async () => {
    try {
      const response = await generateQRCodeForMFA(token);
      await generateQRCodeImage(response.message);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const generateQRCodeImage = async (url: string) => {
    try {
      const qrCodeImage = await QRCode.toDataURL(url);
      setQR(qrCodeImage);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getPreAuthSecretKey = async () => {
    try {
      const key = await fetchPreAuthSecretKey(token);
      setPreAuthSecretKey(`${key?.match(/.{1,6}/g).join("-")}`);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      verifyOTP();
    }
  };

  const verifyOTP = async () => {
    try {
      if (!isValidOTP(payload.code)) {
        return openErrorNotification("Please enter the code!");
      }
      setProcessing(true);
      const response = await activateMFA(
        token,
        payload.email,
        payload.password,
        payload.code
      );
      const formattedResources = convertResourceToObjectFormat(
        response.resources
      );
      dispatch(
        changeIntervalAction({
          label: strings.thisWeek,
          fromDate: moment().startOf("isoWeek").format("MM-DD-yyyy"),
          toDate: moment().format("MM-DD-yyyy"),
        })
      );
      dispatch(changeIsFirstNameMandatory(true));
      dispatch(
        loginAction({
          email,
          authenticated: true,
          accessToken: response.authToken,
          userName: response.name,
          role: response.role,
          resources: formattedResources,
          account: response.account,
          isTrial: response.trial,
        })
      );
      setProcessing(false);
    } catch (error: any) {
      setProcessing(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const copyToClipBoard = async () => {
    await navigator.clipboard.writeText(preAuthSecretKey);
    setKeyCopied(true);
    setTimeout(() => setKeyCopied(false), 3000);
  };

  const renderSetupMFAScreen = () => {
    return (
      <Box>
        <Typography sx={{ ...classes.getHeading, mx: 3 }}>
          Keep your account secure
        </Typography>
        <Box mx={3}>
          <Typography sx={classes.headingCenter} component="span">
            Set up the multi-factor authentication for your account.
          </Typography>
        </Box>

        <Stack
          spacing={2}
          sx={{
            py: 1,
            px: 3,
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="flex-start"
            sx={{
              backgroundColor: "#faf7ea",
              p: 1,
              borderRadius: "8px",
            }}
          >
            <WarningIcon fontSize="small" htmlColor="#fe9e1c" />
            <Typography sx={classes.headingCenter}>
              This setting is mandatory to access your account.
            </Typography>
          </Stack>
        </Stack>

        <Stack
          alignItems="center"
          sx={{
            border: "0.5px solid #E9E9E9",
            borderRadius: "20px",
            py: 2,
            mx: 3,
          }}
        >
          {QR && <img src={QR} alt="QR Code" height={180} width={180} />}
          <Typography mt={1}>OR</Typography>
          {preAuthSecretKey && (
            <Stack
              direction="column"
              alignItems="center"
              gap={2}
              sx={{
                overflowWrap: "break-word",
                wordBreak: "break-all",
                padding: "10px",
              }}
            >
              <Typography
                sx={{
                  wordWrap: "break-word",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {preAuthSecretKey}
                <span
                  style={{
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={copyToClipBoard}
                >
                  {keyCopied ? (
                    <Tooltip title="Copied">
                      <DoneAllIcon sx={{ fontSize: "18px" }} />
                    </Tooltip>
                  ) : (
                    <ContentCopyIcon sx={{ fontSize: "18px" }} />
                  )}
                </span>
              </Typography>
            </Stack>
          )}
        </Stack>
        <Typography
          sx={{
            ...classes.headingCenter,
            fontSize: getRelativeFontSize(2),
            px: 2,
            mx: 2,
          }}
        >
          To complete the setup, either scan the QR code or enter the secret key
          into a multi-factor authenticator app like Google Authenticator or
          Authy. Then, enter the code that appears in the app.
        </Typography>

        <Box mx={4} mt={2}>
          <MuiOtpInput
            length={6}
            onChange={(value: any) =>
              setPayload({
                ...payload,
                code: value.replace(/[^0-9]/g, ""),
              })
            }
            sx={{ ...classes.otpInput, gap: !isDesktop ? "10px" : "20px" }}
            value={payload.code}
            onKeyDown={handleKeypress}
          />
          <Box mt={2}>
            <CustomButton
              label="Verify"
              onClick={verifyOTP}
              loading={processing}
              customClasses={classes.signBtn}
              id="verify_button"
            />
          </Box>
        </Box>
        <Box
          marginTop={2}
          width={"100%"}
          onClick={() => history.push(urls.loginViewPath)}
        >
          <Typography sx={classes.backToLogin}>Back to Login</Typography>
        </Box>
      </Box>
    );
  };
  if (isLoggedIn) {
    history.push(urls.dashboardViewPath);
    return null;
  } else {
    return <>{renderSetupMFAScreen()}</>;
  }
};

export default SetupMFA;
