import urls from "global/constants/UrlConstants";
import { getCallParams, makeCall } from "utils/service";

export const getProfileDetails = async (emailId: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.userEmail + "/" + emailId,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateProfileDetails = async (profileDetails: any) => {
  try {
    const callParams = await getCallParams("PATCH", profileDetails);
    const response: any = await makeCall(urls.updateProfileDetails, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
