import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {
  CustomAppHeader,
  CustomButton,
  CustomInput,
  CustomTable,
} from "global/components";
import SearchIcon from "@mui/icons-material/Search";
import notifiers from "global/constants/NotificationConstants";
import strings from "global/constants/StringConstants";
import {
  debounceEventHandler,
  handleIntervalChange,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import {
  FailedStatsFormattedData,
  SortingConfig,
  FailedStatsFilters,
  GlobalInterval,
} from "models/interfaces";
import { useEffect, useState } from "react";
import { selectIsManager } from "redux/authSlice";
import { useTitle } from "utils/UseTitle";
import { useAppSelector } from "utils/hooks";
import {
  selectCampaignRetentionDuration,
  selectInterval,
} from "redux/persistSlice";
import { darkPurpledColor } from "utils/styles";
import CachedIcon from "@mui/icons-material/Cached";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import {
  getCampaignHistoryByDate,
  getCampaignHistoryCountByDate,
  getCampaignHistoryCountSearchByDates,
  getCampaignHistorySearchByDates,
} from "screens/CampaignHistory/CampaignHistoryServices";
import FailStatsStyles from "screens/FailedStats/FailedStats.styles";
import {
  failedStatsBounceStatus,
  failedStatsHeader,
  failedStatsStatus,
  getInitialFailedState,
} from "screens/FailedStats/FailedStatsData";
import { getOptions } from "screens/Dashboard/DashboardData";
import CustomDatePicker from "global/components/CustomDatePicker/CustomDatePicker";
import moment from "moment";

const FailedStats = () => {
  useTitle(strings.FailedStatsTitle);
  const classes = FailStatsStyles;

  const isManager = useAppSelector(selectIsManager);
  const interval = useAppSelector(selectInterval);
  const [failedStatsFilter, setFailedStatsFilter] =
    useState<FailedStatsFilters>(getInitialFailedState(interval));
  const [formattedTableData, setFormattedTableData] = useState<
    FailedStatsFormattedData[]
  >([]);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<SortingConfig[]>([]);
  const campaignRetention = useAppSelector(selectCampaignRetentionDuration);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  useEffect(() => {
    getFailedStats();
  }, [failedStatsFilter, sortConfig]);

  const getFailedStats = async () => {
    try {
      setIsLoading(true);
      const {
        status,
        campaignCategory,
        interval,
        searchText,
        page,
        perPageData,
      } = failedStatsFilter;
      const [statsCount, statsData] = isTruthy(searchText)
        ? await Promise.all([
            getCampaignHistoryCountSearchByDates(
              status,
              interval,
              searchText,
              campaignCategory
            ),
            getCampaignHistorySearchByDates(
              status,
              interval,
              searchText,
              page,
              campaignCategory,
              perPageData,
              sortConfig
            ),
          ])
        : await Promise.all([
            getCampaignHistoryCountByDate(status, interval, campaignCategory),
            getCampaignHistoryByDate(
              status,
              interval,
              page,
              campaignCategory,
              perPageData,
              sortConfig
            ),
          ]);
      setCount(statsCount);
      setFormattedTableData(getFormattedTableData(statsData));
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const setIntervalInFailedStatsFilter = (newInterval: GlobalInterval) => {
    setFailedStatsFilter({
      ...failedStatsFilter,
      interval: { ...newInterval },
      page: 1,
    });
  };

  const statusChangeHandler = (event: any) => {
    const status: string = event.target.value;
    setFailedStatsFilter({
      ...failedStatsFilter,
      page: 1,
      status,
    });
  };

  const handleSearchOnChange = async (event: any) => {
    const searchText: string = event.target.value;
    setFailedStatsFilter({
      ...failedStatsFilter,
      searchText,
      page: 1,
    });
  };

  const handleCampaignsDropdown = (event: any) => {
    const campaignCategory: string = event.target.value;
    setFailedStatsFilter({
      ...failedStatsFilter,
      campaignCategory,
      page: 1,
    });
  };

  const handleChangePage = (event: any, page: number) => {
    setFailedStatsFilter({
      ...failedStatsFilter,
      page,
    });
  };

  const handlePerPageData = (event: any) => {
    const perPageData: number = event.target.value;
    setFailedStatsFilter({
      ...failedStatsFilter,
      perPageData,
      page: 1,
    });
  };

  const getFormattedTableData = (data: any[]) => {
    const tableData = data.map((item: any) => {
      return {
        campaignOwner: (
          <Typography sx={classes.rowColor}>{item.campaignOwner}</Typography>
        ),
        contactEmail: (
          <Typography sx={classes.tableRowText}>{item.contactEmail}</Typography>
        ),
        campaignName: (
          <Typography sx={classes.campaignNameColumn}>
            {item.campaignName}
          </Typography>
        ),
        response: (
          <Typography sx={classes.failEventColumn}>
            {getFailEvent(item.response)}
          </Typography>
        ),
        reason: (
          <Typography sx={classes.reasonColumn}>{item.reason}</Typography>
        ),
      };
    });
    return tableData;
  };

  const getFailEvent = (rawFailEvent: string) => {
    return failedStatsBounceStatus.find(
      (item: any) => item.value.toLowerCase() === rawFailEvent.toLowerCase()
    )?.label;
  };

  const getHeader = () => {
    return (
      <Box>
        <Typography sx={classes.mainCardHeading}>Failed Stats</Typography>
      </Box>
    );
  };

  const getFailedStatsTable = () => {
    return (
      <Box id="failed_stats_history_display_table" sx={classes.mainTable}>
        <CustomTable
          headers={failedStatsHeader}
          rows={formattedTableData}
          handlePageChange={handleChangePage}
          handlePerPageData={handlePerPageData}
          paginationCount={count}
          pageNumber={failedStatsFilter.page}
          isLoading={isLoading}
          perPageData={failedStatsFilter.perPageData}
          rowsPerPage={failedStatsFilter.perPageData}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
          sortingEntity={"ContactFailResponse"}
        />
      </Box>
    );
  };

  const getDropDowns = () => {
    return (
      <Box sx={classes.inputsSectionDropdowns}>
        <Box sx={classes.refreshBtnAndDateRangeBox}>
          <Box sx={classes.dateBox}>
            <Typography sx={classes.date}>
              {moment(interval.fromDate).format("LL")} to{" "}
              {moment(interval.toDate).format("LL")}
            </Typography>
          </Box>
          <CustomButton
            id="failed_stats_refresh_button"
            onClick={() => getFailedStats()}
            label={<CachedIcon htmlColor={darkPurpledColor} />}
            customClasses={classes.refreshBtn}
          />
        </Box>
        <Box sx={classes.dropdownAndInputBox}>
          <Select
            id="failed_stats_interval_dropdown"
            sx={{
              ...classes.inputWrapperAddClasses,
              ...classes.dropDownStyle,
              ...classes.dateRangePickerSelect,
            }}
            value={interval.label}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {getOptions(+campaignRetention)?.map((data) => (
              <MenuItem
                value={data.label}
                sx={classes.optionStyle}
                onClick={() =>
                  handleIntervalChange(
                    data.label,
                    setIsDatePickerOpen,
                    setIntervalInFailedStatsFilter
                  )
                }
              >
                {data.label}
              </MenuItem>
            ))}
          </Select>
          <Select
            id="failed_stats_options_dropdown"
            sx={{
              ...classes.inputWrapperAddClasses,
              ...classes.dropDownStyle,
            }}
            value={failedStatsFilter.status}
            onChange={statusChangeHandler}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {failedStatsBounceStatus.map((item: any) => (
              <MenuItem
                key={item.id}
                value={item.value}
                sx={classes.optionStyle}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    );
  };

  const getSearchInput = () => {
    return (
      <Box sx={classes.searchInputWrapper}>
        {isManager && (
          <Select
            id="failed_stats_category_dropdown"
            sx={classes.dropDownStyle}
            value={failedStatsFilter.campaignCategory}
            onChange={handleCampaignsDropdown}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {failedStatsStatus.map((item) => (
              <MenuItem
                key={item.id}
                value={item.value}
                sx={classes.optionStyle}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
        <Box sx={classes.searchInput}>
          <CustomInput
            id="failed_stats_search_field"
            placeHolder="Search text"
            onChange={debounceEventHandler(
              handleSearchOnChange,
              strings.SEARCH_TIME_OUT
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    );
  };

  const getUserInputs = () => {
    return (
      <Stack
        direction={{
          xl: "row",
          lg: "row",
          md: "column",
          sm: "column",
          xs: "column",
        }}
        justifyContent="space-between"
      >
        {getDropDowns()}
        {getSearchInput()}
      </Stack>
    );
  };

  return (
    <>
      <Box>
        <CustomAppHeader className={classes.headerBackgroundColor}>
          <Grid container alignItems="center" sx={classes.mainGridContainer}>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              {getHeader()}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              display="flex"
              justifyContent={{
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-end",
                xl: "flex-end",
              }}
            >
              {getUserInputs()}
            </Grid>
          </Grid>
        </CustomAppHeader>
        <Box sx={classes.mainSection}>{getFailedStatsTable()}</Box>
      </Box>
      {isDatePickerOpen && (
        <CustomDatePicker
          pastMonths={+campaignRetention}
          isDatePickerOpen={isDatePickerOpen}
          setIsDatePickerOpen={setIsDatePickerOpen}
          callBackFunction={setIntervalInFailedStatsFilter}
        />
      )}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default FailedStats;
