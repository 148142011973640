import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import notifiers from "global/constants/NotificationConstants";
import { openErrorNotification, isTruthy } from "helpers/methods";
import { Box, Container, Grid, Tooltip, Typography } from "@mui/material";
import { CustomAppHeader, CustomPaper } from "global/components";
import { doFetchTicketById } from "screens/Tickets/ViewTicket/ViewTicketService";
import ViewTicketStyles from "screens/Tickets/ViewTicket/ViewTicket.styles";
import { KeyboardBackspace } from "@mui/icons-material";
import { disabledBackgroundColor } from "utils/styles";
import history from "utils/history";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import TicketReporterDetails from "./Components/TicketReporterDetails";
import Activity from "screens/Tickets/ViewTicket/Components/Activity";
import TicketDetails from "screens/Tickets/ViewTicket/Components/TicketDetails";
import urls from "global/constants/UrlConstants";

const ViewTicket = () => {
  const classes = ViewTicketStyles;
  const { id } = useParams<{ id: string }>();
  const urlParams = new URLSearchParams(useLocation().search);
  const tabValueName = urlParams.get("tab");
  const [isLoading, setIsLoading] = useState(false);
  const [ticket, setTicket] = useState<any>(null);

  useEffect(() => {
    fetchTicketById();
  }, []);

  const fetchTicketById = async () => {
    try {
      setIsLoading(true);
      const response = await doFetchTicketById(id);
      setTicket(response);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getHeader = () => {
    return (
      <Grid container xs={12} sx={classes.stepOneHeader}>
        <Box sx={classes.backArrowBox}>
          <Tooltip
            title={
              <CustomPaper
                className={{
                  backgroundColor: disabledBackgroundColor,
                }}
              >
                <Typography sx={classes.tooltipText}>
                  {"Back to Tickets"}
                </Typography>
              </CustomPaper>
            }
            placement="right"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  background: "none",
                },
              },
            }}
          >
            <KeyboardBackspace
              sx={{ cursor: "pointer" }}
              onClick={() => {
                history.push({
                  pathname: urls.ticketsViewPath,
                  state: {
                    fromPageTableValue: tabValueName,
                    loadingState: true,
                  },
                });
              }}
            />
          </Tooltip>
        </Box>
        <Typography sx={classes.pageTitle}>
          [#{ticket?.id}] {ticket?.name}
        </Typography>
      </Grid>
    );
  };

  const getBody = () => {
    return (
      <Grid container display="flex" spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
          <Grid container display="flex" flexDirection="column" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TicketReporterDetails
                ticketDetails={ticket}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Activity
                ticketDetails={ticket}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                ticketId={id}
                fetchTicketById={fetchTicketById}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Grid container display="flex" flexDirection="column" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TicketDetails ticketDetails={ticket} isLoading={isLoading} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <CustomAppHeader className={classes.headerSection}>
        <Container maxWidth={"xl"}>{getHeader()}</Container>
      </CustomAppHeader>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 2,
          pb: 6,
        }}
      >
        <Container maxWidth={"xl"}>{getBody()}</Container>
      </Box>
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default ViewTicket;
