import strings from "global/constants/StringConstants";

export const whatsAppTemplateStatusTabsContent = [
  {
    label: strings.approved,
    count: 0,
  },
  {
    label: strings.pending,
    count: 0,
  },
  {
    label: strings.rejected,
    count: 0,
  },
];

export const whatsAppTemplateHeader = (status: string) => [
  {
    name: "Template Name",
    field: "TemplateName",
  },
  {
    name: "Template Type",
    field: "TemplateType",
  },
  {
    name: "Language",
    field: "TemplateLanguage",
  },
  {
    name: "Last Updated On",
    field: "LastUpdatedOn",
  },
  {
    name: "Created By",
    field: "CreatedBy",
    align: "center",
  },
  ...(status === strings.pending
    ? [
        {
          name: "Action",
          field: "Action",
          align: "center",
        },
      ]
    : []),
];

export const getLanguageName = (inputLanguageCode: string) => {
  return templateLanguage.find(
    (language, index) => language.languageCode === inputLanguageCode
  )?.languageName;
};

export const templateLanguage = [
  {
    languageName: "English",
    languageCode: "en",
  },
  {
    languageName: "English (UK)",
    languageCode: "en_GB",
  },
  {
    languageName: "English (US)",
    languageCode: "en_US",
  },
];
