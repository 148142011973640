import urls from "global/constants/UrlConstants";
import { getCallParams, getFileCallParams, makeCall } from "utils/service";

export const linkedinUserDetail = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.linkedInUserDetail, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const linkedInAccessToken = async (code: any) => {
  try {
    const body = { code };
    const callParams = await getCallParams("POST", body);
    const response = await makeCall(urls.linkedInAccessToken, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const linkedInImageUpload = async (file: any) => {
  try {
    const callParams = await getFileCallParams(file);
    const response = await makeCall(urls.linkedInImageUpload, callParams);
    return response;
  } catch (error) {
    throw error;
  }
};
export const linkedInImageWithPost = async (
  userId: any,
  uploadedImage: any
) => {
  try {
    const body = {
      author: `${userId}`,
      lifecycleState: "PUBLISHED",
      specificContent: {
        "com.linkedin.ugc.ShareContent": {
          shareCommentary: {
            text: "Malav Upload Image",
          },
          shareMediaCategory: "IMAGE",
          media: [
            {
              status: "READY",
              description: {
                text: "Center stage!",
              },
              media: `${uploadedImage}`,
              title: {
                text: "LinkedIn Talent Connect 2021",
              },
            },
          ],
        },
      },
      visibility: {
        "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC",
      },
    };
    const callParams = await getCallParams("POST", body);
    const response = await makeCall(
      urls.linkedInImageUploadWithPost,
      callParams
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const linkedInPost = async (
  userId: any,
  shareCommentaryValue: any,
  postDescription: any,
  postTitle: any
) => {
  try {
    const body = {
      author: `${userId}`,
      lifecycleState: "PUBLISHED",
      specificContent: {
        "com.linkedin.ugc.ShareContent": {
          shareCommentary: {
            text: `${shareCommentaryValue}`,
          },
          shareMediaCategory: "ARTICLE",
          media: [
            {
              status: "READY",
              description: {
                text: `${postDescription}`,
              },
              originalUrl: "mailzzy.com",
              title: {
                text: `${postTitle}`,
              },
            },
          ],
        },
      },
      visibility: {
        "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC",
      },
    };
    const callParams = await getCallParams("POST", body);
    const response = await makeCall(urls.linkedPost, callParams);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteLinkedPost = async (id: any) => {
  try {
    const body = { id };
    const callParams = await getCallParams("DELETE", body);
    const response = await makeCall(urls.deleteLinkedInPost, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
