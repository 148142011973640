import urls from "global/constants/UrlConstants";
import { getCallParams, makeCall } from "utils/service";

export const getWhatsAppTemplatesCount = async (status: string) => {
  try {
    const url: string = `${
      urls.whatsAppBaseUrl
    }/status/${status.toUpperCase()}/count`;
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getWhatsAppTemplates = async (
  status: string,
  page: number,
  limit: number
) => {
  try {
    const url: string = `${
      urls.whatsAppBaseUrl
    }/status/${status.toUpperCase()}/page/${page}/limit/${limit}`;
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getWhatsAppTemplateStatus = async (templateId: string) => {
  try {
    const url: string = `${urls.checkForTemplateUpdate}/${templateId}`;
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const checkWhatsAppIntegration = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.getIntegratedAppNames, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
