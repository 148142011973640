import { useState, useEffect, ChangeEvent, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
  Chip,
  Stack,
  Divider,
  TextField,
} from "@mui/material";
import {
  getCampaignGroupsCount,
  getCampaignGroups,
  getSearchedCampaignGroupsCount,
  getSearchedCampaignGroups,
  deleteGroupRequest,
  downloadContactList,
  updateContactListDocument,
  getSubordinatesList,
  getContactGroupTypes,
} from "screens/CampaignGroups/CampaignGroups.service";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import UploadGroup from "screens/CampaignGroups/UploadGroups";
import { campaignGroupStyles } from "screens/CampaignGroups/CampaignGroups.styles";
import ExportCSV from "global/components/ExportCSV";
import history from "utils/history";
import { CampaignData, SortingConfig, groupsFilters } from "models/interfaces";
import {
  debounceEventHandler,
  getFormattedStatsCount,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
  validatePageQuery,
  validatePerPageQuery,
  validateCategoryQuery,
} from "helpers/methods";
import { CustomAppHeader, CustomButton, CustomTable } from "global/components";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { darkPurpledColor } from "utils/styles";
import CustomDialog from "global/components/CustomDialog/CustomDialog";
import strings from "global/constants/StringConstants";
import {
  campaignGroupHeader,
  dropDownOptions,
  initialGroupFilterState,
} from "screens/CampaignGroups/CampaignGroupData";
import { DropzoneAreaBase } from "react-mui-dropzone";
import { primaryGreenColor } from "utils/styles";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import notifiers from "global/constants/NotificationConstants";
import urls from "global/constants/UrlConstants";
import campaignDeleteModal from "assets/images/campaignDeleteModal.svg";
import updateCampaignGroupImg from "assets/images/updateCampaignGroup.svg";
import { ReactComponent as dropZoneDropZone } from "assets/icons/dropZoneDropZone.svg";
import { useTitle } from "utils/UseTitle";
import { useAppSelector } from "utils/hooks";
import { useDispatch } from "react-redux";
import { selectIsManager } from "redux/authSlice";
import { useLocation } from "react-router";
import { hasAccessTo } from "utils/AuthorizationManager";
import { changeRun, selectIsTourActive } from "redux/tourSlice";

const initialUpdateGroupState = {
  groupId: "",
  groupName: "",
  groupCSV: {} as any,
};

const CampaignGroups = () => {
  useTitle(strings.CampaignGroups);
  const classes = campaignGroupStyles;
  const dispatch = useDispatch();
  const isManager = useAppSelector(selectIsManager);
  const isTourActive = useAppSelector(selectIsTourActive);

  const urlParams = new URLSearchParams(useLocation().search);
  const pageFromUrl = validatePageQuery(urlParams.get("page"));
  const perPageFromUrl = validatePerPageQuery(urlParams.get("perPage"));
  const categoryFromUrl = validateCategoryQuery(urlParams.get("category"));
  const groupTypeFromUrl = urlParams.get("type");
  const searchFromUrl = urlParams.get("search");

  const searchRef = useRef<any>("");
  const [groupsFilters, setGroupsFilters] = useState<groupsFilters>(
    initialGroupFilterState(
      pageFromUrl,
      perPageFromUrl,
      categoryFromUrl,
      searchFromUrl ?? "",
      groupTypeFromUrl ?? "All"
    )
  );
  const [uploadGroupDialogHandler, setUploadGroupDialogHandler] =
    useState(false);
  const [groupsCounts, setGroupsCounts] = useState<number>(0);
  const [formattedTableData, setFormattedTableData] = useState<CampaignData[]>(
    []
  );
  const [deleteGroup, setDeleteGroup] = useState("");
  const [deleteName, setDeleteName] = useState<string>();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalUpload, SetOpenModalUpload] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortingConfig[]>([]);
  const [groupTypes, setGroupTypes] = useState<string[]>(["All"]);
  const [updateCampaignGroup, setUpdateCampaignGroup] = useState(
    initialUpdateGroupState
  );
  const hasContactUploadAccess = hasAccessTo(
    strings.contact,
    strings.uploadPermission
  );
  const hasContactDeleteAccess = hasAccessTo(
    strings.contact,
    strings.deletePermission
  );
  const hasContactUpdateAccess = hasAccessTo(
    strings.contact,
    strings.updatePermission
  );

  useEffect(() => {
    if (isTourActive) {
      dispatch(changeRun(isLoading ? false : true));
    }
  }, [isLoading]);

  useEffect(() => {
    const { page, perPageData, searchText, dropdownValue, groupType } =
      groupsFilters;
    searchRef.current.value = searchText;
    window.history.replaceState(
      null,
      "",
      `?page=${page}&perPage=${perPageData}&category=${dropdownValue}&type=${groupType}&search=${searchText}`
    );
    getGroupTypes();
    getTableData();
  }, [groupsFilters, sortConfig]);

  const handlePerPageData = (event: any) => {
    const perPageData = event.target.value;
    setGroupsFilters({
      ...groupsFilters,
      perPageData,
      page: 1,
    });
  };

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setGroupsFilters({ ...groupsFilters, page });
  };

  const handleDropDownChange = (event: any) => {
    const dropdownValue = event.target.value;
    setGroupsFilters({
      ...groupsFilters,
      dropdownValue,
      page: 1,
    });
  };

  const handleGroupTypeChange = (event: any) => {
    const groupType = event.target.value;
    setGroupsFilters({
      ...groupsFilters,
      groupType,
      page: 1,
    });
  };

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value.trim();
    setGroupsFilters({
      ...groupsFilters,
      searchText,
      page: 1,
    });
  };

  const getGroupTypes = async () => {
    try {
      const groupType = await getContactGroupTypes();
      setGroupTypes(["All",...groupType]);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getTableData = async () => {
    const { page, perPageData, searchText, dropdownValue, groupType } =
      groupsFilters;
    try {
      setIsLoading(true);
      const [subordinates, count] = await Promise.all([
        getSubordinatesList(),
        isTruthy(searchText)
          ? getSearchedCampaignGroupsCount(searchText, dropdownValue,groupType)
          : getCampaignGroupsCount(dropdownValue, groupType),
      ]);
      setGroupsCounts(count);
      await getTableDataAccordingToPage(
        page,
        count,
        dropdownValue,
        groupType,
        perPageData,
        searchText,
        subordinates
      );
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getTableDataAccordingToPage = async (
    page: number,
    count: number,
    dropdownValue: string,
    groupType: string,
    perPageData: number,
    searchText: string,
    subordinates: string[]
  ) => {
    try {
      let tableData: any[] = [];
      const expectedPages = Math.ceil(count / perPageData);
      if (page <= expectedPages || count === 0) {
        tableData = isTruthy(searchText)
          ? await getSearchedCampaignGroups(
              searchText,
              dropdownValue,
              groupType,
              page,
              perPageData,
              sortConfig
            )
          : await getCampaignGroups(
              page,
              dropdownValue,
              groupType,
              perPageData,
              sortConfig
            );
        getTableFormattedData(subordinates, tableData);
      } else {
        setGroupsFilters({
          ...groupsFilters,
          page: page - 1,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const getTableFormattedData = (subordinates: string[], data: any[]) => {
    const tableValue = data.map((item: any) => {
      return {
        name: (
          <Typography
            sx={classes.rowColor}
            onClick={() => {
              history.push(`${urls.campaignGroupsViewPath}/` + item.id);
            }}
          >
            {item.name}
          </Typography>
        ),
        type: item.type,
        owner: <Typography sx={{ minWidth: "200px" }}>{item.owner}</Typography>,
        recipientCount: (
          <Box style={{ minWidth: "150px" }}>
            {getFormattedStatsCount(item.recipientCount)}
          </Box>
        ),
        action: (
          <>
            {subordinates?.includes(item.owner) && (
              <>
                <Grid container xs={12} style={{ minWidth: "200px" }}>
                  <Stack
                    direction={{ xs: "row", md: "row", sm: "row", lg: "row" }}
                    divider={
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={classes.dividerStyle}
                      />
                    }
                  >
                    {hasContactDeleteAccess && (
                      <Grid item>
                        <Tooltip title="Delete Group" placement="top" arrow>
                          <Button
                            sx={classes.groupBtn}
                            onClick={() => {
                              deleteRequestBtn(item.id, item.name);
                            }}
                          >
                            <DeleteOutlineIcon htmlColor={primaryGreenColor} />
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item>
                      <Tooltip title="Export Group" placement="top" arrow>
                        <Button
                          sx={classes.groupBtn}
                          onClick={() => {
                            handleDownload(item.id, item.name);
                          }}
                        >
                          <DownloadIcon htmlColor={primaryGreenColor} />
                        </Button>
                      </Tooltip>
                    </Grid>
                    {hasContactUpdateAccess && (
                      <Grid item>
                        <Tooltip title="Update Group" placement="top" arrow>
                          <Button
                            sx={classes.groupBtn}
                            onClick={() => {
                              handleUpload(item.id, item.name);
                            }}
                          >
                            <FileUploadIcon htmlColor={primaryGreenColor} />
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                  </Stack>
                </Grid>
              </>
            )}
          </>
        ),
        id: item.id,
      };
    });
    setFormattedTableData(tableValue);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoading(true);
      setOpenModal(false);
      const response = await deleteGroupRequest(deleteGroup);
      openSuccessNotification(response.message);
      getTableData();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      setIsLoading(false);
    }
  };

  const handleOnDropzone = (event: any) => {
    setUpdateCampaignGroup({ ...updateCampaignGroup, groupCSV: event[0] });
  };

  const handleDeleteFileName = () => {
    setUpdateCampaignGroup({ ...updateCampaignGroup, groupCSV: {} });
  };

  const upLoadDialogContent = () => {
    return (
      <Grid>
        <InputLabel sx={classes.input} shrink>
          Updated File
          <Box ml={0.4} sx={classes.star}>
            *
          </Box>
        </InputLabel>
        <Box sx={classes.dropzoneAreaBase}>
          <DropzoneAreaBase
            fileObjects={[]}
            dropzoneText="Drag and Drop your attachments"
            onAdd={(e) => {
              handleOnDropzone(e);
            }}
            maxFileSize={3000000}
            acceptedFiles={[".csv"]}
            showAlerts={true}
            showPreviewsInDropzone={true}
            showFileNames={true}
            filesLimit={1}
            Icon={dropZoneDropZone}
          />
        </Box>
        {Object.keys(updateCampaignGroup.groupCSV).length > 0 && (
          <Chip
            sx={classes.previewChip}
            label={updateCampaignGroup?.groupCSV?.file?.path}
            variant="filled"
            onDelete={handleDeleteFileName}
          />
        )}
        <Typography sx={classes.warningContent}>
          * Only supporting updating of existing contacts first name, last name,
          contact number, and tags.
        </Typography>
        <Typography sx={classes.warningContent}>
          * Please do not add any new email ids while updating contact list.
        </Typography>
      </Grid>
    );
  };

  const handleConfirmUpload = async () => {
    try {
      setIsLoading(true);
      if (!Object.keys(updateCampaignGroup.groupCSV).length) {
        openErrorNotification("Please upload the updated campaign group csv");
        setIsLoading(false);
        return;
      }
      const data = new FormData();
      const file = updateCampaignGroup.groupCSV.file;
      data.append("file", file);
      const response = await updateContactListDocument(
        data,
        updateCampaignGroup.groupId
      );
      openSuccessNotification(response.value ?? "Document has been uploaded.");
      handleCloseModelUpload();
      setIsLoading(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      setIsLoading(false);
    }
  };

  const uploadDialogFooterContent = () => {
    return (
      <Box sx={classes.dialogFooter}>
        <CustomButton
          customClasses={classes.buttonWhiteBg}
          label="Cancel"
          onClick={() => handleCloseModelUpload()}
          id="campaign_group_cancel_button"
        />
        <CustomButton
          label="Update"
          loading={isLoading}
          onClick={() => handleConfirmUpload()}
          id="campaign_group_update_button"
        />
      </Box>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={updateCampaignGroupImg} alt="image" />
      </Box>
    );
  };

  const customDialogUpload = () => {
    return (
      <CustomDialog
        dialogHeaderContent={dialogHeaderContent()}
        isDialogOpen={openModalUpload}
        closable
        closeButtonVisibility
        handleDialogClose={handleCloseModelUpload}
        dialogTitleContent={uploadDialogTitleContent()}
        dialogBodyContent={upLoadDialogContent()}
        dialogFooterContent={uploadDialogFooterContent()}
        width="550px"
      />
    );
  };

  const handleUpload = (groupId: string, groupName: string) => {
    SetOpenModalUpload(true);
    setUpdateCampaignGroup({
      ...updateCampaignGroup,
      groupId,
      groupName,
    });
  };

  const handleCloseModelUpload = () => {
    SetOpenModalUpload(false);
    setUpdateCampaignGroup(initialUpdateGroupState);
  };

  const uploadDialogTitleContent = () => {
    return (
      <Box sx={classes.dialogTitleWrapper}>
        <Box sx={classes.titleRight}>Update Campaign Group</Box>
        <Box sx={classes.groupNameBox}>
          Group Name:
          <Box component={"span"} sx={classes.spanStyle}>
            {updateCampaignGroup.groupName}
          </Box>
        </Box>
      </Box>
    );
  };

  const handleDownload = async (requestId: string, name: string) => {
    try {
      const data = await downloadContactList(requestId);
      let a = document.createElement("a");
      // @ts-ignore
      a.style = "display:none";
      let url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = `${name}.csv`;
      a.click();
      // @ts-ignore
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  // delete button functionality

  const deleteRequestBtn = (requestId: string, name: string) => {
    setDeleteGroup(requestId);
    setDeleteName(name);
    setOpenModal(true);
  };

  const handleCloseModel = () => {
    setOpenModal(false);
  };

  const dialogTitleContent = () => {
    return <Typography sx={classes.modalTitle}>Delete Group</Typography>;
  };

  const dialogContent = () => {
    return (
      <Box sx={classes.centerItemFlex} style={{ flexDirection: "column" }}>
        <Typography sx={classes.fontText}>
          Are you sure you want to delete
        </Typography>
        <Typography sx={classes.fontText}>
          <Box component="span" sx={classes.textBold}>
            {deleteName}
          </Box>{" "}
          group ?
        </Typography>
      </Box>
    );
  };

  const addUserHeaderContent = () => {
    return <img src={campaignDeleteModal} alt="image not found" />;
  };

  const addUserDialogFooter = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            customClasses={classes.cancelButtonStyle}
            label="Cancel"
            onClick={() => handleCloseModel()}
            id="campaign_group_add_user_cancel_button"
          />
          <CustomButton
            label="Delete"
            onClick={() => handleConfirmDelete()}
            id="campaign_group_add_user_delete_button"
          />
        </Box>
      </Grid>
    );
  };

  const customDialog = () => {
    return (
      <CustomDialog
        isDialogOpen={openModal}
        closable
        handleDialogClose={handleCloseModel}
        dialogTitleContent={dialogTitleContent()}
        dialogBodyContent={dialogContent()}
        dialogHeaderContent={addUserHeaderContent()}
        dialogFooterContent={addUserDialogFooter()}
        width="460px"
        closeButtonVisibility
      />
    );
  };

  const refreshTableData = () => {
    getTableData();
  };

  const getHeader = () => {
    return (
      <Box>
        <Typography sx={classes.mainCardHeading}>Groups</Typography>
      </Box>
    );
  };

  const addCampaignGroup = () => {
    return (
      <Box sx={classes.rowStyling}>
        <CustomButton
          id="groups_download_template_button"
          label="Download&nbsp;Template"
          onClick={ExportCSV(
            ["EMAIL_ID,FIRST_NAME,LAST_NAME,CONTACT_NO,TAGS"],
            "email_template"
          )}
        />
        {hasContactUploadAccess && (
          <CustomButton
            id="groups_upload_button"
            label="Upload Group"
            onClick={() => setUploadGroupDialogHandler(true)}
          />
        )}
      </Box>
    );
  };

  const getUploadGroupDialog = () => {
    return (
      <UploadGroup
        showDialog={uploadGroupDialogHandler}
        handleDialogClose={closeUploadGroupDialogHandler}
        tableData={getTableData}
      />
    );
  };

  const closeUploadGroupDialogHandler = () => {
    setUploadGroupDialogHandler(false);
  };

  const groupTable = () => {
    return (
      <Box
        id="groups_group_table"
        sx={{ minWidth: "300px", width: "100%", overflow: "auto" }}
      >
        <CustomTable
          headers={campaignGroupHeader}
          rows={formattedTableData}
          handlePageChange={handleChangePage}
          paginationCount={groupsCounts}
          pageNumber={groupsFilters.page}
          isLoading={isLoading}
          handlePerPageData={handlePerPageData}
          perPageData={groupsFilters.perPageData}
          rowsPerPage={groupsFilters.perPageData}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
          sortingEntity={"ContactGroup"}
        />
      </Box>
    );
  };

  const getGroupTable = () => {
    return <Box sx={classes.mainSection}>{groupTable()}</Box>;
  };

  const getDropdown = () => {
    return (
      <Box sx={classes.inputsSectionDropdowns}>
        <Box>
          <CustomButton
            id="groups_refresh_button"
            onClick={() => refreshTableData()}
            label={<CachedIcon htmlColor={darkPurpledColor} />}
            customClasses={classes.refreshBtn}
          />
        </Box>
        <Box>
          <FormControl>
            <Select
              id="groups_category_groupType"
              sx={{ ...classes.dropDownStyle, width: "150px" }}
              value={groupsFilters.groupType}
              onChange={handleGroupTypeChange}
              displayEmpty
              renderValue={
                groupsFilters.groupType !== ""
                  ? undefined
                  : () => " Select Group Type"
              }
            >
              {groupTypes.map((data) => (
                <MenuItem value={data} sx={classes.optionStyle}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {isManager && (
          <Box>
            <FormControl>
              <Select
                id="groups_category_dropdown"
                sx={classes.dropDownStyle}
                value={groupsFilters.dropdownValue}
                onChange={handleDropDownChange}
              >
                {dropDownOptions.map((data) => (
                  <MenuItem value={data.value} sx={classes.optionStyle}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Box
          id="groups_search_field_container"
          sx={{...classes.searchWrapper , width: "150px"}}
          pt={1}
        >
          <TextField
            id="groups_search_field"
            placeholder="Search text"
            sx={classes.searchInput}
            inputRef={searchRef}
            onChange={debounceEventHandler(
              handleSearchOnChange,
              strings.SEARCH_TIME_OUT
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    );
  };

  const InputsSection = () => {
    return (
      <Box sx={classes.mainCardInputsSection}>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={12} xl={12} sx={classes.inputSection}>
            <Box>{getDropdown()}</Box>
            <Box>{addCampaignGroup()}</Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const campaignPanel = () => {
    return (
      <>
        <Box>
          <CustomAppHeader className={classes.headerBackgroundColor}>
            <Grid container xs={12} md={12} lg={12} xl={12} alignItems="center">
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                {getHeader()}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={10}
                lg={9}
                xl={9}
                display="flex"
                justifyContent={{
                  md: "flex-start",
                  lg: "flex-start",
                  xl: "flex-end",
                }}
                gap={"10px"}
              >
                {InputsSection()}
              </Grid>
            </Grid>
          </CustomAppHeader>
          <Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {getGroupTable()}
            </Grid>
            {getUploadGroupDialog()}
            {customDialog()}
            {customDialogUpload()}
          </Grid>
          <CustomLoader isLoading={isLoading} />
        </Box>
      </>
    );
  };

  return campaignPanel();
};

export default CampaignGroups;
