import { useState, useEffect } from "react";
import history from "utils/history";
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  Stack,
  FormHelperText,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import {
  CustomAppHeader,
  CustomButton,
  CustomInput,
  CustomPaper,
} from "global/components";
import Step1Styles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/ChooseCampaignType/ChooseCampaignType.styles";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { isTruthy, openErrorNotification } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import {
  campaignExistsCheck,
  customDomain,
} from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignServices";
import { handleStep1Validation } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignHelpers";
import strings from "global/constants/StringConstants";
import CreateCampaignStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignNew.styles";
import urls from "global/constants/UrlConstants";
import { disabledBackgroundColor } from "utils/styles";

const ChooseElementToABTest = [
  {
    key: "subject-lines",
    label: "Subject lines",
    description:
      "Test two different subject lines to improve your email open rates.",
  },
  {
    key: "email-content",
    label: "Email content",
    description: "Test two different designs to improve your click rates.",
  },
];

const EmailCampaignType = {
  [strings.EMAIL_REGULAR_CAMPAIGN]: {
    label: "Regular",
    description:
      "Maintain subscriber involvement by conveying your latest updates, showcasing your top-selling items, or disclosing an impending event by creating regular campaign for both your existing and prospective audience.",
  },
  // [strings.EMAIL_AB_CAMPAIGN]: {
  //   key: "ab",
  //   label: "A/B Test",
  //   description:
  //     "Choose an element to A/B test. Recipients in your test group will receive either version A or B. The version with the best engagement will be sent to your remaining recipients. Works best with 5000+ recipients.",
  // },
};

interface CustomProps {
  campaignData: any;
  setCampaignData: any;
  setActiveStep: any;
  campaignType: string;
  setCampaignType: Function;
}

const ChooseCampaignType = (props: CustomProps) => {
  const classes = Step1Styles;
  const createCampaignClasses = CreateCampaignStyles;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availableDomain, setAvailableDomain] = useState<string[]>([]);

  useEffect(() => {
    domainAvailable();
  }, []);

  const backClickHandler = () => {
    history.goBack();
  };

  const domainAvailable = async () => {
    try {
      setIsLoading(true);
      const response = await customDomain();
      setAvailableDomain(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidation = () => {
    const { isValid, errors } = handleStep1Validation(
      props.campaignData,
      availableDomain?.length > 1
    );
    props.setCampaignData(errors);
    return isValid;
  };

  const handleNext = async (pageNumber: number) => {
    try {
      if (handleValidation()) {
        setIsLoading(true);
        await campaignExistsCheck(props.campaignData.name.value.trim());
        props.setActiveStep(pageNumber);
        setIsLoading(false);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCampaignCategoryTab = (event: any, newValue: string) => {
    props.setCampaignType(newValue);
  };

  const onChangeHandler = (event: any) => {
    props.setCampaignData({
      ...props.campaignData,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const getCategoryTabs = () => (
    <Tabs
      sx={classes.categoryTabsSection}
      value={props.campaignType}
      onChange={handleCampaignCategoryTab}
    >
      {Object.keys(EmailCampaignType).map((type: string) => (
        <Tab
          sx={classes.categoryTab}
          value={type}
          label={EmailCampaignType[type].label}
        />
      ))}
    </Tabs>
  );

  const getAbTestBox = () => (
    <Grid container spacing={1}>
      {ChooseElementToABTest.map((element) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={classes.campaignElementAB}>
              <Typography sx={classes.boldFont}>{element.label}</Typography>
              <Typography sx={classes.regularFonts}>
                {element.description}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );

  const getCampaignNameAndDomains = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <CustomInput
            required
            label="Campaign Name"
            id="step1_name_field"
            type="text"
            name="name"
            placeHolder="Enter Campaign Name"
            propsToInputElement={{ maxlength: 40 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span>{props.campaignData.name.value.length} / 40</span>
                </InputAdornment>
              ),
            }}
            onChange={onChangeHandler}
            value={props.campaignData.name.value}
            error={props.campaignData.name.error}
          />
        </Grid>
        {availableDomain?.length > 1 && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
            <Stack direction="column">
              <InputLabel sx={classes.inputLabel} shrink>
                Available Domains
                <Box ml={0.4} sx={classes.star}>
                  *
                </Box>
              </InputLabel>
              <Select
                id="step1_domain_dropdown"
                sx={classes.dropdownStyleDomain}
                name="domain"
                value={props.campaignData?.domain?.value}
                onChange={onChangeHandler}
                renderValue={
                  props.campaignData?.domain?.value
                    ? undefined
                    : () => "Select a Domain Name"
                }
                displayEmpty
                error={props.campaignData?.domain?.error}
              >
                {availableDomain?.map((item, index) => (
                  <MenuItem sx={classes.optionStyle} key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error sx={classes.errorStyle}>
                {props.campaignData?.domain?.error}
              </FormHelperText>
            </Stack>
          </Grid>
        )}
      </Grid>
    );
  };

  const getNextButton = () => {
    return (
      <Box width="150px">
        <CustomButton label={"Next"} onClick={() => handleNext(2)} />
      </Box>
    );
  };

  const getCategoryDetails = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box my={2}>
            <Typography sx={classes.regularFonts}>
              {EmailCampaignType[props.campaignType].description}
            </Typography>
          </Box>
          {props.campaignType === strings.EMAIL_AB_CAMPAIGN && (
            <Box mb={2}>{getAbTestBox()}</Box>
          )}
          <Box mb={2}>{getCampaignNameAndDomains()}</Box>
          <Box mb={2}>{getNextButton()}</Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid>
      <CustomAppHeader className={createCampaignClasses.headerSection}>
        <Grid container xs={12} sx={createCampaignClasses.stepOneHeader}>
          <Box sx={createCampaignClasses.backArrowBox}>
            <Tooltip
              title={
                <CustomPaper
                  className={{
                    backgroundColor: disabledBackgroundColor,
                  }}
                >
                  <Typography sx={createCampaignClasses.tooltipText}>
                    {"Back to Create Campaigns"}
                  </Typography>
                </CustomPaper>
              }
              placement="right"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    background: "none",
                  },
                },
              }}
            >
              <KeyboardBackspace
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  backClickHandler();
                }}
              />
            </Tooltip>
          </Box>
          <Typography sx={createCampaignClasses.pageTitle}>
            Create Campaign
          </Typography>
        </Grid>
      </CustomAppHeader>
      <Box sx={classes.step1Wrapper}>
        {Object.keys(EmailCampaignType).length > 1 && getCategoryTabs()}
        {getCategoryDetails()}
        <CustomLoader isLoading={isLoading} />
      </Box>
    </Grid>
  );
};

export default ChooseCampaignType;
