import {
  getRelativeFontSize,
  primaryHeadingColor,
  theme,
  boldFont,
} from "utils/styles";

const StepTwoStyles = {
  mainContainerBox: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  headerSection: {
    padding: "40px 20px 20px 20px",
  },
  headerGridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerButtons: {
    width: "100%",
    margin: "0 5px",
    [theme.breakpoints.down("md")]: { marginTop: "20px" },
    [theme.breakpoints.down("sm")]: { marginTop: 0 },
  },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  },
  mainGridContainer: { height: "100%", padding: "20px" },
  formGridItem: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "20px",
    },
  },
  previewGridItem: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      padding: "20px 0",
    },
  },
  campaignDetailsBox: {
    border: "1px solid #eee",
    padding: "16px",
  },
} as const;
export default StepTwoStyles;
