import {
  boldFont,
  centerItemFlex,
  darkPurpledColor,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  pinkDarkColor,
  pinkThemedMenuItems,
  pureWhiteColor,
  purpleThemedSelectComponent,
  regularFont,
  theme,
  warningColor,
} from "utils/styles";

const Step2Styles = {
  step2Wrapper: {
    padding: theme.spacing(4),
  },
  headerBox: {
    background: pureWhiteColor,
    padding: "10px 5px",
    border: "1px solid #E7E7E7",
    borderRadius: "18px",
  },
  headerButtonGroup: {
    display: "flex",
    gap: "5px",
    flexWrap: "wrap",
    [theme.breakpoints.down("lg")]: { marginTop: "10px" },
  },
  headerButton: { width: "100%", whiteSpace: "nowrap" },
  label: { display: "flex" },
  labelText: {
    fontSize: "16px",
    ...boldFont,
    color: "rgba(0, 0, 0, 0.6)",
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    marginBottom: "10px",
    ...boldFont,
  },
  selectMenu: {
    width: "100% !important",
    height: "47px",
    backgroundColor: "none",
    paddingBottom: "5px",
  },
  autocompleteStyle: {
    "& .MuiOutlinedInput-root": {
      height: "47px",
      borderRadius: "12px",
      "&.Mui-focused fieldset": {
        borderColor: darkPurpledColor,
      },
      "& .MuiAutocomplete-input": {
        padding: "0px",
      },
    },
  },
  errorStyle: {
    paddingLeft: "15px",
  },
  dropZoneWrapper: {
    "& .MuiDropzoneArea-root": {
      width: "100%",
      minHeight: "70px",
      fontSize: "12px",
      borderRadius: "10px",
      borderWidth: "3px",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: getRelativeFontSize(2),
      ...mediumFont,
    },
  },
  warningContent: {
    color: warningColor,
    fontSize: getRelativeFontSize(),
    margin: "10px 0",
    ...mediumFont,
  },
  uploadFileStyle: {
    marginLeft: "5px",
    marginTop: "8px",
  },
  mailingListHeading: {
    ...mediumFont,
    fontSize: getRelativeFontSize(4),
  },
  permissionMessage: {
    color: "#ff4244",
    ...regularFont,
  },
  mailingListWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  checkboxWrapper: {
    background: "white",
    padding: "5px 15px 5px 25px",
    border: "1px solid #E7E7E7",
    borderRadius: "18px",
    margin: "0 15px 0 0",
    minWidth: "120px",
    display: "flex",
    justifyContent: "space-between",
  },
  checkbox: {
    margin: "0 0 0 15px",
    "&.Mui-checked": {
      color: pinkDarkColor,
    },
  },
  dropDownStyle: {
    backgroundColor: "#FFFFFF",
    height: "47px",
    padding: "2px",
    width: " 150px",
    borderRadius: "12px",
    marginLeft: "10px",
    ...purpleThemedSelectComponent,
    [theme.breakpoints.down("md")]: {
      margin: "0",
      marginBottom: "10px",
    },
  },
  optionStyle: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "12px",
    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        borderColor: darkPurpledColor,
      },
    },
  },
  dialogFooterWrapper: { ...centerItemFlex, paddingBottom: "20px" },
  dialogFooter: {
    display: "flex",
    gap: "10px",
    ...centerItemFlex,
    width: "100%",
    justifyContent: "center",
    "& button": {
      width: "120px",
    },
  },
  cancelButtonStyle: {
    color: "#212121 !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E7E7E7",
    "&:hover": {
      background: "none",
    },
  },
  addTagChip: {
    marginLeft: "5px",
    marginTop: "8px",
    borderRadius: "5px",
    ...mediumFont,
    fontSize: "15px",
    backgroundColor: "#fcf5ff",
  },
  boldFonts: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    textAlign: "center",
  },
  radioChecked: {
    "&.Mui-checked": {
      color: pinkDarkColor,
    },
  },
  scheduleModalTitle: {
    ...centerItemFlex,
    mt: 1,
  },
  scheduleBtn: {
    width: "170px !important",
  },
  campaignDetailsBox: {
    border: "1px solid #eee",
    padding: "16px",
  },
} as const;

export default Step2Styles;
