import { Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomDialog, CustomTable } from "global/components";
import { ViewContactStyles } from "screens/Contact/ViewContact.styles";
import recipientsSummaryModal from "assets/images/recipientsSummaryModal.svg";
import { Actions } from "screens/CampaignsNew/Campaigns/CampaignInterfaces";
import { downloadReportOpenedAndClicked } from "screens/CampaignsNew/Campaigns/Campaign.services";
import {
  convertESTtoUserLocalDateAndTime,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { useLocation } from "react-router-dom";
import { failedStatsBounceStatus } from "screens/FailedStats/FailedStatsData";
import strings from "global/constants/StringConstants";
import { HtmlTooltip } from "utils/styles";

const OpenHeader = [
  {
    name: "Recipient",
    field: "recipient",
  },
  {
    name: "Opened On",
    field: "openOn",
  },
  // {
  //   name: "Opened From",
  //   field: "openFrom",
  // },
  //For not displaying devices
  // {
  //   name: "Opened Device",
  //   field: "deviceName",
  // },
];

const clickHeader = [
  {
    name: "Recipient",
    field: "recipient",
  },
  {
    name: "Clicked On",
    field: "openOn",
  },
  {
    name: "Clicked From",
    field: "openFrom",
  },
  // For not displaying devices
  {
    name: "Clicked Device",
    field: "deviceName",
  },
  {
    name: "Urls",
    field: "urls",
  },
];

const unsubscribeHeader = [
  {
    name: "Recipient",
    field: "recipient",
  },
  {
    name: "Unsubscribed On",
    field: "openOn",
  },
  {
    name: "Unsubscribed From",
    field: "openFrom",
  },
  //For not displaying devices
  {
    name: "Unsubscribed Device",
    field: "deviceName",
  },
];

const failedHeader = [
  {
    name: "Recipient",
    field: "recipient",
  },
  {
    name: "Response",
    field: "response",
  },
];

const getHeaders = {
  Clicked: clickHeader,
  Opened: OpenHeader,
  Unsubscribed: unsubscribeHeader,
  Failed: failedHeader,
} as { [key: string]: any[] };

interface CustomProps {
  isModalVisible: boolean;
  handleClose: Function;
  actionType: string;
  actionData: Actions[];
  actionCount: number;
  setPage: Function;
  page: any;
  perPageData: number;
  setPerPageData: Function;
}

const ActionModals = (props: CustomProps) => {
  const campaignId = new URLSearchParams(useLocation().search).get("id");
  const classes = ViewContactStyles;

  useEffect(() => {
    props.setPage(1);
  }, [props.isModalVisible]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    props.setPage(newPage);
  };

  const dialogBody = () => {
    return (
      <Box
        sx={{
          minWidth: "600px",
          width: "100%",
          overflow: "auto",
        }}
      >
        <CustomTable
          headers={getHeaders[props.actionType]}
          rows={setTableData()}
          handlePageChange={handleChangePage}
          rowsPerPage={props.perPageData}
          paginationCount={props.actionCount}
          pageNumber={props.page}
          setPage={props.setPage}
          isRowPerPageEnable={true}
        />
      </Box>
    );
  };

  const titleContent = () => {
    return (
      <Typography sx={classes.recipientTableTitle}>
        {props.actionType + " " + "Statistics"}
      </Typography>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <img src={recipientsSummaryModal} alt="" style={{ width: "100%" }} />
    );
  };

  const getFailEvent = (rawFailEvent: string) => {
    return failedStatsBounceStatus.find(
      (item: any) => item.value.toLowerCase() === rawFailEvent.toLowerCase()
    )?.label;
  };

  const isFailedActionModal = () => {
    return props.actionType === strings.Failed;
  };

  const setTableData = () => {
    const tableData = props.actionData.map((data: Actions) => {
      const flag =
        "https://cdn.ipwhois.io/flags/" +
        data?.actionDetails?.country?.toLowerCase() +
        ".svg";
      return {
        recipient: data.contactEmail,
        response: (
          <Typography>
            {data?.response && getFailEvent(data?.response)}
          </Typography>
        ),
        openOn: convertESTtoUserLocalDateAndTime(
          data?.actionDetails?.actionTimeLines?.[0]
        ),
        deviceName: data.actionDetails?.userAgent,
        openFrom: (
          <Box display="flex" alignItems="center">
            {data.actionDetails?.country ? (
              <>
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                    whiteSpace: "nowrap",
                    mr: 1,
                  }}
                  alt="flag"
                  src={flag}
                />
                <Typography>
                  {data.actionDetails.city} - {data.actionDetails.country}
                </Typography>
              </>
            ) : (
              "No data available"
            )}
          </Box>
        ),
        urls: (
          <>
            {data?.actionDetails?.urls?.map((url: string) => (
              <Box>
                <HtmlTooltip
                  title={url}
                  placement="bottom"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        width: "auto",
                        background: "none",
                      },
                    },
                  }}
                >
                  <Typography sx={classes.clickedUrlTextStyles}>
                    {url}
                  </Typography>
                </HtmlTooltip>
              </Box>
            ))}
          </>
        ),
      };
    });
    return tableData;
  };

  return (
    <CustomDialog
      dialogHeaderContent={dialogHeaderContent()}
      dialogTitleContent={titleContent()}
      dialogBodyContent={dialogBody()}
      isDialogOpen={props.isModalVisible}
      handleDialogClose={() => {
        props.handleClose?.();
      }}
      closable
      width="fit-content"
      closeButtonVisibility
    />
  );
};

export default ActionModals;
