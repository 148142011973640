import { useEffect, useState } from "react";
import { Grid, Box, Chip, Typography } from "@mui/material";
import { CustomButton, CustomDialog, CustomInput } from "global/components";
import StepThreeStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/StepThree.styles";
import { cloneDeep, isEmpty, isEqual, uniqWith } from "lodash";
import { useAppSelector } from "utils/hooks";
import { selectEmail } from "redux/authSlice";
import userUpdateImg from "assets/images/uploadUser.svg";
import strings from "global/constants/StringConstants";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { sendTestEmail } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignServices";
import { mapFormDataToValues } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignHelpers";

interface CustomProps {
  testMailModalOpen: boolean;
  setTestMailModalOpen: any;
  setIsLoading: any;
  campaignData: any;
  templateHtml: any;
}

const classes = StepThreeStyles;

export const TestMailModal = ({
  testMailModalOpen,
  setTestMailModalOpen,
  setIsLoading,
  campaignData,
  templateHtml,
}: CustomProps) => {
  const loggedInUserEmail = useAppSelector(selectEmail);
  const [addTestEmail, setTestEmail] = useState<any>({
    value: "",
    error: "",
  });
  const [finalTestEmail, setFinalTestEmail] = useState<string[]>([
    loggedInUserEmail,
  ]);

  useEffect(() => {
    setTestEmail({ value: "", error: "" });
    setFinalTestEmail([loggedInUserEmail]);
  }, [testMailModalOpen]);

  const testMailSubmit = async () => {
    try {
      setIsLoading(true);
      let payload = mapFormDataToValues(campaignData);
      payload.subject = `[Test] ${payload.subject}`;
      payload.status = "Submitted";
      payload.content = templateHtml;
      payload.testIds = finalTestEmail;

      const selfEmailResponse = await sendTestEmail(payload);
      if (selfEmailResponse) {
        openSuccessNotification(selfEmailResponse.message);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const deleteEmailHandler = (indexValue: number) => {
    setFinalTestEmail(
      finalTestEmail.filter((item: any, index: number) => index !== indexValue)
    );
  };

  const validateEmail = () => {
    let isValid = true;
    const clonedAddTestEmailValue = cloneDeep(addTestEmail);
    const checkExistingValue = finalTestEmail?.some(
      (item: any) => item === (addTestEmail?.value).toLowerCase().trim()
    );
    if (checkExistingValue) {
      clonedAddTestEmailValue.error = "You cannot add duplicate emails.";
      isValid = false;
    }
    if (!strings.regex.test(addTestEmail.value)) {
      clonedAddTestEmailValue.error = "Please enter valid email";
      isValid = false;
    }
    if (finalTestEmail?.length > strings.TEST_MAIL_LIMIT) {
      clonedAddTestEmailValue.error = `Test emails can't be sent to more than ${
        strings.TEST_MAIL_LIMIT + 1
      } emails`;
      isValid = false;
    }
    setTestEmail({ ...clonedAddTestEmailValue });
    return isValid;
  };

  const addMultipleEmailHandler = () => {
    if (validateEmail()) {
      const bulkEmail: any[] = [];
      bulkEmail.push(...finalTestEmail, addTestEmail.value);
      const filterEmailValue = uniqWith(bulkEmail, isEqual);
      setFinalTestEmail(filterEmailValue);
      setTestEmail({ value: "", error: "" });
    }
  };

  const modalHeader = () => {
    return (
      <Box display="flex">
        <img src={userUpdateImg} alt="Image not found!" />
      </Box>
    );
  };

  const updateUserDialogTitle = () => {
    return (
      <Box>
        <Typography sx={classes.boldFonts}>
          Add emails to receive the test mail
        </Typography>
      </Box>
    );
  };

  const updateUserDialogBody = () => {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
            <CustomInput
              id="test_modal_email_field"
              name="email"
              propsToInputElement={{ maxLength: strings.USER_EMAIL_LIMIT }}
              placeHolder="Enter Email"
              error={addTestEmail.error}
              onChange={(event: any) =>
                setTestEmail({ value: event.target.value, error: "" })
              }
              value={addTestEmail.value}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <CustomButton
              label="Add Email"
              id="test_modal_add_button"
              onClick={addMultipleEmailHandler}
              customClasses={{ width: "100%", marginTop: "6px" }}
              disabled={isEmpty(addTestEmail.value)}
            />
          </Grid>
        </Grid>
        <Box display={"flex"} flexWrap={"wrap"}>
          {finalTestEmail &&
            finalTestEmail?.map((item: string, index: number) => (
              <Chip
                key={index}
                label={item}
                sx={classes.addTagChip}
                variant="filled"
                onDelete={() => deleteEmailHandler(index)}
              />
            ))}
        </Box>
      </Box>
    );
  };

  const updateUserDialogFooter = () => {
    return (
      <Grid container sx={classes.dialogFooterWrapper}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            id="test_mail_cancel_button"
            label="Cancel"
            onClick={() => setTestMailModalOpen(false)}
            customClasses={classes.cancelButtonStyle}
          />
          <CustomButton
            id="test_mail_submit_button"
            label="Test Mail"
            onClick={() => {
              setTestMailModalOpen(false);
              testMailSubmit();
            }}
            disabled={isEmpty(finalTestEmail)}
          />
        </Box>
      </Grid>
    );
  };

  return (
    <CustomDialog
      isDialogOpen={testMailModalOpen}
      closable
      closeButtonVisibility
      handleDialogClose={() => setTestMailModalOpen(false)}
      dialogHeaderContent={modalHeader()}
      dialogTitleContent={updateUserDialogTitle()}
      dialogBodyContent={updateUserDialogBody()}
      dialogFooterContent={updateUserDialogFooter()}
      width="500px"
    />
  );
};
