export const initialGroupContactsFilterState = () => {
  return {
    searchText: "",
    page: 1,
    rowsPerPage: 10,
  };
};

export const initialGroupToBeUpdatedState = {
  groupId: "",
  groupName: "",
  groupCSV: {} as any,
};
