import { Box } from "@mui/material";
import React from "react";
import { HtmlTooltip } from "utils/styles";
import InfoIcon from "assets/icons/Info.svg";

interface CustomProps {
  infoMessage: any;
  placement?: string;
}

const CustomInfoTooltip = (props: CustomProps) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
    >
      <HtmlTooltip
        title={props?.infoMessage ?? ""}
        placement={props.placement ? props.placement : "bottom"}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              width: "auto",
              background: "none",
            },
          },
        }}
      >
        <img
          alt="info_icon"
          src={InfoIcon}
          width={"15px"}
          height={"15px"}
          style={{
            textAlign: "center",
          }}
        />
      </HtmlTooltip>
    </Box>
  );
};

export default CustomInfoTooltip;
