import {
  boldFont,
  darkPurpledColor,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  pinkDarkColor,
  pinkThemedMenuItems,
  primaryHeadingColor,
  pureWhiteColor,
  purpleThemedSelectComponent,
  regularFont,
  textLightColor,
  theme,
} from "utils/styles";

const StepOneStyles = {
  headerGridContainer: {
    my: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      direction: "column",
    },
  },
  backArrowBox: {
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
  },
  tooltipText: {
    padding: "10px",
    color: pureWhiteColor,
    fontSize: "14px",
    ...regularFont,
  },
  titleGridItem: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: { flexDirection: "column" },
  },
  headerSection: {
    backgroundColor: "#fcf5ff",
    padding: "35px 20px 18px 20px",
  },
  headerButton: { width: "100%", whiteSpace: "nowrap" },
  headerButtonStyle: { margin: "5px", width: "140px" },
  pageTitle: {
    // border: "2px solid black",
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      // marginTop: theme.spacing(4),
      // marginBottom: theme.spacing(1),
    },
  },
  stepOneWrapper: {},
  gridMargin: {
    margin: "12px 20px 12px 20px",
    ...regularFont,
    color: textLightColor,
    fontSize: getRelativeFontSize(4),
    textAlign: "justify",
  },
  label: { display: "flex" },
  labelText: {
    fontSize: "16px",
    ...boldFont,
    color: "#212121",
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    marginBottom: "10px",
    ...boldFont,
  },
  nameField: {
    ...boldFont,
    color: "#212121",
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
  },
  selectMenu: {
    width: "100% !important",
    height: "47px",
    backgroundColor: "none",
    paddingBottom: "5px",
  },
  domainSelectMenu: {
    ...regularFont,
    ...purpleThemedSelectComponent,
    width: "100% !important",
    height: "47px",
    borderRadius: "12px",
  },
  autocompleteStyle: {
    "& .MuiOutlinedInput-root": {
      height: "47px",
      borderRadius: "12px",
      "&.Mui-focused fieldset": {
        borderColor: darkPurpledColor,
      },
      "& .MuiAutocomplete-input": {
        padding: "0px",
      },
    },
  },
  mailingListDropdown: {
    backgroundColor: "#FFFFFF",
    height: "47px",
    padding: "2px",
    width: "150px",
    borderRadius: "12px",
    ...purpleThemedSelectComponent,
    [theme.breakpoints.down("md")]: {
      margin: "0",
      marginBottom: "10px",
    },
  },

  dropdown: {
    ...regularFont,
    ...purpleThemedSelectComponent,
    height: "47px",
    width: "200px",
    backgroundColor: pureWhiteColor,
    borderRadius: "12px",
    color: "#22222C",
    border: 0,
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.03)",
    "&:hover": {
      border: 0,
    },
  },
  dropdownOptions: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  errorStyle: {
    paddingLeft: "15px",
  },
  //Mailing list table
  mailingListHeading: {
    ...boldFont,
    fontSize: getRelativeFontSize(2),
  },
  permissionMessage: {
    color: "#ff4244",
    ...regularFont,
  },
  mailingListWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  checkboxWrapper: {
    background: "white",
    height: "47px",
    padding: "0px 15px 0px 25px",
    border: "1px solid #E7E7E7",
    borderRadius: "18px",
    margin: "5px 15px 5px 0",
    minWidth: "120px",
    display: "flex",
    justifyContent: "space-between",
  },
  checkbox: {
    margin: "0 0 0 15px",
    "&.Mui-checked": {
      color: pinkDarkColor,
    },
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "12px",
    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        borderColor: darkPurpledColor,
      },
    },
  },
} as const;

export default StepOneStyles;
