import { inputLabelRequiredColor, theme } from "utils/styles";

const CustomTextareaStyles = {
  commonContainerStyles: {
    borderRadius: "10px",
    padding: "0 6px",
    display: "flex",
    flexDirection: "column",
  },
  mainContainerActive: {
    outline: "2px solid #4B0150",
  },
  mainContainerInactive: {
    outline: "1px solid #CFCFCF",
  },
  mainContainerError: {
    outline: `1px solid ${inputLabelRequiredColor}`,
  },
  templateComponentTextarea: {
    border: "none",
    outline: "none",
    borderRadius: "10px",
    padding: "10px",
    font: "inherit",
    resize: "none",
  },
  templateComponentHelperText: {
    margin: "0 0 10px 10px",
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("lg")]: {
      marginTop: "5px",
    },
  },
  characterCountContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorStyles: { paddingLeft: "5px" },
  iconButtonStyles: { borderRadius: "5px" },
} as const;

export default CustomTextareaStyles;
