import {
  boldFont,
  centerItemFlex,
  darkPurpledColor,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  primaryHeadingColor,
  pureWhiteColor,
  regularFont,
  theme,
} from "utils/styles";

const OnboardingStyles = {
  //Step one
  headingContainerBox: { marginTop: "50px" },
  userTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(30),
    color: darkPurpledColor,
    padding: "0 20px",
  },
  headingDescription: {
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
    color: darkPurpledColor,
    padding: "0 20px",
  },
  planContainer: {
    background: "#FFFFFF",
    boxShadow: " 0px 8px 30px rgba(0, 0, 0, 0.07)",
    borderRadius: "10px",
    height: "100%",
  },
  planCard: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "9px 9px 1px 0px",
    textAlign: "center",
    background: "#ADC804",
    color: "#1A0224",
  },
  yourPlan: {
    fontSize: "15px",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "25px",
  },
  planName: {
    ...boldFont,
    fontSize: getRelativeFontSize(4),
    textAlign: "center",
  },
  contentHeading: {
    ...mediumFont,
    textAlign: "center",
    color: "#666666",
  },
  featureHeading: {
    ...mediumFont,
    textAlign: "center",
    color: "#666666",
  },
  featureContent: {
    display: "flex",
    gap: "10px",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  featureContentData: {
    ...mediumFont,
    fontSize: { xl: "16px", lg: "14px", md: "16px", sm: "16px", xs: "11px" },
    color: "#666666",
  },
  headerSection: {
    padding: "35px 20px 18px 20px",
  },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
  },

  //Parent component
  logoBox: { padding: "0 30px", marginTop: "30px" },
  btn: {
    mt: 4,
    width: "200px",
  },
  descriptionText: {
    ...regularFont,
    textAlign: "center",
    fontSize: getRelativeFontSize(4),
    color: "#666666",
  },
  mainContainer: {
    marginTop: "20px",
    width: "100%",
  },
  gridItemStyles: {
    padding: "20px",
  },
  centerItem: {
    ...centerItemFlex,
  },
  stepColors: {
    ...regularFont,
    "& .MuiStepLabel-root .Mui-active": {
      color: "#8569BB ",
    },
    "& .MuiStepLabel-root .Mui-completed": {
      color: "#8569BB ", // circle color (COMPLETED)
    },
  },
  shortdesc: {
    color: "#666666",
    ...regularFont,
    fontSize: getRelativeFontSize(),
    textAlign: "center",
  },
  validity: {
    ...regularFont,
    display: "flex",
    fontSize: getRelativeFontSize(2),
    color: "#212121",
  },
  smallFontText: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#666666",
  },
  purpleHeadingText: {
    color: "#462682",
    fontSize: getRelativeFontSize(10),
    ...boldFont,
  },
  paymentTerms: {
    ...regularFont,
    fontSize: getRelativeFontSize(),
    margin: "15px 17px 0 17px",
    wordWrap: "break-word",
  },
  headingText: {
    color: "#212121",
    fontSize: getRelativeFontSize(15),
    ...boldFont,
  },
  cardElementStyle: {
    marginTop: "10px",
    border: "#dad3dd solid 1px",
    borderRadius: "9px",
    padding: "12px",
    backgroundColor: "#fff",
  },
  whiteHeadingText: {
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(15),
    ...boldFont,
  },
  mediumText: {
    color: "#212121",
    fontSize: getRelativeFontSize(6),
    ...mediumFont,
    justifyContent: "center",
    textAlign: "center",
  },
  lastStepContainer: {
    backgroundColor: "#5A3C97",
    height: "100vh",
    ...centerItemFlex,
  },
  stepperHeading: {
    ...boldFont,
    fontSize: getRelativeFontSize(2),
    color: "#474747",
  },
  stepperDesc: {
    ...regularFont,
    fontSize: getRelativeFontSize(),
    maxWidth: "140px",
  },
  whiteDescText: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#FFFFFF",
  },
  borderBox: {
    padding: "20px 30px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #B7B7B7",
    borderRadius: "10px",
  },
  inputContainerBox: { margin: "10px 0" },
  buttonContainer: { margin: "30px 0" },
  buttonStyles: { width: "100%" },
  inputLabelStyles: {
    ...boldFont,
    color: "#212121",
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
  },
  dropdown: {
    minWidth: "150px",
    margin: "18px 0px 8px 0px",
    height: "47px",
    borderRadius: "10px",
    background: pureWhiteColor,
    ...regularFont,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
} as const;
export default OnboardingStyles;
