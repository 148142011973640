import { Grid, Typography, Box, FormHelperText } from "@mui/material";
import { CustomButton, CustomInput } from "global/components";
import history from "utils/history";
import urls from "global/constants/UrlConstants";
import forgotPasswordStyles from "screens/LandingPage/components/ForgotPassword.styles";
import { useState } from "react";
import { User } from "models/interfaces";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import { forgotPassword } from "screens/LandingPage/landingPageService";
import {
  forgotPasswordValidation,
  forgotPasswordValue,
} from "screens/LandingPage/components/LoginTypesAndValidation";
import strings from "global/constants/StringConstants";
import notifiers from "global/constants/NotificationConstants";
import { useTitle } from "utils/UseTitle";
import React from "react";

interface CustomProps {
  executeRecaptcha: any;
}

const ForgotPassword = React.forwardRef((props: CustomProps, ref: any) => {
  useTitle(strings.ForgotPasswordTitle);
  const classes = forgotPasswordStyles;
  const emailRegex = strings.regex;
  const [formField, setFormField] = useState(forgotPasswordValue);
  const [loading, setLoading] = useState(false);

  const logInPage = () => {
    history.push(urls.loginViewPath);
  };

  const handleOnchange = (event: React.ChangeEvent<any>) => {
    if (event.target.value !== undefined) {
      setFormField({
        ...formField,
        [event.target.name]: {
          ...formField[event.target.name],
          value: event.target.value,
        },
      });
    }
  };

  const onSubmit = async () => {
    if (
      handleValidation() &&
      emailRegex.test(formField.email.value) &&
      isTruthy(formField.email.value)
    ) {
      const userData: User = {
        pwd: "",
        authToken: "",
        name: "",
        contactNo: "",
        email: formField.email.value.toLowerCase(),
        role: "",
        resources: [],
        account: "",
        company: "",
        captchaToken: "",
      };
      try {
        let token = "";
        if (urls.PROD) {
          token = await props.executeRecaptcha();
        }
        setLoading(true);
        userData.captchaToken = token;
        await forgotPassword(userData);
        setLoading(false);
        openSuccessNotification(
          "We have sent you an email with your password reset link."
        );
        history.push(urls.landingViewPath);
      } catch (error: any) {
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
        setLoading(false);
      }
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit();
    }
  };

  const handleValidation = () => {
    const { isValid, errors }: { isValid: boolean; errors: any } =
      forgotPasswordValidation(formField);
    setFormField({ ...errors });
    return isValid;
  };

  return (
    <Box>
      <Typography sx={classes.getHeading}>Reset Password</Typography>
      <Box mx={4}>
        <Typography sx={classes.headingCenter} component="span">
          Get password-reset link on your email
        </Typography>
      </Box>
      <Grid container sx={[classes.formCenter, { marginTop: "30px" }]}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={classes.label}>
            <Typography sx={classes.labelText}>Email </Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            placeHolder="Enter your email"
            id="email"
            type="email"
            name="email"
            value={formField.email.value}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
            error={!isTruthy(formField.email.value) && formField.email.error}
          />
          {!emailRegex.test(formField.email.value) &&
            formField.email.value.length > 0 && (
              <FormHelperText error sx={classes.errorStyling}>
                Invalid email Id
              </FormHelperText>
            )}
          <Box marginTop={2} width={"100%"}>
            <CustomButton
              onClick={onSubmit}
              label="Submit"
              loading={loading}
              disabled={loading}
              customClasses={classes.submitBtn}
              id="forgot_password_button"
            />
          </Box>

          <Box marginTop={2} width={"100%"}>
            <Typography onClick={logInPage} sx={classes.submitBtn}>
              Back to Login
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ForgotPassword;
