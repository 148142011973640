import {
  FormHelperText,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomDialog,
  CustomInput,
  CustomTable,
} from "global/components";
import notifiers from "global/constants/NotificationConstants";
import {
  debounceEventHandler,
  getFormattedStatsCount,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import {
  previewTagsHeader,
  tagsHeader,
} from "screens/CreateCampaign/CreateCampaignData";
import {
  createTagGroup,
  getTags,
  getSearchTagCountData,
  getSearchTagCount,
  getTagsCount,
} from "screens/CreateCampaign/createCampaignServices";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Stack } from "@mui/system";
import uploadGroupStyles from "global/components/UploadGroups/UploadGroups.styles";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { getContactGroupTypes } from "screens/CampaignGroups/CampaignGroups.service";
import UploadGroupModal from "assets/images/UploadGroupModal.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import strings from "global/constants/StringConstants";
import {
  tagsTableField,
  uploadGroupValidation,
} from "screens/CreateCampaign/createCampaignTypes";
import { SortingConfig } from "models/interfaces";
interface TagTableProps {
  setLoading?: Function;
  setMailingAudience?: Function;
  stateData: any;
  getSelectedRow?: Function;
  finalSubmitGroups?: Function;
  selectedRowCheckData?: any;
  isSelectedRow?: any;
  singleSelectCheckBox?: Function;
  selectAllcheckBox?: Function;
  test?: any;
}

const TagsTable = (props: TagTableProps) => {
  const uploadStyles = uploadGroupStyles;
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState<any>([]);
  const [viewDialog, showDialog] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<any>(tagsTableField);
  const [groupTypes, setGroupTypes] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [countSelectedEmail, setCountSelectedEmail] = useState<number>(0);
  const [previewTablePage, setPreviewTablePage] = useState<number>(0);
  const [perPageData, setPerPageData] = useState(10);
  const [sortConfig, setSortConfig] = useState<SortingConfig[]>([]);

  const isSelected = (id: any) => {
    return selected.indexOf(id) !== -1;
  };
  const userPerPage = 5;
  const pageVisited = previewTablePage * userPerPage;

  useEffect(() => {
    if (props.stateData.checkedListId.length === 0) {
      setCountSelectedEmail(0);
    }
  }, [props.stateData.checkedListId]);

  useEffect(() => {
    getGroupTypes();
  }, []);

  useEffect(() => {
    setFormFields(tagsTableField());
  }, [viewDialog]);

  useEffect(() => {
    if (searchValue) {
      tagsSearchHandler();
      setPageNumber(1);
    } else {
      fetchTagsTableData();
    }
  }, [searchValue, pageNumber, perPageData, sortConfig]);

  const handlePerPageData = (event: any) => {
    setPerPageData(event.target.value);
    setPageNumber(1);
  };

  const handlePreviewTableData = () => {
    const finalData = selectedRowData?.map((item: any) => {
      return {
        name: item.name,
        owner: item.owner,
        recipientCount: item.recipientCount,
        remove: (
          <>
            <DeleteIcon onClick={() => finalPreviewTable(item?.id)} />
          </>
        ),
      };
    });

    return finalData;
  };
  const displayRows = handlePreviewTableData()?.slice(
    pageVisited,
    pageVisited + userPerPage
  );

  useEffect(() => {
    if (displayRows?.length === 0) {
      showDialog(false);
    }
  }, [displayRows]);

  const finalPreviewTable = (rowId: number) => {
    if (isSelected(rowId)) {
      const removeSelectedArrayValue = _.filter(selected, (item) => {
        return item !== rowId;
      });
      setSelected(removeSelectedArrayValue);
      const removeSelectedTagName = _.filter(selectedRowData, (item) => {
        return item.id !== rowId;
      });
      setSelectedRowData(removeSelectedTagName);
    }
  };
  const handleValidation = () => {
    const { errors, isValid } = uploadGroupValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };
  // const handleClick = (event: any, row: any) => {
  //   const selectedCheckBoxValue: any[] = [];
  //   const selectArrayTagName: any[] = [];
  //   if (isSelected(row.id)) {
  //     finalPreviewTable(row.id);
  //   } else {
  //     if (event.target.checked) {
  //       selectedCheckBoxValue.push(...selected, row.id);
  //       setSelected(selectedCheckBoxValue);
  //       selectArrayTagName.push(...selectedRowData, row);
  //       setSelectedRowData(selectArrayTagName);
  //     }
  //   }
  // };

  const handlePageChangeHandler = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPageNumber(page);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPreviewTablePage(page - 1);
  };

  // multiple select method
  const selectAllCheckBoxHandler = (event: any) => {
    let checkBoxValue: any[] = [];
    let checkAllRowData: any[] = [];
    if (event.target.checked) {
      const newSelected = tableData?.map((item: any) => item.id);
      checkBoxValue.push(...selected, ...newSelected);
      setSelected(checkBoxValue);
      const value = _.map(tableData, (item: any) => {
        return {
          id: item.id,
          name: item.name,
          owner: item.owner,
          recipientCount: item.recipientCount,
        };
      });
      setSelectedRowData(value);
      checkAllRowData.push(...selectedRowData, ...value);
      setSelectedRowData(checkAllRowData);
      return;
    }
    let newSelected = tableData?.map((item: any) => item.id);
    let unCheckSelectAll = selected?.filter(
      (item: any) => !newSelected.includes(item)
    );
    setSelected(unCheckSelectAll);
    const unSelectedRowData = _.filter(selectedRowData, (item: any) => {
      return !newSelected.includes(item.id);
    });
    setSelectedRowData(unSelectedRowData);
  };

  const fetchTagsTableData = async () => {
    try {
      props.setLoading && props.setLoading(true);
      const [response, countValue] = await Promise.all([
        getTags(pageNumber, perPageData, sortConfig),
        getTagsCount(),
      ]);
      setTableData(response);
      setCount(countValue);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setLoading && props.setLoading(false);
    }
  };

  const tagGroupHandler = async () => {
    if (handleValidation()) {
      try {
        props.setLoading && props.setLoading(true);
        const response = await createTagGroup(
          formFields.groupName.value,
          formFields.groupType.value,
          selected
        );
        openSuccessNotification(response.value);
        showDialog(false);
        props.setMailingAudience && props.setMailingAudience("contact");
      } catch (error: any) {
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
      } finally {
        props.setLoading && props.setLoading(false);
        showDialog(false);
      }
    }
  };

  const tagsSearchHandler = async () => {
    try {
      props.setLoading && props.setLoading(true);
      const [countValue, data] = await Promise.all([
        getSearchTagCount(searchValue),
        getSearchTagCountData(searchValue, pageNumber, perPageData, sortConfig),
      ]);
      setCount(countValue);
      setTableData(data);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setLoading && props.setLoading(false);
    }
  };

  const handleOnchange = (e: any) => {
    setSearchValue(e.target.value);
    setPerPageData(10);
  };

  const searchInputAndDropdown = () => {
    return (
      <>
        <Box display={"flex"} justifyContent={"space-between"} mb={4}>
          <CustomInput
            id="step4_tags_search_field"
            placeHolder="Search text"
            onChange={debounceEventHandler(
              handleOnchange,
              strings.SEARCH_TIME_OUT
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {/* <CustomButton
            customClasses={{ width: "200px" }}
            label="Create Tags Group"
            disabled={selected.length < 1}
            onClick={() => showDialog(true)}
          /> */}
        </Box>
      </>
    );
  };

  const getDialogFooter = () => {
    return (
      <Box sx={uploadStyles.footerWrapper}>
        <CustomButton
          customClasses={uploadStyles.buttonWhiteBg}
          label="Cancel"
          onClick={() => showDialog(false)}
        />
        <CustomButton label="Submit" onClick={() => tagGroupHandler()} />
      </Box>
    );
  };

  const getGroupTypes = async () => {
    try {
      const groupType = await getContactGroupTypes();
      setGroupTypes(groupType);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const dialogHeaderContent = () => {
    return (
      <>
        <Box display={"flex"}>
          <img src={UploadGroupModal} alt="" />
        </Box>
      </>
    );
  };

  const getDialogTitle = () => {
    return <Box sx={uploadStyles.mainHeader}>Create Tags Group</Box>;
  };

  const handleGroupNameOnChange = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleGroupTypeOnchange = (event: SelectChangeEvent) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };
  const getDialogBody = () => {
    return (
      <Grid xs={12} sm={12} md={12} lg={12}>
        <Grid style={{ height: "300px" }}>
          <CustomInput
            required
            label="Group Name"
            id="groupname-input"
            placeHolder="Group name"
            name="groupName"
            value={formFields.groupName.value}
            onChange={handleGroupNameOnChange}
            error={
              !isTruthy(formFields.groupName.value) &&
              formFields.groupName.error
            }
          />
          {isTruthy(formFields.groupName.value) &&
            formFields.groupName.value.length > 50 && (
              <FormHelperText error style={{ paddingLeft: "10px" }}>
                Group name should be less than or equal to 50 characters
              </FormHelperText>
            )}
          <Stack direction="column">
            <InputLabel id="simple-select" sx={uploadStyles.input} shrink>
              Group Type{" "}
              <Box ml={0.4} sx={uploadStyles.star}>
                *
              </Box>
            </InputLabel>
            <Select
              sx={uploadStyles.groupTypeStyle}
              id="groupType"
              value={formFields.groupType.value}
              name="groupType"
              displayEmpty
              renderValue={
                formFields.groupType.value !== ""
                  ? undefined
                  : () => "Group Type"
              }
              onChange={handleGroupTypeOnchange}
              error={
                formFields.groupType.value.length < 4 &&
                formFields.groupType.error.length !== 0
              }
            >
              {groupTypes.map((data) => (
                <MenuItem value={data} sx={uploadStyles.groupTypeOptions}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          {!isTruthy(formFields.groupType.value) && (
            <FormHelperText error style={{ paddingLeft: "10px" }}>
              {formFields.groupType.error}
            </FormHelperText>
          )}
          <InputLabel id="simple-select" sx={uploadStyles.input} shrink>
            Preview Table{" "}
            <Box ml={0.4} sx={uploadStyles.star}>
              *
            </Box>
          </InputLabel>
          <CustomTable
            headers={previewTagsHeader}
            rows={displayRows}
            paginationCount={handlePreviewTableData()?.length}
            pageNumber={previewTablePage + 1}
            handlePageChange={handlePageChange}
            rowsPerPage={5}
          />
        </Grid>
      </Grid>
    );
  };

  const onSelectAllClick = (event: any) => {
    props.selectAllcheckBox && props.selectAllcheckBox(event, tableData);
  };
  const handleClick = (event: any, row: any) => {
    props.singleSelectCheckBox && props.singleSelectCheckBox(event, row);
  };
  return (
    <>
      <Box>
        <Box mt={3} mb={2}>
          {props.stateData.checkedListId.length > 0 ? (
            <Typography className="padding-count">
              {getFormattedStatsCount(props.stateData.countSelectedEmail)}{" "}
              selected out of {""}
              {getFormattedStatsCount(props.stateData.allowedEmailCount)}{" "}
              available emails.
            </Typography>
          ) : (
            <Typography className="padding-count">
              {getFormattedStatsCount(countSelectedEmail)} selected out of {""}
              {getFormattedStatsCount(props.stateData.allowedEmailCount)}{" "}
              available emails.
            </Typography>
          )}
        </Box>
      </Box>
      {searchInputAndDropdown()}
      <CustomTable
        headers={tagsHeader}
        rows={tableData}
        handlePageChange={handlePageChangeHandler}
        pageNumber={pageNumber}
        paginationCount={count}
        checkboxSelection={true}
        handleClick={handleClick}
        isSelected={props.isSelectedRow}
        onSelectAllClick={onSelectAllClick}
        isSelectAll={props.selectedRowCheckData}
        setPage={setPageNumber}
        handlePerPageData={handlePerPageData}
        perPageData={perPageData}
        rowsPerPage={perPageData}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        sortingEntity={"ContactTags"}
      />

      <CustomDialog
        dialogHeaderContent={dialogHeaderContent()}
        isDialogOpen={viewDialog!}
        closable
        handleDialogClose={() => showDialog(false)}
        dialogTitleContent={getDialogTitle()}
        dialogBodyContent={getDialogBody()}
        dialogFooterContent={getDialogFooter()}
        closeButtonVisibility={true}
        width="800px"
      />
    </>
  );
};

export default TagsTable;
