import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import CampaignHistoryStyles from "screens/CampaignHistory/CampaignHistory.styles";
import CachedIcon from "@mui/icons-material/Cached";
import {
  CustomAppHeader,
  CustomButton,
  CustomInput,
  CustomTable,
} from "global/components";
import {
  CampaignUserData,
  GlobalInterval,
  HistoryFiltersInterface,
  SortingConfig,
} from "models/interfaces";
import {
  getCampaignHistoryByDate,
  getCampaignHistoryCountByDate,
  getCampaignHistoryCountSearchByDates,
  getCampaignHistorySearchByDates,
} from "screens/CampaignHistory/CampaignHistoryServices";
import {
  convertESTtoUserLocalDateAndTime,
  debounceEventHandler,
  handleIntervalChange,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import {
  emailOptions,
  campaignerHeader,
  campaignStatusOption,
  initialHistoryFilterState,
} from "screens/CampaignHistory/CampaignData";
import strings from "global/constants/StringConstants";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { darkPurpledColor, theme } from "utils/styles";
import notifiers from "global/constants/NotificationConstants";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { useTitle } from "utils/UseTitle";
import { selectIsManager } from "redux/authSlice";
import { useAppSelector } from "utils/hooks";
import {
  selectCampaignRetentionDuration,
  selectInterval,
} from "redux/persistSlice";
import { useDispatch } from "react-redux";
import { getOptions } from "screens/Dashboard/DashboardData";
import CustomDatePicker from "global/components/CustomDatePicker/CustomDatePicker";

const CampaignHistory = () => {
  useTitle(strings.CampaignHistoryTitle);
  const classes = CampaignHistoryStyles;
  const interval = useAppSelector(selectInterval);
  const [userDataSource, setUserDataSource] = useState<CampaignUserData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [sortConfig, setSortConfig] = useState<SortingConfig[]>([]);
  const isManager = useAppSelector(selectIsManager);
  const [historyFilters, setHistoryFilters] = useState<HistoryFiltersInterface>(
    initialHistoryFilterState(interval)
  );
  const campaignRetention = useAppSelector(selectCampaignRetentionDuration);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  useEffect(() => {
    mainDataFetchingFunction();
  }, [historyFilters, sortConfig]);

  const mainDataFetchingFunction = () => {
    if (isTruthy(historyFilters.searchText)) {
      searchTableData();
    } else {
      campaignHistoryDetailTable();
    }
  };

  const setIntervalInHistoryFilter = (newInterval: GlobalInterval) => {
    setHistoryFilters({
      ...historyFilters,
      interval: { ...newInterval },
      page: 1,
    });
  };

  const onHandleEmailOptionChange = (event: SelectChangeEvent<string>) => {
    setHistoryFilters({
      ...historyFilters,
      emailStatusFilter: event.target.value as string,
      page: 1,
    });
  };

  const handlePerPageData = (event: any) => {
    setHistoryFilters({
      ...historyFilters,
      perPageData: event.target.value,
      page: 1,
    });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setHistoryFilters({
      ...historyFilters,
      page: newPage,
    });
  };

  const tableDataShowHandler = (data: string[]) => {
    const tableData = data.map((item: any, index: any) => {
      const flag =
        "https://cdn.ipwhois.io/flags/" +
        item?.actionDetails?.country?.toLowerCase() +
        ".svg";
      return {
        campaignName: (
          <Typography sx={{ wordBreak: "break-all", fontSize: "14px" }}>
            {item.campaignName}
          </Typography>
        ),
        contactEmail: item.contactEmail,
        campaignOwner: (
          <Typography sx={classes.rowColor}>{item.campaignOwner}</Typography>
        ),
        location: (
          <Box display="flex" alignItems="center">
            {isTruthy(item?.actionDetails?.country) ? (
              <>
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                    whiteSpace: "nowrap",
                    mr: 1,
                  }}
                  alt="flag"
                  src={flag}
                />
                <Typography>
                  {item?.actionDetails?.city} - {item?.actionDetails?.country}
                </Typography>
              </>
            ) : (
              "No data Available"
            )}
          </Box>
        ),
        actionTimeLines: convertESTtoUserLocalDateAndTime(
          item.actionDetails.actionTimeLines[0],
          "MMM DD, YYYY"
        ),
      };
    });
    setUserDataSource(tableData);
  };

  const searchTableData = async () => {
    try {
      const {
        page,
        perPageData,
        searchText,
        interval,
        emailStatusFilter,
        campaignerOwnerFilter,
      } = historyFilters;
      setIsLoading(true);

      const [count, searchData] = await Promise.all([
        getCampaignHistoryCountSearchByDates(
          emailStatusFilter,
          interval,
          searchText,
          campaignerOwnerFilter
        ),
        getCampaignHistorySearchByDates(
          emailStatusFilter,
          interval,
          searchText,
          page,
          campaignerOwnerFilter,
          perPageData,
          sortConfig
        ),
      ]);
      setCount(count);
      tableDataShowHandler(searchData);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchOnChange = async (
    SearchEvent: ChangeEvent<HTMLInputElement>
  ) => {
    if (isTruthy(SearchEvent.target.value)) {
      setHistoryFilters({
        ...historyFilters,
        searchText: SearchEvent.target.value.trim(),
        page: 1,
      });
    } else {
      setHistoryFilters({
        ...historyFilters,
        searchText: "",
      });
    }
  };

  const campaignHistoryDetailTable = async () => {
    try {
      const {
        page,
        perPageData,
        interval,
        emailStatusFilter,
        campaignerOwnerFilter,
      } = historyFilters;
      setIsLoading(true);

      const [count, groups] = await Promise.all([
        getCampaignHistoryCountByDate(
          emailStatusFilter,
          interval,
          campaignerOwnerFilter
        ),
        getCampaignHistoryByDate(
          emailStatusFilter,
          interval,
          page,
          campaignerOwnerFilter,
          perPageData,
          sortConfig
        ),
      ]);
      setCount(count);
      tableDataShowHandler(groups);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCampaignsDropdown = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setHistoryFilters({
      ...historyFilters,
      campaignerOwnerFilter: event.target.value,
      page: 1,
    });
  };

  const getInput = () => {
    return (
      <Box sx={classes.searchInputWrapper}>
        {isManager && (
          <FormControl>
            <Select
              id="campaign_history_category_dropdown"
              sx={classes.dropDownStyle}
              value={historyFilters.campaignerOwnerFilter}
              onChange={(e: any) => handleCampaignsDropdown(e)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {campaignStatusOption.map((data) => (
                <MenuItem
                  key={data.id}
                  value={data.value}
                  sx={classes.optionStyle}
                >
                  {data.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <CustomInput
          id="campaign_history_search_field"
          placeHolder="Search text"
          sx={classes.searchInput}
          onChange={debounceEventHandler(
            handleSearchOnChange,
            strings.SEARCH_TIME_OUT
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };

  const getDropdown = () => {
    return (
      <Box sx={classes.inputsSectionDropdowns}>
        <Box sx={classes.refreshBtnAndDateRangeBox}>
          <Box sx={classes.dateBox}>
            <Typography sx={classes.date}>
              {moment(interval.fromDate).format("LL")} to{" "}
              {moment(interval.toDate).format("LL")}
            </Typography>
          </Box>
          <Box>
            <CustomButton
              id="campaign_history_refresh_button"
              onClick={() => mainDataFetchingFunction()}
              label={<CachedIcon htmlColor={darkPurpledColor} />}
              customClasses={classes.refreshBtn}
            />
          </Box>
        </Box>
        <Box sx={classes.dropdownAndInputBox}>
          <Box>
            <FormControl>
              <Select
                id="campaign_history_interval_dropdown"
                sx={{
                  ...classes.inputWrapperAddClasses,
                  ...classes.dropDownStyle,
                  ...classes.dateRangePickerSelect,
                }}
                value={interval.label}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {getOptions(+campaignRetention)?.map((data) => (
                  <MenuItem
                    value={data.label}
                    sx={classes.optionStyle}
                    onClick={() =>
                      handleIntervalChange(
                        data.label,
                        setIsDatePickerOpen,
                        setIntervalInHistoryFilter
                      )
                    }
                  >
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl>
              <Select
                id="campaign_history_options_dropdown"
                sx={{
                  ...classes.inputWrapperAddClasses,
                  ...classes.dropDownStyle,
                }}
                value={historyFilters.emailStatusFilter}
                onChange={(e) => onHandleEmailOptionChange(e)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {emailOptions.map((element) => (
                  <MenuItem
                    key={element.id}
                    value={element.value}
                    sx={classes.optionStyle}
                  >
                    {element.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    );
  };

  const InputsSection = () => {
    return (
      <Stack
        direction={{
          xl: "row",
          lg: "column",
          md: "column",
          sm: "column",
          xs: "column",
        }}
        justifyContent="space-between"
      >
        {getDropdown()}
        {getInput()}
      </Stack>
    );
  };

  const getCustomTable = () => {
    return (
      <Box
        id="campaign_history_display_table"
        sx={{
          minWidth: "300px",
          width: "100%",
          overflow: "auto",
        }}
      >
        <CustomTable
          headers={campaignerHeader}
          rows={userDataSource}
          size={[5]}
          handlePageChange={handleChangePage}
          paginationCount={count}
          pageNumber={historyFilters.page}
          isLoading={isLoading}
          handlePerPageData={handlePerPageData}
          perPageData={historyFilters.perPageData}
          rowsPerPage={historyFilters.perPageData}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
          sortingEntity={"ContactResponse"}
        />
      </Box>
    );
  };

  const getHeader = () => {
    return (
      <Box>
        <Typography sx={classes.mainCardHeading}>History</Typography>
      </Box>
    );
  };

  return (
    <>
      <Box>
        <CustomAppHeader className={classes.headerBackgroundColor}>
          <Grid container xs={12} md={12} lg={12} xl={12} alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
              {getHeader()}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              display="flex"
              justifyContent={{
                xs: "start",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-start",
                xl: "flex-end",
              }}
            >
              {InputsSection()}
            </Grid>
          </Grid>
        </CustomAppHeader>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={classes.mainSection}
        >
          {getCustomTable()}
        </Grid>
      </Box>
      {isDatePickerOpen && (
        <CustomDatePicker
          pastMonths={+campaignRetention}
          isDatePickerOpen={isDatePickerOpen}
          setIsDatePickerOpen={setIsDatePickerOpen}
          callBackFunction={setIntervalInHistoryFilter}
        />
      )}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default CampaignHistory;
