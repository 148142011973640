import urls from "global/constants/UrlConstants";
import { GlobalInterval } from "models/interfaces";
import {
  getCallParams,
  makeCall,
  getSearchedCallParams,
  getSortingSearchedCallParams,
} from "utils/service";

export const getCampaignHistoryCountByDate = async (
  status: string,
  interval: GlobalInterval,
  campaignHistoryFilter: string
) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.campaignHistoryEmailEvent}/${campaignHistoryFilter}/${status}/count/dates/${interval.fromDate}/${interval.toDate}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignHistoryByDate = async (
  status: string,
  interval: GlobalInterval,
  pageNumber: number,
  campaignHistoryFilter: string,
  limit: number,
  sortingParams: any
) => {
  try {
    const callParams = await getCallParams("POST", sortingParams);
    const response: any = await makeCall(
      `${urls.campaignHistoryEmailEvent}/${campaignHistoryFilter}/${status}/dates/${interval.fromDate}/${interval.toDate}/page/${pageNumber}/limit/${limit}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignHistoryCountSearchByDates = async (
  status: string,
  interval: GlobalInterval,
  searchText: string,
  campaignHistoryFilter: string
) => {
  try {
    const callParams = await getSearchedCallParams("POST", searchText);
    const response: any = await makeCall(
      `${urls.campaignHistoryEmailEvent}/${campaignHistoryFilter}/${status}/count/dates/${interval.fromDate}/${interval.toDate}/search`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignHistorySearchByDates = async (
  status: string,
  interval: GlobalInterval,
  searchText: string,
  pageNumber: any,
  campaignHistoryFilter: string,
  limit: number,
  entitySorters?: any
) => {
  try {
    const body = { entitySorters, searchText };
    const callParams = await getSortingSearchedCallParams("POST", body);
    const response: any = await makeCall(
      `${urls.campaignHistoryEmailEvent}/${campaignHistoryFilter}/${status}/dates/${interval.fromDate}/${interval.toDate}/search/page/${pageNumber}/limit/${limit}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
