import { Box } from "@mui/material";
import CustomDialog from "global/components/CustomDialog/CustomDialog";
import DeleteImg from "assets/images/DeleteImg.svg";
import BillingUsageStyles from "screens/Settings/BillingUsage/BillingUsage.styles";
import { CustomButton } from "global/components";
import strings from "global/constants/StringConstants";

interface CustomProps {
  isDomainsModalOpen: boolean;
  setIsDomainsModalOpen: Function;
  setTabValue: Function;
}

const DomainsRedirectionModal = (props: CustomProps) => {
  const classes = BillingUsageStyles;
  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={DeleteImg} alt="delete Image" />
      </Box>
    );
  };

  const dialogTitleContent = () => {
    return (
      <>
        <Box sx={classes.dialogTitleWrapper}>
          <Box sx={classes.titleRight}>
            Prioritize obtaining your custom domain
          </Box>
        </Box>
      </>
    );
  };

  const dialogBody = () => (
    <Box sx={classes.dialogContent} style={{ textAlign: "center" }}>
      To upgrade from your trial plan to a paid plan, obtaining your custom
      domain is a prerequisite!
    </Box>
  );

  const dialogContent = () => {
    return (
      <>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            label="Create"
            onClick={() => {
              props.setIsDomainsModalOpen(false);
              props.setTabValue(strings.DOMAINS);
            }}
            id="create_domains_redirection_button"
          />
        </Box>
      </>
    );
  };

  return (
    <CustomDialog
      dialogHeaderContent={dialogHeaderContent()}
      isDialogOpen={props.isDomainsModalOpen}
      handleDialogClose={() => {
        props.setIsDomainsModalOpen(false);
      }}
      dialogTitleContent={dialogTitleContent()}
      dialogBodyContent={dialogBody()}
      dialogFooterContent={dialogContent()}
      width="550px"
    />
  );
};

export default DomainsRedirectionModal;
