import { useState, useEffect } from "react";
import { CustomButton, CustomDialog, CustomInput } from "global/components";
import {
  Box,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import scheduleTimeImage from "assets/images/schedule.svg";
import socialStyles from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/Social.styles";
import moment from "moment";
import { getRelativeFontSize, boldFont } from "utils/styles";
import { isTruthy } from "helpers/methods";

interface CustomProps {
  isScheduleTimeModalOpen: boolean;
  setIsScheduleTimeModalOpen: Function;
  postData: any;
  setPostData: Function;
  postFeedData: Function;
}

const ScheduleTimeModal = (props: CustomProps) => {
  const classes = socialStyles;

  const radioButtonOptions = [
    { value: "now", label: "Post it right away" },
    { value: "future", label: "Schedule for future" },
  ];

  const [selectedValue, setSelectedValue] = useState<string>(
    radioButtonOptions[0].value
  );

  useEffect(() => {
    if (moment(props.postData?.scheduleTime?.value).isBefore(moment())) {
      setEmptyScheduleTime();
      setSelectedValue(radioButtonOptions[0].value);
    }
  }, [props.isScheduleTimeModalOpen]);

  const handleChange = (event: any) => {
    const checkBoxValue = event.target.value;
    if (checkBoxValue === radioButtonOptions[0].value) {
      setEmptyScheduleTime();
    }
    setSelectedValue(checkBoxValue);
  };

  const setEmptyScheduleTime = () => {
    props.setPostData({
      ...props.postData,
      scheduleTime: {
        value: "",
        error: "",
      },
    });
  };

  const handleScheduleTimeChange = (event: any) => {
    let error = "";
    if (
      isTruthy(event.target.value) &&
      moment(event.target.value).isBefore(moment())
    ) {
      error = "Scheduled time cannot be before today's date or time";
    }
    props.setPostData({
      ...props.postData,
      scheduleTime: {
        value: event.target.value,
        error: error,
      },
    });
  };

  const finalButtonValidation = () => {
    let isValid = true;
    if (
      !props.postData?.scheduleTime?.value &&
      selectedValue === radioButtonOptions[1].value
    ) {
      props.setPostData({
        ...props.postData,
        scheduleTime: {
          value: "",
          error: "Please enter schedule time",
        },
      });
      isValid = false;
    }
    return isValid;
  };

  const getHeader = () => {
    return (
      <Box display={"flex"}>
        <img src={scheduleTimeImage} alt="image not found!" />
      </Box>
    );
  };

  const getTitle = () => {
    return (
      <>
        <Typography
          sx={{
            ...boldFont,
            fontSize: getRelativeFontSize(10),
            textAlign: "center",
          }}
        >
          What should be the posting time?
        </Typography>
        <Box sx={classes.scheduleModalTitle}>
          <FormControl>
            <RadioGroup value={selectedValue} onChange={handleChange}>
              {radioButtonOptions.map((option: any) => {
                return (
                  <FormControlLabel
                    value={option.value}
                    label={option.label}
                    control={<Radio sx={classes.radioChecked} />}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
      </>
    );
  };

  const getBody = () => {
    return (
      <CustomInput
        disabled={selectedValue === "future" ? false : true}
        type="datetime-local"
        id="social_design_schedule_time_field"
        name="scheduleTime"
        propsToInputElement={{
          min: moment().format("YYYY-MM-DDTkk:mm"),
        }}
        value={props.postData?.scheduleTime?.value}
        onChange={handleScheduleTimeChange}
        error={props.postData?.scheduleTime?.error}
      />
    );
  };

  const getFooter = () => {
    return (
      <Grid container sx={classes.dialogFooterWrapper}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            id="step2_schedule_cancel_button"
            label="Cancel"
            onClick={() => props.setIsScheduleTimeModalOpen(false)}
            customClasses={classes.cancelButtonStyle}
          />
          <CustomButton
            id="step2_schedule_submit_button"
            label={
              selectedValue === radioButtonOptions[1].value
                ? "Schedule Campaign"
                : "Send Campaign"
            }
            onClick={() => {
              if (finalButtonValidation()) {
                props.postFeedData();
              }
            }}
            disabled={props.postData?.scheduleTime?.error}
            customClasses={classes.scheduleBtn}
          />
        </Box>
      </Grid>
    );
  };

  return (
    <CustomDialog
      isDialogOpen={props.isScheduleTimeModalOpen}
      closable
      closeButtonVisibility
      handleDialogClose={() => props.setIsScheduleTimeModalOpen(false)}
      dialogHeaderContent={getHeader()}
      dialogTitleContent={getTitle()}
      dialogBodyContent={getBody()}
      dialogFooterContent={getFooter()}
      width={"500px"}
    />
  );
};

export default ScheduleTimeModal;
