import strings from "global/constants/StringConstants";
import { hasAccessTo, isAdmin } from "utils/AuthorizationManager";

export interface UserProfile {
  account: string;
  app: string;
  email: string;
  name: string;
  contactNo: string;
  roleIds: number[];
  role: string;
  pwd: string;
  lastLogin: string;
  deviceIds: any[];
  status: string;
  notifyActivation: boolean;
  tokenExpiryTime: any;
  trial: boolean;
  bioMetricLogin: boolean;
  multiFactorLogin: boolean;
  mfaSetupRequired: boolean;
}

export const tabConfig = () => [
  {
    label: strings.profile,
    count: 0,
  },
  ...(hasAccessTo(strings.billing, strings.fetchPermission)
    ? [
        {
          label: strings.billingUsage,
          count: 0,
        },
      ]
    : []),
  ...(isAdmin()
    ? [
        {
          label: strings.roleManagement,
          count: 0,
        },
      ]
    : []),
  ...(isAdmin()
    ? [
        {
          label: strings.USERS,
          count: 0,
        },
      ]
    : []),
  ...(isAdmin()
    ? [
        {
          label: strings.DOMAINS,
          count: 0,
        },
      ]
    : []),
  {
    label: strings.SECURITY,
    count: 0,
  },
];

export const getTabLabelFromUrl = (tabUrl: any) => {
  switch (tabUrl) {
    case "profile":
      return strings.profile;
    case "billing-usage":
      return strings.billingUsage;
    case "role-management":
      return strings.roleManagement;
    case "user":
      return strings.USERS;
    case "security":
      return strings.SECURITY;
    default:
      return "";
  }
};

export const getUrlFromTabLabel = (tabLabel: any) => {
  switch (tabLabel) {
    case strings.profile:
      return "profile";
    case strings.billingUsage:
      return "billing-usage";
    case strings.roleManagement:
      return "role-management";
    case strings.USERS:
      return "user";
    case "security":
      return strings.SECURITY;
    default:
      return "";
  }
};
