import moment from "moment";
import strings from "global/constants/StringConstants";
import { isTruthy } from "helpers/methods";
import { store } from "utils/store";

export const campaignNumberArray = [2, 3, 4];

export const commonCampaignDataInitialState = () => {
  return {
    name: {
      value: "",
      error: "",
    },
    fromId: {
      value: store.getState()?.auth?.email,
      error: "",
    },
    displayName: {
      value: store.getState().auth?.userName,
      error: "",
    },
    domain: {
      value: "",
      error: "",
    },
    target: strings.groupMailingListType,
    groups: [{ type: strings.groupMailingListType, value: [] }],
    owner: store.getState().auth.email,
  };
};

export const commonCampaignDataMapper = (
  index: number,
  commonCampaignData: any
) => {
  const { name, fromId, displayName, domain } = commonCampaignData;
  return {
    ...commonCampaignData,
    ["name"]: {
      ...name,
      value: index !== 0 ? `${name.value}_${index}` : name.value,
    },
    ["fromId"]: fromId,
    ["displayName"]: displayName,
    ["domain"]: domain,
  };
};

export const dynamicCampaignDataMapper = (data: any = undefined) => {
  return {
    id: null,
    subject: {
      value: data?.subject?.value ?? "",
      error: "",
    },
    scheduleTime: {
      value: data?.scheduleTime?.value ?? "",
      error: "",
    },
    data: {
      spamPercentage: data?.data?.spamPercentage ?? 0,
    },
    content: data?.content ?? "",
    attachments: data?.attachments ?? {},
  };
};

export const mapFormDataToValues = (
  formData: any,
  templateHtmlContent: string
) => {
  return {
    ...formData,
    name: formData.name.value,
    fromId: formData.fromId.value,
    displayName: formData.displayName.value,
    subject: formData.subject.value,
    domain: formData.domain.value,
    scheduleTime: moment(formData.scheduleTime.value)
      .tz("America/New_York")
      .format("MM/DD/yyyy HH:mm:ss"),
    status: "Submitted",
    content: templateHtmlContent,
    data: {
      ...formData.data,
      domainName: isTruthy(formData.domain.value)
        ? formData.domain.value
        : undefined,
    },
  };
};

export const step1Validation = (
  commonCampaignData: any,
  emailUsers: string[],
  isDomainAvailable: boolean
) => {
  let isValid = true;
  let errors = { ...commonCampaignData };

  if (!commonCampaignData?.name?.value) {
    isValid = false;
    errors.name.error = "Please enter the Campaign Name";
  }
  if (!commonCampaignData?.displayName?.value) {
    isValid = false;
    errors.displayName.error = "Please enter the Display name";
  }
  if (
    !emailUsers.includes(commonCampaignData.fromId.value) ||
    !isTruthy(commonCampaignData.fromId.value)
  ) {
    isValid = false;
    errors.fromId.error = "Please select the from id from dropdown";
  }
  if (isDomainAvailable && !commonCampaignData?.domain?.value) {
    isValid = false;
    errors.domain.error = "Please select the domain from dropdown";
  }
  return { isValid, errors };
};

const setFieldError = (
  falsyCondition: boolean,
  index: number,
  field: string,
  errorMessage: string,
  isValid: boolean[],
  errorLadenArray: any[]
) => {
  if (falsyCondition) {
    const objectToBeUpdated = { ...errorLadenArray[index] };
    const updatedObject = {
      ...objectToBeUpdated,
      [field]: {
        ...objectToBeUpdated[field],
        error: errorMessage,
      },
    };
    errorLadenArray.splice(index, 1, updatedObject);
    isValid.splice(index, 1, false);
  } else {
    isValid.splice(index, 1, true);
  }
  return isValid[index];
};

export const step2Validation = (
  mainCampaignDataArray: any[],
  isValidArray: boolean[]
) => {
  let isValid: boolean[] = [...isValidArray];
  let errorLadenArray: any[] = [...mainCampaignDataArray];

  for (let i = 0; i < mainCampaignDataArray.length; i++) {
    let errorState: boolean[] = [];
    errorState.push(
      setFieldError(
        !mainCampaignDataArray[i].subject.value,
        i,
        "subject",
        "Please enter subject line",
        isValid,
        errorLadenArray
      )
    );
    errorState.push(
      setFieldError(
        !mainCampaignDataArray[i].scheduleTime.value,
        i,
        "scheduleTime",
        "Please enter schedule time",
        isValid,
        errorLadenArray
      )
    );
    isValid[i] = errorState.every((error) => error);
  }
  return { isValid, errorLadenArray };
};

export const step3Validation = (subjectLineData: any) => {
  let isValid = true;
  let errors = { ...subjectLineData };

  if (!subjectLineData.value) {
    isValid = false;
    errors.error = "Please enter subject line";
  }
  return { isValid, errors };
};

export const campaignerGroupHeader = [
  {
    name: "Name",
    field: "name",
    sorting: true,
  },
  {
    name: "Group type",
    field: "groupType",
    sorting: true,
  },
  {
    name: "Owner",
    field: "owner",
    sorting: true,
  },
  {
    name: "Members",
    field: "recipientCount",
    sorting: true,
  },
];

export const campaignGroupOptionAdmin = [
  {
    id: 0,
    label: "All Groups",
    value: "all",
  },
  {
    id: 2,
    label: "My Groups",
    value: "my",
  },
  {
    id: 3,
    label: "Team's Group",
    value: "team",
  },
];

export const campaignGroupOptionUser = [
  {
    id: 0,
    label: "All Groups",
    value: "all",
  },
  {
    id: 2,
    label: "My Groups",
    value: "my",
  },
];
