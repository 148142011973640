import {
  boldFont,
  getRelativeFontSize,
  mediumFont,
  theme,
  textLightColor,
  buttonWhiteBg,
  purplePrimaryColor,
} from "utils/styles";

const SideBarRouterStyle = {
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
  },

  titleStyle: {
    ...boldFont,
    fontSize: getRelativeFontSize(14),
  },

  buttonStyle: {
    marginLeft: "8px",
  },

  contentWrapper: {
    display: "flex",
    justifyContent: "center",
  },

  contentStyle: {
    fontSize: getRelativeFontSize(4),
    ...mediumFont,
    color: textLightColor,
  },

  footerWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    padding: "20px 0",
    "& button": {
      whiteSpace: "noWrap",
    },
  },

  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },

  buttonGrayBg: {
    background: buttonWhiteBg,
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },

  buttonGroupStyle: {
    [theme.breakpoints.down("sm")]: {
      minWidth: " 200px",
    },
  },
} as const;

export default SideBarRouterStyle;
