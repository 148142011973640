import React, { useEffect, useState } from "react";
import { CustomButton, CustomDialog, CustomInput } from "global/components";
import { Box, Typography, Grid, Chip } from "@mui/material";
import userUpdateImg from "assets/images/userUpdateImg.svg";
import { ViewContactStyles } from "screens/Contact/ViewContact.styles";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import { addTag, deleteById } from "screens/Contact/ViewContactService";
import _ from "lodash";
import notifiers from "global/constants/NotificationConstants";
import strings from "global/constants/StringConstants";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { recipient } from "models/interfaces";
import deepClone from "lodash/cloneDeep";

interface CustomProps {
  updateUserDialogOpen: boolean;
  handleUpdateDialogClose: Function;
  selectedRowData: any;
  tableData?: Function;
  setIsLoading?: any;
}

const UpdateContactModal = (props: CustomProps) => {
  const classes = ViewContactStyles;
  const [addTagsValue, setTagsValue] = useState<{
    value: string;
    error: string;
  }>({
    value: "",
    error: "",
  });
  const [finalTags, setFinalTags] = useState<{ id: string; name: string }[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [recipientsData, setRecipientData] = useState<recipient>();

  useEffect(() => {
    setFinalTags(props.selectedRowData.tags);
    setRecipientData(props.selectedRowData);
    setTagsValue({ value: "", error: "" });
  }, [props.updateUserDialogOpen]);

  const removeTagsNameHandler = (index: number) => {
    setFinalTags(finalTags.filter((v: any, i: number) => i !== index));
  };

  const validateTags = () => {
    let isValid = true;
    const clonedAddTagsValue = deepClone(addTagsValue);
    const checkExistingValue = finalTags?.some(
      (item: any) => item?.name === (addTagsValue?.value).toLowerCase().trim()
    );
    if (!isTruthy(addTagsValue?.value)) {
      clonedAddTagsValue.error = "Tag cant be empty";
      isValid = false;
    }
    if (checkExistingValue) {
      clonedAddTagsValue.error = "You cannot add duplicate tags";
      isValid = false;
    }
    setTagsValue({ ...clonedAddTagsValue });
    return isValid;
  };

  const addTagsHandler = () => {
    if (validateTags()) {
      const addedTags: any[] = [];
      addedTags.push(...finalTags, {
        id: "NEW",
        name: addTagsValue?.value?.toLowerCase().trim(),
      });
      const filterValue = _.uniqWith(addedTags, _.isEqual);
      setFinalTags(filterValue);
      setTagsValue({ value: "", error: "" });
    }
  };

  const deleteTagsHandler = async (
    emailId: any,
    tagsId: any,
    index: number
  ) => {
    try {
      setIsLoading(true);
      if (tagsId !== "NEW") {
        const res = await deleteById(emailId, tagsId);
        openSuccessNotification(res.message);
        props.tableData && props.tableData();
      }
      removeTagsNameHandler(index);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const updateTagHandler = async () => {
    try {
      props.setIsLoading && props.setIsLoading(true);
      const res = await addTag(
        recipientsData?.emailId!,
        finalTags.map((data) => data.name)
      );
      openSuccessNotification(res.message);
      await props.tableData?.();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading && props.setIsLoading(false);
      props.handleUpdateDialogClose && props.handleUpdateDialogClose();
    }
  };

  const closeDialog = () => {
    props.handleUpdateDialogClose && props.handleUpdateDialogClose();
    setTagsValue({
      value: "",
      error: "",
    });
  };

  const modalHeader = () => {
    return (
      <Box display="flex">
        <img src={userUpdateImg} alt="Update user image not found!" />
      </Box>
    );
  };

  const updateUserDialogTitle = () => {
    return (
      <Box>
        <Typography sx={classes.boldFonts}>Update Tags</Typography>
      </Box>
    );
  };

  const updateUserDialogBody = () => {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
            <CustomInput
              id="contact_update_tag_field"
              name="addTag"
              propsToInputElement={{
                maxLength: strings.TAGS_LIMIT,
              }}
              error={addTagsValue.error}
              placeHolder="Enter Tag Name"
              onChange={(event: any) =>
                setTagsValue({ value: event.target.value, error: "" })
              }
              value={addTagsValue.value}
              sx={{ background: "red" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <CustomButton
              id="contact_update_add_tag_button"
              label="Add Tag"
              onClick={() => addTagsHandler()}
              customClasses={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        {finalTags?.map((item: any, index: any) => (
          <Chip
            key={index}
            label={item?.name}
            sx={classes.addTagChip}
            variant="filled"
            onDelete={() =>
              deleteTagsHandler(recipientsData?.emailId, item?.id, index)
            }
          />
        ))}
      </Box>
    );
  };

  const updateUserDialogFooter = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            id="contact_update_cancel_button"
            label="Cancel"
            onClick={() => closeDialog()}
            customClasses={classes.cancelButtonStyle}
          />
          <CustomButton
            id="contact_update_submit_button"
            label="Update"
            onClick={() => updateTagHandler()}
            disabled={
              _.isEqual(props.selectedRowData.tags, finalTags)
                ? true
                : false || finalTags?.length < 1
            }
          />
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <CustomDialog
        isDialogOpen={props.updateUserDialogOpen}
        closable
        closeButtonVisibility
        handleDialogClose={props.handleUpdateDialogClose}
        dialogHeaderContent={modalHeader()}
        dialogTitleContent={updateUserDialogTitle()}
        dialogBodyContent={updateUserDialogBody()}
        dialogFooterContent={updateUserDialogFooter()}
        width="450px"
      />
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default UpdateContactModal;
