import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  CustomAppHeader,
  CustomButton,
  CustomDialog,
  CustomInput,
} from "global/components";
import EmailEditor, { Design } from "react-email-editor";
import StepThreeStyles from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepThree/StepThree.styles";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { isTruthy, openErrorNotification } from "helpers/methods";
import Confirmation from "assets/images/Confirmation.svg";
import notifiers from "global/constants/NotificationConstants";
import {
  getAllTemplates,
  getSpamPrediction,
  subjectLineGeneration,
} from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/CreateSequentialCampaignsServices";
import SubjectLinesModal from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepThree/components/SubjectLinesModal";
import { SpamBox } from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepThree/components/SpamBox";
import { TemplatesModal } from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepThree/components/TemplatesModal";
import strings from "global/constants/StringConstants";
import urls from "global/constants/UrlConstants";

interface CustomProps {
  index: number;
  setActiveStep: Function;
  mainCampaignDataArray: any[];
  setMainCampaignDataArray: Function;
  templateHtmlArray: string[];
  setTemplateHtmlArray: Function;
  defaultTemplateFooter: Design | undefined;
}
const StepThree = (props: CustomProps) => {
  const classes = StepThreeStyles;
  const emailEditorRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [spamPredictionValue, setSpamPredictionValue] = useState<number>(
    props.mainCampaignDataArray[props.index].data.spamPercentage
  );
  const [color, setColor] = useState<string>("");
  const [spamText, setSpamText] = useState<string>("");
  const [topSpamKeywords, setTopSpamKeywords] = useState<any>({});
  const [spamLoading, setSpamLoading] = useState<boolean>(false);
  const [chooseTemplateOpen, setChooseTemplateOpen] = useState<boolean>(false);
  const [isSubjectModalOpen, setIsSubjectModalOpen] = useState<boolean>(false);
  const [subjectLine, setSubjectLine] = useState(
    props.mainCampaignDataArray[props.index].subject
  );
  const [subjectLines, setSubjectLines] = useState<string[]>([]);
  const [templates, setTemplates] = useState<any>([]);

  useEffect(() => {
    if (spamPredictionValue !== 0) {
      spanText(spamPredictionValue);
    }
  }, []);

  const getTemplates = async () => {
    try {
      setIsLoading(true);
      const response = await getAllTemplates();
      setTemplates(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const spanText = (spamPredictionValue: number) => {
    let color = "#FF0000";
    let spamText = "This email will not end up in the inbox!";

    if (spamPredictionValue >= 0 && spamPredictionValue <= 40) {
      color = "#228B22";
      spamText = "Woohoo! This email will end up in the inbox!";
    } else if (spamPredictionValue <= 60) {
      color = "#FFA500";
      spamText = "Uh-huh! This email might end up in the inbox!";
    }

    setColor(color);
    setSpamText(spamText);
  };

  const spamPredictionAPI = async () => {
    try {
      setSpamLoading(true);
      const data: any = await getEmailEditorContent();
      const response = await getSpamPrediction(data.html);
      setTopSpamKeywords(response.topSpamKeywords);
      spanText(response.spamPrediction);
      setSpamPredictionValue(response.spamPrediction);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setSpamLoading(false);
    }
  };

  const backStepHandler = () => {
    props.setActiveStep((prevStep: number) => prevStep - 1);
  };

  const onSubjectChangeHandler = (event: any) => {
    setSubjectLine({
      value: event.target.value,
      error: "",
    });
  };

  const handleSave = () => {
    dynamicCampaignDataSaver(backStepHandler);
  };

  const dynamicCampaignDataSaver = async (callback: any) => {
    const mainArray: any[] = [...props.mainCampaignDataArray];
    const htmlTemplateArray: string[] = [...props.templateHtmlArray];
    const objectToBeUpdated = { ...props.mainCampaignDataArray[props.index] };
    const templateContent: any = await getEmailEditorContent();

    const updatedObject = {
      ...objectToBeUpdated,
      ["subject"]: subjectLine,
      ["content"]: JSON.stringify(templateContent.design),
      ["data"]: { spamPercentage: spamPredictionValue },
    };

    mainArray.splice(props.index, 1, updatedObject);
    htmlTemplateArray.splice(props.index, 1, templateContent.html);
    props.setMainCampaignDataArray([...mainArray]);
    props.setTemplateHtmlArray([...htmlTemplateArray]);
    callback();
  };

  const getEmailEditorContent = () => {
    return new Promise((resolve, reject) => {
      emailEditorRef.current.editor?.exportHtml((data: any) => {
        if (data && data.html) {
          resolve(data);
        } else {
          reject(new Error("Export template design failed"));
        }
      });
    });
  };

  const loadDesign = () => {
    setDisabled(false);
    if (emailEditorRef.current) {
      if (props.mainCampaignDataArray[props.index].content) {
        emailEditorRef?.current?.editor?.loadDesign(
          JSON.parse(props.mainCampaignDataArray[props.index].content)
        );
      } else {
        // New Campaign with Default Mailzzy footer
        emailEditorRef?.current?.editor?.loadDesign(
          props.defaultTemplateFooter
        );
      }
    }
  };

  const handleExportError = (error: any) => {
    setIsLoading(false);
    const errorMessage = error.message || notifiers.GENERIC_ERROR;
    openErrorNotification(errorMessage);
  };

  const checkIfContentsNonEmpty = (body: any) => {
    return body?.rows?.some((row: any) =>
      row?.columns?.some((column: any) => column?.contents?.length > 0)
    );
  };

  const subjectLineGenerationHandler = async () => {
    try {
      setIsLoading(true);
      const data: any = await getEmailEditorContent();
      const {
        html,
        design: { body },
      } = data;

      const isEmpty = !checkIfContentsNonEmpty(body);

      if (!isEmpty) {
        if (subjectLines.length === 0 || isSubjectModalOpen) {
          const response = await subjectLineGeneration(html);
          setSubjectLines(response.subject);
        }
        setIsSubjectModalOpen(true);
      } else {
        openErrorNotification("Please choose a template or create one");
      }
    } catch (error) {
      handleExportError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const closePrompt = () => {
    setShowPrompt(false);
  };

  const getDialogHeaderContent = () => {
    return (
      <>
        <Box display={"flex"}>
          <img src={Confirmation} alt="" />
        </Box>
        <Box sx={{ position: "absolute", top: "15px", right: "15px" }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              closePrompt();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </>
    );
  };

  const getTitleContent = () => {
    return (
      <>
        <Box sx={classes.titleWrapper}>
          <Typography sx={classes.title}>Hold On!</Typography>
        </Box>
      </>
    );
  };

  const getFooterContent = () => {
    return (
      <Box sx={classes.footerWrapper}>
        <CustomButton
          label="Back"
          onClick={() => {
            backStepHandler();
          }}
          customClasses={classes.buttonWhiteBg}
          id="step_three_back_Button"
        />
        <CustomButton
          label="Save"
          onClick={() => {
            handleSave();
          }}
          loading={isLoading}
          customClasses={classes.buttonGrayBg}
          id="step_three_save_Button"
        />
        <CustomButton
          label="Continue"
          onClick={() => {
            setShowPrompt(false);
          }}
          id="step_three_continue_Button"
        />
      </Box>
    );
  };

  const getBodyContent = () => {
    return (
      <Box sx={classes.contentWrapper}>
        <Typography sx={classes.contentStyle}>
          You may lose your changes
        </Typography>
      </Box>
    );
  };

  const getHeaderButtons = () => (
    <Stack sx={classes.headerButtonStack}>
      <Box sx={classes.headerButtonStyle}>
        <CustomButton
          id="step_back_button"
          label="Back"
          onClick={() => {
            setShowPrompt(true);
          }}
          disabled={disabled}
          customClasses={classes.headerButton}
        />
      </Box>
      <Box sx={classes.headerButtonStyle}>
        <CustomButton
          id="step3_choose_template_button"
          label="Choose Template"
          onClick={() => {
            setChooseTemplateOpen(true);
          }}
          disabled={disabled}
          customClasses={classes.headerButton}
        />
      </Box>

      <Box sx={classes.headerButtonStyle}>
        <CustomButton
          id="step3_save_button"
          label="Save"
          disabled={disabled}
          onClick={() => {
            handleSave();
          }}
          customClasses={classes.headerButton}
        />
      </Box>
    </Stack>
  );

  const getSubjectLineSection = () => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
          <CustomInput
            required
            id="step3_subject_field"
            label="Subject Line"
            name="subject"
            value={subjectLine.value}
            onChange={onSubjectChangeHandler}
            placeHolder="Enter Subject Line"
            propsToInputElement={{ maxlength: 90 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span>{subjectLine.value.length} / 90</span>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
          <Box mt={{ lg: 4 }}>
            <CustomButton
              label="Generate Subject Line"
              onClick={() => {
                subjectLineGenerationHandler();
              }}
              disabled={disabled}
              customClasses={{ width: "200px" }}
              id="generate_subject_line_button"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const onChooseTemplate = (jsonContent: string) => {
    if (emailEditorRef.current && jsonContent) {
      emailEditorRef?.current?.editor?.loadDesign(JSON.parse(jsonContent));
    }
    setChooseTemplateOpen(false);
  };

  const getEmailEditor = () => {
    return (
      <Box style={{ minWidth: "100%", overflow: "auto", overflowY: "hidden" }}>
        <EmailEditor
          ref={emailEditorRef}
          onReady={loadDesign}
          projectId={strings.UNLAYER_PROJECT_ID}
          minHeight={800}
          options={{
            features: {
              textEditor: {
                tables: true,
              },
            },
            customJS: [urls.customSocialTool, urls.customVideoTool],
          }}
          style={{ display: "flex", flexWrap: "wrap" }}
        />
      </Box>
    );
  };

  const getHeaderSection = () => (
    <Box sx={{ padding: "20px", maxWidth: "600px" }}>
      {getSubjectLineSection()}
    </Box>
  );

  const getBodySection = () => (
    <Grid container mt={3}>
      <Grid item xs={12} lg={9.5}>
        {getEmailEditor()}
      </Grid>
      <Grid item xs={12} lg={2.5}>
        <SpamBox
          spamPredictionValue={spamPredictionValue}
          color={color}
          spamText={spamText}
          topSpamKeywords={topSpamKeywords}
          spamPredictionApi={spamPredictionAPI}
          spamLoading={spamLoading}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <CustomAppHeader className={classes.headerSection}>
        <Grid container sx={classes.headerGridContainer}>
          <Typography sx={classes.pageTitle}>Edit Template</Typography>
          {getHeaderButtons()}
        </Grid>
      </CustomAppHeader>
      {getHeaderSection()}
      {getBodySection()}
      <TemplatesModal
        setChooseTemplateOpen={setChooseTemplateOpen}
        chooseTemplateOpen={chooseTemplateOpen}
        templates={templates}
        onChooseTemplate={onChooseTemplate}
        getTemplates={getTemplates}
      />
      {isSubjectModalOpen && (
        <SubjectLinesModal
          isSubjectModalOpen={isSubjectModalOpen}
          setIsSubjectModalOpen={setIsSubjectModalOpen}
          subjectLines={subjectLines}
          setSubjectLine={setSubjectLine}
          subjectLineGenerationHandler={subjectLineGenerationHandler}
        />
      )}
      <CustomDialog
        dialogHeaderContent={getDialogHeaderContent()}
        isDialogOpen={showPrompt}
        dialogBodyContent={getBodyContent()}
        dialogTitleContent={getTitleContent()}
        dialogFooterContent={getFooterContent()}
        handleDialogClose={closePrompt}
        width="500px"
      />
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default StepThree;
