import { useState, useEffect } from "react";
import moment from "moment";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { CustomInput, NewCustomButton } from "global/components";
import CampaignDetailsSectionStyles from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/campaignDetailsSection/CampaignDetailsSection.styles";
import { isTruthy } from "helpers/methods";

interface CustomProps {
  index: number;
  mainCampaignDataArray: any[];
  setMainCampaignDataArray: Function;
  isValidArray: boolean[];
}

const ScheduleTimeSection = (props: CustomProps) => {
  const classes = CampaignDetailsSectionStyles;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [sendingTime, sendSendingTime] = useState<any>(
    props.mainCampaignDataArray[props.index].scheduleTime
  );

  useEffect(() => {
    sendSendingTime(props.mainCampaignDataArray[props.index].scheduleTime);
    if (
      isTruthy(props.mainCampaignDataArray[props.index].scheduleTime.error) &&
      !props.isValidArray[props.index]
    ) {
      setIsEditMode(true);
    }
  }, [props.mainCampaignDataArray[props.index].scheduleTime]);

  const onChangeHandler = (event: any) => {
    sendSendingTime({
      value: event.target.value,
      error: "",
    });
  };

  const sendingTimeValidationHandler = (scheduleTimeData: any) => {
    let isValid = true;
    let errors: any = scheduleTimeData;
    if (!isTruthy(scheduleTimeData.value)) {
      errors.error = "Please enter schedule time";
      isValid = false;
    }
    if (moment(sendingTime.value).isBefore(moment())) {
      errors.error = "Scheduled time cannot be before today's date or time";
      isValid = false;
    }
    return { errors, isValid };
  };

  const onCancelHandler = () => {
    sendSendingTime({
      value: props.mainCampaignDataArray[props.index].scheduleTime.value,
      error: "",
    });
    setIsEditMode(false);
  };

  const scheduleTimeSaver = () => {
    const mainArray: any[] = [...props.mainCampaignDataArray];
    const objectToBeUpdated = {
      ...props.mainCampaignDataArray[props.index],
    };
    const updatedObject = {
      ...objectToBeUpdated,
      ["scheduleTime"]: sendingTime,
    };
    mainArray.splice(props.index, 1, updatedObject);
    props.setMainCampaignDataArray([...mainArray]);
  };

  const onSaveHandler = () => {
    const { errors, isValid } = sendingTimeValidationHandler(sendingTime);
    sendSendingTime({ ...errors });
    if (isValid) {
      scheduleTimeSaver();
      setIsEditMode(false);
    }
  };

  const getSaveButton = () => {
    return (
      <NewCustomButton
        id="sending_time_save_button"
        label="Save"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onSaveHandler();
        }}
      />
    );
  };

  const getCancelButton = () => {
    return (
      <NewCustomButton
        id="sending_time_cancel_button"
        label="Cancel"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onCancelHandler();
        }}
      />
    );
  };

  const editModeView = () => {
    return (
      <>
        <Box mb={1}>
          <Typography sx={classes.headerLabel}>Sending Time</Typography>
          <Typography sx={classes.headerSubDescription}>
            When should this email be sent?
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10} xl={10} lg={10}>
            <CustomInput
              type="datetime-local"
              id="step2_sending_time_field"
              name="sendingTime"
              propsToInputElement={{
                min: moment().format("YYYY-MM-DDTkk:mm"),
              }}
              value={sendingTime.value}
              onChange={onChangeHandler}
              error={sendingTime.error}
            />
          </Grid>
        </Grid>
        <Stack flexDirection="row" gap={1} mt={1}>
          {getSaveButton()}
          {getCancelButton()}
        </Stack>
      </>
    );
  };

  const viewModeView = () => {
    return (
      <Box mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography sx={classes.headerLabel}>Sending Time</Typography>
          <Typography sx={classes.headerSubDescription}>
            {isTruthy(
              props.mainCampaignDataArray[props.index].scheduleTime.value
            )
              ? moment(
                  props.mainCampaignDataArray[props.index].scheduleTime.value
                ).format("MMMM Do YYYY, h:mm a")
              : ""}
          </Typography>
        </Box>
        <Box>
          <NewCustomButton
            id="sending_time_edit_button"
            label="Edit Sending Time"
            customClasses={{ width: "135px" }}
            onClick={() => setIsEditMode(true)}
          />
        </Box>
      </Box>
    );
  };

  return <Box>{isEditMode ? editModeView() : viewModeView()}</Box>;
};

export default ScheduleTimeSection;
