import urls from "global/constants/UrlConstants";
import strings from "global/constants/StringConstants";
import { store } from "utils/store";
import {
  getCampaignIcon,
  getCampaignListIcon,
  getContactIcon,
  getCreateCampaignsIcon,
  getCreateWhatsAppTemplateIcon,
  getDisplayWhatsAppTemplateIcon,
  getFailedStatsIcon,
  getGroupIcon,
  getHistoryIcon,
  getHomeIcon,
  getIntegrationIcon,
  getSettingsIcon,
  getSocialCampaignsIcon,
  getTemplateIcon,
  getTicketsIcon,
  getWhatsAppCampaignIcon,
} from "utils/SidebarSvgConstant";
import { pinkDarkColor, textLightColor } from "utils/styles";
import { isTruthy } from "helpers/methods";

export interface ListOfMenusType {
  icon?: string;
  activeIcon?: string;
  text?: string;
  key?: string;
  link?: string;
  pageName: string;
  subMenu?: [];
  visibleInSidebar?: boolean;
  accessWithoutAnyResource?: boolean;
  accessToResource: AccessToResource[];
}

export type AccessToResource = {
  resource: string;
  permissions: string[];
  allPermissionRequired: boolean;
};

const ListOfMenus = () =>
  [
    {
      icon: getHomeIcon(textLightColor),
      activeIcon: getHomeIcon(pinkDarkColor),
      text: "Home",
      link: urls.dashboardViewPath,
      pageName: strings.DASHBOARD,
      visibleInSidebar: true,
      accessToResource: [
        {
          resource: strings.campaign,
          permissions: [strings.fetchPermission],
          allPermissionRequired: false,
        },
        {
          resource: strings.contact,
          permissions: [strings.fetchPermission],
          allPermissionRequired: false,
        },
      ],
      subMenu: [],
    },
    {
      icon: getCampaignIcon(textLightColor),
      activeIcon: getCampaignIcon(pinkDarkColor),
      text: "Campaigns",
      pageName: strings.CAMPAIGNS,
      visibleInSidebar: true,
      accessWithoutAnyResource: true,
      accessToResource: [],
      subMenu: [
        {
          icon: getCreateCampaignsIcon(textLightColor),
          activeIcon: getCreateCampaignsIcon(pinkDarkColor),
          text: "Create Campaigns",
          visibleInSidebar: true,
          accessWithoutAnyResource: true,
          accessToResource: [],
          link: urls.createCampaignsViewPath,
          pageName: strings.CREATECAMPAIGNS,
        },
        {
          icon: getCampaignListIcon(textLightColor),
          activeIcon: getCampaignListIcon(pinkDarkColor),
          text: "Campaigns List",
          visibleInSidebar: true,
          link: urls.campaignsListViewPath,
          pageName: strings.CAMPAIGNSLIST,
          accessToResource: [
            {
              resource: strings.campaign,
              permissions: [strings.fetchPermission],
              allPermissionRequired: false,
            },
          ],
        },
        {
          icon: getSocialCampaignsIcon(textLightColor),
          activeIcon: getSocialCampaignsIcon(pinkDarkColor),
          visibleInSidebar: true,
          text: "Social Campaigns List",
          link: urls.socialCampaignsViewPath,
          pageName: strings.SOCIALCAMPAIGNS,
          accessToResource: [
            {
              resource: strings.social,
              permissions: [strings.fetchPermission],
              allPermissionRequired: false,
            },
          ],
        },
        {
          link: urls.createCampaignViewPath,
          pageName: strings.CREATECAMPAIGN,
          visibleInSidebar: false,
          accessToResource: [
            {
              resource: strings.campaign,
              permissions: [strings.addPermission],
              allPermissionRequired: false,
            },
          ],
        },
        {
          link: urls.sequencesViewPath,
          pageName: strings.SEQUENCES,
          visibleInSidebar: false,
          accessToResource: [
            {
              resource: strings.campaign,
              permissions: [strings.addPermission],
              allPermissionRequired: false,
            },
          ],
          subMenu: [],
        },
        {
          link: urls.socialViewPath,
          pageName: strings.SOCIAL,
          visibleInSidebar: false,
          accessToResource: [
            {
              resource: strings.social,
              permissions: [strings.fetchPermission, strings.addPermission],
              allPermissionRequired: false,
            },
          ],
        },
        {
          link: urls.campaignDetailsViewPath,
          pageName: strings.CAMPAIGNDETAILS,
          visibleInSidebar: false,
          accessToResource: [
            {
              resource: strings.campaign,
              permissions: [strings.fetchPermission],
              allPermissionRequired: false,
            },
          ],
        },
        {
          link: urls.retargettingViewPath,
          pageName: strings.RETARGEETTING,
          visibleInSidebar: false,
          accessToResource: [
            {
              resource: strings.campaign,
              permissions: [strings.addPermission],
              allPermissionRequired: false,
            },
          ],
        },
        {
          link: urls.resendCampaign,
          pageName: strings.RESENDCAMPAIGN,
          visibleInSidebar: false,
          accessToResource: [
            {
              resource: strings.campaign,
              permissions: [strings.addPermission],
              allPermissionRequired: false,
            },
          ],
        },
      ],
    },
    {
      icon: getGroupIcon(textLightColor),
      activeIcon: getGroupIcon(pinkDarkColor),
      text: "Groups",
      link: urls.campaignGroupsViewPath,
      pageName: strings.GROUPS,
      visibleInSidebar: true,
      accessToResource: [
        {
          resource: strings.contact,
          permissions: [strings.fetchPermission],
          allPermissionRequired: false,
        },
      ],
      subMenu: [],
    },
    {
      icon: getContactIcon(textLightColor),
      activeIcon: getContactIcon(pinkDarkColor),
      text: "Contacts",
      link: urls.viewContactViewPath,
      pageName: strings.CONTACTS,
      visibleInSidebar: true,
      accessToResource: [
        {
          resource: strings.contact,
          permissions: [strings.fetchPermission],
          allPermissionRequired: false,
        },
      ],
      subMenu: [],
    },
    ...(!urls.PROD
      ? [
          {
            icon: getCreateWhatsAppTemplateIcon(textLightColor),
            activeIcon: getCreateWhatsAppTemplateIcon(pinkDarkColor),
            text: "Create WhatsApp Template",
            link: urls.createWhatsAppTemplateViewPath,
            pageName: strings.CREATEWHATSAPPTEMPLATE,
            visibleInSidebar: true,
            accessToResource: [
              {
                resource: strings.app,
                permissions: [strings.adminPermission],
                allPermissionRequired: false,
              },
            ],
            subMenu: [],
          },
          {
            icon: getDisplayWhatsAppTemplateIcon(textLightColor),
            activeIcon: getDisplayWhatsAppTemplateIcon(pinkDarkColor),
            text: "WhatsApp Templates",
            link: urls.whatsAppTemplatesViewPath,
            pageName: strings.WHATSAPPTEMPLATES,
            visibleInSidebar: true,
            accessToResource: [
              {
                resource: strings.app,
                permissions: [strings.adminPermission],
                allPermissionRequired: false,
              },
            ],
            subMenu: [],
          },
          {
            icon: getWhatsAppCampaignIcon(textLightColor),
            activeIcon: getWhatsAppCampaignIcon(pinkDarkColor),
            text: "Create WhatsApp Campaigns",
            link: urls.createWhatsAppCampaignViewPath,
            pageName: strings.CREATEWHATSAPPCAMPAIGN,
            visibleInSidebar: true,
            accessToResource: [
              {
                resource: strings.app,
                permissions: [strings.adminPermission],
                allPermissionRequired: false,
              },
            ],
            subMenu: [],
          },
        ]
      : []),
    {
      icon: getHistoryIcon(textLightColor),
      activeIcon: getHistoryIcon(pinkDarkColor),
      text: "History",
      link: urls.campaignHistoryViewPath,
      pageName: strings.CAMPAIGNHISTORY,
      visibleInSidebar: true,
      accessToResource: [
        {
          resource: strings.contact,
          permissions: [strings.fetchPermission],
          allPermissionRequired: false,
        },
      ],
      subMenu: [],
    },
    {
      icon: getFailedStatsIcon("#666"),
      activeIcon: getFailedStatsIcon(pinkDarkColor),
      text: "Failed Stats",
      link: urls.failedStatsViewPath,
      pageName: strings.FAILEDSTATS,
      visibleInSidebar: true,
      accessToResource: [
        {
          resource: strings.contact,
          permissions: [strings.fetchPermission],
          allPermissionRequired: false,
        },
      ],
      subMenu: [],
    },
    {
      icon: getTemplateIcon(textLightColor),
      activeIcon: getTemplateIcon(pinkDarkColor),
      text: "Templates",
      link: urls.templateViewPath,
      pageName: strings.TEMPLATES,
      visibleInSidebar: true,
      accessWithoutAnyResource: true,
      accessToResource: [],
      subMenu: [],
    },
    {
      pageName: strings.TEMPLATESEDITOR,
      visibleInSidebar: false,
      accessWithoutAnyResource: true,
      accessToResource: [],
    },
    {
      icon: getIntegrationIcon(textLightColor),
      activeIcon: getIntegrationIcon(pinkDarkColor),
      text: "Integration",
      link: urls.integrationViewPath,
      pageName: strings.INTEGRATION,
      visibleInSidebar: true,
      accessToResource: [
        {
          resource: strings.social,
          permissions: [strings.fetchPermission],
          allPermissionRequired: false,
        },
      ],
      subMenu: [],
    },
    {
      icon: getTicketsIcon(textLightColor),
      activeIcon: getTicketsIcon(pinkDarkColor),
      text: "Tickets",
      link: urls.ticketsViewPath,
      pageName: strings.TICKETS,
      visibleInSidebar: true,
      accessWithoutAnyResource: true,
      accessToResource: [],
      subMenu: [],
    },
    {
      icon: getSettingsIcon(textLightColor),
      activeIcon: getSettingsIcon(pinkDarkColor),
      text: "Settings",
      link: `${urls.settingsViewPath}`,
      pageName: strings.SETTINGS,
      visibleInSidebar: true,
      accessWithoutAnyResource: true,
      accessToResource: [],
      subMenu: [],
    },
  ] as ListOfMenusType[];

const checkAccessToResource = (accessToResource: any) => {
  const resources = store.getState().auth.resources;
  const hasAccess = accessToResource?.some(
    (accessToResource: AccessToResource) => {
      if (!resources.hasOwnProperty(accessToResource.resource)) {
        return false;
      }

      const permissions = resources[accessToResource.resource];

      if (accessToResource.allPermissionRequired) {
        return accessToResource.permissions?.every((indPermission: string) =>
          permissions.includes(indPermission)
        );
      }

      return accessToResource.permissions?.some((indPermission: string) =>
        permissions.includes(indPermission)
      );
    }
  );
  return hasAccess;
};

export const GenerateMenu = (mainMenus: ListOfMenusType[] = ListOfMenus()) => {
  const generatedMenu: ListOfMenusType[] = [];

  mainMenus.forEach((menu: ListOfMenusType) => {
    if (menu.visibleInSidebar) {
      generatedMenu.push(menu);
    }
  });

  return generatedMenu;
};

export const doesUserHasAccessTo = (componentName: string) => {
  const findMenuItem = (
    menuArray: ListOfMenusType[] = ListOfMenus()
  ): ListOfMenusType | undefined => {
    return menuArray.reduce(
      (accumulator: ListOfMenusType | undefined, menuItem: ListOfMenusType) => {
        if (accumulator) {
          return accumulator;
        }
        if (menuItem.pageName === componentName) {
          return menuItem;
        }
        if (menuItem.subMenu) {
          return findMenuItem(menuItem.subMenu);
        }
      },
      undefined
    );
  };

  const doesComponentExist = findMenuItem();

  if (!doesComponentExist) {
    return false;
  }
  if (doesComponentExist.accessWithoutAnyResource) {
    return true;
  }

  const accessToResource = doesComponentExist.accessToResource;

  return isAdmin() || checkAccessToResource(accessToResource);
};

export const hasAccessTo = (resourceName: string, permission: string) => {
  const resources = store.getState().auth.resources;

  return (
    (resources.hasOwnProperty(strings.app) &&
      resources[strings.app].includes(strings.adminPermission)) ||
    (resources.hasOwnProperty(resourceName) &&
      (resources[resourceName].includes(permission)))
  );
};

export const isAdmin = () => {
  return hasAccessTo(strings.app, strings.adminPermission);
};
