import { KeyboardBackspace } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CampaignsStyles from "screens/CampaignsNew/Campaigns/Campaigns.styles";
import { useHistory, useLocation } from "react-router-dom";
import { getRetargetedCampaigns } from "screens/CampaignsNew/Campaigns/Campaign.services";
import moment from "moment";
import RetargetedTable from "screens/CampaignsNew/Campaigns/components/RetargetedTable";
import { isTruthy, openErrorNotification } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import CustomLoader from "global/components/CustomLoader/CustomLoader";

const RetargettingCampaignRequest = () => {
  const classes = CampaignsStyles;
  const history = useHistory();
  const urlParams = useLocation().search;
  const id: any = new URLSearchParams(urlParams).get("id");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignData, setCampaignData] = useState({
    id: "",
    name: "",
    scheduleTime: "",
    status: "",
    subject: "",
    counts: [],
    content: "",
    groups: [],
  });

  useEffect(() => {
    retargetMethodResultDataHandler();
  }, [id]);

  const retargetMethodResultDataHandler = async () => {
    try {
      setIsLoading(true);
      const retargetMethod = await getRetargetedCampaigns(id);
      setCampaignData(retargetMethod);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const headerData = () => {
    return (
      <>
        <Grid
          container
          xs={12}
          sm={12}
          md={7}
          lg={12}
          xl={12}
          justifyContent="space-between"
          sx={classes.headerDataWrapper}
        >
          <Grid item xs={6} sm={6} md={2} lg={1} xl={1}>
            <Typography sx={classes.textStyle}>Status</Typography>
            <Typography sx={classes.textStyle}>Subject</Typography>
            <Typography sx={classes.textStyle}>Date</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={5} lg={11} xl={11}>
            <Typography sx={classes.textStyle}>
              {campaignData.status}
            </Typography>
            <Typography sx={classes.textStyle}>
              {campaignData.subject}
            </Typography>
            <Typography sx={classes.textStyle}>
              {campaignData?.scheduleTime === ""
                ? ""
                : moment(campaignData?.scheduleTime)?.format("MMM DD, YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const backButtonTitle = () => {
    return (
      <>
        <Box>
          <IconButton
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspace />
          </IconButton>
        </Box>
        <Grid xs={12}>
          <Typography fontWeight="bold" fontSize={20}>
            {campaignData.name}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {headerData()}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid sx={{ p: 3 }} xs={12} sm={12} lg={12} xl={12}>
        <Grid>
          <Box sx={classes.backButtonWrapper}>{backButtonTitle()}</Box>
        </Grid>
        <Box
          sx={{
            minWidth: "300px",
            width: "100%",
            overflow: "auto",
          }}
        >
          <RetargetedTable
            id={id ?? ""}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isRefresh={false}
          />
        </Box>
      </Grid>
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default RetargettingCampaignRequest;
