import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import strings from "global/constants/StringConstants";
import { useTitle } from "utils/UseTitle";
import { CustomButton } from "global/components";
import { useEffect, useState } from "react";
import BillingUsageStyles from "../BillingUsage/BillingUsage.styles";
import { pinkDarkColor, theme } from "utils/styles";
import ManageMFAModal from "./ManageMFAModal";
import urls from "global/constants/UrlConstants";
import Passkeys from "./Passkeys/Passkeys";
import CustomLoader from "global/components/CustomLoader/CustomLoader";

const Security = (props: {
  mfaEnabled: boolean;
  appLevelMFA: boolean;
  updateMFAStatus: Function;
}) => {
  useTitle(strings.SECURITY);
  const classes = BillingUsageStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [manageMFA, setManageMFA] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isPasskeyAvailable, setIsPasskeyAvailable] = useState(false);

  useEffect(() => {
    checkPasskeyAvailability();
  }, []);

  const checkPasskeyAvailability = async () => {
    const isUserVerifyingAvailable =
      await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    setIsPasskeyAvailable(isUserVerifyingAvailable);
  };

  const getSecurityBody = () => {
    return (
      <>
        <Box sx={classes.headingContainer} mt={2}>
          <Box sx={{ display: "flex" }}>
            <Typography sx={classes.pageTitle}>2FA</Typography>
          </Box>
        </Box>
        <Grid container mt={2} gap={3}>
          <Grid
            item
            xl={5.8}
            lg={5.8}
            md={12}
            sm={12}
            xs={12}
            sx={{ ...classes.billingDetail, height: "auto", padding: 4 }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignContent={"center"}
              alignItems={"center"}
              mt={1}
            >
              <Typography sx={classes.heading}>
                Multi Factor Authentication
              </Typography>
            </Box>

            <Box
              sx={{
                display: "block",
                position: "relative",
                mt: 1,
              }}
            >
              <Box
                sx={{
                  "&::before": {
                    backgroundColor: props.mfaEnabled
                      ? "success.main"
                      : "error.main",
                    borderRadius: "50%",
                    content: '""',
                    display: "block",
                    height: 8,
                    left: 4,
                    position: "absolute",
                    top: 7,
                    width: 8,
                    zIndex: 1,
                  },
                }}
              >
                <Typography
                  color={props.mfaEnabled ? "green" : "error"}
                  sx={{
                    pl: 3,
                    flexGrow: 1,
                    fontWeight: "bold",
                  }}
                >
                  {props.mfaEnabled ? "ON" : "OFF"}
                </Typography>
              </Box>
              <Typography
                sx={{
                  flexGrow: 1,
                  color: pinkDarkColor,
                  fontWeight: "bold",
                }}
                mt={1}
              >
                Authenticator App
              </Typography>

              <Typography
                sx={{ ...classes.contentHeading, textAlign: "left" }}
                mt={1}
              >
                Use an authenticator app to generate one time security codes.
              </Typography>

              <Box sx={{ mt: 4 }}>
                <CustomButton
                  id="mfa_button"
                  label={props.mfaEnabled ? "Disable" : "Set Up"}
                  onClick={() => setManageMFA(true)}
                  customClasses={{
                    width: "100px",
                  }}
                  disabled={true}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          marginX: isDesktop ? "16px" : "0px",
        }}
      >
        {getSecurityBody()}
      </Container>
      <Box
        sx={{
          marginX: isDesktop ? "16px" : "0px",
        }}
      >
        {isPasskeyAvailable && <Passkeys setIsLoading={setLoading} />}
      </Box>
      <ManageMFAModal
        manageMFA={manageMFA}
        handleDialogClose={() => setManageMFA(false)}
        mfaEnabled={props.mfaEnabled}
        updateMFAStatus={props.updateMFAStatus}
      />
      <CustomLoader isLoading={loading} />
    </>
  );
};

export default Security;
