import { GlobalInterval } from "models/interfaces";

export const getInitialFailedState = (interval: GlobalInterval) => {
  return {
    interval,
    status: failedStatsBounceStatus[0].value,
    campaignCategory: failedStatsStatus[0].value,
    searchText: "",
    page: 1,
    perPageData: 10,
  };
};

export const failedStatsHeader = [
  {
    name: "Sent By",
    field: "campaignOwner",
    sorting: true,
  },
  {
    name: "Campaign Recipient",
    field: "contactEmail",
    sorting: true,
  },
  {
    name: "Campaign Name",
    field: "campaignName",
    sorting: true,
  },
  {
    name: "Fail Event",
    field: "response",
    sorting: false,
  },
  {
    name: "Reason",
    field: "reason",
    sorting: false,
    align: "left",
  },
];

export const failedStatsBounceStatus = [
  {
    id: 0,
    label: "Soft Bounced",
    value: "SoftBounced",
  },
  {
    id: 1,
    label: "Hard Bounced",
    value: "HardBounced",
  },
  {
    id: 2,
    label: "Spam",
    value: "spam",
  },
  {
    id: 3,
    label: "Invalid",
    value: "invalid",
  },
  {
    id: 4,
    label: "Dropped",
    value: "dropped",
  },
  {
    id: 5,
    label: "Abused",
    value: "abuse",
  },
];

export const failedStatsStatus = [
  {
    id: 0,
    label: "All",
    value: "all",
  },

  {
    id: 2,
    label: "My",
    value: "my",
  },
  {
    id: 3,
    label: "Team",
    value: "team",
  },
];
