import { useEffect, useState } from "react";
import history from "utils/history";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { KeyboardBackspace, PersonAdd } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import campaignDeleteModal from "assets/images/campaignDeleteModal.svg";
import updateCampaignGroupImg from "assets/images/updateCampaignGroup.svg";
import GroupContactsStyles from "screens/GroupContacts/GroupContacts.styles";
import {
  debounceEventHandler,
  getFormattedStatsCount,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  SortingConfig,
  campaignGroup,
  contactsFilters,
} from "models/interfaces";
import { getCampaignGroupById } from "screens/Contact/ViewContactService";
import { useParams } from "react-router-dom";
import {
  CustomButton,
  CustomDialog,
  CustomIcon,
  CustomInput,
} from "global/components";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import {
  deleteGroupRequest,
  downloadContactList,
  getContactsByGroupId,
  getContactsByGroupIdCount,
  getSearchedContactsByGroupId,
  getSearchedContactsByGroupIdCount,
  getSubordinatesList,
  updateContactListDocument,
} from "screens/CampaignGroups/CampaignGroups.service";
import urls from "global/constants/UrlConstants";
import { DropzoneAreaBase } from "react-mui-dropzone";
import { ReactComponent as dropZoneDropZone } from "assets/icons/dropZoneDropZone.svg";
import {
  initialGroupContactsFilterState,
  initialGroupToBeUpdatedState,
} from "screens/GroupContacts/GroupContactsData";
import strings from "global/constants/StringConstants";
import { GridSearchIcon } from "@mui/x-data-grid";
import { darkPurpledColor, pureWhiteColor, theme } from "utils/styles";
import { hasAccessTo } from "utils/AuthorizationManager";
import ContactTable from "screens/Contact/ContactTable";
import { useTitle } from "utils/UseTitle";

const GroupContacts = () => {
  useTitle(strings.GroupContacts);
  const classes = GroupContactsStyles;
  const { groupId } = useParams<any>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [group, setGroup] = useState<campaignGroup>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groupFetchLoader, setGroupFetchLoader] = useState<boolean>(false);
  const [groupContactsCount, setGroupContactsCount] = useState<number>(0);
  const [groupContacts, setGroupContacts] = useState<any[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState<boolean>(false);
  const [hideActions, setHideActions] = useState<boolean>(false);
  const [isAddRecipientDialogOpen, setIsAddRecipientDialogOpen] =
    useState<boolean>(false);
  const [groupToBeUpdated, setGroupToBeUpdated] = useState(
    initialGroupToBeUpdatedState
  );
  const [groupContactsFilters, setGroupContactsFilters] =
    useState<contactsFilters>(initialGroupContactsFilterState());
  const [sortConfig, setSortConfig] = useState<SortingConfig[]>([]);

  const hasContactAddAccess = hasAccessTo(
    strings.contact,
    strings.addPermission
  );

  useEffect(() => {
    fetchGroupById();
  }, []);

  useEffect(() => {
    getTableData();
  }, [groupContactsFilters, sortConfig]);

  const getTableData = async () => {
    try {
      setIsLoading(true);
      const { searchText, page, rowsPerPage } = groupContactsFilters;
      const count = isTruthy(searchText)
        ? await getSearchedContactsByGroupIdCount(groupId, searchText)
        : await getContactsByGroupIdCount(groupId);
      count === 0 ? history.goBack() : setGroupContactsCount(count);
      await getTableDataAccordingToPage(count, page, searchText, rowsPerPage);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getTableDataAccordingToPage = async (
    count: number,
    page: number,
    searchText: string,
    rowsPerPage: number
  ) => {
    try {
      let tableData: any[] = [];
      const expectedPages = Math.ceil(count / rowsPerPage);
      if (page <= expectedPages || count === 0) {
        tableData = isTruthy(searchText)
          ? await getSearchedContactsByGroupId(
              searchText,
              groupId,
              page,
              rowsPerPage,
              sortConfig
            )
          : await getContactsByGroupId(groupId, page, rowsPerPage, sortConfig);
        setGroupContacts(tableData);
      } else {
        setGroupContactsFilters({
          ...groupContactsFilters,
          page: page - 1,
        });
      }
    } catch (error: any) {
      throw error;
    }
  };

  const fetchGroupById = async () => {
    try {
      setGroupFetchLoader(true);
      const response = await getCampaignGroupById(groupId);
      setGroup(response);
      if (isTruthy(response)) {
        const subordinates = await getSubordinatesList();
        if (!subordinates.includes(response.owner)) {
          setHideActions(true);
        } else {
          setHideActions(false);
        }
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      history.push(urls.campaignGroupsViewPath);
    } finally {
      setGroupFetchLoader(false);
    }
  };

  const deleteButtonHandler = () => {
    setIsDeleteDialogOpen(true);
  };

  const updateButtonHandler = () => {
    setIsUpdateDialogOpen(true);
    setGroupToBeUpdated({
      ...groupToBeUpdated,
      groupId: group?.id ?? "",
      groupName: group?.name ?? "",
    });
  };

  const deleteDialogCloseHandler = () => {
    setIsDeleteDialogOpen(false);
  };

  const updateDialogCloseHandler = () => {
    setIsUpdateDialogOpen(false);
    setGroupToBeUpdated(initialGroupToBeUpdatedState);
  };

  const deleteGroupHandler = async () => {
    try {
      setIsLoading(true);
      const response = await deleteGroupRequest(group?.id);
      openSuccessNotification(response.message);
      history.push(urls.campaignGroupsViewPath);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnDropzone = (event: any) => {
    setGroupToBeUpdated({ ...groupToBeUpdated, groupCSV: event[0] });
  };

  const handleDeleteFileName = () => {
    setGroupToBeUpdated({ ...groupToBeUpdated, groupCSV: {} });
  };

  const handleGroupReportExport = async () => {
    try {
      setIsLoading(true);
      const data = await downloadContactList(group?.id ?? "");
      let a = document.createElement("a");
      // @ts-ignore
      a.style = "display:none";
      let url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = `${group?.name}.csv`;
      a.click();
      // @ts-ignore
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmUpload = async () => {
    try {
      setIsLoading(true);
      if (!Object.keys(groupToBeUpdated.groupCSV).length) {
        openErrorNotification("Please upload the updated campaign group csv");
        setIsLoading(false);
        return;
      }
      const data = new FormData();
      const file = groupToBeUpdated.groupCSV.file;
      data.append("file", file);
      const response = await updateContactListDocument(
        data,
        groupToBeUpdated.groupId
      );
      openSuccessNotification(response.message);
      updateDialogCloseHandler();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getHeading = () => {
    return (
      <Grid container sx={classes.headingGridContainer}>
        <IconButton
          onClick={() => {
            history.goBack();
          }}
          id="group_contacts_back_button"
        >
          <KeyboardBackspace />
        </IconButton>
        <Typography sx={classes.groupContactsTitle}>
          Group's Contacts
        </Typography>
      </Grid>
    );
  };

  const searchChangeHandler = (event: any) => {
    const searchText = event.target.value;
    setGroupContactsFilters({
      ...groupContactsFilters,
      page: 1,
      searchText,
    });
  };

  const pageChangeHandler = (event: any, page: number) => {
    setGroupContactsFilters({
      ...groupContactsFilters,
      page,
    });
  };

  const rowsPerPageChangeHandler = (event: any) => {
    setGroupContactsFilters({
      ...groupContactsFilters,
      rowsPerPage: event.target.value,
      page: 1,
    });
  };

  const refreshTableData = () => {
    getTableData();
    fetchGroupById();
  };

  const deleteDialogTitleContent = () => {
    return <Typography sx={classes.deleteModalTitle}>Delete Group</Typography>;
  };

  const updateDialogTitleContent = () => {
    return (
      <Box sx={classes.updateDialogTitleWrapper}>
        <Box sx={classes.updateDialogTitle}>Update Campaign Group</Box>
        <Box sx={classes.updateDialogNameBox}>
          Group Name:
          <Box component={"span"} sx={classes.spanStyle}>
            {group?.name}
          </Box>
        </Box>
      </Box>
    );
  };

  const deleteDialogBodyContent = () => {
    return (
      <Box sx={classes.deleteDialogBodyContainer}>
        <Typography sx={classes.fontText}>
          Are you sure you want to delete
        </Typography>
        <Typography sx={classes.fontText}>
          <Box component="span" sx={classes.textBold}>
            {group?.name}
          </Box>{" "}
          group ?
        </Typography>
      </Box>
    );
  };

  const updateDialogBodyContent = () => {
    return (
      <Grid>
        <InputLabel sx={classes.input} shrink>
          Updated File
          <Box ml={0.4} sx={classes.star}>
            *
          </Box>
        </InputLabel>
        <Box sx={classes.dropzoneAreaBase}>
          <DropzoneAreaBase
            fileObjects={[]}
            dropzoneText="Drag and Drop your attachments"
            onAdd={(e) => {
              handleOnDropzone(e);
            }}
            maxFileSize={3000000}
            acceptedFiles={[".csv"]}
            showAlerts={true}
            showPreviewsInDropzone={true}
            showFileNames={true}
            filesLimit={1}
            Icon={dropZoneDropZone}
          />
        </Box>
        {Object.keys(groupToBeUpdated.groupCSV).length > 0 && (
          <Chip
            sx={classes.previewChip}
            label={groupToBeUpdated?.groupCSV?.file?.path}
            variant="filled"
            onDelete={handleDeleteFileName}
          />
        )}
        <Typography sx={classes.warningContent}>
          * Only supporting updating of existing contacts first name, last name,
          contact number, and tags.
        </Typography>
        <Typography sx={classes.warningContent}>
          * Please do not add any new email ids while updating contact list.
        </Typography>
      </Grid>
    );
  };

  const deleteDialogHeaderContent = () => {
    return <img src={campaignDeleteModal} alt="image not found" />;
  };

  const updateDialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={updateCampaignGroupImg} alt="image" />
      </Box>
    );
  };

  const deleteDialogDialogFooter = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            customClasses={classes.cancelButtonStyle}
            label="Cancel"
            onClick={deleteDialogCloseHandler}
            id="group_contacts_delete_dialog_cancel_button"
          />
          <CustomButton
            label="Delete"
            onClick={deleteGroupHandler}
            id="group_contacts_delete_dialog_delete_button"
          />
        </Box>
      </Grid>
    );
  };

  const updateDialogFooterContent = () => {
    return (
      <Box sx={classes.dialogFooter}>
        <CustomButton
          label="Cancel"
          customClasses={classes.buttonWhiteBg}
          onClick={updateDialogCloseHandler}
          id="group_contacts_update_dialog_cancel_button"
        />
        <CustomButton
          label="Update"
          loading={isLoading}
          onClick={handleConfirmUpload}
          id="group_contacts_update_dialog_update_button"
        />
      </Box>
    );
  };

  const getGroupInformation = () => {
    return (
      <Box sx={classes.groupInformationBox}>
        <Stack
          direction={{ xl: "row", lg: "column" }}
          justifyContent="space-between"
          spacing={2}
        >
          <Stack
            direction={{ sm: "column", xs: "column" }}
            sx={classes.groupInfoStack}
          >
            <Typography sx={classes.groupInformationHeadingText}>
              Contacts of{" "}
              <span style={{ color: "#BB0070" }}>{group?.name}</span>
              <Chip
                sx={classes.groupTypeText}
                label={`Type: ${group?.type ?? ""}`}
              />
            </Typography>
            <Typography sx={classes.groupTotalContacts}>
              Total number of contacts:{" "}
              <span style={classes.groupContactCount}>
                {getFormattedStatsCount(group?.recipientCount ?? 0)}
              </span>
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            {!hideActions &&
              getButton(
                "group_contacts_delete_button",
                "Delete",
                <DeleteOutlineIcon htmlColor={pureWhiteColor} />,
                deleteButtonHandler
              )}
            {!hideActions &&
              getButton(
                "group_contacts_export_button",
                "Export",
                <DownloadIcon htmlColor={pureWhiteColor} />,
                handleGroupReportExport
              )}
            {!hideActions &&
              getButton(
                "group_contacts_update_button",
                "Update",
                <FileUploadIcon htmlColor={pureWhiteColor} />,
                updateButtonHandler
              )}
          </Stack>
        </Stack>
      </Box>
    );
  };

  const getButton = (
    id: string,
    label: string,
    icon: JSX.Element,
    onClick: Function
  ) => {
    return isMobile ? (
      <CustomIcon
        icon={icon}
        onClick={onClick}
        customClass={classes.customIconsStyles}
      />
    ) : (
      <CustomButton
        id={id}
        label={label}
        icon={icon}
        onClick={onClick}
        customClasses={classes.headingButtonStyles}
      />
    );
  };

  const getGroupContactInputs = () => {
    return (
      <Box sx={classes.additionalInputsContainer}>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          sx={classes.additionalInputsStack}
        >
          <CustomButton
            id="group_contacts_refresh_button"
            onClick={refreshTableData}
            label={<CachedIcon htmlColor={darkPurpledColor} />}
            customClasses={classes.refreshBtn}
          />
          <Box sx={[classes.inputBoxStyles, classes.inputBoxStylesExtended]}>
            <CustomInput
              id="group_contacts_search_field"
              placeHolder="Search text"
              onChange={debounceEventHandler(
                searchChangeHandler,
                strings.SEARCH_TIME_OUT
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <GridSearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {!hideActions && (
            <Box sx={[classes.inputBoxStyles, classes.inputBoxStylesExtended]}>
              {hasContactAddAccess && (
                <CustomButton
                  id="group_contacts_add_button"
                  customClasses={classes.addBtnStyle}
                  onClick={() => setIsAddRecipientDialogOpen(true)}
                  label="Add Recipient"
                  icon={<PersonAdd />}
                />
              )}
            </Box>
          )}
        </Stack>
      </Box>
    );
  };

  const getGroupContactsTable = () => {
    return (
      <ContactTable
        rawContactData={groupContacts}
        contactsFilters={groupContactsFilters}
        contactCount={groupContactsCount}
        urlId={groupId}
        name={group?.name}
        pageChangeHandler={pageChangeHandler}
        perPageRowsChangeHandler={rowsPerPageChangeHandler}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        fetchTableData={getTableData}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        addRecipientModal={isAddRecipientDialogOpen}
        setAddRecipientModal={setIsAddRecipientDialogOpen}
        fetchGroupInfoById={fetchGroupById}
        hideActions={hideActions ?? false}
      />
    );
  };

  const getGroupDeleteDialog = () => {
    return (
      <CustomDialog
        isDialogOpen={isDeleteDialogOpen}
        closable
        handleDialogClose={deleteDialogCloseHandler}
        dialogTitleContent={deleteDialogTitleContent()}
        dialogBodyContent={deleteDialogBodyContent()}
        dialogHeaderContent={deleteDialogHeaderContent()}
        dialogFooterContent={deleteDialogDialogFooter()}
        width="460px"
        closeButtonVisibility
      />
    );
  };

  const getGroupUpdateDialog = () => {
    return (
      <CustomDialog
        isDialogOpen={isUpdateDialogOpen}
        closable
        dialogHeaderContent={updateDialogHeaderContent()}
        closeButtonVisibility
        handleDialogClose={updateDialogCloseHandler}
        dialogTitleContent={updateDialogTitleContent()}
        dialogBodyContent={updateDialogBodyContent()}
        dialogFooterContent={updateDialogFooterContent()}
        width="550px"
      />
    );
  };

  return (
    <>
      <Box sx={{ margin: 4 }}>
        {getHeading()}
        {getGroupInformation()}
        {getGroupContactInputs()}
        {getGroupContactsTable()}
        {getGroupDeleteDialog()}
        {getGroupUpdateDialog()}
      </Box>
      <CustomLoader isLoading={isLoading || groupFetchLoader} />
    </>
  );
};

export default GroupContacts;
