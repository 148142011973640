import { Typography, Grid, Box, Tooltip } from "@mui/material";
import socialStyles from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/Social.styles";
import FacebookCard from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/FacebookCard";
import InstagramCard from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/InstagramCard";
import LinkedInCard from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/LinkedInCard";
import strings from "global/constants/StringConstants";
import { CustomAppHeader, CustomButton, CustomPaper } from "global/components";
import { KeyboardBackspace } from "@mui/icons-material";
import history from "utils/history";
import urls from "global/constants/UrlConstants";

interface NetworkCustomProps {
  isLoading: boolean;
  socialAppCardClickHandler: Function;
  selectedAppsList: string;
  facebookPage?: any;
  linkedInUserDetails?: any;
  handleSaveContinueBtn: Function;
}

const Network = (props: NetworkCustomProps) => {
  const classes = socialStyles;

  const backClickHandler = () => {
    history.push(urls.createCampaignsViewPath);
  };

  const getBackArrowKey = () => {
    return (
      <Box sx={classes.backArrowKeyContainer}>
        <Tooltip
          title={
            <CustomPaper className={classes.tooltipBackgroundContainer}>
              <Typography sx={classes.tooltipText}>
                {"Back to Create Campaigns"}
              </Typography>
            </CustomPaper>
          }
          placement="right"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                background: "none",
              },
            },
          }}
        >
          <KeyboardBackspace
            sx={classes.backArrowKeyIcon}
            onClick={() => {
              backClickHandler();
            }}
          />
        </Tooltip>
      </Box>
    );
  };

  const getHeaderTitle = () => {
    return (
      <Box>
        <Typography sx={classes.networkPageTitle}>
          Create Social Campaign
        </Typography>
      </Box>
    );
  };

  const getHeaderButton = () => {
    return (
      <Box>
        <CustomButton
          id="social_save_continue_button"
          label="Save & Continue"
          onClick={props.handleSaveContinueBtn}
          customClasses={classes.socialButtonStyle}
        />
      </Box>
    );
  };

  const getMainContent = () => {
    return (
      <Box sx={classes.networkWrapper}>
        <Box sx={classes.networkContentHeading}>
          <Typography sx={classes.networkPageContentHeading}>
            Where Do You Want To Share Your Post?
          </Typography>
          <Typography sx={classes.networkPageContent}>
            Publish to any social media platform and reach all your followers at
            once.
          </Typography>
        </Box>
        <Box sx={classes.networkSocialCardsContainer}>
          <Grid container sx={classes.cardWrapper}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              sx={classes.networkSocialCardGridItem}
            >
              <FacebookCard
                isLoading={props.isLoading}
                isCardClickable={true}
                selectedAppsList={props.selectedAppsList}
                socialAppCardClickHandler={props.socialAppCardClickHandler}
                facebookPage={props.facebookPage}
              />
            </Grid>
            {strings.isInstagramReleased && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                sx={classes.networkSocialCardGridItem}
              >
                <InstagramCard
                  isCardClickable={true}
                  selectedAppsList={props.selectedAppsList}
                  socialAppCardClickHandler={props.socialAppCardClickHandler}
                  facebookPage={props.facebookPage}
                />
              </Grid>
            )}
            {strings.isLinkedinReleased && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                sx={classes.networkSocialCardGridItem}
              >
                <LinkedInCard
                  isCardClickable={true}
                  selectedAppsList={props.selectedAppsList}
                  socialAppCardClickHandler={props.socialAppCardClickHandler}
                  linkedInUserDetails={props.linkedInUserDetails}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <CustomAppHeader className={classes.networkPageHeaderSection}>
        <Grid container sx={classes.headerGridContainer}>
          <Grid item xs={12} xl={12}>
            {getBackArrowKey()}
          </Grid>
          <Grid item xs={12} xl={12} sx={classes.headerContentGridItem}>
            {getHeaderTitle()}
            {getHeaderButton()}
          </Grid>
        </Grid>
      </CustomAppHeader>
      {getMainContent()}
    </>
  );
};

export default Network;
