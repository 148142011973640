import strings from "global/constants/StringConstants";
import { GlobalInterval } from "models/interfaces";

export const getInitialSocialCampaignFilterState = (
  page: number,
  perPageData: number,
  search: string,
  status: string,
  interval: GlobalInterval
) => {
  return {
    page,
    perPageData,
    search,
    status,
    interval,
  };
};

export const options = [
  {
    id: 0,
    label: "Today",
    value: 0,
  },
  {
    id: 2,
    label: "Yesterday",
    value: 1,
  },
  {
    id: 3,
    label: "Last 4 Days",
    value: 4,
  },
  {
    id: 4,
    label: "Last 1 Week",
    value: 7,
  },
  {
    id: 5,
    label: "Last 2 Weeks",
    value: 14,
  },
  {
    id: 6,
    label: "Last 1 Month",
    value: 30,
  },
  {
    id: 7,
    label: "Last 45 days",
    value: 45,
  },
];

export const SocialCampaignsTabContent = [
  {
    label: strings.Completed,
    count: 0,
  },
  {
    label: strings.Submitted,
    count: 0,
  },
];

export const campaignerHeader = [
  {
    name: "Campaign Name",
    field: "SocialCampaignName",
  },
  {
    name: "Account Name",
    field: "PageName",
  },
  {
    name: "App Name",
    field: "AppIcon",
  },
  {
    name: "Submitted By",
    field: "SubmittedBy",
  },

  {
    name: "Sent On",
    field: "SentOn",
  },

  {
    name: "Action",
    field: "SocialCampaignStats",
    align: "center",
  },
];

export const campaignerHeaderSubmitted = [
  {
    name: "Campaign Name",
    field: "SocialCampaignName",
  },
  {
    name: "Account Name",
    field: "PageName",
  },
  {
    name: "App Name",
    field: "AppIcon",
  },
  {
    name: "Submitted By",
    field: "SubmittedBy",
  },
  {
    name: "Scheduled On",
    field: "SentOn",
  },
  {
    name: "Action",
    field: "SocialCampaignStats",
    align: "center",
  },
];
