import strings from "global/constants/StringConstants";
import urls from "global/constants/UrlConstants";
import {
  getCallParams,
  getSearchedCallParams,
  makeCall,
  makeCallFile,
} from "utils/service";

export async function getContactsCount(dropdownValue: string) {
  try {
    const callParams = await getCallParams("GET");
    const url = urls.getContactsCountValue + `/${dropdownValue}`;
    const response: any = await makeCall(
      url,
      callParams,
      strings.CUSTOM_API_TIMEOUT_LIMIT
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getContacts(
  dropdownValue: string,
  page: number,
  limit: number,
  sortingParams: any
) {
  try {
    const callParams = await getCallParams("POST", sortingParams);
    const url = `${urls.getContactsPageValue}/${dropdownValue}/page/${page}/limit/${limit}`;
    const response: any = await makeCall(
      url,
      callParams,
      strings.CUSTOM_API_TIMEOUT_LIMIT
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getSearchedContactsCount(
  searchText: string | undefined,
  dropdownValue: any
) {
  try {
    const callParams = await getSearchedCallParams("POST", searchText ?? "");
    const url = `${urls.getContactCountSearch}/${dropdownValue}/search`;
    const response: any = await makeCall(
      url,
      callParams,
      strings.CUSTOM_API_TIMEOUT_LIMIT
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getSearchedContacts(
  searchText: string | undefined,
  dropdownValue: any,
  page: number,
  limit: number,
  entitySorters?: any
) {
  try {
    const body = { entitySorters, searchText };
    const callParams = await getSearchedCallParams(
      "POST",
      JSON.stringify(body)
    );
    const url = `${urls.getContactSearchPage}/${dropdownValue}/search/page/${page}/limit/${limit}`;
    const response: any = await makeCall(
      url,
      callParams,
      strings.CUSTOM_API_TIMEOUT_LIMIT
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getTableDeleteModal(email: any) {
  try {
    const callParams = await getCallParams("GET");
    const url = urls.getDeleteGroupData + `/${email}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const deleteContactFromGroups = async (email: string, id: any) => {
  try {
    const url = urls.deleteContact + `/${email}`;
    const callParams = await getCallParams("DELETE", id);
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export async function getRecipientSummary(emailId: string) {
  try {
    const callParams = await getSearchedCallParams("POST", emailId);
    const url: string = `${urls.getCampaignsRecipient}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getCampaignGroupById(groupId: any) {
  try {
    const callParams = await getCallParams("GET");
    const url = `${urls.campaignGroupById}/${groupId}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function deleteById(emailId: any, tagsId: any) {
  try {
    const callParams = await getCallParams("POST");
    const url = `${urls.deleteTagsById}/${emailId}/tagId/${tagsId}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function addTag(emailId: string, tagNames: string[]) {
  try {
    const callParams = await getCallParams("POST", tagNames);
    const url = `${urls.addTags}/${emailId}`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function addNewContact(groupId: string | null, contactData: any) {
  try {
    const callParams = await getCallParams("POST", contactData);
    const url = `${urls.addNewContact}/${groupId}/addNew`;
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const contactReportDownload = async (contactFilter: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(
      `${urls.downloadContactReport}/${contactFilter}/report`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
