import { Card, Stack, Typography } from "@mui/material";
import React from "react";
import ViewTicketStyles from "screens/Tickets/ViewTicket/ViewTicket.styles";
import { parseHTML } from "screens/Tickets/ViewTicket/Components/CommentEditor";
import { isTruthy } from "helpers/methods";
import Attachments from "screens/Tickets/ViewTicket/Components/Attachments";
import { regularFont } from "utils/styles";

interface CustomProps {
  ticketDetails: any;
  isLoading: boolean;
}

const TicketReporterDetails = (props: CustomProps) => {
  const classes = ViewTicketStyles;
  return (
    <Card sx={{ ...classes.billingDetail, height: "auto", p: 3 }}>
      <Stack direction="column" spacing={1}>
        <Typography sx={{ ...classes.heading, color: "#242A3A" }}>
          Description
        </Typography>
        <Typography sx={{...regularFont}} color="#6C737F">
          {isTruthy(props?.ticketDetails?.description) &&
            parseHTML(props?.ticketDetails?.description)}
        </Typography>
      </Stack>
      {props.ticketDetails?.docUrls.length > 0 && (
        <Attachments
          ticketDetails={props.ticketDetails}
          isLoading={props.isLoading}
        />
      )}
    </Card>
  );
};

export default TicketReporterDetails;
