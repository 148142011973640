import { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Radio,
  FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import StepThreeStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/StepThree.styles";
import strings from "global/constants/StringConstants";
import {
  debounceEventHandler,
  getFormattedStatsCount,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import {
  getCampaignGroupsBySearchTextAllTeamMy,
  getCampaignGroupsCountBySearchTextAllTeamMy,
  getGroupsAllMyTeam,
  getGroupsAllMyTeamCountGroupsTeamCount,
  getSearchTagCount,
  getSearchTagData,
  getTags,
  getTagsCount,
  getValidDistinctEmailCountByGroups,
  getValidDistinctEmailCountByTags,
} from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignServices";
import notifiers from "global/constants/NotificationConstants";
import { CustomTable } from "global/components";
import { useAppSelector } from "utils/hooks";
import { selectIsManager } from "redux/authSlice";
import {
  campaignGroupOptionAdmin,
  campaignGroupOptionUser,
  campaignerGroupHeader,
} from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignHelpers";
import { cloneDeep } from "lodash";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { SortingConfig } from "models/interfaces";
import { getContactGroupTypes } from "screens/CampaignGroups/CampaignGroups.service";

interface CustomProps {
  campaignData: any;
  setCampaignData: any;
  allowedEmailCount: number;
}

const classes = StepThreeStyles;

export const MailingListTable = ({
  campaignData,
  setCampaignData,
  allowedEmailCount,
}: CustomProps) => {
  const [selectedDropdownValue, setSelectedDropdownValue] =
    useState<string>("all");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [perPageData, setPerPageData] = useState(10);
  const [count, setCount] = useState<number>(0);
  const [tableData, setTableData] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const [memberCount, setMemberCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataLoader, setDataLoader] = useState<boolean>(false);
  const searchRef = useRef<any>("");

  const selectedGroups = campaignData.groups?.[0]?.value;
  const selectedGroupIds = selectedGroups.map((item: any) => item.id);
  const currentMailingList = campaignData.groups?.[0]?.type;
  const isManager = useAppSelector(selectIsManager);
  const [sortConfig, setSortConfig] = useState<SortingConfig[]>([]);
  const [groupTypes, setGroupTypes] = useState<string[]>(["All"]);
  const [selectedGroupType, setSelectedGroupType] = useState<string>("All");

  useEffect(() => {
    getGroupTypes();
    getTableData(
      currentMailingList,
      pageNumber,
      selectedDropdownValue,
      selectedGroupType,
      perPageData,
      search
    );
    getSelectedMembersCount(selectedGroupIds);
  }, [sortConfig]);

  const getGroupTypes = async () => {
    try {
      setIsLoading(true);
      const groupType = await getContactGroupTypes();
      setGroupTypes(["All", ...groupType]);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getTableData = async (
    target: string,
    pageNumber: any,
    selectedDropdownValue: any,
    selectedGroupType: string,
    perPageData: any,
    searchValue: string
  ) => {
    try {
      setDataLoader(true);
      let response, countValue;

      if (target === strings.groupMailingListType) {
        if (searchValue !== "") {
          [response, countValue] = await Promise.all([
            getCampaignGroupsBySearchTextAllTeamMy(
              searchValue,
              pageNumber,
              selectedDropdownValue,
              selectedGroupType,
              perPageData,
              sortConfig
            ),
            getCampaignGroupsCountBySearchTextAllTeamMy(
              searchValue,
              selectedDropdownValue,
              selectedGroupType
            ),
          ]);
        } else {
          setDataLoader(true);
          [response, countValue] = await Promise.all([
            getGroupsAllMyTeam(
              pageNumber,
              selectedDropdownValue,
              selectedGroupType,
              perPageData,
              sortConfig
            ),
            getGroupsAllMyTeamCountGroupsTeamCount(
              selectedDropdownValue,
              selectedGroupType
            ),
          ]);
        }
        handleTableData(response);
      } else {
        if (searchValue !== "") {
          [response, countValue] = await Promise.all([
            getSearchTagData(searchValue, pageNumber, perPageData, sortConfig),
            getSearchTagCount(searchValue),
          ]);
        } else {
          [response, countValue] = await Promise.all([
            getTags(pageNumber, perPageData, sortConfig),
            getTagsCount(),
          ]);
        }
        setTableData(response);
      }
      setCount(countValue);
      setDataLoader(false);
    } catch (error: any) {
      setDataLoader(false);
      const errorMessage = error?.message || notifiers.GENERIC_ERROR;
      openErrorNotification(
        isTruthy(errorMessage) ? errorMessage : notifiers.GENERIC_ERROR
      );
    }
  };

  const getValidDistinctEmailCountByGroupsMethod = async (
    selectedGroupIds: any
  ) => {
    try {
      setIsLoading(true);
      const response = await getValidDistinctEmailCountByGroups(
        selectedGroupIds
      );
      setMemberCount(response);
      checkEmailUserCount(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const tagsEmailCount = async (selectedGroupIds: any) => {
    try {
      setIsLoading(true);
      const response = await getValidDistinctEmailCountByTags(selectedGroupIds);
      setMemberCount(response);
      checkEmailUserCount(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const checkEmailUserCount = (response: any) => {
    if (response > allowedEmailCount) {
      const errorMessage = `The requested email count of ${response} exceeds your permissible limit of ${allowedEmailCount}.`;
      openErrorNotification(errorMessage);
    }
  };

  const getSelectedMembersCount = (selectedGroupIds: any) => {
    if (selectedGroupIds.length === 0) {
      setMemberCount(0);
    } else if (currentMailingList === strings.groupMailingListType) {
      getValidDistinctEmailCountByGroupsMethod(selectedGroupIds);
    } else {
      tagsEmailCount(selectedGroupIds);
    }
  };

  const handleTableData = (emailGroup: any) => {
    const data: any = emailGroup.map((eachRecord: any) => ({
      id: eachRecord.id,
      key: eachRecord.id,
      name: eachRecord.name,
      groupType: eachRecord.type,
      owner: eachRecord.owner,
      recipientCount: eachRecord.recipientCount,
    }));
    setTableData(data);
  };

  const onChangeMailingList = (value: string) => {
    if (value !== currentMailingList) {
      setPageNumber(1);
      setSearch("");
      searchRef.current.value = "";
      setCampaignData({
        ...campaignData,
        target: value,
        groups: [{ type: value, value: [] }],
      });
      setSortConfig([]);
      getSelectedMembersCount([]);
      getTableData(
        value,
        1,
        selectedDropdownValue,
        selectedGroupType,
        perPageData,
        ""
      );
    }
  };

  const handleSearchChange = (event: any) => {
    const searchVal = event.target.value.trim();
    setSearch(searchVal);
    setPageNumber(1);
    if (searchVal !== search) {
      getTableData(
        currentMailingList,
        1,
        selectedDropdownValue,
        selectedGroupType,
        perPageData,
        searchVal
      );
    }
  };

  const handleDropdownOnchange = (event: any) => {
    setPageNumber(1);
    setSelectedDropdownValue(event.target.value);
    setSearch("");
    searchRef.current.value = "";
    getTableData(
      currentMailingList,
      1,
      event.target.value,
      selectedGroupType,
      perPageData,
      ""
    );
  };

  const handleGroupTypeChange = (event: any) => {
    setSelectedGroupType(event.target.value);
    setPageNumber(1);
    setSearch("");
    searchRef.current.value = "";
    getTableData(
      currentMailingList,
      1,
      selectedDropdownValue,
      event.target.value,
      perPageData,
      ""
    );
  };

  const handlePerPageData = (event: any) => {
    setPageNumber(1);
    setPerPageData(event.target.value);
    getTableData(
      currentMailingList,
      1,
      selectedDropdownValue,
      selectedGroupType,
      event.target.value,
      search
    );
  };

  const handlePageChange = (event: any, pageNo: number) => {
    setPageNumber(pageNo);
    if (pageNumber !== pageNo) {
      getTableData(
        currentMailingList,
        pageNo,
        selectedDropdownValue,
        selectedGroupType,
        perPageData,
        search
      );
    }
  };

  const isSelectedRow = (id: any) => {
    return selectedGroupIds?.indexOf(id) !== -1;
  };

  const handleSingleRowCheckBoxClick = (event: any, row: any) => {
    const updatedCampaignData = cloneDeep(campaignData);
    const targetValue = updatedCampaignData.groups?.[0]?.value;

    if (event.target.checked) {
      targetValue.push({ id: row.id, name: row.name });
    } else {
      const indexToRemove = targetValue.findIndex(
        (item: any) => item.id === row.id
      );
      targetValue.splice(indexToRemove, 1);
    }

    updateCampaignData(updatedCampaignData);
  };

  const handleSelectAllCheckBoxClick = (event: any) => {
    const updatedCampaignData = cloneDeep(campaignData);
    const targetValue = updatedCampaignData.groups?.[0]?.value;

    if (event.target.checked) {
      tableData.forEach((item: any) => {
        if (!selectedGroupIds.includes(item.id)) {
          targetValue.push({ id: item.id, name: item.name });
        }
      });
    } else {
      const newSelectedIds = tableData.map((item: any) => item.id);
      const uncheckedGroups = selectedGroups?.filter(
        (item: any) => !newSelectedIds.includes(item.id)
      );
      updatedCampaignData.groups[0].value = uncheckedGroups;
    }

    updateCampaignData(updatedCampaignData);
  };

  const updateCampaignData = (updatedData: any) => {
    setCampaignData(updatedData);
    const newIds = updatedData.groups?.[0]?.value.map((item: any) => item.id);
    getSelectedMembersCount(newIds);
  };
  const tableContent = () => {
    return (
      <Box mt={2} sx={{ minWidth: "300px", width: "100%", overflow: "auto" }}>
        <CustomTable
          headers={campaignerGroupHeader}
          rows={tableData}
          handlePageChange={handlePageChange}
          paginationCount={count}
          checkboxSelection={true}
          handleClick={handleSingleRowCheckBoxClick}
          isSelected={isSelectedRow}
          onSelectAllClick={handleSelectAllCheckBoxClick}
          isSelectAll={selectedGroupIds}
          isLoading={isLoading}
          handlePerPageData={handlePerPageData}
          setPage={setPageNumber}
          perPageData={perPageData}
          rowsPerPage={perPageData}
          pageNumber={pageNumber}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
          sortingEntity={"ContactGroup"}
        />
      </Box>
    );
  };

  const getMailingListWrapper = () => (
    <Box sx={classes.mailingListWrapper}>
      <Box display={"flex"} sx={{ marginTop: "10px" }}>
        <FormControlLabel
          value="Groups"
          labelPlacement="start"
          sx={classes.checkboxWrapper}
          control={
            <Radio
              onChange={() => onChangeMailingList(strings.groupMailingListType)}
              checked={currentMailingList === strings.groupMailingListType}
              sx={classes.checkbox}
            />
          }
          label="Groups"
          id="mailing_list_group_checkbox"
        />
        <FormControlLabel
          value="Tags"
          labelPlacement="start"
          sx={classes.checkboxWrapper}
          control={
            <Radio
              onChange={() => {
                onChangeMailingList(strings.tagMailingListType);
              }}
              checked={currentMailingList === strings.tagMailingListType}
              sx={classes.checkbox}
            />
          }
          label="Tags"
          id="mailing_list_tags_checkbox"
        />
      </Box>

      <Box display={"flex"} alignItems={"center"} sx={{ marginTop: "10px" }}>
        {currentMailingList === strings.groupMailingListType && (
          <Select
            id="step2_groups_category_dropdown"
            sx={classes.dropDownStyle}
            displayEmpty
            value={selectedDropdownValue}
            onChange={handleDropdownOnchange}
          >
            {isManager &&
              campaignGroupOptionAdmin?.map((optionAdmin: any) => (
                <MenuItem
                  sx={classes.optionStyle}
                  key={optionAdmin.id}
                  value={optionAdmin.value}
                >
                  {optionAdmin.label}
                </MenuItem>
              ))}
            {!isManager &&
              campaignGroupOptionUser?.map((optionUser: any) => (
                <MenuItem
                  sx={classes.optionStyle}
                  key={optionUser.id}
                  value={optionUser.value}
                >
                  {optionUser.label}
                </MenuItem>
              ))}
          </Select>
        )}
        {currentMailingList === strings.groupMailingListType && (
          <FormControl>
            <Select
              id="groups_category_groupType"
              sx={{ ...classes.dropDownStyle, width: "150px" }}
              value={selectedGroupType}
              onChange={handleGroupTypeChange}
              displayEmpty
              renderValue={
                selectedGroupType !== ""
                  ? undefined
                  : () => " Select Group Type"
              }
            >
              {groupTypes.map((data) => (
                <MenuItem value={data} sx={classes.optionStyle}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Box ml={1} sx={classes.searchWrapper}>
          <TextField
            id="step2_groups_search_field"
            placeholder="Search text"
            sx={classes.searchInput}
            inputRef={searchRef}
            onChange={debounceEventHandler(
              handleSearchChange,
              strings.SEARCH_TIME_OUT
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Typography mt={2} sx={classes.mailingListHeading}>
        Select Mailing Audience
      </Typography>
      {getMailingListWrapper()}
      <Box mt={2}>
        <Typography>
          {getFormattedStatsCount(memberCount)} selected out of {""}
          {getFormattedStatsCount(allowedEmailCount)} available emails.
        </Typography>
      </Box>
      {tableContent()}
      <CustomLoader isLoading={isLoading || dataLoader} />
    </>
  );
};
