import { Box, Grid, Typography } from "@mui/material";
import IntegrationStyles from "screens/Integration/Integration.styles";

const IntegrationHeader = () => {
  const classes = IntegrationStyles;

  const header = () => {
    return (
      <>
        <Grid xs={12} sm={12} md={7} lg={12} xl={12} sx={{ mt: 3 }}>
          <Grid item xs={6} sm={6} md={2} lg={1} xl={1}>
            <Box sx={classes.headerWrapper}>
              <Typography sx={classes.headerTitle}>Integrations</Typography>
              <Typography sx={classes.headerSubTitle}>
                Say goodbye to manual data transfer and hello to seamless
                integrations!
                {/* Stay In Touch with Your Customer using social media platforms. */}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Grid sx={{ p: 3 }} xs={12} sm={12} lg={12} xl={12}>
      <Grid item>{header()}</Grid>
    </Grid>
  );
};
export default IntegrationHeader;
