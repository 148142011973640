import { Box, Typography } from "@mui/material";
import DeleteImg from "assets/images/DeleteImg.svg";
import { CustomButton, CustomDialog } from "global/components";
import templateStyle from "screens/Templates/Template.styles";

interface CustomProps {
  setOpenModel: Function;
  openModel: boolean;
  deleteTemplate: Function;
  deleteTemplateInfo: any;
}

const DeleteTemplateModel = (props: CustomProps) => {
  const classes = templateStyle;

  const handleCloseModel = () => {
    props.setOpenModel(false);
  };

  const dialogTitleContent = () => {
    return (
      <Box sx={classes.dialogTitleWrapper}>
        <Box sx={classes.titleRight}>Delete Template</Box>
      </Box>
    );
  };

  const dialogBody = () => (
    <Typography sx={classes.deleteGroupBodyStyle}>
      Are you sure you want to delete
      <Typography sx={classes.textBold}>
        {props.deleteTemplateInfo.TemplateName}
        <Box component="span" sx={classes.fontText}>
          template?
        </Box>
      </Typography>
    </Typography>
  );

  const dialogContent = () => {
    return (
      <Box sx={classes.dialogFooter}>
        <CustomButton
          label="Cancel"
          customClasses={classes.buttonWhiteBg}
          onClick={() => {
            props.setOpenModel(false);
          }}
          id="template_delete_Cancel_button"
        />
        <CustomButton
          label="Delete"
          onClick={() => {
            props.deleteTemplate(props.deleteTemplateInfo.templateId);
          }}
          id="template_delete_button"
        />
      </Box>
    );
  };
  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={DeleteImg} alt="delete Image" />
      </Box>
    );
  };

  return (
    <CustomDialog
      dialogHeaderContent={dialogHeaderContent()}
      isDialogOpen={props.openModel}
      closable
      closeButtonVisibility
      handleDialogClose={handleCloseModel}
      dialogTitleContent={dialogTitleContent()}
      dialogBodyContent={dialogBody()}
      dialogFooterContent={dialogContent()}
      width="450px"
    />
  );
};

export default DeleteTemplateModel;
