import React, { useEffect, useState } from "react";
import { fetchQRCodeForMFA, verifyMFACode } from "./SecurityServices";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import QRCode from "qrcode";
import { CustomButton, CustomDialog, CustomInput } from "global/components";
import {
  Box,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { handleValidate, handleValidateForDisableMFA } from "./SecurityHelpers";
import { useAppSelector } from "utils/hooks";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  getRelativeFontSize,
  primaryHeadingColor,
  regularFont,
} from "utils/styles";

const initialState = {
  code: {
    value: "",
    error: "",
  },
  password: {
    value: "",
    error: "",
  },
};

const ManageMFAModal = (props: {
  handleDialogClose: Function;
  manageMFA: boolean;
  mfaEnabled: boolean;
  updateMFAStatus: Function;
}) => {
  const [qr, setQR] = useState<string>();
  const [processing, setProcessing] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<{
    [key: string]: {
      value: string;
      error: string;
    };
  }>({
    code: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  });
  const [visibility, setVisibility] = useState(false);
  const { email } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (props.manageMFA) {
      makeQRCode();
    }
  }, [props.manageMFA]);

  const makeQRCode = async () => {
    try {
      const response = await fetchQRCodeForMFA();
      await generateQRCodeImage(response.message);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const generateQRCodeImage = async (url: string) => {
    try {
      const qrCodeImage = await QRCode.toDataURL(url);
      setQR(qrCodeImage);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const updateMFA = async () => {
    try {
      if (
        props.mfaEnabled
          ? handleValidateForDisableMFA(credentials, setCredentials)
          : handleValidate(credentials, setCredentials)
      ) {
        setProcessing(true);
        const response = await verifyMFACode(
          email,
          credentials.password.value,
          credentials.code.value,
          !props.mfaEnabled
        );
        if (response) {
          props.updateMFAStatus(!props.mfaEnabled);
          setCredentials(initialState);
        }
        props.updateMFAStatus(!props.mfaEnabled);
        openSuccessNotification(response.message);
        handleClose();
        setProcessing(false);
      }
    } catch (error: any) {
      setProcessing(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getBody = () => {
    return (
      <Stack direction="column" spacing={2} pt={2}>
        <Typography
          sx={{
            ...regularFont,
            fontSize: getRelativeFontSize(1),
            color: primaryHeadingColor,
          }}
        >
          {props.mfaEnabled
            ? "Please enter your password to disable the multi-factor authentication."
            : "To enable the multi-factor authentication, you need to scan this QR Code with your Authentication App and enter the verification code and your password below."}
        </Typography>
        {!props.mfaEnabled && (
          <Stack
            alignItems="center"
            sx={{ border: "0.5px solid #E9E9E9", borderRadius: "20px", py: 2 }}
          >
            <img src={qr} height={150} width={150} />
          </Stack>
        )}
        <Box>
          {!props.mfaEnabled && (
            <InputLabel
              required
              sx={{
                ...regularFont,
                fontSize: getRelativeFontSize(1),
              }}
            >
              Password
            </InputLabel>
          )}
          <CustomInput
            id="password"
            type={visibility ? "text" : "password"}
            placeHolder="Password"
            sx={{
              "& .MuiInputBase-input": { padding: "13px 13px" },
              "& input: : -ms-reveal": { display: "none" },
            }}
            onChange={(event: any) =>
              setCredentials({
                ...credentials,
                password: {
                  value: event.target.value,
                  error: "",
                },
              })
            }
            value={credentials.password.value}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => setVisibility(!visibility)}
                  sx={{ cursor: "pointer" }}
                >
                  <SvgIcon>
                    {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            error={
              credentials.password.value.length < 8
                ? credentials.password.error
                : null
            }
          />
        </Box>
        {!props.mfaEnabled && (
          <Stack spacing={1}>
            <InputLabel
              required
              sx={{
                ...regularFont,
                fontSize: getRelativeFontSize(1),
              }}
            >
              Verification Code
            </InputLabel>
            <MuiOtpInput
              length={6}
              onChange={(value: any) =>
                setCredentials({
                  ...credentials,
                  code: {
                    value: value.replace(/[^0-9]/g, ""),
                    error: "",
                  },
                })
              }
              sx={{
                "& .MuiFilledInput-input": {
                  p: "14px",
                },
              }}
              value={credentials.code.value}
            />
            {!isTruthy(credentials.code.value) && (
              <FormHelperText error sx={{ paddingLeft: "14px" }}>
                {credentials.code.error}
              </FormHelperText>
            )}
          </Stack>
        )}
      </Stack>
    );
  };

  const handleClose = () => {
    setCredentials({
      code: {
        value: "",
        error: "",
      },
      password: {
        value: "",
        error: "",
      },
    });
    props.handleDialogClose();
  };

  const getFooter = () => {
    return (
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <CustomButton
          onClick={updateMFA}
          disabled={processing}
          label={processing ? "Processing..." : "Save"}
          customClasses={{}}
        />
      </Box>
    );
  };

  return (
    <CustomDialog
      handleDialogClose={handleClose}
      isDialogOpen={props.manageMFA}
      dialogBodyContent={getBody()}
      dialogFooterContent={getFooter()}
      dialogTitleContent={
        <Typography variant="h5">Multi Factor Authentication</Typography>
      }
      closable
      closeButtonVisibility
      width={"400px"}
    />
  );
};

export default ManageMFAModal;
