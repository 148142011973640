import { Box, Typography, Button, Card } from "@mui/material";
import socialStyles from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/Social.styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import linkedinRound from "assets/icons/linkedin-round.png";
import linkedinLayout from "assets/icons/linkedin-layout.png";
import dotBlueIcon from "assets/icons/dot-blue.png";
import dotLightIcon from "assets/icons/dot-light.png";
import dotYellowIcon from "assets/icons/dot-yellow.png";
import linkedinLikeIcon from "assets/icons/linkedin-like.png";
import linkedinShareIcon from "assets/icons/linkedin-share.png";
import linkedinCommentIcon from "assets/icons/linkedin-comment.png";
import strings from "global/constants/StringConstants";
interface linkedInCustomProps {
  selectedAppsList?: string;
  isCardClickable: boolean;
  socialAppCardClickHandler?: Function;
  linkedInUserDetails?: any;
}
const LinkedInCard = (props: linkedInCustomProps) => {
  const classes = socialStyles;

  const onClickHandler = () => {
    if (props.isCardClickable) {
      props.socialAppCardClickHandler?.(strings.LINKEDIN);
    }
  };

  return (
    <Box sx={classes.socialCardContainer} onClick={onClickHandler}>
      <Card
        sx={[
          classes.socialCard,
          ...(props.selectedAppsList?.includes(strings.LINKEDIN)
            ? [classes.socialCardSelected]
            : []),
          ...(props.isCardClickable ? [classes.socialCardClickable] : []),
        ]}
      >
        <Box sx={classes.socialCardHeader}>
          <Box sx={classes.socialCardHeaderLeft}>
            <Box>
              <img src={linkedinRound} alt="fb-icon" />
            </Box>
            <Box>
              <Typography sx={classes.profileName}>Your Profile</Typography>
              <Typography sx={classes.postTime}>Business Associate</Typography>
              <Typography sx={classes.postTime}>2h</Typography>
            </Box>
          </Box>

          <Box>
            <MoreVertIcon />
          </Box>
        </Box>

        <Box sx={classes.socialCardLayout}>
          <img src={linkedinLayout} alt="images" />
        </Box>
        <Box sx={classes.socialCardFooter}>
          <Box sx={classes.footerLikes}>
            <Box sx={classes.userReactIcon}>
              <Box component="span" sx={classes.reactIcon}>
                <img src={dotBlueIcon} alt="icon" />
              </Box>
              <Box component="span" sx={classes.reactIcon}>
                <img src={dotLightIcon} alt="icon" />
              </Box>
              <Box component="span" sx={classes.reactIcon}>
                <img src={dotYellowIcon} alt="" />
              </Box>

              <Box
                component="span"
                style={{
                  marginLeft: "10px",
                  fontWeight: "100 !important",
                  fontSize: "14px",
                }}
              >
                600
              </Box>
            </Box>
          </Box>
          <Box sx={classes.linkedinFooterActions}>
            <Button sx={classes.linkedinActionBtn}>
              <img src={linkedinLikeIcon} alt="btnIcon" />
              Like
            </Button>

            <Button sx={classes.linkedinActionBtn}>
              <img src={linkedinCommentIcon} alt="btnIcon" />
              Comments
            </Button>

            <Button sx={classes.linkedinActionBtn}>
              <img src={linkedinShareIcon} alt="btnIcon" />
              Share
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default LinkedInCard;
