import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import viewIcon from "assets/images/view.svg";
import deleteIcon from "assets/images/Delete.svg";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import SocialCampaignsStyles from "screens/CampaignsNew/SocialCampaigns/SocialCampaigns.styles";
import {
  CustomAppHeader,
  CustomButton,
  CustomPaper,
  CustomTable,
} from "global/components";
import CustomTabs from "global/components/CustomTabs/CustomTabs";
import {
  SocialCampaignsTabContent,
  campaignerHeader,
  campaignerHeaderSubmitted,
  getInitialSocialCampaignFilterState,
} from "screens/CampaignsNew/SocialCampaigns/SocialCampaignsData";
import { useLocation } from "react-router-dom";
import {
  convertESTtoUserLocalDateAndTime,
  getSocialAppPostRedirection,
  handleIntervalChange,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
  validatePageQuery,
  validatePerPageQuery,
} from "helpers/methods";
import { validateSocialCampaignsStatusQuery } from "screens/CampaignsNew/SocialCampaigns/SocialCampaignsHelpers";
import { darkPurpledColor } from "utils/styles";
import CachedIcon from "@mui/icons-material/Cached";
import { useAppSelector } from "utils/hooks";
import {
  changeIntervalAction,
  selectCampaignRetentionDuration,
  selectInterval,
} from "redux/persistSlice";
import { useDispatch } from "react-redux";
import strings from "global/constants/StringConstants";
import notifiers from "global/constants/NotificationConstants";
import {
  deleteSocialCampaign,
  getSocialCampaignsByDates,
  getSocialCampaignsCountByDates,
} from "screens/CampaignsNew/SocialCampaigns/SocialCampaignsServices";
import moment from "moment";
import DeleteSocialCampaignModal from "screens/CampaignsNew/SocialCampaigns/components/DeleteSocialCampaignModal";
import { hasAccessTo } from "utils/AuthorizationManager";
import CustomDatePicker from "global/components/CustomDatePicker/CustomDatePicker";
import { getOptions } from "screens/Dashboard/DashboardData";
import { GlobalInterval, SocialCampaignsFilters } from "models/interfaces";

const SocialCampaigns = () => {
  const classes = SocialCampaignsStyles;

  const interval = useAppSelector<GlobalInterval>(selectInterval);
  const urlParams = new URLSearchParams(useLocation().search);
  const pageFromUrl = validatePageQuery(urlParams.get("page"));
  const perPageFromUrl = validatePerPageQuery(urlParams.get("perPageData"));
  const statusFromUrl = validateSocialCampaignsStatusQuery(
    urlParams.get("status")
  );
  const searchFromUrl = urlParams.get("search");
  const searchRef = useRef<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [headerData, setHeaderData] = useState(campaignerHeader);
  const [count, setCount] = useState<number>(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [socialCampaignsData, setSocialCampaignsData] = useState<any[]>([]);
  const [deleteSocialCampaignId, setDeleteSocialCampaignId] =
    useState<string>("");
  const [socialCampaignFilters, setSocialCampaignFilters] =
    useState<SocialCampaignsFilters>(
      getInitialSocialCampaignFilterState(
        pageFromUrl,
        perPageFromUrl,
        searchFromUrl ?? "",
        statusFromUrl,
        interval
      )
    );
  const campaignRetention = useAppSelector(selectCampaignRetentionDuration);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const hasSocialDeleteAccess = hasAccessTo(
    strings.social,
    strings.deletePermission
  );

  useEffect(() => {
    const { page, perPageData, search, status, interval } =
      socialCampaignFilters;
    // searchRef.current.value = search;
    window.history.replaceState(
      null,
      "",
      `?page=${page}&perPage=${perPageData}&status=${status}&search=${search}`
    );
    setTableHeader(status);
    getTableDataByDates();
  }, [socialCampaignFilters]);

  const getTableDataByDates = async () => {
    try {
      setIsLoading(true);
      const { page, perPageData, search, status, interval } =
        socialCampaignFilters;
      const [countValue, socialCampaignsData] = await Promise.all([
        getSocialCampaignsCountByDates(status, interval),
        getSocialCampaignsByDates(status, interval, page, perPageData),
      ]);
      setCount(countValue);
      tableDataViewHandler(socialCampaignsData);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getAppIcon = (appName: string) => {
    let appIcon: any;
    switch (appName) {
      case strings.FACEBOOK: {
        appIcon = <FacebookIcon />;
        break;
      }
      case strings.INSTAGRAM: {
        appIcon = <InstagramIcon />;
        break;
      }
    }
    return (
      <Box
        sx={{
          height: 16,
          width: 15,
          whiteSpace: "nowrap",
          mr: 1,
        }}
      >
        {appIcon}
      </Box>
    );
  };

  const deleteRequestBtn = (id: string) => {
    setDeleteSocialCampaignId(id);
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoading(true);
      const response = await deleteSocialCampaign(deleteSocialCampaignId);
      openSuccessNotification(
        response.errorCode ?? "Social campaign has been successfully deleted"
      );
      getTableDataByDates();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage)
          ? error.errorMessage
          : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
      setShowDeleteDialog(false);
    }
  };

  const showViewPopover = () => {
    return (
      <CustomPaper>
        <Box>
          <Box
            sx={{
              padding: "10px 10px",
              width: "100%",
            }}
          >
            <Typography>Click this button to view stats.</Typography>
          </Box>
        </Box>
      </CustomPaper>
    );
  };

  const getActions = (data: any) => {
    return (
      <>
        {data.status === strings.Submitted ? (
          hasSocialDeleteAccess && (
            <Button
              sx={classes.ActionBtn}
              onClick={() => deleteRequestBtn(data.id)}
            >
              <Box component={"img"} src={deleteIcon} /> <span>Delete</span>
            </Button>
          )
        ) : (
          <Tooltip
            title={showViewPopover()}
            arrow
            placement="left"
            componentsProps={{
              tooltip: {
                sx: {
                  background: "none",
                },
              },
            }}
          >
            <Button
              sx={classes.ActionBtn}
              onClick={() => {
                getSocialAppPostRedirection(data);
              }}
            >
              <Box component={"img"} src={viewIcon} /> <span>View</span>
            </Button>
          </Tooltip>
        )}
      </>
    );
  };

  const tableDataViewHandler = (data: any) => {
    const tableData = data.map((SocialCampaign: any) => {
      return {
        SocialCampaignName: (
          <Typography sx={classes.campaignNameStyle}>
            {SocialCampaign.name}
          </Typography>
        ),
        PageName: SocialCampaign.socialSource,
        AppIcon: getAppIcon(SocialCampaign.socialAppName),
        SubmittedBy: <Typography>{SocialCampaign.owner}</Typography>,
        SentOn: convertESTtoUserLocalDateAndTime(
          SocialCampaign.status === strings.Submitted
            ? SocialCampaign.scheduleTime
            : SocialCampaign.createdOn,
          "MMM DD, YYYY"
        ),
        SocialCampaignStats: getActions(SocialCampaign),
      };
    });
    setSocialCampaignsData(tableData);
  };

  const handleTabs = (value: string) => {
    setSocialCampaignFilters({
      ...socialCampaignFilters,
      status: value,
      search: "",
      page: 1,
    });
    setTableHeader(value);
  };

  const setTableHeader = (value: string) => {
    switch (value) {
      case strings.Completed:
        setHeaderData(campaignerHeader);
        break;
      case strings.Submitted:
        setHeaderData(campaignerHeaderSubmitted);
        break;
      default:
        break;
    }
  };

  const setIntervalInSocialCampaignFilter = (newInterval: GlobalInterval) => {
    setSocialCampaignFilters({
      ...socialCampaignFilters,
      interval: { ...newInterval },
      page: 1,
    });
  };

  const handlePerPageData = (event: any) => {
    const perPageData = event.target.value;
    setSocialCampaignFilters({
      ...socialCampaignFilters,
      perPageData,
      page: 1,
    });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setSocialCampaignFilters({
      ...socialCampaignFilters,
      page,
    });
  };

  const socialCampaignsHeaderContent = () => {
    return (
      <>
        <Box sx={{ padding: "45px 10px 10px 25px" }}>
          <Typography sx={classes.pageTitle}>Social Campaigns</Typography>
        </Box>
        <Stack
          direction={{ lg: "row", md: "column", sm: "column", xs: "column" }}
          sx={{ padding: "0 20px 20px 20px", justifyContent: "space-between" }}
        >
          <CustomTabs
            containerId="social_campaigns_status_tab"
            changeValue={(newValue: string) => {
              handleTabs(newValue);
            }}
            selected={socialCampaignFilters.status}
            tabConfig={SocialCampaignsTabContent}
          />
          <Stack direction={{ lg: "row", md: "row", sm: "row", xs: "column" }}>
            <Box sx={classes.dateBox}>
              <Typography sx={classes.date}>
                {moment(interval.fromDate).format("LL")} to{" "}
                {moment(interval.toDate).format("LL")}
              </Typography>
            </Box>
            <Box
              sx={{
                margin: "5px",
              }}
            >
              <CustomButton
                id="social_campaigns_refresh_button"
                onClick={getTableDataByDates}
                label={<CachedIcon htmlColor={darkPurpledColor} />}
                customClasses={classes.refreshBtn}
              />
            </Box>
            <Box
              sx={{
                margin: "5px",
              }}
            >
              <Select
                id="social_campaigns_interval_dropdown"
                sx={classes.dropDownStyle}
                value={interval.label}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {getOptions(+campaignRetention)?.map((intervalData) => (
                  <MenuItem
                    value={intervalData.label}
                    sx={classes.optionStyle}
                    onClick={() =>
                      handleIntervalChange(
                        intervalData.label,
                        setIsDatePickerOpen,
                        setIntervalInSocialCampaignFilter
                      )
                    }
                  >
                    {intervalData.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {/* <Box sx={classes.searchBox}>
              <TextField
                id="social_campaigns_search_field"
                placeholder="Search text"
                sx={classes.searchInput}
                inputRef={searchRef}
                onChange={debounceEventHandler(
                  handleSearchOnChange,
                  strings.SEARCH_TIME_OUT
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box> */}
          </Stack>
        </Stack>
      </>
    );
  };

  const SocialCampaignsTable = () => {
    return (
      <>
        <Box
          id="campaigns_table"
          sx={{ minWidth: "300px", width: "100%", overflow: "auto" }}
        >
          <CustomTable
            headers={headerData}
            rows={socialCampaignsData}
            handlePageChange={handleChangePage}
            paginationCount={count}
            pageNumber={socialCampaignFilters.page}
            isLoading={isLoading}
            handlePerPageData={handlePerPageData}
            perPageData={socialCampaignFilters.perPageData}
            rowsPerPage={socialCampaignFilters.perPageData}
          />
        </Box>
      </>
    );
  };

  const customDeleteDialog = () => {
    return (
      <DeleteSocialCampaignModal
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    );
  };

  return (
    <>
      <CustomAppHeader>{socialCampaignsHeaderContent()}</CustomAppHeader>
      <Box sx={classes.paperInnerWrapper}>
        {SocialCampaignsTable()}
        {customDeleteDialog()}
      </Box>
      {isDatePickerOpen && (
        <CustomDatePicker
          pastMonths={+campaignRetention}
          isDatePickerOpen={isDatePickerOpen}
          setIsDatePickerOpen={setIsDatePickerOpen}
          callBackFunction={setIntervalInSocialCampaignFilter}
        />
      )}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default SocialCampaigns;
