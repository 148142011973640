import { Box, Button, SxProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import customButtonStyles from "global/components/CustomButton/CustomButton.styles";
import clsx from "clsx";

interface CustomProps {
  id?: string;
  label?: string | JSX.Element;
  onClick: Function;
  loading?: boolean;
  customClasses?: any;
  icon?: JSX.Element;
  disabled?: boolean;
  endIcon?: JSX.Element;
}

const CustomButton = (props: CustomProps) => {
  const classes = customButtonStyles;
  const appliedClass = props.customClasses;

  const processing = props.loading ? props.loading : false;
  const disabled = props.disabled ? props.disabled : false;

  return (
    <Button
      {...(props.id && { id: props.id })}
      startIcon={props.icon}
      sx={
        props.customClasses
          ? [classes.btnStyle, props.customClasses]
          : classes.btnStyle
      }
      onClick={(event: any) => props.onClick(event)}
      disabled={processing || disabled}
      endIcon={props.endIcon}
    >
      {processing ? (
        <CircularProgress sx={classes.loadingStyle} />
      ) : (
        props.label
      )}
    </Button>
  );
};

export default CustomButton;
