import {
  boldFont,
  centerItemFlex,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  pinkDarkColor,
  regularFont,
  theme,
} from "utils/styles";

const loginStyles = {
  select: {
    borderRadius: "20px",
    border: "1px solid black",
    width: "100% !important",
  },
  error: {
    borderRadius: "8px",
    outline: "none",
    borderColor: "red !important",
    width: "100% !important",
  },
  label: { display: "flex", height: "20px", marginTop: "30px" },
  labelIcon: { color: "black" },
  labelText: {
    marginLeft: "6px",
    ...boldFont,
  },
  formCenter: {
    ...centerItemFlex,
  },
  headingCenter: {
    ...mediumFont,
    fontSize: getRelativeFontSize(),
    mt: 2,
  },
  joinMailzzyHeadingCenter: {
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    mt: 2,
    ...centerItemFlex,
    color: pinkDarkColor,
  },
  textRadious: {
    borderRadius: "100px",
  },
  getHeading: {
    ...boldFont,
    fontSize: getRelativeFontSize(14),
    mx: 4,
    mt: 8,
  },
  authenticatorDescription: {
    ...mediumFont,
    fontSize: getRelativeFontSize(0),
    mx: 4,
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    fontSize: getRelativeFontSize(5),
    ...boldFont,
  },
  center: {
    ...boldFont,
    alignItem: "center",
  },
  getLoginScreen: {
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      mt: 10,
    },
  },
  forgetPasswordWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    mt: 2,
  },
  signBtn: {
    width: "100%",
  },
  errorStyling: {
    paddingLeft: "10px",
  },
  backToLogin: {
    width: "100%",
    cursor: "pointer",
    ...mediumFont,
    fontSize: getRelativeFontSize(),
    ...centerItemFlex,
  },
  OTPHelperMessage: {
    mt: 2,
    mx: 4,
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    textAlign: "justify",
  },
  otpInput: {
    "& .MuiFilledInput-input": {
      p: "14px",
    },
    "& .MuiOutlinedInput-root": {
      background: "#FFFFFF",
    },
  },
  recaptchaDisclaimer: {
    ...regularFont,
    fontSize: getRelativeFontSize(-2),
    mt: 1,
  },
} as const;

export default loginStyles;
