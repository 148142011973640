import history from "utils/history";
import { BrowserRouter, Router, Switch, Route } from "react-router-dom";
import urls from "global/constants/UrlConstants";
import withClearCache from "ClearCache";
import Box from "@mui/system/Box";
import Notifications from "utils/notifications";
import LandingPage from "screens/LandingPage/LandingPage";
import Layout from "screens/Shared/Layout/Layout";
import PageNotFound from "screens/PageNotFound/PageNotFound";
import RegistrationSuccess from "screens/LandingPage/components/RegistrationSuccess";
import OnboardNew from "screens/Onboarding/OnboardNew";

const App = () => {
  return <ClearCacheComponent />;
};

const MainApp = () => {
  return (
    <Box>
      <BrowserRouter
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
      >
        <Router history={history}>
          <Switch>
            <Route
              exact
              path={[
                urls.landingViewPath,
                urls.loginViewPath,
                urls.forgotPasswordViewPath,
                urls.registerViewPath,
                urls.resetPasswordViewPath,
                urls.activateUserViewPath,
                urls.verifyTOTPViewPath,
                urls.setupMFAViewPath,
              ]}
              component={() => <LandingPage />}
            />
            <Route
              exact
              path={urls.registrationSuccessViewPath}
              component={RegistrationSuccess}
            />
            <Route exact path={urls.onBoardViewPath} component={OnboardNew} />

            <Layout />
            <Route path={""} component={PageNotFound} />
          </Switch>
          <Notifications />
        </Router>
      </BrowserRouter>
    </Box>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

export default App;
