import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import strings from "global/constants/StringConstants";
import { GlobalInterval } from "models/interfaces";
import moment from "moment";
import { RootState } from "utils/store";

export interface persistState {
  isFirstNameMandatory: boolean;
  integrationAppName: string;
  interval: GlobalInterval;
  facebookPageName: string;
  facebookPageId: number | undefined;
  campaignRetentionDuration: string;
}

const initialState: persistState = {
  isFirstNameMandatory: true,
  integrationAppName: "",
  interval: {
    label: strings.thisWeek,
    fromDate: moment().startOf("isoWeek").format("MM-DD-yyyy"),
    toDate: moment().format("MM-DD-yyyy"),
  },
  facebookPageName: "",
  facebookPageId: undefined,
  campaignRetentionDuration: "1",
};

export const persistSlice = createSlice({
  name: "persist",
  initialState,
  reducers: {
    changeIsFirstNameMandatory: (state, action: PayloadAction<boolean>) => {
      state.isFirstNameMandatory = action.payload;
    },
    changeIntegrationAppNameAction: (state, action: PayloadAction<string>) => {
      state.integrationAppName = action.payload;
    },
    changeIntervalAction: (state, action: PayloadAction<GlobalInterval>) => {
      state.interval = action.payload;
    },
    campaignRetentionDuration: (state, action: PayloadAction<string>) => {
      state.campaignRetentionDuration = action.payload;
    },
    changeFacebookPageAction: (
      state,
      action: PayloadAction<{
        facebookPageName: string;
        facebookPageId: number | undefined;
      }>
    ) => {
      state.facebookPageName = action.payload.facebookPageName;
      state.facebookPageId = action.payload.facebookPageId;
    },
    clearPersistenceAction: (state, action: {}) => {
      state.isFirstNameMandatory = true;
      state.integrationAppName = "";
      state.interval = {
        label: strings.thisWeek,
        fromDate: moment().startOf("isoWeek").format("MM-DD-yyyy"),
        toDate: moment().format("MM-DD-yyyy"),
      };
      state.facebookPageName = "";
      state.facebookPageId = undefined;
      state.campaignRetentionDuration = "1";
    },
  },
});

export const {
  changeIsFirstNameMandatory,
  changeIntegrationAppNameAction,
  changeIntervalAction,
  changeFacebookPageAction,
  campaignRetentionDuration,
  clearPersistenceAction,
} = persistSlice.actions;

export const selectIsFirstNameMandatory = (state: RootState) =>
  state.persist.isFirstNameMandatory;
export const selectIntegrationAppName = (state: RootState) =>
  state.persist.integrationAppName;
export const selectInterval = (state: RootState) => state.persist.interval;
export const selectFacebookPageName = (state: RootState) =>
  state.persist.facebookPageName;
export const selectFacebookPageId = (state: RootState) =>
  state.persist.facebookPageId;
export const selectCampaignRetentionDuration = (state: RootState) =>
  state.persist.campaignRetentionDuration;

export default persistSlice.reducer;
