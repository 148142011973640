const customDropzoneStyles = {
  dropzone: {
    border: "2px dashed #ccc",
    borderRadius: "13px",
    padding: "20px",
    textAlign: "center",
    transition: "border-color 0.3s",
  },

  dragover: {
    borderColor: "#aaa",
  },
};

export default customDropzoneStyles;
