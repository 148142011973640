import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, InputLabel } from "@mui/material";
import { CustomButton, CustomDialog } from "global/components";
import {
  centerItemFlex,
  getRelativeFontSize,
  inputLabelRequiredColor,
  boldFont,
} from "utils/styles";
import campaignDeleteModal from "assets/images/campaignDeleteModal.svg";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { uploadContactGroupsCsvParsing } from "screens/CampaignGroups/CampaignGroups.service";
import CustomAutoComplete from "global/components/CustomAutoComplete/CustomAutoComplete";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { useAppSelector } from "utils/hooks";
import { selectIsFirstNameMandatory } from "redux/persistSlice";
import uploadGroupStyles from "global/components/UploadGroups/UploadGroups.styles";
import { isTrial } from "redux/authSlice";

interface AddContactGroupWithCsvProps {
  isOpenCsvModal: any;
  setIsOpenCsvModal: any;
  formFields: any;
  handleDialogClose?: any;
  handleValidation?: any;
  setFormFields?: any;
  setColumnMatch?: any;
  uploadContactMailingList?: any;
}

const AddContactGroupWithCsv = ({
  isOpenCsvModal,
  setIsOpenCsvModal,
  formFields,
  handleDialogClose,
  setColumnMatch,
  setFormFields,
  uploadContactMailingList,
}: AddContactGroupWithCsvProps) => {
  const classes = uploadGroupStyles;
  const [loading, setLoading] = useState(false);
  const isTrialAccount = useAppSelector(isTrial);
  const isFirstNameMandatory = useAppSelector(selectIsFirstNameMandatory);
  const [csvColumn, setCsvColumn] = useState<
    {
      name: string;
      field: string;
      type: string;
      fieldMapping: string;
      required: boolean;
      error: string;
    }[]
  >([
    {
      name: "Email",
      field: "emailId",
      type: "String",
      fieldMapping: "",
      required: true,
      error: "",
    },
    {
      name: "First Name",
      field: "firstName",
      type: "String",
      fieldMapping: "",
      required: isFirstNameMandatory,
      error: "",
    },
    {
      name: "Last Name",
      field: "lastName",
      type: "String",
      fieldMapping: "",
      required: false,
      error: "",
    },
    {
      name: "Contact No",
      field: "contactNo",
      type: "String",
      fieldMapping: "",
      required: false,
      error: "",
    },
    {
      name: "Tags",
      field: "tagName",
      type: "String",
      fieldMapping: "",
      required: false,
      error: "",
    },
  ]);

  const columnTypeData = useMemo(() => {
    return (
      Object.entries(isOpenCsvModal.csvData).map(([key, value]) => ({
        key,
        value,
      })) || []
    );
  }, [isOpenCsvModal.csvData]);

  useEffect(() => {
    setCsvColumn(
      csvColumn.map((item) => ({ ...item, fieldMapping: "", error: "" }))
    );
    setFormFields({
      ...formFields,
      groupCSV: { ...formFields.groupCSV, value: {} },
    });
    setColumnMatch(false);
  }, [isOpenCsvModal]);

  const handleOnChange = (event: any, newValue: any) => {
    const name = event.target.id.split("-")[0];
    setCsvColumn((prev: any) =>
      prev.map((item: any) => {
        if (item.field === name) {
          item.fieldMapping = isTruthy(newValue) ? newValue.label : "";
          item.error = "";
        }
        return item;
      })
    );
  };

  const validate = () => {
    let isValid = true;
    csvColumn.forEach((item: any) => {
      if (item.required && !isTruthy(item.fieldMapping)) {
        setCsvColumn((prev: any) =>
          prev.map((data: any) => {
            if (data.field === item.field) {
              data.error = `Please select ${item.name}!`;
            }
            return data;
          })
        );
        isValid = false;
      }
    });
    return isValid;
  };

  const toBase64 = () =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(formFields?.groupCSV?.value.file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleOnSave = async () => {
    if (validate()) {
      try {
        await toBase64();
        setLoading(true);
        const contactGroupUpload: any = {};
        csvColumn.forEach((item: any) => {
          if (isTruthy(item.fieldMapping)) {
            contactGroupUpload[item.field] = item.fieldMapping;
          }
        });
        const formData = new FormData();
        formData.append("file", formFields?.groupCSV?.value.file);
        formData.append("groupName", formFields.groupName.value.trim());
        formData.append("columnMapperJson", JSON.stringify(contactGroupUpload));
        const res = await uploadContactGroupsCsvParsing(
          formFields.groupType.value,
          formData
        );
        openSuccessNotification(res.message);
        closeConfirmModel();
        handleDialogClose();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
      }
    }
  };

  const largeUpload = async () => {
    const contactGroupUpload: any = {};
    csvColumn.forEach((item: any) => {
      if (isTruthy(item.fieldMapping)) {
        contactGroupUpload[item.fieldMapping] = item.field;
      }
    });
    if (validate()) {
      try {
        setLoading(true);
        await uploadContactMailingList(true, contactGroupUpload);
        closeConfirmModel();
        handleDialogClose();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
      }
    }
  };

  const closeConfirmModel = () => {
    setIsOpenCsvModal({ ...isOpenCsvModal, isOpen: false, csvData: {} });
  };

  const confirmDeleteModelFooter = () => {
    return (
      <Grid
        container
        sx={{
          ...centerItemFlex,
          paddingBottom: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            ...centerItemFlex,
            width: "100%",
            justifyContent: "center",
            "& button": {
              width: "120px",
            },
          }}
        >
          <CustomButton
            customClasses={{
              color: "#212121 !important",
              backgroundColor: "#FFFFFF",
              border: "1px solid #E7E7E7",
              "&:hover": {
                background: "none",
              },
            }}
            label="Cancel"
            onClick={closeConfirmModel}
            id="campaign_group_csv_cancel_button"
          />
          <CustomButton
            label="Upload"
            onClick={handleOnSave}
            loading={loading}
            id="campaign_group_csv_upload_button"
          />
          {!isTrialAccount && (
            <CustomButton
              id="campaign_group_async_upload_button"
              label="Large Upload"
              onClick={largeUpload}
              loading={loading}
              customClasses={classes.largeUploadButton}
            />
          )}
        </Box>
      </Grid>
    );
  };

  const confirmModelSubTitle = () => (
    <Box>
      <Grid container mt={3}>
        {csvColumn.map((item) => (
          <React.Fragment key={item.field}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} mb={3} spacing={1}>
              <Box mt={2} gap={4}>
                <InputLabel
                  id="state"
                  required={item.required}
                  sx={{
                    ...boldFont,
                    fontSize: getRelativeFontSize(3),
                    color: "#202730",
                    textTransform: "capitalize",
                    "& .MuiFormLabel-asterisk": {
                      color: inputLabelRequiredColor,
                    },
                  }}
                >
                  {item.name}
                </InputLabel>
              </Box>
            </Grid>
            <Grid container xs={12} sm={12} md={9} lg={9} xl={9} spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
                <CustomAutoComplete
                  id={item.field}
                  name={item.field}
                  options={columnTypeData.map((column) => ({
                    label: column.key,
                  }))}
                  value={item.fieldMapping}
                  onChange={handleOnChange}
                  placeHolder={`Select ${item.name}`}
                  clearIcon={false}
                  errorMessage={
                    item.required && !isTruthy(item.fieldMapping)
                      ? item.error
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );

  const addUserHeaderContent = () => (
    <Box display={"flex"}>
      <img src={campaignDeleteModal} alt="image not found" />
    </Box>
  );

  return (
    <>
      <CustomDialog
        isDialogOpen={!!isOpenCsvModal.isOpen}
        closable
        closeButtonVisibility
        width="450px"
        dialogHeaderContent={addUserHeaderContent()}
        dialogBodyContent={confirmModelSubTitle()}
        dialogFooterContent={confirmDeleteModelFooter()}
        handleDialogClose={closeConfirmModel}
      />
      <CustomLoader isLoading={loading} />
    </>
  );
};

export default React.memo(AddContactGroupWithCsv);
