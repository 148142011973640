import {
  getRelativeFontSize,
  primaryHeadingColor,
  theme,
  boldFont,
  regularFont,
  inputLabelRequiredColor,
  pureWhiteColor,
  purpleThemedSelectComponent,
  pinkThemedMenuItems,
} from "utils/styles";

const StepOneStyles = {
  headerSection: {
    padding: "40px 20px 18px 20px",
  },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  },
  mainContentBox: {
    padding: theme.spacing(4),
  },
  pageDescription: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
  },
  inputLabel: {
    display: "flex",
    color: "#212121",
    fontSize: getRelativeFontSize(7),
    fontColor: theme.palette.common.black + " !important",
    variant: "standard",
    ...boldFont,
    "& .MuiTextField-root": {
      color: "red",
    },
    "& .MuiInputLabel-root ": {
      color: "red",
    },
    "&:focus": {
      color: "red",
    },
    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: "red",
    },
  },
  star: { color: inputLabelRequiredColor },
  selectDropdownStyle: {
    ...regularFont,
    backgroundColor: pureWhiteColor,
    height: "47px",
    padding: "2px",
    width: "100%",
    borderRadius: "12px",
    fontSize: "16px",
    ...purpleThemedSelectComponent,
  },
  selectOptionStyle: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  errorMessageStyle: {
    paddingLeft: "15px",
  },
} as const;

export default StepOneStyles;
