import { Box, Chip, Typography } from "@mui/material";
import { NewCustomButton } from "global/components";
import { useState } from "react";
import CampaignDetailsSectionStyles from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/campaignDetailsSection/CampaignDetailsSection.styles";
import AttachmentDropzone from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/AttachmentDropzone";

interface CustomProps {
  index: number;
  setIsLoading: Function;
  mainCampaignDataArray: any[];
  setMainCampaignDataArray: Function;
  attachmentFileSizeArray: any[];
  setAttachmentFileSize: Function;
  totalAttachmentSizeArray: number[];
  setTotalAttachmentSize: Function;
}

const AttachmentsSection = (props: CustomProps) => {
  const classes = CampaignDetailsSectionStyles;
  const attachmentNames = Object.keys(
    props.mainCampaignDataArray[props.index].attachments
  );
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const editModeView = () => {
    return (
      <>
        <Box mb={1} sx={classes.viewModeBox}>
          <Box>
            <Typography sx={classes.headerLabel}>Attachments</Typography>
            <Typography sx={classes.headerSubDescription}>
              Do you want to add attachments to this email?
            </Typography>
          </Box>
          <Box>
            <NewCustomButton
              id={"to_edit_close_button"}
              label={"Close"}
              customClasses={classes.buttonStyles}
              onClick={() => setIsEditMode(false)}
            />
          </Box>
        </Box>
        <AttachmentDropzone
          index={props.index}
          setIsLoading={props.setIsLoading}
          mainCampaignDataArray={props.mainCampaignDataArray}
          setMainCampaignDataArray={props.setMainCampaignDataArray}
          attachmentFileSizeArray={props.attachmentFileSizeArray}
          setAttachmentFileSize={props.setAttachmentFileSize}
          totalAttachmentSizeArray={props.totalAttachmentSizeArray}
          setTotalAttachmentSize={props.setTotalAttachmentSize}
        />
      </>
    );
  };

  const viewModeView = () => {
    return (
      <Box mb={1} sx={classes.viewModeBox}>
        <Box>
          <Typography sx={classes.headerLabel}>Attachments</Typography>
          <Box sx={classes.headerSubDescription}>
            {attachmentNames
              ?.filter(
                (item: any, index: any) =>
                  attachmentNames.indexOf(item) === index
              )
              ?.map((name: string) => {
                return (
                  <Chip
                    key={name}
                    label={
                      <Typography
                        noWrap
                        sx={{
                          maxWidth: {
                            xs: "100px",
                            sm: "150px",
                            md: "150px",
                            lg: 300,
                            xl: 600,
                          },
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {name}
                      </Typography>
                    }
                    variant="filled"
                  />
                );
              })}
          </Box>
        </Box>
        <Box>
          <NewCustomButton
            id="attachments_edit_button"
            label="Edit Attachments"
            customClasses={{ width: "135px" }}
            onClick={() => setIsEditMode(true)}
          />
        </Box>
      </Box>
    );
  };

  return <Box>{isEditMode ? editModeView() : viewModeView()}</Box>;
};

export default AttachmentsSection;
