import { useEffect, useState } from "react";
import Network from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/Network";
import Design from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/Design";
import {
  getUserDetailToFB,
  getIntegratedAppNames,
} from "screens/Integration/component/FacebookService";
import { isTruthy, openErrorNotification } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import SocialMediaIntegrationModel from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/SocialMediaIntegrationModel";
import { useTitle } from "utils/UseTitle";
import strings from "global/constants/StringConstants";
import { useDispatch } from "react-redux";
import {
  changeFacebookPageAction,
  selectFacebookPageId,
} from "redux/persistSlice";
import { useAppSelector } from "utils/hooks";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import {
  instagramAccountInitialState,
  postFeedFaceBook,
} from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/SocialTypesAndValidations";

const Social = () => {
  useTitle(strings.Social);
  const dispatch = useDispatch();
  const facebookPageId = useAppSelector(selectFacebookPageId);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAppsList, setSelectedAppsList] = useState<string>("");
  const [facebookUserDetail, setFacebookUserDetail] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [linkedInUserDetails, setLinkedInUserDetails] = useState<any>();
  const [isFacebookIntegrated, setIsFacebookIntegrated] = useState(false);
  const [loadingLoggedIn, setLoadingLoggedIn] = useState<boolean>(false);
  const [postData, setPostData] = useState(postFeedFaceBook);
  const [instagramAccountData, setInstagramAccountData] = useState<any>(
    instagramAccountInitialState
  );
  const [postImage, setPostImage] = useState<string[]>([]);

  useEffect(() => {
    if (isFacebookIntegrated) {
      getFacebookUserDetail();
      // fetchLinkedInUser();
    }
  }, [isFacebookIntegrated]);

  useEffect(() => {
    areAppsIntegrated();
  }, []);

  const setAppIntegrationFlag = (
    appName: string,
    activeAppArray: any[],
    setState: Function
  ) => {
    setState(
      activeAppArray?.some((item: any) => item === appName)
    );
  };

  const areAppsIntegrated = async () => {
    try {
      setLoadingLoggedIn(true);
      const isActive = await getIntegratedAppNames();
      setAppIntegrationFlag(
        strings.FACEBOOK,
        isActive,
        setIsFacebookIntegrated
      );
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setLoadingLoggedIn(false);
    }
  };

  const getFacebookUserDetail = async () => {
    try {
      setIsLoading(true);
      const responseUserDetails = await getUserDetailToFB(strings.FACEBOOK);
      const isPageIdPresent = responseUserDetails?.metaData?.pageDetails?.some(
        (item: any) => item.id === facebookPageId
      );
      //Checking Condition Array is  Empty Or Not
      if (Array.isArray(responseUserDetails?.metaData?.pageDetails) && !isPageIdPresent) {
        const firstPageName = responseUserDetails?.metaData?.pageDetails.map(
          (item: any) => item.name
        );
        const firstPageId = responseUserDetails.metaData.pageDetails.find(
          (item: any) => item.name === firstPageName[0]
        );
        dispatch(
          changeFacebookPageAction({
            facebookPageName: firstPageName[0],
            facebookPageId: firstPageId?.id,
          })
        );
      }
      // }
      setFacebookUserDetail(responseUserDetails);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  // const fetchLinkedInUser = async () => {
  //   try {
  //     const response = await linkedinUserDetail();
  //     setLinkedInUserDetails(response);
  //   } catch (error: any) {
  //     console.log(error);
  //   }
  // };

  const handleSaveContinueBtn = () => {
    if (selectedAppsList.length > 0) {
      setActiveStep((prevStep: number) => prevStep + 1);
    } else {
      openErrorNotification("Please select any social app");
    }
  };

  const socialAppCardClickHandler = (appName: string) => {
    setSelectedAppsList(appName);
  };

  const getSocialContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Network
            isLoading={isLoading}
            socialAppCardClickHandler={socialAppCardClickHandler}
            selectedAppsList={selectedAppsList}
            facebookPage={facebookUserDetail}
            linkedInUserDetails={linkedInUserDetails}
            handleSaveContinueBtn={handleSaveContinueBtn}
          />
        );
      case 1:
        return (
          <Design
            selectedAppsList={selectedAppsList}
            setActiveStep={setActiveStep}
            faceBookPage={facebookUserDetail}
            linkedInUserDetails={linkedInUserDetails}
            postData={postData}
            setPostData={setPostData}
            instagramAccountData={instagramAccountData}
            setInstagramAccountData={setInstagramAccountData}
            postImage={postImage}
            setPostImage={setPostImage}
            setIsLoading={setIsLoading}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {isFacebookIntegrated ? (
        getSocialContent()
      ) : (
        <SocialMediaIntegrationModel isOpen={loadingLoggedIn} />
      )}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default Social;
