import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  convertResourceToObjectFormat,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import {
  login,
  loginUsingPasskey,
} from "screens/LandingPage/landingPageService";
import urls from "global/constants/UrlConstants";
import notifiers from "global/constants/NotificationConstants";
import { useAppDispatch } from "utils/hooks";
import loginStyles from "screens/LandingPage/components/Login.styles";
import { loginAction } from "redux/authSlice";
import history from "utils/history";
import { mediumFont, theme } from "utils/styles";
import { CustomButton, CustomInput } from "global/components";
import {
  loginForm,
  loginValidation,
} from "screens/LandingPage/components/LoginTypesAndValidation";
import strings from "global/constants/StringConstants";
import hidePasswordIcon from "assets/images/Hide.svg";
import showPasswordIcon from "assets/images/Show.svg";
import { useTitle } from "utils/UseTitle";
import _ from "lodash";
import {
  changeFacebookPageAction,
  changeIntegrationAppNameAction,
  changeIntervalAction,
  changeIsFirstNameMandatory,
} from "redux/persistSlice";
import { getCredentialOptions } from "screens/Settings/PassKeys/PasskeyService";
import moment from "moment";

interface CustomProps {
  executeRecaptcha: any;
}

const Login = React.forwardRef((props: CustomProps, ref: any) => {
  useTitle(strings.LoginTitle);
  const classes = loginStyles;
  const emailRegex = strings.regex;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState({
    login: false,
    loginUsingPassKey: false,
  });
  const [formFields, setFormFields] = useState(loginForm);
  const [showPassword, setShowPassword] = useState(false);
  const [isPasskeyAvailable, setIsPasskeyAvailable] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    checkPasskeyAvailability();
  }, []);

  useEffect(() => {
    const host = window.location.hostname;
    if (host.includes("demo")) {
      setFormFields({
        email: {
          value: "mailzzy.demo@softsages.com",
          error: "",
        },
        password: {
          value: "ssmaildemo@1234",
          error: "",
        },
      });
    }
  }, []);

  const checkPasskeyAvailability = async () => {
    const isUserVerifyingAvailable =
      await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    setIsPasskeyAvailable(isUserVerifyingAvailable);
  };

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleLogin = async () => {
    try {
      if (handleValidation()) {
        const email = formFields.email.value.toLowerCase();
        const password = formFields.password.value;
        let captchaToken = "";
        // if (urls.PROD) {
        captchaToken = await props.executeRecaptcha();
        // }
        setIsLoading((prev: any) => ({
          ...prev,
          login: true,
        }));
        const user = await login(email, password, captchaToken);
        if (user.multiFactorLogin) {
          history.push({
            pathname: urls.verifyTOTPViewPath,
            state: {
              email: user.email,
            },
          });
          return;
        }
        if (user.mfaSetupRequired) {
          history.push({
            pathname: urls.setupMFAViewPath,
            state: {
              email: user.email,
              password: formFields.password.value,
              token: user.authToken,
            },
          });
          return;
        }
        const formattedResources = convertResourceToObjectFormat(
          user.resources
        );
        dispatch(changeIsFirstNameMandatory(true));
        dispatch(
          changeIntervalAction({
            label: strings.thisWeek,
            fromDate: moment().startOf("isoWeek").format("MM-DD-yyyy"),
            toDate: moment().format("MM-DD-yyyy"),
          })
        );
        dispatch(
          loginAction({
            email,
            authenticated: true,
            accessToken: user.authToken,
            userName: user.name,
            role: user.role,
            resources: formattedResources,
            account: user.account,
            isTrial: user.trial,
          })
        );
        dispatch(
          changeFacebookPageAction({
            facebookPageName: "",
            facebookPageId: undefined,
          })
        );
        dispatch(changeIntegrationAppNameAction(""));
        setIsLoading((prev: any) => ({
          ...prev,
          login: false,
        }));
        history.push(urls.dashboardViewPath);
      }
    } catch (error: any) {
      setIsLoading((prev: any) => ({
        ...prev,
        login: false,
      }));
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.LOGIN_ERROR
      );
    } finally {
      setIsLoading((prev: any) => ({
        ...prev,
        loginUsingPassKey: false,
      }));
    }
  };

  const handleLoginUsingPasskey = async () => {
    try {
      setIsLoading((prev: any) => ({
        ...prev,
        loginUsingPassKey: true,
      }));
      const user = await getCredentialOptions();
      if (user) {
        const formattedResources = convertResourceToObjectFormat(
          user.resources
        );
        dispatch(changeIsFirstNameMandatory(true));
        dispatch(
          changeIntervalAction({
            label: strings.thisWeek,
            fromDate: moment().startOf("isoWeek").format("MM-DD-yyyy"),
            toDate: moment().format("MM-DD-yyyy"),
          })
        );
        dispatch(
          loginAction({
            email: user.email,
            authenticated: true,
            accessToken: user.authToken,
            userName: user.name,
            role: user.role,
            resources: formattedResources,
            account: user.account,
            isTrial: user.trial,
          })
        );
        dispatch(
          changeFacebookPageAction({
            facebookPageName: "",
            facebookPageId: undefined,
          })
        );
        dispatch(changeIntegrationAppNameAction(""));
        history.push(urls.dashboardViewPath);
      }
      setIsLoading((prev: any) => ({
        ...prev,
        loginUsingPassKey: false,
      }));
    } catch (error: any) {
      setIsLoading((prev: any) => ({
        ...prev,
        loginUsingPassKey: false,
      }));
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.LOGIN_ERROR
      );
    } finally {
      setIsLoading((prev: any) => ({
        ...prev,
        loginUsingPassKey: false,
      }));
    }
  };

  const forgetPassword = () => {
    history.push(urls.forgotPasswordViewPath);
  };

  const registerPage = () => {
    history.push(urls.registerViewPath);
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const handleValidation = () => {
    const { isValid, errors } = loginValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const getLoginScreen = () => {
    return (
      <Box sx={classes.getLoginScreen}>
        <Typography sx={classes.getHeading}>Log In</Typography>
        <Box mx={4}>
          <Typography sx={classes.headingCenter} component="span">
            New around here? Create an account
            <span
              onClick={registerPage}
              style={{
                cursor: "pointer",
                fontWeight: "bolder",
                color: "#C11986",
                marginLeft: 4,
                marginRight: 4,
              }}
            >
              Join Mailzzy
            </span>
            for free 30 days trial
          </Typography>
        </Box>
        <Box mx={4}>
          <Box sx={classes.label}>
            <Typography sx={classes.labelText} className="loginValidation">
              Email
            </Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            placeHolder="Enter Email Address"
            id="email"
            type="email"
            name="email"
            value={formFields.email.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            error={!isTruthy(formFields.email.value) && formFields.email.error}
          />
          {!emailRegex.test(formFields.email.value) &&
            formFields.email.value.length > 0 && (
              <FormHelperText error sx={classes.errorStyling}>
                Please enter valid email id
              </FormHelperText>
            )}
          <Box sx={classes.label} borderColor="red">
            <Typography sx={classes.labelText}>Password </Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <CustomInput
            sx={classes.textRadious}
            placeHolder="••••••••"
            id="password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formFields.password.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            error={
              !isTruthy(formFields.password.value) && formFields.password.error
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    <Box
                      component="img"
                      src={showPassword ? showPasswordIcon : hidePasswordIcon}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={classes.forgetPasswordWrapper}>
            <Typography
              sx={{
                textDecoration: "none",
                ...mediumFont,
                color: "black",
                cursor: "pointer",
              }}
              onClick={forgetPassword}
            >
              Forgot Password?
            </Typography>
          </Box>
          <Box mt={2}>
            <CustomButton
              label="Sign In"
              onClick={handleLogin}
              disabled={
                _.isEmpty(formFields.email.value) ||
                _.isEmpty(formFields.password.value) ||
                !emailRegex.test(formFields.email.value) ||
                formFields.password?.value?.length < 8 ||
                isLoading.loginUsingPassKey
              }
              loading={isLoading.login}
              customClasses={classes.signBtn}
              id="login_button"
            />
            <Typography sx={classes.recaptchaDisclaimer}>
              This site is protected by reCAPTCHA and the Google{" "}
              <a href="https://policies.google.com/privacy">
                <span>Privacy Policy</span>
              </a>{" "}
              and{" "}
              <a href="https://policies.google.com/terms">
                <span>Terms of Service</span>
              </a>{" "}
              apply.
            </Typography>
            {isPasskeyAvailable && (
              <>
                <Divider sx={{ margin: "40px 0" }}>OR</Divider>
                <CustomButton
                  label="Sign In Using Passkey"
                  disabled={isLoading.login}
                  onClick={handleLoginUsingPasskey}
                  loading={isLoading.loginUsingPassKey}
                  customClasses={classes.signBtn}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return <Box>{getLoginScreen()}</Box>;
});

export default Login;
