import { useState, useEffect } from "react";
import { Box, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import CampaignDetailsSectionStyles from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/campaignDetailsSection/CampaignDetailsSection.styles";
import { CustomInput, NewCustomButton } from "global/components";
import { isTruthy, openErrorNotification } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { campaignExistsCheck } from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/CreateSequentialCampaignsServices";

interface CustomProps {
  index: number;
  mainCampaignDataArray: any[];
  setMainCampaignDataArray: Function;
  isValidArray: boolean[];
}

const CampaignNameSection = (props: CustomProps) => {
  const classes = CampaignDetailsSectionStyles;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [campaignName, setCampaignName] = useState<any>(
    props.mainCampaignDataArray[props.index].name
  );
  useEffect(() => {
    setCampaignName(props.mainCampaignDataArray[props.index].name);
    if (
      isTruthy(props.mainCampaignDataArray[props.index].name.error) &&
      !props.isValidArray[props.index]
    ) {
      setIsEditMode(true);
    }
  }, [props.mainCampaignDataArray]);

  const campaignNameSaver = () => {
    const mainArray: any[] = [...props.mainCampaignDataArray];
    const objectToBeUpdated = { ...props.mainCampaignDataArray[props.index] };
    const updatedObject = {
      ...objectToBeUpdated,
      ["name"]: campaignName,
    };
    mainArray.splice(props.index, 1, updatedObject);
    props.setMainCampaignDataArray([...mainArray]);
  };

  const onChangeHandler = (event: any) => {
    setCampaignName({ value: event.target.value, error: "" });
  };

  const onCancelHandler = () => {
    setCampaignName(props.mainCampaignDataArray[props.index].name);
    setIsEditMode(false);
  };

  const campaignNameValidator = (campaignName: any) => {
    let isValid = true;
    let errors = { ...campaignName };
    if (!isTruthy(campaignName.value)) {
      isValid = false;
      errors.error = "Please enter a Campaign Name";
    }
    if (
      campaignName.value !==
        props.mainCampaignDataArray[props.index].name.value &&
      props.mainCampaignDataArray.some(
        (campaignObject: any) =>
          campaignObject.name.value === campaignName.value
      )
    ) {
      isValid = false;
      errors.error = "This name is already being used";
    }
    return { isValid, errors };
  };
  const onSaveHandler = async () => {
    try {
      const { isValid, errors } = campaignNameValidator(campaignName);
      setCampaignName({ ...errors });
      if (isValid) {
        await campaignExistsCheck(campaignName.value.trim());
        campaignNameSaver();
        setIsEditMode(false);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getSaveButton = () => {
    return (
      <NewCustomButton
        id="campaign_name_save_button"
        label="Save"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onSaveHandler();
        }}
      />
    );
  };

  const getCancelButton = () => {
    return (
      <NewCustomButton
        id="campaign_name_cancel_button"
        label="Cancel"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onCancelHandler();
        }}
      />
    );
  };

  const editModeView = () => {
    return (
      <>
        <Box mb={1}>
          <Typography sx={classes.headerLabel}>Campaign Name</Typography>
          <Typography sx={classes.headerSubDescription}>
            What should be the name of this sequence?
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10} xl={10} lg={10}>
            <CustomInput
              id="step2_campaign_name"
              type="text"
              name="name"
              placeHolder="Enter the Campaign Name"
              propsToInputElement={{ maxLength: 40 }}
              value={campaignName.value}
              onChange={onChangeHandler}
              error={campaignName.error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>{campaignName?.value?.length} /40</span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Stack flexDirection="row" gap={1} mt={1}>
          {getSaveButton()}
          {getCancelButton()}
        </Stack>
      </>
    );
  };
  const viewModeView = () => {
    return (
      <Box mb={1} sx={classes.viewModeBox}>
        <Box>
          <Typography sx={classes.headerLabel}>Campaign Name</Typography>
          <Typography sx={classes.headerSubDescription}>
            {props.mainCampaignDataArray[props.index].name.value}
          </Typography>
        </Box>
        <Box>
          <NewCustomButton
            id="campaign_name_edit_button"
            label="Edit Name"
            customClasses={classes.buttonStyles}
            onClick={() => setIsEditMode(true)}
          />
        </Box>
      </Box>
    );
  };
  return <Box>{isEditMode ? editModeView() : viewModeView()}</Box>;
};

export default CampaignNameSection;
