import { useEffect, useState } from "react";
import history from "utils/history";
import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormHelperText,
  Select,
  MenuItem,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import {
  CustomAppHeader,
  CustomButton,
  CustomInput,
  CustomPaper,
} from "global/components";
import StepOneStyles from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepOne/StepOne.styles";
import strings from "global/constants/StringConstants";
import { isTruthy, openErrorNotification } from "helpers/methods";
import {
  campaignExistsCheck,
  customDomain,
  getSubordinateEmailsUsers,
} from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/CreateSequentialCampaignsServices";
import notifiers from "global/constants/NotificationConstants";
import { step1Validation } from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/CreateSequentialCampaignsHelpers";
import { MailingListTable } from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepOne/MailingListTable";
import { hasAccessTo } from "utils/AuthorizationManager";
import { disabledBackgroundColor, theme } from "utils/styles";
import { KeyboardBackspace } from "@mui/icons-material";
import urls from "global/constants/UrlConstants";

interface CustomProps {
  commonCampaignData: any;
  setCommonCampaignData: Function;
  setActiveStep: Function;
  allowedEmailCount: number;
  totalCampaigns: number;
  setTotalCampaign: Function;
  campaignNumberArray: number[];
  campaignsArrayConstructor: Function;
  isLoading: boolean;
  setIsLoading: Function;
}

const StepOne = (props: CustomProps) => {
  const classes = StepOneStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const hasContactFetchAccess = hasAccessTo(
    strings.contact,
    strings.fetchPermission
  );
  const hasCampaignerFetchAccess = hasAccessTo(
    strings.campaigner,
    strings.fetchPermission
  );

  const [emailUsers, setEmailUsers] = useState<string[]>([]);
  const [availableDomain, setAvailableDomain] = useState<string[]>([]);

  useEffect(() => {
    fetchUserEmailUser();
    domainAvailable();
  }, []);

  const hasCampaignExecutionPermission = () => {
    return hasCampaignerFetchAccess && hasContactFetchAccess;
  };

  const backClickHandler = () => {
    history.push(urls.createCampaignsViewPath);
  };

  const domainAvailable = async () => {
    try {
      props.setIsLoading(true);
      const response = await customDomain();
      setAvailableDomain(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const fetchUserEmailUser = async () => {
    try {
      props.setIsLoading(true);
      const response = await getSubordinateEmailsUsers();
      const removeDuplicates = response?.filter(
        (item: any, index: any) => response.indexOf(item) === index
      );
      setEmailUsers(removeDuplicates);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const nextStepHandler = () => {
    props.setActiveStep((prevStep: number) => prevStep + 1);
  };

  const onChangeHandler = (event: any) => {
    props.setCommonCampaignData({
      ...props.commonCampaignData,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const onFromIdChangeHandler = (event: any, newValue: any) => {
    props.setCommonCampaignData({
      ...props.commonCampaignData,
      fromId: {
        value: newValue,
        error: "",
      },
    });
  };

  const handleStep1Validation = () => {
    const { isValid, errors } = step1Validation(
      props.commonCampaignData,
      emailUsers,
      availableDomain?.length > 1
    );
    props.setCommonCampaignData({ ...errors });
    return isValid;
  };

  const checkMailingAudienceSelected = () => {
    let isValid = true;
    if (props.commonCampaignData?.groups[0]?.value?.length === 0) {
      isValid = false;
      openErrorNotification("Please select mailing audience");
    }
    return isValid;
  };

  const handleSave = async () => {
    try {
      props.setIsLoading(true);
      if (handleStep1Validation() && checkMailingAudienceSelected()) {
        await campaignExistsCheck(props.commonCampaignData.name.value.trim());
        props.campaignsArrayConstructor(nextStepHandler);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const getSequenceForm = () => {
    return (
      <Box sx={classes.gridMargin}>
        <Grid container>
          <Grid item xs={12} sm={10} lg={6} xl={6.5}>
            <Typography sx={classes.gridMargin}>
              Maintain subscriber involvement by conveying your latest updates,
              showcasing your top-selling items, or disclosing an impending
              event by creating regular campaign for both your existing and
              prospective audience.
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sx={classes.gridMargin} xs={12} sm={10} lg={6} xl={6.5}>
            <CustomInput
              required
              label="Campaign Name"
              id="sequence_step1_name_field"
              type="text"
              name="name"
              value={props.commonCampaignData.name.value}
              error={props.commonCampaignData.name.error}
              onChange={onChangeHandler}
              placeHolder="Enter Campaign Name"
              propsToInputElement={{ maxLength: 40 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>
                      {props.commonCampaignData.name.value.length} / 40
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid sx={classes.gridMargin} item xs={12} sm={10} lg={6} xl={6.5}>
            <CustomInput
              required
              label="Display Name"
              id="sequence_step1_displayName_field"
              type="text"
              name="displayName"
              value={props.commonCampaignData.displayName.value}
              error={props.commonCampaignData.displayName.error}
              onChange={onChangeHandler}
              placeHolder="Enter Display Name"
              propsToInputElement={{ maxLength: strings.DISPLAY_NAME_SIZE }}
            />
          </Grid>
          <Grid sx={classes.gridMargin} item xs={12} sm={10} lg={6} xl={6.5}>
            <Box sx={classes.label}>
              <Typography sx={classes.labelText}>From</Typography>
              <Typography sx={classes.star}>*</Typography>
            </Box>
            <Autocomplete
              sx={classes.selectMenu}
              id="sequence_step1_from_dropdown"
              options={emailUsers}
              value={props.commonCampaignData.fromId.value}
              autoHighlight={true}
              autoSelect={true}
              onChange={(e, newVal) => onFromIdChangeHandler(e, newVal)}
              renderInput={(params) => (
                <TextField
                  sx={{
                    ...classes.autocompleteStyle,
                    ...classes.nameField,
                  }}
                  {...params}
                  name="fromId"
                  error={props.commonCampaignData.fromId.error}
                />
              )}
            />
            <FormHelperText error sx={classes.errorStyle}>
              {props.commonCampaignData.fromId.error}
            </FormHelperText>
          </Grid>
          {availableDomain.length > 1 && (
            <Grid sx={classes.gridMargin} item xs={12} sm={10} lg={6} xl={6.5}>
              <Box sx={classes.label}>
                <Typography sx={classes.labelText}>
                  Available Domains
                </Typography>
                <Typography sx={classes.star}>*</Typography>
              </Box>
              <Select
                id="sequence_step1_domain_dropdown"
                sx={classes.domainSelectMenu}
                name="domain"
                value={props.commonCampaignData.domain.value}
                onChange={onChangeHandler}
                renderValue={
                  props.commonCampaignData?.domain?.value
                    ? undefined
                    : () => "Select a Domain Name"
                }
                displayEmpty
                error={props.commonCampaignData.domain.error}
              >
                {availableDomain?.map((item, index) => (
                  <MenuItem
                    sx={classes.dropdownOptions}
                    key={index}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error sx={classes.errorStyle}>
                {props.commonCampaignData.domain.error}
              </FormHelperText>
            </Grid>
          )}
          <Grid sx={classes.gridMargin} item xs={12} sm={10} lg={6} xl={6.5}>
            <Box mb={1} sx={classes.label}>
              <Typography sx={classes.labelText}>
                Number of Campaigns
              </Typography>
            </Box>
            <Select
              sx={classes.dropdown}
              id="sequence_step1_select_total_campaigns"
              value={props.totalCampaigns}
              onChange={(event: any) => {
                props.setTotalCampaign(event.target.value);
              }}
            >
              {props.campaignNumberArray.map((data: number) => (
                <MenuItem key={data} value={data} sx={classes.dropdownOptions}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid sx={classes.gridMargin} item xs={12} sm={10} lg={6} xl={6.5}>
            {hasCampaignExecutionPermission() ? (
              <MailingListTable
                campaignData={props.commonCampaignData}
                setCampaignData={props.setCommonCampaignData}
                allowedEmailCount={props.allowedEmailCount}
                isLoading={props.isLoading}
                setIsLoading={props.setIsLoading}
              />
            ) : (
              <Typography variant="body1" sx={classes.permissionMessage}>
                * You don't have permission to execute the campaigns. Please
                contact your account administrator.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <CustomAppHeader className={classes.headerSection}>
        <Grid container sx={classes.headerGridContainer}>
          <Grid item xs={12}>
            <Box sx={classes.backArrowBox}>
              <Tooltip
                title={
                  <CustomPaper
                    className={{
                      backgroundColor: disabledBackgroundColor,
                    }}
                  >
                    <Typography sx={classes.tooltipText}>
                      {"Back to Create Campaigns"}
                    </Typography>
                  </CustomPaper>
                }
                placement="right"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "none",
                    },
                  },
                }}
              >
                <KeyboardBackspace
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    backClickHandler();
                  }}
                />
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sx={classes.titleGridItem}>
            <Typography sx={classes.pageTitle}>Sequential Campaigns</Typography>
            <Box sx={classes.headerButtonStyle}>
              <CustomButton
                id="sequence_step1_next_button"
                label="Next"
                onClick={() => {
                  handleSave();
                }}
                disabled={!hasCampaignExecutionPermission()}
                customClasses={classes.headerButton}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomAppHeader>
      <Box sx={classes.stepOneWrapper}>{getSequenceForm()}</Box>
    </>
  );
};

export default StepOne;
