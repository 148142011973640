import { useState, useEffect } from "react";
import { Box, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { CustomInput, NewCustomButton } from "global/components";
import strings from "global/constants/StringConstants";
import { isTruthy } from "helpers/methods";
import CampaignDetailsSectionStyles from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/campaignDetailsSection/CampaignDetailsSection.styles";

interface CustomProps {
  index: number;
  mainCampaignDataArray: any[];
  setMainCampaignDataArray: Function;
  isValidArray: boolean[];
}

const SubjectSection = (props: CustomProps) => {
  const classes = CampaignDetailsSectionStyles;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [subject, setSubject] = useState(
    props.mainCampaignDataArray[props.index].subject
  );

  useEffect(() => {
    setSubject(props.mainCampaignDataArray[props.index].subject);
    if (
      isTruthy(props.mainCampaignDataArray[props.index].subject.error) &&
      !props.isValidArray[props.index]
    ) {
      setIsEditMode(true);
    }
  }, [props.mainCampaignDataArray[props.index].subject]);

  const onChangeHandler = (event: any) => {
    setSubject({ value: event.target.value, error: "" });
  };

  const subjectLineSaver = () => {
    const mainArray: any[] = [...props.mainCampaignDataArray];
    const objectToBeUpdated = { ...props.mainCampaignDataArray[props.index] };
    const updatedObject = {
      ...objectToBeUpdated,
      ["subject"]: subject,
    };
    mainArray.splice(props.index, 1, updatedObject);
    props.setMainCampaignDataArray([...mainArray]);
  };

  const onSaveHandler = () => {
    if (isTruthy(subject.value)) {
      subjectLineSaver();
      setIsEditMode(false);
    } else {
      setSubject({
        ...subject,
        error: "Please enter the subject line",
      });
    }
  };

  const onCancelHandler = () => {
    setSubject({
      value: props.mainCampaignDataArray[props.index].subject.value,
      error: "",
    });
    setIsEditMode(false);
  };

  const getSaveButton = () => {
    return (
      <NewCustomButton
        id="subject_save_button"
        label="Save"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onSaveHandler();
        }}
      />
    );
  };

  const getCancelButton = () => {
    return (
      <NewCustomButton
        id="subject_cancel_button"
        label="Cancel"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onCancelHandler();
        }}
      />
    );
  };

  const editModeView = () => {
    return (
      <>
        <Box mb={1}>
          <Typography sx={classes.headerLabel}>Subject</Typography>
          <Typography sx={classes.headerSubDescription}>
            What's the subject line for this email?
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10} xl={10} lg={10}>
            <CustomInput
              id="step2_subject_field"
              type="type"
              name="subject"
              placeHolder="Enter your subject"
              propsToInputElement={{ maxLength: strings.SUBJECT_LINE_SIZE }}
              value={subject.value}
              onChange={onChangeHandler}
              error={subject.error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>
                      {subject?.value?.length} / {strings.SUBJECT_LINE_SIZE}
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Typography sx={classes.description}>
          A great subject line gives your audience a reason to open your email.
        </Typography>
        <Stack flexDirection="row" gap={1} mt={1}>
          {getSaveButton()}
          {getCancelButton()}
        </Stack>
      </>
    );
  };

  const viewModeView = () => {
    return (
      <Box mb={1} sx={classes.viewModeBox}>
        <Box>
          <Typography sx={classes.headerLabel}>Subject</Typography>
          <Typography sx={classes.headerSubDescription}>
            {props.mainCampaignDataArray[props.index].subject.value}
          </Typography>
        </Box>
        <Box>
          <NewCustomButton
            id="subject_edit_button"
            label="Edit Subject"
            customClasses={classes.buttonStyles}
            onClick={() => setIsEditMode(true)}
          />
        </Box>
      </Box>
    );
  };

  return <Box>{isEditMode ? editModeView() : viewModeView()}</Box>;
};

export default SubjectSection;
