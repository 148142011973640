import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import CampaignsStyles from "screens/CampaignsNew/Campaigns/Campaigns.styles";
import { getCampaignsChildByParentId } from "screens/CampaignsNew/Campaigns/Campaign.services";
import { Tooltip, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import retweet from "assets/icons/retarget.svg";
import moment from "moment";
import urls from "global/constants/UrlConstants";
import {
  convertESTtoUserLocalDateAndTime,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import strings from "global/constants/StringConstants";
import CustomSimpleTable from "global/components/CustomSimpleTable/CustomSimpleTable";

interface TableProps {
  id: string;
  isLoading: boolean;
  setIsLoading: Function;
  isRefresh: boolean;
}

const RetargetedTable = (props: TableProps) => {
  const classes = CampaignsStyles;
  const history = useHistory();
  const [userDataSource, setUserDataSource] = useState<any>([]);

  const columns = [
    {
      name: "Campaign name",
      field: "campaignName",
    },
    {
      name: "Campaign Subject",
      field: "campaignSubject",
    },
    {
      name: "Submitted By",
      field: "submittedBy",
    },
    {
      name: "Sent On",
      field: "scheduledOn",
    },
    {
      name: "Campaign status",
      field: "status",
    },
  ];

  useEffect(() => {
    campaignsTableDataByParent();
  }, [userDataSource.id, props.id, props.isRefresh]);

  const campaignsTableDataByParent = async () => {
    try {
      props.setIsLoading(true);
      const campResult = await getCampaignsChildByParentId(props.id);
      const tableData = campResult?.map((campaign: any) => {
        return {
          campaignName: (
            <Box>
              {campaign.status === strings.Submitted ||
              campaign.status === strings.InReview ? (
                <Typography sx={classes.campaignNameStyle}>
                  {campaign.name}
                </Typography>
              ) : (
                <Typography
                  onClick={() => {
                    campaign.status === strings.Draft
                      ? history.push(
                          `${urls.createCampaignViewPath}?id=` + campaign.id
                        )
                      : history.push(
                          `${urls.campaignDetailsViewPath}?id=${campaign.id}`
                        );
                  }}
                  sx={classes.campaignNameStyle}
                >
                  {campaign.name}
                  {campaign.haveChildren && (
                    <Tooltip title="Retargeted" placement="top">
                      <img
                        src={retweet}
                        style={{
                          height: "25px",
                          width: "22px",
                          marginLeft: "8px",
                        }}
                      />
                    </Tooltip>
                  )}
                </Typography>
              )}
            </Box>
          ),
          campaignSubject: (
            <Typography sx={classes.retargetTableCellText}>
              {campaign.subject}
            </Typography>
          ),
          submittedBy: (
            <Typography sx={classes.retargetTableCellText}>
              {campaign.owner}
            </Typography>
          ),
          scheduledOn: (
            <Typography sx={classes.retargetTableCellText}>
              {convertESTtoUserLocalDateAndTime(
                campaign.scheduleTime,
                "MMM DD, YYYY"
              )}
            </Typography>
          ),
          status: (
            <Typography sx={classes.retargetTableCellText}>
              {campaign.status}
            </Typography>
          ),
        };
      });
      setUserDataSource(tableData);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          minWidth: "300px",
          width: "100%",
          overflow: "auto",
        }}
      >
        <CustomSimpleTable
          tableHeader={columns}
          tableRows={userDataSource}
          isLoading={props.isLoading}
        />
      </Box>
    </>
  );
};

export default RetargetedTable;
