import strings from "global/constants/StringConstants";
import { UserFields } from "models/interfaces";

export const campaignerHeader = [
  {
    name: "User Email",
    field: "emailId",
    sorting: true,
  },
  {
    name: "Manager Email",
    field: "assignBy",
    sorting: true,
  },
  {
    name: "Allowed Email Count",
    field: "allowedEmailCount",
    sorting: true,
  },
  {
    name: "Used Email Count",
    field: "emailCount",
    sorting: true,
  },
  {
    name: "Title",
    field: "title",
    sorting: true,
  },
  {
    name: "Actions",
    field: "action",
    sorting: false,
  },
];

export const insertUserField = () => {
  return {
    emailId: {
      value: "",
      error: "",
    },
    firstName: {
      value: "",
      error: "",
    },
    lastName: {
      value: "",
      error: "",
    },
    title: {
      value: "",
      error: "",
    },
    allowedEmailCount: {
      value: "",
      error: "",
    },
    assignBy: {
      value: "",
      error: "",
    },
    role: {
      value: "",
      error: "",
    },
  } as UserFields;
};

export const validateAddUserForm = (userDetail: any) => {
  let errors = userDetail;
  let isValid = true;
  const emailId = userDetail.emailId.value;
  const firstName = userDetail.firstName.value;
  const title = userDetail.title.value;
  const allowedEmailCount = userDetail.allowedEmailCount.value;
  const assignBy = userDetail.assignBy.value;
  const role = userDetail.role.value;

  if (!emailId) {
    errors.emailId.error = "Please enter email Id";
    isValid = false;
  }
  if (!firstName) {
    errors.firstName.error = "Please enter first name";
    isValid = false;
  }
  if (!title) {
    errors.title.error = "Please enter title";
    isValid = false;
  }
  if (!allowedEmailCount) {
    errors.allowedEmailCount.error = "Please enter allowed email count";
    isValid = false;
  }
  if (!assignBy) {
    errors.assignBy.error = "Please select manager email";
    isValid = false;
  }
  if (!role) {
    errors.role.error = "Please select the role";
    isValid = false;
  }
  if (!strings.regex.test(emailId)) {
    errors.emailId.error = "Please enter valid email id";
    isValid = false;
  }

  return { isValid, errors };
};

export const updateUserValidation = (data: any) => {
  return {
    emailId: {
      value: data?.mailId,
      error: "",
    },
    assignBy: {
      value: data?.assignBy,
      error: "",
    },
    allowedEmailCount: {
      value: data?.allowedEmailCount,
      error: "",
    },
    title: {
      value: data?.title,
      error: "",
    },
  } as UserFields;
};

export const updateUserValidationForm = (updateUserData: any) => {
  const errors = updateUserData;
  let isValid = true;
  const assignBy = updateUserData.assignBy.value;
  const allowedEmailCount = updateUserData.allowedEmailCount.value;
  const title = updateUserData.title.value;
  if (!assignBy && !allowedEmailCount && !title) {
    errors.assignBy.error = "Please select manager email";
    errors.allowedEmailCount.error = "Please enter allowed email count";
    errors.title.error = "Please enter title";
    isValid = false;
  } else if (!assignBy) {
    errors.assignBy.error = "Please select manager email";
    isValid = false;
  } else if (!allowedEmailCount) {
    errors.allowedEmailCount.error = "Please enter allowed email count";
    isValid = false;
  } else if (!title) {
    errors.title.error = "Please enter title";
    isValid = false;
  }
  return { isValid, errors };
};
