import { Margin } from "@mui/icons-material";
import {
  boldFont,
  buttonWhiteBg,
  centerItemFlex,
  darkPurpledColor,
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  pinkDarkColor,
  primaryHeadingColor,
  pureWhiteColor,
  purplePrimaryColor,
  regularFont,
  theme,
} from "utils/styles";

const GroupContactsStyles = {
  // New Page Styles
  groupContactsTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
  },
  headingGridContainer: { alignItems: "center" },
  groupInformationBox: {
    background: "#FFFFFF",
    border: "1px solid #E7E7E7",
    borderRadius: "10px",
    padding: 2,
  },
  groupInfoStack: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "20px",
    },
  },
  groupInformationHeadingText: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
  },
  groupContactCount: { ...boldFont },
  groupTypeText: {
    backgroundColor: pinkDarkColor,
    color: pureWhiteColor,
    ...mediumFont,
    fontSize: getRelativeFontSize(),
    margin: "0 0 5px 10px",
  },
  groupTotalContacts: { ...mediumFont, fontSize: getRelativeFontSize(5) },
  headingButtonStyles: {
    minWidth: "130px",
    [theme.breakpoints.down("sm")]: { minWidth: "50px" },
  },
  customIconsStyles: {
    backgroundColor: purplePrimaryColor,
    padding: "7px",
    borderRadius: "10px",
  },
  deleteModalTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(13),
    textAlign: "center",
  },
  deleteDialogBodyContainer: {
    ...centerItemFlex,
    flexDirection: "column",
  },
  fontText: {
    ...regularFont,
  },
  textBold: {
    ...boldFont,
    wordBreak: "break-all",
    marginLeft: "5px",
  },
  centerItemFlex: { ...centerItemFlex },
  dialogFooter: {
    ...centerItemFlex,
    gap: "10px",
    paddingBottom: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& button": {
      width: "120px",
    },
  },
  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  cancelButtonStyle: {
    color: "#212121 !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E7E7E7",
    borderRadius: "10px",
    "&:hover": {
      background: "none",
    },
  },
  updateDialogTitleWrapper: {
    display: "flex",
    alignItemCenter: "center",
    gap: "20px",
    flexDirection: "column",
  },
  updateDialogTitle: {
    color: "rgba(0,0,0,.85)",
    ...boldFont,
    fontSize: getRelativeFontSize(14),
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
  },
  updateDialogNameBox: {
    background: "#F8F8F8",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
  },
  spanStyle: {
    ...boldFont,
    marginLeft: "5px",
  },
  dropzoneAreaBase: {
    "& .MuiDropzoneArea-root": {
      width: "100%",
      minHeight: "70px",
      fontSize: "12px",
      borderRadius: "10px",
      borderWidth: "3px",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: getRelativeFontSize(2),
      ...mediumFont,
    },
  },
  star: {
    color: inputLabelRequiredColor,
  },
  input: {
    fontSize: getRelativeFontSize(7),
    color: "black",
    fontColor: theme.palette.common.black,
    ...boldFont,
    marginTop: "10px",
    display: "flex",
    "& .star": {
      color: inputLabelRequiredColor,
    },
  },
  previewChip: {
    marginTop: 1,
  },
  warningContent: {
    color: "red",
    fontSize: getRelativeFontSize(),
    margin: "10px 0",
    ...mediumFont,
  },
  additionalInputsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "15px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      padding: "15px 0",
    },
  },
  additionalInputsStack: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  refreshBtn: {
    height: "47px",
    backgroundColor: "#ffffff",
    border: "1px solid #ced4da",
    minWidth: "50px",
    borderRadius: "10px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContact: "center",
    fontSize: "50px",
    "&:hover": {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 10px 0",
    },
  },
  inputBoxStyles: {
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 10px 0",
    },
  },
  inputBoxStylesExtended: {
    width: "100%",
  },
  addBtnStyle: {
    width: "100%",
    whiteSpace: "nowrap",
  },
} as const;

export default GroupContactsStyles;
