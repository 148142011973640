import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, InputAdornment, Stack, Typography } from "@mui/material";
import usersStyles from "screens/Settings/Users/Users.styles";
import CustomButton from "global/components/CustomButton/CustomButton";
import AddUser from "screens/Settings/Users/components/AddUser/AddUser";
import { RowData, SortingConfig, UserData } from "models/interfaces";
import SearchIcon from "@mui/icons-material/Search";
import {
  debounceEventHandler,
  getFormattedStatsCount,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import { CustomInput, CustomTable } from "global/components";
import {
  disableMFA,
  getActiveCampaigner,
  getCampaigners,
  getCampaignersCount,
  getReport,
  getSearchUsers,
  getSearchUsersCounts,
  getUserRoles,
  unlockUser,
} from "screens/Settings/Users/UserService";
import UpdateUser from "screens/Settings/Users/components/UpdateUser/UpdateUser";
import { campaignerHeader } from "screens/Settings/Users/UserTypeAndValidation";
import strings from "global/constants/StringConstants";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import notifiers from "global/constants/NotificationConstants";
import { useTitle } from "utils/UseTitle";
import { hasAccessTo } from "utils/AuthorizationManager";
import {
  getRelativeFontSize,
  primaryHeadingColor,
  boldFont,
  HtmlTooltip,
} from "utils/styles";
import { useAppSelector } from "utils/hooks";
import { selectEmail } from "redux/authSlice";

const Users = (props: any) => {
  useTitle(strings.UsersTitle);
  const classes = usersStyles;
  const [isLoading, setIsLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [addUserDialogHandler, setAddUserDialogHandler] = useState(false);
  const [updateUserDialogHandler, setUpdateUserDialogHandler] = useState(false);
  const [userDataSource, setUserDataSource] = useState<UserData[]>([]);
  const [searchCampaigner, setSearchCampaigner] = useState<string>("");
  const [roles, setRoles] = useState([]);
  const [campaignerCount, setCampaignerCount] = useState(1);
  const [selectedRowData, setSelectedRowData] = useState<RowData>({
    mailId: "",
    assignBy: "",
    allowedEmailCount: "",
    title: "",
  });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [perPageData, setPerPageData] = useState(10);
  const [searchPageNumber, setSearchPageNumber] = useState<number>(1);
  const [activeCampaigner, setActiveCampaigner] = useState<any>([]);
  const [sortConfig, setSortConfig] = useState<SortingConfig[]>([]);
  const hasUserUpdateAccess = hasAccessTo(
    strings.campaigner,
    strings.updatePermission
  );
  const hasUserAddAccess = hasAccessTo(
    strings.campaigner,
    strings.addPermission
  );
  const loggedInUserEmail = useAppSelector(selectEmail);
  useEffect(() => {
    fetchRole();
  }, []);

  useEffect(() => {
    getUsersDetailTable();
  }, [searchCampaigner, pageNumber, perPageData, searchPageNumber, sortConfig]);

  const getUnLockIcon = () => {
    return (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 15.0001V12.0001C12.999 11.0258 13.3537 10.0847 13.9974 9.35333C14.6411 8.62199 15.5295 8.15072 16.496 8.02798C17.4625 7.90523 18.4406 8.13945 19.2467 8.68667C20.0528 9.23388 20.6314 10.0565 20.874 11.0001"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 15H24V24C24 24.5304 23.7893 25.0391 23.4142 25.4142C23.0391 25.7893 22.5304 26 22 26H12C11.4696 26 10.9609 25.7893 10.5858 25.4142C10.2107 25.0391 10 24.5304 10 24V15Z"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.5 20.5H19.51V20.51H19.5V20.5Z"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  const getLockIcon = () => {
    return (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 15.0001V12.0001C12.999 11.0258 13.3537 10.0847 13.9974 9.35333C14.6411 8.62199 15.5295 8.15072 16.496 8.02798C17.4625 7.90523 18.4406 8.13945 19.2467 8.68667C20.0528 9.23388 20.6314 10.0565 20.874 11.0001C21.1166 11.9436 21 14.5 21 14.5"
          stroke="#f79009"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M10 15H24V24C24 24.5304 23.7893 25.0391 23.4142 25.4142C23.0391 25.7893 22.5304 26 22 26H12C11.4696 26 10.9609 25.7893 10.5858 25.4142C10.2107 25.0391 10 24.5304 10 24V15Z"
          fill="#f79009"
          stroke="#f79009"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
        <path
          d="M19.5 20.5H19.51V20.51H19.5V20.5Z"
          stroke="#f79009"
          stroke-width="2.25"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  const getMFAActiveIcon = () => {
    return (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 24.9005C18.6167 24.4005 19.9667 23.4131 21.05 21.9385C22.1333 20.4638 22.7667 18.8178 22.95 17.0005H17V9.14448L11.404 11.2215C11.2753 11.2728 11.176 11.3498 11.106 11.4525C11.0353 11.5551 11 11.6705 11 11.7985V16.5315C11 16.6608 11.0167 16.8171 11.05 17.0005H17V24.9005ZM17 25.8425C16.9093 25.8425 16.8137 25.8341 16.713 25.8175C16.6123 25.8008 16.519 25.7758 16.433 25.7425C14.4643 24.9925 12.9 23.7205 11.74 21.9265C10.58 20.1325 10 18.1905 10 16.1005V11.8175C10 11.4775 10.0983 11.1698 10.295 10.8945C10.4917 10.6191 10.7433 10.4191 11.05 10.2945L16.435 8.29448C16.6297 8.22381 16.818 8.18848 17 8.18848C17.182 8.18848 17.3707 8.22381 17.566 8.29448L22.95 10.2945C23.2567 10.4191 23.5083 10.6191 23.705 10.8945C23.9017 11.1698 24 11.4775 24 11.8175V16.1005C24 18.1905 23.42 20.1325 22.26 21.9265C21.1 23.7205 19.5357 24.9925 17.567 25.7425C17.4803 25.7758 17.387 25.8008 17.287 25.8175C17.187 25.8341 17.0913 25.8425 17 25.8425Z"
          fill="#1a0224"
        />
      </svg>
    );
  };

  const getMFAInActiveIcon = () => {
    return (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 24.9005C18.6167 24.4005 19.9667 23.4131 21.05 21.9385C22.1333 20.4638 22.7667 18.8178 22.95 17.0005H17V9.14448L11.404 11.2215C11.2753 11.2728 11.176 11.3498 11.106 11.4525C11.0353 11.5551 11 11.6705 11 11.7985V16.5315C11 16.6608 11.0167 16.8171 11.05 17.0005H17V24.9005ZM17 25.8425C16.9093 25.8425 16.8137 25.8341 16.713 25.8175C16.6123 25.8008 16.519 25.7758 16.433 25.7425C14.4643 24.9925 12.9 23.7205 11.74 21.9265C10.58 20.1325 10 18.1905 10 16.1005V11.8175C10 11.4775 10.0983 11.1698 10.295 10.8945C10.4917 10.6191 10.7433 10.4191 11.05 10.2945L16.435 8.29448C16.6297 8.22381 16.818 8.18848 17 8.18848C17.182 8.18848 17.3707 8.22381 17.566 8.29448L22.95 10.2945C23.2567 10.4191 23.5083 10.6191 23.705 10.8945C23.9017 11.1698 24 11.4775 24 11.8175V16.1005C24 18.1905 23.42 20.1325 22.26 21.9265C21.1 23.7205 19.5357 24.9925 17.567 25.7425C17.4803 25.7758 17.387 25.8008 17.287 25.8175C17.187 25.8341 17.0913 25.8425 17 25.8425Z"
          fill="#8E929A"
        />
      </svg>
    );
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage);
  };
  const handlePerPageData = (event: any) => {
    setPageNumber(1);
    setSearchPageNumber(1);
    setPerPageData(event.target.value);
  };

  const unlockCampaigner = async (emailId: any) => {
    try {
      setIsLoading(true);
      const response = await unlockUser(emailId);
      openSuccessNotification(response.message);
      await getUsersDetailTable();
      setIsLoading(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      setIsLoading(false);
    }
  };

  const disableMFACampaigner = async (emailId: any) => {
    try {
      setIsLoading(true);
      const response = await disableMFA(emailId);
      openSuccessNotification(response.message);
      await getUsersDetailTable();
      setIsLoading(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      setIsLoading(false);
    }
  };

  const getActions = (emailId: any, status: any, multiFactorLogin: any) => {
    return (
      <Stack display={"flex"} direction={"row"}>
        {status === "Locked" ? (
          <>
            <HtmlTooltip
              title={"Unlock user"}
              placement="top"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    width: "auto",
                    background: "none",
                    padding: "6px !important",
                  },
                },
              }}
            >
              <Box
                onClick={async (event: any) => {
                  event.stopPropagation();
                  await unlockCampaigner(emailId);
                }}
              >
                {getLockIcon()}
              </Box>
            </HtmlTooltip>
          </>
        ) : (
          <Box>{getUnLockIcon()}</Box>
        )}
        {multiFactorLogin ? (
          <>
            <HtmlTooltip
              title={"Reset MFA"}
              placement="top"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    width: "auto",
                    background: "none",
                    padding: "6px !important",
                  },
                },
              }}
            >
              <Box
                onClick={async (event: any) => {
                  event.stopPropagation();
                  await disableMFACampaigner(emailId);
                  if (emailId === loggedInUserEmail) {
                    props.updateMFAStatus(false);
                  }
                }}
              >
                {getMFAActiveIcon()}
              </Box>
            </HtmlTooltip>
          </>
        ) : (
          <Box>{getMFAInActiveIcon()}</Box>
        )}
      </Stack>
    );
  };

  const tableDataShowHandler = (usersData: any) => {
    const source = usersData.map((usersData: UserData) => {
      return {
        emailId: (
          <Typography sx={classes.rowColor}>{usersData.emailId}</Typography>
        ),
        mailId: usersData.emailId,
        assignBy: usersData.assignBy,
        allowedEmailCount: getFormattedStatsCount(usersData.allowedEmailCount),
        emailCount: getFormattedStatsCount(usersData.emailCount),
        title: usersData.title,
        action: getActions(
          usersData.emailId,
          usersData.status,
          usersData.multiFactorLogin
        ),
      };
    });
    setUserDataSource([...source]);
  };

  const fetchRole = async () => {
    try {
      setIsLoading(true);
      const [role, res] = await Promise.all([
        getUserRoles(),
        getActiveCampaigner(),
      ]);

      setRoles(role);
      setActiveCampaigner(res);
      setIsLoading(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      setIsLoading(false);
    }
  };

  const getUsersDetailTable = async () => {
    try {
      setIsUserLoading(true);
      const [count, users] = await Promise.all([
        getCampaignersCount(searchCampaigner),
        getCampaigners(pageNumber, perPageData, searchCampaigner, sortConfig),
      ]);
      tableDataShowHandler(users);
      setCampaignerCount(count);
      setIsUserLoading(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      setIsUserLoading(false);
    }
  };

  const handleSearchOnChange = (SearchEvent: ChangeEvent<HTMLInputElement>) => {
    if (SearchEvent.target.value) {
      setSearchCampaigner(SearchEvent.target.value.replace(/\s/g, ""));
      setPageNumber(1);
      setPerPageData(10);
    } else {
      setSearchCampaigner("");
    }
  };

  const getSearchBar = () => {
    return (
      <CustomInput
        placeHolder="Search text"
        id="users_search_field"
        onChange={debounceEventHandler(
          handleSearchOnChange,
          strings.SEARCH_TIME_OUT
        )}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const addUserButton = () => {
    return (
      <CustomButton
        id="users_add_button"
        label={"Add User"}
        onClick={() => setAddUserDialogHandler(true)}
        customClasses={{
          width: "150px",
        }}
      />
    );
  };

  const addUserDialogBox = () => {
    return (
      <AddUser
        openAddUserDialog={addUserDialogHandler}
        handleCloseAddUserDialog={closeAddUserDialogHandler}
        managerMail={activeCampaigner}
        roles={roles}
        tableData={getUsersDetailTable}
        isLoading={isLoading}
      />
    );
  };

  const closeAddUserDialogHandler = () => {
    setAddUserDialogHandler(false);
  };

  const updateUserDetails = (selectedRow: RowData) => {
    if (hasUserUpdateAccess) {
      setUpdateUserDialogHandler(true);
      setSelectedRowData({
        ...selectedRowData,
        mailId: selectedRow.mailId,
        assignBy: selectedRow.assignBy,
        allowedEmailCount: selectedRow.allowedEmailCount,
        title: selectedRow.title,
      });
    }
  };

  const updateUserDialogBox = () => {
    return (
      <UpdateUser
        updateUserDialogOpen={updateUserDialogHandler}
        handleUpdateDialogClose={updateDialogCloseHandler}
        selectedRowData={selectedRowData}
        managerMail={activeCampaigner}
        tableData={getUsersDetailTable}
      />
    );
  };

  const updateDialogCloseHandler = () => {
    setUpdateUserDialogHandler(false);
  };

  const handleSearchChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setSearchPageNumber(newPage);
  };
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const data = await getReport();
      openSuccessNotification(data.message);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const campaignerTable = () => {
    return (
      <Box id="users_display_table" sx={classes.campaignerTable}>
        <CustomTable
          headers={campaignerHeader}
          rows={userDataSource}
          paginationCount={campaignerCount}
          handleRowClick={updateUserDetails}
          handlePageChange={
            searchCampaigner ? handleSearchChangePage : handleChangePage
          }
          pageNumber={searchCampaigner ? searchPageNumber : pageNumber}
          setPage={searchCampaigner ? setSearchPageNumber : setPageNumber}
          isLoading={isLoading}
          handlePerPageData={handlePerPageData}
          perPageData={perPageData}
          rowsPerPage={perPageData}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
          sortingEntity={"Campaigner"}
          isExportCSV={searchCampaigner ? false : true}
          onClickExportCSV={handleDownload}
        />
      </Box>
    );
  };

  const getUser = () => (
    <Box>
      <Stack
        px={4}
        pt={2}
        direction={{ lg: "row", xs: "column" }}
        justifyContent="space-between"
        alignItems={{ lg: "center" }}
      >
        <Typography
          sx={{
            fontSize: getRelativeFontSize(6),
            ...boldFont,
            color: primaryHeadingColor,
          }}
        >
          Users
        </Typography>

        <Stack
          direction={{ sm: "row", xs: "column" }}
          alignItems={{ sm: "center" }}
          spacing={1}
        >
          {getSearchBar()}
          {hasUserAddAccess && addUserButton()}
        </Stack>
      </Stack>

      <Box
        sx={{
          minWidth: "300px",
          overflow: "auto",
          padding: "30px",
        }}
      >
        {campaignerTable()}
        {addUserDialogBox()}
        {updateUserDialogBox()}
      </Box>
      <CustomLoader isLoading={isLoading || isUserLoading} />
    </Box>
  );

  return getUser();
};

export default Users;
