import {
  getRelativeFontSize,
  primaryHeadingColor,
  theme,
  boldFont,
  regularFont,
} from "utils/styles";

const StepOneStyles = {
  headerSection: {
    padding: "40px 20px 20px 20px",
  },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  },
  mainContainer: {
    padding: theme.spacing(4),
  },
  regularFonts: {
    ...regularFont,
  },
} as const;

export default StepOneStyles;
