import urls from "global/constants/UrlConstants";
import { GlobalInterval } from "models/interfaces";
import { getCallParams, makeCall } from "utils/service";

export const deleteSocialCampaign = async (id: string) => {
  try {
    const callParams = await getCallParams("DELETE");
    const response: any = await makeCall(
      `${urls.SOCIAL_CAMPAIGN_URL}/delete/${id}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSocialCampaignsCountByDates = async (
  status: string,
  interval: GlobalInterval
) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.SOCIAL_FETCH_CAMPAIGN_URL}/status/${status}/dates/${interval.fromDate}/${interval.toDate}/count`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSocialCampaignsByDates = async (
  status: string,
  interval: GlobalInterval,
  page: number,
  perPageData: number
) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.SOCIAL_FETCH_CAMPAIGN_URL}/status/${status}/dates/${interval.fromDate}/${interval.toDate}/page/${page}/limit/${perPageData}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
