export const insertRetargetingField = () => {
  return {
    groupName: {
      value: "",
      error: "",
    },
    groupType: {
      value: "",
      error: "",
    },
    open: {
      value: false,
      error: "",
    },
    click: {
      value: false,
      error: "",
    },
    notOpened: {
      value: false,
      error: "",
    },
  } as any;
};

export const validateAddUserForm = (userDetail: any) => {
  let errors = userDetail;
  let isValid = true;
  const open = userDetail.open.value;
  const click = userDetail.click.value;
  const notOpened = userDetail.notOpened.value;
  const groupName = userDetail.groupName.value;

  if (!groupName) {
    errors.groupName.error = "Please enter group name";
    isValid = false;
  }
  if (!open && !click && !notOpened) {
    errors.open.error = "Please select the audience";
    isValid = false;
  }

  return { isValid, errors };
};
