import { boldFont, getRelativeFontSize, mediumFont, pinkDarkColor, primaryHeadingColor, pureWhiteColor, regularFont, theme } from "utils/styles";

const ViewTicketStyles = {
  headerSection: {
    backgroundColor: "#fcf5ff",
    padding: "35px 18px 18px 18px",
  },
  stepOneHeader: {
    my: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tooltipText: {
    padding: "10px",
    color: pureWhiteColor,
    fontSize: "14px",
    ...regularFont,
  },
  backArrowBox: {
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
  },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: pinkDarkColor,
  },
  step1Wrapper: {
    padding: theme.spacing(4),
  },
  billingDetail: {
    background: "#FFFFFF",
    boxShadow: " 0px 8px 30px rgba(0, 0, 0, 0.07)",
    borderRadius: "10px",
    width: "auto",
    padding: "5px",
    overflow: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  heading: {
    ...boldFont,
    fontSize: "1rem",
    color: "#5E6979",
  },
  title: {
    ...mediumFont,
    fontSize: "1rem",
    color: "#5E6979",
  },
};

export default ViewTicketStyles;
