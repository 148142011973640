import {
  boldFont,
  buttonWhiteBg,
  getRelativeFontSize,
  mediumFont,
  regularFont,
  textLightColor,
  theme,
} from "utils/styles";

const DomainDetailsStyles = {
  mainContainer: { padding: "0 30px" },
  subHeadingBox: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    margin: "10px 0",
  },
  subHeadingText: {
    ...boldFont,
    fontSize: getRelativeFontSize(4),
  },
  subHeadingDescription: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
  },
  verifyButtonContainer: {
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  verifyButtonStyles: { width: "150px !important" },
  domainDetailsTable: {
    margin: "20px 0",
    minWidth: "300px",
    width: "100%",
    overflow: "auto",
  },
  hostNameTextStyles: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
  },
  typeTextStyles: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
  },
  textField: {
    width: "80%",
    minWidth: "200px",
    borderRadius: "12px",
    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      backgroundColor: "#fff",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        borderColor: "#4B0150",
      },
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: textLightColor,
    },
  },
  requiredValueDescriptionBox: {
    marginTop: "5px",
    display: "flex",
  },
  redInfoIconStyles: { width: "15px" },
  requiredValueInfo: {
    ...regularFont,
    marginLeft: "5px",
    color: "#FF0F00",
    fontSize: getRelativeFontSize(),
  },
  statusChip: {
    ...boldFont,
    padding: "5px",
    width: "80%",
    size: getRelativeFontSize(2),
  },
} as const;

export default DomainDetailsStyles;
