import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { regularFont } from "utils/styles";
import salesforceIcon from "assets/icons/salesforce.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import history from "utils/history";
import notifiers from "global/constants/NotificationConstants";
import strings from "global/constants/StringConstants";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import { changeIntegrationAppNameAction } from "redux/persistSlice";
import IntegrationStyles from "screens/Integration/Integration.styles";
import { hasAccessTo } from "utils/AuthorizationManager";
import urls from "global/constants/UrlConstants";
import { CustomButton } from "global/components";
import {
  disconnectSalesforce,
  getSalesforceAuthenticationUrl,
} from "screens/Integration/component/SalesforceServices";
import { useEffect, useState } from "react";

interface CustomProps {
  previousStepHandler: Function;
  isSalesforceIntegrated: boolean;
  setIsSalesforceIntegrated: Function;
  isAppAuthenticated: Function;
  setIsLoading: Function;
}

const Salesforce = (props: CustomProps) => {
  const classes = IntegrationStyles;
  const dispatch = useDispatch();
  const [authenticationUrl, setAuthenticationUrl] = useState<string>("");

  useEffect(() => {
    getAuthenticationUrl();
  }, []);

  const hasSocialDeleteAccess = hasAccessTo(
    strings.social,
    strings.deletePermission
  );

  const getAuthenticationUrl = async () => {
    try {
      props.setIsLoading(true);
      const response = await getSalesforceAuthenticationUrl();
      setAuthenticationUrl(response.message);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const salesforceRedirection = () => {
    window.location.href = authenticationUrl;
  };

  const onConnectHandler = () => {
    dispatch(changeIntegrationAppNameAction(strings.SALESFORCE));
    salesforceRedirection();
  };

  const salesforceDisconnectHandler = async () => {
    try {
      const response = await disconnectSalesforce(strings.SALESFORCE);
      openSuccessNotification(response?.message);
      props.isAppAuthenticated();
      history.push(urls.integrationViewPath);
      props.setIsSalesforceIntegrated(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error?.message) ? error?.message : notifiers?.GENERIC_ERROR
      );
    }
  };

  return (
    <Grid>
      <Grid item>
        <Box sx={classes.integrationsWrapper}>
          <IconButton
            id="salesforce_back_button"
            onClick={() => {
              props.previousStepHandler();
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography sx={classes.integrationsTitle}>
            Back to Channels
          </Typography>
        </Box>
      </Grid>
      <Grid item mt={1}>
        <Box sx={classes.connectWrapper}>
          <Box
            component="img"
            src={salesforceIcon}
            height={80}
            width={80}
            mt={1}
            ml={2}
          />
          <Box sx={classes.connectContent}>
            <Typography sx={classes.socialTitle}>SalesForce</Typography>
            <Typography sx={classes.connectPara}>
              Unlock the full potential of your SalesForce presence with
              Mailzzy! 🚀
            </Typography>
            <Typography sx={classes.connectPara}>
              Connect your SalesForce account and start experiencing the
              benefits of Mailzzy's features.
            </Typography>
            {!hasSocialDeleteAccess && (
              <Typography
                variant="body1"
                sx={{
                  color: "#ff4244",
                  ...regularFont,
                }}
              >
                * You don't have permission to disconnect channel. Please
                contact your account administrator.
              </Typography>
            )}

            <Box mt={2} width={{ sm: "100%", lg: "20%" }}>
              {props.isSalesforceIntegrated ? (
                hasSocialDeleteAccess && (
                  <CustomButton
                    label="Disconnect"
                    onClick={() => salesforceDisconnectHandler()}
                    id="salesforce_disconnect_button"
                  />
                )
              ) : (
                <CustomButton
                  label="Connect"
                  onClick={() => {
                    onConnectHandler();
                  }}
                  id="salesforce_connect_button"
                />
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Typography fontWeight="bold" fontSize={23} mt={6}>
          Features
        </Typography>
        <Box ml={7}>
          <List sx={classes.listStyle}>
            <ListItem sx={classes.listItemStyle}>
              <span style={{ fontWeight: "bold" }}>
                Streamline your SalesForce data management:
              </span>{" "}
              No more logging in and out of multiple platforms. Keep track of
              all your SalesForce interactions in one place.
            </ListItem>

            <ListItem sx={classes.listItemStyle}>
              <span style={{ fontWeight: "bold" }}>
                Create ad campaigns directly:
              </span>{" "}
              Say goodbye to manual data transfer. Create and manage ad
              campaigns directly within Mailzzy.
            </ListItem>
            <ListItem sx={classes.listItemStyle}>
              <span style={{ fontWeight: "bold" }}>Real-time tracking:</span>{" "}
              Stay on top of customer interactions and respond quickly,
              improving communication and customer satisfaction.
            </ListItem>
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Salesforce;
