import React, { useEffect, useState } from "react";
import { CustomButton, CustomDialog, CustomInput } from "global/components";
import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import moment from "moment";
import { isTruthy } from "helpers/methods";
import CreateCampaignStyle from "screens/CreateCampaign/createCampaign.styles";
import scheduleTimeImage from "assets/images/schedule.svg";
import Radio from "@mui/material/Radio";
import { pinkDarkColor } from "utils/styles";
interface ScheduleTimeModalProps {
  setScheduleTimeModal: Function;
  isScheduleTimeModal: boolean;
  setStepOneFormData: any;
  stepOneFormData: any;
  submit: Function;
  setShowPrompt?: Function;
  setBlocking?: Function;
}
const ScheduleTimeModal = ({
  isScheduleTimeModal,
  setScheduleTimeModal,
  setStepOneFormData,
  stepOneFormData,
  submit,
  setShowPrompt,
  setBlocking,
}: ScheduleTimeModalProps) => {
  const classes = CreateCampaignStyle;
  const [selectedValue, setSelectedValue] = React.useState<string>("right");
  const [errorScheduleTime, setErrorScheduleTime] = useState("");
  useEffect(() => {
    if (moment(stepOneFormData?.scheduleTime?.value).isBefore(moment())) {
      setStepOneFormData({
        ...stepOneFormData,
        scheduleTime: {
          value: "",
        },
      });
    }
    setErrorScheduleTime("");
    setSelectedValue("right");
  }, [isScheduleTimeModal]);

  const modalHeader = () => {
    return (
      <Box display={"flex"}>
        <img src={scheduleTimeImage} alt="image not found!" />
      </Box>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkBoxValue = event.target.value;
    if (checkBoxValue === "right") {
      setStepOneFormData({
        ...stepOneFormData,
        scheduleTime: {
          value: "",
        },
      });
      setSelectedValue(checkBoxValue);
    } else {
      setSelectedValue(checkBoxValue);
    }
    setErrorScheduleTime("");
  };

  const scheduleTimeDialogTitle = () => {
    return (
      <>
        <Typography sx={classes.boldFonts}>Choose sending time</Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box display={"flex"} alignItems={"center"}>
            <Radio
              id="step4_schedule_now_field"
              checked={selectedValue === "right"}
              sx={{
                color: pinkDarkColor,
                "&.Mui-checked": {
                  color: pinkDarkColor,
                },
              }}
              value={"right"}
              onChange={handleChange}
            />
            <Typography sx={classes.radioButtonlabel}>
              Send it right away
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box display={"flex"} alignItems={"center"} ml={1}>
            <Radio
              id="step4_schedule_future_field"
              checked={selectedValue === "future"}
              sx={{
                color: pinkDarkColor,
                "&.Mui-checked": {
                  color: pinkDarkColor,
                },
              }}
              value={"future"}
              onChange={handleChange}
            />
            <Typography sx={classes.radioButtonlabel}>
              Schedule for future
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  const finalButtonValidation = () => {
    let isValid = true;
    if (!stepOneFormData.scheduleTime.value && selectedValue === "future") {
      setErrorScheduleTime("Please Enter Schedule Time");
      isValid = false;
    }
    return isValid;
  };

  const handleStepOneOnChange = (event: React.ChangeEvent<any>) => {
    setStepOneFormData({
      ...stepOneFormData,
      [event.target.name]: {
        ...stepOneFormData[event.target.name],
        value: event.target.value,
        error: "",
      },
    });
    setErrorScheduleTime("");
  };

  const scheduleTimeBody = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomInput
              disabled={selectedValue === "future" ? false : true}
              type="datetime-local"
              id="step4_schedule_time_field"
              name="scheduleTime"
              propsToInputElement={{
                min: moment().format("YYYY-MM-DDTkk:mm"),
              }}
              value={stepOneFormData?.scheduleTime?.value}
              onChange={handleStepOneOnChange}
              error={
                (isTruthy(stepOneFormData?.scheduleTime?.value) &&
                  moment(stepOneFormData?.scheduleTime?.value).isBefore(
                    moment()
                  )) ||
                (!isTruthy(stepOneFormData?.scheduleTime?.value) &&
                  errorScheduleTime)
              }
            />
            {isTruthy(stepOneFormData?.scheduleTime?.value) &&
              moment(stepOneFormData?.scheduleTime?.value).isBefore(
                moment()
              ) && (
                <FormHelperText error style={{ paddingLeft: "5px" }}>
                  Scheduled time cannot be before today's date or time
                </FormHelperText>
              )}
          </Grid>
        </Grid>
      </>
    );
  };
  const scheduleDialogFooter = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            id="step4_schedule_cancel_button"
            label="Cancel"
            onClick={() => setScheduleTimeModal(false)}
            customClasses={classes.cancelButtonStyle}
          />
          <CustomButton
            id="step4_schedule_submit_button"
            label={
              selectedValue === "future" ? "Schedule Campaign" : "Send Campaign"
            }
            onClick={() => {
              if (finalButtonValidation()) {
                setShowPrompt?.(false);
                setBlocking?.(false);
                submit();
              }
            }}
            disabled={moment(stepOneFormData?.scheduleTime?.value).isBefore(
              moment()
            )}
            customClasses={classes.scheduleBtn}
          />
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <CustomDialog
        isDialogOpen={!!isScheduleTimeModal}
        closable
        closeButtonVisibility
        handleDialogClose={() => setScheduleTimeModal(false)}
        dialogHeaderContent={modalHeader()}
        dialogTitleContent={scheduleTimeDialogTitle()}
        dialogBodyContent={scheduleTimeBody()}
        dialogFooterContent={scheduleDialogFooter()}
        width={"500px"}
      />
    </>
  );
};

export default ScheduleTimeModal;
