import strings from "global/constants/StringConstants";
import urls from "global/constants/UrlConstants";
import { getCallParams, getFileCallParams, makeCall } from "utils/service";

export const authUserToFB = async (code: any, redirectUrl: any, state: any) => {
  try {
    const body = {
      code,
      redirectUrl,
      state,
    };
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(urls.userAuthenticated, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getUserDetailToFB = async (appName: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.getFaceBookUserDetail}/${appName}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const postFeedToFacebook = async (
  pageId: number,
  message: string,
  imageLinks: string[]
) => {
  try {
    const body = {
      link: imageLinks,
      message,
      pageId,
    };
    const callParams = await getCallParams("POST", body);
    const response = await makeCall(urls.postFacebookFeed, callParams,strings.CUSTOM_API_TIMEOUT_LIMIT);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const socialCampaignExistsCheck = async (
  campaignName: string,
  appName: string
) => {
  try {
    const body = { name: campaignName, socialAppName: appName };
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(
      urls.socialCampaignNameCheck,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const sendSocialCampaign = async (payload: any) => {
  try {
    const callParams = await getCallParams("POST", payload);
    const response: any = await makeCall(urls.sendSocialCampaign, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const uploadDocument = async (file: any) => {
  try {
    const callParams = await getFileCallParams(file);
    const response = await makeCall(urls.uploadImageFacebook, callParams,strings.CUSTOM_API_TIMEOUT_LIMIT);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getIntegratedAppNames = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.getIntegratedAppNames, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const deleteUnexecutedCampaigns = async () => {
  try {
    const callParams = await getCallParams("DELETE");
    const response = await makeCall(
      urls.deleteUnexecutedSocialCampaigns,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const disconnectFacebook = async (appName: string) => {
  try {
    const callParams = await getCallParams("DELETE");
    const response = await makeCall(
      `${urls.disconnectIntegratedApp}/${appName}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getSocialCampaignsCount = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      `${urls.SOCIAL_FETCH_CAMPAIGN_URL}/status/${strings.Submitted}/count`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
