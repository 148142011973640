import CustomButton from "global/components/CustomButton/CustomButton";
import CampaignsStyles from "screens/CampaignsNew/Campaigns/Campaigns.styles";
import { Box } from "@mui/material";
import CustomDialog from "global/components/CustomDialog/CustomDialog";
import DeleteImg from "assets/images/DeleteImg.svg";

interface customProps {
  setOpenModal: Function;
  openModal: boolean;
  handleConfirmDelete: Function;
}

const DeleteBulkEmailModel = (props: customProps) => {
  const classes = CampaignsStyles;

  const handleCloseModel = () => {
    props.setOpenModal(false);
  };

  const dialogTitleContent = () => {
    return (
      <>
        <Box sx={classes.dialogTitleWrapper}>
          <Box sx={classes.titleRight}>Delete Campaign</Box>
        </Box>
      </>
    );
  };

  const dialogBody = () => (
    <Box sx={classes.dialogContent}>
      Are you sure you want to delete the Campaign?
    </Box>
  );

  const dialogContent = () => {
    return (
      <>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            label="Cancel"
            customClasses={classes.buttonWhiteBg}
            onClick={() => handleCloseModel()}
            id="campaign_delete_bulk_email_cancel_button"
          />
          <CustomButton
            label="Delete"
            onClick={() => props.handleConfirmDelete()}
            id="campaign_delete_bulk_email_delete_button"
          />
        </Box>
      </>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={DeleteImg} alt="delete Image" />
      </Box>
    );
  };

  return (
    <>
      <CustomDialog
        dialogHeaderContent={dialogHeaderContent()}
        isDialogOpen={props.openModal}
        closable
        closeButtonVisibility
        handleDialogClose={handleCloseModel}
        dialogTitleContent={dialogTitleContent()}
        dialogBodyContent={dialogBody()}
        dialogFooterContent={dialogContent()}
        width="450px"
      />
    </>
  );
};

export default DeleteBulkEmailModel;
