import history from "utils/history";
import urls from "global/constants/UrlConstants";
import { store } from "utils/store";
import { logOutAction } from "redux/authSlice";
import strings from "global/constants/StringConstants";
import notifiers from "global/constants/NotificationConstants";
import { isTruthy } from "helpers/methods";
import { clearPersistenceAction } from "redux/persistSlice";

export const fetchData = (
  url: any,
  methodType: any,
  authRequired: any,
  bodyObject = {},
  isFile = false
) => {
  let headers = {};
  let requestOptions = {};
  if (authRequired && isFile) {
    headers = { "Content-Type": "multipart/form-data" };
  } else if (authRequired) {
    headers = { "Content-Type": "application/json" };
  } else {
    headers = { "Content-Type": "application/json" };
  }

  if (
    (Object.keys(bodyObject).length >= 0 || isFile) &&
    methodType.match(/POST|DELETE|PATCH/gi)
  ) {
    requestOptions = {
      method: methodType,
      headers: headers,
      body: JSON.stringify(bodyObject),
    };
  }
  // var storageString: any = "current_user";
  if (isFile) {
    let user = JSON.parse(sessionStorage.getItem("current_user")!);
    requestOptions = {
      method: methodType,
      headers: { Authorization: "Bearer " + store.getState().auth.accessToken },
      body: bodyObject,
    };
  }
  if (
    Object.keys(bodyObject).length === 0 &&
    methodType.match(/GET|DELETE|PATCH/gi)
  ) {
    requestOptions = {
      method: methodType,
      headers: headers,
    };
  }
  return fetch(url, requestOptions).then(handleResponse);
};

export const handleResponse = (response: any) => {
  return response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 403 || response.status === 401) {
        sessionStorage.clear();
        history.push(urls.loginViewPath);
      }
      const error = (data && data.errorMessage) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
};

export const checkStatus = (error: any) => {
  if (error.status === 401) {
    store.dispatch(logOutAction());
    store.dispatch(clearPersistenceAction());
    history.push(urls.landingViewPath);
    return true;
  } else return false;
};

// Authenticated Call Headers For MFA
export const getMFACallParams = async (
  token: string,
  methodType: string,
  body?: any,
  signal?: any
) => {
  const accessToken = "Bearer " + token;
  return {
    method: methodType,
    headers: await getHeaderObject(accessToken, strings.applicationJSON),
    body: JSON.stringify(body),
    signal,
  };
};

export async function getCallParams(methodType: string, body?: any) {
  const accessToken = "Bearer " + store.getState().auth.accessToken;
  return {
    method: methodType,
    headers: await getHeaderObject(accessToken, strings.applicationJSON),
    body: JSON.stringify(body),
  };
}

export async function getSortingSearchedCallParams(
  methodType: string,
  body?: any
) {
  const accessToken = "Bearer " + store.getState().auth.accessToken;
  return {
    method: methodType,
    headers: await getHeaderObject(accessToken, strings.applicationJSON),
    body: JSON.stringify(body),
  };
}

export async function getSearchedCallParams(methodType: string, body?: any) {
  const accessToken = "Bearer " + store.getState().auth.accessToken;
  return {
    method: methodType,
    headers: await getHeaderObject(accessToken, strings.applicationJSON),
    body: body,
  };
}

export async function getCredentialCallParams(methodType: string, body?: any) {
  const accessToken = "Bearer " + store.getState().auth.accessToken;
  return {
    credentials: "include",
    method: methodType,
    body: JSON.stringify(body),
    headers: await getHeaderObject(accessToken, strings.applicationJSON),
  };
}

export async function getAccessToken(
  methodType: string,
  body?: any,
  token?: string
) {
  const accessToken = "Bearer " + token;
  return {
    method: methodType,
    headers: await getHeaderObject(accessToken, strings.applicationJSON),
    ...(isTruthy(body) && { body: JSON.stringify(body) }),
  };
}

export function getNoAuthCallParams(methodType: string, body?: any) {
  return {
    method: methodType,
    headers: strings.applicationJSON,
    body: JSON.stringify(body),
  };
}

export async function getNoAuthCallParamsSelenium(
  methodType: string,
  body?: any
) {
  const accessToken = "Bearer " + store.getState().auth.accessToken;
  return {
    method: methodType,
    headers: await getHeaderObject(accessToken, strings.applicationJSON),
    body: JSON.stringify(body),
  };
}

export async function getFileCallParams(body: any) {
  const accessToken = "Bearer " + store.getState().auth.accessToken;
  return {
    method: "POST",
    headers: await getHeaderObject(accessToken),
    body: body,
  };
}

export async function getNoAuthFileCallParams(body: any) {
  return {
    method: "POST",
    headers: strings.multipartForm,
    body: body,
  };
}

export async function getHeaderObject(accessToken: string, contentType?: any) {
  try {
    const isMWAA = window?.location?.hostname.includes("mwaa");
    if (accessToken) {
      if (isMWAA) {
        return {
          ...contentType,
          Authorization: accessToken,
          Mwaa: isMWAA,
        };
      }
      return {
        ...contentType,
        Authorization: accessToken,
      };
    } else {
      history.push(urls.landingViewPath);
    }
  } catch (error: any) {
    throw error;
  }
}

export async function makeCall(
  callName: string,
  callParams: any,
  apiTimeOut: number = 20000
) {
  try {
    let call = fetch(callName, callParams);
    let response: any;
    if (urls.BYPASS.some((url) => callName.includes(url))) {
      response = await Promise.race([call]).catch((error) => {
        throw error;
      });
    } else {
      let timeout = getTimeoutPromise(apiTimeOut);
      response = await Promise.race([call, timeout]).catch((error) => {
        throw error;
      });
    }
    const json = await response.json();
    if (response && response.ok) {
      return json;
    } else {
      throw json;
    }
  } catch (error: any) {
    const isLoggedOut = checkStatus(error);
    if (isLoggedOut) {
      error.message = notifiers.LOGGEDOUT;
      error.errorMessage = notifiers.LOGGEDOUT;
    }
    throw error;
  }
}

export async function makeCallFile(callName: string, callParams: any) {
  try {
    let call = fetch(callName, callParams);
    let timeout = getTimeoutPromise();

    const response: any = await Promise.race([timeout, call]).catch((err) => {
      throw err;
    });

    // const json = await response.json();
    if (response && response.ok) {
      return response.blob();
    } else {
      throw response.blob();
    }
  } catch (error: any) {
    throw error;
  }
}

export async function makeCallUserMailCheck(callName: string, callParams: any) {
  try {
    let call = fetch(callName, callParams);
    let timeout = getTimeoutPromise();

    const response: any = await Promise.race([timeout, call]).catch((err) => {
      throw err;
    });
    const json = await response.text();
    if (response && response.ok) {
      return json;
    } else {
      throw json;
    }
  } catch (error: any) {
    throw error;
  }
}

export function getTimeoutPromise(apiTimeOut: number = 20000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject({ error: true, message: "Timeout" });
    }, apiTimeOut);
  });
}
