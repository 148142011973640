import {
  Box,
  Card,
  Chip,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import StatsStyles from "screens/Stats/Stats.styles";
import { useLocation, useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import strings from "global/constants/StringConstants";
import {
  boldFont,
  centerItemFlex,
  darkPurpledColor,
  getRelativeFontSize,
  mediumFont,
  theme,
} from "utils/styles";
import { useEffect, useState } from "react";
import noData from "assets/images/tableNoData.svg";
import notifiers from "global/constants/NotificationConstants";
import {
  openErrorNotification,
  isTruthy,
  translateFirstAndLastName,
  convertESTtoUserLocalDateAndTime,
  convertESTtoUserLocalTime,
} from "helpers/methods";
import {
  getRetargetedCampaigns,
  getCamapignActionsByStatus,
  downloadReport,
  downloadOpenedClickReport,
  getCampaignFailDataForCampaign,
  getCamapignActionsByStatusCount,
  getCampaignFailDataForCampaignCount,
  getCampaignsPerformanceData,
} from "screens/CampaignsNew/Campaigns/Campaign.services";
import {
  ActionModalStatus,
  CampaignData,
  Count,
  PerformanceReportChartData,
} from "screens/CampaignsNew/Campaigns/CampaignInterfaces";
import moment from "moment";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import RetargetModal from "screens/CampaignsNew/Campaigns/components/RetargetModal";
import ActionModals from "screens/Stats/components/ActionModals";
import RetargetedTable from "screens/CampaignsNew/Campaigns/components/RetargetedTable";
import history from "utils/history";
import { KeyboardBackspace } from "@mui/icons-material";
import { CustomButton } from "global/components";
import urls from "global/constants/UrlConstants";
import { useTitle } from "utils/UseTitle";
import { hasAccessTo } from "utils/AuthorizationManager";
import ClickedLinksTable from "screens/CampaignsNew/Campaigns/components/ClickedLinksTable";
import CustomInfoTooltip from "global/components/CustomInfoTooltip/CustomInfoTooltip";
import CachedIcon from "@mui/icons-material/Cached";
import PerformanceReportChart from "./components/PerformanceReportChart";
import { useAppSelector } from "utils/hooks";
import { selectAccount } from "redux/authSlice";
import CustomCheckboxDropDown from "global/components/CustomCheckboxDropDown/CustomCheckboxDropDown";

const options = ["All", "Opened", "Clicked", "Unsubscribed", "Failed"];

const getColorBasedOnStatus = {
  Completed: "#044f0496",
  Submitted: "rgb(146 76 175 / 23%)",
  Darft: "#ebebeb",
  Failed: "rgb(175 76 76 / 57%)",
  InProgress: "rgb(76 119 175 / 57%)",
  Sent: "rgb(76 119 175 / 57%)",
  ConfirmationPending: "rgb(76 119 175 / 57%)",
} as { [key: string]: string };

const getLabelColorsBasedOnStatus = {
  Requested: "#006BCE",
  Sent: "#BB0070",
  Success: "#1E7D33",
  Failed: "#BE2F38",
  Unsubscribed: "#C3782E",
  Opened: "#13B4CA",
  Clicked: "#462682",
} as { [key: string]: string };

const getTooltipsBasedOnStatus = {
  Sent: "",
  Success: "",
  Failed: "Click here to view the recipients",
  Unsubscribed: "Click here to view the recipients",
  Opened: "Click here to view the recipients",
  Clicked: "Click here to view the recipients",
} as { [key: string]: string };

const Stats = () => {
  useTitle(strings.StatsTitle);
  const classes = StatsStyles;
  const userAccount = useAppSelector(selectAccount);
  const isMwaa = userAccount === "mwaa";
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const campaignId: any = new URLSearchParams(useLocation().search).get("id");
  const [loader, setLoader] = useState<boolean>(false);
  const [actionModalLoader, setActionModalLoader] = useState<boolean>(false);
  const [clickedTableLoader, setClickedTableLoader] = useState<boolean>(false);
  const [graphLoader, setGraphLoader] = useState<boolean>(false);
  const [campaignData, setCampaignData] = useState<CampaignData>();
  const [performanceData, setPerformanceData] = useState<
    PerformanceReportChartData[]
  >([]);
  const [isReadMore, setIsReadMore] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [perPageData, setPerPageData] = useState<number>(5);
  const [action, setAction] = useState<string>("");
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  const [isActionModalVisible, setActionModalVisible] =
    useState<ActionModalStatus>({
      visibility: false,
      action: "Opened",
      data: [],
    });

  const hasCampaignAddAccess = hasAccessTo(
    strings.campaign,
    strings.addPermission
  );

  useEffect(() => {
    getCampaignAndCampaignActionData();
    !isMwaa && getCampaignPerformanceData();
  }, [campaignId]);

  useEffect(() => {
    if (isTruthy(action)) getOpenAndClickModalsOnClick[action]();
  }, [page]);

  const getOpenAndClickModalsOnClick = {
    Requested: () => {},
    Sent: () => {},
    Success: () => {},
    Failed: async () => {
      const data = await getCampaignFailData(campaignId!);
      return setActionModalVisible({
        visibility: true,
        action: strings.Failed,
        data: data ?? [],
      });
    },
    Unsubscribed: async () => {
      const data = await getCampaignActionData(
        campaignId!,
        strings.unsubscribed
      );
      return setActionModalVisible({
        visibility: true,
        action: strings.unsubscribed,
        data: data ?? [],
      });
    },
    Opened: async () => {
      const data = await getCampaignActionData(campaignId!, strings.Opened);
      return setActionModalVisible({
        visibility: true,
        action: strings.Opened,
        data: data ?? [],
      });
    },
    Clicked: async () => {
      const data = await getCampaignActionData(campaignId!, strings.Clicked);
      return setActionModalVisible({
        visibility: true,
        action: strings.Clicked,
        data: data ?? [],
      });
    },
  } as { [key: string]: Function };

  const getCampaignAndCampaignActionData = async () => {
    try {
      setLoader(true);
      if (campaignId) {
        const [retargetMethod] = await Promise.all([
          getRetargetedCampaigns(campaignId),
        ]);
        const countData = getFilteredCounts(retargetMethod.counts);
        const formattedCampaignData = {
          ...retargetMethod,
          counts: countData,
        };
        setCampaignData(formattedCampaignData);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setLoader(false);
    }
  };

  const getCampaignPerformanceData = async () => {
    try {
      setGraphLoader(true);
      if (campaignId) {
        const response = await getCampaignsPerformanceData(campaignId);
        const convertedData = response.map((item: any) => ({
          ...item,
          Time: convertESTtoUserLocalTime(item.Time, "hh:mm a", "HH:mm"),
        }));
        setPerformanceData(convertedData);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setGraphLoader(false);
    }
  };
  const getCampaignActionData = async (campaignId: string, action: string) => {
    try {
      setActionModalLoader(true);
      if (campaignId) {
        const count = await getCamapignActionsByStatusCount(campaignId, action);
        const response = await getCamapignActionsByStatus(
          campaignId,
          action,
          page,
          perPageData
        );
        setCount(count);
        return response;
      }
      return [];
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      return [];
    } finally {
      setActionModalLoader(false);
    }
  };

  const getCampaignFailData = async (campaignId: string) => {
    try {
      setActionModalLoader(true);
      if (campaignId) {
        const [count, response] = await Promise.all([
          getCampaignFailDataForCampaignCount(campaignId),
          getCampaignFailDataForCampaign(campaignId, page, perPageData),
        ]);
        setCount(count);
        return response;
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      return [];
    } finally {
      setActionModalLoader(false);
    }
  };

  const getFilteredCounts = (counts: Count[]) => {
    return counts.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.count }),
      {}
    );
  };

  const handleActionModalDialogClose = () => {
    setAction("");
    setActionModalVisible({
      visibility: false,
      action: isActionModalVisible.action ?? strings.Opened,
      data: [],
    });
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const getHeader = () => {
    return (
      <Grid container xs={12} alignItems="center">
        <IconButton
          onClick={() => {
            history.goBack();
          }}
          id="stats_back_button"
        >
          <KeyboardBackspace />
        </IconButton>
        <Typography sx={classes.CampaignTitle}>Campaign Details</Typography>
      </Grid>
    );
  };

  const getRefreshData = async () => {
    setIsRefresh(!isRefresh);
    await getCampaignAndCampaignActionData();
    !isMwaa && (await getCampaignPerformanceData());
  };

  const getCampaignHeader = () => {
    return (
      <Box sx={classes.campaignHeader}>
        <Stack
          direction={{
            xl: "row",
            lg: "row",
            md: "column",
            sm: "column",
            xs: "column",
          }}
          justifyContent="space-between"
          spacing={2}
        >
          <Stack direction="column" justifyContent={{ sm: "space-between" }}>
            <Typography sx={classes.heading}>
              Campaign for{" "}
              <span style={{ color: "#BB0070" }}>{campaignData?.name}</span>
            </Typography>
            <Typography sx={classes.date}>
              {campaignData?.scheduleTime
                ? convertESTtoUserLocalDateAndTime(
                    campaignData?.scheduleTime,
                    "MMM DD, YYYY"
                  )
                : moment(campaignData?.scheduleTime).format("MMM DD, YYYY")}
            </Typography>
          </Stack>
          <Stack
            display={"flex"}
            direction={{
              xl: "row",
              lg: "row",
              md: "row",
              sm: "row",
              xs: "column",
            }}
            spacing={1}
          >
            {campaignData?.status && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{
                  height: "30px",
                  width: "auto",
                  padding: 1,
                  borderRadius: "10px",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor:
                    getColorBasedOnStatus[
                      campaignData?.status ?? "rgb(76 119 175 / 57%)"
                    ],
                }}
              >
                <Box
                  sx={{
                    backgroundColor:
                      getColorBasedOnStatus[
                        campaignData?.status ?? "rgb(76 119 175 / 57%)"
                      ],
                    height: "10px",
                    width: "10px",
                    borderRadius: "100%",
                  }}
                ></Box>
                <Typography
                  sx={{
                    ...boldFont,
                    color:
                      getColorBasedOnStatus[
                        campaignData?.status ?? "rgb(76 119 175 / 57%)"
                      ],
                  }}
                >
                  {campaignData?.status}
                </Typography>
              </Stack>
            )}
            <CustomButton
              id="campaign_history_refresh_button"
              onClick={async () => await getRefreshData()}
              label={<CachedIcon htmlColor={darkPurpledColor} />}
              customClasses={classes.refreshBtn}
            />
            {hasCampaignAddAccess && (
              <>
                <RetargetModal
                  openedCount={parseInt(
                    campaignData?.counts[strings.Opened]! ?? 0
                  )}
                  clickedCount={parseInt(
                    campaignData?.counts[strings.Clicked]! ?? 0
                  )}
                  isFollowUp={
                    parseInt(campaignData?.counts[strings.Success]! ?? 0) !==
                    parseInt(campaignData?.counts[strings.Opened]! ?? 0)
                  }
                  id={campaignId}
                />
                <CustomButton
                  id="stats_resend_button"
                  label="Resend"
                  onClick={() => {
                    history.push(`${urls.resendCampaign}?id=${campaignId}`);
                  }}
                />
                {isTruthy(campaignData?.counts) && (
                  <CustomCheckboxDropDown
                    options={options}
                    campaignId={campaignId}
                    counts={campaignData?.counts}
                  />
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    );
  };

  const getDisplayPercentage = (numerator: string, denominator: string) => {
    return (
      (parseInt(numerator) / parseInt(denominator == "0" ? "1" : denominator)) *
      100
    );
  };

  const shouldShowDecimals = (numerator: string, denominator: string) => {
    return (
      (parseInt(numerator) * 100) %
        parseInt(denominator == "0" ? "1" : denominator) ===
      0
    );
  };

  const getCardCount = (label: string, count: string, divideby: string) => {
    return (
      <Tooltip
        arrow={true}
        title={getTooltipsBasedOnStatus[label]}
        placement="top"
      >
        <Card
          sx={{
            width: "auto",
            borderRadius: "10px",
            border: "1px solid #E7E7E7",
            cursor: "pointer",
            "&.MuiCard-root": {
              boxShadow: 0,
            },
          }}
          onClick={() => {
            setAction(label);
            getOpenAndClickModalsOnClick[label]();
          }}
        >
          <CardContent sx={{ display: "flex", flexDirection: "row" }}>
            <Typography sx={classes.heading}>{count}</Typography>
            <Typography sx={classes.percentagetext}>
              (
              {(
                (parseInt(count) / parseInt(divideby == "0" ? "1" : divideby)) *
                100
              ).toFixed()}
              %)
            </Typography>
          </CardContent>

          <CardActions sx={{ backgroundColor: "#E7E7E7" }}>
            <Box
              sx={{
                backgroundColor: getLabelColorsBasedOnStatus[label],
                height: "8px",
                width: "8px",
                borderRadius: "100%",
              }}
            ></Box>
            <Typography
              sx={{
                ...mediumFont,
                pt: isDesktop ? "0px" : "2px",
                fontSize: "16px",
                color: getLabelColorsBasedOnStatus[label],
                textAlign: "center",
                ml: isDesktop ? "3px !important" : "2px !important",
                [theme.breakpoints.only("lg")]: {
                  pt: "2px",
                  fontSize: "14px",
                },
              }}
            >
              {label}
            </Typography>
          </CardActions>
        </Card>
      </Tooltip>
    );
  };

  const checkSumOpenClickUnsubscribe = () => {
    const { counts } = campaignData! || {};
    const { Opened, Clicked, unsubscribed } = strings;
    const openedCount: number = Number(counts?.[Opened]!) || 0;
    const clickedCount: number = Number(counts?.[Clicked]!) || 0;
    const unsubscribedCount: number = Number(counts?.[unsubscribed]!) || 0;
    return openedCount + clickedCount + unsubscribedCount > 0;
  };

  const getStatusBoxes = () => {
    return (
      <>
        {campaignData && (
          <Grid
            container
            spacing={2}
            rowSpacing={4}
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent="center"
          >
            <Grid
              item
              xl={1.7}
              lg={1.7}
              md={4}
              sm={6}
              xs={12}
              sx={{
                paddingLeft: isDesktop ? "10px !important" : "0px",
              }}
            >
              {getCardCount(
                strings.Requested,
                campaignData.counts[strings.Requested]! ?? 0,
                campaignData.counts[strings.Requested]! ?? 1
              )}
            </Grid>
            <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12}>
              {getCardCount(
                strings.Sent,
                campaignData?.counts[strings.Sent]! ?? 0,
                campaignData.counts[strings.Requested]! ?? 1
              )}
            </Grid>
            <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12}>
              {getCardCount(
                strings.Success,
                campaignData?.counts[strings.Success]! ?? 0,
                campaignData.counts[strings.Requested] ?? 1
              )}
            </Grid>
            <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12}>
              {getCardCount(
                strings.Failed,
                campaignData?.counts[strings.Failed]! ?? 0,
                campaignData.counts[strings.Requested]! ?? 1
              )}
            </Grid>
            <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12}>
              {getCardCount(
                strings.Opened,
                campaignData?.counts[strings.Opened]! ?? 0,
                campaignData.counts[strings.Success]! ?? 1
              )}
            </Grid>
            <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12}>
              {getCardCount(
                strings.Clicked,
                campaignData?.counts[strings.Clicked]! ?? 0,
                campaignData.counts[strings.Success]! ?? 1
              )}
            </Grid>
            <Grid item xl={1.7} lg={1.7} md={4} sm={6} xs={12}>
              {getCardCount(
                strings.unsubscribed,
                campaignData?.counts[strings.unsubscribed]! ?? 0,
                campaignData.counts[strings.Success]! ?? 1
              )}
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const getRetargetData = () => {
    return (
      <RetargetedTable
        id={campaignId ?? ""}
        isLoading={loader}
        setIsLoading={setLoader}
        isRefresh={isRefresh}
      />
    );
  };

  const renderClickActionDetails = () => {
    return (
      <ClickedLinksTable
        id={campaignId ?? ""}
        isLoading={loader}
        setIsLoading={setClickedTableLoader}
        isRefresh={isRefresh}
      />
    );
  };

  const getNoRetargetedComponent = () => {
    return (
      <Container>
        <Box sx={{ ...centerItemFlex, flexDirection: "column" }} m={6}>
          <img src={noData} />
          <Typography mt={2} sx={classes.mediumFont} gutterBottom>
            No data to display!
          </Typography>
        </Box>
      </Container>
    );
  };

  const getCampaignDesignAndAttachments = () => {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          px: 0,
          mt: isDesktop ? 1 : 0,
        }}
      >
        <Box sx={classes.camapignBox}>
          <Typography sx={classes.statsheading}>
            <span style={{ ...boldFont }}>From:</span>{" "}
            {campaignData?.fromId ?? ""}
          </Typography>
          <Typography sx={classes.statsheading}>
            {campaignData?.groups?.map((data: any) => {
              const recipientList = !isReadMore
                ? data.value.map((value: any) => value.name).join(", ")
                : data.value
                    .map((value: any) => value.name)
                    .slice(0, 2)
                    .join(", ");

              return (
                <Typography>
                  <span style={{ ...boldFont }}> To:</span> {recipientList}
                  {data.value.length > 2 && (
                    <span
                      onClick={toggleReadMore}
                      style={{
                        marginLeft: 5,
                        ...boldFont,
                        backgroundColor: "#D6D6D6",
                        padding: "2px 8px",
                        cursor: "pointer",
                        borderRadius: "5px",
                        fontSize: getRelativeFontSize(),
                      }}
                    >
                      {isReadMore
                        ? `+ ${data.value.length - 2} more `
                        : "show less"}
                    </span>
                  )}
                </Typography>
              );
            })}
          </Typography>
          <Typography sx={classes.statsheading}>
            <span style={{ ...boldFont }}> Subject:</span>{" "}
            {translateFirstAndLastName(campaignData?.subject ?? "")}
          </Typography>
          <Box mt={2} mb={2}>
            {campaignData?.content && (
              <Box
                sx={{
                  width: "100%",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <iframe
                  srcDoc={campaignData?.content}
                  width="100%"
                  height="600px"
                  frameBorder="0"
                ></iframe>
              </Box>
            )}
          </Box>
          <Box>
            {campaignData?.attachments &&
              Object.keys(campaignData.attachments).map((name) => {
                return (
                  <Chip
                    label={name}
                    sx={{ width: "120px", mr: 1 }}
                    component="a"
                    variant="outlined"
                    clickable
                  />
                );
              })}
          </Box>
        </Box>
      </Container>
    );
  };

  const getDetailsSection = () => {
    return (
      <Grid container my={4}>
        <Stack
          display={"flex"}
          direction={"row"}
          justifyContent={"flex-end"}
          width={"100%"}
          pr={3}
          pb={1}
        >
          {isTruthy(campaignData) && (
            <Typography sx={classes.date}>
              <span style={{ color: "#BB0070" }}>Last updated: </span>{" "}
              {convertESTtoUserLocalDateAndTime(campaignData?.updatedOn ?? "")}
            </Typography>
          )}
        </Stack>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {getStatusBoxes()}
        </Grid>
        {!isMwaa && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box mt={2}>
              <Box ml={1} pt={"5px"} display={"flex"}>
                <Typography py={2} sx={classes.clickedLinksTableTitle}>
                  Performance Report
                </Typography>
                <Box
                  ml={1}
                  pt={"4px"}
                  display={"flex"}
                  justifyContent={"center"}
                  textAlign={"center"}
                  alignItems={"center"}
                >
                  <CustomInfoTooltip
                    placement="top"
                    infoMessage={
                      "This graph illustrates the count of opens and clicks over the first 24 hours."
                    }
                  />
                </Box>
              </Box>
              {isTruthy(performanceData) ? (
                <PerformanceReportChart performanceData={performanceData} />
              ) : (
                getNoRetargetedComponent()
              )}
            </Box>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={12} sm={12} xl={6}>
            {renderClickActionDetails()}
            <Stack display={"flex"} direction={"row"} mt={2}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                textAlign={"center"}
                alignItems={"center"}
              >
                <Typography py={2} sx={classes.clickedLinksTableTitle}>
                  Retargetted Campaigns
                </Typography>
              </Box>
              <Box
                ml={1}
                pt={"5px"}
                display={"flex"}
                justifyContent={"center"}
                textAlign={"center"}
                alignItems={"center"}
              >
                <CustomInfoTooltip
                  placement="top"
                  infoMessage={strings.Retargetted_Campaign_Table_Info}
                />
              </Box>
            </Stack>
            {campaignData?.haveChildren
              ? getRetargetData()
              : getNoRetargetedComponent()}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={6}
            mt={isDesktop ? "20px" : 0}
          >
            {getCampaignDesignAndAttachments()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box sx={{ m: 4 }}>
      {getHeader()}
      {getCampaignHeader()}
      {getDetailsSection()}
      <ActionModals
        isModalVisible={isActionModalVisible?.visibility ?? false}
        handleClose={handleActionModalDialogClose}
        actionType={isActionModalVisible.action ?? strings.Opened}
        actionData={isActionModalVisible.data}
        actionCount={count}
        page={page}
        setPage={setPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
      />
      <CustomLoader
        isLoading={
          loader || actionModalLoader || clickedTableLoader || graphLoader
        }
      />
    </Box>
  );
};

export default Stats;
