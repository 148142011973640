export const initialValue = (data: any) => {
  return {
    campaignName: {
      value: "",
      error: "",
    },
    displayName: {
      value: data?.displayName ?? "",
      error: "",
    },
    from: {
      value: data?.fromId ?? "",
      error: "",
    },
    subject: {
      value: data?.subject ?? "",
      error: "",
    },
    scheduleTime: {
      value: "",
      error: "",
    },
  } as any;
};

export const validateForStepOne = (stepOneFormData: any) => {
  let errors = stepOneFormData;
  let isValid = true;
  const from = stepOneFormData?.from?.value;
  const campaignName = stepOneFormData?.campaignName?.value;
  const displayName = stepOneFormData?.displayName?.value;
  const subject = stepOneFormData?.subject?.value;
  if (!campaignName && !subject && !displayName) {
    // Set the fields as error true
    errors.campaignName.error = "Please enter campaign name";
    errors.displayName.error = "Please enter display name";
    errors.subject.error = "Please enter subject";
    errors.from.error = "Please select from dropdown";
    isValid = false;
  }
  if (!campaignName) {
    errors.campaignName.error = "Please enter campaign name";
    isValid = false;
  }
  if (!from) {
    errors.from.error = "Please select from dropdown";
    isValid = false;
  }
  if (!displayName) {
    errors.displayName.error = "Please enter display name";
    isValid = false;
  }
  if (!subject) {
    errors.subject.error = "Please enter subject";
    isValid = false;
  }
  return { isValid, errors };
};
