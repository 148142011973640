import urls from "global/constants/UrlConstants";
import { getCallParams, makeCall } from "utils/service";

export const getCustomDomains = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.fetchCustomDomains, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const addCustomDomain = async (domainToBeAdded: any) => {
  try {
    const callParams = await getCallParams("POST", domainToBeAdded);
    const response: any = await makeCall(urls.addCustomDomain, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getDomainDetails = async (domainName: string) => {
  try {
    const url = `${urls.getDomainDetails}/${domainName}`;
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
