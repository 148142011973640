import {
  boldFont,
  buttonWhiteBg,
  getRelativeFontSize,
  regularFont,
  pureWhiteColor,
} from "utils/styles";

const CreateCampaignsCardStyles = {
  mainCardStyles: {
    height: "290px",
    maxWidth: "300px",
    width: "100%",
    padding: "20px",
    transition: "all 0.3 ease",
    border: `1px solid ${buttonWhiteBg}`,
    borderRadius: "15px",
  },
  permittedCardStyles: {
    "&:hover": {
      boxShadow: "0px 10px 40px 5px rgba(0, 0, 0, 0.05)",
      background: pureWhiteColor,
      transition: "all 0.3 ease",
      cursor: "pointer",
    },
  },
  notPermittedCardStyles: {
    cursor: "not-allowed",
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${buttonWhiteBg}`,
    borderRadius: "16px",
    height: "60px",
    width: "60px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.12)",
  },
  titleBox: { margin: "20px 0 10px 0" },
  titleTextStyles: {
    fontSize: getRelativeFontSize(8),
    ...boldFont,
    lineHeight: "31.33px",
  },
  descriptionTextStyles: {
    fontSize: getRelativeFontSize(2),
    ...regularFont,
    lineHeight: "22.78px",
  },
  unauthorizedUserMessage: {
    ...regularFont,
    color: "#ff4244",
  },
} as const;

export default CreateCampaignsCardStyles;
