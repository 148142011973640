export const handleValidateForDisableMFA = (
  credentials: any,
  setCredentials: Function
) => {
  let errors = credentials;
  let isValid: boolean = true;
  const password = credentials.password.value;
  if (!password) {
    errors.password.error = "Please enter password";
    isValid = false;
  }
  setCredentials({ ...errors });
  return isValid;
};

export const handleValidate = (credentials: any, setCredentials: Function) => {
    let errors = credentials;
    let isValid: boolean = true;
    const password = credentials.password.value;
    const verificationCode = credentials.code.value;
    if (!verificationCode && !password) {
      errors.code.error = "Please enter TOTP";
      errors.password.error = "Please enter password";
      isValid = false;
    }
    if (!verificationCode) {
      errors.code.error = "Please enter TOTP";
      isValid = false;
    }
    if (!password) {
      errors.password.error = "Please enter password";
      isValid = false;
    }
    setCredentials({ ...errors });
    return isValid;
  };
