import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Popover,
  Typography,
  IconButton,
} from "@mui/material";
import BillingUsageStyles from "screens/Settings/BillingUsage/BillingUsage.styles";
import { CustomButton, CustomTable } from "global/components";
import AddCardModal from "screens/Settings/BillingUsage/Component/AddCardModal";
import star from "assets/images/Star.svg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  convertESTtoUserLocalDateAndTime,
  getFormattedStatsCount,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import urls from "global/constants/UrlConstants";
import {
  getAccountPlanDetail,
  featurePlan,
  setDefaultCard,
  changePlanService,
  cancelSubscription,
  checkActivePlan,
  getInvoiceDetail,
  getInvoiceDetailCount,
  getDailyUsedEmailCount,
} from "screens/Settings/BillingUsage/BillingUsage.service";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { getCard } from "screens/Settings/BillingUsage/BillingUsage.service";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  buttonWhiteBg,
  mediumFont,
  pinkDarkColor,
  purplePrimaryColor,
} from "utils/styles";
import NoData from "assets/images/tableNoData.svg";
import cardIcon from "assets/images/Card-Flags.svg";
import delIcon from "assets/images/iconDelete.svg";
import right from "assets/images/right.svg";
import DeleteCardModel from "screens/Settings/BillingUsage/Component/DeleteCardModal";
import { deleteCard } from "screens/Settings/BillingUsage/BillingUsage.service";
import noPaymentImage from "assets/images/OBJECTS.svg";
import AddDefaultCardModel from "screens/Settings/BillingUsage/Component/AddDefaultCardModal";
import { useAppSelector } from "utils/hooks";
import { selectAccount } from "redux/authSlice";
import { store } from "utils/store";
import ChangePlan from "screens/Settings/BillingUsage/Component/ChangePlan";
import { PricingData } from "models/interfaces";
import PlanPayment from "screens/Settings/BillingUsage/Component/PlanPayment";
import { updatePlan } from "screens/Settings/BillingUsage/BillingUsage.service";
import CancelSubscriptionModal from "screens/Settings/BillingUsage/Component/CancelSubscriptionModal";
import UpdatePlanModal from "screens/Settings/BillingUsage/Component/UpdatePlanModal";
import { hasAccessTo } from "utils/AuthorizationManager";
import strings from "global/constants/StringConstants";
import DownloadIcon from "@mui/icons-material/Download";
import { getCustomDomains } from "screens/Settings/Domains/Domains.service";
import DomainsRedirectionModal from "./Component/DomainsRedirectionModal";

const usageCardColor = {
  "Used emails": "#BB0070",
  "Allowed emails": "#462682",
  "Allowed contacts": "#ADC804",
  "Used contacts": "#0069A9",
  "Plan Valid till": "#E73745",
  "Allowed dailyEmails": "#462682",
  "Used dailyEmails": "#BB0070",
} as any;

const invoiceDetailHeader = [
  {
    name: "Invoice #",
    field: "invoiceNumber",
  },
  {
    name: "Plan",
    field: "planName",
  },
  {
    name: "Start Date",
    field: "startDate",
  },
  {
    name: "End Date",
    field: "endDate",
  },
  {
    name: "Status",
    field: "paymentStatus",
  },
  {
    name: "Action",
    field: "invoiceUrl",
  },
];

interface CustomProps {
  setTabValue: Function;
}

const BillingUsages = (props: CustomProps) => {
  const classes = BillingUsageStyles;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const promise = loadStripe(urls.STRIPE_PUBLIC_KEY);
  const [cardData, setCardData] = useState([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isToggleContent, setIsToggleContent] = useState<string | null>(null);
  const [featurePlanDetail, setFeaturePlan] = useState<any>({});
  const [cardId, setCardId] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [defaultCardModal, setDefaultCardModal] = useState<boolean>(false);
  const [cancelSubscriptionModalBox, setCancelSubscriptionModalBox] =
    useState<boolean>(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [accountPlan, setAccountPlan] = useState<any>({});
  const accountName = useAppSelector(selectAccount);
  const [billingUsageStepper, setBillingUsageStepper] = useState<any>(0);
  const [selectedPlan, setSelectedPlan] = useState<PricingData>(
    {} as PricingData
  );
  const [isSubscriptionCancel, setIsSubscriptionCancel] =
    useState<boolean>(false);
  const [updatePlanModal, setUpdatePlanModal] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [invoiceList, setInvoiceList] = useState<any>([]);
  const [plans, setPlans] = useState<any>({});
  const [currency, setCurrency] = useState<string | null | undefined>("");
  const [cardDetailData, setCardDetailData] = useState<any>([]);
  const [perPageData, setPerPageData] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [emailCount, setEmailCount] = useState<number>(0);
  const [domains, setDomains] = useState<any[]>([]);
  const [isDomainsModalOpen, setIsDomainsModalOpen] = useState<boolean>(false);
  const hasBillingAddAccess = hasAccessTo(
    strings.billing,
    strings.addPermission
  );
  const hasBillingUpdateAccess = hasAccessTo(
    strings.billing,
    strings.updatePermission
  );
  const hasBillingDeleteAccess = hasAccessTo(
    strings.billing,
    strings.deletePermission
  );

  useEffect(() => {
    fetchBillingDetailTable();
  }, [page, perPageData]);

  useEffect(() => {
    billingUsageApiHandler();
  }, []);

  const fetchBillingDetailTable = async () => {
    try {
      setLoading(true);
      const [invoiceDetail, invoiceCount] = await Promise.all([
        getInvoiceDetail(page, perPageData),
        getInvoiceDetailCount(),
      ]);
      const resInvoiceDetail = convertTableInvoiceData(invoiceDetail);
      setInvoiceList(resInvoiceDetail);
      setCount(invoiceCount);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const billingUsageApiHandler = async () => {
    try {
      setLoading(true);
      const [
        accountDetail,
        fetaureResponse,
        cardResponse,
        res,
        dailyEmailCount,
        customDomains,
      ] = await Promise.all([
        getAccountPlanDetail(),
        featurePlan(accountName),
        getCard(),
        checkActivePlan(),
        getDailyUsedEmailCount(),
        getCustomDomains(),
      ]);
      setAccountPlan(accountDetail);
      setFeaturePlan(fetaureResponse);
      setCardData(cardResponse);
      setIsSubscriptionCancel(res);
      setEmailCount(dailyEmailCount);
      setDomains(customDomains);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handlePerPageData = (event: any) => {
    const perPageVal = event.target.value;
    setPerPageData(perPageVal);
    setPage(1);
  };

  const customDialog = () => {
    return (
      <Elements stripe={promise}>
        <AddCardModal
          setOpenModal={setOpenModal}
          openModal={openModal}
          fetchCardDetailHandler={billingUsageApiHandler}
        />
      </Elements>
    );
  };

  const setDefaultCardHandler = async () => {
    try {
      setLoading(true);
      const res = await setDefaultCard(cardId);
      openSuccessNotification(res.message);
      setDefaultCardModal(false);
      setIsToggleContent("");
      await billingUsageApiHandler();
      setLoading(false);
    } catch (error: any) {
      setDefaultCardModal(false);
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    value: string
  ) => {
    setAnchorEl(event.currentTarget);
    setIsToggleContent(value);
  };

  const primaryCardModal = () => {
    return (
      <AddDefaultCardModel
        openModal={defaultCardModal}
        setOpenModal={setDefaultCardModal}
        handleConfirmDelete={setDefaultCardHandler}
      />
    );
  };

  const cardDataHandler = (item: any, item2: any) => {
    return (
      <Card sx={classes.mainCard}>
        <CardActions>
          <Box
            sx={{
              backgroundColor: usageCardColor[item],
              height: "16px",
              width: "16px",
              borderRadius: "4px",
              mr: 1,
            }}
          />
          <Typography sx={classes.mainCardContent}>{item}</Typography>
        </CardActions>
        <CardContent sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={classes.mainCardContentSection}>
            {item === "Used dailyEmails" ? emailCount : item2}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const getUsageBoxes = () => {
    return (
      <Grid container spacing={1}>
        {accountPlan?.appPlanLimitList
          ?.filter((item: any) => item.entity !== "entity")
          ?.map((item: any, index: number) => {
            return (
              <>
                <Grid item xl={2.5} lg={2.5} md={4} sm={6} xs={12} key={index}>
                  {cardDataHandler(
                    `Allowed ${item.entity}s`,
                    getFormattedStatsCount(item.limit ?? 0)
                  )}
                </Grid>
                <Grid item xl={2.2} lg={2.5} md={4} sm={6} xs={12}>
                  {cardDataHandler(
                    `Used ${item.entity}s`,
                    getFormattedStatsCount(item.current ?? 0)
                  )}
                </Grid>
              </>
            );
          })}
        <Grid item xl={2.5} lg={2} md={4} sm={6} xs={12}>
          {accountPlan.validTill !== null &&
            cardDataHandler(
              "Plan Valid till",
              convertESTtoUserLocalDateAndTime(
                accountPlan.validTill,
                "Do MMM, YYYY"
              )
            )}
        </Grid>
      </Grid>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteCardModalHandler = () => {
    return (
      <DeleteCardModel
        openModal={deleteModal}
        setOpenModal={setDeleteModal}
        handleConfirmDelete={deleteCardHandler}
      />
    );
  };

  const deleteCardHandler = async () => {
    try {
      setLoading(true);
      const res = await deleteCard(cardId);
      openSuccessNotification(res.message ?? "Delete Card Successfully");
      setDeleteModal(false);
      await billingUsageApiHandler();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const updatePlanDialog = () => {
    return (
      <UpdatePlanModal
        setOpenModal={setUpdatePlanModal}
        openModal={updatePlanModal}
        handleConfirm={handleNext}
        planDetail={accountPlan}
      />
    );
  };

  const getDomainsRedirectionModal = () => {
    return (
      <DomainsRedirectionModal
        isDomainsModalOpen={isDomainsModalOpen}
        setIsDomainsModalOpen={setIsDomainsModalOpen}
        setTabValue={props.setTabValue}
      />
    );
  };

  const billingDetailsSection = () => {
    return (
      <Grid container mt={3} gap={3}>
        <Grid
          item
          xl={5.8}
          lg={5.8}
          md={12}
          sm={12}
          xs={12}
          sx={classes.billingDetail}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignContent={"center"}
            alignItems={"center"}
            mt={2}
          >
            <Typography sx={classes.heading} ml={4}>
              Card Details
            </Typography>
            {cardData.length > 0 && hasBillingAddAccess && (
              <CustomButton
                id="billing_usage_add_card_button"
                label={"Add Card"}
                onClick={() => setOpenModal(true)}
                customClasses={{ width: "120px", marginRight: "20px" }}
              />
            )}
          </Box>
          {cardData?.length > 0 ? (
            cardData.map((item: any, index: number) => {
              return (
                <>
                  <Box
                    key={index}
                    display={"flex"}
                    sx={classes.billingDetailBox}
                    mb={2}
                    ml={4}
                    mr={4}
                    mt={2}
                    justifyContent={"space-between"}
                  >
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Typography sx={{ flexGrow: 1 }}>
                        XXXX-XXXX-XXXX-
                      </Typography>
                      <Typography sx={{ flexGrow: 1 }}>
                        {item.number}
                      </Typography>
                      {item.default && (
                        <Typography
                          sx={{
                            flexGrow: 1,
                            textAlign: { xs: "left", sm: "right" },
                            color: pinkDarkColor,
                            fontWeight: "bold",
                          }}
                          ml={1}
                        >
                          (Primary)
                        </Typography>
                      )}
                    </Box>
                    {!item.default && (
                      <Box
                        component={"button"}
                        sx={classes.actionTab}
                        onClick={(e) => handleClick(e, item.id)}
                      >
                        <MoreHorizIcon fontSize="small" />
                      </Box>
                    )}
                    {isToggleContent === item.id && (
                      <>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Card sx={classes.cardBox}>
                            {hasBillingUpdateAccess && (
                              <Box display={"flex"} gap={1}>
                                <Box component={"img"} src={cardIcon} />
                                <Typography
                                  sx={{
                                    ...mediumFont,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setDefaultCardModal(true);
                                    setCardId(item.id);
                                  }}
                                >
                                  Set as Primary
                                </Typography>
                              </Box>
                            )}
                            {!item.default && hasBillingDeleteAccess && (
                              <Box display={"flex"} gap={2}>
                                <Box component={"img"} src={delIcon} />
                                <Typography
                                  sx={{
                                    ...mediumFont,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setDeleteModal(true);
                                    setCardId(item.id);
                                  }}
                                >
                                  Delete Card
                                </Typography>
                              </Box>
                            )}
                          </Card>
                        </Popover>
                      </>
                    )}
                  </Box>
                </>
              );
            })
          ) : (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              flexDirection="column"
              spacing={2}
            >
              <Grid item xs={12}>
                <Box
                  component="img"
                  src={noPaymentImage}
                  display="block"
                  mx="auto"
                  height="auto"
                  width="100%"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={classes.noPaymentText}>
                  No Payment Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={classes.noPaymentTextSubContent}>
                  We guess this is your first time! Select a Plan now{" "}
                </Typography>
                <Typography sx={classes.noPaymentTextSubContent}>
                  to get started with Campaigns!
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xl={5.8}
          lg={5.8}
          md={12}
          sm={12}
          xs={12}
          sx={{
            background: "#FFFFFF",
            boxShadow: " 0px 8px 30px rgba(0, 0, 0, 0.07)",
            borderRadius: "10px",
            width: "auto",
            height: "467px",
          }}
        >
          <Box
            sx={classes.planCard}
            display={"flex"}
            justifyContent={"center"}
            borderRadius={"9px 9px 1px 0px"}
          >
            <Box component={"img"} src={star} />
            <Typography sx={classes.yourPlan}>Your Plan</Typography>
          </Box>
          {!isSubscriptionCancel && cardData.length > 0 ? (
            <Box textAlign="center">
              <Box
                component="img"
                src={NoData}
                overflow="auto"
                height="100px"
                width="100%"
              />
              <Typography
                sx={{
                  ...mediumFont,
                  fontSize: { xl: "19px", xs: "14px" },
                }}
              >
                You don't have any active plan. Please Update your plan.
              </Typography>
            </Box>
          ) : (
            <Box mt={2}>
              <Typography sx={classes.planName}>
                {featurePlanDetail?.name}
              </Typography>
              <Typography sx={classes.contentHeading}>
                Send the right content at the right time with testing
              </Typography>
              <Typography sx={classes.featureHeading}>
                and scheduling features
              </Typography>
              <Typography
                sx={classes.mediumText}
                style={{ textAlign: "center" }}
              >
                <span style={classes.purpleHeadingText}>
                  {featurePlanDetail.currency === "INR"
                    ? `₹${getFormattedStatsCount(
                        featurePlanDetail?.price ?? 0
                      )}`
                    : `$${getFormattedStatsCount(
                        featurePlanDetail?.price ?? 0
                      )}`}
                </span>
              </Typography>
              <Typography fontWeight={"bold"} textAlign={"center"}>
                Validity: {featurePlanDetail.validityInDays} days
              </Typography>
            </Box>
          )}
          <Grid container spacing={2} mt={{ xl: 0, xs: 0 }} p={2}>
            {featurePlanDetail.feature &&
              featurePlanDetail?.feature?.map((item: any) => {
                return (
                  <Grid item xs={4}>
                    <Box sx={classes.featureContent}>
                      <Box component={"img"} src={right} />
                      <Typography sx={classes.featureContentData}>
                        {item}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
          {featurePlanDetail?.price === 0 && isSubscriptionCancel ? (
            <Box display={"flex"} justifyContent={"center"}>
              {hasBillingAddAccess && (
                <CustomButton
                  id="billing_usage_change_plan_button"
                  label={"Change Plan"}
                  onClick={() => {
                    domains.length === 0
                      ? setIsDomainsModalOpen(true)
                      : handleNext();
                  }}
                  customClasses={{ width: "130px" }}
                />
              )}
            </Box>
          ) : (
            <>
              {!isSubscriptionCancel ? (
                <Box display={"flex"} justifyContent={"center"} gap={2}>
                  {hasBillingUpdateAccess && (
                    <CustomButton
                      id="billing_usage_upgrade_plan_button"
                      label={"Upgrade Plan"}
                      onClick={() => {
                        domains.length === 0
                          ? setIsDomainsModalOpen(true)
                          : handleNext();
                      }}
                      customClasses={{ width: "130px" }}
                    />
                  )}
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  gap={2}
                  pb={2}
                  mt={{ xl: 1, xs: 1 }}
                >
                  {hasBillingDeleteAccess && (
                    <CustomButton
                      id="billing_usage_cancel_plan_button"
                      label={"Cancel Subscription"}
                      onClick={() => {
                        setCancelSubscriptionModalBox(true);
                      }}
                      customClasses={{
                        width: { xl: "180px", xs: "150px" },
                        background: "none",
                        border: "1px solid",
                        borderColor: buttonWhiteBg,
                        color: purplePrimaryColor,
                        "&:hover": {
                          background: "none",
                        },
                      }}
                    />
                  )}
                  {hasBillingUpdateAccess && (
                    <CustomButton
                      id="billing_usage_upgrade_plan_button"
                      label={"Upgrade Plan"}
                      onClick={() => {
                        domains.length === 0
                          ? setIsDomainsModalOpen(true)
                          : setUpdatePlanModal(true);
                      }}
                      customClasses={{ width: "130px" }}
                    />
                  )}
                </Box>
              )}
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  const handleNext = () => {
    setBillingUsageStepper((prevActiveStep: number) => prevActiveStep + 1);
    setUpdatePlanModal(false);
  };

  const handleBack = () => {
    setBillingUsageStepper((prevActiveStep: number) => prevActiveStep - 1);
  };

  const getDetailSection = () => {
    return (
      <Grid container mt={2}>
        <Grid item xs={12}>
          <Typography sx={classes.heading}>Usage</Typography>
          {getUsageBoxes()}
        </Grid>
      </Grid>
    );
  };

  const createPlanCreateIntent = async (pi: any) => {
    try {
      const data = {
        app: urls.APP,
        account: accountName,
        email: store.getState().auth.email,
        name: store.getState().auth.userName,
        contactNo: "",
        planId: selectedPlan.id,
        paymentMethodId: pi,
      };
      setLoading(true);
      const res = await changePlanService(data);
      openSuccessNotification(res.message);
      setBillingUsageStepper(0);
      await billingUsageApiHandler();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const updatePlanHandler = async () => {
    try {
      setLoading(true);
      const res = await updatePlan(selectedPlan.id);
      openSuccessNotification(res.message);
      setBillingUsageStepper(0);
      await billingUsageApiHandler();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const cancelSubscriptionHandler = async () => {
    try {
      setLoading(true);
      const res = await cancelSubscription();
      const [active, _] = await Promise.all([
        checkActivePlan(),
        billingUsageApiHandler(),
      ]);
      openSuccessNotification(res.message);
      setIsSubscriptionCancel(active);
      setCancelSubscriptionModalBox(false);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const mainSectionRender = () => {
    return (
      <Container maxWidth="lg">
        {getDetailSection()}
        {billingDetailsSection()}
        {invoiceDetailTable()}
      </Container>
    );
  };

  const cancelSubscriptionModel = () => {
    return (
      <CancelSubscriptionModal
        openModal={cancelSubscriptionModalBox}
        setOpenModal={setCancelSubscriptionModalBox}
        handleConfirm={cancelSubscriptionHandler}
      />
    );
  };

  const convertTableInvoiceData = (data: string[]) => {
    return data.map((item: any, index: number) => {
      return {
        invoiceNumber: item.invoiceNumber,
        planName: item.planName,
        startDate: convertESTtoUserLocalDateAndTime(
          item.startDate,
          "Do MMM, YYYY",
          "MM/DD/YYYY"
        ),
        endDate: convertESTtoUserLocalDateAndTime(
          item.endDate,
          "Do MMM, YYYY",
          "MM/DD/YYYY"
        ),
        paymentStatus: (
          <Chip
            key={index}
            label={item.paymentStatus}
            sx={classes.addTagChip}
            variant="filled"
          />
        ),
        invoiceUrl: (
          <>
            <IconButton
              onClick={() => {
                window.open(item.invoiceUrl, "_blank");
              }}
            >
              <DownloadIcon />
            </IconButton>
          </>
        ),
      };
    });
  };

  const invoiceDetailTable = () => {
    return (
      <>
        <Box sx={{ overflow: "auto", width: "100%", my: 2 }}>
          <Typography sx={classes.heading} mt={2}>
            Billing Details
          </Typography>
          <CustomTable
            headers={invoiceDetailHeader}
            rows={invoiceList}
            handlePageChange={handleChangePage}
            paginationCount={count}
            pageNumber={page}
            setPage={setPage}
            perPageData={perPageData}
            handlePerPageData={handlePerPageData}
            rowsPerPage={perPageData}
            isLoading={loading}
          />
        </Box>
      </>
    );
  };

  const getContent = () => {
    switch (billingUsageStepper) {
      case 0:
        return mainSectionRender();
      case 1:
        return (
          <>
            <ChangePlan
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              handleNext={handleNext}
              handleBack={handleBack}
              plans={plans}
              setCurrency={setCurrency}
              currency={currency}
              setCardData={setCardDetailData}
              cardData={cardDetailData}
              setPlans={setPlans}
              setCardDetailData={setCardDetailData}
              setLoading={setLoading}
            />
          </>
        );
      case 2:
        return (
          <>
            <Box m={4}>
              <Elements stripe={promise}>
                <PlanPayment
                  chosenPlan={selectedPlan}
                  cardData={cardData}
                  finalSubmitPayment={createPlanCreateIntent}
                  updatePlanHandler={updatePlanHandler}
                  featurePlanDetail={featurePlanDetail}
                  handleBack={handleBack}
                />
              </Elements>
            </Box>
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        {getContent()}
        {customDialog()}
        {deleteCardModalHandler()}
        {primaryCardModal()}
        {cancelSubscriptionModel()}
        {updatePlanDialog()}
        {getDomainsRedirectionModal()}
      </Container>
      <CustomLoader isLoading={loading} />
    </>
  );
};
export default BillingUsages;
