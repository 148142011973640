import urls from "global/constants/UrlConstants";
import { getCallParams, getFileCallParams, makeCall } from "utils/service";

export const doFetchTicketById = async (id: string) => {
  const url = `${urls.TICKET_BY_ID}/${id}`;
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};


export const doViewTicketAttachment = async (file: any) => {
  try {
    const callParams = await getCallParams("POST", file);
    const response: any = await makeCall(urls.VIEW_FILE, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};


export const doAddComment = async (
  comments: any
) => {
  try {
    const callParams = await getCallParams("POST", comments);
    const response: any = await makeCall(urls.ADD_COMMENT, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};