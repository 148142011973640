import React, { useEffect, useState, useRef } from "react";
import { Typography, Box, Grid, FormHelperText } from "@mui/material";
import { useLocation } from "react-router-dom";
import { CustomButton } from "global/components";
import { BootstrapInput } from "utils/styles";
import ResetPasswordStyles from "screens/LandingPage/components/ResetPassword.styles";
import history from "utils/history";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import urls from "global/constants/UrlConstants";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import {
  updatePwd,
  verifyTempPwd,
} from "screens/LandingPage/landingPageService";
import notifiers from "global/constants/NotificationConstants";
import strings from "global/constants/StringConstants";

interface CustomProps {
  executeRecaptcha: any;
}

const ResetPassword = React.forwardRef((props: CustomProps, ref: any) => {
  const classes = ResetPasswordStyles;
  const [user, setUser] = useState<any>();
  const context = new URLSearchParams(useLocation().search).get("context");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [newPasswordIconType, setNewPasswordIconType] = useState(false);
  const [confirmPasswordIconType, setConfirmPasswordIconType] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formFields, setFormFields] = useState<any>({
    newPassword: {
      value: "",
      inputError: false,
    },
    confirmPassword: {
      value: "",
      inputError: false,
    },
  });

  useEffect(() => {
    verifyPwd(context);
  }, []);

  const verifyPwd = async (token: any) => {
    try {
      const response: any = await verifyTempPwd(token);
      setUser(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      history.push(urls.loginViewPath);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
        inputError: event.target.value.replace(/\s/g, "").length <= 0,
      },
    });
  };

  const handleSubmitButton = () => {
    if (formFields.newPassword.value.length > 7) {
      if (
        formFields.newPassword.value === formFields.confirmPassword.value &&
        strings.passwordValidationRegex.test(formFields.newPassword.value) &&
        strings.passwordValidationRegex.test(formFields.confirmPassword.value)
      ) {
        updatePassword();
      } else {
        setFormFields({
          ...formFields,
          confirmPassword: {
            value: formFields.confirmPassword.value,
            inputError: true,
          },
          newPassword: {
            value: formFields.newPassword.value,
            inputError: true,
          },
        });
      }
    } else {
      setFormFields({
        newPassword: {
          value: formFields.newPassword.value,
          inputError: true,
        },
        confirmPassword: {
          value: formFields.confirmPassword.value,
          inputError: true,
        },
      });
    }
  };

  const updatePassword = async () => {
    try {
      user.pwd = formFields.newPassword.value;
      let captchaToken = "";
      if (urls.PROD) {
        captchaToken = await props.executeRecaptcha();
      }
      setLoading(true);
      const token = user.authToken;
      const response: any = await updatePwd(user, token);
      openSuccessNotification("Your password has been changed successfully!");
      setLoading(false);
      history.push(urls.landingViewPath);
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      history.push(urls.loginViewPath);
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmitButton();
    }
  };

  const togglePassword = () => {
    if (newPasswordType === "password") {
      setNewPasswordType("text");
      setNewPasswordIconType(true);
      return;
    }
    setNewPasswordType("password");
    setNewPasswordIconType(false);
  };

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      setConfirmPasswordIconType(true);
      return;
    }
    setConfirmPasswordType("password");
    setConfirmPasswordIconType(false);
  };

  const getResetPasswordPage = () => {
    return (
      <Box>
        <Box>
          <Typography sx={classes.getHeading}>Change Password</Typography>
          <Grid container sx={classes.formCenter}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={classes.label}>
                <Typography sx={classes.labelText}>New Password</Typography>
                <Typography sx={classes.star}>*</Typography>
              </Box>
              <BootstrapInput
                sx={
                  formFields.newPassword.inputError
                    ? classes.error
                    : classes.select
                }
                placeholder="Enter your new password"
                id="newPassword"
                type={newPasswordType}
                name="newPassword"
                value={formFields.newPassword.value}
                onChange={handleOnChange}
                onKeyPress={handleKeypress}
                endAdornment={
                  <Box onClick={togglePassword}>
                    {newPasswordIconType ? (
                      <RemoveRedEyeIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </Box>
                }
              />
              {!strings.passwordValidationRegex.test(
                formFields.newPassword.value
              ) &&
                formFields.newPassword.value?.length > 0 && (
                  <FormHelperText error>
                    Please enter a password that is at least 8 characters long
                    and includes at least one digit, one lowercase letter, one
                    uppercase letter, and one special character from the
                    following: @ $ ! % * ? & #
                  </FormHelperText>
                )}
              {formFields.newPassword.value.length <= 0 &&
                formFields.newPassword.inputError && (
                  <FormHelperText error>
                    Please input your new password
                  </FormHelperText>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={classes.label}>
                <Typography sx={classes.labelText}>Confirm Password</Typography>
                <Typography sx={classes.star}>*</Typography>
              </Box>
              <BootstrapInput
                sx={
                  formFields.confirmPassword.inputError
                    ? classes.error
                    : classes.select
                }
                placeholder="Enter your confirm password"
                id="confirmPassword"
                type={confirmPasswordType}
                name="confirmPassword"
                value={formFields.confirmPassword.value}
                onChange={handleOnChange}
                onKeyPress={handleKeypress}
                endAdornment={
                  <Box onClick={toggleConfirmPassword}>
                    {confirmPasswordIconType ? (
                      <RemoveRedEyeIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </Box>
                }
              />
              {formFields.confirmPassword.value !==
                formFields.newPassword.value &&
                formFields.confirmPassword.value.length > 0 && (
                  <FormHelperText error>
                    Confirm password doesn't match
                  </FormHelperText>
                )}
              {formFields.confirmPassword.value.length <= 0 &&
                formFields.confirmPassword.inputError && (
                  <FormHelperText error>
                    Please input your Confirm password
                  </FormHelperText>
                )}
              {!strings.passwordValidationRegex.test(
                formFields.confirmPassword.value
              ) &&
                formFields.confirmPassword?.value?.length > 0 && (
                  <FormHelperText error style={{ paddingLeft: "6px" }}>
                    Please enter a password that is at least 8 characters long
                    and includes at least one digit, one lowercase letter, one
                    uppercase letter, and one special character from the
                    following: @ $ ! % * ? & #
                  </FormHelperText>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box marginTop={"10px"} width={"100%"}>
                <CustomButton
                  label="Submit"
                  onClick={handleSubmitButton}
                  loading={loading}
                  disabled={loading}
                  customClasses={classes.submitBtn}
                  id="reset_password_submit_button"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  return getResetPasswordPage();
});

export default ResetPassword;
