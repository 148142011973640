import {
  getRelativeFontSize,
  primaryHeadingColor,
  theme,
  boldFont,
  regularFont,
  pinkDarkColor,
  pureWhiteColor,
  disabledBackgroundColor,
} from "utils/styles";

const WhatsAppTemplateStyles = {
  headerSection: {
    padding: "40px 20px 18px 20px",
  },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  },
  mainContentBox: {
    padding: theme.spacing(4),
  },
  tableContainerBox: {
    minWidth: "300px",
    width: "100%",
    overflow: "auto",
  },
  templateNameStyles: {
    ...boldFont,
    color: pinkDarkColor,
    fontSize: "14px",
    padding: " 0",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  ActionBtn: {
    width: "170px",
    border: "1px solid #ccc",
    background: "none",
    display: "flex",
    gap: "5px ",
    fontSize: "12px",
    padding: "2px 15px",
    color: "rgba(0,0,0,0.7)",
    ...boldFont,
  },
  centeredItem: {
    display: "flex",
    justifyContent: "center",
  },
  tooltipComponent: { backgroundColor: disabledBackgroundColor },
  tooltipText: {
    padding: "10px",
    color: pureWhiteColor,
    fontSize: getRelativeFontSize(),
    ...regularFont,
  },
  tooltipComponentPropsStyles: {
    background: "transparent",
  },
  syncIconStyles: { fontSize: "18px" },
} as const;
export default WhatsAppTemplateStyles;
