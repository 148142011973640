import urls from "global/constants/UrlConstants";
import { User } from "models/interfaces";
import {
  getNoAuthCallParams,
  makeCall,
  getCallParams,
  getAccessToken,
  getMFACallParams,
  makeCallUserMailCheck,
} from "utils/service";

export const login = async (
  email: string,
  password: string,
  captchaToken: string
) => {
  try {
    const body = {
      email,
      pwd: password,
      app: "mailzzy",
      captchaToken,
      captchaVersion: "v3",
    };
    const callParams = getNoAuthCallParams("POST", body);
    const response: any = await makeCall(urls.loginUser, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const loginUsingPasskey = async (
  email: string,
  captchaToken: string
) => {
  try {
    const body = {
      email,
      app: "mailzzy",
      captchaToken,
    };
    const callParams = getNoAuthCallParams("POST", body);
    const response: any = await makeCall(urls.passkeyLoginUser, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const createNewAccount = async (user: User) => {
  try {
    const body = {
      ...user,
      captchaVersion: "v3",
    };
    const callParams = getNoAuthCallParams("POST", body);
    const response: any = await makeCall(urls.registerUser, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const forgotPassword = async (user: User) => {
  try {
    const body = {
      ...user,
      captchaVersion: "v3",
    };
    const callParams = getNoAuthCallParams("POST", body);
    const response: any = await makeCall(urls.forgotPassword, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const verifyTempPwd = async (authToken: string) => {
  try {
    const requestBody = {
      authToken,
    };
    const callParams = getNoAuthCallParams("POST", requestBody);
    const response: any = await makeCall(urls.verifyTempPwd, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updatePwd = async (user: User, token: string) => {
  try {
    const body = {
      ...user,
      captchaVersion: "v3",
    };
    const callParams = await getAccessToken("POST", body, token);
    const response: any = await makeCall(urls.updatePwd, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const activateUser = async (user: User, token: string) => {
  try {
    const callParams = await getAccessToken("POST", user, token);
    const response: any = await makeCall(urls.activateUser, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getManager = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.isManager, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getFirstNameMandatory = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.isFirstNameMandatory, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export async function verifyTOTP(email: string, authenticatorCode: number) {
  try {
    const body = {
      email,
      authenticatorCode,
    };
    const callParams = getNoAuthCallParams("POST", body);
    const response: any = await makeCall(urls.VERIFY_TOTP, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const activateMFA = async (
  token: string,
  email: string,
  password: string,
  authenticatorCode: string
) => {
  try {
    const body = {
      email,
      pwd: password,
      authenticatorCode,
      multiFactorLogin: true,
    };
    const callParams = await getMFACallParams(token, "POST", body);
    const response: any = await makeCall(urls.ACTIVATE_MFA, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const generateQRCodeForMFA = async (token: string) => {
  try {
    const callParams = await getMFACallParams(token, "GET");
    const response: any = await makeCall(urls.MFA_GET_QR_CODE, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchCampaignRetentionDuration = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.campaignRetentionDuration,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchPreAuthSecretKey = async (token: string) => {
  try {
    const callParams = await getMFACallParams(token, "GET");
    const response: any = await makeCallUserMailCheck(
      urls.MFA_GET_PREAUTH_KEY,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};