import {
  Box,
  Chip,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import socialStyles from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/Social.styles";
import { useEffect, useState } from "react";
import {
  uploadDocument,
  postFeedToFacebook,
  sendSocialCampaign,
  socialCampaignExistsCheck,
} from "screens/Integration/component/FacebookService";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { CustomAppHeader, CustomButton, CustomInput } from "global/components";
import { postFeedValidation } from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/SocialTypesAndValidations";
import {
  linkedInImageUpload,
  linkedInImageWithPost,
  linkedInPost,
} from "screens/Integration/component/Linkedin.service";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  changeFacebookPageAction,
  selectFacebookPageName,
} from "redux/persistSlice";
import { useAppSelector } from "utils/hooks";
import { selectEmail } from "redux/authSlice";
import ScheduleTimeModal from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/ScheduleTimeModal";
import FacebookCard from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/FacebookCard";
import InstagramCard from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/component/InstagramCard";
import strings from "global/constants/StringConstants";
import CustomDropzone from "global/components/CustomDropzone/CustomDropzone";
import urls from "global/constants/UrlConstants";
import history from "utils/history";
import { miniSocialCards } from "../SocialData";

interface DesignProps {
  selectedAppsList: string;
  faceBookPage?: any;
  setActiveStep: Function;
  linkedInUserDetails: any;
  postData: any;
  setPostData: Function;
  instagramAccountData: any;
  setInstagramAccountData: Function;
  postImage: string[];
  setPostImage: Function;
  setIsLoading: Function;
}
const Design = (props: DesignProps) => {
  const classes = socialStyles;
  const dispatch = useDispatch();
  const userId = useAppSelector(selectEmail);
  const facebookPageName = useAppSelector(selectFacebookPageName);
  const acceptedFilesExtension: string[] = [".jpeg", ".jpg", ".png"];

  const [isPostLinkedIn, setPostLinkedIn] = useState<boolean>(false);
  const [uploadImageAsset, setUploadImageAsset] = useState<any>();
  const [carousalIndex, setCarousalIndex] = useState<number>(0);
  const [isScheduleTimeModalOpen, setIsScheduleTimeModalOpen] =
    useState<boolean>(false);
  const [selectedSocialApp, setSelectedSocialApp] = useState<string>(
    props.selectedAppsList
  );
  const [totalAllowedUploadImages, setTotalAllowedUploadImages] =
    useState<number>(0);

  const [linkedInPostData, setLinkedPostData] = useState<any>({
    shareCommentaryValue: {
      value: "",
    },
    postDescription: {
      value: "",
    },
    postTitle: {
      value: "",
    },
  });

  // useEffect(() => {
  //   if (isTruthy(postData.file.value)) {
  //     uploadImage();
  //     // linkedInImageUplaodHandler();
  //   }
  // }, [postData.file.value]);

  // useEffect(() => {
  //   postFeedFaceBook();
  // }, [isLoading]);

  useEffect(() => {
    getAllowedUploadImages();
  }, [selectedSocialApp]);

  const getAllowedUploadImages = () => {
    switch (selectedSocialApp) {
      case strings.FACEBOOK:
        setTotalAllowedUploadImages(4);
        break;
      case strings.INSTAGRAM:
        setTotalAllowedUploadImages(10);
        break;
    }
  };
  const handleFormDataChange = (event: React.ChangeEvent<any>) => {
    props.setPostData({
      ...props.postData,
      file: {
        ...props.postData.file,
        error:
          event.target.name === "content" &&
          selectedSocialApp === strings.FACEBOOK
            ? ""
            : props.postData.file.error,
      },
      [event.target.name]: {
        ...props.postData[event.target.name],
        value: event.target.value,
        error: "",
      },
    });
  };

  const faceBookAccountDataHandler = (selectedPageId: any) => {
    const { name: facebookPageName, instagram_business_account } =
      props.faceBookPage?.metaData?.pageDetails.find(
        (item: any) => item.id === selectedPageId
      );
    dispatch(
      changeFacebookPageAction({
        facebookPageName: facebookPageName,
        facebookPageId: selectedPageId,
      })
    );

    return instagram_business_account;
  };

  const instagramAccountDataHandler = (accountData: any) => {
    if (isTruthy(accountData)) {
      const {
        username: instagramAccountName,
        profile_picture_url: instagramProfilePicture,
      } = accountData;
      props.setInstagramAccountData({
        profileTitle: instagramAccountName,
        profilePicture: instagramProfilePicture,
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    const instagramAccountInfo = faceBookAccountDataHandler(event.target.value);
    instagramAccountDataHandler(instagramAccountInfo);

    props.setPostData({
      ...props.postData,
      [event.target.name]: {
        ...props.postData[event.target.name],
        value: event.target.value,
      },
    });
  };

  // LinkedIn Image Post Function Only
  const linkedInImageUplaodHandler = async () => {
    try {
      props.setIsLoading(true);
      const data = new FormData();
      const fileData = props.postData?.file?.file;
      data.append("file", fileData);
      const response = await linkedInImageUpload(data);
      setUploadImageAsset(response.value.asset);
      props.setIsLoading(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  // LinkedIn Image Post With data
  const imageUploadWithPostLinked = async () => {
    try {
      props.setIsLoading(true);
      const response = await linkedInImageWithPost(
        props?.linkedInUserDetails?.userId,
        uploadImageAsset
      );
      openSuccessNotification("Image UploadSuccessFully");
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  // LinkedIn Post Only function
  const createLinkedInPostHandler = async () => {
    try {
      props.setIsLoading(true);
      const response = await linkedInPost(
        props?.linkedInUserDetails?.userId,
        linkedInPostData?.shareCommentaryValue?.value,
        linkedInPostData?.postDescription?.value,
        linkedInPostData?.postTitle?.value
      );
      openSuccessNotification("LinkedIn Post Successfully");
      props.setIsLoading(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getAppAccountInfo = (socialAppName: string) => {
    let appAccountName: string;

    switch (socialAppName) {
      case strings.INSTAGRAM: {
        appAccountName = props.instagramAccountData.profileTitle;
        break;
      }
      default: {
        appAccountName = facebookPageName;
        break;
      }
    }

    return { appAccountName };
  };

  const createSocialCampaignPayload = (socialAppName: string) => {
    const { appAccountName } = getAppAccountInfo(socialAppName);
    return {
      owner: userId,
      name: props.postData.socialCampaignName.value.trim(),
      socialAppName: socialAppName,
      scheduleTime: props.postData.scheduleTime.value
        ? moment(props.postData.scheduleTime.value)
            .tz("America/New_York")
            .format("MM/DD/yyyy HH:mm:ss")
        : "",
      socialSource: appAccountName,
      socialCampaign: {
        link: [...props.postImage],
        message: props.postData.content.value,
        pageId: props.postData.publish.value,
      },
    };
  };

  const handleBackButton = () => {
    props.setActiveStep((prevStep: number) => prevStep - 1);
  };

  const handleSendSocialCampaign = async () => {
    try {
      props.setIsLoading(true);
      if (handleValidation()) {
        await socialCampaignExistsCheck(
          props.postData.socialCampaignName.value.trim(),
          selectedSocialApp
        );
        setIsScheduleTimeModalOpen(true);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error?.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const redirectToCampaignsView = (status: any) => {
    const searchParams = new URLSearchParams({ status }).toString();
    const viewPath = `${urls.socialCampaignsViewPath}?${searchParams}`;
    history.push(viewPath);
  };

  //Post Page Data In FaceBook
  const postFeedData = async () => {
    try {
      if (handleValidation()) {
        props.setIsLoading(true);
        if (isPostLinkedIn) {
          await Promise.all([
            linkedInPost(
              props?.linkedInUserDetails?.userId,
              props.postData.content?.value,
              "First Two in Post",
              "Two in Post"
            ),
            postFeedToFacebook(
              props.postData.publish.value,
              props.postData.content.value,
              props.postImage
            ),
          ]);
        } else {
          const payload = createSocialCampaignPayload(selectedSocialApp);
          const response = await sendSocialCampaign(payload);
          openSuccessNotification(
            response.message ??
              "Social Campaign has been submitted successfully"
          );
        }
        redirectToCampaignsView(strings.Submitted);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error?.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsScheduleTimeModalOpen(false);
      props.setIsLoading(false);
    }
  };

  //Form Error validation Handler
  const handleValidation = () => {
    const { isValid, errors }: { errors: any; isValid: boolean } =
      postFeedValidation(props.postData, selectedSocialApp);
    props.setPostData({ ...errors });
    return isValid;
  };

  const handleOnDropZoneFile = async (event: any) => {
    try {
      let postDataImageArray: any[] = [...props.postData.file.value];
      let postImageArray: string[] = [...props.postImage];
      let duplicateImagesArray: string[] = [];
      let reject4mbFiles: string[] = [];
      let rejectedDimensionFiles: string[] = [];
      let invalidExtensionFiles: string[] = [];
      let errorsArray: string[] = [];
      if (
        !(event.length <= totalAllowedUploadImages - props.postImage.length)
      ) {
        return openErrorNotification(
          `Only ${totalAllowedUploadImages} images can be uploaded`
        );
      }
      props.setIsLoading(true);
      for (const element of event) {
        const imageName = element.name;
        const imageSize = element.size;
        const isFileExtensionInCorrect = checkFileExtension(imageName);
        const isImagePresent = isDuplicateImagePresent(imageName);
        const invalidDimension = await isImageDimensionNotValid(element);
        switch (true) {
          case isFileExtensionInCorrect:
            invalidExtensionFiles.push(imageName);
            break;
          case isImagePresent:
            duplicateImagesArray.push(imageName);
            break;
          case imageSize > 4194304:
            reject4mbFiles.push(imageName);
            break;
          case selectedSocialApp === strings.INSTAGRAM && invalidDimension:
            rejectedDimensionFiles.push(imageName);
            break;
          default:
            const data = new FormData();
            data.append("file", element);
            const response = await uploadDocument(data);
            postImageArray.push(response.message);
            postDataImageArray.push(element);
            break;
        }
      }
      props.setIsLoading(false);
      props.setPostImage(postImageArray);
      props.setPostData({
        ...props.postData,
        file: {
          value: postDataImageArray,
          error: "",
        },
        content: {
          ...props.postData.content,
          error: "",
        },
      });
      setErrorMessage(
        errorsArray,
        invalidExtensionFiles,
        `${invalidExtensionFiles.join(
          ", "
        )} doesn't have valid file extension. `
      );
      setErrorMessage(
        errorsArray,
        duplicateImagesArray,
        `${duplicateImagesArray.join(", ")} ${
          duplicateImagesArray.length > 1 ? "are" : "is"
        } already uploaded. `
      );
      setErrorMessage(
        errorsArray,
        reject4mbFiles,
        `${reject4mbFiles.join(", ")} ${
          reject4mbFiles.length > 1 ? "are" : "is"
        } over 4 MB. `
      );
      setErrorMessage(
        errorsArray,
        rejectedDimensionFiles,
        `${rejectedDimensionFiles.join(
          ", "
        )} does not have the aspect ratio between 0.8 to 1.96. `
      );
      if (isTruthy(errorsArray)) {
        openErrorNotification(errorsArray);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const setErrorMessage = (
    mainErrorArray: string[],
    individualErrorArray: string[],
    errorMessage: string
  ) => {
    if (isTruthy(individualErrorArray)) {
      mainErrorArray.push(errorMessage);
    }
  };

  const checkFileExtension = (imageName: string) => {
    const fileExtension = imageName
      .substring(imageName.lastIndexOf("."))
      .toLowerCase();
    const isPresent = acceptedFilesExtension.includes(fileExtension);
    return !isPresent;
  };

  const isImageDimensionNotValid = async (image: any) => {
    let isInvalid: boolean = true;
    if (image && image.type.startsWith("image/")) {
      const objectURL = URL.createObjectURL(image);
      const img = new Image();
      img.src = objectURL;
      await img.decode();
      let aspectRatio = img.width / img.height;
      let roundOff: number = parseFloat(aspectRatio.toFixed(2));
      isInvalid = roundOff >= 0.8 && roundOff <= 1.91 ? false : true;
    }
    return isInvalid;
  };
  const isDuplicateImagePresent = (imageName: string) => {
    const postImageNames: string[] = props.postData.file.value.map(
      (image: any) => image.name
    );
    const isPresent = postImageNames.some((name: string) => name === imageName);
    return isPresent;
  };

  const handleDeleteDropZoneFile = (index: number) => {
    props.setPostData({
      ...props.postData,
      file: {
        ...props.postData["file"],
        value: props.postData.file.value.filter(
          (item: any, itemIndex: number) => itemIndex !== index
        ),
      },
    });
    props.setPostImage(
      props.postImage.filter(
        (item: any, itemIndex: number) => itemIndex !== index
      )
    );
    setCarousalIndex(0);
  };

  const getSocialCard = () => {
    switch (selectedSocialApp) {
      case strings.FACEBOOK: {
        return (
          <FacebookCard
            isCardClickable={false}
            facebookPage={props.faceBookPage}
            scheduleTime={props.postData.scheduleTime.value}
            postContent={props.postData.content.value}
            imageArray={props.postImage}
            carousalIndex={carousalIndex}
            setCarousalIndex={setCarousalIndex}
          />
        );
      }
      case strings.INSTAGRAM: {
        return (
          <InstagramCard
            isCardClickable={false}
            facebookPage={props.faceBookPage}
            postContent={props.postData.content.value}
            imageArray={props.postImage}
            carousalIndex={carousalIndex}
            setCarousalIndex={setCarousalIndex}
            instagramProfilePicture={props.instagramAccountData.profilePicture}
            instagramAccountName={props.instagramAccountData.profileTitle}
          />
        );
      }
    }
  };

  const getMainContent = () => {
    return (
      <Grid container xs={12} sm={12} md={12} lg={10} xl={10} margin="0 auto">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5}
          xl={5}
          sx={classes.designLeft}
        >
          <Box sx={classes.DesignPostHeader}>
            <Typography sx={classes.DesignPostTitle}>Design Post</Typography>
          </Box>
          <Box sx={classes.formWrapper}>
            <Box sx={{ marginTop: "20px" }}>
              <CustomInput
                required
                label="Campaign Name"
                id="social_campaign_name_field"
                type="text"
                name="socialCampaignName"
                placeHolder="Enter Campaign Name"
                propsToInputElement={{ maxlength: 40 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span>
                        {props.postData.socialCampaignName.value.length} / 40
                      </span>
                    </InputAdornment>
                  ),
                }}
                onChange={handleFormDataChange}
                value={props.postData.socialCampaignName.value}
                error={props.postData.socialCampaignName.error}
              />
            </Box>
            <Box sx={classes.formInput}>
              <Typography sx={classes.label}>
                Publish To{" "}
                <Box ml={0.4} sx={classes.star}>
                  *
                </Box>
              </Typography>
              <Select
                id="publish"
                name="publish"
                onChange={(event: any) => handleChange(event)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={classes.selectStyle}
                value={props.postData?.publish?.value}
                renderValue={
                  props.postData.publish.value !== ""
                    ? undefined
                    : () => " Select Publish"
                }
                error={
                  props.postData.publish.value.length < 4 &&
                  props.postData.publish.error.length !== 0
                }
              >
                {props?.faceBookPage?.metaData?.pageDetails
                  ?.filter(
                    (item: any) =>
                      selectedSocialApp === strings.FACEBOOK ||
                      (selectedSocialApp === strings.INSTAGRAM &&
                        item.hasOwnProperty(
                          strings.instagramBusinessAccountKey
                        ))
                  )
                  .map((item: any) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      sx={classes.dropdownLogoStyle}
                    >
                      {selectedSocialApp === strings.INSTAGRAM &&
                      item.hasOwnProperty(strings.instagramBusinessAccountKey)
                        ? item?.instagram_business_account?.username
                        : item.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
            {!isTruthy(props.postData.publish.value) && (
              <FormHelperText error sx={classes.errorStyle}>
                {props.postData.publish.error}
              </FormHelperText>
            )}
            <Box sx={classes.formInput}>
              <Typography sx={classes.label}>Content</Typography>
              <TextField
                multiline
                name="content"
                id="content"
                aria-label="minimum height"
                minRows={5}
                maxRows={5}
                placeholder="Enter the post content here"
                sx={classes.textAreaStyle}
                value={props.postData?.content?.value}
                error={props.postData?.content?.error}
                onChange={handleFormDataChange}
              />
              <FormHelperText error sx={classes.errorStyle}>
                {props.postData.content.error}
              </FormHelperText>
            </Box>
            <InputLabel sx={classes.input} shrink>
              Upload File{" "}
              {selectedSocialApp === strings.INSTAGRAM && (
                <Box ml={0.4} sx={classes.star}>
                  *
                </Box>
              )}
            </InputLabel>
            <Box>
              <CustomDropzone
                dropzoneText="Drag and Drop your images"
                onAdd={handleOnDropZoneFile}
              />
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {props.postData.file.value.map((item: any, index: number) => {
                  return (
                    <>
                      <Chip
                        sx={classes.previewChip}
                        label={item.name}
                        variant="filled"
                        onClick={() => {
                          setCarousalIndex(index);
                        }}
                        onDelete={() => handleDeleteDropZoneFile(index)}
                      />
                    </>
                  );
                })}
              </Box>
              <FormHelperText error sx={classes.errorStyle}>
                {props.postData.file.error}
              </FormHelperText>
              <Typography sx={classes.warningContent}>
                {`Only ${totalAllowedUploadImages} files of type .png/.jpg/.jpeg and size less than 4MB each
                are allowed.`}
                {selectedSocialApp === strings.INSTAGRAM &&
                  " The aspect ratio of the images should be between 0.8 and 1.96."}
              </Typography>
            </Box>
          </Box>

          {/* <Box display="flex" gap={2} marginTop={2}>
            <Typography sx={classes.label}>Allow To Post LinkedIn</Typography>
            <SwitchButton
              isPrivateOrPublic={isPostLinkedIn}
              handlePermission={(e: any) => {
                setPostLinkedIn(!isPostLinkedIn);
              }}
            />
          </Box> */}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5}
          xl={5}
          sx={classes.socialCardGridItem}
        >
          {getSocialCard()}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
          <Box style={classes.miniCardsContainer}>
            {miniSocialCards
              .filter((item: any) =>
                props.selectedAppsList.includes(item.appName)
              )
              .map((item: any) => {
                return (
                  <img
                    style={{ display: "flex", margin: "auto" }}
                    src={item.appLayout}
                    alt={item.altText}
                  />
                );
              })}
          </Box>
        </Grid>
        <ScheduleTimeModal
          isScheduleTimeModalOpen={isScheduleTimeModalOpen}
          setIsScheduleTimeModalOpen={setIsScheduleTimeModalOpen}
          postData={props.postData}
          setPostData={props.setPostData}
          postFeedData={postFeedData}
        />
      </Grid>
    );
  };

  const getHeaderTitle = () => {
    return (
      <Box>
        <Typography sx={classes.networkPageTitle}>
          Create Social Campaign
        </Typography>
      </Box>
    );
  };

  const getHeaderButton = () => {
    return (
      <Stack sx={classes.headerButtonStackStyles}>
        <CustomButton
          id="social_back_button"
          label="Back"
          onClick={handleBackButton}
          customClasses={classes.headerButtons}
        />
        <CustomButton
          id="social_submit_button"
          label="Submit"
          onClick={handleSendSocialCampaign}
          customClasses={classes.headerButtons}
        />
      </Stack>
    );
  };

  return (
    <>
      <CustomAppHeader className={classes.networkPageHeaderSection}>
        <Grid container sx={classes.headerGridContainer}>
          {getHeaderTitle()}
          {getHeaderButton()}
        </Grid>
      </CustomAppHeader>
      {getMainContent()}
    </>
  );
};

export default Design;
