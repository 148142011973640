import { isTruthy } from "helpers/methods";

export const whatsAppCampaignInitialState = () => {
  return {
    name: {
      value: "",
      error: "",
    },
    from: {
      value: {},
      error: "",
    },
    to: {
      value: [],
      error: "",
    },
    template: {
      value: {},
      error: "",
    },
  };
};

export const stepOneValidations = (campaignData: any) => {
  let errorLadenState = { ...campaignData };
  let isValid = true;

  if (!errorLadenState.name.value) {
    errorLadenState.name.error = "Please enter the campaign name";
    isValid = false;
  }

  return { isValid, errorLadenState };
};

export const stepTwoValidations = (campaignData: any) => {
  let errorLadenState = { ...campaignData };
  let isValid = true;

  if (!isTruthy(errorLadenState.from.value)) {
    errorLadenState.from.error = "Please select the WhatsApp number.";
    isValid = false;
  }
  if (!isTruthy(errorLadenState.to.value)) {
    errorLadenState.to.error = "Please enter at-least one phone number.";
    isValid = false;
  }
  if (!isTruthy(errorLadenState.template.value)) {
    errorLadenState.template.error =
      "Please select a template form the dropdown.";
    isValid = false;
  }

  return { isValid, errorLadenState };
};

export const getCampaignPayload = (campaignData: any) => {
  return {
    to: campaignData.to.value,
    template: {
      name: campaignData.template.value.templateName,
      language: { code: campaignData.template.value.language },
    },
  };
};
