import { useEffect, useState } from "react";
import { CustomAppHeader } from "global/components";
import SettingStyles from "screens/Settings/Setting.styles";
import { Box, Grid, Stack, Typography } from "@mui/material";
import CustomTabs from "global/components/CustomTabs/CustomTabs";
import { ProfilePage } from "screens/Settings/Profile/ProfilePage";
import { RoleManagement } from "screens/Settings/RoleManagement/RoleManagement";
import strings from "global/constants/StringConstants";
import BillingUsages from "screens/Settings/BillingUsage/BillingUsages";
import { UserProfile, tabConfig } from "screens/Settings/SettingsHelpers";
import { hasAccessTo, isAdmin } from "utils/AuthorizationManager";
import UnauthorizedPage from "screens/UnauthorizedPage/UnauthorizedPage";

import Users from "screens/Settings/Users/Users";
import { useLocation } from "react-router-dom";
import {
  isTruthy,
  openErrorNotification,
  validateTabValue,
} from "helpers/methods";
import Security from "screens/Settings/Security/Security";
import { useAppSelector } from "utils/hooks";
import { getProfileDetails } from "screens/Settings/Profile/ProfilePageServices";
import { selectEmail } from "redux/authSlice";
import notifiers from "global/constants/NotificationConstants";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import Domains from "screens/Settings/Domains/Domains";

const Settings = () => {
  const classes = SettingStyles;
  const userEmail = useAppSelector(selectEmail);
  const urlParams = new URLSearchParams(useLocation().search);
  const tabValueName = validateTabValue(urlParams.get("tabValue"));
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState<string>(tabValueName!);
  const [userProfile, setUserProfile] = useState<UserProfile>(
    {} as UserProfile
  );

  useEffect(() => {
    window.history.replaceState(null, "", `?tabValue=${tabValue}`);
    tabValue === strings.profile && fetchProfileDetails();
  }, [tabValue]);

  const handleChange = (newValue: string) => {
    setTabValue(newValue);
  };

  const fetchProfileDetails = async () => {
    try {
      setLoading(true);
      const response = await getProfileDetails(userEmail);
      setUserProfile(response);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const tabDataHandler = () => {
    return (
      <CustomAppHeader className={classes.headerBackgroundColor}>
        <Box ml={1}>
          <Typography style={classes.settingsTitle}>My Settings</Typography>
        </Box>
        <Stack
          direction={{ lg: "row", md: "column", sm: "column", xs: "column" }}
          justifyContent="space-between"
          mt={2}
        >
          <CustomTabs
            changeValue={handleChange}
            selected={tabValue}
            tabConfig={tabConfig()}
          />
        </Stack>
      </CustomAppHeader>
    );
  };

  const viewTabDataHandler = () => {
    switch (tabValue) {
      case strings.profile:
        return (
          <ProfilePage
            profile={userProfile}
            fetchProfile={fetchProfileDetails}
          />
        );
      case strings.roleManagement:
        return isAdmin() ? (
          <RoleManagement />
        ) : (
          <UnauthorizedPage pageName={strings.roleManagement} />
        );
      case strings.billingUsage:
        const hasBillingFetchAccess = hasAccessTo(
          strings.billing,
          strings.fetchPermission
        );
        return hasBillingFetchAccess ? (
          <BillingUsages setTabValue={setTabValue} />
        ) : (
          <UnauthorizedPage pageName={strings.billingUsage} />
        );
      case strings.USERS:
        return isAdmin() ? (
          <Users
            updateMFAStatus={(status: boolean) =>
              setUserProfile({
                ...userProfile,
                multiFactorLogin: status,
              })
            }
          />
        ) : (
          <UnauthorizedPage pageName={strings.USERS} />
        );
      case strings.SECURITY:
        return (
          <Security
            mfaEnabled={userProfile.multiFactorLogin ?? false}
            appLevelMFA={userProfile.mfaSetupRequired ?? true}
            updateMFAStatus={(status: boolean) =>
              setUserProfile({
                ...userProfile,
                multiFactorLogin: status,
              })
            }
          />
        );
      case strings.DOMAINS:
        return isAdmin() ? (
          <Domains />
        ) : (
          <UnauthorizedPage pageName={strings.DOMAINS} />
        );
      default:
        return (
          <ProfilePage
            profile={userProfile}
            fetchProfile={fetchProfileDetails}
          />
        );
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box>{tabDataHandler()}</Box>
        </Grid>
      </Grid>
      <CustomLoader isLoading={loading} />
      {viewTabDataHandler()}
    </>
  );
};

export default Settings;
