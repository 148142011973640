import { boldFont, getRelativeFontSize, pinkDarkColor } from "utils/styles";

const DomainListStyles = {
  domainsListContainer: {
    margin: "25px 30px",
  },
  domainNameTextStyles: {
    ...boldFont,
    cursor: "pointer",
    width: "fit-content",
    color: pinkDarkColor,
    fontSize: getRelativeFontSize(2),
  },
  domainViewButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  ActionBtn: {
    width: "100px",
    border: "1px solid #ccc",
    background: "none",
    display: "flex",
    gap: "5px ",
    fontSize: "12px",
    padding: "2px 15px",
    alignItem: "center",
    color: "rgba(0,0,0,0.7)",
    ...boldFont,
  },
} as const;

export default DomainListStyles;
