import CustomButton from "global/components/CustomButton/CustomButton";
import SocialStyles from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/Social.styles";
import { Box } from "@mui/material";
import CustomDialog from "global/components/CustomDialog/CustomDialog";
import DeleteImg from "assets/images/DeleteImg.svg";
import history from "utils/history";
import CustomLoader from "global/components/CustomLoader/CustomLoader";

interface customProps {
  isOpen?: boolean;
}

const SocialMediaIntergrationModel = (props: customProps) => {
  const classes = SocialStyles;

  const dialogTitleContent = () => {
    return (
      <>
        <Box sx={classes.dialogTitleWrapper}>
          <Box sx={classes.titleRight}>Media Integration</Box>
        </Box>
      </>
    );
  };

  const dialogBody = () => (
    <Box sx={classes.dialogContent} style={{ textAlign: "center" }}>
      There are no accounts integrated with Mailzzy.
    </Box>
  );

  const dialogContent = () => {
    return (
      <>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            label="Integrate"
            onClick={() => history.push("/integration")}
            id="social_integrate_button"
          />
        </Box>
      </>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={DeleteImg} alt="delete Image" />
      </Box>
    );
  };

  return (
    <>
      {props.isOpen ? (
        <CustomLoader isLoading={props.isOpen} />
      ) : (
        <CustomDialog
          dialogHeaderContent={dialogHeaderContent()}
          isDialogOpen={true}
          handleDialogClose={() => {
            history.push("/dashboard");
          }}
          dialogTitleContent={dialogTitleContent()}
          dialogBodyContent={dialogBody()}
          dialogFooterContent={dialogContent()}
          width="450px"
        />
      )}
    </>
  );
};

export default SocialMediaIntergrationModel;
