import { useState, useEffect } from "react";
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomDomainStyles from "screens/Settings/Domains/Domains.styles";
import { CustomButton, CustomDialog, CustomInput } from "global/components";
import addDomainModalImage from "assets/images/addDomainModalImage.svg";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import {
  domainNameValidation,
  getAddDomainPayload,
  getInitialDomainState,
} from "screens/Settings/Domains/DomainsHelpers";
import DomainList from "screens/Settings/Domains/DomainsList/DomainList";
import DomainDetails from "screens/Settings/Domains/DomainDetails/DomainDetails";
import AddDomainButtonIcon from "assets/icons/AddDomainButtonIcon.svg";
import strings from "global/constants/StringConstants";
import { useTitle } from "utils/UseTitle";
import { KeyboardBackspace } from "@mui/icons-material";
import {
  addCustomDomain,
  getCustomDomains,
} from "screens/Settings/Domains/Domains.service";
import CustomLoader from "global/components/CustomLoader/CustomLoader";

const CustomDomains = () => {
  useTitle(strings.DomainsTitle);
  const classes = CustomDomainStyles;

  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [domainToBeAdded, setDomainToBeAdded] = useState<any>(
    getInitialDomainState()
  );
  const [domainToBeDisplayed, setDomainToBeDisplayed] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<string>(strings.domainsList);
  const [domains, setDomains] = useState<any[]>([]);

  useEffect(() => {
    fetchCustomDomains();
  }, []);

  const fetchCustomDomains = async () => {
    try {
      setIsLoading(true);
      const response = await getCustomDomains();
      setDomains(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const addDomainButtonHandler = () => {
    setIsAddDialogOpen(true);
  };

  const isDomainNameValid = () => {
    const { isValid, errorLadenState } = domainNameValidation(domainToBeAdded);
    setDomainToBeAdded({ ...errorLadenState });
    return isValid;
  };

  const newDomainAddHandler = async () => {
    try {
      if (isDomainNameValid()) {
        setIsLoading(true);
        const payload = getAddDomainPayload(domainToBeAdded);
        const response = await addCustomDomain(payload);
        openSuccessNotification(
          "Your domain registration is requested successfully."
        );
        setDomainToBeAdded({ ...getInitialDomainState() });
        fetchCustomDomains();
        setIsAddDialogOpen(false);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeHandler = (event: any) => {
    setDomainToBeAdded({
      ...domainToBeAdded,
      [event.target.name]: { value: event.target.value, error: "" },
    });
  };

  const isDomainDetailsPage = () => {
    return currentPage === strings.domainDetails;
  };

  const domainViewButtonHandler = (domainName: string) => {
    setDomainToBeDisplayed(domainName);
    setCurrentPage(strings.domainDetails);
  };

  const getAddDomainButton = () => {
    return (
      <CustomButton
        id="domains_add_button"
        label={"Add Domain"}
        icon={<img src={AddDomainButtonIcon} />}
        onClick={addDomainButtonHandler}
        customClasses={classes.addButtonStyles}
      />
    );
  };

  const addDialogCloseHandler = () => {
    setIsAddDialogOpen(false);
    setDomainToBeAdded({ ...getInitialDomainState() });
  };

  const getDomainNameForAdornment = (domainName: string) => {
    return domainName.length > 35
      ? `@${domainName.substring(0, 35)}...`
      : `@${domainName}`;
  };

  const addDialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={addDomainModalImage} alt="Add Domain image" />
      </Box>
    );
  };

  const addDialogTitleContent = () => {
    return (
      <Box>
        <Typography sx={classes.boldFonts}>Add Sender Domain</Typography>
      </Box>
    );
  };

  const addDialogBodyContent = () => {
    return (
      <>
        <Box>
          <CustomInput
            id="domains_domain_name"
            label="Domain Name"
            name="domainName"
            required
            type="text"
            placeHolder="example.com"
            value={domainToBeAdded.domainName.value}
            error={domainToBeAdded.domainName.error}
            onChange={onChangeHandler}
            propsToInputElement={{ maxlength: strings.DOMAIN_NAME_LIMIT }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span>
                    {domainToBeAdded.domainName.value.length} /{" "}
                    {strings.DOMAIN_NAME_LIMIT}
                  </span>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText sx={classes.domainNameHelperText}>
            * Enter a domain which you want to use as "from" in your emails.
          </FormHelperText>
        </Box>
        <Box sx={classes.inputContainer}>
          <Box mb={1}>
            <InputLabel required sx={classes.nameField}>
              Sender ID
            </InputLabel>
          </Box>
          <TextField
            id="domains_sender_id"
            name="senderId"
            required
            type="text"
            placeholder={"Sender ID"}
            value={domainToBeAdded.senderId.value}
            error={domainToBeAdded.senderId.error}
            sx={classes.senderIdTextarea}
            onChange={onChangeHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" variant="filled">
                  <Typography>
                    {isTruthy(domainToBeAdded.domainName.value)
                      ? getDomainNameForAdornment(
                          domainToBeAdded.domainName.value
                        )
                      : "@example.com"}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          {isTruthy(domainToBeAdded.senderId.error) && (
            <FormHelperText error sx={{ paddingLeft: "13px" }}>
              {domainToBeAdded.senderId.error}
            </FormHelperText>
          )}
        </Box>
        <Box sx={classes.inputContainer}>
          <CustomInput
            id="domains_sender_name"
            label="Sender Name"
            name="senderName"
            required
            type="text"
            placeHolder="Sender Name"
            value={domainToBeAdded.senderName.value}
            error={domainToBeAdded.senderName.error}
            onChange={onChangeHandler}
          />
        </Box>
      </>
    );
  };

  const addDialogFooterContent = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <Box sx={classes.addDialogFooter}>
          <CustomButton
            id="add_domain_cancel_button"
            label="Cancel"
            onClick={addDialogCloseHandler}
            customClasses={classes.addDialogCancelButton}
          />
          <CustomButton
            id="add_domain_submit_button"
            label="Add Domain"
            onClick={newDomainAddHandler}
            loading={isLoading}
          />
        </Box>
      </Grid>
    );
  };

  const getDomainsPageHeading = () => {
    return (
      <Stack
        px={4}
        pt={2}
        direction={{ lg: "row", xs: "column" }}
        justifyContent="space-between"
        alignItems={{ lg: "center" }}
      >
        <Box sx={classes.headingContainer}>
          <Box sx={{ display: "flex" }}>
            {isDomainDetailsPage() && (
              <Box sx={classes.backIconBox}>
                <IconButton
                  onClick={() => {
                    setDomainToBeDisplayed("");
                    setCurrentPage(strings.domainsList);
                  }}
                  id="stats_back_button"
                >
                  <KeyboardBackspace />
                </IconButton>
              </Box>
            )}
            {!isDomainDetailsPage() && (
              <Typography sx={classes.pageTitle}>Domains</Typography>
            )}
          </Box>
          {isDomainDetailsPage() && (
            <Box sx={{ display: "flex" }}>
              <Typography sx={classes.domainNameStyles}>
                {domainToBeDisplayed}
              </Typography>
            </Box>
          )}
        </Box>
        {!isDomainDetailsPage() && getAddDomainButton()}
      </Stack>
    );
  };

  const getDomainsContentPage = () => {
    switch (currentPage) {
      case strings.domainsList:
        return (
          <DomainList
            domains={domains}
            domainViewButtonHandler={domainViewButtonHandler}
            isLoading={isLoading}
          />
        );
      case strings.domainDetails:
        return (
          <DomainDetails
            domainToBeDisplayed={domainToBeDisplayed}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        );
      default:
        return (
          <DomainList
            domains={domains}
            domainViewButtonHandler={domainViewButtonHandler}
            isLoading={isLoading}
          />
        );
    }
  };

  const getDomainsPage = () => {
    return (
      <>
        <Box>
          {getDomainsPageHeading()}
          {getDomainsContentPage()}
          <CustomDialog
            isDialogOpen={isAddDialogOpen}
            closable
            closeButtonVisibility
            handleDialogClose={addDialogCloseHandler}
            width={"600px"}
            dialogHeaderContent={addDialogHeaderContent()}
            dialogTitleContent={addDialogTitleContent()}
            dialogBodyContent={addDialogBodyContent()}
            dialogFooterContent={addDialogFooterContent()}
          />
        </Box>
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };

  return getDomainsPage();
};

export default CustomDomains;
