import urls from "global/constants/UrlConstants";
import { getCallParams, makeCall } from "utils/service";

export const authUserToWhatsApp = async (
  code: any,
  redirectUrl: any,
  state: any
) => {
  try {
    const body = {
      code,
      redirectUrl,
      state,
    };
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(
      urls.whatsAppUserAuthenticated,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
export const disconnectWhatsapp = async (appName: string) => {
  try {
    const callParams = await getCallParams("DELETE");
    const response = await makeCall(
      `${urls.disconnectIntegratedApp}/${appName}`,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};
