import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import customDropzoneStyles from "global/components/CustomDropzone/CustomDropzone.styles";
import { isTruthy } from "helpers/methods";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface CustomProps {
  sx?: any;
  dropzoneText: string;
  onAdd: Function;
}

const CustomDropzone = (props?: CustomProps) => {
  const classes = customDropzoneStyles;
  const [isDragOver, setIsDragOver] = useState(false);

  const appliedClass = props?.sx
    ? { ...props.sx, ...classes.dropzone }
    : classes.dropzone;

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const fileListToArray = (list: any) => {
    const result: any[] = [];
    for (const listItem of list) {
      result.push(listItem);
    }
    return result;
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);

    const files = e.dataTransfer?.files;
    if (isTruthy(files)) {
      props?.onAdd(fileListToArray(files));
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (isTruthy(files)) {
      props?.onAdd(fileListToArray(files));
    }
  };

  return (
    <>
      <input
        id="drag&drop"
        style={{ display: "none" }}
        type="file"
        onChange={handleFileInputChange}
        value={""}
        multiple
      />
      <label htmlFor="drag&drop">
        <Box
          sx={
            isDragOver ? { ...appliedClass, ...classes.dragover } : appliedClass
          }
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <CloudUploadIcon fontSize="large" />
            <Typography>{props?.dropzoneText}</Typography>
          </Stack>
        </Box>
      </label>
    </>
  );
};

export default CustomDropzone;
