import { Box, Grid, InputLabel, Typography } from "@mui/material";
import OnboardingStyles from "screens/Onboarding/Onboard.styles";
import star from "assets/images/Star.svg";
import right from "assets/images/right.svg";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import {
  getFormattedStatsCount,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import { CustomButton, CustomInput } from "global/components";
import notifiers from "global/constants/NotificationConstants";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import strings from "global/constants/StringConstants";

const cardStyle = {
  style: {
    base: {
      color: "#00000",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#000",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

interface CustomInterface {
  planFeatures: any;
  cardUserDetails: any;
  setCardUserDetails: Function;
  userName: string;
  handleValidation: Function;
  finalUserActivation: Function;
  setIsLoading: Function;
}

const NewOnboardStep1 = (props: CustomInterface) => {
  const classes = OnboardingStyles;
  const stripe = useStripe();
  const elements = useElements();

  const paymentHandler = async () => {
    try {
      props.setIsLoading(true);
      if (props.handleValidation() && stripe !== null && elements !== null) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: props.cardUserDetails.cardName.value,
            email: props.cardUserDetails.userEmail.value,
          },
        });
        if (error) {
          openErrorNotification(
            isTruthy(error) ? error.message : notifiers.GENERIC_ERROR
          );
          props.setIsLoading(false);
        } else {
          props.setIsLoading(false);
          await props.finalUserActivation(
            true,
            props.planFeatures.id,
            undefined,
            paymentMethod.id
          );
        }
      }
    } catch (error: any) {
      props.setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const getHeadingDescription = () =>
    props.planFeatures.id !== strings.FREE_PLAN_ID
      ? "Finalize your account setup with payment. We'll activate your account and notify you by email."
      : "Verifying your details. Please wait for a moment!";

  return (
    <>
      <Box sx={classes.headingContainerBox}>
        <Typography sx={classes.userTitle}>
          Hello, {props.userName ?? "user"}!
        </Typography>
        <Typography sx={classes.headingDescription}>
          {props.planFeatures && getHeadingDescription()}
        </Typography>
      </Box>
      <Grid container sx={classes.mainContainer}>
        <Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={classes.gridItemStyles}
        >
          {props.planFeatures &&
            props.planFeatures.id !== strings.FREE_PLAN_ID && (
              <Box sx={classes.borderBox}>
                <Box sx={classes.inputContainerBox}>
                  <CustomInput
                    required
                    id="onboard_card_name"
                    disabled={true}
                    label="Name"
                    name="cardName"
                    placeHolder="Name"
                    value={props.cardUserDetails?.cardName?.value}
                    error={props.cardUserDetails?.cardName?.error}
                  />
                </Box>
                <Box sx={classes.inputContainerBox}>
                  <CustomInput
                    required
                    id="onboard_user_email"
                    disabled={true}
                    label="Email"
                    name="userEmail"
                    placeHolder="Email id"
                    value={props.cardUserDetails?.userEmail?.value}
                    error={props.cardUserDetails?.userEmail?.error}
                  />
                </Box>
                <Box sx={classes.inputContainerBox}>
                  <InputLabel required sx={classes.inputLabelStyles}>
                    Card Number
                  </InputLabel>
                  <Box sx={classes.cardElementStyle}>
                    <CardElement
                      options={cardStyle}
                      id="onboard_paid_plan_card_number"
                    />
                  </Box>
                </Box>
                <Box sx={classes.buttonContainer}>
                  <CustomButton
                    id="onboard_payment_button"
                    label="PAY NOW"
                    icon={<HttpsOutlinedIcon />}
                    onClick={paymentHandler}
                    customClasses={classes.buttonStyles}
                  />
                </Box>
              </Box>
            )}
        </Grid>
        {isTruthy(props.planFeatures) && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={6}
            lg={6}
            sx={classes.gridItemStyles}
          >
            <Box sx={classes.planContainer}>
              <Box sx={classes.planCard}>
                <Box component={"img"} src={star} />
                <Typography sx={classes.yourPlan}>Your Plan</Typography>
              </Box>
              <Box mt={2}>
                <Typography sx={classes.mediumText}>
                  You have selected{" "}
                  <span style={classes.purpleHeadingText}>
                    {props.planFeatures?.name}
                  </span>{" "}
                  plan
                </Typography>
                <Typography sx={classes.shortdesc} gutterBottom>
                  Sell even more with personalization, optimization tools.
                </Typography>
                <Typography sx={classes.mediumText}>
                  <span style={classes.purpleHeadingText}>
                    {props.planFeatures.currency === "INR"
                      ? `₹${getFormattedStatsCount(
                          props.planFeatures.price ?? 0
                        )}`
                      : `$${getFormattedStatsCount(
                          props.planFeatures.price ?? 0
                        )}`}
                  </span>
                </Typography>
                <Typography fontWeight={"bold"} textAlign={"center"}>
                  Validity: {props.planFeatures.validityInDays} days
                </Typography>
              </Box>
              <Grid container spacing={2} mt={2} p={2}>
                {props?.planFeatures?.feature?.map((item: any) => {
                  return (
                    <>
                      <Grid item xs={4}>
                        <Box sx={classes.featureContent}>
                          <Box component={"img"} src={right} />
                          <Typography sx={classes.featureContentData}>
                            {item}
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box>
        <Typography sx={classes.paymentTerms}>
          By clicking “Confirm Payment,” I acknowledge that I am subscribing to
          a plan and give Mailzzy permission to charge my payment method monthly
          at the current rate (plus any applicable taxes) until I cancel. I can
          cancel at any time by visiting the Billing Usage tab in my Settings
          page.
        </Typography>
        <Typography sx={classes.paymentTerms}>
          The actual price may differ. The prices shown are estimates based on
          current exchange rates. The exact amount charged will vary according
          to the exchange rate at the time of payment processing. For exact base
          pricing, please refer to the prices listed in USD.
        </Typography>
      </Box>
    </>
  );
};

export default NewOnboardStep1;
