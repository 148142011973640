import {
  Box,
  Typography,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  Stack,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import StepOneStyles from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/StepOne/StepOne.styles";
import { CustomAppHeader, CustomButton, CustomInput } from "global/components";
import {
  stepOneValidation,
  templateCategories,
  templateLanguage,
} from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/CreateWhatsAppTemplateHelpers";
import { isTruthy, openErrorNotification } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { isWhatsAppTemplateNameUnique } from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/CreateWhatsAppTemplateServices";

interface CustomProps {
  templateData: any;
  setTemplateData: Function;
  setActiveStep: Function;
  setIsLoading: Function;
}

const StepOne = (props: CustomProps) => {
  const classes = StepOneStyles;

  const getFormattedFieldValue = (fieldName: string, event: any) => {
    switch (fieldName) {
      case "name": {
        return event.target.value.trim().toLowerCase();
      }
      default:
        return event.target.value;
    }
  };

  const handleValidation = () => {
    const { isValid, errorLadenState } = stepOneValidation(props.templateData);
    props.setTemplateData({ ...errorLadenState });
    return isValid;
  };

  const handleNextStep = async () => {
    try {
      if (handleValidation()) {
        props.setIsLoading(true);
        await isWhatsAppTemplateNameUnique(
          props.templateData.name.value,
          props.templateData.language.value
        );
        props.setActiveStep(2);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const isNameValid = (fieldName: string, event: any) => {
    const nameRegex = /^[a-zA-Z0-9_]*$/;
    const fieldValue = event.target.value;
    let isValid = true;
    if (fieldName === "name" && !nameRegex.test(fieldValue)) {
      isValid = false;
    }
    return isValid;
  };

  const onChangeHandler = (event: any) => {
    const fieldName = event.target.name;

    if (isNameValid(fieldName, event)) {
      props.setTemplateData({
        ...props.templateData,
        [fieldName]: {
          value: getFormattedFieldValue(fieldName, event),
          error: "",
        },
      });
    }
  };

  const getStepOneForm = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CustomInput
            required
            label="Name"
            id="step1_template_name_field"
            type="text"
            name="name"
            placeHolder="Enter Template Name"
            propsToInputElement={{
              maxlength: 50,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span>{props.templateData.name.value.length} / 50</span>
                </InputAdornment>
              ),
            }}
            onChange={onChangeHandler}
            value={props.templateData.name.value}
            error={props.templateData.name.error}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Stack>
            <InputLabel sx={classes.inputLabel} shrink>
              Category
              <Box sx={classes.star}>*</Box>
            </InputLabel>
          </Stack>
          <Select
            id="step1_template_category_dropdown"
            sx={classes.selectDropdownStyle}
            name="category"
            value={props.templateData.category.value}
            onChange={onChangeHandler}
            renderValue={
              props.templateData.category.value
                ? undefined
                : () => "Select a Category"
            }
            displayEmpty
            error={props.templateData.category.error}
          >
            {templateCategories.map((item, index) => (
              <MenuItem sx={classes.selectOptionStyle} key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Stack>
            <InputLabel sx={classes.inputLabel} shrink>
              Language
              <Box sx={classes.star}>*</Box>
            </InputLabel>
          </Stack>
          <Select
            id="step1_template_language_dropdown"
            sx={classes.selectDropdownStyle}
            name="language"
            value={props.templateData.language.value}
            onChange={onChangeHandler}
            renderValue={
              props.templateData.language.value
                ? undefined
                : () => "Select a Language"
            }
            displayEmpty
            error={props.templateData.language.error}
          >
            {templateLanguage.map((item, index) => (
              <MenuItem
                sx={classes.selectOptionStyle}
                key={index}
                value={item.languageCode}
              >
                {item.languageName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error sx={classes.errorMessageStyle}>
            {props.templateData?.language?.error}
          </FormHelperText>
        </Grid>
      </Grid>
    );
  };

  const getNextButton = () => {
    return (
      <Box width="150px">
        <CustomButton
          label={"Next"}
          onClick={() => {
            handleNextStep();
          }}
        />
      </Box>
    );
  };

  const getStepOneContent = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box my={2}>
            <Typography sx={classes.pageDescription}>
              Create reusable WhatsApp templates using Mailzzy. These templates
              need to be approved by the Meta first before using it for any
              WhatsApp campaigns.
            </Typography>
          </Box>
          <Box mb={2}>{getStepOneForm()}</Box>
          <Box mb={2}>{getNextButton()}</Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <CustomAppHeader className={classes.headerSection}>
        <Box>
          <Typography sx={classes.pageTitle}>
            Create WhatsApp Template
          </Typography>
        </Box>
      </CustomAppHeader>
      <Box sx={classes.mainContentBox}>{getStepOneContent()}</Box>
    </>
  );
};

export default StepOne;
