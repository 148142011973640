import {
  Box,
  FormHelperText,
  Grid,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomButton, CustomInput } from "global/components";
import CustomSimpleTable from "global/components/CustomSimpleTable/CustomSimpleTable";
import { simpleTableHeader } from "models/interfaces";
import React, { useEffect, useState } from "react";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import {
  getCredentialCreationOptions,
  createPasskey,
  fetchPasskeys,
  deletePasskey,
  editPasskeyName,
} from "screens/Settings/PassKeys/PasskeyService";
import { centerItemFlex, primaryGreenColor } from "utils/styles";
import notifiers from "global/constants/NotificationConstants";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CustomDialog from "global/components/CustomDialog/CustomDialog";
import userUpdateImg from "assets/images/userUpdateImg.svg";
import strings from "global/constants/StringConstants";
import PasskeysStyles from "./Passkeys.styles";

interface CustomProps {
  setIsLoading: Function;
}

const passkeyListTableHeader: simpleTableHeader[] = [
  {
    name: "Display Name",
    field: "displayName",
    align: "left",
  },
  {
    name: "User Name",
    field: "username",
    align: "left",
  },
  {
    name: "Action",
    field: "action",
    align: "right",
    styles: { paddingRight: "48px" },
  },
];

const Passkeys = (props: CustomProps) => {
  const classes = PasskeysStyles;
  const [passKeys, setPassKeys] = useState<any[]>([]);
  const [passkeyConfigs, setPasskeyConfigs] = useState<any>({
    credentialOptions: null,
    passKeys: [],
    passKeyData: [],
    openCreatePasskeyDialog: false,
    displayName: "",
    error: "",
    isEdit: false,
    isDelete: false,
  });

  useEffect(() => {
    getPasskeys();
  }, []);

  const getPasskeys = async () => {
    try {
      props.setIsLoading(true);
      const response = await fetchPasskeys();
      getFormattedTableRow(response);
    } catch (error: any) {
      props.setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const handleDialogClose = () => {
    setPasskeyConfigs((prevState: any) => ({
      ...prevState,
      displayName: "",
      openCreatePasskeyDialog: false,
      error: "",
      isEdit: false,
      isDelete: false,
    }));
  };

  const handleDialogOpen = () => {
    setPasskeyConfigs((prevState: any) => ({
      ...prevState,
      isEdit: false,
      isDelete: false,
      error: "",
      displayName: "",
      openCreatePasskeyDialog: true,
    }));
  };

  const updatePasskeyHandler = (displayName: string) => {
    setPasskeyConfigs((prevState: any) => ({
      ...prevState,
      openCreatePasskeyDialog: true,
      isEdit: true,
      isDelete: false,
      displayName,
    }));
  };

  const deletePasskeyHandler = (displayName: string) => {
    setPasskeyConfigs((prevState: any) => ({
      ...prevState,
      isDelete: true,
      openCreatePasskeyDialog: true,
      isEdit: false,
      displayName,
    }));
  };

  const getFormattedTableRow = (passkeys: any[]) => {
    const formattedRows = passkeys?.map((passkey: any) => {
      return {
        displayName: (
          <Box>
            <Typography sx={classes.domainNameTextStyles}>
              {passkey.displayName}
            </Typography>
          </Box>
        ),
        username: passkey.username,
        action: (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "40px",
            }}
          >
            <Tooltip title="Edit Passkey" placement="top" arrow>
              <EditIcon
                htmlColor={"#1a0224"}
                style={{ margin: "0px 8px -7px 17px", cursor: "pointer" }}
                onClick={() => {
                  setPasskeyConfigs((prevState: any) => ({
                    ...prevState,
                    passKeyData: passkey,
                  }));
                  updatePasskeyHandler(passkey.displayName);
                }}
              />
            </Tooltip>
            <DeleteOutlineIcon
              htmlColor={primaryGreenColor}
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setPasskeyConfigs((prevState: any) => ({
                  ...prevState,
                  passKeyData: passkey,
                }));
                deletePasskeyHandler(passkey.displayName);
              }}
            />
          </Box>
        ),
      };
    });
    setPassKeys(formattedRows);
  };

  const handleValidation = () => {
    if (!passkeyConfigs.displayName) {
      setPasskeyConfigs((prevState: any) => ({
        ...prevState,
        error: "Please enter passkey name",
      }));
      return false;
    }
    return true;
  };

  const handleUpdatePasskey = async () => {
    try {
      if (isTruthy(passkeyConfigs.passKeyData)) {
        props.setIsLoading(true);
        const response = await editPasskeyName({
          ...passkeyConfigs.passKeyData,
          displayName: passkeyConfigs.displayName,
        });
        openSuccessNotification(response.message);
        handleDialogClose();
        await getPasskeys();
      }
    } catch (error: any) {
      props.setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const handleCreatePasskey = async () => {
    try {
      if (isTruthy(passkeyConfigs.displayName)) {
        props.setIsLoading(true);
        const options = await getCredentialCreationOptions(
          passkeyConfigs.displayName
        );
        if (!options || options instanceof Error) {
          throw new Error("Failed to retrieve credential creation options");
        }
        setPasskeyConfigs((prevState: any) => ({
          ...prevState,
          credentialOptions: options,
          openCreatePasskeyDialog: false,
        }));
        const response = await createPasskey(options);
        openSuccessNotification(response.message);
        await getPasskeys();
      }
    } catch (error: any) {
      props.setIsLoading(false);
      if (
        error.message.includes(
          "The operation either timed out or was not allowed"
        )
      ) {
        return;
      }
      if (
        error.message ===
        "The user attempted to register an authenticator that contains one of the credentials already registered with the relying party."
      ) {
        openErrorNotification(
          "You have already added this device. Please use a different device and try again."
        );
      } else {
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
      }
    } finally {
      props.setIsLoading(false);
    }
  };

  const handleDeletePasskey = async () => {
    try {
      props.setIsLoading(true);
      const response = await deletePasskey(passkeyConfigs.passKeyData);
      openSuccessNotification(response.message);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const getAddPassKeyButton = () => {
    return (
      <CustomButton
        id="passkey_btn"
        label={"Create Passkey"}
        onClick={() => handleDialogOpen()}
        customClasses={classes.addButtonStyles}
      />
    );
  };

  const getPasskeyHeader = () => {
    return (
      <Stack
        direction={{ lg: "row", md: "row", xs: "column" }}
        justifyContent="space-between"
        alignItems={{ lg: "center" }}
      >
        <Box sx={classes.headingContainer}>
          <Box sx={{ display: "flex" }}>
            <Typography sx={classes.pageTitle}>Passkeys</Typography>
          </Box>
        </Box>
        {getAddPassKeyButton()}
      </Stack>
    );
  };

  const modalHeader = () => {
    return (
      <Box display="flex">
        <img src={userUpdateImg} alt="Update user image not found!" />
      </Box>
    );
  };

  const getDialogTitleContent = () => {
    if (passkeyConfigs.isEdit) {
      return "Update";
    } else if (passkeyConfigs.isDelete) {
      return "Delete";
    } else {
      return "Create";
    }
  };
  const updateDialogTitleContent = () => {
    return (
      <Box sx={classes.updateDialogTitleWrapper}>
        <Box
          sx={classes.updateDialogTitle}
        >{`${getDialogTitleContent()} Passkey`}</Box>
      </Box>
    );
  };

  const getDialogBody = () => {
    return (
      <>
        {!passkeyConfigs.isDelete ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomInput
                id="passkey_name"
                name="addPasskeyName"
                propsToInputElement={{
                  maxLength: strings.TAGS_LIMIT,
                }}
                placeHolder="Enter passkey name"
                onChange={(event: any) =>
                  setPasskeyConfigs((prevState: any) => ({
                    ...prevState,
                    error: event.target.value ? "" : prevState.error,
                    displayName: event.target.value,
                  }))
                }
                value={passkeyConfigs.displayName}
                sx={{ background: "red" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span>{passkeyConfigs?.displayName.length} / 25</span>
                    </InputAdornment>
                  ),
                }}
              />
              {isTruthy(passkeyConfigs.error) && (
                <FormHelperText error>Please enter passkey name</FormHelperText>
              )}
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ ...centerItemFlex }} style={{ flexDirection: "column" }}>
            <Typography sx={classes.fontText}>
              Are you sure you want to delete
            </Typography>
            <Typography sx={classes.fontText}>
              <Box component="span" sx={classes.textBold}>
                {passkeyConfigs.displayName}
              </Box>{" "}
              passkey ?
            </Typography>
          </Box>
        )}
      </>
    );
  };

  const getPasskeyActionFunction = async () => {
    if (!handleValidation()) {
      return;
    }

    if (passkeyConfigs.isEdit) {
      await handleUpdatePasskey();
    } else if (passkeyConfigs.isDelete) {
      await handleDeletePasskey();
    } else {
      await handleCreatePasskey();
    }
  };

  const getCustomDatePickerFooter = () => {
    return (
      <Grid container>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            label="Cancel"
            customClasses={classes.cancelButtonStyle}
            onClick={() => handleDialogClose()}
            id="cancel_button"
          />
          <CustomButton
            label={getDialogTitleContent()}
            onClick={async () => {
              await getPasskeyActionFunction();
              if (handleValidation()) {
                handleDialogClose();
                await getPasskeys();
              }
            }}
            id="apply_button"
          />
        </Box>
      </Grid>
    );
  };

  const getPasskeyList = () => {
    return (
      <>
        <Box px={2} mt={2}>
          {getPasskeyHeader()}
        </Box>
        <Box m={2}>
          <CustomSimpleTable
            tableHeader={passkeyListTableHeader}
            tableRows={passKeys}
            emptyTableMessage={"Sorry, we don't have any passkey to show."}
          />
          <CustomDialog
            isDialogOpen={passkeyConfigs.openCreatePasskeyDialog}
            closable
            closeButtonVisibility
            handleDialogClose={() => handleDialogClose()}
            dialogHeaderContent={modalHeader()}
            dialogTitleContent={updateDialogTitleContent()}
            dialogBodyContent={getDialogBody()}
            dialogFooterContent={getCustomDatePickerFooter()}
            width="450px"
          />
        </Box>
      </>
    );
  };
  return getPasskeyList();
};

export default Passkeys;
