import parse from "html-react-parser";
import strings from "global/constants/StringConstants";
import moment from "moment";

export const templateFormDataInitialState = () => {
  return {
    name: {
      value: "",
      error: "",
    },
    category: {
      value: templateCategories[0],
      error: "",
    },
    allowCategoryChange: {
      value: true,
      error: "",
    },
    language: {
      value: "",
      error: "",
    },
    header: {
      format: templateHeaderFormat[0],
      headerTextVariables: [],
      headerHandle: [],
      headerMediaPreviewUrl: "",
      headerMediaUrl: "",
      text: {
        value: "",
        error: "",
      },
    },
    body: {
      text: {
        value: "",
        error: "",
      },
      bodyTextVariables: [[]],
    },
    footer: {
      text: {
        value: "",
        error: "",
      },
    },
  };
};

export const stepOneValidation = (templateData: any) => {
  const nameRegex = /^[a-zA-Z0-9_]*$/;
  let errorLadenState = { ...templateData };
  let isValid = true;
  if (!errorLadenState.name.value) {
    errorLadenState.name.error = "Please enter the template name";
    isValid = false;
  }
  if (!nameRegex.test(errorLadenState.name.value)) {
    errorLadenState.name.error = "Please enter only alphanumeric characters";
    isValid = false;
  }
  if (!errorLadenState.category.value) {
    errorLadenState.category.error = "Please choose the template category";
    isValid = false;
  }
  if (!errorLadenState.language.value) {
    errorLadenState.language.error = "Please choose the language for template";
    isValid = false;
  }
  return { isValid, errorLadenState };
};

export const stepTwoValidation = (
  templateData: any,
  isHeaderIncluded: boolean,
  isFooterIncluded: boolean
) => {
  const regex = /{{(?:[^}]+)?}}|{(?:[^}]+)?}/;
  let errorLadenState = { ...templateData };
  let isValid = true;
  if (isHeaderIncluded && !errorLadenState.header.text.value) {
    errorLadenState.header.text.error = "Please enter header text";
    isValid = false;
  }
  if (isHeaderIncluded && regex.test(errorLadenState.header.text.value)) {
    errorLadenState.header.text.error =
      "Please remove curly braces from the header text";
    isValid = false;
  }
  if (!errorLadenState.body.text.value) {
    errorLadenState.body.text.error = "Please enter body text";
    isValid = false;
  }
  if (regex.test(errorLadenState.body.text.value)) {
    errorLadenState.body.text.error =
      "Please remove curly braces from the body text";
    isValid = false;
  }
  if (isFooterIncluded && !errorLadenState.footer.text.value) {
    errorLadenState.footer.text.error = "Please enter footer text";
    isValid = false;
  }
  if (isFooterIncluded && regex.test(errorLadenState.footer.text.value)) {
    errorLadenState.footer.text.error =
      "Please remove curly braces from the footer text";
    isValid = false;
  }
  return { isValid, errorLadenState };
};

export const getMarkdownText = (markdownText: string): string => {
  // const regex = /(_(.*?)_|(\*(.*?)\*)|~(.*?)~/g;
  const regex = /(_|\*|~)(.*?)\1/g;
  return markdownText.replaceAll(regex, (match, p1, p2) => {
    if (p1 === "*") {
      return `<strong>${getMarkdownText(p2)}</strong>`;
    }
    if (p1 === "_") {
      return `<em>${getMarkdownText(p2)}</em>`;
    }
    if (p1 === "~") {
      return `<s>${getMarkdownText(p2)}</s>`;
    }
    return match;
  });
};

export const formatTextHandler = (
  text: string,
  markdownSymbol: string,
  startIndex: number,
  endIndex: number,
  setEndIndex: Function
) => {
  const leftString = text.substring(0, startIndex);
  const middleString = text.substring(startIndex, endIndex);
  const rightString = text.substring(endIndex, text.length);
  const manipulatedString = `${leftString}${markdownSymbol}${middleString}${markdownSymbol}${rightString}`;
  setEndIndex((prevIndex: number) => prevIndex + 2);
  return manipulatedString;
};

export const getMarkupText = (inputString: string) => {
  const markedUpText = parse(getMarkdownText(inputString));
  return markedUpText;
};

export const getTemplatePayload = (
  templateData: any,
  isHeaderIncluded: boolean,
  isFooterIncluded: boolean
) => {
  return {
    templateName: templateData.name.value,
    templateCategory: templateData.category.value.toUpperCase(),
    allow_category_change: templateData.allowCategoryChange.value,
    language: templateData.language.value,
    createdOn: moment().tz("America/New_York").format("MM/DD/yyyy HH:mm:ss"),
    ...(isHeaderIncluded && {
      header: {
        format: templateData.header.format.toUpperCase(),
        text: templateData.header.text.value,
      },
    }),
    body: {
      type: "body",
      text: templateData.body.text.value,
    },
    ...(isFooterIncluded && {
      footer: {
        type: "footer",
        text: templateData.footer.text.value,
      },
    }),
  };
};

export const headerFormatTabContent = [
  { label: "Text", count: 0 },
  { label: "Media", count: 0 },
];

export const templateCategories = ["Marketing", "Utility"];

export const templateLanguage = [
  {
    languageName: "English",
    languageCode: "en",
  },
  {
    languageName: "English (UK)",
    languageCode: "en_GB",
  },
  {
    languageName: "English (US)",
    languageCode: "en_US",
  },
];

export const textFormationData = [
  {
    formattingType: strings.bold,
    markdownSymbol: strings.boldMarkdownSymbol,
  },
  {
    formattingType: strings.italic,
    markdownSymbol: strings.italicMarkdownSymbol,
  },
  {
    formattingType: strings.strikethrough,
    markdownSymbol: strings.strikethroughMarkdownSymbol,
  },
];

export const templateHeaderFormat = [
  "Text",
  "Image",
  "Video",
  "Document",
  "Location",
];

export const allowedImageFormats = ["jpg", "jpeg", "png"];

export const allowedVideoFormats = ["mp4"];

export const allowedDocumentFormats = ["pdf"];
