import { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { CustomButton, CustomDialog } from "global/components";
import StepTwoStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepTwo/StepTwo.styles";

interface CustomProps {
  setChooseTemplateOpen: any;
  chooseTemplateOpen: any;
  templates: any;
  onChooseTemplate: any;
}

const classes = StepTwoStyles;

export const TemplatesModal = ({
  setChooseTemplateOpen,
  chooseTemplateOpen,
  templates,
  onChooseTemplate,
}: CustomProps) => {
  const [isOnHoverDisplay, setIsOnHoverDisplay] = useState<number>(-1);

  const dialogBodyContent = () => (
    <>
      <Grid container>
        {templates.map((template: any, index: number) => {
          let isPublic =
            template.hasOwnProperty("users") &&
            template?.users?.includes("ALL");

          return (
            <Grid item xs={12} sm={6} lg={3}>
              <Box
                m={3}
                sx={classes.templateGridItem}
                onMouseEnter={() => {
                  setIsOnHoverDisplay(index);
                }}
                onMouseLeave={() => {
                  setIsOnHoverDisplay(-1);
                }}
                onTouchStart={() => {
                  setIsOnHoverDisplay(index);
                }}
                onTouchCancel={() => {
                  setIsOnHoverDisplay(-1);
                }}
              >
                {(isPublic || template.category === "System") && (
                  <Box sx={classes.ribbon}>
                    <Box component="span" sx={classes.ribbonText}>
                      {template.category === "System" ? "FREE" : "PUBLIC"}
                    </Box>
                  </Box>
                )}
                <Box key={index} sx={classes.templateCard}>
                  <Box px={2} sx={classes.templateImgWrapper}>
                    <img src={template.image} />
                  </Box>
                </Box>
                {isOnHoverDisplay === index && (
                  <Box sx={classes.templateHover}>
                    {template.name.substring(0, 20)}
                    {template.name.length > 20 && "..."}
                    <CustomButton
                      id="step3_template_modal_choose_button"
                      label="Choose"
                      onClick={() => {
                        onChooseTemplate(template.jsonContent);
                      }}
                      customClasses={classes.chooseTemplateBtn}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );

  return (
    <CustomDialog
      isDialogOpen={chooseTemplateOpen}
      closable
      closeButtonVisibility
      dialogHeaderContent={
        <Typography sx={classes.templateModalHeading}>Templates</Typography>
      }
      handleDialogClose={() => setChooseTemplateOpen(false)}
      dialogBodyContent={dialogBodyContent()}
      width="80vw"
    />
  );
};
