import {
  boldFont,
  darkPurpledColor,
  getRelativeFontSize,
  inputLabelRequiredColor,
  pinkDarkColor,
  pinkThemedMenuItems,
  primaryBlackColor,
  pureWhiteColor,
  purpleThemedSelectComponent,
  regularFont,
  textLightColor,
  theme,
} from "utils/styles";

const Step1Styles = {
  step1Wrapper: {
    padding: theme.spacing(4),
  },
  regularFonts: {
    ...regularFont,
  },
  boldFont: {
    ...boldFont,
  },
  categoryTabsSection: {
    "& .MuiTabs-indicator": {
      backgroundColor: pinkDarkColor,
    },
  },
  categoryTab: {
    ...boldFont,
    fontSize: getRelativeFontSize(3),
    textTransform: "none",
    "&.Mui-selected": {
      color: pinkDarkColor,
    },
  },
  contentList: {
    listStyleType: "disc",
    padding: "10px 0px 10px 40px",
  },
  contentListItem: {
    ...regularFont,
    paddingLeft: "0px",
    fontSize: "16px",
    fontStyle: "normal",
    color: primaryBlackColor,
    textTransform: "capitalize",
    display: "list-item",
    lineHeight: "15px",
    "::marker": {
      color: pinkDarkColor,
    },
  },
  inputLabel: {
    display: "flex",
    color: "#212121",
    fontSize: getRelativeFontSize(7),
    fontColor: theme.palette.common.black + " !important",
    variant: "standard",
    ...boldFont,
    "& .MuiTextField-root": {
      color: "red",
    },
    "& .MuiInputLabel-root ": {
      color: "red",
    },
    "&:focus": {
      color: "red",
    },
    "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: "red",
    },
  },
  star: { color: inputLabelRequiredColor },
  errorStyle: { paddingLeft: "15px" },
  dropdownStyleDomain: {
    ...regularFont,
    backgroundColor: pureWhiteColor,
    height: "47px",
    padding: "2px",
    width: "100%",
    borderRadius: "12px",
    fontSize: "16px",
    ...purpleThemedSelectComponent,
  },
  optionStyle: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  abTestBox: {
    background: pureWhiteColor,
    margin: 0,
    border: "1px solid #E7E7E7",
    borderRadius: "18px",
    padding: "20px 30px",
  },
  abTestHeading: {
    ...boldFont,
    color: darkPurpledColor,
  },
  abTestSubText: {
    ...regularFont,
    marginTop: "5px",
    color: textLightColor,
  },
  radioGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  radioChecked: {
    "&.Mui-checked": {
      color: pinkDarkColor,
    },
  },
  campaignElementAB: {
    padding: "8px",
    background: pureWhiteColor,
    border: "1px solid #E7E7E7",
    borderRadius: "15px",
    cursor: "pointer",
  },
  activeCampaignElementAB: {
    border: `1px solid ${darkPurpledColor}`,
  },
} as const;

export default Step1Styles;
