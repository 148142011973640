import { StringConstants } from "global/constants/StringConstants";

class UrlConstants extends StringConstants {
  PROD = true;

  url_prod = "https://services.softsages.com/";
  url_dev = "https://api-uat.softsages.com/";
  // url_dev = "http://localhost:6030/";
  url = (this.PROD ? this.url_prod : this.url_dev) + "crm";
  url_core =
    (this.PROD
      ? this.url_prod
      : this.url_dev.includes("uat")
      ? this.url_dev
      : "http://localhost:6040/") + "core";

  STRIPE_PUBLIC_KEY = this.PROD
    ? "pk_live_51Jzc0nGMH0qloJSHPOEZr6nMXIlRCzYunReGIIRWJcp5CSaJleYzEpUWkSvd00jcnSeSl2bECFdvxcPy0AkFVNQ100BjvjG2mP"
    : "pk_test_51MjcMuCNqjP1g29JFHvecvJFpGPkHsUAATKm2nTpHxSZWpXnb6TPh9tIcH3Il1odLe16Qyt2I8Nx0ZCfzlui3Cz400lJJGRNIc";

  //Attachments BaseURL
  AMAZON_S3_URL = "https://softsages-mailzzy-docs.s3.amazonaws.com/";

  //Unlayer custom tools
  customSocialTool =
    "https://cdn.softsages.com/mailzzy/unlayer-tools/Social.js";
  customVideoTool = "https://cdn.softsages.com/mailzzy/unlayer-tools/Video.js";

  //Permissions
  SELF = "self";
  GET = "fetch";
  ADD = "add";
  UPDATE = "update";
  UPLOAD = "upload";
  DELETE = "delete";
  APP = "mailzzy";

  // Resources
  PUBLIC_URI_PATH_TOKEN = `${this.url_core}/public`;
  SECURE_URI_PATH_TOKEN = `${this.url_core}/secure`;
  RESTRICTED_URI_PATH_TOKEN = `${this.url_core}/restricted`;
  WORKFLOW_PATH = `${this.url_core}/secure`;
  MULTIFACTOR = "multiFactor";
  CAMPAIGN_URL = `${this.url}/campaign`;
  CAMPAIGNER_URL = `${this.url}/campaigner`;
  CONTACT_URL = `${this.url}/contact`;
  SOCIAL_URL = `${this.url_core}/social`;
  BILLING_URL = `${this.url_core}/billing`;
  SMS_URL = `${this.url_core}/sms`;
  SALESFORCE_URL = `${this.url_core}/salesforce`;
  EXTERNAL_APP_URL = `${this.url_core}/secure/externalApp`;
  SOCIAL_CAMPAIGN_URL = `${this.url}/social`;
  SOCIAL_FETCH_CAMPAIGN_URL = `${this.url}/social/fetch`;

  // View Paths
  landingViewPath = "/";
  loginViewPath = "/login";
  forgotPasswordViewPath = "/forgot-password";
  registerViewPath = "/register";
  resetPasswordViewPath = "/changepwd";
  activateUserViewPath = "/activate";
  integrationViewPath = "/integration";
  ticketsViewPath = "/tickets";
  onBoardViewPath = "/onboard";
  dashboardViewPath = "/dashboard";
  campaignGroupsViewPath = "/groups";
  campaignHistoryViewPath = "/history";
  failedStatsViewPath = "/failed-stats";
  campaignsViewPath = "/campaigns";
  socialCampaignsViewPath = "/social-campaigns";
  sequencesViewPath = "/sequences";
  usersViewPath = "/users";
  socialViewPath = "/social";
  templateViewPath = "/templates";
  templateAddPath = "/templates/add";
  templateEditPath = "/templates/edit";
  viewContactViewPath = "/contacts";
  campaignDetailsViewPath = `${this.campaignsViewPath}/view`;
  createCampaignViewPath = "/create-campaign";
  createCampaignsViewPath = "/create-campaigns";
  campaignsListViewPath = "/campaigns-list";
  registrationSuccessViewPath = "/registered";
  retargettingViewPath = "/retargetting";
  resendCampaign = "/resend";
  settingsViewPath = "/settings";
  createWhatsAppTemplateViewPath = "/create-whatsapp-template";
  whatsAppTemplatesViewPath = "/whatsapp-templates";
  createWhatsAppCampaignViewPath = "/create-whatsapp-campaign";
  verifyTOTPViewPath = "/verify-code";
  setupMFAViewPath = "/setup-mfa";

  // Landing Page API
  registerUser = `${this.PUBLIC_URI_PATH_TOKEN}/register`;
  forgotPassword = `${this.PUBLIC_URI_PATH_TOKEN}/user/pwd/reset`;
  updatePwd = `${this.RESTRICTED_URI_PATH_TOKEN}/user/pwd/${this.UPDATE}`;
  activateUser = `${this.url}/secure/campaigner/activate`;
  verifyTempPwd = `${this.PUBLIC_URI_PATH_TOKEN}/user/pwd/verify`;
  verifyTempPwdActivate = `${this.PUBLIC_URI_PATH_TOKEN}/user/pwd/verify/activate`;
  viewPlans = `${this.PUBLIC_URI_PATH_TOKEN}/plans/${this.APP}/account`;
  loginUser = `${this.PUBLIC_URI_PATH_TOKEN}/user/login`;
  MFA_GET_QR_CODE = `${this.RESTRICTED_URI_PATH_TOKEN}/${this.MULTIFACTOR}/${this.fetchPermission}/qrCode`;
  ACTIVATE_MFA = `${this.RESTRICTED_URI_PATH_TOKEN}/${this.MULTIFACTOR}/enable/mfa`;
  MFA_GET_PREAUTH_KEY = `${this.RESTRICTED_URI_PATH_TOKEN}/${this.MULTIFACTOR}/${this.GET}/secretKey`;

  // Dashboard Page API
  latestCampaign = `${this.CAMPAIGN_URL}/${this.GET}/top/status`;
  recentCampaignActivity = `${this.CAMPAIGN_URL}/${this.GET}/today/metrics`;
  recentContactActivity = `${this.CONTACT_URL}/${this.GET}/today/metrics`;
  fetCampaignsByInterval = `${this.CAMPAIGN_URL}/${this.GET}/interval`;
  contactsOpenClickMetrics = `${this.CONTACT_URL}/${this.GET}/metrics/open/interval`;
  campaignRecipientsData = `${this.CAMPAIGN_URL}/${this.GET}/metrics/count/interval`;
  campaignsMetricsByTypeAndInterval = `${this.CAMPAIGN_URL}/${this.GET}/metrics/status/interval`;
  last3Campaigns = `${this.CAMPAIGN_URL}/${this.GET}/status`;
  fetchSocialCampaignList = `${this.SOCIAL_FETCH_CAMPAIGN_URL}/interval`;

  campaignsMetricsByTypeAndDates = `${this.CAMPAIGN_URL}/${this.GET}/metrics/status/dates`;
  campaignRecipientsDataByDates = `${this.CAMPAIGN_URL}/${this.GET}/metrics/count/dates`;
  fetCampaignsByDates = `${this.CAMPAIGN_URL}/${this.GET}/dates`;
  socialCampaignListByDates = `${this.SOCIAL_FETCH_CAMPAIGN_URL}/dates`;

  // User API
  campaigners = `${this.CAMPAIGNER_URL}/${this.GET}/page`;
  campaignersReport = `${this.CAMPAIGNER_URL}/${this.GET}/download/report`;
  campaignersCount = `${this.CAMPAIGNER_URL}/${this.GET}/count`;
  addCampaigner = `${this.CAMPAIGNER_URL}/${this.ADD}`;
  updateCampaigner = `${this.CAMPAIGNER_URL}/${this.ADD}`;
  userSearch = `${this.CAMPAIGNER_URL}/${this.GET}/search/page`;
  userSearchCount = `${this.CAMPAIGNER_URL}/${this.GET}/search/page`;
  activeCampaigner = `${this.CAMPAIGNER_URL}/${this.GET}/active`;
  unlockUser = `${this.url_core}/unlock/user/email`;
  disableMFA = `${this.url_core}/mfa/disable/email`;
  userEmail = `${this.SECURE_URI_PATH_TOKEN}/user/email`;

  // Create Campaign Page API
  formatCampaignTemplate = `${this.url}/secure/email/custom/templates/all`;
  isManager = `${this.url}/secure/isManager`;
  groupAllMyTeam = `${this.CONTACT_URL}/${this.GET}/groups`;
  groupAllMyTeamCount = `${this.CONTACT_URL}/${this.GET}/groups`;
  sendBulkEmailDraft = `${this.CAMPAIGN_URL}/${this.ADD}/draft`;
  getDraftCampaignRequestById = `${this.CAMPAIGN_URL}/${this.GET}/id`;
  userEmailAccount = `${this.CAMPAIGNER_URL}/${this.GET}/${this.SELF}`;
  spamDetect = `${this.url}/secure/ai/email/spam/prediction`;
  createTagGroup = `${this.url}/contactTag/create/groupName`;
  getTagSearchCount = `${this.CONTACT_URL}/${this.GET}/tag/search/count`;
  getTagSearchCountData = `${this.CONTACT_URL}/${this.GET}/tag/search`;
  tagsCount = `${this.CONTACT_URL}/${this.GET}/tag/count`;
  getTags = `${this.CONTACT_URL}/${this.GET}/tag/owner/page`;
  getTagsCount = `${this.CONTACT_URL}/${this.GET}/tag/owner/count`;
  getTagsById = `${this.CONTACT_URL}/tagId`;
  attachFileUpload = `${this.url}/secure/email/${this.UPLOAD}`;
  sendTestEmail = `${this.url}/secure/campaign/${this.SELF}`;
  validDistinctEmailCount = `${this.CONTACT_URL}/${this.GET}/count`;
  sendBulkEmail = `${this.CAMPAIGN_URL}/${this.ADD}/submit`;
  sendRetargetFollowUpCampaign = `${this.CAMPAIGN_URL}/${this.ADD}/retarget/submit/groupType`;
  sendSequentialEmail = `${this.CAMPAIGN_URL}/${this.ADD}/sequential/submit`;
  customDomains = `${this.url}/secure/email/custom/domains`;
  subjectLines = `${this.url}/secure/ai/email/subjectLines`;

  // Campaign History Page API
  campaignHistoryEmailEvent = `${this.CONTACT_URL}/${this.GET}/response`;

  // Campaigns Page API
  bulkEmailRequestsByStatusAndInterval = `${this.CAMPAIGN_URL}/${this.GET}`;
  deleteBulkEmailRequest = `${this.CAMPAIGN_URL}/${this.DELETE}`;
  campaignActionByCampaignId = `${this.CAMPAIGN_URL}/${this.GET}/id`;
  campaignsCountInterval = `${this.CAMPAIGN_URL}/${this.GET}`;
  campaignActionByStatus = `${this.CONTACT_URL}/${this.GET}/response`;
  campaignActionByStatusExport = `${this.CONTACT_URL}/${this.GET}/response/download`;
  retargetedGroup = `${this.CONTACT_URL}/retarget/validate/campaign`;
  campaignsChild = `${this.CAMPAIGN_URL}/${this.GET}/child`;
  campaignExistsCheck = `${this.CAMPAIGN_URL}/${this.ADD}/validate`;
  campaignPerformanceData = `${this.CONTACT_URL}/${this.GET}/responsesAnalytics`;

  DOWNLOAD_REPORT = `${this.CAMPAIGN_URL}/${this.GET}/download/id`;
  downloadOpenAndClickedReport = `${this.CONTACT_URL}/${this.GET}/response/download`;
  isFirstNameMandatory = `${this.CAMPAIGN_URL}/${this.GET}/hasMandatory/firstName`;
  campaignRetentionDuration = `${this.CAMPAIGN_URL}/${this.GET}/campaign/retention`;

  // Campaign Groups Page API
  contactGroups = `${this.CONTACT_URL}/${this.GET}/groups`;
  contactGroupTypes = `${this.CONTACT_URL}/${this.GET}/group/types`;
  getAllContactGroups = `${this.CONTACT_URL}/${this.GET}/groups/all/-1`;
  deleteCampaignGroup = `${this.CONTACT_URL}/group/${this.DELETE}`;
  updateContactGroup = `${this.CONTACT_URL}/group/${this.UPDATE}`;
  uploadContactGroup = `${this.CONTACT_URL}/${this.UPLOAD}/group`;
  downloadCampaignGroups = `${this.CONTACT_URL}/${this.GET}/group`;
  getSubordinates = `${this.url}/secure/subordinates`;
  campaignsRecipientsByGroupIdCount = `${this.CONTACT_URL}/${this.GET}/group/count`;
  campaignsRecipientsByGroupId = `${this.CONTACT_URL}/${this.GET}/group`;
  campaignsRecipientsSearchByGroupIdCount = `${this.CONTACT_URL}/${this.GET}/search/count/group`;
  campaignsRecipientsSearchByGroupId = `${this.CONTACT_URL}/${this.GET}/search/group`;

  // Contact Page API
  campaignGroupById = `${this.CONTACT_URL}/${this.GET}/group`;
  downloadContactReport = `${this.CONTACT_URL}/${this.GET}/download`;
  addNewContact = `${this.CONTACT_URL}/group/${this.ADD}`;
  getContactsCountValue = `${this.CONTACT_URL}/${this.GET}/count`;
  getContactsPageValue = `${this.CONTACT_URL}/${this.GET}`;
  getContactCountSearch = `${this.CONTACT_URL}/${this.GET}/count`;
  getContactSearchPage = `${this.CONTACT_URL}/${this.GET}`;
  getDeleteGroupData = `${this.CONTACT_URL}/${this.GET}/contact/groups/emailId`;
  deleteContact = `${this.CONTACT_URL}/${this.DELETE}/emailId`;
  getCampaignsRecipient = `${this.CONTACT_URL}/${this.GET}/response`;
  deleteTagsById = `${this.CONTACT_URL}/tag/${this.DELETE}/emailId`;
  addTags = `${this.CONTACT_URL}/tag/${this.UPDATE}/emailId`;
  campaignsRecipientsByEmail = `${this.CONTACT_URL}/${this.GET}/response`;
  FETCH_COLUMN_TYPE = `${this.CONTACT_URL}/${this.UPLOAD}/column/type/csv`;
  COLUMN_TYPE_CSV_ADD = `${this.CONTACT_URL}/${this.UPLOAD}`;

  // Templates Page API
  getCampaignTemplates = `${this.url}/secure/email/custom/templates/my`;
  addCampaignTemplate = `${this.url}/secure/email/custom/template/${this.ADD}`;
  updateCampaignTemplate = `${this.url}/secure/email/custom/template/${this.UPDATE}`;
  updateCampaignTemplatePermission = `${this.url}/secure/email/custom/template/users/${this.UPDATE}`;
  deleteCampaignTemplate = `${this.url}/secure/email/custom/template/${this.DELETE}`;
  getCampaignTemplateById = `${this.url}/secure/email/custom/template`;
  getDefaultFooterTemplate = `${this.url}/secure/email/template/default/${this.GET}`;

  // stripe integration
  getplansId = `${this.PUBLIC_URI_PATH_TOKEN}/plan/`;
  userRegisterPaidValidates = `${this.PUBLIC_URI_PATH_TOKEN}/user/register/paid/validate`;
  getAccountUnauthPaymentIntents = `${this.PUBLIC_URI_PATH_TOKEN}/paymentIntent`;
  userRegisterPaid = `${this.PUBLIC_URI_PATH_TOKEN}/user/register/paid`;

  // Facebook Integration API
  userAuthenticated = `${this.SOCIAL_URL}/${this.GET}/fb/accessToken`;
  getFaceBookUserDetail = `${this.EXTERNAL_APP_URL}/secrets`;
  getPageFeeds = `${this.SOCIAL_URL}/${this.GET}/fb/feed`;
  deletePageFeed = `${this.SOCIAL_URL}/fb/feed/${this.DELETE}`;
  postFacebookFeed = `${this.SOCIAL_URL}/fb/feed/${this.ADD}`;
  sendSocialCampaign = `${this.SOCIAL_CAMPAIGN_URL}/add/submit`;
  updateFacebookFeed = `${this.SOCIAL_URL}/fb/feed/${this.UPDATE}`;
  uploadImageFacebook = `${this.SECURE_URI_PATH_TOKEN}/${this.UPLOAD}/media`;
  uploadFeedWithImage = `${this.SOCIAL_URL}/fb/image/${this.ADD}`;
  getIntegratedAppNames = `${this.EXTERNAL_APP_URL}/integrated`;
  socialCampaignNameCheck = `${this.SOCIAL_CAMPAIGN_URL}/add/validate`;
  deleteUnexecutedSocialCampaigns = `${this.SOCIAL_CAMPAIGN_URL}/${this.DELETE}`;
  disconnectIntegratedApp = `${this.url_core}/secure/delete/externalApp/secrets`;

  // Salesforce Integration APIs
  getSalesforceIntegration = `${this.SALESFORCE_URL}/${this.GET}/account/authUrl`;
  salesforceUserAuthenticated = `${this.SALESFORCE_URL}/${this.GET}/account/authCode`;
  checkSalesforceIntegration = `${this.SALESFORCE_URL}/${this.GET}/integrated`;
  disconnectSalesforce = `${this.url_core}/secure/delete/externalApp/secrets`;

  // LinkedIn Integration API
  linkedInUserDetail = `${this.url}/linkedIn/userDetails`;
  linkedInAccessToken = `${this.url}/linkedIn/accessToken`;
  linkedInImageUpload = `${this.url}/linkedIn/image/register`;
  linkedInImageUploadWithPost = `${this.url}/linkedIn/image/post`;
  linkedPost = `${this.url}/linkedIn/post`;
  deleteLinkedInPost = `${this.url}/linkedIn/${this.DELETE}`;

  //WhatsApp Integration API
  whatsAppBaseUrl = `${this.SMS_URL}/${this.GET}`;
  sendTemplateForApproval = `${this.SMS_URL}/template/approve`;
  sendWhatsAppCampaign = `${this.SMS_URL}/send/message/template`;
  getWhatsAppFromDetails = `${this.SMS_URL}/${this.GET}/from/details`;
  whatsAppUserAuthenticated = `${this.SMS_URL}/${this.GET}/whatsapp/token`;
  checkTemplateNameUniqueness = `${this.SMS_URL}/${this.GET}/template/check`;
  checkForTemplateUpdate = `${this.SMS_URL}/${this.GET}/template/check/status`;

  // Tickets
  ASSIGNED = `${this.WORKFLOW_PATH}/workflow/tasks/my/assigned`;
  ASSIGNED_COUNT = `${this.WORKFLOW_PATH}/workflow/tasks/my/assigned/count`;
  ASSIGNED_SEARCH_DATA = `${this.WORKFLOW_PATH}/workflow/tasks/my/assigned/search`;
  ASSIGNED_SEARCH_COUNT = `${this.WORKFLOW_PATH}/workflow/tasks/my/assigned/search/count`;
  RAISED = `${this.WORKFLOW_PATH}/workflow/tasks/my`;
  RAISED_COUNT = `${this.WORKFLOW_PATH}/workflow/tasks/my/count`;
  RAISED_SEARCH_DATA = `${this.WORKFLOW_PATH}/workflow/tasks/my/search`;
  RAISED_SEARCH_COUNT = `${this.WORKFLOW_PATH}/workflow/tasks/my/search/count`;
  COMPLETED = `${this.WORKFLOW_PATH}/workflow/tasks/status/completed`;
  COMPLETED_COUNT = `${this.WORKFLOW_PATH}/workflow/tasks/status/completed/count`;
  COMPLETED_SEARCH_DATA = `${this.WORKFLOW_PATH}/workflow/tasks/status/completed/search`;
  COMPLETED_SEARCH_COUNT = `${this.WORKFLOW_PATH}/workflow/tasks/status/completed/search/count`;
  OPEN = `${this.WORKFLOW_PATH}/workflow/tasks/status/open`;
  OPEN_COUNT = `${this.WORKFLOW_PATH}/workflow/tasks/status/open/count`;
  OPEN_SEARCH_DATA = `${this.WORKFLOW_PATH}/workflow/tasks/search`;
  OPEN_SEARCH_COUNT = `${this.WORKFLOW_PATH}/workflow/tasks/search/count`;
  UPDATE_TASK = `${this.WORKFLOW_PATH}/workflow/task/${this.UPDATE}`;
  COMPLETE = `${this.WORKFLOW_PATH}/workflow/task/complete`;
  ASSIGNEE_GROUP_NAME = `${this.WORKFLOW_PATH}/workflow/task/group`;
  ASSIGNEE = `${this.WORKFLOW_PATH}/workflow/task/assign`;
  VIEW_FILE = `${this.WORKFLOW_PATH}/document/view`;
  DOWNLOAD_FILE = `${this.WORKFLOW_PATH}/document/download`;
  PROCESS_ADMIN = `${this.WORKFLOW_PATH}/workflow/isProcessAdmin`;
  FETCH_TICKETS_METRICS = `${this.WORKFLOW_PATH}/tickets/my/metrics`;
  TICKETS_BY_STATUS = `${this.WORKFLOW_PATH}/tickets/my/status`;
  TICKET_BY_ID = `${this.WORKFLOW_PATH}/workflow/task`;
  UPLOAD_TICKET_FILE = `${this.WORKFLOW_PATH}/ticket/document/upload/ticketId`;
  ADD_COMMENT = `${this.WORKFLOW_PATH}/task/comment/add`;
  processDefinitions = `${this.SECURE_URI_PATH_TOKEN}/workflow/process/def`;
  addSuportTicket = `${this.SECURE_URI_PATH_TOKEN}/ticket/${this.ADD}`;
  DELETE_DOCUMENT = `${this.SECURE_URI_PATH_TOKEN}/ticket/document/delete`;

  passkeyLoginUser = `${this.PUBLIC_URI_PATH_TOKEN}/user/login_passkey`;
  //Passkeys Page API
  passkeyRegister = `${this.SECURE_URI_PATH_TOKEN}/webauthn/register/start`;
  passkeyFinishRegister = `${this.SECURE_URI_PATH_TOKEN}/webauthn/register/finish`;
  passkeyLogin = `${this.PUBLIC_URI_PATH_TOKEN}/webauthn/start`;
  passkeyFinishLogin = `${this.PUBLIC_URI_PATH_TOKEN}/webauthn/finish`;
  getAllPasskeys = `${this.SECURE_URI_PATH_TOKEN}/webauthn/passkeys`;
  deletePasskey = `${this.SECURE_URI_PATH_TOKEN}/webauthn/delete/passkey`;
  updatePasskey = `${this.SECURE_URI_PATH_TOKEN}/webauthn/update/passkey`;

  //Profile Page API
  updateProfileDetails = `${this.SECURE_URI_PATH_TOKEN}/user/${this.UPDATE}`;

  //Billing-Usage Page API
  getCardDetail = `${this.BILLING_URL}/${this.GET}/paymentMethod`;
  deleteCard = `${this.BILLING_URL}/${this.DELETE}/paymentMethod`;
  addCard = `${this.BILLING_URL}/${this.ADD}/paymentMethod`;
  setPrimaryCard = `${this.BILLING_URL}/${this.UPDATE}/paymentMethod`;
  changePlan = `${this.BILLING_URL}/${this.ADD}/paidCustomer`;
  updatePlan = `${this.BILLING_URL}/${this.UPDATE}/plan`;
  cancelSubscription = `${this.BILLING_URL}/${this.DELETE}/subscription`;
  isActiveCancelSubscription = `${this.BILLING_URL}/${this.GET}/subscription/status`;
  featurePlan = `${this.PUBLIC_URI_PATH_TOKEN}/plan/account`;
  getPlanDetail = `${this.BILLING_URL}/${this.GET}/planLimit`;
  getInvoiceDetail = `${this.BILLING_URL}/${this.GET}/invoice`;
  getInvoiceCount = `${this.BILLING_URL}/${this.GET}/count`;
  getDailyEmailCount = `${this.CAMPAIGN_URL}/${this.GET}/emailCount`;

  //Role Management Page API
  GET_ROLES = `${this.url_core}/roles`;
  GET_USERS_ROLES = `${this.SECURE_URI_PATH_TOKEN}/user/roles`;
  GET_ROLES_COUNT = `${this.url_core}/roles/count`;
  ADD_ROLE = `${this.url_core}/role/${this.ADD}`;
  GET_ROLES_SEARCH = `${this.url_core}/roles/search`;
  GET_ROLES_SEARCH_COUNT = `${this.url_core}/roles/count/search`;
  UPDATE_ROLE = `${this.url_core}/role/${this.UPDATE}`;
  GET_RESOURCES = `${this.SECURE_URI_PATH_TOKEN}/user/resources`;

  // SECURITY
  GET_QR_CODE = `${this.SECURE_URI_PATH_TOKEN}/${this.MULTIFACTOR}/fetch/qrCode`;
  VERIFY_MFA = `${this.SECURE_URI_PATH_TOKEN}/update/mfa`;
  VERIFY_TOTP = `${this.url_core}/public/totp/verify`;

  //Custom Domains
  fetchCustomDomains = `${this.SECURE_URI_PATH_TOKEN}/account/Domains`;
  addCustomDomain = `${this.SECURE_URI_PATH_TOKEN}/${this.ADD}/domain`;
  getDomainDetails = `${this.SECURE_URI_PATH_TOKEN}/dns/domain`;

  //Discord Webhook frontend url
  webhook_URL = this.PROD
    ? "https://discord.com/api/webhooks/1049942036905730079/26N3vnnnk7b8ObzuT4k1hLFOM3Est6FpPKpkLot20gQLfCTxKgWuMPB7EcnWoasB-UAw"
    : "https://discord.com/api/webhooks/1049942034485620806/waT1rmBWvZZjRRgPwLEaro2Ihok315u1JMd2L8FnN-VoLmBDnyFqOQGK7XC7tNvWWBAB";

  BYPASS = [
    this.uploadContactGroup,
    this.FETCH_COLUMN_TYPE,
    this.COLUMN_TYPE_CSV_ADD,
    this.campaignsRecipientsByGroupId,
    this.campaignsRecipientsByGroupIdCount,
    this.validDistinctEmailCount,
    this.sendBulkEmail,
  ];
}

let urls = new UrlConstants();
export default urls;
