import { useEffect, useState } from "react";
import history from "utils/history";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import StepOne from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/StepOne/StepOne";
import StepTwo from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/StepTwo/StepTwo";
import {
  getTemplatePayload,
  templateFormDataInitialState,
} from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/CreateWhatsAppTemplateHelpers";
import {
  checkWhatsAppIntegration,
  submitTemplateForApproval,
} from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/CreateWhatsAppTemplateServices";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import urls from "global/constants/UrlConstants";
import WhatsAppIntegrationModel from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/components/WhatsAppIntegrationModel/WhatsAppIntegrationModel";
import strings from "global/constants/StringConstants";

const CreateWhatsAppTemplate = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [templateData, setTemplateData] = useState<any>(
    templateFormDataInitialState()
  );
  const [isWhatsAppIntegrated, setIsWhatsAppIntegrated] =
    useState<boolean>(false);

  useEffect(() => {
    checkIsWhatsAppAuthentication();
  }, []);

  const checkIsWhatsAppAuthentication = async () => {
    try {
      setIsLoading(true);
      const response = await checkWhatsAppIntegration();
      setIsWhatsAppIntegrated(
        response.some((item: any) => item.integratedApp === strings.WHATSAPP)
      );
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const redirectToWhatsAppTemplatesView = () => {
    history.push({
      pathname: urls.whatsAppTemplatesViewPath,
      state: { status: urls.pending },
    });
  };

  const submitTemplate = async (
    isHeaderIncluded: boolean,
    isFooterIncluded: boolean
  ) => {
    try {
      setIsLoading(true);
      const templatePayload = getTemplatePayload(
        templateData,
        isHeaderIncluded,
        isFooterIncluded
      );
      const response = await submitTemplateForApproval(templatePayload);
      if (isTruthy(response)) {
        const successMessage =
          "WhatsApp template has been successfully submitted for the approval.";
        openSuccessNotification(successMessage);
      }
      redirectToWhatsAppTemplatesView();
    } catch (error: any) {
      openErrorNotification(notifiers.GENERIC_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const getTemplateCreationSteps = () => {
    switch (activeStep) {
      case 1:
        return (
          <StepOne
            templateData={templateData}
            setTemplateData={setTemplateData}
            setActiveStep={setActiveStep}
            setIsLoading={setIsLoading}
          />
        );
      case 2:
        return (
          <StepTwo
            templateData={templateData}
            setTemplateData={setTemplateData}
            setActiveStep={setActiveStep}
            submitTemplate={submitTemplate}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {isWhatsAppIntegrated ? (
        getTemplateCreationSteps()
      ) : (
        <WhatsAppIntegrationModel isOpen={!isLoading} />
      )}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default CreateWhatsAppTemplate;
