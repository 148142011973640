import history from "utils/history";
import { Box } from "@mui/material";
import { CustomButton, CustomDialog } from "global/components";
import DeleteImg from "assets/images/DeleteImg.svg";
import CreateWhatsAppCampaignStyles from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/CreateWhatsAppCampaign.styles";
import urls from "global/constants/UrlConstants";

interface CustomProps {
  isOpen: boolean;
}

const WhatsAppIntegrationModel = (props: CustomProps) => {
  const classes = CreateWhatsAppCampaignStyles;

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={DeleteImg} alt="unauthenticated_image" />
      </Box>
    );
  };

  const dialogTitleContent = () => {
    return (
      <Box sx={classes.dialogTitleWrapper}>
        <Box sx={classes.titleRight}>Media Integration</Box>
      </Box>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Box sx={classes.dialogContent} style={{ textAlign: "center" }}>
        There are no WhatsApp accounts integrated with Mailzzy.
      </Box>
    );
  };

  const dialogFooterContent = () => {
    return (
      <Box sx={classes.dialogFooter}>
        <CustomButton
          label="Integrate"
          onClick={() => history.push(urls.integrationViewPath)}
          id="whatsapp_integrate_button"
        />
      </Box>
    );
  };

  return (
    <CustomDialog
      dialogHeaderContent={dialogHeaderContent()}
      isDialogOpen={props.isOpen}
      handleDialogClose={() => {
        history.push(urls.dashboardViewPath);
      }}
      dialogTitleContent={dialogTitleContent()}
      dialogBodyContent={dialogBodyContent()}
      dialogFooterContent={dialogFooterContent()}
      width="450px"
    />
  );
};

export default WhatsAppIntegrationModel;
