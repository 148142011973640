import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useTitle } from "utils/UseTitle";
import strings from "global/constants/StringConstants";
import CreateCampaignStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignNew.styles";
import {
  campaignFormDataInitialState,
  mapFormDataToValues,
} from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignHelpers";
import { StepThree } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/StepThree";
import { StepTwo } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepTwo/StepTwo";
import { useLocation } from "react-router";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import {
  getLoggedInEmailUser,
  getDraftCampaignById,
  sendBulkEmailDraft,
  sendCampaign,
  sendRetargetOrFollowUpCampaign,
} from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignServices";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { hasAccessTo } from "utils/AuthorizationManager";
import history from "utils/history";
import urls from "global/constants/UrlConstants";
import moment from "moment";
import ChooseCampaignType from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/ChooseCampaignType/ChooseCampaignType";
import { Design } from "react-email-editor";
import { getFooterTemplate } from "screens/Templates/TemplatePageService";

export const CreateCampaignNew = (props: any) => {
  useTitle(strings.CreateCampaignTitle);
  const classes = CreateCampaignStyles;
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignData, setCampaignData] = useState(
    campaignFormDataInitialState()
  );
  const [allowedEmailCount, setAllowedEmailCount] = useState<number>(0);
  const [templateHtml, setTemplateHtml] = useState<any>("");
  const [attachmentFileSize, setAttachmentFileSize] = useState<any>([]);
  const [totalAttachmentSize, setTotalAttachmentSize] = useState<number>(0);
  const [isBlocking, setIsBlocking] = useState<boolean>(false);
  const [campaignType, setCampaignType] = useState<string>(
    strings.EMAIL_REGULAR_CAMPAIGN
  );
  const [defaultFooterTemplate, setDefaultFooterTemplate] = useState<Design>();

  // retarget/follow-up campaign
  const parentId = props?.location?.state?.parentId;
  const groupName = props?.location?.state?.groupName;
  const groupId = props?.location?.state?.groupId;
  const groupType = props?.location?.state?.groupType;
  const opened = props?.location?.state?.opened;

  const urlParams = useLocation().search;
  const draftCampaignId: any = new URLSearchParams(urlParams).get("id");
  const hasCampaignerFetchAccess = hasAccessTo(
    strings.campaigner,
    strings.fetchPermission
  );
  const isRetargetOrFollowUp = Boolean(parentId);

  useEffect(() => {
    hasCampaignerFetchAccess && getAllowedEmailCount();
    getDefaultFooterTemplate();
  }, []);

  useEffect(() => {
    if (draftCampaignId) {
      getDraftCampaignRequest();
    }
  }, [draftCampaignId]);

  const getDefaultFooterTemplate = async () => {
    try {
      setIsLoading(true);
      const response = await getFooterTemplate();
      setDefaultFooterTemplate(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getDraftCampaignRequest = async () => {
    try {
      setIsLoading(true);
      const response = await getDraftCampaignById(draftCampaignId);
      setCampaignData(campaignFormDataInitialState(response));
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getAllowedEmailCount = async () => {
    try {
      const response = await getLoggedInEmailUser();
      setAllowedEmailCount(response?.allowedEmailCount);
    } catch (error: any) {
      const errorMessage = error?.message || notifiers.GENERIC_ERROR;
      openErrorNotification(errorMessage);
    }
  };

  const handleSaveAsDraft = async (campaignFormData: any = campaignData) => {
    try {
      setIsLoading(true);
      let payload = mapFormDataToValues(campaignFormData);
      payload.scheduleTime = payload.scheduleTime
        ? moment(payload.scheduleTime).format("MM/DD/yyyy HH:mm:ss")
        : "";
      payload.status = "Draft";

      const response = await sendBulkEmailDraft(payload);
      setIsBlocking(false);
      openSuccessNotification(response?.message);
      history.push({
        pathname: urls.campaignsListViewPath,
        search: `?status=${strings.Draft}`,
      });
    } catch (error: any) {
      setIsBlocking(true);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendCampaign = async () => {
    try {
      setIsLoading(true);
      const payload = createCampaignPayload(campaignData, isRetargetOrFollowUp);
      const retargetOrFollowUp = groupType === strings.FollowUp ? true : opened;
      const response = isRetargetOrFollowUp
        ? await sendRetargetOrFollowUpCampaign(
            groupType,
            retargetOrFollowUp,
            payload
          )
        : await sendCampaign(payload);
      setIsBlocking(false);
      openSuccessNotification(response?.message);
      const targetStatus = isRetargetOrFollowUp
        ? strings.Completed
        : strings.Submitted;
      redirectToCampaignsView(targetStatus);
    } catch (error) {
      setIsBlocking(true);
      const errorMessage = getErrorMessage(error);
      openErrorNotification(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const createCampaignPayload = (campaignData: any, isRetarget: boolean) => {
    const payload = mapFormDataToValues(campaignData);
    payload.scheduleTime = payload.scheduleTime
      ? moment(payload.scheduleTime)
          .tz("America/New_York")
          .format("MM/DD/yyyy HH:mm:ss")
      : "";
    payload.status = "Submitted";
    payload.content = templateHtml;

    if (isRetarget) {
      payload.groups = [
        {
          type: "Groups",
          value: [
            {
              id: groupId,
              name: groupName,
            },
          ],
        },
      ];
      payload.parentId = parentId;
    }

    return payload;
  };

  const redirectToCampaignsView = (status: any) => {
    const searchParams = new URLSearchParams({ status }).toString();
    const viewPath = `${urls.campaignsListViewPath}?${searchParams}`;
    history.push(viewPath);
  };

  const getErrorMessage = (error: any) => {
    return isTruthy(error?.message) ? error.message : notifiers.GENERIC_ERROR;
  };

  const getStepsContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <ChooseCampaignType
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            setActiveStep={setActiveStep}
            campaignType={campaignType}
            setCampaignType={setCampaignType}
          />
        );
      case 2:
        return (
          <StepTwo
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            setActiveStep={setActiveStep}
            parentId={parentId}
            handleSaveAsDraft={handleSaveAsDraft}
            setTemplateHtml={setTemplateHtml}
            isBlocking={isBlocking}
            setIsBlocking={setIsBlocking}
            defaultFooterTemplate={defaultFooterTemplate}
            isRetargetOrFollowUp={isRetargetOrFollowUp}
          />
        );
      case 3:
        return (
          <StepThree
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            setActiveStep={setActiveStep}
            parentId={parentId}
            allowedEmailCount={allowedEmailCount}
            handleSaveAsDraft={handleSaveAsDraft}
            handleSendCampaign={handleSendCampaign}
            templateHtml={templateHtml}
            attachmentFileSize={attachmentFileSize}
            setAttachmentFileSize={setAttachmentFileSize}
            totalAttachmentSize={totalAttachmentSize}
            setTotalAttachmentSize={setTotalAttachmentSize}
            isBlocking={isBlocking}
            setIsBlocking={setIsBlocking}
            groupType={groupType}
            isRetargetOrFollowUp={isRetargetOrFollowUp}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Box>{getStepsContent()}</Box>
      <CustomLoader isLoading={isLoading} />
    </>
  );
};
