import {
  Box,
  Chip,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomAppHeader, CustomTable } from "global/components";
import CustomTabs from "global/components/CustomTabs/CustomTabs";
import strings from "global/constants/StringConstants";
import {
  convertESTtoUserLocalDateAndTime,
  debounceEventHandler,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import React, { useEffect, useRef, useState } from "react";
import {
  BootstrapInput,
  HtmlTooltip,
  mediumFont,
  pinkDarkColor,
} from "utils/styles";
import ticketsStyles from "./Tickets.styles";
import SearchIcon from "@mui/icons-material/Search";
import { useTitle } from "utils/UseTitle";
import {
  doFetchAllTicketsByStatus,
  doFetchAllTicketsCountByStatus,
  doFetchAllTicketsMetrics,
} from "./ticketService";
import notifiers from "global/constants/NotificationConstants";
import { tableHeaders } from "./TicketData";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { Link, useLocation } from "react-router-dom";
import urls from "global/constants/UrlConstants";

interface customProps {
  location: any;
}

const tabConfig = [
  {
    label: strings.open,
    value: strings.open,
    key: "open",
    count: 0,
  },
  {
    label: strings.inProcess,
    value: strings.inProcess,
    key: "InProcess",
    count: 0,
  },
  {
    label: strings.complete,
    value: strings.complete,
    key: "complete",
    count: 0,
  },
];

const AllTickets = (props: customProps) => {
  useTitle(strings.TICKETS);
  const classes = ticketsStyles;
  const redirectTabValue = props?.location?.state?.fromPageTableValue;
  const urlParams = new URLSearchParams(useLocation().search);
  const [count, setCount] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const loadingState = props?.location?.state?.loadingState;
  const [isLoading, setIsLoading] = useState<boolean>(loadingState ?? false);
  const [searchParam, setSearchParam] = useState<string>("");
  const [activeData, setActiveData] = useState<any[]>([]);
  const searchValue = useRef<any>("");
  const [currentSelectedTab, setCurrentSelectedTab] = useState<string>(
    redirectTabValue ? redirectTabValue : strings.open
  );
  const [metrics, setMetrics] = useState<any>();
  const [tab, setTab] = useState<any>(tabConfig);
  const [perPageData, setPerPageData] = useState(10);

  useEffect(() => {
    window.history.replaceState(null, "", `?tab=${currentSelectedTab}`);
  }, [currentSelectedTab]);

  useEffect(() => {
    fetchMetrics();
  }, [currentSelectedTab,redirectTabValue]);

  useEffect(() => {
    mainTableDataFetching(currentSelectedTab, pageNumber, searchParam);
  }, [pageNumber, searchValue, searchParam, currentSelectedTab, perPageData,redirectTabValue]);

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const metrics = await doFetchAllTicketsMetrics();
      const metricsMap = metrics.reduce((map: any, metric: any) => {
        map[metric.name] = metric.count;
        return map;
      }, {});
      setMetrics(metricsMap);
      setTab((prevTab: any) =>
        prevTab.map((tabItem: any) => ({
          ...tabItem,
          count: metricsMap[tabItem.value] || 0,
        }))
      );
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const mainTableDataFetching = async (
    tab: string,
    page: number,
    searchText: string
  ) => {
    await getTableData(tab, page, searchText);
  };

  const handleTabChange = async (newValue: string) => {
    setCurrentSelectedTab(newValue);
    setPageNumber(1);
  };

  const handlePageChange = (event: any, pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handlePerPageData = (event: any) => {
    setPerPageData(event.target.value);
  };

  const handleSearchParams = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const searchText = event.target.value.trim();
    setSearchParam(searchText);
    setPerPageData(10);
  };

  const getTableData = async (
    tab: string,
    page: number,
    searchText: string
  ) => {
    try {
      setIsLoading(true);
      const count = await doFetchAllTicketsCountByStatus(tab, searchText);
      const response = await doFetchAllTicketsByStatus(
        tab,
        perPageData,
        page,
        searchText
      );
      setTab((prevTab: any) =>
        prevTab.map((tabItem: any) => ({
          ...tabItem,
          count: tabItem.value === tab ? count : tabItem.count,
        }))
      );
      const tableData = convertDataToTableFormat(response);
      setActiveData([...tableData]);
      setCount(count);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getHeader = () => {
    return (
      <Box>
        <CustomAppHeader className={classes.headerBackgroundColor}>
          <Box ml={2}>
            <Typography sx={classes.heading}>Tickets</Typography>
          </Box>
          <Stack
            sx={classes.outerTabBox}
            direction={{
              xl: "row",
              lg: "column",
              md: "column",
              sm: "column",
              xs: "column",
            }}
            justifyContent={{
              lg: "space-between",
              md: "space-between",
              sm: "space-between",
              xs: "flex-start",
            }}
            ml={2}
          >
            <CustomTabs
              containerId="tickets_category_tabs"
              changeValue={(newVaue: string) => {
                handleTabChange(newVaue);
              }}
              selected={currentSelectedTab}
              tabConfig={tab}
              redirectTabValue={redirectTabValue}
              state={props?.location?.state}
            />
            <Stack
              direction="row"
              justifyContent={{
                xl: "flex-end",
                lg: "flex-start",
                md: "flex-start",
                sm: "flex-start",
                xs: "flex-start",
              }}
              spacing={1}
            >
              <Box sx={classes.alignmentStyle}>
                <BootstrapInput
                  id="tickets_search_field"
                  placeholder="Search text"
                  sx={classes.input}
                  inputRef={searchValue}
                  onChange={debounceEventHandler(
                    handleSearchParams,
                    strings.SEARCH_TIME_OUT
                  )}
                  endAdornment={
                    <Box mr={1}>
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    </Box>
                  }
                />
              </Box>
            </Stack>
          </Stack>
        </CustomAppHeader>
      </Box>
    );
  };

  const convertDataToTableFormat = (data: any[]) => {
    return data.map((el: any) => {
      const assignees =
        el.hasOwnProperty("assigneeName") && el?.assigneeName.split(",");
      const displayedAssignees = assignees && assignees?.slice(0, 2).join(", ");
      const tooltipContent = displayedAssignees && assignees?.join(", ");
      return {
        id: (
          <Link
            color="#2970FF"
            to={`${urls.ticketsViewPath}/${el.id}?tab=${currentSelectedTab}`}
            style={{
              textDecoration: "none",
            }}
          >
            <Typography
              sx={{
                ...mediumFont,
                color: pinkDarkColor,
              }}
            >
              {el.id}
            </Typography>
          </Link>
        ),
        name: el.name,
        process: el.process,
        description: el.description,
        assigneeName: el.hasOwnProperty("assigneeName") ? (
          <HtmlTooltip placement="top" title={tooltipContent} arrow>
            <Typography sx={{ wordBreak: "break-word", cursor: "pointer" }}>
              {assignees.length > 2
                ? `${displayedAssignees}, ...`
                : displayedAssignees}
            </Typography>
          </HtmlTooltip>
        ) : (
          <Chip size="small" label="Unassigned" />
        ),
        activationDate: convertESTtoUserLocalDateAndTime(
          el.activationDate,
          "MMMM D, YYYY [at] h:mm A",
          "MM/DD/YYYY HH:mm:ss"
        ),
        priority: el.priority,
        createdByName: el.createdByName,
        action: el.action,
      };
    });
  };

  const getTicketsTable = () => {
    return (
      <Box id="tickets_display_table" sx={classes.tableStyle}>
        <CustomTable
          headers={tableHeaders}
          rows={activeData}
          paginationCount={count}
          rowsPerPage={perPageData}
          pageNumber={pageNumber}
          handlePageChange={handlePageChange}
          isLoading={isLoading}
          searchParam={searchParam}
          currentSelectedTab={currentSelectedTab}
          setPage={setPageNumber}
          handlePerPageData={handlePerPageData}
          perPageData={perPageData}
          // rowsPerPage={perPageData}
          isRowPerPageEnable={true}
        />
      </Box>
    );
  };
  return (
    <>
      {getHeader()} {getTicketsTable()} <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default AllTickets;
