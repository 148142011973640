import fabLayout from "assets/images/fab-layout.svg";
import InstagramLayout from "assets/images/inta-layout.svg";
import strings from "global/constants/StringConstants";

export const miniSocialCards = [
  {
    appName: strings.FACEBOOK,
    appLayout: fabLayout,
    altText: "facebook-layout",
  },
  {
    appName: strings.INSTAGRAM,
    appLayout: InstagramLayout,
    altText: "instagram-layout",
  },
];
