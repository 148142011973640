import strings from "global/constants/StringConstants";
import urls from "global/constants/UrlConstants";
import { getCallParams, makeCall } from "utils/service";

export const getApprovedWhatsAppTemplatesCount = async () => {
  try {
    const url: string = `${
      urls.whatsAppBaseUrl
    }/status/${strings.approved.toUpperCase()}/count`;
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getApprovedWhatsAppTemplates = async (count: number) => {
  try {
    const url: string = `${
      urls.whatsAppBaseUrl
    }/status/${strings.approved.toUpperCase()}/page/1/limit/${count}`;
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getWhatsAppFromPhoneNumbers = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.getWhatsAppFromDetails,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const sendWhatsAppCampaign = async (campaignPayload: any) => {
  try {
    const callParams = await getCallParams("POST", campaignPayload);
    const response: any = await makeCall(urls.sendWhatsAppCampaign, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const checkWhatsAppIntegration = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.getIntegratedAppNames, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
