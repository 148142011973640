import { ChangeEventHandler, useState } from "react";
import CustomTextareaStyles from "global/components/CustomTextarea/CustomTextarea.styles";
import {
  Box,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import {
  formatTextHandler,
  textFormationData,
} from "global/components/CustomTextarea/CustomTextareaHelpers";
import strings from "global/constants/StringConstants";
import { isTruthy } from "helpers/methods";

interface CustomProps {
  inputName: string;
  value: string;
  error: string;
  onChangeHandler: ChangeEventHandler<HTMLTextAreaElement>;
  maxLength: number;
  textareaRows: number;
  allowTextFormatting: boolean;
  dataObjectSetter?: Function;
}

const CustomTextarea = (props: CustomProps) => {
  const classes = CustomTextareaStyles;
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(0);
  const [isTextareaActive, setIsTextareaActive] = useState<boolean>(false);

  const bodyTextFormatHandler = (inputName: string, markdownSymbol: string) => {
    const manipulatedString: string = formatTextHandler(
      props.value,
      markdownSymbol,
      startIndex,
      endIndex,
      setEndIndex
    );
    props?.dataObjectSetter?.(inputName, manipulatedString);
  };

  const onFocusHandler = () => {
    setIsTextareaActive(true);
  };

  const onBlurHandler = () => {
    setIsTextareaActive(false);
  };

  const onSelectHandler = (event: any) => {
    setStartIndex(event.target.selectionStart);
    setEndIndex(event.target.selectionEnd);
  };

  const getTextFormatIcons = (formattingType: string) => {
    switch (formattingType) {
      case strings.bold:
        return <FormatBoldIcon />;
      case strings.italic:
        return <FormatItalicIcon />;
      case strings.strikethrough:
        return <FormatStrikethroughIcon />;
    }
  };

  const getFormattedButtons = () => {
    return (
      <Stack direction={"row"}>
        {textFormationData.map((item: any) => (
          <IconButton
            sx={classes.iconButtonStyles}
            onClick={() => {
              bodyTextFormatHandler(props.inputName, item.markdownSymbol);
            }}
          >
            {getTextFormatIcons(item.formattingType)}
          </IconButton>
        ))}
      </Stack>
    );
  };

  return (
    <>
      <Box
        sx={[
          classes.commonContainerStyles,
          isTextareaActive
            ? classes.mainContainerActive
            : isTruthy(props.error)
            ? classes.mainContainerError
            : classes.mainContainerInactive,
        ]}
      >
        <textarea
          name={props.inputName}
          value={props.value}
          onChange={(event) => {
            props.onChangeHandler(event);
          }}
          onSelect={(event) => {
            onSelectHandler(event);
          }}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          maxLength={props.maxLength}
          rows={props.textareaRows}
          style={classes.templateComponentTextarea}
        />
        <Box sx={classes.templateComponentHelperText}>
          <Box sx={classes.characterCountContainer}>
            <Typography sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
              {props.value?.length}/{props.maxLength}
            </Typography>
          </Box>
          {props.allowTextFormatting && getFormattedButtons()}
        </Box>
      </Box>
      <FormHelperText error sx={classes.errorStyles}>
        {props.error}
      </FormHelperText>
    </>
  );
};

export default CustomTextarea;
