import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/store";
import { FormattedResources } from "models/interfaces";
import { Step } from "react-joyride";

export interface TourState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  isTourActive: boolean;
}

const initialState: TourState = {
  run: true,
  stepIndex: 0,
  steps: [],
  isTourActive: true,
};

export const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    changeRun: (state, action: PayloadAction<boolean>) => {
      state.run = action.payload;
    },
    changeStepIndex: (state, action: PayloadAction<number>) => {
      state.stepIndex = action.payload;
    },
    changeSteps: (state, action: PayloadAction<any[]>) => {
      state.steps = action.payload;
    },
    changeIsTourActive: (state, action: PayloadAction<boolean>) => {
      state.isTourActive = action.payload;
    },
  },
});

export const { changeRun, changeStepIndex, changeSteps, changeIsTourActive } =
  tourSlice.actions;

export const selectRun = (state: RootState) => state.tour.run;
export const selectStepIndex = (state: RootState) => state.tour.stepIndex;
export const selectSteps = (state: RootState) => state.tour.steps;
export const selectIsTourActive = (state: RootState) => state.tour.isTourActive;

export default tourSlice.reducer;
