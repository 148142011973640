import { boldFont, getRelativeFontSize, regularFont } from "utils/styles";

const CampaignDetailsSectionStyles = {
  viewModeBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerLabel: {
    ...boldFont,
    fontSize: getRelativeFontSize(5),
  },
  headerSubDescription: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
  },
  description: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#666",
  },
  buttonStyles: {
    width: "135px",
  },
} as const;
export default CampaignDetailsSectionStyles;
