import { useState, useEffect } from "react";
import { CustomAppHeader, CustomButton } from "global/components";
import Step2Styles from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/StepTwo.styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import CampaignNameSection from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/campaignDetailsSection/CampaignNameSection";
import SubjectSection from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/campaignDetailsSection/SubjectSection";
import AttachmentsSection from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/campaignDetailsSection/AttachmentsSection";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import PreviewTemplate from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/PreviewTemplate";
import ScheduleTimeSection from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/campaignDetailsSection/ScheduleTimeSection";
import { step2Validation } from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/CreateSequentialCampaignsHelpers";

interface CustomProps {
  mainCampaignDataArray: any[];
  setMainCampaignDataArray: Function;
  setActiveStep: Function;
  attachmentFileSizeArray: any[];
  setAttachmentFileSizeArray: Function;
  totalAttachmentsSizeArray: number[];
  setTotalAttachmentsSizeArray: Function;
  templateHtmlArray: string[];
  setStepThreeActiveIndex: Function;
  isValidArray: boolean[];
  setIsValidArray: Function;
  isAccordionOpenArray: boolean[];
  setIsAccordionOpenArray: Function;
  handleSendCampaign: Function;
}

const StepTwo = (props: CustomProps) => {
  const classes = Step2Styles;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    props.setIsAccordionOpenArray((prevState: boolean[]) =>
      props.isValidArray.map((isValid: boolean, index: number) =>
        isValid ? prevState[index] : !isValid
      )
    );
  }, [props.isValidArray]);

  useEffect(() => {
    props.setStepThreeActiveIndex(-1);
  }, []);

  const onEditHandler = (index: number) => {
    props.setStepThreeActiveIndex(index);
    props.setActiveStep((prevStep: number) => prevStep + 1);
  };

  const getCampaignDetails = (index: number) => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={8} xl={8} lg={8}>
          <Box sx={classes.campaignDetailsBox}>
            <CampaignNameSection
              index={index}
              mainCampaignDataArray={props.mainCampaignDataArray}
              setMainCampaignDataArray={props.setMainCampaignDataArray}
              isValidArray={props.isValidArray}
            />
          </Box>
          <Box sx={classes.campaignDetailsBox}>
            <SubjectSection
              index={index}
              mainCampaignDataArray={props.mainCampaignDataArray}
              setMainCampaignDataArray={props.setMainCampaignDataArray}
              isValidArray={props.isValidArray}
            />
          </Box>
          <Box sx={classes.campaignDetailsBox}>
            <AttachmentsSection
              index={index}
              setIsLoading={setIsLoading}
              mainCampaignDataArray={props.mainCampaignDataArray}
              setMainCampaignDataArray={props.setMainCampaignDataArray}
              attachmentFileSizeArray={props.attachmentFileSizeArray}
              setAttachmentFileSize={props.setAttachmentFileSizeArray}
              totalAttachmentSizeArray={props.totalAttachmentsSizeArray}
              setTotalAttachmentSize={props.setTotalAttachmentsSizeArray}
            />
          </Box>
          <Box sx={classes.campaignDetailsBox}>
            <ScheduleTimeSection
              index={index}
              mainCampaignDataArray={props.mainCampaignDataArray}
              setMainCampaignDataArray={props.setMainCampaignDataArray}
              isValidArray={props.isValidArray}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          xl={4}
          lg={4}
          sx={{ backgroundColor: "#eee" }}
        >
          <PreviewTemplate previewHTML={props.templateHtmlArray[index]} />
        </Grid>
      </Grid>
    );
  };

  const accordionHandler = (index: number, expanded: boolean) => {
    const temporaryAccordionArray = [...props.isAccordionOpenArray];
    temporaryAccordionArray.splice(index, 1, expanded ? true : false);
    props.setIsAccordionOpenArray([...temporaryAccordionArray]);
  };

  const getCampaignAccordions = () => {
    return (
      <>
        {props.mainCampaignDataArray.map(
          (CampaignObject: any, index: number) => (
            <Accordion
              onChange={(event, expanded) => {
                accordionHandler(index, expanded);
              }}
              expanded={props.isAccordionOpenArray[index]}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={classes.accordionTitle}>
                  {CampaignObject.name.value}
                </Typography>
                <EditIcon
                  sx={{ color: "#777777" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onEditHandler(index);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>{getCampaignDetails(index)}</AccordionDetails>
            </Accordion>
          )
        )}
      </>
    );
  };

  const handleSend = () => {
    const { isValid, errorLadenArray } = step2Validation(
      props.mainCampaignDataArray,
      props.isValidArray
    );
    props.setIsValidArray([...isValid]);
    props.setMainCampaignDataArray([...errorLadenArray]);
    if (!isValid.includes(false)) {
      props.handleSendCampaign();
    }
  };

  return (
    <>
      <CustomAppHeader className={classes.headerSection}>
        <Grid container sx={classes.headerGridContainer}>
          <Typography sx={classes.pageTitle}>Sequence List</Typography>
          <Stack sx={classes.headerButtonStack}>
            <Box sx={classes.headerButtonStyle}>
              <CustomButton
                id="sequence_step2_back_button"
                label="Back"
                onClick={() => {
                  props.setActiveStep((prevStep: number) => prevStep - 1);
                }}
                customClasses={classes.headerButton}
              />
            </Box>
            <Box sx={classes.headerButtonStyle}>
              <CustomButton
                id="sequence_send_button"
                label="Send"
                onClick={() => {
                  handleSend();
                }}
                customClasses={classes.headerButton}
              />
            </Box>
          </Stack>
        </Grid>
      </CustomAppHeader>
      <Box sx={classes.wrapper}>{getCampaignAccordions()}</Box>
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default StepTwo;
