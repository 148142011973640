import {
  boldFont,
  chipBackgroundColor,
  getRelativeFontSize,
  lightTextColor,
  mainContainer,
  mediumFont,
  primaryBlackColor,
  primaryHeadingColor,
  pureWhiteColor,
  regularFont,
  textLightColor,
  theme,
  infoTextColor,
  pinkDarkColor,
  buttonWhiteBg,
  purplePrimaryColor,
  warningColor,
  purpleThemedSelectComponent,
  pinkThemedMenuItems,
} from "utils/styles";

const ticketsStyles = {
  mainSection: {
    ...mainContainer,
    marginTop: theme.spacing(2),
  },
  headerBackgroundColor: {
    backgroundColor: "#fcf5ff",
    padding: "8px 13px 18px 5px",
  },
  tableStyle: {
    padding: theme.spacing(2),
    paddingTop: "2px",
    marginTop: "20px",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(0),
    },
  },
  headerBox: {
    background: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {},
  },
  mainBox: {
    padding: "15px 30px",
  },
  ticketsText: {
    ...boldFont,
  },
  heading: {
    ...boldFont,
    margin: "32px 0 8px 0",
    fontSize: getRelativeFontSize(10),
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1),
    },
  },
  outerTabBox: {
    borderBottom: "none",
    marginTop: "8px",
  },
  tab: {
    "& .MuiButtonBase-root-MuiTab-root": {
      padding: "12px 2px",
    },
  },
  tabBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px",
    borderRadius: "10px",
    height: "40px",
    width: "100%",
  },
  active: {
    backgroundColor: "#F0ECFF",
    color: "#F9F9F9",
    textDecoration: "none",
  },
  inActive: {
    backgroundColor: "#F9F9F9",
    color: "#828282",
  },
  tabText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...mediumFont,
    marginLeft: theme.spacing(1),
    textTransform: "none",
  },
  counts: {
    marginLeft: theme.spacing(2),
    opacity: "0.5",
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    marginLeft: theme.spacing(1),
  },

  alignmentStyle: {
    display: "flex",
    alignItems: "center",
    marginRight: "8px",
  },

  input: {
    height: "47px",
    border: "1px solid #E7E7E7",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
    },
  },
  outerBox: {
    width: "95px",
  },

  iframeTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(7),
  },

  btnBox: {
    color: "white",
    textTransform: "none",
    justifyContent: "space-between",
  },
  addBox: {
    background: "#4F31BC",
    width: "32px",
    height: "32px",
    borderRadius: "10px",
    color: "#FFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  titleWrapper: {
    height: "100px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
  },

  drawerTitle: {
    color: "#212121",
    ...boldFont,
    fontSize: getRelativeFontSize(10),
  },

  cancelButtonStyle: {
    background: "none",
    border: "1px solid",
    color: purplePrimaryColor,
    borderColor: buttonWhiteBg,
    "&:hover": {
      background: "none",
    },
  },
  closeButton: {
    background: "none",
    color: textLightColor,
    border: "none",
    "&:hover": {
      background: "none",
    },
  },
  bodyWrapper: {
    marginTop: "20px",
    paddingBottom: "50px",
  },

  dropDownStyle: {
    backgroundColor: pureWhiteColor,
    height: "47px",
    padding: "2px",
    minWidth: "100%",
    borderRadius: "12px",
    ...regularFont,
    ...purpleThemedSelectComponent,
  },

  optionStyle: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },

  titleBodyWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  bodyTitle: {
    color: "#1A0224",
    fontSize: getRelativeFontSize(8),
    ...boldFont,
  },

  bodyText: {
    ...regularFont,
    color: lightTextColor,
    wordBreak: "break-word",
  },

  spanBold: {
    ...boldFont,
    marginRight: "10px",
  },

  spanComments: {
    ...mediumFont,
    marginLeft: "5px",
    wordBreak: "break-word",
  },

  ticketInfoText: {
    ...regularFont,
    display: "flex",
    alignItems: "flex-start",
    marginTop: "10px",
  },

  commentWrapper: {
    borderRadius: "20px",
    backgroundColor: "#F8F8F8",
    marginBottom: "10px",
    padding: "15px",
    wordBreak: "break-word",
  },

  commentBy: {
    ...boldFont,
  },

  dotSeparate: {
    backgroundColor: "#9a9a9a",
    height: "7px",
    width: "7px",
    borderRadius: "50%",
    marginRight: "10px",
    marginLeft: "10px",
  },

  commentDate: {
    ...boldFont,
    fontSize: "14px",
    color: infoTextColor,
  },

  listWrapper: {
    "& .listItem:before ": {
      content: "-",
    },
  },

  uploadFileTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(4),
  },
  uploadFileName: {
    ...regularFont,
    color: lightTextColor,
  },

  uploadGroup: {
    display: "flex",
  },

  uploadWrapper: {
    display: "flex",
    flexDirection: "column",
    background: "#F8F8F8",
    padding: "15px",
    borderRadius: "20px",
  },

  fileNotFoundWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
  },

  fileNotFoundText: {
    ...mediumFont,
    color: infoTextColor,
  },

  uploadFile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    width: "100%",
  },

  buttonStyle: {
    width: 0,
    background: "none",
    color: primaryBlackColor,
    outline: "none",
    border: "none",
    boxShadow: "none",
    fontSize: getRelativeFontSize(2),
    "&:hover": {
      background: "none",
    },
  },

  uploadButtons: {
    display: "flex",
    gap: "20px",
  },

  inputLabel: {
    ...boldFont,
    marginBottom: "10px",
  },

  checkLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-6px",
    "& .css-ahj2mt-MuiTypography-root": {
      ...mediumFont,
    },
  },

  star: {
    color: warningColor,
    ...boldFont,
  },

  testAreaStyle: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important",
    },
  },

  testAreaStyleError: {
    padding: "10px ",
    borderRadius: "12px",
    borderColor: warningColor,
  },

  titleStyle: {
    ...mediumFont,
    color: pinkDarkColor,
    padding: "0",
    fontSize: "14px",
    wordBreak: "break-all",
    minWidth: "150px",
  },

  paperStyle: {
    gap: "10px",
    borderRadius: "0",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
  },

  chipStyle: {
    background: chipBackgroundColor,
    textTransform: "uppercase",
    ...boldFont,
    display: "flex",
    justifyContent: "center",
  },

  errorStyle: {
    paddingLeft: "15px",
  },
} as any;

export default ticketsStyles;
