export const initialContactsFilterState = () => {
  return {
    page: 1,
    rowsPerPage: 10,
    searchText: "",
    dropdownValue: contactsDropdownOptions[0].value,
  };
};

export const contactTableHeader = [
  {
    name: "Email",
    field: "emailId",
    sorting: true,
  },
  {
    name: "First Name",
    field: "firstName",
    sorting: true,
  },
  {
    name: "Last Name",
    field: "lastName",
    sorting: true,
  },
  {
    name: "Contact Number",
    field: "contactNo",
    sorting: true,
  },
  {
    name: "Tags",
    field: "tags",
    sorting: false,
  },
  {
    name: "Action",
    field: "action",
    sorting: false,
  },
];

export const deleteModalHeader = [
  { name: "Group Name", field: "name" },
  { name: "Owner", field: "owner" },
  { name: "Type", field: "type" },
];

export const recipientsSummaryHeader = [
  { name: "Email From", field: "campaignOwner" },
  { name: "Campaign Name", field: "campaignName" },
  { name: "Status", field: "status" },
  { name: "Emailed On", field: "scheduleTime" },
];

export const emailModelHeader = [
  { name: "Email From", field: "campaignOwner" },
  { name: "Campaign Name", field: "campaignName" },
  { name: "Opened", field: "opened" },
  { name: "Clicked", field: "clicked" },
  { name: "Emailed On", field: "scheduleTime" },
];

export const contactsDropdownOptions = [
  { label: "All Contacts", value: "all" },
  { label: "My Contacts", value: "my" },
  { label: "Team's Contact", value: "team" },
];
