import { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import OnboardingStyles from "screens/Onboarding/Onboard.styles";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { KeyboardBackspace } from "@mui/icons-material";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { CustomButton, CustomInput } from "global/components";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { store } from "utils/store";
import {
  getFormattedStatsCount,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import BillingUsageStyles from "screens/Settings/BillingUsage/BillingUsage.styles";
import star from "assets/images/Star.svg";

const cardStyle = {
  style: {
    base: {
      color: "#00000",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#000",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

interface PlanPaymentProps {
  chosenPlan: any;
  finalSubmitPayment: Function;
  cardData: any[];
  updatePlanHandler: Function;
  featurePlanDetail: any;
  handleBack: Function;
}

const PlanPayment = (props: PlanPaymentProps) => {
  const classes = OnboardingStyles;
  const billingClasses = BillingUsageStyles;
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [cardName, setCardName] = useState<string>(
    store.getState().auth.userName ?? ""
  );
  const [userEmail, setUserEmail] = useState<string>(
    store.getState().auth.email ?? ""
  );

  const confirmPaymentHandler = () => {
    if (props.featurePlanDetail.price === 0) {
      handleSubmit();
    } else {
      props.updatePlanHandler();
    }
  };

  const getDefaultCardNumber = (cardData: any[]) => {
    const cardNumber = cardData.find(
      (cardItem: any) => cardItem.default
    ).number;
    return `XXXX-XXXX-XXXX-${cardNumber ?? ""}`;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (stripe !== null && elements !== null) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: cardName,
            email: userEmail,
          },
        });
        if (error) {
          openErrorNotification(
            isTruthy(error) ? error.message : notifiers.GENERIC_ERROR
          );
          setLoading(false);
        } else {
          setLoading(false);
          props.finalSubmitPayment(paymentMethod.id);
        }
      }
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const rightIcon = () => {
    return (
      <Box
        component="span"
        sx={{
          marginRight: 1,
          color: "#ADC804",
          svg: {
            height: "20px",
            width: "20px",
          },
        }}
      >
        <DoneRoundedIcon />
      </Box>
    );
  };

  return (
    <>
      <Stack direction="row">
        <IconButton
          onClick={() => {
            props.handleBack();
          }}
        >
          <KeyboardBackspace />
        </IconButton>
        <Typography sx={classes.headingText}>Payment</Typography>
      </Stack>
      <Grid container sx={billingClasses.mainContainer}>
        <Grid
          item
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={billingClasses.gridItemStyles}
        >
          <Box sx={billingClasses.borderBox}>
            <Box sx={billingClasses.inputLabels}>
              <CustomInput
                id="card_name"
                label="Name"
                required
                disabled={true}
                value={cardName}
                onChange={(event: any) => setCardName(event.target.value)}
              />
            </Box>
            <Box sx={billingClasses.inputLabels}>
              <CustomInput
                id="user_email"
                required
                label="Email"
                disabled={true}
                value={userEmail}
                onChange={(event: any) => setUserEmail(event.target.value)}
              />
            </Box>
            <Box sx={billingClasses.inputLabels}>
              <InputLabel required sx={classes.inputLabelStyles}>
                Card Number
              </InputLabel>
              {props.featurePlanDetail.price === 0 ? (
                <Box sx={billingClasses.cardElementStyle1}>
                  <CardElement options={cardStyle} id="paid_plan_card_number" />
                </Box>
              ) : (
                isTruthy(props.cardData) && (
                  <Box sx={billingClasses.inputLabels}>
                    <CustomInput
                      id="disabled_card_input_element"
                      disabled={true}
                      value={getDefaultCardNumber(props.cardData)}
                    />
                  </Box>
                )
              )}
            </Box>
            <Box sx={billingClasses.buttonContainer}>
              <CustomButton
                id="plan_payment_submit_button"
                label="Confirm Payment"
                onClick={confirmPaymentHandler}
                icon={<HttpsOutlinedIcon />}
                customClasses={billingClasses.buttonStyles}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          sx={billingClasses.gridItemStyles}
        >
          <Box sx={billingClasses.planContainer1}>
            <Box sx={billingClasses.planCard}>
              <Box component={"img"} src={star} />
              <Typography sx={billingClasses.yourPlan}>Your Plan</Typography>
            </Box>
            <Box mt={2}>
              <Typography sx={billingClasses.mediumText}>
                You have selected{" "}
                <span style={classes.purpleHeadingText}>
                  {props.chosenPlan.name}
                </span>{" "}
                plan
              </Typography>
              <Typography sx={classes.shortdesc} gutterBottom>
                Sell even more with personalization, optimization tools.
              </Typography>
              <Typography sx={billingClasses.mediumText}>
                <span style={classes.purpleHeadingText}>
                  {props.chosenPlan.currency === "INR"
                    ? `₹${getFormattedStatsCount(props.chosenPlan.price ?? 0)}`
                    : `$${getFormattedStatsCount(props.chosenPlan.price ?? 0)}`}
                </span>
              </Typography>
              <Typography fontWeight={"bold"} textAlign={"center"}>
                Validity: {props.chosenPlan.validityInDays} days
              </Typography>
            </Box>
            <Grid container spacing={2} mt={2} p={2}>
              {props?.chosenPlan?.feature?.map((item: any) => {
                return (
                  <>
                    <Grid item xs={4}>
                      <Box sx={billingClasses.featureContent}>
                        {rightIcon()}
                        <Typography sx={billingClasses.featureContentData}>
                          {item}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Typography sx={classes.paymentTerms}>
          By clicking “Confirm Payment,” I acknowledge that I am subscribing to
          a plan and give Mailzzy permission to charge my payment method monthly
          at the current rate (plus any applicable taxes) until I cancel. I can
          cancel at any time by visiting the Billing Usage tab in my Settings
          page.
        </Typography>
        <Typography sx={classes.paymentTerms}>
          The actual price may differ. The prices shown are estimates based on
          current exchange rates. The exact amount charged will vary according
          to the exchange rate at the time of payment processing. For exact base
          pricing, please refer to the prices listed in USD.
        </Typography>
      </Box>
      <CustomLoader isLoading={loading} />
    </>
  );
};

export default PlanPayment;
