import strings from "global/constants/StringConstants";

let options = [
  {
    label: strings.thisWeek,
  },
  {
    label: strings.lastWeek,
  },
  {
    label: strings.thisMonth,
  },
  {
    label: strings.lastMonth,
  },
  {
    label: strings.custom,
  },
];

export const getOptions = (campaignRetention: number) => {
  if (+campaignRetention === 1) {
    const updatedOptions = options.filter(
      (option) => option.label !== strings.lastMonth
    );
    return updatedOptions;
  }
  return options;
};

export const dashboardTourSteps = [
  {
    target: "#Dashboard_Interval_Dropdown",
    content:
      "Users have the option to choose their desired data range, spanning up to 45 days. The dashboard will dynamically reflect the selected timeframe's data",
    disableBeacon: true,
    disableOverlayClose: true,
    pointsArray: [],
  },
  {
    target: "#Dashboard_My_Campaigns",
    content:
      "Based on the chosen interval, all regular campaign will be listed here for easy access.",
    disableOverlayClose: true,
    pointsArray: [],
  },
  {
    target: "#Dashboard_Campaign_Recipients",
    content:
      "A visual representation illustrates campaign statuses, adapting based on the selected date range",
    disableOverlayClose: true,
    pointsArray: [],
  },
  {
    target: "#Dashboard_Recent_Activities",
    content:
      "Displays recent activities, such as executed campaigns and contact-related activities.",
    disableOverlayClose: true,
    pointsArray: [],
  },
  {
    target: "#Dashboard_Last_Campaign",
    content:
      "The outcomes of the last executed campaigns are showcased in this segment.",
    disableOverlayClose: true,
    pointsArray: [],
  },
  {
    target: "#Dashboard_Social_Campaign_Calender",
    content:
      "Based on the interval, all social campaign will be listed here for easy access.",
    disableOverlayClose: true,
    pointsArray: [],
  },
];

export const getCampaignRecipientInitialStates =
  (): CampaignRecipientCounts => ({
    Total: {
      name: "Total",
      value: 0,
    },
    Success: {
      name: "Success",
      value: 0,
    },
    Opened: {
      name: "Opened",
      value: 0,
    },
    Clicked: {
      name: "Clicked",
      value: 0,
    },
    Failed: {
      name: "Failed",
      value: 0,
    },
    Unsubscribed: {
      name: "Unsubscribed",
      value: 0,
    },
  });

export const getRecentActivityOptions = (
  hasCampaignFetchAccess: boolean,
  hasContactFetchAccess: boolean
) => {
  let options = [];
  hasCampaignFetchAccess &&
    hasContactFetchAccess &&
    options.push({
      label: "All",
      value: strings.allActivity,
    });
  hasCampaignFetchAccess &&
    options.push({
      label: "Campaign",
      value: strings.campaignActivity,
    });
  hasContactFetchAccess &&
    options.push({
      label: "Audience",
      value: strings.audienceActivity,
    });

  return options;
};

export const COLORS = [
  "rgb(138, 185, 4)",
  "rgb(231, 69, 77)",
  "rgb(248, 101, 34)",
  "rgb(108, 150, 226)",
  "#6C96E2",
  "#00C49F",
  "#CC5285",
  "#0088FE",
  "#8AB904",
  "#F86522",
  "#E7454D",
  "#FFCA16",
  "#FFBB28",
  "#FF8042",
];

export interface Counts {
  name: string;
  count: number;
}

export interface CampaignRecipientCounts {
  [key: string]: { name: string; value: number };
}

export interface CampaignCounts {
  [key: string]: { name: string; value: number; fill: string };
}

export interface RecentCampaignStats {
  id: string;
  name: string;
  createdOn: string;
  scheduleTime: Date;
  stats: CampaignCounts;
}

export interface Last3DaysCampaigns {
  [key: string]: any[];
}
