import {
  boldFont,
  buttonWhiteBg,
  centerItemFlex,
  getRelativeFontSize,
  infoTextColor,
  inputLabelRequiredColor,
  mediumFont,
  pinkDarkColor,
  primaryHeadingColor,
  regularFont,
  theme,
} from "utils/styles";

const CustomDomainStyles = {
  headingContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: { flexDirection: "column" },
  },
  backIconBox: { marginLeft: "-12px", display: "flex", alignItems: "center" },
  pageTitle: {
    fontSize: getRelativeFontSize(6),
    ...boldFont,
    display: "flex",
    alignItems: "center",
    color: primaryHeadingColor,
  },
  domainNameStyles: {
    ...boldFont,
    color: "#BB0070",
    fontSize: getRelativeFontSize(6),
    display: "flex",
    alignItems: "center",
    marginLeft: "5px",
  },
  inputContainer: { paddingTop: "20px" },
  nameField: {
    ...boldFont,
    color: "#212121",
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
  },
  senderIdTextarea: {
    width: "100%",
    minWidth: "200px",
    borderRadius: "12px",
    backgroundColor: buttonWhiteBg,
    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      backgroundColor: "#fff",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        borderColor: "#4B0150",
      },
    },
  },
  addButtonStyles: {
    width: "150px",
  },
  boldFonts: {
    ...boldFont,
    fontSize: getRelativeFontSize(13),
    textAlign: "center",
  },
  centerItemFlex: {
    ...centerItemFlex,
  },
  addDialogFooter: {
    display: "flex",
    gap: "10px",
    ...centerItemFlex,
    width: "100%",
    margin: "20px",
    justifyContent: "center",
    "& button": {
      width: "120px",
    },
  },
  addDialogCancelButton: {
    color: "#212121 !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E7E7E7",
    "&:hover": {
      background: "none",
    },
  },
  domainNameHelperText: {
    color: infoTextColor,
    ...regularFont,
    fontSize: getRelativeFontSize(),
  },
} as const;

export default CustomDomainStyles;
