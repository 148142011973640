import moment from "moment";
import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import WhatsAppMessagePreviewStyles from "global/components/WhatsAppMessagePreview/WhatsAppMessagePreview.styles";
import { CameraAltOutlined } from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import VideocamIcon from "@mui/icons-material/Videocam";
import CallIcon from "@mui/icons-material/Call";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { getMarkupText } from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/CreateWhatsAppTemplateHelpers";
import { headerFormatTabContent } from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/CreateWhatsAppTemplateHelpers";
import { useAppSelector } from "utils/hooks";
import { selectName } from "redux/authSlice";

interface CustomProps {
  isHeaderIncluded: boolean;
  isFooterIncluded: boolean;
  headerTextValue?: string;
  bodyTextValue: string;
  footerTextValue?: string;
  headerFormat: string;
}

export const WhatsAppMessagePreview = (props: CustomProps) => {
  const classes = WhatsAppMessagePreviewStyles;
  const userName = useAppSelector(selectName);

  const getTemplateHeaderPreviewComponent = () => {
    return (
      <Box>
        {props.headerFormat === headerFormatTabContent[1].label ? (
          <Box
            sx={{
              margin: "3px",
            }}
          >
            <img
              src="https://softsages-mailzzy-docs.s3.amazonaws.com/routerPromptChange.png"
              // src={props.templateData.header.headerMediaPreviewUrl},
              alt="thumbnail_image"
              style={{ borderRadius: "5px" }}
              width="100%"
              height="auto"
            />
          </Box>
        ) : (
          <Box sx={{ margin: "10px" }}>
            <Typography sx={classes.previewHeaderText}>
              {props?.headerTextValue}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const getTemplateBodyPreviewComponent = () => {
    return (
      <Box sx={classes.previewBodyText}>
        {getMarkupText(props?.bodyTextValue)}
      </Box>
    );
  };

  const getTemplateFooterPreviewComponent = () => {
    return (
      <Box sx={{ margin: "10px" }}>
        <Typography sx={classes.previewFooterText}>
          {props?.footerTextValue}
        </Typography>
      </Box>
    );
  };

  const getTimeStamp = () => {
    return (
      <Box sx={classes.footerTimeStampContainer}>
        <Typography sx={classes.footerTimeStampText}>
          {moment().format("hh:mm")}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={classes.mainPreviewBox}>
      <Stack sx={classes.previewHeaderStack}>
        <KeyboardArrowLeftIcon sx={classes.leftArrowIcon} />
        <Avatar>{userName.charAt(0)}</Avatar>
        <Box
          sx={{
            marginLeft: "5px",
          }}
        >
          <Box sx={classes.whatsAppAccountNameContainerStyles}>
            <Typography sx={classes.whatsAppAccountNameStyles}>
              {userName.substring(0, 10)}
              {userName.length > 10 && "..."}
            </Typography>
            <VerifiedIcon sx={classes.verifiedIconStyles} />
          </Box>
          <Typography sx={{ fontSize: "12px" }}>online</Typography>
        </Box>
        <Box sx={classes.previewHeaderLeftIcons}>
          <VideocamIcon sx={classes.videoCallIcon} />
          <CallIcon sx={classes.callIcon} />
        </Box>
      </Stack>
      <Box sx={classes.previewBodyContainer}>
        <Box sx={classes.currentDateContainer}>
          <Box sx={classes.currentDateBox}>
            <Typography sx={classes.currentDateText}>
              {moment().format("ll")}
            </Typography>
          </Box>
        </Box>
        <Box sx={classes.speechBubbleContainer}>
          <Card sx={classes.speechBubbleCard}>
            {props.isHeaderIncluded && getTemplateHeaderPreviewComponent()}
            {getTemplateBodyPreviewComponent()}
            {props.isFooterIncluded && getTemplateFooterPreviewComponent()}
            {getTimeStamp()}
          </Card>
          <Box sx={classes.speechBubbleArrowBackground}></Box>
          <Box sx={classes.mainSpeechBubbleArrow}></Box>
        </Box>
      </Box>
      <Stack sx={classes.previewFooterContainer}>
        <AddOutlinedIcon sx={classes.addIcon} />
        <Box sx={classes.previewFooterTextInputBox}>
          <FileOpenOutlinedIcon sx={classes.FileOpenIcon} />
        </Box>
        <CameraAltOutlined sx={classes.cameraIcon} />
        <MicNoneOutlinedIcon sx={classes.micIcon} />
      </Stack>
      <Box sx={classes.previewSpeakerContainer}>
        <Box sx={classes.previewSpeaker}></Box>
      </Box>
    </Box>
  );
};

export default WhatsAppMessagePreview;
