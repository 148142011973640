import strings from "global/constants/StringConstants";
import { tabInterface } from "models/interfaces";

export const assignedTicketHeader = [
  {
    name: "Title",
    field: "name",
  },
  {
    name: "Process",
    field: "process",
  },
  {
    name: "Ticket #",
    field: "id",
  },
  {
    name: "Description",
    field: "description",
  },
  {
    name: "Raised By",
    field: "assignorName",
  },
  {
    name: "Assignee(s)",
    field: "assigneeName",
  },
  {
    name: "Status",
    field: "status",
  },
  {
    name: "Due By",
    field: "dueDate",
  },
];

export const RaisedTicketHeader = [
  {
    name: "Title",
    field: "name",
  },
  {
    name: "Process",
    field: "process",
  },
  {
    name: "Ticket #",
    field: "id",
  },
  {
    name: "Description",
    field: "description",
  },

  {
    name: "Assignee(s)",
    field: "assigneeName",
  },
  {
    name: "Status",
    field: "status",
  },
  {
    name: "Due By",
    field: "dueDate",
  },
];

export const completeTicketHeader = [
  {
    name: "Title",
    field: "name",
  },
  {
    name: "Process",
    field: "process",
  },
  {
    name: "Ticket #",
    field: "id",
  },
  {
    name: "Description",
    field: "description",
  },
  {
    name: "Raised By",
    field: "assignorName",
  },
  {
    name: "Assignee(s)",
    field: "assigneeName",
  },

  {
    name: "Due By",
    field: "dueDate",
  },
];

export const openTicketHeader = [
  {
    name: "Title",
    field: "name",
  },
  {
    name: "Process",
    field: "process",
  },
  {
    name: "Ticket #",
    field: "id",
  },
  {
    name: "Description",
    field: "description",
  },
  {
    name: "Raised By",
    field: "assignorName",
  },
  {
    name: "Assignee(s)",
    field: "assigneeName",
  },

  {
    name: "Due By",
    field: "dueDate",
  },

  {
    name: "Action",
    field: "action",
  },
];

export const fileFormate = [
  "jpeg",
  "gif",
  "png",
  "jpeg",
  "jpg",
  "pdf",
  "png",
  "txt",
];


export const tableHeaders = [
  {
    name: "#",
    field: "id",
    align: "left",
    sorting: false,
  },
  {
    name: "Summary",
    field: "name",
    align: "left",
    sorting: false,
  },
  {
    name: "Topic",
    field: "process",
    align: "left",
    sorting: false,
  },
  {
    name: "Priority",
    field: "priority",
    align: "left",
    sorting: false,
  },
  {
    name: "Assignee(s)",
    field: "assigneeName",
    align: "left",
    sorting: false,
  },
  {
    name: "Created On",
    field: "activationDate",
    align: "left",
    sorting: false,
  },
  {
    name: "Raised By",
    field: "createdByName",
    align: "left",
    sorting: false,
  },
];