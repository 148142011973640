import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/store";

export interface BlockingState {
  isBlocking: boolean;
  isTemplateBlockingState: boolean;
}

const initialState: BlockingState = {
  isBlocking: false,
  isTemplateBlockingState: false,
};

export const blockSlice = createSlice({
  name: "blocking",
  initialState,
  reducers: {
    blockingState: (state, action: PayloadAction<{ isBlocking: boolean }>) => {
      state.isBlocking = action.payload.isBlocking;
    },
    templateBlockingState: (
      state,
      action: PayloadAction<{ isTemplateBlockingState: boolean }>
    ) => {
      state.isTemplateBlockingState = action.payload.isTemplateBlockingState;
    },
  },
});
export const { blockingState, templateBlockingState } = blockSlice.actions;

export const isBlockingState = (state: RootState) => state.blocking.isBlocking;
export const isTemplateBlockingState = (state: RootState) =>
  state.blocking.isTemplateBlockingState;

export default blockSlice.reducer;
