import {
  boldFont,
  regularFont,
  getRelativeFontSize,
  primaryHeadingColor,
  theme,
  pureWhiteColor,
} from "utils/styles";

const CreateCampaignStyles = {
  headerGridContainer: {
    my: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      direction: "column",
    },
  },
  stepOneHeader: {
    my: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  backArrowBox: {
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
  },
  tooltipText: {
    padding: "10px",
    color: pureWhiteColor,
    fontSize: "14px",
    ...regularFont,
  },
  headerSection: {
    backgroundColor: "#fcf5ff",
    padding: "35px 20px 18px 20px",
  },
  headerButtonStyle: { margin: "5px" },
  headerButtonStack: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
  },
} as const;

export default CreateCampaignStyles;
