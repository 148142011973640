export const rolesTableHeader = [
  {
    name: "Role Name",
    field: "name",
  },
  {
    name: "Permission And Resources",
    field: "resources",
  },
  {
    name: "Action",
    field: "action",
  },
];

export const initialRoleData = {
  name: "",
  resources: [{ name: "", permissions: [], path: "" }],
};

export const staticPredefinedRoles: any[] = [
  {
    name: "Contact",
    path: "contact",
    permissions: ["fetch", "add", "update", "delete", "upload"],
    tooltipContent:
      "Use this for Viewing, Adding, Uploading, Updating, and Deleting Contacts.",
  },

  {
    name: "Social",
    path: "social",
    permissions: ["fetch", "add", "delete"],
    tooltipContent:
      "Use this for Viewing, Adding, and Deleting Social Media Posts",
  },
];

export const campaignerPreDefinedRoleData: any[] = [
  {
    name: "Campaign",
    path: "campaign",
    permissions: ["fetch", "add", "update", "delete"],
  },
  {
    name: "Contact",
    path: "contact",
    permissions: ["fetch", "add", "update", "delete", "upload"],
  },
  {
    name: "Campaigner",
    path: "campaigner",
    permissions: ["fetch", "add", "update", "delete"],
  },
];

export const createRoleFormData = (roleDetails: any) => {
  let roleFormData: any = {
    name: {
      value: roleDetails.name,
      error: "",
    },
    resources: [],
  };

  roleDetails.resources.forEach((item: any) => {
    roleFormData.resources.push({
      name: {
        value: item.name,
        error: "",
      },
      permissions: {
        value: item.permissions,
        error: "",
      },
      path: {
        value: item.path,
        error: "",
      },
    });
  });

  return roleFormData;
};

export const roleFormValidation = (
  roleFormData: any,
  isAddResourceValidation: boolean
) => {
  let isValid = true;
  let errors = { ...roleFormData };

  if (!errors.name.value && !isAddResourceValidation) {
    isValid = false;
    errors.name.error = "Please enter a role name";
  }

  errors.resources.forEach((role: any) => {
    if (!role.name.value) {
      isValid = false;
      role.name.error = "Select a resource type";
    }
    if (role.permissions.value.length == 0) {
      isValid = false;
      role.permissions.error = "Select atleast one permission";
    }
  });

  return { isValid, errors };
};

export const getResourceObj = (predefindedResource?: any) => {
  return {
    name: {
      value: predefindedResource?.name ?? "",
      error: "",
    },
    permissions: {
      value: predefindedResource?.permissions ?? [],
      error: "",
    },
    path: {
      value: predefindedResource?.path ?? "",
      error: "",
    },
  };
};

export const mapFormDataToValues = (roleFormData: any) => {
  let roleData: any = {
    name: roleFormData.name.value,
    resources: [],
  };

  roleFormData.resources.forEach((item: any) => {
    roleData.resources.push({
      name: item.name.value,
      permissions: item.permissions.value,
      path: item.path.value,
    });
  });

  return roleData;
};
