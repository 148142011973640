const CustomAppHeaderStyles = {
  customPaper: {
    // boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
    backgroundColor: "#fcf5ff",
    border: "none",
  },
  customPaperMobile: {
    border: "none",
    // boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
  },
} as const;

export default CustomAppHeaderStyles;
