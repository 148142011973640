import {
  getCallParams,
  getCredentialCallParams,
  makeCall,
} from "utils/service";
import urls from "global/constants/UrlConstants";
import { store } from "utils/store";
enum UserVerificationRequirement {
  REQUIRED = "required",
  PREFERRED = "preferred",
  DISCOURAGED = "discouraged",
}

export const getCredentialOptions = async () => {
  try {
    const callParams = await getCredentialCallParams("POST");
    const credentialGetJson = await makeCall(urls.passkeyLogin, callParams);
    const publicKeyCredentialOptions = {
      publicKey: {
        challenge: base64urlToUint8array(credentialGetJson.publicKey.challenge),
        userVerification: UserVerificationRequirement.REQUIRED,
        rpId: credentialGetJson.publicKey.rpId,
        allowCredentials: [],
      },
    };

    let publicKeyCredential;

    try {
      publicKeyCredential = (await navigator.credentials.get({
        publicKey: publicKeyCredentialOptions.publicKey,
      })) as PublicKeyCredential;

      if (!publicKeyCredential) {
        throw new Error("Failed to obtain publicKeyCredential");
      }
    } catch (error: any) {
      if (
        error.message.includes(
          "The operation either timed out or was not allowed"
        )
      ) {
        return;
      }
      throw error;
    }

    const assertionResponse =
      publicKeyCredential.response as AuthenticatorAssertionResponse;

    const encodedResult = {
      type: publicKeyCredential.type,
      id: publicKeyCredential.id,
      response: {
        authenticatorData: uint8arrayToBase64url(
          new Uint8Array(assertionResponse.authenticatorData)
        ),
        clientDataJSON: uint8arrayToBase64url(
          new Uint8Array(assertionResponse.clientDataJSON)
        ),
        signature: uint8arrayToBase64url(
          new Uint8Array(assertionResponse.signature)
        ),
        userHandle: assertionResponse.userHandle
          ? uint8arrayToBase64url(new Uint8Array(assertionResponse.userHandle))
          : null,
      },
      clientExtensionResults: publicKeyCredential.getClientExtensionResults(),
    };

    try {
      const finishUrl = `${urls.passkeyFinishLogin}/${credentialGetJson.publicKey.challenge}`;
      const callParams = await getCredentialCallParams("POST", encodedResult);
      const finishResponse = await makeCall(finishUrl, callParams);
      return finishResponse;
    } catch (error) {
      throw error;
    }
  } catch (error: any) {
    if (
      error.message.includes(
        "The operation either timed out or was not allowed"
      )
    ) {
      return;
    }
    throw error;
  }
};

export const getCredentialCreationOptions = async (displayName: string) => {
  try {
    const url = `${urls.passkeyRegister}/${displayName}`;
    const callParams = await getCredentialCallParams("POST");
    const response = await makeCall(url, callParams);
    const credentialCreateJson = response;

    credentialCreateJson.excludeCredentials.forEach((credential: any) => {
      credential.id = base64urlToUint8array(credential.id);
    });
    const publicKey = {
      challenge: base64urlToUint8array(credentialCreateJson.challenge),
      rp: {
        id: credentialCreateJson.rp.id,
        name: credentialCreateJson.rp.name,
      },
      user: {
        id: base64urlToUint8array(credentialCreateJson.user.id),
        name: credentialCreateJson.user.name,
        displayName: credentialCreateJson.user.displayName,
      },
      pubKeyCredParams: credentialCreateJson.pubKeyCredParams,
      authenticatorSelection: credentialCreateJson.authenticatorSelection,
      extension: credentialCreateJson.extension,
      excludeCredentials: credentialCreateJson.excludeCredentials,
    };
    return { publicKey };
  } catch (error: any) {
    if (
      error.message.includes(
        "The operation either timed out or was not allowed"
      )
    ) {
      return;
    }
    return new Error(
      "Failed to fetch credential creation options. Please try again later."
    );
  }
};

export const createPasskey = async (options: any) => {
  const response = (await navigator.credentials.create(options)) as any;

  const publicKeyCredential = {
    type: response.type,
    id: response.id,
    rawId: uint8arrayToBase64url2(new Uint8Array(response.rawId)),
    response: {
      attestationObject: uint8arrayToBase64url2(
        new Uint8Array(response.response.attestationObject)
      ),
      clientDataJSON: uint8arrayToBase64url2(
        new Uint8Array(response.response.clientDataJSON)
      ),
      transports: response.response.getTransports
        ? response.response.getTransports()
        : [],
    },
    clientExtensionResults: response.getClientExtensionResults(),
  };

  const registrationnesponse: any = await fetch(
    `${urls.passkeyFinishRegister}`,
    {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(publicKeyCredential),
      headers: { Authorization: "Bearer " + store.getState().auth.accessToken },
    }
  );

  return await registrationnesponse.json();
};

function uint8arrayToBase64url2(array: Uint8Array): string {
  return btoa(String.fromCharCode.apply(null, array as any))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

const uint8arrayToBase64url = (buffer: ArrayBuffer) => {
  const base64 = btoa(
    String.fromCharCode(...Array.from(new Uint8Array(buffer)))
  );
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

const base64urlToUint8array = (base64url: string) => {
  const base64 = base64url.replace(/-/g, "+").replace(/_/g, "/");
  const padLength = 4 - (base64.length % 4);
  const padded = base64 + "====".slice(0, padLength);
  const raw = atob(padded);
  const array = Array.from(raw);
  return new Uint8Array(array.map((char) => char.charCodeAt(0)));
};

export const fetchPasskeys = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.getAllPasskeys, callParams);
    return response;
  } catch (error) {
    return error;
  }
};

export const deletePasskey = async (passkeyData: any) => {
  try {
    const callParams = await getCallParams("DELETE", passkeyData);
    const response = await makeCall(urls.deletePasskey, callParams);
    return response;
  } catch (error) {
    return error;
  }
};

export const editPasskeyName = async (passkeyData: any) => {
  try {
    const callParams = await getCallParams("PATCH", passkeyData);
    const response = await makeCall(urls.updatePasskey, callParams);
    return response;
  } catch (error) {
    return error;
  }
};
