import { useMediaQuery, Box, Grid } from "@mui/material";
import { theme } from "utils/styles";
import landingPageStyles from "screens/LandingPage/LandingPage.styles";
import { useAppSelector } from "utils/hooks";
import { selectAuthenticated } from "redux/authSlice";
import history from "utils/history";
import urls from "global/constants/UrlConstants";
import strings from "global/constants/StringConstants";
import Login from "screens/LandingPage/components/Login";
import Register from "screens/LandingPage/components/Register";
import ForgotPassword from "screens/LandingPage/components/ForgotPassword";
import logo from "assets/icons/mailzzy-logo.png";
import loginvector from "assets/images/login_vector.png";
import ResetPassword from "screens/LandingPage/components/ResetPassword";
import ActivateUser from "screens/LandingPage/components/ActivateUser";
import OTP from "screens/LandingPage/components/OTP";
import SetupMFA from "screens/LandingPage/components/SetupMFA";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const LandingPage = () => {
  const classes = landingPageStyles;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isAuthenticated = useAppSelector(selectAuthenticated);

  const staticImageComponent = () => {
    return (
      <Box
        sx={{
          height: "100vh",
          backgroundColor: "#EBEDFF",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <img src={loginvector} height="auto" width="100%" />
      </Box>
    );
  };

  const getComponentBasedOnURL = () => {
    const location = window.location.pathname?.split("/")[1].toLowerCase();
    switch (location) {
      case strings.LOGIN: {
        return <Login executeRecaptcha={executeRecaptcha} />;
      }
      case strings.REGISTER: {
        return <Register executeRecaptcha={executeRecaptcha} />;
      }
      case strings.FORGOT_PASSWORD: {
        return <ForgotPassword executeRecaptcha={executeRecaptcha} />;
      }
      case strings.RESET_PASSWORD: {
        return <ResetPassword executeRecaptcha={executeRecaptcha} />;
      }
      case strings.ACTIVATE_USER: {
        return <ActivateUser />;
      }
      case strings.VERIFY_MFA_CODE: {
        return <OTP />;
      }
      case strings.SETUP_MFA: {
        return <SetupMFA />;
      }
      default: {
        return <Login executeRecaptcha={executeRecaptcha} />;
      }
    }
  };

  const getLandingPage = () => {
    return (
      <Box>
        <Grid container justifyContent="center">
          {isDesktop && (
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              {staticImageComponent()}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            position="relative"
            style={{
              background: "#F9F9F9",
              height: "100vh",
              overflowY: "auto",
            }}
          >
            <Box component="img" src={logo} sx={classes.logo} />
            <Box>{getComponentBasedOnURL()}</Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  if (isAuthenticated) {
    history.push(urls.dashboardViewPath);
    return null;
  }
  return getLandingPage();
};

export default LandingPage;
