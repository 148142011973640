import { useState } from "react";
import _ from "lodash";
import { Box, Chip, Grid, Typography } from "@mui/material";
import {
  CustomButton,
  CustomContactNumberInput,
  CustomDialog,
  CustomInput,
} from "global/components";
import userUpdateImg from "assets/images/uploadUser.svg";
import { ViewContactStyles } from "screens/Contact/ViewContact.styles";
import strings from "global/constants/StringConstants";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import { addNewContact } from "screens/Contact/ViewContactService";
import notifiers from "global/constants/NotificationConstants";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useAppSelector } from "utils/hooks";
import { selectIsFirstNameMandatory } from "redux/persistSlice";
interface AddRecipientModalProps {
  setIsLoading: Function;
  handleAddRecipientDialogClose: Function;
  addRecipientModal: boolean;
  groupId: string | null;
  getContacts: Function;
  firstNameMandatory?: boolean;
  getGroupInfo?: Function;
}

const initialData = {
  firstName: {
    value: "",
    error: "",
  },
  lastName: {
    value: "",
    error: "",
  },
  emailId: {
    value: "",
    error: "",
  },
  contactNo: {
    value: "",
    error: "",
  },
  tagsValue: {
    value: "",
    error: "",
  },
};

const AddRecipientModal = (props: AddRecipientModalProps) => {
  const classes = ViewContactStyles;
  const isFirstNameMandatory = useAppSelector(selectIsFirstNameMandatory);
  const [addRecipientData, setAddRecipientData] = useState(initialData);
  const [tags, setTags] = useState<{ id: string; name: string }[]>([]);
  const [isVisitedContactField, setVisitedContactFelid] =
    useState<boolean>(false);

  const validateTags = () => {
    if (!isTruthy(addRecipientData.tagsValue?.value)) {
      setAddRecipientData((prevData) => ({
        ...prevData,
        tagsValue: { ...prevData.tagsValue, error: "Tag cant be empty" },
      }));
      return false;
    }
    const checkExistingValue = tags?.some(
      (item: any) =>
        item?.name === (addRecipientData.tagsValue?.value).toLowerCase().trim()
    );
    if (checkExistingValue) {
      setAddRecipientData((prevData) => ({
        ...prevData,
        tagsValue: {
          ...prevData.tagsValue,
          error: "You cannot add duplicate tags",
        },
      }));
      return false;
    }
    return true;
  };

  const validateForm = () => {
    let isValid = true;
    let tempContactData = _.cloneDeep(addRecipientData);
    if (isFirstNameMandatory && !tempContactData.firstName.value) {
      tempContactData.firstName.error = "Please enter First Name";
      isValid = false;
    }
    if (!tempContactData.emailId.value) {
      tempContactData.emailId.error = "Please enter Email Address";
      isValid = false;
    }
    if (
      tempContactData.emailId.value &&
      !strings.regex.test(tempContactData.emailId.value)
    ) {
      tempContactData.emailId.error = "Please enter a valid Email Address";
      isValid = false;
    }
    setAddRecipientData(tempContactData);
    return isValid;
  };

  const addTagsHandler = () => {
    if (validateTags()) {
      const addedTags: any[] = [];

      addedTags.push(...tags, {
        id: "NEW",
        name: addRecipientData.tagsValue?.value?.toLowerCase().trim(),
      });
      const filterValue = _.uniqWith(addedTags, _.isEqual);
      setTags(filterValue);
      setAddRecipientData((prevData) => ({
        ...prevData,
        tagsValue: { value: "", error: "" },
      }));
    }
  };

  const removeTagsHandler = (index: number) => {
    setTags(tags.filter((v: any, i: number) => i !== index));
  };

  const onChangeHandler = (event: React.ChangeEvent<any>) => {
    setAddRecipientData({
      ...addRecipientData,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const closeDialog = () => {
    props.handleAddRecipientDialogClose();
    setAddRecipientData(initialData);
    setTags([]);
    setVisitedContactFelid(false);
  };
  const isPhoneValid = (phone: string) => {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error: any) {
      return false;
    }
  };

  const handleAddRecipient = async () => {
    if (validateForm()) {
      try {
        props.setIsLoading(true);
        const { firstName, lastName, emailId, contactNo } = addRecipientData;
        const contactData = {
          firstName: firstName.value,
          lastName: lastName.value,
          emailId: emailId.value,
          contactNo:
            isPhoneValid(contactNo.value) && isVisitedContactField
              ? contactNo.value
              : "",
          tagName: tags.map((tag) => tag.name).join(","),
        };
        const res = await addNewContact(props.groupId, contactData);
        openSuccessNotification(res.message);
      } catch (error: any) {
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
      } finally {
        props.setIsLoading(false);
        closeDialog();
        await props.getContacts();
        await props.getGroupInfo?.();
      }
    }
  };

  const addRecipientModalHeader = () => {
    return (
      <Box display="flex">
        <img src={userUpdateImg} alt="Add Recipient" />
      </Box>
    );
  };

  const addRecipientModalBody = () => {
    return (
      <Box px={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <CustomInput
              required={isFirstNameMandatory}
              label="First Name"
              id="recipient_modal_first_name_field"
              type="text"
              name="firstName"
              placeHolder="Enter First Name"
              onChange={onChangeHandler}
              propsToInputElement={{ maxlength: 25 }}
              value={addRecipientData.firstName.value}
              error={addRecipientData.firstName.error}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <CustomInput
              label="Last Name"
              id="recipient_modal_last_name_field"
              type="text"
              name="lastName"
              placeHolder="Enter Last Name"
              onChange={onChangeHandler}
              propsToInputElement={{ maxlength: 25 }}
              value={addRecipientData.lastName.value}
              error={addRecipientData.lastName.error}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <CustomInput
              required
              label="Email"
              id="recipient_modal_email_field"
              type="email"
              name="emailId"
              placeHolder="Enter Email Address"
              propsToInputElement={{ maxlength: 50 }}
              onChange={onChangeHandler}
              value={addRecipientData.emailId.value}
              error={addRecipientData.emailId.error}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <CustomContactNumberInput
              label="Contact Number"
              id="contact_no_modal_field"
              value={addRecipientData.contactNo.value}
              placeHolder="Enter Your Contact No"
              onChange={(phone: any) => {
                setAddRecipientData({
                  ...addRecipientData,
                  contactNo: {
                    value: phone,
                    error:
                      isTruthy(addRecipientData.contactNo.value) &&
                      !isPhoneValid(phone)
                        ? "Please enter a valid contact No"
                        : "",
                  },
                });
              }}
              onClick={() => setVisitedContactFelid(true)}
              error={addRecipientData.contactNo.error}
              onBlur={() =>
                setVisitedContactFelid(
                  isPhoneValid(addRecipientData.contactNo.value)
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container gap={2} mt={2}>
          <Grid item xs={12} sm={6} md={8} lg={8} xl={4}>
            <CustomInput
              label="Tags"
              id="recipient_modal_tags_field"
              type="text"
              name="tagsValue"
              placeHolder="Enter Tag Name"
              propsToInputElement={{
                maxLength: strings.TAGS_LIMIT,
              }}
              error={addRecipientData.tagsValue.error}
              onChange={onChangeHandler}
              value={addRecipientData.tagsValue.value}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} lg={4} xl={2}>
            <CustomButton
              id="recipient_modal_add_tag_button"
              customClasses={classes.addTagStyle}
              onClick={addTagsHandler}
              label="Add Tag"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xl={6}>
            {tags.map((tag: any, index: any) => (
              <Chip
                key={index}
                label={tag.name}
                sx={classes.addTagChip}
                variant="filled"
                onDelete={() => removeTagsHandler(index)}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const addRecipientModalTitle = () => {
    return (
      <Box>
        <Typography sx={classes.boldFonts}>Add Recipient</Typography>
      </Box>
    );
  };

  const addRecipientModalFooter = () => (
    <Grid container sx={classes.centerItemFlex}>
      <Box sx={classes.dialogFooter}>
        <CustomButton
          id="recipient_modal_cancel_button"
          label="Cancel"
          onClick={() => closeDialog()}
          customClasses={classes.cancelButtonStyle}
        />
        <CustomButton
          id="recipient_modal_submit_button"
          customClasses={classes.addBtnStyle}
          onClick={handleAddRecipient}
          label="Add Recipient"
        />
      </Box>
    </Grid>
  );

  return (
    <CustomDialog
      dialogHeaderContent={addRecipientModalHeader()}
      isDialogOpen={props.addRecipientModal}
      dialogBodyContent={addRecipientModalBody()}
      dialogTitleContent={addRecipientModalTitle()}
      closable={true}
      closeButtonVisibility={true}
      handleDialogClose={closeDialog}
      width={"900px"}
      dialogFooterContent={addRecipientModalFooter()}
    />
  );
};

export default AddRecipientModal;
