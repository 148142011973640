import {
  boldFont,
  drawerWidth,
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
  textLightColor,
  purplePrimaryColor,
  pinkDarkColor,
  activeMenuBackgroundColor,
  pureWhiteColor,
} from "utils/styles";

const appDrawerStyles = {
  drawer: {
    display: "flex",
    height: "100vh",
    width: 70,
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 40px 0px #0000000D",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    padding: 0,
    margin: 0,
    zIndex: 0,
  },
  drawerContainerBox: {
    position: "relative",
    transition: "width 0.4s ease-in-out",
  },
  drawerIconBox: {
    background: pureWhiteColor,
    width: "26px",
    height: "51px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "absolute",
    top: "26px",
  },
  openDrawerIconBox: {
    borderRadius: "100px 0 0 100px",
    right: 0,
    boxShadow: "-7px 1px 14px 0 rgba(0, 0, 0, 0.08)",
  },
  closedDrawerIconBox: { borderRadius: "0 100px 100px 0", right: -15 },
  drawerHide: {
    display: "flex",
    height: "100vh",
    width: drawerWidth,
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 40px 0px #0000000D",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    padding: 0,
    margin: 0,
    zIndex: 0,
  },
  drawerWidth: {
    width: drawerWidth,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    overflowY: "hidden",
    overflowX: "hidden",

    [theme.breakpoints.down("xl")]: {
      width: drawerWidth,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  navLink: {
    textDecoration: "none",
  },
  menuItems: {
    margin: "4px",
  },
  closedDrawerListItemStyles: {
    paddingLeft: "5px",
    margin: "4px 0",
  },
  openDrawerListItemIcon: {
    minWidth: "35px",
  },
  closedDrawerListItemArrowIcon: { maxHeight: "20px" },
  closedDrawerListItemIcon: {
    minWidth: "22px",
  },
  subMenuItems: {
    borderLeft: "4px solid",
  },
  closedDrawerSubMenuItem: { paddingLeft: "5px", margin: "4px 0" },
  menuOptionsHeight: {
    height: "75vh",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
    },
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      border: "3px solid #ffffff",
    },
  },
  menuOptionListItem: {
    ...mediumFont,
    cursor: "pointer",
    borderLeft: "4px solid transparent",
  },
  selectedMenuOptionListItem: {
    color: purplePrimaryColor,
    display: "flex",
    borderLeft: "4px solid",
    height: "30px",
  },
  menuOption: {
    display: "flex",
    ...boldFont,
    textDecoration: "none",
    color: textLightColor,
    flexDirection: "column",
    cursor: "pointer",
    margin: "0 10px",
  },
  selectedMenuOption: {
    display: "flex",
    ...mediumFont,
    textDecoration: "none",
    color: purplePrimaryColor,
    backgroundColor: activeMenuBackgroundColor,
    flexDirection: "column",
    cursor: "pointer",
    margin: "0 10px",
  },
  selectedSubMenuOption: {
    display: "flex",
    ...mediumFont,
    textDecoration: "none",
    color: purplePrimaryColor,
    flexDirection: "column",
    marginBottom: "15px",
  },
  navBarLabel: {
    ...mediumFont,
  },
  listItemIconBox: {
    display: "flex",
    alignItems: "flex-start",
  },
  listItemTextBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoBox: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "32px",
  },
  workText: {
    ...boldFont,
    display: "flex",
    fontSize: getRelativeFontSize(21),
    [theme.breakpoints.down("xl")]: {
      fontSize: getRelativeFontSize(17),
    },
  },
  sageText: {
    ...boldFont,
    color: "#828282",
    fontSize: getRelativeFontSize(21),
    [theme.breakpoints.down("xl")]: {
      fontSize: getRelativeFontSize(17),
    },
  },
  supportTicketBox: {
    borderRadius: "15px",
    mx: 5,
    mt: 1,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {},
    marginBottom: "10%",
  },
  btnBox: {
    ...regularFont,
    textTransform: "none",
  },
  imgBox: {
    background: "#22BAB6",
    width: "32px",
    height: "32px",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeIcon: {
    backgroundColor: pinkDarkColor,
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    marginRight: "8px",
  },
  getCampaignWrapper: {
    height: "25vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "16px",
    "& img": {
      width: "125px",
      height: "auto",
    },
  },
  getCampaignIcon: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  getCampaignImg: {
    width: "137px",
    height: "auto",
  },
  getCampaignPara: {
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#666666",
    ...regularFont,
    margin: "10px 0",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logOutWrapper: {
    height: "10vh",
    display: "flex",
    padding: "0 16px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: "15px",
    [theme.breakpoints.down("xl")]: {},
  },
  logOutWrapper1: {
    height: "10vh",
    display: "flex",
    padding: "0 16px",
    alignItems: "center",
    flexWrap: "wrap",
    backgroundColor: "white",
    borderRadius: "15px",
    [theme.breakpoints.down("xl")]: {},
  },
  logOutLeft: {
    display: "flex",
    alignItems: "center",
  },
  logoutTooltipText: {
    padding: "10px",
    color: "white",
    fontSize: "14px",
    ...regularFont,
  },
  logoutImageStyle: { width: "25px", height: "auto", cursor: "pointer" },
  squareBox: {
    width: "46px",
    height: "46px",
    borderRadius: "15px",
    background: "#DBDBDB",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  squareBox1: {
    width: "35px",
    height: "46px",
    borderRadius: "15px",
    background: "#DBDBDB",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarStyle: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
  },
  avatarFirstName: {
    color: "#212121",
    ...boldFont,
    fontSize: getRelativeFontSize(5),
    marginLeft: "8px",
    wordBreak: "break-all",
  },
} as const;

export default appDrawerStyles;
