import {
  boldFont,
  getRelativeFontSize,
  mediumFont,
  pinkDarkColor,
  primaryHeadingColor,
  regularFont,
  theme,
} from "utils/styles";

const PasskeysStyles = {
  headingContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: { flexDirection: "column" },
  },
  pageTitle: {
    fontSize: getRelativeFontSize(6),
    ...boldFont,
    display: "flex",
    alignItems: "center",
    color: primaryHeadingColor,
  },
  updateDialogTitle: {
    color: "rgba(0,0,0,.85)",
    ...boldFont,
    fontSize: getRelativeFontSize(14),
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
  },
  updateDialogNameBox: {
    background: "#F8F8F8",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
  },
  domainsListContainer: {
    margin: "25px 30px",
  },
  domainNameTextStyles: {
    ...boldFont,
    cursor: "pointer",
    width: "fit-content",
    color: pinkDarkColor,
    fontSize: getRelativeFontSize(2),
  },
  domainViewButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addButtonStyles: {
    width: "150px",
  },
  updateDialogTitleWrapper: {
    display: "flex",
    alignItemCenter: "center",
    gap: "20px",
    flexDirection: "column",
  },
  spanStyle: {
    ...boldFont,
    marginLeft: "5px",
  },
  dialogFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    width: "100%",
    "& button": {
      width: "100px",
    },
  },
  cancelButtonStyle: {
    color: "#212121 !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E7E7E7",
    // borderRadius: "10px",
    "&:hover": {
      background: "none",
    },
  },
  textBold: {
    ...boldFont,
    wordBreak: "break-all",
    marginLeft: "5px",
  },
  fontText: {
    ...regularFont,
  },
} as const;

export default PasskeysStyles;
