import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "global/components/PrivateRoute/PrivateRoute";
import urls from "global/constants/UrlConstants";
import Dashboard from "screens/Dashboard/Dashboard";
import CampaignGroups from "screens/CampaignGroups/CampaignGroups";
import AppDrawer from "screens/Shared/AppDrawer/AppDrawer";
import AppHeader from "screens/Shared/AppHeader/AppHeader";
import layoutStyles from "screens/Shared/Layout/Layout.styles";
import { useMediaQuery, useTheme } from "@mui/material";
import CampaignHistory from "screens/CampaignHistory/CampaignHistory";
import Templates from "screens/Templates/Templates";
import RetargettingCampaignRequest from "screens/CampaignsNew/Campaigns/RetargettingCampaignRequest";
import Campaigns from "screens/CampaignsNew/Campaigns/Campaigns";
import Integration from "screens/Integration/Integration";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { addIsManager, selectAuthenticated } from "redux/authSlice";
import PageNotFound from "screens/PageNotFound/PageNotFound";
import strings from "global/constants/StringConstants";
import Social from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/Social";
import CreateTicket from "screens/Shared/Layout/CreateTicket";
import Stats from "screens/Stats/Stats";
import {
  getManager,
  getFirstNameMandatory,
  fetchCampaignRetentionDuration,
} from "screens/LandingPage/landingPageService";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import ResendCampaign from "screens/Stats/components/ResendCampaign";
import TemplateEditor from "screens/Templates/components/TemplateEditor";
import Settings from "screens/Settings/Settings";
import { changeSteps } from "redux/tourSlice";
import { dashboardTourSteps } from "screens/Dashboard/DashboardData";
import { campaignTourSteps } from "screens/CampaignsNew/Campaigns/CampaignsData";
import { campaignGroupsTourSteps } from "screens/CampaignGroups/CampaignGroupData";
import UserTour from "utils/UserTour/UserTour";
import { CreateCampaignNew } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignNew";
import CreateSequentialCampaigns from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/CreateSequentialCampaigns";
import CreateCampaigns from "screens/CampaignsNew/CreateCampaigns/CreateCampaigns";
import CreateWhatsAppTemplate from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/CreateWhatsAppTemplate";
import WhatsAppTemplates from "screens/WhatsAppCampaign/WhatsAppTemplates/WhatsAppTemplates";
import CreateWhatsAppCampaigns from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/CreateWhatsAppCampaigns";
import SocialCampaigns from "screens/CampaignsNew/SocialCampaigns/SocialCampaigns";
import {
  campaignRetentionDuration,
  changeIsFirstNameMandatory,
} from "redux/persistSlice";
import { isTruthy, openErrorNotification } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import GroupContacts from "screens/GroupContacts/GroupContacts";
import ViewContact from "screens/Contact/ViewContact";
import { hasAccessTo } from "utils/AuthorizationManager";
import FailedStats from "screens/FailedStats/FailedStats";
import AllTickets from "screens/Tickets/AllTickets";
import ViewTicket from "screens/Tickets/ViewTicket/ViewTicket";

const Layout = () => {
  const classes = layoutStyles;
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const hasAccess = hasAccessTo(strings.campaign, strings.fetchPermission);

  useEffect(() => {
    tourInitializer();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getIsManager();
      getCampaignRetentionDuration();
      hasAccess && getIsFirstNameMandatory();
    }
  }, [isAuthenticated]);

  const getIsManager = async () => {
    try {
      const isManager: boolean = await getManager();
      dispatch(addIsManager(isManager));
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getIsFirstNameMandatory = async () => {
    try {
      const isFirstNameMandatory: boolean = await getFirstNameMandatory();
      dispatch(changeIsFirstNameMandatory(isFirstNameMandatory));
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getCampaignRetentionDuration = async () => {
    try {
      const campaignRetention = await fetchCampaignRetentionDuration();
      dispatch(campaignRetentionDuration(campaignRetention));
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const tourInitializer = () => {
    dispatch(
      changeSteps([
        ...dashboardTourSteps,
        ...campaignTourSteps,
        ...campaignGroupsTourSteps,
      ])
    );
  };

  const getContent = () => {
    return (
      <Box sx={classes.content}>
        <Switch>
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.dashboardViewPath}
            component={Dashboard}
            componentName={strings.DASHBOARD}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.campaignHistoryViewPath}
            component={CampaignHistory}
            componentName={strings.CAMPAIGNHISTORY}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.failedStatsViewPath}
            component={FailedStats}
            componentName={strings.FAILEDSTATS}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.campaignsListViewPath}
            component={Campaigns}
            componentName={strings.CAMPAIGNSLIST}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.createCampaignsViewPath}
            component={CreateCampaigns}
            componentName={strings.CREATECAMPAIGNS}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.socialCampaignsViewPath}
            component={SocialCampaigns}
            componentName={strings.SOCIALCAMPAIGNS}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={`${urls.campaignDetailsViewPath}`}
            component={Stats}
            componentName={strings.CAMPAIGNDETAILS}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.viewContactViewPath}
            component={ViewContact}
            componentName={strings.CONTACTS}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.settingsViewPath}
            componentName={strings.SETTINGS}
            component={Settings}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.socialViewPath}
            component={Social}
            componentName={strings.SOCIAL}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.integrationViewPath}
            component={Integration}
            componentName={strings.INTEGRATION}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.templateViewPath}
            component={Templates}
            componentName={strings.TEMPLATES}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={[urls.templateAddPath, urls.templateEditPath]}
            component={TemplateEditor}
            componentName={strings.TEMPLATESEDITOR}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.retargettingViewPath}
            component={RetargettingCampaignRequest}
            componentName={strings.RETARGEETTING}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={`${urls.campaignGroupsViewPath}/:groupId`}
            component={GroupContacts}
            componentName={strings.GROUPS}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.campaignGroupsViewPath}
            component={CampaignGroups}
            componentName={strings.GROUPS}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.createCampaignViewPath}
            component={CreateCampaignNew}
            componentName={strings.CREATECAMPAIGN}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.ticketsViewPath}
            component={AllTickets}
            componentName={strings.TICKETS}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={`${urls.ticketsViewPath}/:id`}
            component={ViewTicket}
            componentName={strings.TICKETS}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.resendCampaign}
            component={ResendCampaign}
            componentName={strings.RESENDCAMPAIGN}
          />
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={urls.sequencesViewPath}
            component={CreateSequentialCampaigns}
            componentName={strings.SEQUENCES}
          />
          {!urls.PROD && (
            <PrivateRoute
              exact
              isLoggedIn={isAuthenticated}
              path={urls.createWhatsAppCampaignViewPath}
              component={CreateWhatsAppCampaigns}
              componentName={strings.CREATEWHATSAPPCAMPAIGN}
            />
          )}
          {!urls.PROD && (
            <PrivateRoute
              exact
              isLoggedIn={isAuthenticated}
              path={urls.createWhatsAppTemplateViewPath}
              component={CreateWhatsAppTemplate}
              componentName={strings.CREATEWHATSAPPTEMPLATE}
            />
          )}
          {!urls.PROD && (
            <PrivateRoute
              exact
              isLoggedIn={isAuthenticated}
              path={urls.whatsAppTemplatesViewPath}
              component={WhatsAppTemplates}
              componentName={strings.WHATSAPPTEMPLATES}
            />
          )}
          <PrivateRoute
            exact
            isLoggedIn={isAuthenticated}
            path={""}
            component={PageNotFound}
            componentName={strings.PAGENOTFOUND}
          />
        </Switch>
      </Box>
    );
  };

  const getLayout = () => {
    return isLoading ? (
      <CustomLoader isLoading />
    ) : (
      <>
        <UserTour />
        <Box>{!isDesktop && <AppHeader />}</Box>
        <Box sx={classes.root}>
          {isDesktop && <AppDrawer />}
          {getContent()}
          {strings.SUPPORT_TICKET && <CreateTicket />}
        </Box>
      </>
    );
  };

  return getLayout();
};

export default Layout;
