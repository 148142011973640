import { boldFont, getRelativeFontSize, mediumFont, theme } from "utils/styles";

const customSimpleTableStyles = {
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 5px",
    borderTopRadius: "15px",
    borderBottomRadius: "15px",
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      overflow: "auto",
    },
  },
  tableHeaderRow: { borderRadius: "10px" },
  tableHeaderCell: {
    ...boldFont,
    padding: "12px",
    fontSize: getRelativeFontSize(),
    color: "#FFFFFF !important",
    backgroundColor: "#1a0224",
    borderTop: "1px solid #F0F0F0",
    borderBottom: "1px solid #F0F0F0",
  },
  tableBody: {
    maxHeight: "500px",
    overflow: "auto",
  },
  tableRow: {
    margin: "10px 0",
    backgroundColor: "#FFFFFF",
  },
  tableFooterRow: {
    width: "100%",
    backgroundColor: "#E7E7E7",
  },
  noDataTableCell: {
    border: "1px solid #F0F0F0",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
  },
  mediumFonts: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
  },
  tableRowCells: {
    padding: "12px",
    borderTop: "1px solid #F0F0F0",
    borderBottom: "1px solid #F0F0F0",
  },
} as const;

export default customSimpleTableStyles;
