import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { NewCustomButton, CustomInput } from "global/components";
import strings from "global/constants/StringConstants";
import campaignDetailsSectionsStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/CampaignDetailsSections/CampaignDetailsSections.style";
import { useState } from "react";
import { isTruthy } from "helpers/methods";

interface CustomProps {
  setCampaignData: Function;
  campaignData: any;
  emailUsers: string[];
}

const FromSection = (props: CustomProps) => {
  const classes = campaignDetailsSectionsStyles;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState({
    value: props.campaignData?.displayName?.value,
    error: "",
  });
  const [fromEmail, setFromEmail] = useState({
    value: props.campaignData?.fromId?.value,
    error: "",
  });

  const onNameChangeHandler = (event: any) => {
    setDisplayName({
      ...displayName,
      value: event.target.value,
    });
  };

  const onEmailChangeHandler = (event: any, newValue: any) => {
    setFromEmail({
      value: newValue,
      error: existValueValidation(newValue)
        ? ""
        : "Please select the value from dropdown",
    });
  };

  const existValueValidation = (value: any) => props.emailUsers.includes(value);

  const validateFromSection = () => {
    const formId = fromEmail.value;
    const name = displayName.value;
    let isValid = true;
    if (!isTruthy(name)) {
      setDisplayName({
        ...displayName,
        error: "Please enter the display name",
      });
      isValid = false;
    }
    return isValid;
  };

  const onSaveHandler = () => {
    if (validateFromSection()) {
      props.setCampaignData({
        ...props.campaignData,
        displayName: {
          value: displayName.value,
          error: "",
        },
        fromId: {
          value: fromEmail.value,
          error: "",
        },
      });
      setIsEditMode(false);
    }
  };

  const onCancelHandler = () => {
    setDisplayName({
      value: props.campaignData?.displayName?.value,
      error: "",
    });
    setFromEmail({ value: props.campaignData?.fromId?.value, error: "" });
    setIsEditMode(false);
  };

  const getSaveButton = () => {
    return (
      <NewCustomButton
        id="from_save_button"
        label="Save"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onSaveHandler();
        }}
      />
    );
  };

  const getCancelButton = () => {
    return (
      <NewCustomButton
        id="from_cancel_button"
        label="Cancel"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onCancelHandler();
        }}
      />
    );
  };

  const editModeView = () => {
    return (
      <>
        <Box mb={1} sx={classes.viewModeBox}>
          <Box>
            <Typography sx={classes.headerLabel}>From</Typography>
            <Typography sx={classes.headerSubDescription}>
              Who is sending this email?
            </Typography>
          </Box>
          <Box>
            <NewCustomButton
              id="from_edit_button"
              label="Edit From"
              customClasses={classes.buttonStyles}
              onClick={() => setIsEditMode(true)}
            />
          </Box>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={5} xl={5} lg={5}>
            <Box>
              <Typography sx={classes.labelText}>Name</Typography>
            </Box>
            <CustomInput
              id="step2_display_name_field"
              type="type"
              name="displayName"
              placeHolder="Enter your display name"
              propsToInputElement={{ maxLength: strings.DISPLAY_NAME_SIZE }}
              value={displayName.value}
              onChange={onNameChangeHandler}
              error={displayName.error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>
                      {displayName.value?.length} / {strings.DISPLAY_NAME_SIZE}
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} xl={5} lg={5}>
            <Box>
              <Typography sx={classes.labelText}>Email Address</Typography>
            </Box>
            <Autocomplete
              sx={classes.selectMenu}
              id="step2_from_dropdown"
              options={props.emailUsers}
              value={fromEmail.value}
              autoHighlight={true}
              autoSelect={true}
              onChange={(e, newVal) => onEmailChangeHandler(e, newVal)}
              renderInput={(params) => (
                <TextField
                  sx={classes.autocompleteStyle}
                  {...params}
                  name="fromId"
                  error={isTruthy(fromEmail.error) || !existValueValidation(fromEmail.value)}
                />
              )}
            />
            {isTruthy(fromEmail.error) && (
              <FormHelperText error sx={classes.errorStyle}>
                {fromEmail.error}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Typography sx={classes.description}>
          Use something subscribers will instantly recognize, like your company
          name.
        </Typography>
        <Stack flexDirection="row" gap={1} mt={1}>
          {getSaveButton()}
          {getCancelButton()}
        </Stack>
      </>
    );
  };

  const viewModeView = () => {
    return (
      <Box mb={1} sx={classes.viewModeBox}>
        <Box>
          <Typography sx={classes.headerLabel}>From</Typography>
          <Typography sx={classes.headerSubDescription}>
            {props.campaignData.displayName.value} &#x2022;{" "}
            <Typography sx={{ wordBreak: "break-word" }} component={"span"}>
              {props.campaignData.fromId.value}
            </Typography>
          </Typography>
        </Box>

        <Box>
          <NewCustomButton
            id="from_edit_button"
            label="Edit From"
            onClick={() => setIsEditMode(true)}
            customClasses={classes.buttonStyles}
          />
        </Box>
      </Box>
    );
  };

  return <Box>{isEditMode ? editModeView() : viewModeView()}</Box>;
};

export default FromSection;