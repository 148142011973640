import {
  boldFont,
  darkPurpledColor,
  getRelativeFontSize,
  regularFont,
} from "utils/styles";

const campaignDetailsSectionsStyles = {
  headerLabel: {
    ...boldFont,
    fontSize: getRelativeFontSize(5),
  },
  headerSubDescription: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
  },
  labelText: {
    fontSize: "16px",
    ...boldFont,
    color: "rgba(0, 0, 0, 0.6)",
  },
  description: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#666",
  },
  selectMenu: {
    width: "100% !important",
    height: "47px",
    backgroundColor: "none",
    paddingBottom: "5px",
  },
  autocompleteStyle: {
    "& .MuiOutlinedInput-root": {
      height: "47px",
      borderRadius: "12px",
      "&.Mui-focused fieldset": {
        borderColor: darkPurpledColor,
      },
      "& .MuiAutocomplete-input": {
        padding: "0px",
      },
    },
  },
  errorStyle: {
    paddingLeft: "15px",
  },
  viewModeBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonStyles: {
    width: "130px",
  },
  permissionMessage: {
    color: "#ff4244",
    ...regularFont,
  },
} as const;

export default campaignDetailsSectionsStyles;
