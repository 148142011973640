import urls from "global/constants/UrlConstants";
import { getCallParams, getFileCallParams, makeCall } from "utils/service";

export const getProcessDefinitions = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.processDefinitions, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const addNewSupportTicket = async (ticketDetails: any) => {
  try {
    const callParams = await getCallParams("POST", ticketDetails);
    const response: any = await makeCall(urls.addSuportTicket, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const uploadSupportTicketAttachment = async (
  formData: any,
  ticketId: string
) => {
  try {
    const url = `${urls.UPLOAD_TICKET_FILE}/${ticketId}`;
    const callParams = await getFileCallParams(formData);
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const doDeleteTicketAttachment = async (file: any) => {
  try {
    const callParams = await getCallParams("POST", file);
    const response: any = await makeCall(urls.DELETE_DOCUMENT, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
