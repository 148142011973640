export const campaignerGroupHeader = [
  {
    name: "Name",
    field: "name",
    sorting: true,
  },
  {
    name: "Group Type",
    field: "groupType",
    sorting: true,
  },
  {
    name: "Owner",
    field: "owner",
    sorting: true,
  },
  {
    name: "Members",
    field: "recipientCount",
    sorting: true,
  },
];

export const tagsHeader = [
  {
    name: "Tags Name",
    field: "name",
    sorting: true,
  },
  {
    name: "Owner",
    field: "owner",
    sorting: true,
  },
  {
    name: "Members",
    field: "recipientCount",
    sorting: true,
  },
];

export const previewTagsHeader = [
  {
    name: "Tags Name",
    field: "name",
  },
  {
    name: "Owner",
    field: "owner",
  },
  {
    name: "Members",
    field: "recipientCount",
  },
  {
    name: "Action",
    field: "remove",
  },
];

export const campaignGroupOptionAdmin = [
  {
    id: 0,
    label: "All Groups",
    value: "all",
  },

  {
    id: 2,
    label: "My Groups",
    value: "my",
  },
  {
    id: 3,
    label: "Team's Group",
    value: "team",
  },
];

export const campaignGroupOptionUser = [
  {
    id: 0,
    label: "All Groups",
    value: "all",
  },
  {
    id: 2,
    label: "My Groups",
    value: "my",
  },
];
