import { useState, useEffect } from "react";
import history from "utils/history";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import StepOne from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/StepOne/StepOne";
import StepTwo from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/StepTwo/StepTwo";
import {
  getCampaignPayload,
  whatsAppCampaignInitialState,
} from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/CreateWhatsAppCampaignHelpers";
import {
  checkWhatsAppIntegration,
  sendWhatsAppCampaign,
} from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/CreateWhatsAppCampaignServices";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import urls from "global/constants/UrlConstants";
import notifiers from "global/constants/NotificationConstants";
import WhatsAppIntegrationModel from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/components/WhatsAppIntegrationModel/WhatsAppIntegrationModel";
import strings from "global/constants/StringConstants";

const CreateWhatsAppCampaigns = () => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignData, setCampaignData] = useState(
    whatsAppCampaignInitialState()
  );
  const [isWhatsAppIntegrated, setIsWhatsAppIntegrated] =
    useState<boolean>(false);

  useEffect(() => {
    checkIsWhatsAppAuthentication();
  }, []);

  const checkIsWhatsAppAuthentication = async () => {
    try {
      setIsLoading(true);
      const response = await checkWhatsAppIntegration();
      setIsWhatsAppIntegrated(
        response.some((item: any) => item.integratedApp === strings.WHATSAPP)
      );
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const sendHandler = async () => {
    try {
      setIsLoading(true);
      const campaignPayload = getCampaignPayload(campaignData);
      const response = await sendWhatsAppCampaign(campaignPayload);
      openSuccessNotification(
        "The WhatsApp message has been successfully sent."
      );
      handleRedirection();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirection = () => {
    history.push(urls.dashboardViewPath);
  };

  const getStepsContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <StepOne
            setActiveStep={setActiveStep}
            campaignData={campaignData}
            setCampaignData={setCampaignData}
          />
        );
      case 2:
        return (
          <StepTwo
            setIsLoading={setIsLoading}
            setActiveStep={setActiveStep}
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            sendHandler={sendHandler}
          />
        );
    }
  };

  return (
    <>
      {isWhatsAppIntegrated ? (
        getStepsContent()
      ) : (
        <WhatsAppIntegrationModel isOpen={!isLoading} />
      )}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};
export default CreateWhatsAppCampaigns;
