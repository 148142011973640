import { useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Autocomplete,
  TextField,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { getSubordinateEmailsUsers } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignServices";
import { isTruthy, openErrorNotification } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { CustomAppHeader, CustomButton, CustomInput } from "global/components";
import StepThreeStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/StepThree.styles";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import strings from "global/constants/StringConstants";
import { handleStep2Validation } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignHelpers";
import { TestMailModal } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/TestMailModal";
import { ScheduleTimeModal } from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/ScheduleTimeModal";
import SideBarRouterPrompt from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/component/SideBarRouterPrompt";
import { useAppSelector } from "utils/hooks";
import { isBlockingState } from "redux/blockingSlice";
import AttachmentDropzone from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/AttachmentDropzone";
import PreviewTemplate from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/PreviewTemplate";
import FromSection from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/CampaignDetailsSections/FromSection";
import SubjectSection from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/CampaignDetailsSections/SubjectSection";
import ToSection from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/CampaignDetailsSections/ToSection";
import AttachmentsSection from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/CampaignDetailsSections/AttachmentsSection";
import DesignSection from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/CampaignDetailsSections/DesignSection";
import CreateCampaignStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/CreateCampaignNew.styles";
import { hasAccessTo } from "utils/AuthorizationManager";

interface CustomProps {
  campaignData: any;
  setCampaignData: any;
  setActiveStep: any;
  parentId: any;
  allowedEmailCount: number;
  handleSaveAsDraft: any;
  handleSendCampaign: any;
  templateHtml: any;
  attachmentFileSize: any[];
  setAttachmentFileSize: Function;
  totalAttachmentSize: number;
  setTotalAttachmentSize: Function;
  isBlocking: boolean;
  setIsBlocking: Function;
  groupType: string;
  isRetargetOrFollowUp: boolean;
}

const classes = StepThreeStyles;
const createCampaignClasses = CreateCampaignStyles;

export const StepThree = (props: CustomProps) => {
  const hasContactFetchAccess = hasAccessTo(
    strings.contact,
    strings.fetchPermission
  );
  const hasCampaignerFetchAccess = hasAccessTo(
    strings.campaigner,
    strings.fetchPermission
  );

  const [emailUsers, setEmailUsers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [testMailModalOpen, setTestMailModalOpen] = useState<boolean>(false);
  const [scheduleTimeModalOpen, setScheduleTimeModalOpen] =
    useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const logoutBlockState = useAppSelector(isBlockingState);

  useEffect(() => {
    props.setIsBlocking(false);
  }, [logoutBlockState]);

  useEffect(() => {
    props.setIsBlocking(true);
    fetchUserEmailUser();
  }, []);

  const hasCampaignExecutionPermission = () => {
    return hasCampaignerFetchAccess && hasContactFetchAccess;
  };

  const fetchUserEmailUser = async () => {
    try {
      setIsLoading(true);
      const response = await getSubordinateEmailsUsers();
      const removeDuplicates = response?.filter(
        (item: any, index: any) => response.indexOf(item) === index
      );
      setEmailUsers(removeDuplicates);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleValidation = () => {
    const { isValid, errors } = handleStep2Validation(props.campaignData);
    props.setCampaignData(errors);
    return isValid;
  };

  const handleStep2SaveDraft = () => {
    if (handleValidation()) {
      props.handleSaveAsDraft();
    }
  };

  const onChangeHandler = (event: any) => {
    props.setCampaignData({
      ...props.campaignData,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const handleBackStep = async () => {
    await props.setIsBlocking(false);
    props.setActiveStep((prevStep: number) => prevStep - 1);
  };

  const handleStartDesign = () => {
    if (handleValidation()) {
      props.setActiveStep((prevStep: number) => prevStep + 1);
    }
  };

  const checkIfStep3Completed = () => {
    if (!props.campaignData.subject || !props.campaignData.content) {
      openErrorNotification("You need to design a template first");
      return false;
    }
    return true;
  };

  const checkMailingAudienceSelected = () => {
    if (isTruthy(props.parentId)) {
      return true;
    }
    if (props.campaignData.groups?.[0]?.value.length === 0) {
      openErrorNotification("Please Select Mailing Audience");
      return false;
    }
    return true;
  };

  const handleTestMailClick = () => {
    if (checkIfStep3Completed()) {
      setTestMailModalOpen(true);
    }
  };

  const handleStep2Send = () => {
    if (checkIfStep3Completed() && checkMailingAudienceSelected()) {
      setScheduleTimeModalOpen(true);
    }
  };

  const getHeaderForm = () => (
    <Grid container sx={classes.headerBox}>
      <Grid item md={6} xs={12}>
        <Box mx={2} my={1}>
          <Box sx={classes.label}>
            <Typography sx={classes.labelText}>From</Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <Autocomplete
            sx={classes.selectMenu}
            id="step2_from_dropdown"
            options={emailUsers}
            value={props.campaignData?.fromId?.value}
            autoHighlight={true}
            autoSelect={true}
            renderInput={(params) => (
              <TextField
                sx={classes.autocompleteStyle}
                {...params}
                onSelect={onChangeHandler}
                name="fromId"
                error={props.campaignData?.fromId?.error}
              />
            )}
          />
          <FormHelperText error sx={classes.errorStyle}>
            {props.campaignData?.fromId?.error}
          </FormHelperText>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mx={2} my={1}>
          <CustomInput
            required
            label="Display Name"
            id="step2_display_name_field"
            type="type"
            name="displayName"
            placeHolder="Enter your display name"
            propsToInputElement={{ maxLength: strings.DISPLAY_NAME_SIZE }}
            value={props.campaignData?.displayName?.value}
            onChange={onChangeHandler}
            error={props.campaignData?.displayName?.error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span>
                    {props.campaignData.displayName?.value?.length} / 50
                  </span>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <AttachmentDropzone
          setIsLoading={setIsLoading}
          campaignData={props.campaignData}
          setCampaignData={props.setCampaignData}
          attachmentFileSize={props.attachmentFileSize}
          setAttachmentFileSize={props.setAttachmentFileSize}
          totalAttachmentSize={props.totalAttachmentSize}
          setTotalAttachmentSize={props.setTotalAttachmentSize}
        />
      </Grid>
      <Grid item md={6} xs={12} display={"flex"} alignItems={"flex-start"}>
        <Box mx={2} my={1} width={"100%"}>
          <CustomButton
            customClasses={{ width: "100%" }}
            label="Start Designing"
            onClick={handleStartDesign}
            id="start_designing_step_three"
          />
        </Box>
      </Grid>
    </Grid>
  );

  const getHeaderButtons = () => (
    <Stack sx={createCampaignClasses.headerButtonStack}>
      <Box sx={createCampaignClasses.headerButtonStyle}>
        <CustomButton
          label="Back"
          onClick={handleBackStep}
          customClasses={classes.headerButton}
          id="step2_back_button"
        />
      </Box>
      {!props.parentId && (
        <Box sx={createCampaignClasses.headerButtonStyle}>
          <CustomButton
            id="step2_save_draft_button"
            label="Save as draft"
            onClick={handleStep2SaveDraft}
            customClasses={classes.headerButton}
          />
        </Box>
      )}
      <Box sx={createCampaignClasses.headerButtonStyle}>
        <CustomButton
          id="step3_test_mail_button"
          label="Test mail"
          onClick={handleTestMailClick}
          customClasses={classes.headerButton}
        />
      </Box>
      <Box sx={createCampaignClasses.headerButtonStyle}>
        <CustomButton
          id="step3_send_button"
          label="Send"
          onClick={handleStep2Send}
          disabled={!hasCampaignExecutionPermission()}
          customClasses={classes.headerButton}
        />
      </Box>
    </Stack>
  );

  const getHeaderSection = () => (
    <Grid container>
      <Grid item xs={12} lg={6} xl={7}>
        {getHeaderForm()}
      </Grid>
      <Grid
        item
        justifyContent={{
          lg: "end",
          xs: "start",
        }}
        sx={classes.headerButtonGroup}
        xs={12}
        lg={6}
        xl={5}
      >
        {getHeaderButtons()}
      </Grid>
    </Grid>
  );

  const getCampaignDetails = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={8} xl={8} lg={8}>
          <Box sx={classes.campaignDetailsBox}>
            <ToSection
              parentId={props.parentId}
              allowedEmailCount={props.allowedEmailCount}
              campaignData={props.campaignData}
              setCampaignData={props.setCampaignData}
              groupType={props.groupType}
            />
          </Box>
          <Box sx={classes.campaignDetailsBox}>
            <FromSection
              emailUsers={emailUsers}
              campaignData={props.campaignData}
              setCampaignData={props.setCampaignData}
            />
          </Box>
          <Box sx={classes.campaignDetailsBox}>
            <SubjectSection
              emailUsers={emailUsers}
              campaignData={props.campaignData}
              setCampaignData={props.setCampaignData}
            />
          </Box>
          <Box sx={classes.campaignDetailsBox}>
            <AttachmentsSection
              setIsLoading={setIsLoading}
              campaignData={props.campaignData}
              setCampaignData={props.setCampaignData}
              attachmentFileSize={props.attachmentFileSize}
              setAttachmentFileSize={props.setAttachmentFileSize}
              totalAttachmentSize={props.totalAttachmentSize}
              setTotalAttachmentSize={props.setTotalAttachmentSize}
            />
          </Box>
          <Box sx={classes.campaignDetailsBox}>
            <DesignSection
              parentId={props.parentId}
              campaignData={props.campaignData}
              setCampaignData={props.setCampaignData}
              setActiveStep={props.setActiveStep}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          xl={4}
          lg={4}
          sx={{ backgroundColor: "#eee" }}
        >
          <PreviewTemplate
            previewHTML={props.templateHtml}
            campaignData={props.campaignData}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <CustomAppHeader className={createCampaignClasses.headerSection}>
        <Grid container xs={12} sx={createCampaignClasses.headerGridContainer}>
          <Typography sx={createCampaignClasses.pageTitle}>
            Configuration
          </Typography>

          {getHeaderButtons()}
        </Grid>
      </CustomAppHeader>
      <Box sx={classes.step2Wrapper}>{getCampaignDetails()}</Box>
      <TestMailModal
        testMailModalOpen={testMailModalOpen}
        setTestMailModalOpen={setTestMailModalOpen}
        setIsLoading={setIsLoading}
        campaignData={props.campaignData}
        templateHtml={props.templateHtml}
      />
      <ScheduleTimeModal
        setScheduleTimeModalOpen={setScheduleTimeModalOpen}
        scheduleTimeModalOpen={scheduleTimeModalOpen}
        campaignData={props.campaignData}
        setCampaignData={props.setCampaignData}
        handleSendCampaign={props.handleSendCampaign}
      />
      <SideBarRouterPrompt
        isBlocking={props.isBlocking}
        showPrompt={showPrompt}
        setShowPrompt={setShowPrompt}
        setBlocking={props.setIsBlocking}
        draftSubmit={handleStep2SaveDraft}
        isRetargetOrFollowUp={props.isRetargetOrFollowUp}
      />
      <CustomLoader isLoading={isLoading} />
    </>
  );
};
