import { Box, Stack, Typography, styled } from "@mui/material";
import dashboardStyles from "screens/Dashboard/DashboardStyles";
import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from "@mui/material/LinearProgress";

interface CustomProps {
  graphData: { name: string; value: number; fill: string }[];
  total: number;
}

interface StyledLinearProgressProps extends LinearProgressProps {
  bgcolor?: string;
}

const RecentCampaignStatschart = (props: CustomProps) => {
  const classes = dashboardStyles;

  const data = props.graphData.map((stat) => {
    const portion = props.total && props.total !== 0 ? (stat.value / props.total) * 100 : 0;
    return {
      ...stat,
      portion: parseInt(portion.toFixed()),
    };
  });

  const BorderLinearProgress = styled(
    ({ bgcolor, ...other }: StyledLinearProgressProps) => (
      <LinearProgress {...other} />
    )
  )(({ theme, bgcolor }: any) => ({
    height: 8,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: `${bgcolor}10`,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: bgcolor,
    },
  }));

  return (
    <>
      <Box width={"100%"} minHeight={"250px"}>
        {data.map((item: any, index: number) => (
          <Box mt={3.5} px={1}>
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Typography sx={classes.graphLegend}>{item.name}</Typography>
              <Typography sx={classes.graphLegend}>{item.value}</Typography>
            </Stack>
            <BorderLinearProgress
              variant="determinate"
              value={item.portion}
              bgcolor={item.fill}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default RecentCampaignStatschart;
