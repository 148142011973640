import { Box, Typography, Stack, Chip } from "@mui/material";
import StepTwoStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepTwo/StepTwo.styles";
import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import { Pie, PieChart, Sector } from "recharts";
import nospam from "assets/images/nospam.svg";
import { CustomButton } from "global/components";

const renderActiveShape = (props: any) => {
  const { cx, cy, fill, percent, startAngle, endAngle, payload } = props;
  const a = payload.outerRadius;
  const b = payload.innerRadius;
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={b}
        outerRadius={a}
        fill={fill}
      />
    </g>
  );
};

const colorIndicators = [
  { color: "#f87171", title: "Urgency" },
  { color: "#fdba74", title: "Shady" },
  { color: "#f9a8d4", title: "Moderate" },
  { color: "#d1d5db ", title: "Unnatural" },
];

const classes = StepTwoStyles;

interface CustomProps {
  spamPredictionValue: number;
  color: string;
  spamText: string;
  topSpamKeywords: any;
  spamPredictionApi: any;
  spamLoading: boolean;
  disabled: boolean;
}

export const SpamBox = ({
  spamPredictionValue,
  color,
  spamText,
  topSpamKeywords,
  spamPredictionApi,
  spamLoading,
  disabled,
}: CustomProps) => {
  const spamStatus = [
    {
      name: "predictedValue",
      value: spamPredictionValue,
      fill: color,
      outerRadius: 80,
      innerRadius: 60,
    },
    {
      name: "outOf100",
      value: 100 - spamPredictionValue,
      fill: "#c5c5c5",
    },
  ];

  const getBgColor = (severity: number) => {
    if (severity >= 0.7) {
      return colorIndicators[0].color;
    } else if (severity >= 0.5 && severity <= 0.69) {
      return colorIndicators[1].color;
    } else if (severity >= 0.3 && severity <= 0.49) {
      return colorIndicators[2].color;
    } else {
      return colorIndicators[3].color;
    }
  };

  const getNoSpamKeywordsMessage = () => {
    return (
      <Box sx={classes.noSpam}>
        <Stack direction="column">
          <img src={nospam} height={100} />
          <Typography sx={classes.message}>
            Voila! You have no spam keywords in your email body
          </Typography>
        </Stack>
      </Box>
    );
  };

  const getKeywordButtons = () => {
    return (
      <Box mt={1}>
        {Object.keys(topSpamKeywords).map((item) => (
          <Chip
            label={item}
            sx={{
              ...classes.wordChip,
              backgroundColor: getBgColor(topSpamKeywords[item]),
            }}
          />
        ))}
      </Box>
    );
  };

  return (
    <Box px={2}>
      <Box sx={classes.spamImageWrapper}>
        <CustomButton
          id="step3_check_spam_button"
          onClick={spamPredictionApi}
          loading={spamLoading}
          label="Check Spam"
          disabled={disabled}
        />
      </Box>
      <Box sx={classes.chartWrapper}>
        <PieChart width={200} height={200}>
          <Pie
            activeIndex={0}
            activeShape={renderActiveShape}
            data={spamStatus}
            cx={"50%"}
            cy={"50%"}
            innerRadius={65}
            outerRadius={75}
            dataKey="value"
            startAngle={90}
            endAngle={-270}
          />
        </PieChart>
      </Box>
      <Typography sx={classes.spamTextStyle}>{spamText}</Typography>
      <Box mt={2} sx={classes.spamWarningWrapper}>
        <ErrorSharpIcon color="warning" />
        <Typography sx={classes.spamWaringTextStyle}>
          All spam filters work differently. What may pass here, might not be in
          your owner's inbox (especially if they have already marked you as
          'SPAM')
        </Typography>
      </Box>
      <Typography sx={classes.spamWordsTitle}>Spam Words</Typography>
      <Stack direction="row" spacing={2} mt={1}>
        {colorIndicators.map((item) => (
          <Box sx={classes.colorIndicatorsBox}>
            <Box sx={classes.colorSquare} bgcolor={item.color}></Box>
            <Typography sx={classes.severityFonts}>{item.title}</Typography>
          </Box>
        ))}
      </Stack>
      <Stack direction="column" spacing={2}>
        {Object.keys(topSpamKeywords).length === 0
          ? getNoSpamKeywordsMessage()
          : getKeywordButtons()}
      </Stack>
    </Box>
  );
};
