import { useState, useEffect } from "react";
import { Box, Typography, Grid, Stack } from "@mui/material";
import { CustomAppHeader, CustomButton } from "global/components";
import StepTwoStyles from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/StepTwo/StepTwo.styles";
import FromSection from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/StepTwo/CampaignDetailsSections/FromSection";
import ToSection from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/StepTwo/CampaignDetailsSections/ToSection";
import ContentSection from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/StepTwo/CampaignDetailsSections/ContentSection";
import {
  capitalizeFirstCharacter,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import {
  getApprovedWhatsAppTemplates,
  getApprovedWhatsAppTemplatesCount,
  getWhatsAppFromPhoneNumbers,
} from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/CreateWhatsAppCampaignServices";
import { stepTwoValidations } from "screens/WhatsAppCampaign/CreateWhatsAppCampaigns/CreateWhatsAppCampaignHelpers";
import WhatsAppMessagePreview from "global/components/WhatsAppMessagePreview/WhatsAppMessagePreview";
import { headerFormatTabContent } from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/CreateWhatsAppTemplateHelpers";

interface CustomProps {
  setIsLoading: Function;
  setActiveStep: Function;
  campaignData: any;
  setCampaignData: Function;
  sendHandler: Function;
}

const StepTwo = (props: CustomProps) => {
  const classes = StepTwoStyles;

  const [approvedTemplates, setApprovedTemplates] = useState<any[]>([]);
  const [whatsAppFromDetails, setWhatsAppFromDetails] = useState<any>({});

  useEffect(() => {
    getWhatsAppTemplates();
    getWhatsAppFromDetails();
  }, []);

  const backButtonHandler = (previousStep: number) => {
    props.setActiveStep(previousStep);
  };

  const handleSendCampaign = () => {
    const { isValid, errorLadenState } = stepTwoValidations(props.campaignData);
    props.setCampaignData({ ...errorLadenState });
    if (isValid) {
      props.sendHandler();
    }
  };

  const getWhatsAppFromDetails = async () => {
    try {
      props.setIsLoading(true);
      const response = await getWhatsAppFromPhoneNumbers();
      setWhatsAppFromDetails(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const getWhatsAppTemplates = async () => {
    try {
      props.setIsLoading(true);
      const templateCount = await getApprovedWhatsAppTemplatesCount();
      const templates = await getApprovedWhatsAppTemplates(templateCount);
      setApprovedTemplates(templates);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const getHeaderTitle = () => {
    return (
      <Typography sx={classes.pageTitle}>Create WhatsApp Campaign</Typography>
    );
  };

  const getHeaderButtonStack = () => {
    return (
      <Stack direction={"row"}>
        <CustomButton
          id="whatsapp_template_step2_back"
          label="Back"
          onClick={() => {
            backButtonHandler(1);
          }}
          customClasses={classes.headerButtons}
        />
        <CustomButton
          id="whatsapp_template_step2_submit"
          label="Submit"
          onClick={() => {
            handleSendCampaign();
          }}
          customClasses={classes.headerButtons}
        />
      </Stack>
    );
  };

  const getStepTwoContent = () => {
    return (
      <Grid container sx={classes.mainGridContainer}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          sx={classes.formGridItem}
        >
          <Box
            sx={{
              ...classes.campaignDetailsBox,
              borderRadius: "10px 10px 0 0",
            }}
          >
            <FromSection
              campaignData={props.campaignData}
              setCampaignData={props.setCampaignData}
              whatsAppFromDetails={whatsAppFromDetails.phoneNumberDetails}
            />
          </Box>
          <Box sx={classes.campaignDetailsBox}>
            <ToSection
              campaignData={props.campaignData}
              setCampaignData={props.setCampaignData}
            />
          </Box>
          <Box
            sx={{
              ...classes.campaignDetailsBox,
              borderRadius: "0 0 10px 10px",
            }}
          >
            <ContentSection
              campaignData={props.campaignData}
              setCampaignData={props.setCampaignData}
              approvedTemplates={approvedTemplates}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
          sx={classes.previewGridItem}
        >
          <WhatsAppMessagePreview
            isHeaderIncluded={isTruthy(
              props?.campaignData?.template?.value?.header?.text
            )}
            isFooterIncluded={isTruthy(
              props?.campaignData?.template?.value?.footer?.text
            )}
            headerTextValue={props?.campaignData?.template?.value?.header?.text}
            bodyTextValue={
              props?.campaignData?.template?.value?.body?.text ?? ""
            }
            footerTextValue={props?.campaignData?.template?.value?.footer?.text}
            headerFormat={capitalizeFirstCharacter(
              props?.campaignData?.template?.value?.header?.format ??
                headerFormatTabContent[1].label
            )}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box sx={classes.mainContainerBox}>
      <CustomAppHeader className={classes.headerSection}>
        <Grid container sx={classes.headerGridContainer}>
          {getHeaderTitle()}
          {getHeaderButtonStack()}
        </Grid>
      </CustomAppHeader>
      {getStepTwoContent()}
    </Box>
  );
};

export default StepTwo;
