import Joyride from "react-joyride";
import { useAppSelector } from "utils/hooks";
import { selectRun, selectStepIndex, selectSteps } from "redux/tourSlice";
import { tourHandler } from "utils/UserTour/UserTourHelpers";
import UserTourTooltip from "utils/UserTour/UserTourTooltip";
import { lightPinkColor } from "utils/styles";

const UserTour = () => {
  const run = useAppSelector(selectRun);
  const stepIndex = useAppSelector(selectStepIndex);
  const steps = useAppSelector(selectSteps);

  return (
    <Joyride
      run={run}
      tooltipComponent={UserTourTooltip}
      continuous
      stepIndex={stepIndex}
      steps={steps}
      callback={tourHandler}
      showProgress
      showSkipButton
      disableScrollParentFix={true}
      styles={{
        options: { arrowColor: lightPinkColor },
        spotlight: {
          borderRadius: "10px",
        },
      }}
    />
  );
};

export default UserTour;
