import { Box, Chip, Grid, Typography, useMediaQuery } from "@mui/material";
import { NewCustomButton } from "global/components";
import campaignDetailsSectionsStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/CampaignDetailsSections/CampaignDetailsSections.style";
import { useState } from "react";
import AttachmentDropzone from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/AttachmentDropzone";
import { getFileExtension } from "helpers/methods";
import { theme } from "utils/styles";

interface CustomProps {
  setCampaignData: Function;
  campaignData: any;
  setIsLoading: any;
  attachmentFileSize: any[];
  setAttachmentFileSize: Function;
  totalAttachmentSize: number;
  setTotalAttachmentSize: Function;
}

const AttachmentsSection = (props: CustomProps) => {
  const classes = campaignDetailsSectionsStyles;
  const attachmentNames = Object.keys(props.campaignData.attachments);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const editModeView = () => {
    return (
      <>
        <Box mb={1} sx={classes.viewModeBox}>
          <Box>
            <Typography sx={classes.headerLabel}>Attachments</Typography>
            <Typography sx={classes.headerSubDescription}>
              Do you want to add attachments to this email?
            </Typography>
          </Box>
          <Box>
            <NewCustomButton
              id={"to_edit_cancel_button"}
              label={"Cancel"}
              customClasses={classes.buttonStyles}
              onClick={() => setIsEditMode(false)}
            />
          </Box>
        </Box>
        <AttachmentDropzone
          setIsLoading={props.setIsLoading}
          campaignData={props.campaignData}
          setCampaignData={props.setCampaignData}
          attachmentFileSize={props.attachmentFileSize}
          setAttachmentFileSize={props.setAttachmentFileSize}
          totalAttachmentSize={props.totalAttachmentSize}
          setTotalAttachmentSize={props.setTotalAttachmentSize}
        />
      </>
    );
  };

  const viewModeView = () => {
    return (
      <Box mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography sx={classes.headerLabel}>Attachments</Typography>
          <Box sx={classes.headerSubDescription}>
            {attachmentNames
              ?.filter(
                (item: any, index: any) =>
                  attachmentNames.indexOf(item) === index
              )
              ?.map((name: string) => {
                return (
                  <Chip
                    key={name}
                    label={
                      <Typography
                        noWrap
                        sx={{
                          maxWidth: {
                            xs: "100px",
                            sm: "150px",
                            md: "150px",
                            lg: 300,
                            xl: 600,
                          },
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {name}
                      </Typography>
                    }
                    variant="filled"
                  />
                );
              })}
          </Box>
        </Box>
        <Box>
          <NewCustomButton
            id="attachments_edit_button"
            label={
              Object.keys(props?.campaignData?.attachments).length !== 0
                ? "Edit Attachments"
                : "Add Attachments"
            }
            onClick={() => setIsEditMode(true)}
            customClasses={classes.buttonStyles}
          />
        </Box>
      </Box>
    );
  };

  return <Box>{isEditMode ? editModeView() : viewModeView()}</Box>;
};

export default AttachmentsSection;
