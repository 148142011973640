import {
  Stack,
  Button,
  Typography,
  Card,
  CardContent,
  styled,
  Chip,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Attachment from "assets/icons/Attachment.svg";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import notifiers from "global/constants/NotificationConstants";
import { setFileName } from "helpers/methods";
import {
  doDeleteTicketAttachment,
  uploadSupportTicketAttachment,
} from "screens/Shared/Layout/createTicketService";
import { purplePrimaryColor, regularFont } from "utils/styles";
import ViewTicketStyles from "../ViewTicket.styles";

export const parseHTML = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const rootElement = doc.body;

  const convertNode = (node: any) => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.nodeValue;
    }

    const children: any = Array.from(node.childNodes).map(convertNode);

    const attributes: { [key: string]: string } = {};
    if (node.nodeType === Node.ELEMENT_NODE) {
      Array.from(node.attributes).forEach((attr: any) => {
        attributes[attr.name] = attr.value;
      });
    }

    // Handle IMG tag separately
    if (node.tagName.toLowerCase() === "img") {
      return React.createElement(node.tagName.toLowerCase(), {
        key: node.src,
        ...attributes,
      });
    }

    return React.createElement(
      node.tagName.toLowerCase(),
      {
        key: node.tagName.toLowerCase(),
        ...attributes,
        style: { margin: 0, padding: 2 },
      },
      children.length ? children : null
    );
  };

  return convertNode(rootElement);
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const useStyles = makeStyles(() => ({
  customQuill: {
    "& .ql-toolbar": {
      opacity: 0.9,
      borderRadius: "10px 10px 0 0",
      border: "0.5px solid #E9E9E9",
    },
    "& .ql-editor": {
      minHeight: "10em !important",
    },
    "& .ql-container.ql-snow": {
      borderRadius: "0 0 10px 10px",
      border: "0.5px solid #E9E9E9",
    },
  },
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface CustomProps {
  content: string;
  onChange: (content: string) => void;
  handleCancel: Function;
  handleAddComment: Function;
  ticketId: string;
  files: any[];
  setFiles: Function;
  cancelBtn?: boolean;
}

const CommentEditor = (props: CustomProps) => {
  const classes = useStyles();
  const viewTicketStyles = ViewTicketStyles;
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadDocument = async (event: any) => {
    event.preventDefault();
    const file = event.target.files;
    try {
      for (const element of file) {
        const fileDetails = new FormData();
        fileDetails.append("file", element);
        fileDetails.append("filename", element.name);
        setIsLoading(true);
        const response = await uploadSupportTicketAttachment(
          fileDetails,
          props.ticketId
        );
        if (response) {
          props.setFiles((prevFiles: any) => [...prevFiles, response.path]);
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleDocumentDelete = async (path: string) => {
    try {
      setIsLoading(true);
      const body = {
        path,
      };
      const response = await doDeleteTicketAttachment(body);
      props.setFiles((prevFiles: any) =>
        prevFiles.filter((file: any) => file !== path)
      );
      openSuccessNotification(response.message);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const showUploadedFiles = () => {
    return (
      <Grid container gap={1}>
        {props.files?.map((file: string) => (
          <Chip
            key={file}
            label={setFileName(file)}
            onDelete={() => handleDocumentDelete(file)}
            sx={{
              ...regularFont,
              backgroundColor: "#EBEFFF",
              fontSize: "13px",
              maxWidth: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        ))}
      </Grid>
    );
  };

  const getEditor = () => {
    return (
      <>
        <CustomLoader isLoading={isLoading} />
        <Card sx={viewTicketStyles.billingDetail}>
          <CardContent>
            <Stack direction="column" spacing={1}>
              <ReactQuill
                theme="snow"
                value={props.content}
                onChange={props.onChange}
                formats={formats}
                modules={modules}
                className={classes.customQuill}
              />
              <Stack
                direction="row"
                justifyContent={
                  props.files?.length > 0 ? "space-between" : "flex-end"
                }
                alignItems="center"
              >
                {props.files?.length > 0 && showUploadedFiles()}
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                  <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    endIcon={<img src={Attachment} />}
                  >
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event: any) => handleUploadDocument(event)}
                      accept=".jpeg, .jpg, .png, .gif, .bmp, .tiff, .pdf, .doc, .docx, .txt, .xls, .xlsx, .heic"
                      multiple
                    />
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      fontSize: "0.85rem",
                      background: purplePrimaryColor,
                      "&:hover": {
                        background: purplePrimaryColor,
                      },
                    }}
                    onClick={() => props.handleAddComment()}
                  >
                    Save
                  </Button>
                  {!props.cancelBtn ? (
                    <Button
                      size="small"
                      color="inherit"
                      sx={{ fontSize: "0.85rem" }}
                      variant="outlined"
                      onClick={() => props.handleCancel()}
                    >
                      Cancel
                    </Button>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </>
    );
  };

  return getEditor();
};

export default CommentEditor;
