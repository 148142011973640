import {
  boldFont,
  getRelativeFontSize,
  mediumFont,
  pinkDarkColor,
  pinkThemedMenuItems,
  primaryHeadingColor,
  pureWhiteColor,
  purpleThemedSelectComponent,
  regularFont,
  theme,
} from "utils/styles";

const FailStatsStyles = {
  headerBackgroundColor: {
    backgroundColor: "#fcf5ff",
    padding: "10px 20px 15px 18px",
  },
  mainGridContainer: {
    padding: "20px 0px",
  },
  mainSection: {
    padding: "20px",
  },
  mainCardHeading: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1),
    },
  },
  inputsSectionDropdowns: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
      flexDirection: "row-reverse",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  refreshBtnAndDateRangeBox: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      margin: "5px 0 5px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      marginBottom: "5px",
    },
  },
  dropdownAndInputBox: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  dateBox: {
    [theme.breakpoints.up("sm")]: {
      margin: "0 10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
    display: "flex",
    alignItems: "center",
  },
  date: {
    color: "#666666",
    ...regularFont,
    fontSize: getRelativeFontSize(0),
    fontStyle: "italic",
  },
  refreshBtn: {
    height: "47px",
    backgroundColor: "#ffffff",
    border: "1px solid #ced4da",
    minWidth: "50px",
    borderRadius: "10px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContact: "center",
    fontSize: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
    "&:hover": {
      backgroundColor: "white",
    },
  },
  inputWrapperAddClasses: {
    marginLeft: "10px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "10px",
    },
  },
  dropDownStyle: {
    backgroundColor: pureWhiteColor,
    height: "47px",
    padding: "2px",
    maxWidth: "200px",
    borderRadius: "12px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
    ...regularFont,
    ...purpleThemedSelectComponent,
  },
  dateRangePickerSelect: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  optionStyle: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  searchInputWrapper: {
    paddingRight: "10px",
    display: "flex",
    alignItem: "center",
    justifyContent: "flex-end",
    minWidth: "100px",
    gap: "10px",
    marginLeft: "10px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
      justifyContent: "flex-start",
      marginLeft: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginLeft: "10px",
    },
  },
  searchInput: {
    maxWidth: "150px",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  rowColor: {
    color: pinkDarkColor,
    ...mediumFont,
    fontSize: getRelativeFontSize(),
    display: "flex",
    justifyContent: "flex-start",
  },
  mainTable: {
    minWidth: "300px",
    width: "100%",
    overflow: "auto",
  },
  tableRowText: {
    fontSize: getRelativeFontSize(),
  },
  failEventColumn: {
    fontSize: getRelativeFontSize(),
    minWidth: "100px",
  },
  campaignNameColumn: {
    fontSize: getRelativeFontSize(),
    minWidth: "130px",
  },
  reasonColumn: {
    wordBreak: "break-all",
    fontSize: getRelativeFontSize(),
  },
} as const;

export default FailStatsStyles;
