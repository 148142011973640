import {
  boldFont,
  getRelativeFontSize,
  regularFont,
  theme,
  pureWhiteColor,
  primaryHeadingColor,
  pinkDarkColor,
  textLightColor,
  mediumFont,
  inputLabelRequiredColor,
  lightBgColor,
  buttonWhiteBg,
  purplePrimaryColor,
  purpleThemedSelectComponent,
  pinkThemedMenuItems,
  primaryBlackColor,
} from "utils/styles";

const CampaignsStyles = {
  customPaper: {
    margin: "0px 0px 15px 0px",
  },
  chipStyle: {
    ...boldFont,
    padding: 2,
    width: "100%",
  },
  headerBackgroundColor: {
    backgroundColor: "#fcf5ff",
    padding: "35px 20px 18px 20px",
  },
  paperInnerWrapper: {
    padding: theme.spacing(2),
    paddingTop: "2px",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(0),
    },
  },
  campaignTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  },
  headerLeftStyle: {},
  dropDownStyle: {
    ...regularFont,
    ...purpleThemedSelectComponent,
    backgroundColor: pureWhiteColor,
    height: "47px",
    padding: "2px",
    maxWidth: "200px",
    borderRadius: "12px",
    fontSize: "14px",
  },
  inputWrapperAddClasses: {
    marginLeft: "10px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "8px",
    },
  },
  optionStyle: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  createButtonStyle: {
    width: "180px",
  },
  date: {
    color: "#666666",
    ...regularFont,
    fontSize: getRelativeFontSize(0),
    fontStyle: "italic",
  },
  refreshBtn: {
    height: "47px",
    backgroundColor: "#ffffff",
    border: "1px solid #ced4da",
    minWidth: "50px",
    borderRadius: "10px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContact: "center",
    fontSize: "50px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  campaignsTabs: {
    marginTop: "20px",
    overflow: "auto",
    width: "100%",
    paddingBottom: "10px",
  },
  tabs: {
    minWidth: "600px",
    "& .MuiTabs-indicator": {
      height: 0,
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#c20c85",
      color: "#ffffff",
    },
  },
  colUserStyle: {
    color: pinkDarkColor,
    ...boldFont,
    padding: "0",
  },
  campaignNameStyle: {
    ...boldFont,
    color: pinkDarkColor,
    fontSize: "14px",
    padding: " 0",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    cursor: "pointer",
  },
  tabBtn: {
    background: "#fafafa",
    border: "1px solid #f0f0f0",
    padding: "0 12px",
    marginLeft: "15px",
    borderRadius: "12px",
    ...regularFont,
    color: "#c20c85",
    fontSize: "14px",
    minHeight: "47px",
  },
  inputWrapper: {
    display: "flex",
    alignItem: "center",
    margin: "15px 0",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "10px",
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "12px",
    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        borderColor: "#4B0150",
      },
    },
  },
  ActionBtn: {
    width: "100px",
    border: "1px solid #ccc",
    background: "none",
    display: "flex",
    gap: "5px ",
    fontSize: "12px",
    padding: "2px 15px",
    alignItem: "center",
    color: "rgba(0,0,0,0.7)",
    ...boldFont,
  },
  ViewPopOver: {
    display: "none",
  },
  eyesBtn: {
    fontSize: "15px",
    paddingTop: "5px",
    color: "rgba(0,0,0,0.7)",
  },
  dialogTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "10px",
    textAlign: "center",
  },
  titleLeft: {
    color: "#faad14",
  },
  titleRight: {
    color: " rgba(0,0,0,.85)",
    ...boldFont,
    fontSize: getRelativeFontSize(14),
  },
  dialogContent: {
    fontSize: getRelativeFontSize(2),
    ...mediumFont,
    textAlign: "center",
    "& span": {
      ...boldFont,
    },
  },
  dialogFooter: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "10px",
    "& button": {
      width: "120px",
    },
  },
  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  deleteBtn: {
    fontSize: "20px",
    background: "none",
    border: "none",
  },
  ViewPopOverWrapper: {},
  popOverHeading: {
    padding: "5px 16px 5px",
    color: "rgba(0,0,0,.85)",
    ...boldFont,
  },
  popOverListItem: {
    padding: "10px 10px",
    width: "100%",
  },
  // view Result dashBoard css
  dashBoardHeader: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginTop: "50px",
  },

  dashBoardHeaderHeading: {
    fontSize: "20px",
    ...boldFont,
  },
  customPaperStyle: {
    maxWidth: 400,
    minWidth: 320,
    height: "100%",
    margin: "0 auto",
  },
  cardTitle: {
    fontSize: "16px",
    ...boldFont,
    marginLeft: "10px",
  },
  cardWrapper: {
    padding: "20px 10px",
  },
  cardFooter: {
    padding: "10px 0",
  },
  cardFooterDivider: {
    borderRight: "1px solid rgba(0,0,0,.3)",
  },
  details: {
    fontSize: "102px",
  },
  titleFontSize: {
    fontSize: "23px",
    whiteSpace: "nowrap",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
    fontSize: getRelativeFontSize(14),
    ...boldFont,
  },
  bodyContentGroupName: {
    minWidth: "100px",
    ...boldFont,
    color: textLightColor,
  },
  labelStyle: {
    ...boldFont,
    color: textLightColor,
    display: "flex",
    marginBottom: "10px",
  },
  customSuccess: {
    maxWidth: 400,
    minWidth: 320,
    height: "100%",
    margin: "0 auto",
  },
  footerWrapper: {
    marginBottom: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    "& button": {
      width: "120px",
    },
  },
  // retargating page css
  textStyle: {
    fontSize: "1.1rem",
    ...boldFont,
  },
  headerDataWrapper: {
    paddingTop: "20px",
    margin: "20px",
  },
  openClick: {
    width: "1000px",
    minWidth: "500px",
    marginLeft: "10px",
  },
  subHeadingStyle: {
    ...regularFont,
    fontSize: getRelativeFontSize(10),
  },
  backButtonWrapper: {
    marginTop: "30px",
  },
  errorStyle: {
    paddingLeft: "10px",
  },
  star: {
    color: inputLabelRequiredColor,
  },
  targetAudienceMainWrapper: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  targetAudienceWrapper: {
    display: "flex",
    alignItems: "center",
    background: lightBgColor,
    width: "100%",
    padding: "10px 0",
    borderRadius: "12px",
    flexDirection: "row",
  },
  targetAudienceOpenWrapper: {
    display: "flex",
    alignItems: "center",
    background: lightBgColor,
    borderRadius: "12px",
    padding: "10px 0",
    marginTop: "10px",
    marginLeft: "15px",
  },
  retargetTableCellText: {
    ...regularFont,
    color: primaryBlackColor,
    fontSize: getRelativeFontSize(2),
  },
} as const;

export default CampaignsStyles;
