export class StringConstants {
  MAILZZY = "Mailzzy";

  applicationJSON = { "Content-Type": "application/json" };
  multipartForm = { "Content-Type": "multipart/form-data" };

  notification = "notification";
  error = "error";
  success = "success";
  warning = "warning";
  info = "info";
  autoHideDuration = 600 * 1000; //in milliseconds

  SEARCH_TIME_OUT = 350;

  integration = "Integration";
  performance = "Performance";

  // Landing Page
  LOGIN = "login";
  REGISTER = "register";
  FORGOT_PASSWORD = "forgot-password";
  RESET_PASSWORD = "changepwd";
  ACTIVATE_USER = "activate";
  VERIFY_MFA_CODE = "verify-code";
  SETUP_MFA = "setup-mfa";
  //Payment plan
  FREE_PLAN_ID = "089cc9f7f4de4023a870717f52364f63";

  //Unlayer
  UNLAYER_PROJECT_ID = 33804;

  //string regex
  nospecialcharacters =
    /^[^<>{}\"/|;:.,~!?@#$%^=&*\\]\\\\()\\[¿§«»ω⊙¤°℃℉€¥£¢¡®©0-9_+]*$/;
  //Email validation Regex
  regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  groupNameRegex = /^[a-zA-Z0-9 _-]*$/;

  passwordregex =
    /^(?=.[a-z])(?=.[A-Z])(?=.[#@$!%?&])(?=.[0-9])[A-Za-z\d#@$!%?&]/;
  //Phone Number Regex
  phoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  // Only Characters Regex
  characterRegex = /^[A-Za-z ]*$/;

  passwordValidationRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[#@$!%*?&])(?=.*[0-9])[A-Za-z\d#@$!%*?&]{8,}/;

  // Custom Domain Regex
  domainNameRegex = /^(?!-)[a-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/;
  // REgex for email's local part
  localPartRegex = /^[a-zA-Z0-9._%+-]+$/i;

  Opened = "Opened";
  Clicked = "Clicked";

  // step 4 acceptedFiles
  jpeg = ".jpeg";
  jpg = ".jpg";
  png = ".png";
  gif = ".gif";
  bmp = ".bmp";
  tiff = ".tiff";
  pdf = ".pdf";
  doc = ".doc";
  docx = ".docx";
  txt = ".txt";
  xls = ".xls";
  xlsx = ".xlsx";

  ALLOWED_ATTACHMENT_TYPE = [
    this.jpeg,
    this.gif,
    this.jpg,
    this.pdf,
    this.png,
    this.jpeg,
    this.jpg,
    this.pdf,
    this.png,
    this.txt,
  ];

  FileUrlPrefix = `https://view.officeapps.live.com/op/embed.aspx?src=`;

  // step 3 using this text in card
  chooseTemplate = "chooseTemplate";
  BuildTemplate = "BuildTemplate";
  PasteHtml = "PasteHtml";

  // ReCAPTCHA
  siteKey = "6Lc6hiAqAAAAABUxVff-zC2KBqRtoi-tH_Q8_0oY";

  SUPER_ADMIN = "Super Admin";
  ADMIN = "Admin";
  USER = "User";
  USERADMIN = "UserAdmin";

  // Predefined Roles
  ACCOUNTADMINROLEID = 1;
  ADMINROLEID = 2;

  // authManager
  DASHBOARD = "Dashboard";
  CAMPAIGNS = "Campaigns";
  SOCIALCAMPAIGNS = "Social Campaigns";
  SEQUENCES = "Sequences";
  GROUPS = "Campaign Groups";
  CONTACTS = "Contacts";
  CAMPAIGNHISTORY = "Campaign History";
  FAILEDSTATS = "Failed Stats";
  TEMPLATES = "Templates";
  TEMPLATESEDITOR = "Templates Editor";
  USERS = "Users";
  DOMAINS = "Domains";
  INTEGRATION = "Integration";
  CREATECAMPAIGN = "Create Campaign";
  CREATECAMPAIGNS = "Create Campaigns";
  CAMPAIGNSLIST = "Campaigns List";
  RETARGEETTING = "Retargetting";
  CAMPAIGNDETAILS = "Campaign Stats";
  PAGENOTFOUND = "Page Not Found";
  SOCIAL = "Social";
  TICKETS = "Tickets";
  SETTINGS = "Settings";
  WHATSAPPTEMPLATES = "WhatsApp Template";
  CREATEWHATSAPPTEMPLATE = "Create WhatsApp Template";
  CREATEWHATSAPPCAMPAIGN = "Create WhatsApp Campaign";
  SECURITY = "Security";

  STATE = "123456789";
  FACEBOOK_APP_ID = 1347418019080617;
  FACEBOOK_APP_SECRET = "f474dc2284358fc1a8fdd73955d2fa81";

  //For Support Ticket
  assignedToMe = "Assigned To Me";
  raisedByMe = "Raised By Me";
  completedTickets = "Completed Tickets";
  openTickets = "Open Tickets";
  PROCESSADMIN = "Process Admin";
  USER_DESCRIPTION_LIMIT = 500;
  TICKET_USER_TITLE_LIMIT = 50;

  // for campaign Component
  Completed = "Completed";
  Submitted = "Submitted";
  Draft = "Draft";
  All = "All";
  InReview = "InReview";

  // sorting config
  ascendingOrder = "asc";
  descendingOrder = "desc";

  CAMPAIGN_NAME_SIZE = 100;
  SUBJECT_LINE_SIZE = 90;
  DISPLAY_NAME_SIZE = 50;
  USER_TITLE_LIMIT = 50;
  USER_FIRST_NAME_LIMIT = 30;
  USER_LAST_NAME_LIMIT = 30;
  USER_EMAIL_LIMIT = 100;
  USER_ADDRESS_LIMIT = 150;
  TAGS_LIMIT = 25;
  TEST_MAIL_LIMIT = 9;
  CUSTOM_API_TIMEOUT_LIMIT = 40000;
  DOMAIN_NAME_LIMIT = 63;

  //  for ticket
  assignData = "assignData";
  RaisedData = "RaisedData";

  openData = "openData";
  completedData = "completedData";
  Reassign = "Reassign";
  DrawerTitle = "Ticket Details";
  ReassignTitleValue = "New Assignee";

  // campaign status
  Success = "Success";
  Failed = "Failed";
  Sent = "Sent";
  unsubscribed = "Unsubscribed";
  Requested = "Requested";
  Retarget = "Retarget";
  FollowUp = "FollowUp";

  groupMailingListType = "Groups";
  tagMailingListType = "Tags";

  // tabs change
  Assigneded = "Assigneded To Me";
  RaisedByMe = "Raised By Me";
  OpenTickets = "Open Tickets";
  CompletedTickets = "Completed Tickets";
  open = "Open";
  complete = "Complete";
  inProcess = "InProcess";

  // Recent Activity drop down values
  allActivity = "all";
  campaignActivity = "campaign";
  audienceActivity = "audience";

  // Integrations
  isFacebookReleased = true;
  isLinkedinReleased = false;
  isInstagramReleased = true;
  isWhatsappReleased = true;
  isSalesforceReleased = true;
  META = "Meta";
  FACEBOOK = "Facebook";
  LINKEDIN = "LinkedIn";
  INSTAGRAM = "Instagram";
  WHATSAPP = "WhatsApp";
  SALESFORCE = "Salesforce";
  INTEGRATIONS = false;
  instagramBusinessAccountKey = "instagram_business_account";

  SUPPORT_TICKET = true;

  RESENDCAMPAIGN = "resendCampaign";

  //for settings
  profile = "Profile";
  roleManagement = "Role Management";
  billingUsage = "Billing Usage";
  editRole = "Edit Role";
  createRole = "Create Role";
  rolesTable = "Roles Table";
  domainsList = "Domains List";
  domainDetails = "Domain Details";

  // for title
  LoginTitle = "Login | Mailzzy";
  CreateAccountTitle = "Create Account | Mailzzy";
  OnboardTitle = "Let's get started | Mailzzy";
  DashboardTitle = "Dashboard | Mailzzy";
  CampaignsTitle = "Campaigns | Mailzzy";
  CampaignsListTitle = "Campaigns List | Mailzzy";
  CreateCampaignsTitle = "Create Campaigns | Mailzzy";
  CreateCampaignTitle = "Create Campaign | Mailzzy";
  CampaignGroups = "Groups | Mailzzy";
  GroupContacts = "Group Contacts | Mailzzy";
  ContactTitle = "Contacts | Mailzzy";
  CampaignHistoryTitle = "History | Mailzzy";
  FailedStatsTitle = "Failed Stats | Mailzzy";
  CheckYourEmailTitle = "Check your email | Mailzzy";
  TemplatesTitle = "Templates | Mailzzy";
  UsersTitle = "Users | Mailzzy";
  DomainsTitle = "Domains | Mailzzy";
  ForgotPasswordTitle = "Forgot Password | Mailzzy";
  Integration = "Integrations | Mailzzy";
  Social = "Social | Mailzzy";
  Ticket = "Tickets | Mailzzy";
  StatsTitle = "Stats | Mailzzy";
  ResendCampaignTitle = "Resend Campaign | Mailzzy";
  ProfileTitle = "Profile | Mailzzy";
  RoleManagementTitle = "Role Management | Mailzzy";
  SequentialCampaignTitle = "Sequences | Mailzzy";
  SecurityTitle = "Security | Mailzzy";

  //for new templates page and new create campaign page
  allTemplates = "All Templates";
  myTemplates = "My Templates";

  // WhatsApp templates
  approved = "Approved";
  pending = "Pending";
  rejected = "Rejected";

  // Intervals
  thisWeek = "This Week";
  lastWeek = "Last Week";
  thisMonth = "This Month";
  lastMonth = "Last Month";
  custom = "Custom";

  // WhatsApp campaigns
  highQualityRating = "GREEN";
  mediumQualityRating = "YELLOW";
  lowQualityRating = "RED";

  // Text formatting
  bold = "bold";
  italic = "italic";
  strikethrough = "strikethrough";
  boldMarkdownSymbol = "*";
  italicMarkdownSymbol = "_";
  strikethroughMarkdownSymbol = "~";

  // Resources
  app = "app";
  account = "account";
  campaigner = "campaigner";
  campaign = "campaign";
  contact = "contact";
  social = "social";
  billing = "billing";

  // Permissions
  adminPermission = "admin";
  selfPermission = "self";
  fetchPermission = "fetch";
  addPermission = "add";
  updatePermission = "update";
  uploadPermission = "upload";
  deletePermission = "delete";

  //Social page
  emptyContentAndImageError =
    "Please either enter content or upload image or both";

  // Email Campaign Types
  EMAIL_REGULAR_CAMPAIGN = "regular";
  EMAIL_AB_CAMPAIGN = "ab";

  //Info Messages
  Link_Tracking_Table_Info =
    "Observed the total count of clicks for the specified link.";
  Retargetted_Campaign_Table_Info =
    "This feature empowers users to take targeted actions based on recipient engagement (opens or clicks) with the campaign.";
}

let strings = new StringConstants();
export default strings;
