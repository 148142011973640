import { Box, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { CustomInput, NewCustomButton } from "global/components";
import strings from "global/constants/StringConstants";
import campaignDetailsSectionsStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/components/CampaignDetailsSections/CampaignDetailsSections.style";
import { useState } from "react";
import { isTruthy } from "helpers/methods";

interface CustomProps {
  setCampaignData: Function;
  campaignData: any;
  emailUsers: string[];
}

const SubjectSection = (props: CustomProps) => {
  const classes = campaignDetailsSectionsStyles;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [subject, setSubject] = useState({
    value: props.campaignData?.subject?.value,
    error: "",
  });

  const onChangeHandler = (event: any) => {
    setSubject({ ...subject, value: event.target.value });
  };

  const onSaveHandler = () => {
    if (isTruthy(subject.value)) {
      props.setCampaignData({
        ...props.campaignData,
        subject: {
          value: subject.value,
          error: "",
        },
      });
      setIsEditMode(false);
    } else {
      setSubject({
        ...subject,
        error: "Please enter the subject line",
      });
    }
  };

  const onCancelHandler = () => {
    setSubject({ value: props.campaignData?.subject?.value, error: "" });
    setIsEditMode(false);
  };

  const getSaveButton = () => {
    return (
      <NewCustomButton
        id="from_save_button"
        label="Save"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onSaveHandler();
        }}
      />
    );
  };

  const getCancelButton = () => {
    return (
      <NewCustomButton
        id="from_cancel_button"
        label="Cancel"
        customClasses={classes.buttonStyles}
        onClick={() => {
          onCancelHandler();
        }}
      />
    );
  };

  const editModeView = () => {
    return (
      <>
        <Box mb={1}>
          <Typography sx={classes.headerLabel}>Subject</Typography>
          <Typography sx={classes.headerSubDescription}>
            What's the subject line for this email?
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10} xl={10} lg={10}>
            <CustomInput
              id="step2_subject_field"
              type="type"
              name="subject"
              placeHolder="Enter your subject"
              propsToInputElement={{ maxLength: strings.SUBJECT_LINE_SIZE }}
              value={subject.value}
              onChange={onChangeHandler}
              error={subject.error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>
                      {subject?.value?.length} / {strings.SUBJECT_LINE_SIZE}
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Typography sx={classes.description}>
          A great subject line gives your audience a reason to open your email.
        </Typography>
        <Stack flexDirection="row" gap={1} mt={1}>
          {getSaveButton()}
          {getCancelButton()}
        </Stack>
      </>
    );
  };

  const viewModeView = () => {
    return (
      <Box mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography sx={classes.headerLabel}>Subject</Typography>
          <Typography sx={classes.headerSubDescription}>
            {props.campaignData.subject.value}
          </Typography>
        </Box>
        <Box>
          <NewCustomButton
            id="subject_edit_button"
            label="Edit Subject"
            onClick={() => setIsEditMode(true)}
            customClasses={{ width: "130px" }}
          />
        </Box>
      </Box>
    );
  };

  return <Box>{isEditMode ? editModeView() : viewModeView()}</Box>;
};

export default SubjectSection;
