import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { mediumFont } from "utils/styles";
import Arrow from "assets/icons/pink_arrow.svg";
import { CustomButton, CustomDialog } from "global/components";
import CustomDatePickerStyles from "global/components/CustomDatePicker/CustomDatePicker.styles";
import { openErrorNotification } from "helpers/methods";
import { useState } from "react";
import { changeIntervalAction, selectInterval } from "redux/persistSlice";
import { useAppSelector } from "utils/hooks";
import { useDispatch } from "react-redux";
import strings from "global/constants/StringConstants";

interface CustomDatePickerTypes {
  customWidth?: any;
  minDate?: Date;
  maxDate?: Date;
  pastMonths?: number;
  isDatePickerOpen: boolean;
  setIsDatePickerOpen: Function;
  callBackFunction?: Function;
}

const CustomDatePicker = (props: CustomDatePickerTypes) => {
  const classes = CustomDatePickerStyles;
  const interval = useAppSelector(selectInterval);
  const dispatch = useDispatch();
  const pastMonths = props.pastMonths ? props.pastMonths : 1;
  const currentDate = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(currentDate.getMonth() - pastMonths);
  const [dateRange, setDateRange] = useState<any>({
    fromDate: interval.fromDate,
    toDate: interval.toDate,
  });

  const handleDateValidation = (fromDate: any, toDate: any) => {
    if (!fromDate || !toDate) {
      return false;
    }
    const from = moment(fromDate);
    const to = moment(toDate);

    return from.isSameOrBefore(to);
  };

  const handleDateRangeChange = (newValue: Date, name: string) => {
    setDateRange((prev: any) => ({
      ...prev,
      [name]: moment(newValue).format("L").replaceAll("/", "-"),
    }));
  };

  const handleApplyDates = () => {
    if (handleDateValidation(dateRange?.fromDate, dateRange?.toDate)) {
      const newInterval = {
        label: strings.custom,
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
      };
      dispatch(changeIntervalAction({ ...newInterval }));
      props.setIsDatePickerOpen(false);
      props.callBackFunction?.({ ...newInterval });
    } else {
      openErrorNotification(
        "Invalid date range: start date should be before end date!"
      );
    }
  };

  const handleDialogClose = () => {
    props.setIsDatePickerOpen(false);
  };

  const getCustomDatePickerHeader = () => {
    return (
      <Box display={"flex"} justifyContent={"space-between"} m={2}>
        <Typography
          sx={{
            ...mediumFont,
            fontSize: "20px",
          }}
          mr={8}
        >
          From Date
        </Typography>
        <img src={Arrow} alt="arrow" width={"50px"} />
        <Typography
          sx={{
            ...mediumFont,
            fontSize: "20px",
          }}
          ml={10}
        >
          To Date
        </Typography>
      </Box>
    );
  };

  const getCustomDatePickerBody = () => {
    return <>{getCustomPickers()}</>;
  };

  const getCustomDatePickerFooter = () => {
    return (
      <Grid container>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            label="Cancel"
            customClasses={classes.cancelButtonStyle}
            onClick={() => handleDialogClose()}
            id="cancel_button"
          />
          <CustomButton
            label="Apply"
            onClick={() => handleApplyDates()}
            id="apply_button"
          />
        </Box>
      </Grid>
    );
  };

  const getCustomPickers = () => {
    return (
      <Stack
        display={"flex"}
        direction={"row"}
        justifyContent={"space-between"}
      >
        {/* From date */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            value={
              dateRange?.fromDate && moment(dateRange?.fromDate).isValid()
                ? moment(dateRange.fromDate).toDate()
                : null
            }
            onChange={(newValue: any) =>
              handleDateRangeChange(newValue, "fromDate")
            }
            disableFuture
            minDate={oneMonthAgo}
            maxDate={currentDate}
            components={{ ActionBar: () => null }}
            sx={classes.datePickerStyles}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              border: "1px solid #C11A85",
              height: "390px",
              margin: "0 20px",
            }}
          />
          {/* To date */}
          <StaticDatePicker
            disableFuture
            minDate={new Date(dateRange.fromDate)}
            maxDate={currentDate}
            value={
              dateRange?.toDate && moment(dateRange?.toDate).isValid()
                ? moment(dateRange.toDate).toDate()
                : null
            }
            onChange={(newValue: any) =>
              handleDateRangeChange(newValue, "toDate")
            }
            components={{ ActionBar: () => null }}
            sx={classes.datePickerStyles}
          />
        </LocalizationProvider>
      </Stack>
    );
  };

  return (
    <CustomDialog
      headerBackground="#F9E9F3"
      dialogHeaderContent={getCustomDatePickerHeader()}
      isDialogOpen={props.isDatePickerOpen ?? false}
      dialogBodyContent={getCustomDatePickerBody()}
      dialogTitleContent={<></>}
      dialogFooterContent={getCustomDatePickerFooter()}
      handleDialogClose={handleDialogClose}
      width={"fit-content"}
    />
  );
};

export default CustomDatePicker;
