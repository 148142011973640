import strings from "global/constants/StringConstants";
import { isTruthy } from "helpers/methods";

export const initialCardUserDetails = (cardName: string, userEmail: string) => {
  return {
    cardName: {
      value: cardName ?? "",
      error: "",
    },
    userEmail: {
      value: userEmail ?? "",
      error: "",
    },
  };
};

export const onboardValidation = (formFields: any) => {
  let erroneousFormFields = { ...formFields };
  let isValid = true;
  const cardName = erroneousFormFields.cardName.value;
  const userEmail = erroneousFormFields.userEmail.value;
  if (!isTruthy(cardName)) {
    erroneousFormFields.cardName.error = "Please enter the card name.";
    isValid = false;
  }
  if (!isTruthy(userEmail)) {
    erroneousFormFields.userEmail.error = "Please enter the email.";
    isValid = false;
  }
  if (!strings.regex.test(userEmail)) {
    erroneousFormFields.userEmail.error = "Please enter a valid email.";
    isValid = false;
  }
  return { isValid, erroneousFormFields };
};
