import { useState, useEffect } from "react";
import moment from "moment";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { CustomButton, CustomDialog, CustomInput } from "global/components";
import scheduleTimeImage from "assets/images/schedule.svg";
import StepThreeStyles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepThree/StepThree.styles";
import { isTruthy } from "helpers/methods";

interface CustomProps {
  scheduleTimeModalOpen: boolean;
  setScheduleTimeModalOpen: any;
  campaignData: any;
  setCampaignData: any;
  handleSendCampaign: any;
}

const classes = StepThreeStyles;

export const ScheduleTimeModal = ({
  scheduleTimeModalOpen,
  setScheduleTimeModalOpen,
  campaignData,
  setCampaignData,
  handleSendCampaign,
}: CustomProps) => {
  const [selectedValue, setSelectedValue] = useState<string>("right");

  useEffect(() => {
    if (moment(campaignData?.scheduleTime?.value).isBefore(moment())) {
      setCampaignData({
        ...campaignData,
        scheduleTime: {
          value: "",
          error: "",
        },
      });
      setSelectedValue("right");
    }
  }, [scheduleTimeModalOpen]);

  const handleChange = (event: any) => {
    const checkBoxValue = event.target.value;
    if (checkBoxValue === "right") {
      setCampaignData({
        ...campaignData,
        scheduleTime: {
          value: "",
          error: "",
        },
      });
    }
    setSelectedValue(checkBoxValue);
  };

  const handleScheduleTimeChange = (event: any) => {
    let error = "";
    if (
      isTruthy(event.target.value) &&
      moment(event.target.value).isBefore(moment())
    ) {
      error = "Scheduled time cannot be before today's date or time";
    }
    setCampaignData({
      ...campaignData,
      scheduleTime: {
        value: event.target.value,
        error: error,
      },
    });
  };

  const finalButtonValidation = () => {
    let isValid = true;
    if (!campaignData?.scheduleTime?.value && selectedValue === "future") {
      setCampaignData({
        ...campaignData,
        scheduleTime: {
          value: "",
          error: "Please enter schedule time",
        },
      });
      isValid = false;
    }
    return isValid;
  };

  const modalHeader = () => {
    return (
      <Box display={"flex"}>
        <img src={scheduleTimeImage} alt="image not found!" />
      </Box>
    );
  };

  const scheduleTimeDialogTitle = () => {
    return (
      <>
        <Typography sx={classes.boldFonts}>Choose sending time</Typography>
        <Box sx={classes.scheduleModalTitle}>
          <RadioGroup value={selectedValue} onChange={handleChange}>
            <FormControlLabel
              value="right"
              control={<Radio sx={classes.radioChecked} />}
              label="Send it right away"
              id="schedule_model_right"
            />
            <FormControlLabel
              value="future"
              control={<Radio sx={classes.radioChecked} />}
              label="Schedule for future"
              id="schedule_model_future"
            />
          </RadioGroup>
        </Box>
      </>
    );
  };

  const scheduleTimeBody = () => {
    return (
      <CustomInput
        disabled={selectedValue === "future" ? false : true}
        type="datetime-local"
        id="step2_schedule_time_field"
        name="scheduleTime"
        propsToInputElement={{
          min: moment().format("YYYY-MM-DDTkk:mm"),
        }}
        value={campaignData?.scheduleTime?.value}
        onChange={handleScheduleTimeChange}
        error={campaignData?.scheduleTime?.error}
      />
    );
  };

  const scheduleDialogFooter = () => {
    return (
      <Grid container sx={classes.dialogFooterWrapper}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            id="step2_schedule_cancel_button"
            label="Cancel"
            onClick={() => setScheduleTimeModalOpen(false)}
            customClasses={classes.cancelButtonStyle}
          />
          <CustomButton
            id="step2_schedule_submit_button"
            label={
              selectedValue === "future" ? "Schedule Campaign" : "Send Campaign"
            }
            onClick={() => {
              if (finalButtonValidation()) {
                handleSendCampaign();
              }
            }}
            disabled={campaignData?.scheduleTime?.error}
            customClasses={classes.scheduleBtn}
          />
        </Box>
      </Grid>
    );
  };

  return (
    <CustomDialog
      isDialogOpen={scheduleTimeModalOpen}
      closable
      closeButtonVisibility
      handleDialogClose={() => setScheduleTimeModalOpen(false)}
      dialogHeaderContent={modalHeader()}
      dialogTitleContent={scheduleTimeDialogTitle()}
      dialogBodyContent={scheduleTimeBody()}
      dialogFooterContent={scheduleDialogFooter()}
      width={"500px"}
    />
  );
};
