import { useEffect, useState } from "react";
import { useTitle } from "utils/UseTitle";
import { useLocation } from "react-router-dom";
import {
  changeIntegrationAppNameAction,
  selectIntegrationAppName,
} from "redux/persistSlice";
import { useDispatch } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import IntergrationHeader from "screens/Integration/IntegrationHeader";
import metaIcon from "assets/icons/meta.svg";
import salesforceIcon from "assets/icons/salesforce.svg";
import whatsAppIcon from "assets/icons/whatsapp.svg";
import linkedinIcon from "assets/images/linkedin.svg";
import Facebook from "screens/Integration/component/Facebook";
import LinkedIn from "screens/Integration/component/LinkedIn";
import strings from "global/constants/StringConstants";
import {
  openSuccessNotification,
  openErrorNotification,
  isTruthy,
  getFacebookRedirectionURL,
} from "helpers/methods";
import {
  authUserToFB,
  getIntegratedAppNames,
} from "screens/Integration/component/FacebookService";
import notifiers from "global/constants/NotificationConstants";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import IntegrationStyles from "screens/Integration/Integration.styles";
import { CustomAppHeader } from "global/components";
import checkIcon from "assets/icons/check.svg";
import Salesforce from "screens/Integration/component/Salesforce";
import { useAppSelector } from "utils/hooks";
import {
  authUserToSalesforce,
  getSalesforceIntegrationStatus,
} from "screens/Integration/component/SalesforceServices";
import WhatsApp from "screens/Integration/component/WhatsApp";
import { authUserToWhatsApp } from "screens/Integration/component/WhatsAppServices";
import urls from "global/constants/UrlConstants";
import Instagram from "screens/Integration/component/Instagram";

const Integration = () => {
  useTitle(strings.Integration);
  const classes = IntegrationStyles;
  const dispatch = useDispatch();
  const integrationAppName = useAppSelector(selectIntegrationAppName);
  const urlParams = useLocation().search;
  const code: any = new URLSearchParams(urlParams).get("code");
  const [integrationApp, setIntegrationApp] = useState(strings.All);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFacebookIntegrated, setIsFacebookIntegrated] =
    useState<boolean>(false);
  const [isSalesforceIntegrated, setIsSalesforceIntegrated] =
    useState<boolean>(false);
  const [isWhatsAppIntegrated, setIsWhatsAppIntegrated] =
    useState<boolean>(false);
  const [isInstagramIntegrated, setIsInstagramIntegrated] =
    useState<boolean>(false);
  const [isLinkedinIntegrated, setIsLinkedinIntegrated] =
    useState<boolean>(false);
  const [isTwitterIntegrated, setIsTwitterIntegrated] =
    useState<boolean>(false);
  const [unexecutedCampaigns, setUnexecutedCampaigns] = useState<number>(0);

  useEffect(() => {
    appBasedUserAuthentication(code);
  }, [code]);

  useEffect(() => {
    areAppsIntegrated();
  }, []);

  const appBasedUserAuthentication = async (urlCode: any) => {
    if (isTruthy(urlCode)) {
      switch (integrationAppName) {
        case strings.FACEBOOK:
          await facebookAuthUser();
          break;
        case strings.WHATSAPP:
          !urls.PROD && (await whatsAppAuthUser());
          break;
        case strings.SALESFORCE:
          !urls.PROD && (await salesforceAuthUser());
          break;
      }
    }
    dispatch(changeIntegrationAppNameAction(""));
  };

  const facebookAuthUser = async () => {
    try {
      setIsLoading(true);
      const response = await authUserToFB(
        code,
        getFacebookRedirectionURL(window.location.hostname),
        strings.STATE
      );
      openSuccessNotification(
        "Facebook account is successfully integrated. Please go to social tab to manage the integrations."
      );
      setIsFacebookIntegrated(true);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const salesforceAuthUser = async () => {
    try {
      setIsLoading(true);
      const response: any = await authUserToSalesforce(code);
      setIsSalesforceIntegrated(true);
      openSuccessNotification(response.message);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const previousStepHandler = () => {
    setIntegrationApp(strings.All);
    dispatch(changeIntegrationAppNameAction(""));
  };

  const setIntegrationState = (
    appName: string,
    activeAppArray: any[],
    setState: Function
  ) => {
    setState(
      activeAppArray.some((item: any) => item === appName)
    );
  };

  const whatsAppAuthUser = async () => {
    try {
      setIsLoading(true);
      await authUserToWhatsApp(
        code,
        getFacebookRedirectionURL(window.location.hostname),
        strings.STATE
      );
      openSuccessNotification(
        "WhatsApp account is successfully integrated. Please go to social tab to manage the integrations."
      );
      setIsWhatsAppIntegrated(true);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const areAppsIntegrated = async () => {
    try {
      setIsLoading(true);
      const isActiveArray = await getIntegratedAppNames();
      setIntegrationState(
        strings.FACEBOOK,
        isActiveArray,
        setIsFacebookIntegrated
      );
      !urls.PROD &&
        setIntegrationState(
          strings.WHATSAPP,
          isActiveArray,
          setIsWhatsAppIntegrated
        );
      !urls.PROD &&
        setIntegrationState(
          strings.SALESFORCE,
          isActiveArray,
          setIsSalesforceIntegrated
        );
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getIntegrationAppsDisplayList = (
    integrationAppName: string,
    image: string,
    displayText: string,
    longText: string,
    integrated: any
  ) => {
    return (
      <Box
        sx={{ cursor: "pointer" }}
        onClick={() => setIntegrationApp(integrationAppName)}
      >
        <Box sx={classes.socialCardStyle}>
          <Box>
            <Box
              component="img"
              src={image}
              height={50}
              width={50}
              mb={2}
              mt={1}
            />
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              fontWeight={"bold"}
            >
              {displayText}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {longText}
            </Typography>
          </Box>
          <Box>{integrated}</Box>
        </Box>
      </Box>
    );
  };

  const getIntegratedMessageDisplay = () => {
    return (
      <Box display={"flex"} alignContent="center" gap={1}>
        <Box component={"img"} src={checkIcon} mt={2} />{" "}
        <Box component={"div"} sx={classes.integratedMsg}>
          Integrated{" "}
        </Box>
      </Box>
    );
  };

  // const fetchLinkedInAccessTokenHandler = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await linkedInAccessToken(linkedInResponCode);
  //     openSuccessNotification(response.value);
  //     setIsLoading(false);
  //   } catch (error: any) {
  //     setIsLoading(false);
  //     openErrorNotification(
  //       isTruthy(error.errorMessage)
  //         ? error.errorMessage
  //         : notifiers.GENERIC_ERROR
  //     );
  //   }
  // };
  const cardData = [
    {
      integrationAppName: strings.FACEBOOK,
      shouldDisplay: strings.isFacebookReleased,
      image: metaIcon,
      displayText: "Meta",
      longText:
        "Improve your customer service and streamline your social media management using Mailzzy's integration with Meta.",
      // "Upgrade your customer service game and simplify your social media management with Mailzzy's Facebook integration",
      // "Connect Facebook  to create your ad campaigns directly from Mailzzy, saving time and increasing efficiency.",

      integrated: isFacebookIntegrated && getIntegratedMessageDisplay(),
    },
    ...(!urls.PROD
      ? [
          {
            integrationAppName: strings.SALESFORCE,
            shouldDisplay: strings.isSalesforceReleased,
            image: salesforceIcon,
            displayText: "SalesForce",
            longText:
              "Integrate the SalesForce with Mailzzy to utilize the power of both.",
            integrated: isSalesforceIntegrated && getIntegratedMessageDisplay(),
          },
          {
            integrationAppName: strings.WHATSAPP,
            shouldDisplay: strings.isWhatsappReleased,
            image: whatsAppIcon,
            displayText: "WhatsApp",
            longText:
              "Improve your customer service and streamline your social media management using Mailzzy's integration with WhatsApp.",
            integrated: isWhatsAppIntegrated && getIntegratedMessageDisplay(),
          },
        ]
      : []),
    {
      integrationAppName: strings.LINKEDIN,
      shouldDisplay: strings.isLinkedinReleased,
      image: linkedinIcon,
      displayText: "LinkdedIn",
      longText:
        "Make smarter marketing decisions when you sync your LinkedIn Lead Gen Forms with Mailzzy.",
      integrated: isLinkedinIntegrated && getIntegratedMessageDisplay(),
    },
  ];

  const socialCardHandler = () => {
    switch (integrationApp) {
      case strings.FACEBOOK:
        return (
          <Facebook
            previousStepHandler={previousStepHandler}
            isFacebookIntegrated={isFacebookIntegrated}
            setIsFacebookIntegrated={setIsFacebookIntegrated}
            isLoggedInSocialMedia={areAppsIntegrated}
            unexecutedCampaigns={unexecutedCampaigns}
            setUnexecutedCampaigns={setUnexecutedCampaigns}
          />
        );
      case strings.WHATSAPP:
        return !urls.PROD ? (
          <WhatsApp
            previousStepHandler={previousStepHandler}
            isWhatsAppIntegrated={isWhatsAppIntegrated}
            setIsWhatsAppIntegrated={setIsWhatsAppIntegrated}
            isLoggedInSocialMedia={areAppsIntegrated}
          />
        ) : null;
      case strings.SALESFORCE:
        return !urls.PROD ? (
          <Salesforce
            previousStepHandler={previousStepHandler}
            isSalesforceIntegrated={isSalesforceIntegrated}
            setIsSalesforceIntegrated={setIsSalesforceIntegrated}
            isAppAuthenticated={areAppsIntegrated}
            setIsLoading={setIsLoading}
          />
        ) : null;
      case strings.INSTAGRAM:
        return <Instagram />;
      case strings.LINKEDIN:
        return <LinkedIn previousStep={previousStepHandler} />;
      case strings.All:
        return cardData
          .filter((cardItem: any) => cardItem.shouldDisplay)
          .map((cardItem: any) => {
            return getIntegrationAppsDisplayList(
              cardItem.integrationAppName,
              cardItem.image,
              cardItem.displayText,
              cardItem.longText,
              cardItem.integrated
            );
          });
      default:
        break;
    }
  };

  return (
    <>
      <CustomAppHeader>
        <IntergrationHeader />
      </CustomAppHeader>
      <Grid sx={{ m: 5 }} xs={12} sm={12} lg={12} xl={12}>
        <Box>
          {integrationApp === strings.All && (
            <Typography fontWeight="bold" fontSize={23} mt={2} ml={1} mb={3}>
              Channels
            </Typography>
          )}

          <Grid item container gap={5}>
            {socialCardHandler()}
          </Grid>
        </Box>
        <CustomLoader isLoading={isLoading} />
      </Grid>
    </>
  );
};

export default Integration;
