import {
  boldFont,
  centerItemFlex,
  getRelativeFontSize,
  mainContainer,
  regularFont,
  rowItemFlex,
  theme,
  pinkDarkColor,
  inputLabelRequiredColor,
  mediumFont,
  buttonWhiteBg,
  purplePrimaryColor,
  primaryHeadingColor,
  purpleThemedSelectComponent,
  pinkThemedMenuItems,
} from "utils/styles";

export const campaignGroupStyles = {
  mainSection: {
    ...mainContainer,
    marginTop: theme.spacing(1),
  },
  headerLeftStyling: {
    marginLeft: "20px",
    marginTop: "4px",
    display: "flex",
  },
  header: {
    position: "sticky",
    marginBottom: 1,
  },
  body: {
    height: "85vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  box: {
    padding: "10px",
    display: "flex",
  },
  dividerStyle: {
    border: "1px solid #dedede",
    height: "11px",
    marginTop: "6px",
  },
  rowStyling: {
    ...rowItemFlex,
    padding: "6px",
    gap: "10px",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "10px",
    },
  },
  buttonGroupWrapper: {
    paddingRight: "10px",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
    },
  },
  inputRowStyling: {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px 10px 0",
    gap: "20px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
    },
  },
  loader: {
    ...centerItemFlex,
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0),
    },
  },
  serviceReport: {
    ...centerItemFlex,
    opacity: 0.4,
    margin: theme.spacing(8),
  },
  service: {
    ...centerItemFlex,
    textAlign: "center",
  },
  reportImg: {
    ...centerItemFlex,
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      ...centerItemFlex,
      marginTop: theme.spacing(0),
    },
  },
  selReport: {
    ...centerItemFlex,
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
    opacity: 0.4,
    [theme.breakpoints.down("sm")]: {
      ...centerItemFlex,
      fontSize: getRelativeFontSize(10),
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
  },
  lastRunTest: {
    ...boldFont,
    fontSize: getRelativeFontSize(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      fontSize: getRelativeFontSize(),
    },
  },
  dropdown: {
    height: 40,
    width: 160,
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    color: "#22222C",
    border: 0,
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.03)",
    "&:hover": {
      border: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "45vw",
    },
  },
  GraphBox: {
    background: "#22222C",
    borderRadius: "40px",
    width: "380px",
    margin: "auto",
    marginTop: 4,
    marginLeft: 2,
    height: "311px",
    padding: 1,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      width: "320px",
      height: "260px",
      borderRadius: "30px",
    },
  },
  colorBox: {
    borderRadius: "50%",
    backgroundColor: "#EFA185",
    height: "10px",
    width: "10px",
    mr: 1,
  },
  testResults: {
    ...boldFont,
    fontSize: getRelativeFontSize(14),
    textAlign: "center",
    mt: 1,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(6),
    },
  },
  title: {
    ...boldFont,
    fontSize: getRelativeFontSize(22),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(8),
    },
  },
  colorbox: {
    backgroundColor: "#ffffff",
    borderRadius: "40%",
    width: "10",
    height: "10",
    margin: "auto",
  },
  graphText: {
    color: "#FFFFFF",
    textAlign: "center",
  },
  graph: {
    alignItems: "center",
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  graphStack: {
    ...centerItemFlex,
  },
  seleniumSection: {
    [theme.breakpoints.up("md")]: {
      marginTop: "10px",
    },
  },
  selBody: {
    [theme.breakpoints.up("md")]: { marginTop: 2 },
  },
  seleniumHeaderMobile: {
    display: "flex",
    alignItems: "center",
  },
  seleniumHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  sonarBody: {
    borderRadius: "20px",
    marginTop: "20px",
  },
  refreshBtn: {
    height: "47px",
    backgroundColor: "#ffffff",
    border: "1px solid #ced4da",
    minWidth: "50px",
    borderRadius: "12px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContact: "center",
    fontSize: "50px",
    marginTop: "8px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  dropDownStyle: {
    ...regularFont,
    ...purpleThemedSelectComponent,
    minWidth: "150px",
    height: "47px",
    borderRadius: "15px",
    backgroundColor: "#FFFFFF",
    marginTop: "8px",
  },
  optionStyle: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  rowColor: {
    ...mediumFont,
    color: pinkDarkColor,
    fontSize: "14px",
    padding: "0",
    display: "flex",
    textAlign: "left",
    wordBreak: "break-all",
    minWidth: "100px",
  },
  titleLeft: {
    ...boldFont,
  },
  iconLeft: {
    color: "#faad14",
  },
  textBold: {
    ...boldFont,
    wordBreak: "break-all",
    marginLeft: "5px",
  },
  fontText: {
    ...regularFont,
  },
  fontTexts: {
    ...regularFont,
    width: "13rem",
    textAlign: "center",
    wordBreak: "break-word",
  },
  dialogContent: {
    padding: "10px 30px",
    color: "rgba(0,0,0, .85)",
    fontSize: "14px",
    marginTop: "10px",
  },
  dialogFooter: {
    ...centerItemFlex,
    gap: "10px",
    paddingBottom: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& button": {
      width: "120px",
    },
  },
  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    width: "100%",
    height: "50px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        borderColor: "#4B0150",
      },
    },
  },
  mainCardInputsSection: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  inputSection: {
    display: "flex",
    flexWrap: "wrap",
  },
  form: {
    mr: 1,
    display: { xs: "flex", sm: "flex" },
  },
  centerItemFlex: {
    ...centerItemFlex,
  },
  dialogTitleWrapper: {
    display: "flex",
    alignItemCenter: "center",
    gap: "20px",
    flexDirection: "column",
  },
  titleRight: {
    color: "rgba(0,0,0,.85)",
    ...boldFont,
    fontSize: getRelativeFontSize(14),
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
  },
  groupNameBox: {
    background: "#F8F8F8",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
  },
  spanStyle: {
    ...boldFont,
    marginLeft: "5px",
  },
  deleteBtn: {
    fontSize: "20px",
    background: "none",
    border: "none",
  },
  dropzone: {
    maxHeight: "50px",
  },
  fileNameWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0",
    padding: "0 50px",
  },
  warningContent: {
    color: "red",
    fontSize: getRelativeFontSize(),
    margin: "10px 0",
    ...mediumFont,
  },
  startStyle: {
    color: inputLabelRequiredColor,
    marginRight: "5px",
  },
  dialogTitle: {
    ...boldFont,
    marginBottom: "10px",
  },
  groupBtn: {
    padding: "0",
    margin: "0",
  },
  star: {
    color: inputLabelRequiredColor,
  },
  input: {
    fontSize: getRelativeFontSize(7),
    color: "black",
    fontColor: theme.palette.common.black,
    ...boldFont,
    marginTop: "10px",
    display: "flex",
    "& .star": {
      color: inputLabelRequiredColor,
    },
  },
  dropzoneAreaBase: {
    "& .MuiDropzoneArea-root": {
      width: "100%",
      minHeight: "70px",
      fontSize: "12px",
      borderRadius: "10px",
      borderWidth: "3px",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: getRelativeFontSize(2),
      ...mediumFont,
    },
  },
  previewChip: {
    marginTop: 1,
  },
  modalTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(13),
    textAlign: "center",
  },
  inputsSectionDropdowns: {
    display: "flex",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      gap: "10px",
    },
  },
  cancelButtonStyle: {
    color: "#212121 !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E7E7E7",
    borderRadius: "10px",
    "&:hover": {
      background: "none",
    },
  },
  headerBackgroundColor: {
    backgroundColor: "#fcf5ff",
    padding: "30px 20px 25px 20px",
  },
  mainCardHeading: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
    },
  },
} as const;
