import React from "react";
import { CustomButton, CustomDialog } from "global/components";
import cardImage from "assets/images/Group.svg";
import { Box, Typography } from "@mui/material";
import CampaignsStyles from "screens/CampaignsNew/Campaigns/Campaigns.styles";
import { store } from "utils/store";

interface CancelSunscriptionModelProps {
  setOpenModal: Function;
  openModal: boolean;
  handleConfirm: () => void;
}

const CancelSubscriptionModal = ({
  openModal,
  setOpenModal,
  handleConfirm,
}: CancelSunscriptionModelProps) => {
  const classes = CampaignsStyles;

  const handleCloseModel = () => {
    setOpenModal(false);
  };

  const dialogTitleContent = () => {
    return (
      <>
        <Box sx={classes.dialogTitleWrapper}>
          <Box sx={classes.titleRight}>Plan Cancellation</Box>
        </Box>
      </>
    );
  };

  const dialogBody = () => (
    <>
      <Box>
        <Typography
          sx={{ fontSize: "20px", color: "#212121", fontWeight: 700 }}
        >
          Hey {store.getState().auth.userName}!
        </Typography>
        <br />
        <Typography sx={{ color: "#666666" }}>
          We are happy to have you on our platform.
        </Typography>
        <Typography sx={{ color: "#666666" }}>
          Are you sure you want to opt for cancellation?
        </Typography>
        <Typography sx={{ color: "#666666" }}>
          If yes please click on “YES” or else click on "NO"
        </Typography>
        <br />

        <Typography color={"#000000"} fontSize={"17px"} fontWeight={500}>
          <span style={{ color: "#BB0070", fontSize: "18px", fontWeight: 700 }}>
            Note:
          </span>{" "}
          On cancellation your subscription will cancelled but you can use the
          application for remaining days of your plan.
        </Typography>
      </Box>
    </>
  );

  const dialogContent = () => {
    return (
      <>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            label="No"
            customClasses={classes.buttonWhiteBg}
            onClick={() => handleCloseModel()}
            id="plan_cancel_modal_no_button"
          />
          <CustomButton
            label="Yes"
            onClick={() => handleConfirm()}
            id="plan_cancel_modal_yes_button"
          />
        </Box>
      </>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={cardImage} alt="delete Image" />
      </Box>
    );
  };

  return (
    <>
      <CustomDialog
        dialogHeaderContent={dialogHeaderContent()}
        isDialogOpen={openModal}
        closable
        closeButtonVisibility
        handleDialogClose={handleCloseModel}
        dialogTitleContent={dialogTitleContent()}
        dialogBodyContent={dialogBody()}
        dialogFooterContent={dialogContent()}
        width="500px"
      />
    </>
  );
};
export default CancelSubscriptionModal;
