import { mediumFont } from "utils/styles";

const CustomDatePickerStyles = {
  datePickerStyles: {
    "& .MuiDateCalendar-root" :{
      height: "350px !important"
    },
    "& .MuiPickersDay-root.Mui-selected": {
      backgroundColor: "#C11A85 !important",
    },
    "& .MuiTypography-overline": {
      display: "none !important",
    },
    "& .MuiDatePickerToolbar-title": {
      ...mediumFont,
      fontSize: "18px !important",
    },
  },
  dialogFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    width: "100%",
    "& button": {
      width: "100px",
    },
  },
  cancelButtonStyle: {
    color: "#212121 !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E7E7E7",
    // borderRadius: "10px",
    "&:hover": {
      background: "none",
    },
  },
};
export default CustomDatePickerStyles;
