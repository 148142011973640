import strings from "global/constants/StringConstants";
import urls from "global/constants/UrlConstants";
import {
  getCallParams,
  getSearchedCallParams,
  makeCall,
  makeCallFile,
} from "utils/service";

export const getAssigned = async (page: number, limit: number) => {
  const url = `${urls.ASSIGNED}/limit/${limit}/page/${page}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getAssignedCount = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.ASSIGNED_COUNT, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getRaised = async (page: number, limit: number) => {
  const url = `${urls.RAISED}/limit/${limit}/page/${page}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getRaisedCount = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.RAISED_COUNT, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCompleted = async (page: number, limit: number) => {
  const url = `${urls.COMPLETED}/limit/${limit}/page/${page}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCompletedCount = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.COMPLETED_COUNT, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getOpen = async (page: number, limit: number) => {
  const url = `${urls.OPEN}/limit/${limit}/page/${page}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getOpenCount = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.OPEN_COUNT, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

const getSearchUrl = (tab: string, page: number, limit: number) => {
  if (tab === strings.assignedToMe) {
    return `${urls.ASSIGNED_SEARCH_DATA}/limit/${limit}/page/${page}`;
  } else if (tab === strings.raisedByMe) {
    return `${urls.RAISED_SEARCH_DATA}/limit/${limit}/page/${page}`;
  } else if (tab === strings.completedTickets) {
    return `${urls.COMPLETED_SEARCH_DATA}/limit/${limit}/page/${page}`;
  } else {
    return `${urls.OPEN_SEARCH_DATA}/limit/${limit}/page/${page}`;
  }
};

export const getSearchParams = async (
  tab: string,
  search: string,
  page: number,
  limit: number
) => {
  const url = getSearchUrl(tab, page, limit);
  try {
    const callParams = await getSearchedCallParams("POST", search);
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

const getSearchCountUrl = (tab: string, search: string) => {
  switch (tab) {
    case strings.assignedToMe:
      return urls.ASSIGNED_SEARCH_COUNT;
    case strings.raisedByMe:
      return urls.RAISED_SEARCH_COUNT;
    case strings.completedTickets:
      return urls.COMPLETED_SEARCH_COUNT;
    case strings.openTickets:
      return urls.OPEN_SEARCH_COUNT;
    default:
      return urls.ASSIGNED_SEARCH_COUNT;
  }
};

export const getSearchCountParams = async (tab: string, search: string) => {
  const url = getSearchCountUrl(tab, search);
  try {
    const callParams = await getSearchedCallParams("POST", search);
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateTicket = async (task: any) => {
  try {
    const callParams = await getCallParams("PATCH", task);
    const response: any = await makeCall(urls.UPDATE_TASK, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const completeTicket = async (task: any, complete: boolean) => {
  try {
    const callParams = await getCallParams("POST", task);
    const response = await makeCall(urls.COMPLETE + "/" + complete, callParams);
    return response;
  } catch (error) {
    throw error;
  }
};

export const assigneeGroupName = async (processName: string) => {
  const url = `${urls.ASSIGNEE_GROUP_NAME}/${processName}`;
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error) {
    throw error;
  }
};

export const reAssigneeTicket = async (task: any) => {
  try {
    const callParams = await getCallParams("POST", task);
    const response: any = await makeCall(urls.ASSIGNEE, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const viewUploadedDocument = async (apiPayload: any) => {
  try {
    const callParams = await getCallParams("POST", apiPayload);
    const response: any = await makeCall(urls.VIEW_FILE, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const downloadDocument = async (data: any) => {
  try {
    const callParams = await getCallParams("POST", data);
    const response: any = await makeCallFile(urls.DOWNLOAD_FILE, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getIsProcessAdmin = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response = await makeCall(urls.PROCESS_ADMIN, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const doFetchAllTicketsMetrics = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.FETCH_TICKETS_METRICS,
      callParams
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const doFetchAllTicketsByStatus = async (
  status: string,
  limit: number,
  page: number,
  searchText: string
) => {
  const url = `${urls.TICKETS_BY_STATUS}/${status}/limit/${limit}/page/${page}`;
  const body = {
    searchText,
  };
  try {
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const doFetchAllTicketsCountByStatus = async (
  status: string,
  searchText: string
) => {
  const url = `${urls.TICKETS_BY_STATUS}/${status}/count`;
  const body = {
    searchText,
  };
  try {
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
