import { useState } from "react";
import { RouterPrompt } from "global/components/CustomRouterPrompt/RouterPrompt";
import { Box, Typography } from "@mui/material";
import { CustomButton } from "global/components";
import { useHistory } from "react-router-dom";
import SideBarRouterStyle from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/component/SideBarRouterPrompt.styles";

interface SideBarRouterPromptProps {
  isBlocking: boolean;
  showPrompt: boolean;
  setShowPrompt: Function;
  setBlocking: Function;
  draftSubmit: Function;
  isRetargetOrFollowUp: boolean;
}

const SideBarRouterPrompt = ({
  isBlocking,
  showPrompt,
  setShowPrompt,
  setBlocking,
  draftSubmit,
  isRetargetOrFollowUp,
}: SideBarRouterPromptProps) => {
  const routerPromptClasses = SideBarRouterStyle;
  const [routerPromptExitMode, setRouterPromptExitMode] = useState<string>("");
  const history = useHistory();
  const title = () => {
    return (
      <>
        <Box sx={routerPromptClasses.titleWrapper}>
          <Typography sx={routerPromptClasses.titleStyle}>
            Confirmation
          </Typography>
        </Box>
      </>
    );
  };

  const bodyContentRouter = () => {
    return (
      <>
        <Box sx={routerPromptClasses.contentWrapper}>
          <Typography sx={routerPromptClasses.contentStyle}>
            Are you sure you want to exit?
          </Typography>
        </Box>
      </>
    );
  };
  const exit = () => {
    setBlocking(false);
    setTimeout(() => history.push(routerPromptExitMode), 0);
  };
  const getFooter = () => {
    return (
      <>
        <Box sx={routerPromptClasses.footerWrapper}>
          <CustomButton
            onClick={exit}
            label="Exit"
            customClasses={routerPromptClasses.buttonWhiteBg}
            id="router_prompt_exit_button"
          />
          {!isRetargetOrFollowUp && (
            <CustomButton
              id="router_prompt_save_exit_button"
              label="Save & Exit"
              customClasses={routerPromptClasses.buttonGrayBg}
              onClick={(e: any) => {
                setShowPrompt(false);
                setBlocking(false);
                draftSubmit(e);
              }}
            />
          )}
          <CustomButton
            onClick={continueExit}
            label=" Continue"
            id="router_prompt_continue_button"
          />
        </Box>
      </>
    );
  };
  const continueExit = () => {
    setShowPrompt(false);
  };
  return (
    <>
      <RouterPrompt
        when={!!isBlocking}
        titleContent={title()}
        bodyContent={bodyContentRouter()}
        footerContent={getFooter()}
        closeModal={continueExit}
        showPrompt={!!showPrompt}
        setShowPrompt={setShowPrompt}
        setRouterPromptExitMode={setRouterPromptExitMode}
      />
    </>
  );
};

export default SideBarRouterPrompt;
