import { GlobalInterval } from "models/interfaces";

export const options = [
  {
    id: 0,
    label: "Today",
    value: "0",
  },
  {
    id: 2,
    label: "Yesterday",
    value: "1",
  },
  {
    id: 3,
    label: "Last 4 Days",
    value: "4",
  },
  {
    id: 4,
    label: "Last 1 Week",
    value: "7",
  },
  {
    id: 5,
    label: "Last 2 Weeks",
    value: "14",
  },
  {
    id: 6,
    label: "Last 1 Month",
    value: "30",
  },
  {
    id: 7,
    label: "Last 45 days",
    value: "45",
  },
];

export const emailOptions = [
  {
    id: 0,
    label: "Opened",
    value: "open",
  },

  {
    id: 2,
    label: "Clicked",
    value: "click",
  },
];

export const campaignerHeader = [
  {
    name: "Sent By",
    field: "campaignOwner",
    sorting: true,
  },
  {
    name: "Campaign Recipient",
    field: "contactEmail",
    sorting: true,
  },
  {
    name: "Campaign Name",
    field: "campaignName",
    sorting: true,
  },
  {
    name: "Location",
    field: "location",
    sorting: false,
  },
  {
    name: "Sent On",
    field: "actionTimeLines",
    sorting: false,
  },
];

export const campaignStatusOptionUser = [
  {
    id: 0,
    label: "All Campaigns",
    value: "all",
  },
  {
    id: 2,
    label: "My Campaigns",
    value: "my",
  },
];

export const campaignStatusOption = [
  {
    id: 0,
    label: "All Campaigns History",
    value: "all",
  },

  {
    id: 2,
    label: "My Campaigns History",
    value: "my",
  },
  {
    id: 3,
    label: "Team's Campaign History",
    value: "team",
  },
];

export const initialHistoryFilterState = (interval: GlobalInterval) => {
  return {
    page: 1,
    perPageData: 10,
    searchText: "",
    interval,
    emailStatusFilter: emailOptions[0].value,
    campaignerOwnerFilter: campaignStatusOption[1].value,
  };
};
