import { Box } from "@mui/material";
import PreviewTemplateStyles from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/components/PreviewTemplate.styles";
import { pureWhiteColor } from "utils/styles";

interface CustomProps {
  previewHTML: string;
}

const PreviewTemplate = (props: CustomProps) => {
  const classes = PreviewTemplateStyles;

  return (
    <Box sx={classes.container}>
      <Box sx={{ backgroundColor: pureWhiteColor }}>
        <iframe
          srcDoc={props.previewHTML}
          width="100%"
          height="600px"
          frameBorder="0"
        ></iframe>
      </Box>
    </Box>
  );
};

export default PreviewTemplate;
