export const campaignGroupHeader = [
  {
    name: "Group Name",
    field: "name",
    sorting: true,
  },
  {
    name: "Group type",
    field: "type",
    sorting: true,
  },
  {
    name: "Owner",
    field: "owner",
    sorting: true,
  },
  {
    name: "Total No of Contacts",
    field: "recipientCount",
    sorting: true,
  },
  {
    name: "Action",
    field: "action",
    sorting: false,
  },
];

export const campaignGroupsTourSteps = [
  {
    target: "#groups_category_dropdown",
    content: "The Category of campaign groups can be selected from here",
    disableOverlayClose: true,
  },
  {
    target: "#groups_search_field_container",
    content: "The groups can be searched here by name",
    disableOverlayClose: true,
  },
  {
    target: "#groups_download_template_button",
    content:
      "User can download the CSV file to create the file for creating their contact group.",
    disableOverlayClose: true,
  },
  {
    target: "#groups_upload_button",
    content:
      "User can upload the CSV file that they have created from here to create their group",
    disableOverlayClose: true,
  },
  {
    target: "#groups_group_table",
    content: "All the groups are displayed here in the tabular format",
    disableOverlayClose: true,
  },
];

export const dropDownOptions = [
  { label: "All Groups", value: "all" },
  { label: "My Groups", value: "my" },
  { label: "Team's Group", value: "team" },
];

export const initialGroupFilterState = (
  page: number,
  perPageData: number,
  dropdownValue: string,
  searchText: string,
  groupType: string
) => {
  return {
    page,
    perPageData,
    searchText,
    dropdownValue: dropdownValue,
    groupType: groupType,
  };
};
