import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomDialog,
  CustomInput,
} from "global/components";
import usersStyles from "screens/Settings/Users/Users.styles";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import {
  emailCheck,
  insertCampaigner,
} from "screens/Settings/Users/UserService";
import {
  insertUserField,
  validateAddUserForm,
} from "screens/Settings/Users/UserTypeAndValidation";
import notifiers from "global/constants/NotificationConstants";
import uploadUser from "assets/images/uploadUser.svg";
import strings from "global/constants/StringConstants";
import _ from "lodash";
import { addUserPayload } from "../../UsersInterface";

interface CustomProps {
  openAddUserDialog: boolean;
  handleCloseAddUserDialog: Function;
  managerMail: string[];
  roles: any[];
  location?: any;
  tableData: Function;
  isLoading: boolean;
}

const AddUser = (props: CustomProps) => {
  const classes = usersStyles;
  const [userFormFields, setUserFormFields] = useState<any>(insertUserField());
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setUserFormFields(insertUserField());
  }, [props.openAddUserDialog]);

  const handleFormDataChange = (formFillEvent: any) => {
    setUserFormFields({
      ...userFormFields,
      [formFillEvent.target.name]: {
        ...userFormFields[formFillEvent.target.name],
        value: formFillEvent.target.value,
      },
    });
  };

  const handleSelectRole = (formFillEvent: SelectChangeEvent<any>) => {
    setUserFormFields({
      ...userFormFields,
      [formFillEvent.target.name]: {
        ...userFormFields[formFillEvent.target.name],
        value: formFillEvent.target.value,
      },
    });
  };

  const   handleOnChange = (event: any, newValue: any) => {
    setUserFormFields({
      ...userFormFields,
      assignBy: {
        ...userFormFields.assignBy,
        value: newValue,
      },
    });
  };

  const addUserDialogTitle = () => {
    return (
      <Box>
        <Typography sx={classes.boldFonts}>Add User</Typography>
      </Box>
    );
  };

  const checkValidManagerEmail = () => {
    return _.uniq(props.managerMail)?.includes(userFormFields.assignBy.value);
  };

  const insertUserDetails = async () => {
    setLoading(true);
    if (handleValidation() && checkValidManagerEmail()) {
      const insertUserBody: addUserPayload = {
        emailId: userFormFields.emailId.value.trim().toLowerCase(),
        firstName: userFormFields.firstName.value.trim(),
        lastName: userFormFields.lastName.value.trim(),
        title: userFormFields.title.value.trim(),
        allowedEmailCount: userFormFields.allowedEmailCount.value,
        assignBy: userFormFields.assignBy.value,
        roleIds: [userFormFields.role.value],
      };
      try {
        const response = await emailCheck(insertUserBody.emailId);
        if (isTruthy(response)) {
          openErrorNotification("Campaigner email already exists");
        } else {
          await insertCampaigner(insertUserBody);
          props.handleCloseAddUserDialog(false);
          openSuccessNotification("Campaigner Added successfully");
          props.tableData && props.tableData();
        }
      } catch (error: any) {
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
      }
    }
    setLoading(false);
  };

  const handleValidation = () => {
    const { isValid, errors }: { isValid: boolean; errors: any } =
      validateAddUserForm(userFormFields);
    setUserFormFields({ ...errors });
    return isValid;
  };

  const addUserDialogBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInput
            required
            id="add_user_title_field"
            placeHolder="Enter title"
            name="title"
            label="Title"
            onChange={handleFormDataChange}
            value={userFormFields.title.value}
            error={
              !isTruthy(userFormFields.title.value) &&
              userFormFields.title.error
            }
            propsToInputElement={{ maxLength: strings.USER_TITLE_LIMIT }}
          />
          {isTruthy(userFormFields.title.value) &&
            userFormFields.title.value.length >= strings.USER_TITLE_LIMIT && (
              <FormHelperText error sx={classes.errorStyle}>
                {`Title must be less than ${strings.USER_TITLE_LIMIT} characters`}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <CustomInput
            required
            id="add_user_first_name_field"
            placeHolder="Enter first name"
            name="firstName"
            label="First Name"
            onChange={handleFormDataChange}
            value={userFormFields.firstName.value}
            error={
              !isTruthy(userFormFields.firstName.value) &&
              userFormFields.firstName.error
            }
            propsToInputElement={{ maxLength: strings.USER_FIRST_NAME_LIMIT }}
          />
          {isTruthy(userFormFields.firstName.value) &&
            userFormFields.firstName.value.length >=
              strings.USER_FIRST_NAME_LIMIT && (
              <FormHelperText error sx={classes.errorStyle}>
                {`First name must be less than ${strings.USER_FIRST_NAME_LIMIT} characters`}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <CustomInput
            id="add_user_last_name_field"
            placeHolder="Enter last name"
            name="lastName"
            label="Last Name"
            onChange={handleFormDataChange}
            value={userFormFields.lastName.value}
            error={
              !isTruthy(userFormFields.lastName.value) &&
              userFormFields.lastName.error
            }
            propsToInputElement={{ maxLength: strings.USER_LAST_NAME_LIMIT }}
          />
          {isTruthy(userFormFields.lastName.value) &&
            userFormFields.lastName.value.length >=
              strings.USER_LAST_NAME_LIMIT && (
              <FormHelperText error sx={classes.errorStyle}>
                {`Last name must be less than ${strings.USER_LAST_NAME_LIMIT} characters`}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <CustomInput
            required
            id="add_user_email_field"
            placeHolder="Enter email"
            name="emailId"
            label="Email"
            onChange={handleFormDataChange}
            value={userFormFields.emailId.value}
            error={
              (!isTruthy(userFormFields.emailId.value) &&
                userFormFields.emailId.error) ||
              (isTruthy(userFormFields.emailId.value) &&
                !strings.regex.test(userFormFields.emailId.value))
            }
            propsToInputElement={{ maxLength: strings.USER_EMAIL_LIMIT }}
          />
          {isTruthy(userFormFields.emailId.value) &&
            !strings.regex.test(userFormFields.emailId.value) && (
              <FormHelperText error sx={classes.errorStyle}>
                Please enter valid email id
              </FormHelperText>
            )}
          {isTruthy(userFormFields.emailId.value) &&
            userFormFields.emailId.value.length >= strings.USER_EMAIL_LIMIT && (
              <FormHelperText error sx={classes.errorStyle}>
                {`Email id must be less than ${strings.USER_EMAIL_LIMIT} characters`}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Box>
            <InputLabel sx={classes.inputLabel} shrink>
              Manager Email{" "}
              <Box ml={0.4} sx={classes.star}>
                *
              </Box>
            </InputLabel>
            <Autocomplete
              sx={classes.emailDropDownStyle}
              id="add_user_manager_dropdown"
              options={props.managerMail.map((item: string) => item)}
              value={userFormFields?.assignBy?.value}
              onChange={(e, newVal) => handleOnChange(e, newVal)}
              renderInput={(params) => (
                <TextField
                  sx={classes.select}
                  {...params}
                  placeholder="Select manager email"
                  name="assignBy"
                  error={
                    (!isTruthy(userFormFields.assignBy.value) &&
                      userFormFields.assignBy.error) ||
                    (isTruthy(userFormFields.assignBy.value) &&
                      !checkValidManagerEmail())
                  }
                />
              )}
            />
            {!isTruthy(userFormFields.assignBy.value) && (
              <FormHelperText error sx={classes.errorStyle}>
                {userFormFields.assignBy.error}
              </FormHelperText>
            )}

            {isTruthy(userFormFields.assignBy.value) &&
              !checkValidManagerEmail() && (
                <FormHelperText error sx={classes.errorStyle}>
                  Please Select Manger Email in DropDown
                </FormHelperText>
              )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <CustomInput
            required
            id="add_user_allowed_email_field"
            placeHolder="Enter allowed email count/day"
            name="allowedEmailCount"
            InputProps={{ type: "number", min: 0 }}
            label="Allowed Email Count/Day"
            onChange={(event: any) => {
              event.target.value = Math.abs(event.target.value);
              handleFormDataChange(event);
            }}
            value={userFormFields.allowedEmailCount.value}
            error={
              !isTruthy(userFormFields.allowedEmailCount.value) &&
              userFormFields.allowedEmailCount.error
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Box>
            <Stack direction="column">
              <InputLabel sx={classes.inputLabel} shrink>
                Role
                <Box ml={0.4} sx={classes.star}>
                  *
                </Box>
              </InputLabel>
              <Select
                sx={classes.dropDownStyle}
                id="add_user_roles_dropdown"
                name="role"
                value={userFormFields.role.value}
                onChange={handleSelectRole}
                renderValue={
                  userFormFields.role.value !== ""
                    ? undefined
                    : () => "Select a role"
                }
                MenuProps={classes.menuProps}
                displayEmpty
                error={
                  userFormFields.role.value.length < 4 &&
                  userFormFields.role.error.length !== 0
                }
              >
                {props.roles.map((role, index) => (
                  <MenuItem
                    key={index}
                    value={role.id}
                    sx={classes.dropDownOptionsStyle}
                  >
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            {!isTruthy(userFormFields.role.value) && (
              <FormHelperText error sx={classes.errorStyle}>
                {userFormFields.role.error}
              </FormHelperText>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const addUserDialogFooter = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            id="add_user_cancel_button"
            label="Cancel"
            onClick={() => props?.handleCloseAddUserDialog()}
            customClasses={classes.cancelButtonStyle}
          />
          <CustomButton
            id="add_user_submit_button"
            label="Add"
            onClick={insertUserDetails}
            loading={loading}
          />
        </Box>
      </Grid>
    );
  };

  const addUserHeaderImg = () => {
    return (
      <Box display={"flex"}>
        <img src={uploadUser} alt="Add user not found!" />
      </Box>
    );
  };

  const getAddUserDialog = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <CustomDialog
          isDialogOpen={props.openAddUserDialog}
          closable
          closeButtonVisibility
          handleDialogClose={props.handleCloseAddUserDialog}
          dialogHeaderContent={addUserHeaderImg()}
          dialogTitleContent={addUserDialogTitle()}
          dialogBodyContent={addUserDialogBody()}
          dialogFooterContent={addUserDialogFooter()}
          width={"700px"}
          fullScreen={false}
        />
      </Grid>
    );
  };

  return <Box>{getAddUserDialog()}</Box>;
};

export default AddUser;
