export const uploadGroupField = () => {
  return {
    groupName: {
      value: "",
      error: "",
    },
    groupType: {
      value: "",
      error: "",
    },
    groupCSV: {
      value: {},
      error: "",
    },
  };
};

export const uploadGroupValidation = (uploadGroupData: any) => {
  const errors = uploadGroupData;
  let isValid = true;
  const groupName = uploadGroupData.groupName.value;
  const groupType = uploadGroupData.groupType.value;
  const groupCSV = uploadGroupData.groupCSV.value;
  if (!groupName) {
    errors.groupName.error = "Please enter group name";
    isValid = false;
  }
  if (!groupType) {
    errors.groupType.error = "Please select group type";
    isValid = false;
  }
  if (!Object.keys(groupCSV).length) {
    errors.groupCSV.error = "Please upload your contact list";
    isValid = false;
  }

  return { isValid, errors };
};

export const uploadGroupDynamicCSVValidation = (uploadGroupData: any) => {
  const errors = uploadGroupData;
  let isValid = true;
  const groupName = uploadGroupData.groupName.value;
  const groupType = uploadGroupData.groupType.value;
  if (!groupName) {
    errors.groupName.error = "Please enter group name";
    isValid = false;
  }
  if (!groupType) {
    errors.groupType.error = "Please select group type";
    isValid = false;
  }

  return { isValid, errors };
};

export const expectedColumnNames = [
  "CONTACT_NO",
  "EMAIL_ID",
  "LAST_NAME",
  "FIRST_NAME",
  "TAGS",
];
