import {
  boldFont,
  getRelativeFontSize,
  mediumFont,
  primaryHeadingColor,
  theme,
  warningColor,
} from "utils/styles";

const Step2Styles = {
  headerGridContainer: {
    my: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      direction: "column",
    },
  },
  headerButtonStack: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  headerButtonStyle: { margin: "5px", width: "140px" },
  headerButton: { width: "100%", whiteSpace: "nowrap" },
  headerSection: {
    backgroundColor: "#fcf5ff",
    padding: "35px 20px 18px 20px",
  },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  },
  wrapper: {
    padding: theme.spacing(4),
  },
  accordionTitle: {
    width: "98%",
    fontSize: getRelativeFontSize(4),
    ...boldFont,
  },
  campaignDetailsBox: {
    border: "1px solid #eee",
    padding: "16px",
  },
  dropZoneWrapper: {
    "& .MuiDropzoneArea-root": {
      width: "100%",
      minHeight: "70px",
      fontSize: "12px",
      borderRadius: "10px",
      borderWidth: "3px",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: getRelativeFontSize(2),
      ...mediumFont,
    },
  },
  uploadFileStyle: {
    margin: "5px",
  },
  warningContent: {
    color: warningColor,
    fontSize: getRelativeFontSize(),
    margin: "10px 0",
    ...mediumFont,
  },
} as const;

export default Step2Styles;
