import { Box, Grid, Typography } from "@mui/material";
import { CustomAppHeader } from "global/components";
import CreateCampaignsStyles from "screens/CampaignsNew/CreateCampaigns/CreateCampaigns.styles";
import { createCampaignTypes } from "screens/CampaignsNew/CreateCampaigns/CreateCampaigns.data";
import CreateCampaignCard from "screens/CampaignsNew/CreateCampaigns/components/CreateCampaignCard/CreateCampaignCard";
import urls from "global/constants/UrlConstants";
import history from "utils/history";
import strings from "global/constants/StringConstants";
import { useTitle } from "utils/UseTitle";

const CreateCampaigns = () => {
  useTitle(strings.CreateCampaignsTitle);
  const classes = CreateCampaignsStyles;

  const redirectionHandler = (campaignType: string) => {
    let redirectionUrl: string;
    switch (campaignType) {
      case strings.SEQUENCES: {
        redirectionUrl = urls.sequencesViewPath;
        break;
      }
      case strings.SOCIAL: {
        redirectionUrl = urls.socialViewPath;
        break;
      }
      case "abtesting": {
        redirectionUrl = urls.createCampaignViewPath;
        break;
      }
      default: {
        redirectionUrl = urls.createCampaignViewPath;
        break;
      }
    }
    history.push(redirectionUrl);
  };

  const getHeader = () => {
    return (
      <>
        <CustomAppHeader className={classes.headerSection}>
          <Grid container xs={12} sx={classes.headerGridContainer}>
            <Typography sx={classes.pageTitle}>
              <span style={{ color: "#D7BBE2" }}>Campaigns</span> / Create
              Campaigns
            </Typography>
            <Typography sx={classes.pageDescription}>
              Stay in touch with your customers using campaign platforms.
            </Typography>
          </Grid>
        </CustomAppHeader>
      </>
    );
  };

  const getContent = () => {
    return (
      <Box sx={classes.mainContentBox}>
        <Grid container>
          {createCampaignTypes().map((cardItem: any) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                sx={{
                  padding: "20px",
                }}
              >
                <Box
                  onClick={() => {
                    cardItem.isUserPermitted &&
                      redirectionHandler(cardItem.onClickText);
                  }}
                >
                  <CreateCampaignCard
                    title={cardItem.title}
                    icon={cardItem.icon}
                    description={cardItem.description}
                    isUserPermitted={cardItem.isUserPermitted}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {getHeader()}
      {getContent()}
    </>
  );
};

export default CreateCampaigns;
