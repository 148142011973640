import { Chip, Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CustomDialog, CustomTable } from "global/components";
import {
  convertESTtoUserLocalDateAndTime,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import { getRecipientSummary } from "screens/Contact/ViewContactService";
import { recipientsSummaryHeader } from "screens/Contact/ContactListData";
import { ViewContactStyles } from "screens/Contact/ViewContact.styles";
import recipientsSummaryModal from "assets/images/recipientsSummaryModal.svg";
import notifiers from "global/constants/NotificationConstants";
import { chipBackgroundColor, completeChipBackgroundColor } from "utils/styles";
import CustomLoader from "global/components/CustomLoader/CustomLoader";

interface CustomProps {
  isRecipientModalOpen: boolean;
  setIsRecipientModalOpen: Function;
  recipientEmailId: string;
}

const EmailRecipientModal = (props: CustomProps) => {
  const classes = ViewContactStyles;
  const perPageData = 5;
  const [formattedTableData, setFormattedTableData] = useState<any[]>([]);
  const [clippedFormattedData, setClippedFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchRecipientSummary();
  }, []);

  useEffect(() => {
    getClippedFormattedData(formattedTableData, page, perPageData);
  }, [formattedTableData, page]);

  const getClippedFormattedData = (
    completeData: any[],
    page: number,
    perPageData: number
  ) => {
    const clipTillIndex = page * perPageData;
    const clipFromIndex = clipTillIndex - perPageData;
    setClippedFormattedData(completeData.slice(clipFromIndex, clipTillIndex));
  };

  const fetchRecipientSummary = async () => {
    try {
      setIsLoading(true);
      const response = await getRecipientSummary(props.recipientEmailId);
      const formattedData = getFormattedTableData(response);
      setFormattedTableData(formattedData);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getFormattedTableData = (rawRecipientData: any) => {
    const formattedData = rawRecipientData.map((item: any) => {
      return {
        campaignOwner: item.campaignOwner,
        campaignName: (
          <Typography sx={classes.recipientCampaignName}>
            {item.campaignName}
          </Typography>
        ),
        status: getAction(item.action),
        scheduleTime: convertESTtoUserLocalDateAndTime(
          item.actionDetails.actionTimeLines[0],
          "MMM DD, YYYY"
        ),
      };
    });
    return formattedData;
  };

  const getAction = (action: string) => {
    const chipColor =
      action === "Clicked" ? completeChipBackgroundColor : chipBackgroundColor;
    return (
      <Chip
        label={action}
        sx={classes.ChipStyle}
        style={{ background: chipColor }}
      />
    );
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const closeEmailDialog = () => {
    props.setIsRecipientModalOpen(false);
  };

  const EmailRecipientTable = () => {
    return (
      <Box sx={classes.emailRecipientTableStyles}>
        <CustomTable
          headers={recipientsSummaryHeader}
          rows={clippedFormattedData}
          paginationCount={formattedTableData.length}
          pageNumber={page}
          handlePageChange={handleChangePage}
          perPageData={perPageData}
          rowsPerPage={perPageData}
          isRowPerPageEnable={true}
        />
      </Box>
    );
  };

  const groupTitleContent = () => {
    return (
      <Typography sx={classes.recipientTableTitle}>
        Recipients Summary
      </Typography>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <img src={recipientsSummaryModal} alt="" style={{ width: "100%" }} />
    );
  };

  return (
    <>
      <CustomDialog
        dialogHeaderContent={dialogHeaderContent()}
        dialogTitleContent={groupTitleContent()}
        dialogBodyContent={EmailRecipientTable()}
        isDialogOpen={props.isRecipientModalOpen}
        handleDialogClose={closeEmailDialog}
        closable
        closeButtonVisibility
        width="700px"
      />
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default EmailRecipientModal;
