import urls from "global/constants/UrlConstants";
import { getCallParams, makeCall } from "utils/service";

export const fetchQRCodeForMFA = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.GET_QR_CODE, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const verifyMFACode = async (
  email: string,
  password: string,
  authenticatorCode: string,
  multiFactorLogin: boolean
) => {
  try {
    const body = {
      email: email,
      pwd: password,
      authenticatorCode,
      multiFactorLogin,
    };
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(urls.VERIFY_MFA, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
