import { useState } from "react";
import { CustomButton, CustomDialog } from "global/components";
import { Box, Typography, Grid } from "@mui/material";
import subjectLineGenerationImg from "assets/images/subjectLine_generation.svg";
import Step3Styles from "screens/CampaignsNew/CreateCampaigns/CreateRegularCampaign/StepTwo/StepTwo.styles";
import { darkPurpledColor, lightPinkColor } from "utils/styles";

interface CustomProps {
  isSubjectModalOpen: boolean;
  setIsSubjectModalOpen: Function;
  subjectLines: string[];
  campaignData: any;
  setCampaignData: Function;
  subjectLineGenerationHandler: Function;
}

const SubjectLinesModal = (props: CustomProps) => {
  const classes = Step3Styles;
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const subjectModalHeader = () => {
    return (
      <Box display="flex">
        <img src={subjectLineGenerationImg} alt="Image not found!" />
      </Box>
    );
  };

  const subjectModalTitle = () => {
    return (
      <Box>
        <Typography sx={classes.boldFonts}>Generated Subject Lines</Typography>
      </Box>
    );
  };

  const subjectModalDialog = () => {
    return (
      <Grid container direction="column" spacing={1}>
        {props.subjectLines
          .filter((subjectLine) => subjectLine !== "")
          .map((subjectLine, index) => (
            <Grid item>
              <Box
                sx={{
                  ...classes.subjectLineBox,
                  ...(index === selectedIndex
                    ? {
                        border: `1px solid ${darkPurpledColor}`,
                        backgroundColor: lightPinkColor,
                      }
                    : { border: "1px solid #E7E7E7" }),
                }}
                onClick={() => {
                  setSelectedIndex(index);
                }}
              >
                <Typography>{subjectLine}</Typography>
              </Box>
            </Grid>
          ))}
      </Grid>
    );
  };

  const subjectModalFooter = () => {
    return (
      <Grid container sx={classes.centerItemFlex}>
        <Box sx={classes.dialogFooter}>
          <CustomButton
            id="subject_modal_regenerate_button"
            label="Regenerate"
            onClick={() => {
              props.subjectLineGenerationHandler();
            }}
          />
          <CustomButton
            id="subject_modal_continue_button"
            label="Continue"
            onClick={() => {
              props.setCampaignData({
                ...props.campaignData,
                subject: {
                  value: props.subjectLines[selectedIndex],
                  error: "",
                },
              });
              props.setIsSubjectModalOpen(false);
            }}
            disabled={selectedIndex < 0}
          />
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <CustomDialog
        isDialogOpen={props.isSubjectModalOpen}
        closable
        closeButtonVisibility
        handleDialogClose={() => props.setIsSubjectModalOpen(false)}
        dialogHeaderContent={subjectModalHeader()}
        dialogTitleContent={subjectModalTitle()}
        dialogBodyContent={subjectModalDialog()}
        dialogFooterContent={subjectModalFooter()}
        width="600px"
      />
    </>
  );
};

export default SubjectLinesModal;
