import { Box, Typography, Card, Stack } from "@mui/material";
import socialStyles from "screens/CampaignsNew/CreateCampaigns/CreateSocialCampaign/Social.styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FacebookLayout from "assets/icons/facebook-layout.png";
import thumbsUpIcon from "assets/icons/thumbs-up.png";
import reactionIcon from "assets/icons/reaction.png";
import hurtIcon from "assets/icons/hurt.png";
import moment from "moment";
import CustomImageCarousal from "global/components/CustomImageCarousal/CustomImageCarousal";
import { isTruthy } from "helpers/methods";
import { boldFont, getRelativeFontSize, regularFont } from "utils/styles";
import strings from "global/constants/StringConstants";

interface FacebookCustomProps {
  isLoading?: boolean;
  socialAppCardClickHandler?: Function;
  isCardClickable: boolean;
  selectedAppsList?: string;
  facebookPage?: any;
  scheduleTime?: any;
  postContent?: string;
  imageArray?: string[];
  carousalIndex?: number;
  setCarousalIndex?: Function;
}

const Facebook = (props: FacebookCustomProps) => {
  const classes = socialStyles;
  const shouldShowFacebookCard = () => {
    const shouldShowCard: boolean = isTruthy(
      props?.facebookPage?.metaData?.pageDetails
    );
    return shouldShowCard;
  };

  const onClickHandler = () => {
    if (props.isCardClickable && shouldShowFacebookCard()) {
      props.socialAppCardClickHandler?.(strings.FACEBOOK);
    }
  };

  return (
    <Box sx={classes.socialCardContainer} onClick={onClickHandler}>
      <Card
        sx={[
          classes.socialCard,
          ...(props.selectedAppsList?.includes(strings.FACEBOOK) &&
          shouldShowFacebookCard()
            ? [classes.socialCardSelected]
            : []),
          ...(props.isCardClickable && shouldShowFacebookCard()
            ? [classes.socialCardClickable]
            : []),
        ]}
      >
        <Box sx={classes.socialCardHeader}>
          <Box sx={classes.socialCardHeaderLeft}>
            <Box>
              <img
                src={props?.facebookPage?.metaData?.profilePicture}
                alt="fb-icon"
                style={{ borderRadius: "50%" }}
              />
            </Box>
            <Box>
              <Typography sx={classes.profileName}>
                {props?.facebookPage?.metaData?.userName}
              </Typography>
              {props.scheduleTime !== undefined ? (
                <Typography sx={classes.postTime}>
                  {moment(
                    props.scheduleTime ? props.scheduleTime : undefined
                  ).format(`${"DD MMM"}, ${"YYYY hh:mm a"}`)}
                </Typography>
              ) : null}
            </Box>
          </Box>

          <Box>
            <MoreHorizIcon />
          </Box>
        </Box>
        <Box sx={classes.socialCardContent}>
          <Typography sx={classes.publishPost}>
            {props.postContent ? (
              <>
                {props.postContent.substring(0, 240)}
                {props.postContent.length > 240 && "..."}
              </>
            ) : (
              "Publish Your Post On Facebook..."
            )}
          </Typography>
        </Box>
        <CustomImageCarousal
          imageArray={props.imageArray}
          noImageLayout={FacebookLayout}
          displayImageIndex={props.carousalIndex}
          setDisplayImageIndex={props.setCarousalIndex}
          customStyle={classes.socialCardLayout}
        />
        <Box sx={classes.socialCardFooter}>
          <Box sx={classes.footerLikes}>
            <Box sx={classes.userReactIcon}>
              <Box component="span" sx={classes.reactIcon}>
                <img src={thumbsUpIcon} alt="icon" />
              </Box>
              <Box component="span" sx={classes.reactIcon}>
                <img src={reactionIcon} alt="icon" />
              </Box>
              <Box component="span" sx={classes.reactIcon}>
                <img src={hurtIcon} alt="" />
              </Box>
            </Box>
          </Box>
        </Box>
        {!shouldShowFacebookCard() && !props.isLoading && (
          <Stack sx={classes.disabledStackItem} spacing={3}>
            <Box sx={{ padding: "0 15px" }}>
              <Typography
                sx={{
                  textAlign: "justify",
                  ...boldFont,
                  fontSize: getRelativeFontSize(4),
                }}
              >
                You have either not selected Facebook pages or not granted
                proper permissions while integration.
              </Typography>
            </Box>
            <Box sx={{ padding: "0 15px" }}>
              <Typography
                sx={{
                  textAlign: "justify",
                  ...regularFont,
                }}
              >
                Please disconnect the Meta from Integration tab and then grant
                proper permission while reconnecting.
              </Typography>
            </Box>
          </Stack>
        )}
      </Card>
    </Box>
  );
};

export default Facebook;
