import { useState, useEffect } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import WhatsAppTemplateStyles from "screens/WhatsAppCampaign/WhatsAppTemplates/WhatsAppTemplates.styles";
import { CustomAppHeader, CustomPaper, CustomTable } from "global/components";
import SyncIcon from "@mui/icons-material/Sync";
import {
  getLanguageName,
  whatsAppTemplateHeader,
  whatsAppTemplateStatusTabsContent,
} from "screens/WhatsAppCampaign/WhatsAppTemplates/WhatsAppTemplatesData";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import {
  capitalizeFirstCharacter,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import CustomTabs from "global/components/CustomTabs/CustomTabs";
import {
  checkWhatsAppIntegration,
  getWhatsAppTemplateStatus,
  getWhatsAppTemplates,
  getWhatsAppTemplatesCount,
} from "screens/WhatsAppCampaign/WhatsAppTemplates/WhatsAppTemplatesServices";
import moment from "moment";
import strings from "global/constants/StringConstants";

interface CustomProps {
  location: any;
}

const WhatsAppTemplates = (props: CustomProps) => {
  const classes = WhatsAppTemplateStyles;

  const statusFromState = props?.location?.state?.status;

  const [templateTableData, setTemplateTableData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(
    statusFromState ?? whatsAppTemplateStatusTabsContent[0].label
  );
  const [isWhatsAppIntegrated, setIsWhatsAppIntegrated] =
    useState<boolean>(false);
  const [perPageData, setPerPageData] = useState(10);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    checkIsWhatsAppAuthentication();
  }, []);

  useEffect(() => {
    getTemplateTableData(status, page, perPageData);
  }, [status, page, perPageData]);

  const handlePerPageData = (event: any) => {
    const newValue = event.target.value;
    setPerPageData(newValue);
    setPage(1);
  };

  const convertESTtoUserLocalTime = (inputTime: string) => {
    const currentLocalTimeZone: string = moment.tz.guess(true);
    const inputTimeInLocalTimeZone: moment.Moment = moment
      .tz(inputTime, "MM-DD-YYYY hh:mm:ss", "America/New_York")
      .clone()
      .tz(currentLocalTimeZone);
    return inputTimeInLocalTimeZone;
  };

  const checkIsWhatsAppAuthentication = async () => {
    try {
      setIsLoading(true);
      const response = await checkWhatsAppIntegration();
      setIsWhatsAppIntegrated(
        response.some((item: any) => item.integratedApp === strings.WHATSAPP)
      );
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const canUserCheckForUpdate = (template: any) => {
    const currentTime: moment.Moment = moment();
    const inputTimeInLocalTimeZone: moment.Moment = convertESTtoUserLocalTime(
      template.createdOn
    );
    const result: number = currentTime.diff(inputTimeInLocalTimeZone, "hours");
    return result < 24 || !isWhatsAppIntegrated;
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const templateActionHandler = async (templateId: string) => {
    try {
      setIsLoading(true);
      const response = await getWhatsAppTemplateStatus(templateId);
      getTemplateTableData(status, page, perPageData);
      openSuccessNotification(response.message);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getFormattedTemplateData = (template: any) => {
    return {
      TemplateName: getFormattedTemplateName(template),
      TemplateType: getFormattedTemplateType(template),
      TemplateLanguage: getFormattedTemplateLanguage(template),
      LastUpdatedOn: getFormattedTemplateUpdateTime(template),
      CreatedBy: getFormattedTemplateCreatedBy(template),
      ...(status !== strings.approved
        ? {
            Action: getFormattedTemplateAction(
              template,
              canUserCheckForUpdate(template)
            ),
          }
        : {}),
    };
  };

  const getFormattedTemplateName = (template: any) => {
    return (
      <Typography sx={classes.templateNameStyles}>
        {template.templateName}
      </Typography>
    );
  };

  const getFormattedTemplateType = (template: any) => {
    return (
      <Typography>
        {capitalizeFirstCharacter(template.templateCategory)}
      </Typography>
    );
  };

  const getFormattedTemplateLanguage = (template: any) => {
    return <Typography>{getLanguageName(template.language)}</Typography>;
  };

  const getFormattedTemplateUpdateTime = (template: any) => {
    return (
      <Typography>
        {convertESTtoUserLocalTime(template.createdOn).format("MMM DD, YYYY")}
      </Typography>
    );
  };

  const getFormattedTemplateCreatedBy = (template: any) => {
    return (
      <Typography sx={classes.centeredItem}>{template.createdBy}</Typography>
    );
  };

  const getFormattedTemplateAction = (
    template: any,
    isButtonDisabled: boolean
  ) => {
    return (
      <Box sx={classes.centeredItem}>
        <Tooltip
          title={
            <CustomPaper className={classes.tooltipComponent}>
              <Typography sx={classes.tooltipText}>
                {
                  "Please either integrate WhatsApp or check after 24 hours of template submission."
                }
              </Typography>
            </CustomPaper>
          }
          arrow
          placement="top"
          componentsProps={{
            tooltip: {
              sx: classes.tooltipComponentPropsStyles,
            },
          }}
          disableHoverListener={!isButtonDisabled}
        >
          <span>
            <Button
              sx={classes.ActionBtn}
              disabled={isButtonDisabled}
              onClick={() => {
                templateActionHandler(template.templateId);
              }}
              id="template_check_for_update_button"
            >
              <SyncIcon sx={classes.syncIconStyles} />{" "}
              <span>Check for update</span>
            </Button>
          </span>
        </Tooltip>
      </Box>
    );
  };

  const getTemplateTableData = async (
    status: string,
    page: number,
    perPageData: number
  ) => {
    try {
      setIsLoading(true);
      const [templateCount, templateData] = await Promise.all([
        getWhatsAppTemplatesCount(status),
        getWhatsAppTemplates(status, page, perPageData),
      ]);
      const formattedTemplateData = templateData.map((template: any) =>
        getFormattedTemplateData(template)
      );
      setTemplateTableData(formattedTemplateData);
      setCount(templateCount);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleTemplateStatusTabs = (newValue: string) => {
    setStatus(newValue);
    setPage(1);
  };

  const getTemplatesTable = () => {
    return (
      <Box sx={classes.tableContainerBox}>
        <CustomTable
          headers={whatsAppTemplateHeader(status)}
          rows={templateTableData}
          handlePageChange={handlePageChange}
          paginationCount={count}
          pageNumber={page}
          isLoading={isLoading}
          setPage={setPage}
          handlePerPageData={handlePerPageData}
          perPageData={perPageData}
          rowsPerPage={perPageData}
        />
      </Box>
    );
  };

  return (
    <>
      <CustomAppHeader className={classes.headerSection}>
        <Box>
          <Typography sx={classes.pageTitle}>WhatsApp Templates</Typography>
        </Box>
        <Box>
          <CustomTabs
            containerId="whatsapp_templates_status_tab"
            changeValue={(newValue: string) => {
              handleTemplateStatusTabs(newValue);
            }}
            selected={status}
            tabConfig={whatsAppTemplateStatusTabsContent}
          />
        </Box>
      </CustomAppHeader>
      <Box sx={classes.mainContentBox}>{getTemplatesTable()}</Box>
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default WhatsAppTemplates;
