import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  Chip,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { CustomAppHeader, CustomButton, CustomInput } from "global/components";
import { useLocation } from "react-router-dom";
import StatsStyles from "screens/Stats/Stats.styles";
import { getRetargetedCampaigns } from "screens/CampaignsNew/Campaigns/Campaign.services";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
  translateFirstAndLastName,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import {
  boldFont,
  getRelativeFontSize,
  mediumFont,
  pinkThemedMenuItems,
  pureWhiteColor,
  purpleThemedSelectComponent,
  regularFont,
  theme,
} from "utils/styles";
import { CampaignData } from "screens/CampaignsNew/Campaigns/CampaignInterfaces";
import Autocomplete from "@mui/material/Autocomplete";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import StepFourTable from "screens/CreateCampaign/StepFour/Component/StepFourTable";
import TagsTable from "screens/CreateCampaign/StepFour/Component/TagsTable";
import {
  getLoggedInEmailUser,
  getSendTestEmail,
  getSubordinateEmailsUsers,
  getValidDistinctEmailCountByGroups,
  sendBulkEmail,
  tagsCountTable,
} from "screens/CreateCampaign/createCampaignServices";
import ScheduleTimeModal from "screens/CreateCampaign/StepFour/Component/ScheduleTimeModal";
import moment from "moment";
import { BulkEmailRequest } from "screens/CreateCampaign/Campaign.model";
import history from "utils/history";
import urls from "global/constants/UrlConstants";
import TestMailModal from "screens/CreateCampaign/StepFour/Component/TestMailModal";
import {
  initialValue,
  validateForStepOne,
} from "screens/Stats/ResendValidationType";
import { KeyboardBackspace } from "@mui/icons-material";
import strings from "global/constants/StringConstants";
import { useTitle } from "utils/UseTitle";
import { hasAccessTo } from "utils/AuthorizationManager";

const ResendCampaign = () => {
  const classes = StatsStyles;
  useTitle(strings.ResendCampaignTitle);
  const urlParams = useLocation().search;
  const campaignId = new URLSearchParams(urlParams).get("id");
  const [campaignData, setCampaignData] = useState<CampaignData>();
  const [loader, setLoader] = useState<boolean>(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const [currentMailingList, setCurrentMailingList] = useState<string>("");
  const [isScheduleTimeModal, setScheduleTimeModal] = useState<boolean>(false);
  const [state, setState] = useState<any>({
    emailAccount: [],
    userCount: 0,
    emailCount: 0,
    allowedEmailCount: 0,
    countSelectedEmail: 0,
    checkedListId: [],
    attachmentFileNames: {},
    formData: FormData,
    spamPercentage: 0,
  });
  const [emailUsers, setEmailUsers] = useState([]);
  const [rowData, setRowData] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [inputType, setInputType] = useState<any>(initialValue);
  const [isTestMail, setTestMail] = useState<boolean>(false);
  const bulkEmailRequest = new BulkEmailRequest();

  const hasCampaignerFetchAccess = hasAccessTo(
    strings.campaigner,
    strings.fetchPermission
  );
  const hasContactFetchAccess = hasAccessTo(
    strings.contact,
    strings.fetchPermission
  );

  useEffect(() => {
    resendApiHandler();
  }, []);

  useEffect(() => {
    if (selected.length > 0 && currentMailingList === "Groups") {
      getValidDistinctEmailCountByGroupsMethod();
    } else if (selected.length > 0 && currentMailingList === "Tags") {
      tagsEmailCount();
    }
  }, [selected]);

  const hasCampaignExecutionPermission = () => {
    return hasCampaignerFetchAccess && hasContactFetchAccess;
  };

  const resendApiHandler = async () => {
    try {
      const response = hasCampaignerFetchAccess
        ? await getLoggedInEmailUser()
        : Promise.resolve([]);
      const [retargetMethod, emailUser] = await Promise.all([
        getRetargetedCampaigns(campaignId!),
        getSubordinateEmailsUsers(),
      ]);
      setState({
        ...state,
        allowedEmailCount: response.allowedEmailCount ?? 0,
      });
      setCampaignData(retargetMethod);
      setInputType(initialValue(retargetMethod));
      setEmailUsers(emailUser);
      return response;
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const checkEmailUserCount = (response: any) => {
    let errorMessage = "";
    if (state.userCount + response > state.allowedEmailCount) {
      errorMessage =
        errorMessage +
        "The requested email count of " +
        response +
        " will add up to " +
        (state.userCount + response) +
        " total count which exceeds your permissible limit of " +
        state.allowedEmailCount +
        ".";
    }
    if (errorMessage.length > 0) {
      openErrorNotification(errorMessage);
    }
  };

  const tagsEmailCount = async () => {
    try {
      setLoader(true);
      const res = await tagsCountTable(selected);
      setState({ ...state, countSelectedEmail: res });
      checkEmailUserCount(res);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getValidDistinctEmailCountByGroupsMethod = async () => {
    try {
      setLoader(true);
      const response = await getValidDistinctEmailCountByGroups(selected);
      setState({ ...state, countSelectedEmail: response });
      checkEmailUserCount(response);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const buttonGroup = () => {
    return (
      <Grid container>
        <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
          <Box style={{ display: "flex" }}>
            <Box>
              <IconButton
                onClick={() => {
                  history.goBack();
                }}
                id="resend_campaign_back_button"
              >
                <KeyboardBackspace />
              </IconButton>
            </Box>
            <Box>
              <Typography sx={classes.CampaignTitle}>
                Resend Campaign
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          justifyContent={{
            xl: "end",
            lg: "end",
            md: "end",
            sm: "start",
            xs: "start",
          }}
          style={{ display: "flex", gap: "5px" }}
          xl={6}
          lg={6}
          md={6}
          xs={12}
          sm={12}
        >
          <Box>
            <CustomButton
              label="Test mail"
              onClick={() => {
                setTestMail(true);
              }}
              customClasses={{ width: "120px" }}
              id="resend_campaign_test_mail_button"
            />
          </Box>
          <Box>
            <CustomButton
              label="Send"
              onClick={() => {
                sendButtonHandler();
              }}
              disabled={!hasCampaignExecutionPermission()}
              customClasses={{ width: "120px" }}
              id="resend_campaign_send_button"
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const handleStepOneOnChange = (event: React.ChangeEvent<any>) => {
    setInputType({
      ...inputType,
      [event.target.name]: {
        ...inputType[event.target.name],
        value: event.target.value,
        error: "",
      },
    });
  };

  const handleFromChangeHandler = (event: any, newValue: any) => {
    setInputType({
      ...inputType,
      from: {
        value: newValue,
        error: "",
      },
    });
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const sendBulkEmailMethod = async () => {
    try {
      if (handleValidation()) {
        setLoader(true);
        const response = await sendBulkEmail(bulkEmailRequest);
        if (response) {
          openSuccessNotification(
            "Campaign request has been submitted successfully. Click refresh button to check the status."
          );
        }
        setLoader(false);
        history.push({
          pathname: urls.campaignsListViewPath,
          search: "?status=Submitted",
        });
        setLoader(false);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      setLoader(false);
    }
  };

  const emailHandlerGroup = (groups: any) => {
    return currentMailingList === "Tags"
      ? [{ type: "Tags", value: groups }]
      : [{ type: "Groups", value: groups }];
  };

  const onSendBulkEmail = () => {
    scheduleTimeHandler();
    let groups: any = rowData;
    bulkEmailRequest.groups = emailHandlerGroup(groups);
    bulkEmailRequest.fromId = inputType?.from?.value;
    bulkEmailRequest.status = "Submitted";
    bulkEmailRequest.owner = campaignData?.owner!;
    bulkEmailRequest.displayName = inputType?.displayName?.value;
    bulkEmailRequest.subject = inputType?.subject?.value;
    bulkEmailRequest.name = inputType?.campaignName?.value;
    bulkEmailRequest.content = campaignData?.content;
    bulkEmailRequest.attachments = campaignData?.attachments;
    bulkEmailRequest.target = currentMailingList;
    bulkEmailRequest.data = campaignData?.data;
    sendBulkEmailMethod();
  };

  const sendButtonHandler = () => {
    if (handleValidation() && checkMailingAudienceSelected()) {
      setScheduleTimeModal(true);
    }
  };

  const checkMailingAudienceSelected = () => {
    let isValid = true;
    if (selected?.length === 0) {
      isValid = false;
      openErrorNotification("Please select the mailing audience");
    }
    return isValid;
  };

  const handleValidation = () => {
    const { isValid, errors }: any = validateForStepOne(inputType);
    setInputType({ ...errors });
    return isValid;
  };

  const getCampaignDesignAndAttachments = () => {
    return (
      <Container maxWidth="md">
        <Box sx={classes.camapignBox}>
          <Typography sx={classes.statsheading}>
            <span style={{ ...boldFont }}>From:</span>{" "}
            {campaignData?.fromId ?? ""}
          </Typography>
          <Typography sx={classes.statsheading}>
            {campaignData?.groups?.map((data: any) => {
              const recipientList = !isReadMore
                ? data.value.map((value: any) => value.name).join(", ")
                : data.value
                    .map((value: any) => value.name)
                    .slice(0, 2)
                    .join(", ");

              return (
                <Typography>
                  <span style={{ ...boldFont }}> To:</span> {recipientList}
                  {data.value.length > 2 && (
                    <span
                      onClick={toggleReadMore}
                      style={{
                        marginLeft: 5,
                        ...boldFont,
                        backgroundColor: "#D6D6D6",
                        padding: "2px 8px",
                        cursor: "pointer",
                        borderRadius: "5px",
                        fontSize: getRelativeFontSize(),
                      }}
                    >
                      {isReadMore
                        ? `+ ${data.value.length - 2} more `
                        : "show less"}
                    </span>
                  )}
                </Typography>
              );
            })}
          </Typography>
          <Typography sx={classes.statsheading}>
            <span style={{ ...boldFont }}> Subject:</span>{" "}
            {translateFirstAndLastName(campaignData?.subject ?? "")}
          </Typography>
          <Box mt={2} mb={2}>
            {campaignData?.content && (
              <Box
                sx={{
                  width: "100%",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <iframe
                  srcDoc={campaignData?.content}
                  width="100%"
                  height="600px"
                  frameBorder="0"
                ></iframe>
              </Box>
            )}
          </Box>
          <Box>
            {campaignData?.attachments &&
              Object.keys(campaignData.attachments).map((name) => {
                return (
                  <Chip
                    label={name}
                    sx={{ width: "120px", mr: 1 }}
                    component="a"
                    variant="outlined"
                    clickable
                  />
                );
              })}
          </Box>
        </Box>
      </Container>
    );
  };

  const TagsTableHeader = () => {
    return (
      <>
        <Box display={"flex"} alignItems="center">
          <Typography marginRight={2} sx={{ ...mediumFont }} variant="h6">
            Select Mailing Audience
          </Typography>
          <FormControl>
            <Select
              id="resend_select_mailing_list"
              sx={{
                minWidth: "150px",
                margin: "18px 0px 8px 0px",
                height: "47px",
                borderRadius: "10px",
                background: pureWhiteColor,
                ...regularFont,
                ...purpleThemedSelectComponent,
                [theme.breakpoints.down("md")]: {
                  marginTop: theme.spacing(1),
                },
              }}
              value={currentMailingList}
              onChange={(e: any) => {
                setCurrentMailingList(e.target.value);
              }}
              renderValue={
                currentMailingList !== "" ? undefined : () => "Select Audience"
              }
              displayEmpty
            >
              <MenuItem
                sx={{
                  ...regularFont,
                  ...pinkThemedMenuItems,
                }}
                value="Groups"
              >
                Groups
              </MenuItem>
              <MenuItem
                sx={{
                  ...regularFont,
                  ...pinkThemedMenuItems,
                }}
                value="Tags"
              >
                Tags
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </>
    );
  };

  const isSelected = (id: any) => {
    return selected.indexOf(id) !== -1;
  };

  const singleCheckboxHandler = (event: any, row: any) => {
    if (isSelected(row.id)) {
      const removeSelectedArrayValue = selected.filter(
        (element: any) => element !== row.id
      );
      setSelected(removeSelectedArrayValue);
      setState({ ...state, checkedListId: removeSelectedArrayValue });
      const finalNewArrayObject = rowData.filter(
        (object: any) => object.id !== row.id
      );
      setRowData(finalNewArrayObject);
    } else if (event?.target?.checked) {
      const selectedCheckBoxValue = [...selected, row.id];
      setSelected(selectedCheckBoxValue);
      setState({ ...state, checkedListId: selectedCheckBoxValue });
      const selectArrayGroupName = [...rowData, { id: row.id, name: row.name }];
      setRowData(selectArrayGroupName);
    }
  };

  const selectAllCheckBoxHandler = (event: any, tableData: any) => {
    let checkBoxValue: any[] = [];
    let checkAllRowData: any[] = [];

    if (event.target.checked) {
      const newSelected = tableData.map((item: any) => item.id);
      checkBoxValue = [...selected, ...newSelected];
      setSelected(checkBoxValue);
      setState({ ...state, checkedListId: checkBoxValue });
      const value = tableData.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
      checkAllRowData = [...rowData, ...value];
      setRowData(checkAllRowData);
    } else {
      const newSelected = tableData.map((item: any) => item.id);
      const unCheckSelectAll = selected.filter(
        (item: any) => !newSelected.includes(item)
      );
      setSelected(unCheckSelectAll);
      setState({ ...state, checkedListId: unCheckSelectAll });
      const unSelectedRowData = rowData.filter(
        (item: any) => !newSelected.includes(item.id)
      );
      setRowData(unSelectedRowData);
    }
  };

  const mailingTableHandler = () => {
    switch (currentMailingList) {
      case "Groups":
        return (
          <StepFourTable
            stateData={state}
            singleSelectCheckBox={singleCheckboxHandler}
            selectAllcheckBox={selectAllCheckBoxHandler}
            selectedRowCheckData={selected}
            isSelectedRow={isSelected}
          />
        );
      case "Tags":
        return (
          <TagsTable
            setLoading={setLoader}
            setMailingAudience={setCurrentMailingList}
            stateData={state}
            singleSelectCheckBox={singleCheckboxHandler}
            selectAllcheckBox={selectAllCheckBoxHandler}
            selectedRowCheckData={selected}
            isSelectedRow={isSelected}
          />
        );
      default:
        break;
    }
  };

  const removeDuplicates: any[] = emailUsers.filter(
    (item, index) => emailUsers.indexOf(item) === index
  );

  const getDetailsSection = () => {
    return (
      <Grid container my={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={7}>
          <Box mt={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomInput
                required
                id="campaignName"
                label="Campaign Name"
                placeHolder="Enter your campaign name"
                type="text"
                name="campaignName"
                value={inputType.campaignName.value}
                onChange={handleStepOneOnChange}
                error={
                  !isTruthy(inputType.campaignName.value) &&
                  inputType.campaignName?.error
                }
              />
            </Grid>
          </Box>
          {/* From select dropdown */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3}>
            <Box mt={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box sx={classes.label}>
                  <Typography sx={classes.labelText}>From</Typography>
                  <Typography sx={classes.star}>*</Typography>
                </Box>
                <Autocomplete
                  sx={classes.selectMenu}
                  id="from"
                  options={removeDuplicates?.map((userEmails) => userEmails)}
                  value={inputType.from.value}
                  autoHighlight={true}
                  autoSelect={true}
                  onChange={(e, newVal) => handleFromChangeHandler(e, newVal)}
                  renderInput={(params) => (
                    <TextField
                      sx={classes.dropdownStyle}
                      {...params}
                      name="from"
                    />
                  )}
                />
              </Grid>
            </Box>
          </Grid>
          {/* Display Name input box*/}
          <Box mt={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomInput
                label="Display Name"
                required
                id="displayName"
                type="type"
                name="displayName"
                value={inputType?.displayName?.value}
                onChange={handleStepOneOnChange}
                error={
                  !isTruthy(inputType?.displayName?.value) &&
                  inputType?.displayName?.error
                }
              />
            </Grid>
          </Box>
          <Box mt={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomInput
                required
                label="Subject"
                type="text"
                id="subject"
                InputProps={{ maxLength: 90 }}
                placeHolder="Enter your campaign subject "
                name="subject"
                value={inputType.subject.value}
                onChange={handleStepOneOnChange}
                error={
                  (!isTruthy(inputType.subject.value) &&
                    inputType?.subject?.error) ||
                  (isTruthy(inputType.subject.value) &&
                    !inputType.subject.value?.includes("$FIRST_NAME") &&
                    inputType.subject?.error)
                }
              />
            </Grid>
          </Box>
          {hasCampaignExecutionPermission() ? (
            <>
              <Box mt={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {TagsTableHeader()}
                </Grid>
              </Box>
              <Box mt={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {mailingTableHandler()}
                </Grid>
              </Box>
            </>
          ) : (
            <Typography
              variant="body1"
              sx={{
                color: "#ff4244",
                ...regularFont,
                marginTop: "10px",
              }}
            >
              * You don't have permission to execute the campaigns. Please
              contact your account administrator.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
          <Box mt={4}>{getCampaignDesignAndAttachments()}</Box>
        </Grid>
      </Grid>
    );
  };

  const scheduleTimeHandler = () => {
    if (inputType.scheduleTime.value === "") {
      bulkEmailRequest.scheduleTime = "";
    } else {
      bulkEmailRequest.scheduleTime = moment(inputType.scheduleTime.value)
        .tz("America/New_York")
        .format("MM/DD/yyyy HH:mm:ss");
    }
  };

  const sendSelfTestEmail = async () => {
    try {
      setLoader(true);
      const selfEmailResponse = await getSendTestEmail(bulkEmailRequest);
      if (selfEmailResponse) {
        openSuccessNotification(selfEmailResponse.message);
      }
      setLoader(false);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      setLoader(false);
    }
  };

  const onSendSelftTestEmail = (testEmail: string[]) => {
    scheduleTimeHandler();
    bulkEmailRequest.status = "Submitted";
    bulkEmailRequest.attachments = campaignData?.attachments;
    bulkEmailRequest.content = campaignData?.content;
    bulkEmailRequest.fromId = inputType?.from?.value;
    bulkEmailRequest.owner = campaignData?.owner!;
    bulkEmailRequest.displayName = inputType?.displayName?.value;
    bulkEmailRequest.subject = `[Test] ${inputType?.campaignName?.value}`;
    bulkEmailRequest.name = inputType?.campaignName?.value;
    bulkEmailRequest.testIds = testEmail;
    bulkEmailRequest.data = campaignData?.data;
    sendSelfTestEmail();
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
          <CustomAppHeader className={classes.headerBackgroundColor}>
            {buttonGroup()}
          </CustomAppHeader>
        </Grid>

        <Grid container m={4}>
          <Grid item xs={12} sm={6} md={12} lg={12} xl={12}>
            {getDetailsSection()}
          </Grid>
        </Grid>
      </Grid>
      <ScheduleTimeModal
        setScheduleTimeModal={setScheduleTimeModal}
        isScheduleTimeModal={isScheduleTimeModal}
        setStepOneFormData={setInputType}
        stepOneFormData={inputType}
        submit={onSendBulkEmail}
      />
      <TestMailModal
        isTestMail={isTestMail}
        setTestMail={setTestMail}
        onMultipleTestMail={onSendSelftTestEmail}
      />
      <CustomLoader isLoading={loader} />
    </>
  );
};

export default ResendCampaign;
