import {
  boldFont,
  getRelativeFontSize,
  theme,
  regularFont,
  pureWhiteColor,
  primaryHeadingColor,
  pinkDarkColor,
  buttonWhiteBg,
  purplePrimaryColor,
  mediumFont,
  textLightColor,
} from "utils/styles";

const IntegrationStyles = {
  customPaper: {
    margin: "0px 0px 15px 0px",
  },

  headerWrapper: {
    marginLeft: "15px",
  },
  headerTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
    },
  },
  headerSubTitle: {
    ...regularFont,
    color: "#666666",
    fontSize: "18px",
  },
  paperInnerWrapper: {
    padding: theme.spacing(2),
  },
  socialWrapper: {
    marginTop: "15px",
  },
  contentTitle: {
    ...boldFont,
    color: "#212121",
    fontSize: "28px",
  },
  cardStyle: {
    width: "300px",
    height: "220px",
    padding: "20px",
  },
  SocialCardTitle: {
    color: "#212121",
    ...boldFont,
    fontSize: "22px",
    marginBottom: "10px",
  },
  SocialCardContent: {
    color: "#666666",
    fontSize: "16px",
  },
  CampaignTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    marginTop: "10px",
    color: primaryHeadingColor,
  },
  dropDownStyle: {
    backgroundColor: pureWhiteColor,
    height: "47px",
    padding: "2px",
    width: " 180px",
    borderRadius: "10px",
    fontSize: "14px",
  },
  refreshBtn: {
    height: "50px",
    backgroundColor: pureWhiteColor,
    minWidth: "50px",
    borderRadius: "13px",
    width: "60px",
    "&:hover": {
      backgroundColor: "#c20c85",
    },
  },
  createCampaignBtn: {
    backgroundColor: "#1a0224",
    fontSize: "14px",
    gap: "10px",
    color: "#ffffff",
    height: "50px",
    borderRadius: "13px",
    "&:hover": {
      backgroundColor: "#c20c85",
    },
    "& span:first-child": {
      marginTop: "5px",
    },
  },
  campaignsTabs: {
    marginTop: "20px",
    overflow: "auto",
    width: "100%",
    paddingBottom: "10px",
  },

  tabs: {
    minWidth: "600px",
    "& .MuiTabs-indicator": {
      backgroundColor: "green",
      height: 0,
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#c20c85",
      color: "#ffffff",
    },
  },
  colUserStyle: {
    color: pinkDarkColor,
    ...boldFont,
    padding: "0",
  },
  campaignNameStyle: {
    color: pinkDarkColor,
    ...regularFont,
    fontSize: "14px",
    padding: " 0",
    textTransform: "capitalize",
  },

  tabBtn: {
    background: "#fafafa",
    border: "1px solid #f0f0f0",
    padding: "0 12px",
    marginLeft: "15px",
    borderRadius: "12px",

    color: "#c20c85",
    fontSize: "12px",
    minHeight: "48px",
  },

  inputWrapper: {
    display: "flex",
    alignItem: "center",
    margin: "15px 0",
    justifyContent: "flex-start",
  },

  searchInput: {
    width: "50%",
    padding: "0px",
    height: "50px",
    marginRight: "10px",
    border: "1px solid black",
  },

  CampaignStatusBtn: {
    border: "1px solid #ccc",
    background: "none",
    display: "flex",
    gap: "5px ",
    fontSize: "12px",
    padding: "2px 15px",
    alignItem: "center",
    color: "rgba(0,0,0,0.7)",
  },

  ViewPopOver: {
    display: "none",
  },

  eyesBtn: {
    fontSize: "15px",
    paddingTop: "5px",
    color: "rgba(0,0,0,0.7)",
  },

  dialogTitleWrapper: {
    display: "flex",
    alignItemCenter: "center",

    width: "100%",
    gap: "10px",
  },

  titleLeft: {
    color: "#faad14",
  },

  titleRight: {
    color: " rgba(0,0,0,.85)",
    ...boldFont,
    fontSize: "16px",
  },
  dialogContent: {
    padding: "0px 30px",
    color: "rgba(0,0,0, .85)",
    fontSize: "14px",
    marginTop: "10px",
  },

  dialogFooter: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "flex-end",
  },

  deleteBtn: {
    fontSize: "20px",
    background: "none",
    border: "none",
  },

  ViewPopOverWrapper: {},
  popOverHeading: {
    padding: "5px 16px 5px",
    color: "rgba(0,0,0,.85)",
    ...boldFont,
  },

  popOverListItem: {
    padding: "10px 10px",
    width: "150px",
  },

  // view Result dashBoard css
  dashBoardHeader: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginTop: "15px",
  },
  dashBoardHeaderHeading: {
    fontSize: "20px",
    ...boldFont,
  },

  cardTitle: {
    fontSize: "16px",
    ...boldFont,
    marginLeft: "10px",
  },

  cardWrapper: {
    padding: "20px 10px",
  },

  cardFooter: {
    padding: "10px 0",
  },

  cardFooterDivider: {
    borderRight: "1px solid rgba(0,0,0,.3)",
  },

  details: {
    fontSize: "102px",
  },

  titleFontSize: {
    fontSize: "23px",
    whiteSpace: "nowrap",
  },
  modelBodyContent: {
    margin: "5px 0px 50px 30px",
  },
  bodyContentGroupName: {
    padding: "12px 25px 10px 8px",
    minWidth: "100px",
  },
  customSuccess: {
    maxWidth: 400,
    minWidth: 320,
  },
  checkBoxVisibilty: {
    minWidth: "200px",
  },

  // retargating page css

  textStyle: {
    fontSize: "1.1rem",
  },

  headerDataWrapper: {
    paddingTop: "20px",
    margin: "20px",
  },

  integrationsWrapper: {
    display: "flex",
    alignItems: "center",
  },

  integrationsTitle: {
    color: "#212121",
    fontSize: "18px",
    ...boldFont,
  },

  connectWrapper: {
    display: "flex",
  },

  socialTitle: {
    ...boldFont,
    fontSize: "28px",
  },

  connectContent: {
    paddingLeft: "20px",
  },

  connectPara: {
    ...regularFont,
    color: "#666666",
    fontSize: "18px",
  },

  listItemStyle: {
    color: "#666666",
    ...regularFont,
    display: "list-item",
  },

  listStyle: {
    listStyleType: "disc",
  },

  stepsStyle: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
  },

  socialCardStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "300px",
    height: "220px",
    padding: "20px",
    transition: "all 0.3 ease",
    border: "1px solid #E7E7E7",
    borderRadius: "15px",
    "&:hover": {
      boxShadow: "0px 10px 40px 5px rgba(0, 0, 0, 0.05)",
      background: "#fff",
      transition: "all 0.3 ease",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px -4px -25px",
    },
  },
  integratedMsg: {
    fontSize: "16px",
    color: "#ADC804",
    fontFamily: "Source Sans 3",
    fontWeight: 400,
    lineHeight: "23px",
    fontStyle: "normal",
    marginTop: "15px",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  titleStyle: {
    ...boldFont,
    fontSize: getRelativeFontSize(14),
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  contentStyle: {
    fontSize: getRelativeFontSize(4),
    textAlign: "center",
    ...mediumFont,
    color: textLightColor,
  },
  footerWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    padding: "20px 0",
    "& button": {
      whiteSpace: "noWrap",
    },
  },
  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
} as const;

export default IntegrationStyles;
