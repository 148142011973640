import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import OnboardingStyles from "screens/Onboarding/Onboard.styles";
import { isTruthy, openErrorNotification } from "helpers/methods";
import CardSection from "global/components/CardSection/CardSection";
import { CustomButton } from "global/components";
import { KeyboardBackspace } from "@mui/icons-material";
import { mediumFont, regularFont } from "utils/styles";
import { getPlans } from "screens/Onboarding/OnboardServices";
import notifiers from "global/constants/NotificationConstants";
import BillingUsageStyles from "screens/Settings/BillingUsage/BillingUsage.styles";
import { selectAccount } from "redux/authSlice";
import { useAppSelector } from "utils/hooks";

interface ChangePlanProps {
  setSelectedPlan: any;
  selectedPlan: any;
  handleNext: any;
  handleBack: any;
  plans: any;
  setCurrency?: any;
  currency?: any;
  setCardData?: any;
  cardData?: any;
  setPlans?: any;
  setCardDetailData?: any;
  setLoading?: any;
}

const ChangePlan = (props: ChangePlanProps) => {
  const classes = OnboardingStyles;
  const billingClasses = BillingUsageStyles;
  const accountName = useAppSelector(selectAccount);

  useEffect(() => {
    allPlans();
  }, []);

  const allPlans = async () => {
    try {
      props.setLoading(true);
      const response: any = await getPlans(accountName);
      const res = response.filter((item: any) => item.price !== 0 && item.status === "Active");
      const groupedData: any = {};
      res?.forEach((item: any) => {
        if (!groupedData[item.currency]) {
          groupedData[item.currency] = [];
        }
        groupedData[item.currency].push(item);
      });
      props.setPlans(groupedData);
      props.setCurrency(
        isTruthy(groupedData) ? Object.keys(groupedData)[0] : ""
      );
      props.setCardDetailData(
        isTruthy(groupedData) ? Object.values(groupedData)[0] : []
      );
      props.setLoading(false);
    } catch (error: any) {
      props.setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };
  return (
    <>
      <Stack direction="row" mt={2} mr={2}>
        <IconButton
          onClick={() => {
            props.handleBack();
          }}
          id="change_plan_back_button"
        >
          <KeyboardBackspace />
        </IconButton>
        <Typography sx={classes.headingText}>Pricing</Typography>
      </Stack>
      <Typography
        sx={[
          billingClasses.pricingDescriptionText,
          {
            width: { xs: "100%", sm: "100%", md: "75%", lg: "75%", xl: "75%" },
          },
        ]}
        // ml={2}
      >
        Our email marketing plans for your business are straightforward, fair,
        and affordable. Choose the one that fits your needs the best or contact
        for custom plan!
      </Typography>
      <Stack
        direction={{
          lg: "row",
          md: "column",
          sm: "column",
          xs: "column",
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        mt={3}
      >
        <Box display={"flex"} alignItems="center">
          <Typography marginRight={2} sx={{ ...mediumFont }} variant="h6">
            Select Price Based On Currency
          </Typography>
          <Select
            id="currency_select_dropdown"
            sx={classes.dropdown}
            value={props.currency!}
            onChange={(e: any) => {
              const value = e.target.value;
              const currencyData = props.plans[value];
              props.setCurrency(value);
              props.setCardData(currencyData);
              props.setSelectedPlan({});
            }}
            renderValue={
              props.currency! !== "" ? undefined : () => "Select Currency"
            }
            displayEmpty
          >
            {Object.keys(props.plans).map((item: any, index: number) => (
              <MenuItem
                sx={{
                  ...regularFont,
                }}
                key={index}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Stack>
      <Box
        display={"flex"}
        justifyContent="flex-start"
        alignItems="flex-start"
        flexWrap={"wrap"}
        mt={3}
        gap={2}
      >
        {props.cardData?.length > 1 && (
          <CardSection
            pricingData={props.cardData}
            plan={props.selectedPlan}
            setPlan={props.setSelectedPlan}
            currency={props.currency}
          />
        )}
      </Box>
      {props.cardData?.length > 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <CustomButton
            label="Next"
            onClick={() => {
              if (Object.keys(props.selectedPlan).length === 0) {
                openErrorNotification("Please Select Atleast One Plan");
              } else {
                props.handleNext();
              }
            }}
            disabled={Object.keys(props.selectedPlan).length === 0}
            customClasses={classes.btn}
            id={"change_plan_next_button"}
          />
        </Box>
      )}
    </>
  );
};

export default ChangePlan;
