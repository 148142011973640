import strings from "global/constants/StringConstants";
import { isTruthy } from "helpers/methods";
import { store } from "utils/store";

export const campaignFormDataInitialState = (data: any = undefined) => {
  return {
    id: data?.id ?? null,
    name: {
      value: data?.name ?? "",
      error: "",
    },
    fromId: {
      value: store.getState()?.auth?.email ?? data?.owner,
      error: "",
    },
    displayName: {
      value: store.getState().auth?.userName ?? data?.displayName,
      error: "",
    },
    subject: {
      value: data?.subject ?? "",
      error: "",
    },
    scheduleTime: {
      value: data?.scheduleTime ?? "",
      error: "",
    },
    content: data?.content ?? "",
    attachments: data?.attachments ?? {},
    target:
      data && data?.groups?.[0]?.type !== ""
        ? data?.groups?.[0]?.type
        : strings.groupMailingListType,
    groups:
      data && data?.groups?.[0]?.type !== ""
        ? data?.groups
        : [{ type: strings.groupMailingListType, value: [] }],
    owner: store.getState().auth.email,
    testIds: [],
    status: "",
    data: {
      spamPercentage: data?.data?.spamPercentage ?? 0,
    },
    domain: {
      value: data?.data?.domainName ?? "",
      error: "",
    },
    // mailBcc: [],
    // mailCc: [],
    // mailTo: [],
    // host: "",
    // env: "",
    // contentType: "",
  };
};

export const mapFormDataToValues = (formData: any) => {
  return {
    ...formData,
    name: formData.name.value,
    fromId: formData.fromId.value,
    displayName: formData.displayName.value,
    subject: formData.subject.value,
    scheduleTime: formData.scheduleTime.value,
    data: {
      ...formData.data,
      domainName: isTruthy(formData.domain.value)
        ? formData.domain.value
        : undefined,
    },
  };
};

export const handleStep1Validation = (
  campaignData: any,
  areCustomDomainsAvailable: boolean
) => {
  let isValid = true;
  let errors = { ...campaignData };
  if (!campaignData?.name?.value) {
    isValid = false;
    errors.name.error = "Please enter campaign name";
  }
  if (areCustomDomainsAvailable && !campaignData?.domain?.value) {
    isValid = false;
    errors.domain.error = "Please select the domain";
  }
  return { isValid, errors };
};

export const handleStep2Validation = (campaignData: any) => {
  let isValid = true;
  let errors = { ...campaignData };
  if (!campaignData?.fromId?.value) {
    isValid = false;
    errors.fromId.error = "Please select value from dropdown";
  }
  if (!campaignData?.displayName?.value) {
    isValid = false;
    errors.displayName.error = "Please enter display name";
  }
  return { isValid, errors };
};

export const handleStep3Validation = (campaignData: any) => {
  let isValid = true;
  let errors = { ...campaignData };
  if (!campaignData?.subject?.value) {
    isValid = false;
    errors.subject.error = "Please enter subject line";
  }
  return { isValid, errors };
};

export const campaignerGroupHeader = [
  {
    name: "Name",
    field: "name",
    sorting: true,
  },
  {
    name: "Group type",
    field: "groupType",
    sorting: true,
  },
  {
    name: "Owner",
    field: "owner",
    sorting: true,
  },
  {
    name: "Members",
    field: "recipientCount",
    sorting: true,
  },
];

export const campaignGroupOptionAdmin = [
  {
    id: 0,
    label: "All Groups",
    value: "all",
  },

  {
    id: 2,
    label: "My Groups",
    value: "my",
  },
  {
    id: 3,
    label: "Team's Group",
    value: "team",
  },
];

export const campaignGroupOptionUser = [
  {
    id: 0,
    label: "All Groups",
    value: "all",
  },
  {
    id: 2,
    label: "My Groups",
    value: "my",
  },
];
