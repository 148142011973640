import {
  getRelativeFontSize,
  boldFont,
  regularFont,
  theme,
  inputLabelRequiredColor,
  pinkThemedMenuItems,
  purpleThemedSelectComponent,
  pinkDarkColor,
  mediumFont,
  darkPurpledColor,
} from "utils/styles";

const CampaignDetailsSectionsStyles = {
  viewModeBox: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  headerLabel: {
    ...boldFont,
    fontSize: getRelativeFontSize(5),
  },
  headerSubDescription: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
  },
  descriptionText: {
    ...regularFont,
    margin: "10px 0",
    fontSize: getRelativeFontSize(2),
  },
  inputLabel: {
    fontSize: getRelativeFontSize(7),
    fontColor: theme.palette.common.black,
    ...boldFont,
    marginTop: "10px",
    display: "flex",
  },
  placeholderText: {
    ...regularFont,
    color: "#999999",
  },
  inputFieldStyles: {
    fontSize: "16px",
    height: "47px",
    borderRadius: "15px",
    width: "100%",
    ...regularFont,
    ...purpleThemedSelectComponent,
  },
  fromDetailItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  fromNameAndPhoneContainer: { display: "flex" },
  fromNameTextStyles: {
    margin: "5px",
    ...boldFont,
    color: darkPurpledColor,
    fontSize: getRelativeFontSize(2),
  },
  fromPhoneNumber: {
    display: "flex",
    alignItems: "center",
    margin: "0 5px",
  },
  phoneIconStyles: { fontSize: "20px" },
  phoneTextStyles: { margin: "5px" },
  errorStyles: { paddingLeft: "15px" },
  optionStyle: {
    ...regularFont,
    ...pinkThemedMenuItems,
    display: "flex",
    justifyContent: "space-between",
  },
  star: {
    ...boldFont,
    marginLeft: "5px",
    color: inputLabelRequiredColor,
  },
  buttonStyles: {
    width: "145px",
    borderRadius: "8px",
    backgroundColor: pinkDarkColor,
  },
  addTagChip: {
    marginLeft: "5px",
    marginBottom: "8px",
    borderRadius: "30px",
    ...mediumFont,
    fontSize: "15px",
    backgroundColor: "#fcf5ff",
  },
  inputFieldContainer: { marginBottom: "20px" },
  toPhoneNumberAddButtonContainer: { width: "150px" },
  toPhoneNumberStack: {
    display: "flex",
    flexDirection: "row",
    marginTop: "15px",
  },
  customContactInputLabel: {
    display: "flex",
    fontColor: theme.palette.common.black,
    ...boldFont,
  },
} as const;

export default CampaignDetailsSectionsStyles;
