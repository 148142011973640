import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import customSimpleTableStyles from "global/components/CustomSimpleTable/CustomSimpleTable.styles";
import NoData from "assets/images/tableNoData.svg";
import { simpleTableHeader } from "models/interfaces";
import { isTruthy } from "helpers/methods";

interface CustomProps {
  tableHeader: simpleTableHeader[];
  tableRows: any[];
  tableFooter?: any[];
  emptyTableMessage?: string;
  emptyTableImage?: string;
  isLoading?: boolean;
}

const CustomSimpleTable = (props: CustomProps) => {
  const classes = customSimpleTableStyles;

  const getTableHeader = () => {
    return (
      <TableHead>
        <TableRow sx={classes.tableHeaderRow}>
          {props.tableHeader.map((headerItem, index) => (
            <TableCell
              sx={[classes.tableHeaderCell, headerItem?.styles]}
              align={headerItem.align}
              key={index}
            >
              {headerItem.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const getTableBody = () => {
    return props.tableRows?.length > 0
      ? getTableRows()
      : !props.isLoading && getEmptyTableMessage();
  };

  const getTableFooter = () => {
    return isTruthy(props?.tableFooter) ? getFooterRows() : null;
  };

  const getTableRows = () => {
    return (
      <TableBody sx={classes.tableBody}>
        {props.tableRows.map((rowItem: any, index: number) => (
          <TableRow sx={classes.tableRow} key={index}>
            {getTableRowCells(rowItem)}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const getTableRowCells = (rowItem: any) => {
    return props.tableHeader.map((headerItem: any, index: number) => (
      <TableCell key={index} sx={classes.tableRowCells}>
        {rowItem[headerItem.field]}
      </TableCell>
    ));
  };

  const getFooterRows = () => {
    return (
      <TableBody sx={classes.tableBody}>
        {props?.tableFooter?.map((rowItem: any, index: number) => (
          <TableRow sx={classes.tableFooterRow} key={index}>
            {getTableFooterCells(rowItem)}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const getTableFooterCells = (rowItem: any) => {
    return props.tableHeader.map((headerItem: any, index: number) => (
      <TableCell sx={classes.tableRowCells} key={index}>
        {rowItem[headerItem.field]}
      </TableCell>
    ));
  };

  const getEmptyTableMessage = () => {
    return (
      <TableCell colSpan={8} style={classes.noDataTableCell}>
        <Box textAlign="center" sx={{}}>
          <Box
            component="img"
            src={props.emptyTableImage ? props.emptyTableImage : NoData}
            overflow="auto"
            height="100px"
            width="100%"
          />
          <Typography sx={classes.mediumFonts}>
            {isTruthy(props.emptyTableMessage)
              ? props.emptyTableMessage
              : "We've got nothing for you, sorry!"}
          </Typography>
        </Box>
      </TableCell>
    );
  };

  const getSimpleTable = () => {
    return (
      <Box>
        <Table sx={classes.table}>
          {getTableHeader()}
          {getTableBody()}
          {getTableFooter()}
        </Table>
      </Box>
    );
  };

  return getSimpleTable();
};

export default CustomSimpleTable;
