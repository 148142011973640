import urls from "global/constants/UrlConstants";
import { GlobalInterval } from "models/interfaces";
import { getCallParams, makeCall } from "utils/service";

export const getLatestCampaignStats = async (status: string) => {
  try {
    const callParams = await getCallParams("GET");
    const url = `${urls.latestCampaign}/${status}`;
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignRecipientsDataByDates = async (
  interval: GlobalInterval
) => {
  try {
    const callParams = await getCallParams("GET");
    const url = `${urls.campaignRecipientsDataByDates}/${interval.fromDate}/${interval.toDate}`;
    const response: [] = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchCampaignBastedOnDates = async (interval: GlobalInterval) => {
  try {
    const callParams = await getCallParams("GET");
    const url = `${urls.fetCampaignsByDates}/${interval.fromDate}/${interval.toDate}`;
    const response: [] = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getCampaignsMetricsByTypeAndDates = async (
  interval: GlobalInterval
) => {
  try {
    const callParams = await getCallParams("GET");
    const url = `${urls.campaignsMetricsByTypeAndDates}/${interval.fromDate}/${interval.toDate}`;
    const response: [] = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const fetchMySocialCampaignsListByDate = async (
  interval: GlobalInterval
) => {
  try {
    const callParams = await getCallParams("GET");
    const url = `${urls.socialCampaignListByDates}/${interval.fromDate}/${interval.toDate}`;
    const response: [] = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
