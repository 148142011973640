import {
  boldFont,
  mainContainer,
  theme,
  regularFont,
  primaryHeadingColor,
  getRelativeFontSize,
  mediumFont,
  buttonWhiteBg,
  purplePrimaryColor,
  textLightColor,
  infoTextColor,
  pinkDarkColor,
  pureWhiteColor,
  activeMenuColor,
  lightPinkColor,
} from "utils/styles";

const TemplateStyles = {
  headerStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: lightPinkColor,
  },
  headerGridContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px 10px 20px 10px",
  },
  mainHeading: {
    TextAlign: "center",
    ...boldFont,
    fontSize: getRelativeFontSize(18),
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  tabsStack: {
    marginTop: "20px",
    marginLeft: "30px",
  },
  newTemplateBtn: {
    width: "80%",
    color: textLightColor,
    background: "#ECECEC",
    "&:hover": {
      background: pinkDarkColor,
      color: pureWhiteColor,
    },
  },
  mediumFonts: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
  },
  noTemplatesMainBox: {
    border: "1px solid #F0F0F0",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    margin: "20px 45px",
    padding: "20px",
  },
  editBtn: {
    width: "60%",
    color: pureWhiteColor,
    background: activeMenuColor,
    marginTop: "30px",
    "&:hover": {
      background: pinkDarkColor,
      color: pureWhiteColor,
    },
  },
  addTemplateGridItem: {
    height: "350px",
    border: "1px solid #E7E7E7",
    background: pureWhiteColor,
    borderRadius: "10px",
  },
  templateGridItem: {
    height: "350px",
    borderRadius: "10px",
    position: "relative",
    background: "#ecf0f1",
  },
  ribbon: {
    width: "150px",
    height: "150px",
    overflow: "hidden",
    position: "absolute",
    top: "-10px",
    left: "-10px",
    "&::before": {
      position: "absolute",
      content: "''",
      display: "block",
      border: "5px solid #9b146c",
      top: "0",
      right: "30px",
    },
    "&::after": {
      position: "absolute",
      content: "''",
      display: "block",
      border: "5px solid #9b146c",
      bottom: "30px",
      left: "0",
    },
  },
  ribbonText: {
    position: "absolute",
    width: "250px",
    padding: "5px 0",
    backgroundColor: pinkDarkColor,
    color: pureWhiteColor,
    textAlign: "center",
    transform: "rotate(-45deg)",
    top: "30px",
    right: "-25px",
    zIndex: "1",
  },
  templateCard: {
    height: "350px",
    overflow: "hidden",
  },
  systemRibbon: {
    width: "150px",
    height: "150px",
    overflow: "hidden",
    position: "absolute",
    top: "-10px",
    left: "-10px",
    "&::before": {
      position: "absolute",
      content: "''",
      display: "block",
      borderTop: "20px solid transparent",
      borderLeft: "20px solid #ADC804",
      borderBottom: "20px solid transparent",
      borderRight: "20px solid transparent",
      top: "30px",
      left: "110px",
      zIndex: "1",
    },
    "&::after": {
      position: "absolute",
      content: "''",
      display: "block",
      borderTop: "5px solid #7D9001",
      borderLeft: "5px solid transparent",
      borderBottom: "5px solid transparent",
      borderRight: "5px solid #7D9001",
      top: "70px",
      left: "0",
    },
  },
  systemRibbonText: {
    position: "absolute",
    height: "30px",
    width: "110px",
    padding: "5px 0",
    backgroundColor: "#ADC804",
    color: pureWhiteColor,
    textAlign: "center",
    top: "30px",
    zIndex: "1",
  },
  templateCardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "10px 0",
  },
  moreButton: {
    position: "absolute",
    top: "23px",
    boxShadow: "-5px 6px 12px -7px rgba(0,0,0,1)",
    zIndex: "1",
    border: `1px solid ${pureWhiteColor} `,
    width: "30px",
    height: "30px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: infoTextColor,
    marginRight: "10px",
    background: pureWhiteColor,
    cursor: "pointer",
  },
  templateImgWrapper: {
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
  templateHover: {
    ...boldFont,
    fontSize: getRelativeFontSize(4),
    position: "absolute",
    inset: "0",
    background: "rgba(255,255,255,0.9)",
    border: "1px solid #ecf0f1",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  createdOnStyles: {
    ...regularFont,
  },
  updatedOnStyles: {
    ...regularFont,
  },
  templateNameStyles: {
    ...boldFont,
  },
  cardToggleContent: {
    padding: "15px 30px",
    gap: "5px",
    display: "flex",
    flexDirection: "column",
  },
  ToggleContent: {
    ...mediumFont,
    cursor: "pointer",
  },
  ToggleContentColor: {
    color: pinkDarkColor,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    ...boldFont,
    fontSize: getRelativeFontSize(14),
  },
  footerWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    padding: "10px 0",
    "& button": {
      whiteSpace: "noWrap",
      width: "120px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  buttonGrayBg: {
    background: buttonWhiteBg,
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  contentStyle: {
    fontSize: getRelativeFontSize(4),
    ...mediumFont,
    color: textLightColor,
  },
  mainSection: {
    ...mainContainer,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
  label: { display: "flex" },
  labelText: { ...boldFont, marginLeft: "6px" },
  saveButtonStyle: {
    width: "100%",
    marginTop: "5px",
  },
  labelSection: {
    display: "flex",
    alignItems: "flex-end",
  },
  dragDropTextColor: {
    color: "#ff4244",
    ...regularFont,
    padding: "0 0 10px 20px",
  },
  unlayerScrollStyle: {
    overflow: "auto",
    overflowY: "hidden",
  },
  dialogTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "10px",
    textAlign: "center",
  },
  titleRight: {
    color: "rgba(0,0,0,.85)",
    ...boldFont,
    fontSize: getRelativeFontSize(14),
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
  },
  deleteGroupBodyStyle: {
    display: "flex",
    justifyContent: "center",
    ...mediumFont,
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  textBold: {
    ...boldFont,
  },
  fontText: {
    ...regularFont,
    paddingLeft: "5px",
  },
  dialogFooter: {
    gap: "10px",
    paddingBottom: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& button": {
      width: "120px",
    },
  },
} as const;

export default TemplateStyles;
