import {
  getRelativeFontSize,
  primaryHeadingColor,
  theme,
  boldFont,
  regularFont,
  textLightColor,
} from "utils/styles";

const CreateCampaignsStyles = {
  headerSection: {
    backgroundColor: "#fcf5ff",
    padding: "35px 20px 18px 20px",
  },
  headerGridContainer: {
    my: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      direction: "column",
    },
  },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  },
  pageDescription: {
    marginTop: "5px",
    ...regularFont,
    color: textLightColor,
    fontSize: "18px",
  },
  mainContentBox: {
    padding: theme.spacing(4),
  },
} as const;

export default CreateCampaignsStyles;
