import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import CustomCheckboxDropDownStyles from "./CustomCheckboxDropDown.styles";
import CustomButton from "../CustomButton/CustomButton";
import { centerItemFlex, whiteThemedMenuItems } from "utils/styles";
import { downloadOpenedClickReport } from "screens/CampaignsNew/Campaigns/Campaign.services";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MomentHelpers from "helpers/MomentHelpers";

interface CustomProps {
  options: string[];
  campaignId?: string;
  counts?: any;
}

const CustomCheckboxDropDown = (props: CustomProps) => {
  const classes = CustomCheckboxDropDownStyles;
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const { Requested, Sent, Success, ...filteredCounts } = props?.counts;
  const userTimeZone = MomentHelpers.guessTheTimeZone();

  const handleDownload = async () => {
    try {
      if (isTruthy(selectedOptions)) {
        setIsLoading(true);
        const response = await downloadOpenedClickReport(
          props?.campaignId,
          userTimeZone,
          selectedOptions
        );
        openSuccessNotification(response.message);
        setSelectedOptions([]);
        setIsLoading(false);
        setOpen(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const handleActionChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.checked;
    if (value) {
      if (name === "All") {
        setSelectedOptions(["All"]);
      } else {
        setSelectedOptions((prevOptions) => {
          const newOptions = [
            ...prevOptions.filter((option) => option !== "All"),
            name,
          ];

          if (newOptions.length === props.options.length - 1) {
            return ["All"];
          }

          return newOptions;
        });
      }
    } else {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((item) => item !== name)
      );
    }
  };

  const disableCheckBox = (option: string) => {
    if (Object.keys(filteredCounts).length === 0) {
      return true;
    }
    if (option === "All") {
      return props.options.some((opt) => filteredCounts[opt] === 0);
    }
    return filteredCounts[option] === 0;
  };

  return (
    <Box>
      <Select
        id="action_dropdown"
        name="actions"
        sx={classes.dropdown}
        value={selectedOptions}
        multiple
        displayEmpty
        renderValue={() => selectedOptions.join(", ") || "Reports"}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <FormControl>
          <FormGroup>
            {props.options.map((option: any, index: number) => (
              <MenuItem
                key={option}
                value={option}
                sx={classes.dropdownOptions}
              >
                <FormControlLabel
                  label={option}
                  control={
                    <Checkbox
                      id="_checkbox"
                      name={option}
                      checked={selectedOptions.includes(option)}
                      onChange={(e: any) => handleActionChange(e)}
                      disabled={disableCheckBox(option)}
                    />
                  }
                  sx={{
                    width: "100%",
                    margin: "0px",
                  }}
                />
              </MenuItem>
            ))}
          </FormGroup>
        </FormControl>

        <MenuItem
          key={"btn"}
          sx={{ ...centerItemFlex, ...whiteThemedMenuItems }}
        >
          <CustomButton
            id="report_button"
            label="Download"
            onClick={handleDownload}
            customClasses={classes.downloadBtn}
            endIcon={<FileDownloadIcon fontSize="small" />}
          />
        </MenuItem>
      </Select>
    </Box>
  );
};

export default CustomCheckboxDropDown;
