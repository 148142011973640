import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "utils/hooks";
import { isTemplateBlockingState } from "redux/blockingSlice";
import classes from "screens/Templates/Template.styles";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import { CustomButton, CustomInput } from "global/components";
import {
  SearchFieldValidation,
  searchField,
} from "screens/Templates/TemplateTypeValidation";
import EmailEditor, { Design } from "react-email-editor";
import urls from "global/constants/UrlConstants";
import { RouterPrompt } from "global/components/CustomRouterPrompt/RouterPrompt";
import {
  CampaignTemplateType,
  HandleTemplateType,
  errorsType,
  exportHtmlType,
} from "models/interfaces";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import {
  addCampaignTemplate,
  getCampaignTemplateById,
  getFooterTemplate,
  updateCampaignTemplate,
} from "screens/Templates/TemplatePageService";
import history from "utils/history";
import { selectEmail } from "redux/authSlice";
import { useLocation } from "react-router-dom";
import strings from "global/constants/StringConstants";
import CustomLoader from "global/components/CustomLoader/CustomLoader";

const TemplateEditor = () => {
  const pathname = window.location.pathname;
  const urlParams = useLocation().search;
  const templateId = new URLSearchParams(urlParams).get("id");
  const [formField, setFormField] = useState<any>(searchField());
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [routerPromptExitMode, setRouterPromptExitMode] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBackButtonClicked, setIsBackButtonClicked] =
    useState<boolean>(false);
  const [draftJson, setDraftJson] = useState<any>();
  const [templateAccess, setTemplateAccess] = useState<string[]>([""]);
  const [isBlocking, setIsBlocking] = useState<boolean>(false);
  const [defaultFooterTemplate, setDefaultFooterTemplate] = useState<Design>();
  const emailEditorRef = useRef<any>();
  const templateBlockState = useAppSelector(isTemplateBlockingState);
  const userId = useAppSelector(selectEmail);

  useEffect(() => {
    setIsBlocking(false);
  }, [templateBlockState]);

  useEffect(() => {
    if (!showPrompt && isBackButtonClicked) {
      setIsBackButtonClicked(false);
    }
  }, [showPrompt]);

  useEffect(() => {
    if (templateId) {
      getTemplateById();
    } else if (pathname !== urls.templateAddPath) {
      history.push(urls.templateViewPath);
    } else {
      getDefaultFooterTemplate();
      setIsBlocking(true);
    }
  }, []);

  const getTemplateById = async () => {
    try {
      setIsLoading(true);
      const response = await getCampaignTemplateById(templateId ?? "");
      const isUserTemplate = response.owner === userId;
      handleViewTemplate(response, isUserTemplate);
    } catch (error: any) {
      setIsBlocking(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      history.push(urls.templateViewPath);
    }
  };

  const getDefaultFooterTemplate = async () => {
    try {
      setIsLoading(true);
      const response = await getFooterTemplate();
      setDefaultFooterTemplate(response);
    } catch (error: any) {
      setIsBlocking(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      history.push(urls.templateViewPath);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (unlayerJSON: Design, exitCallBack: Function) => {
    try {
      setIsLoading(true);
      const campaign: CampaignTemplateType = {
        name: formField.templateName.value.trim(),
        usersJson:
          formField.category.value === "System" ||
          !formField.isUserTemplate.value
            ? [""]
            : templateAccess,
        jsonContent: JSON.stringify(unlayerJSON),
        customTools: [urls.customSocialTool, urls.customVideoTool],
        id:
          isEditing &&
          (formField.category.value === "System" ||
            !formField.isUserTemplate.value)
            ? undefined
            : templateId,
      };

      if (campaign.name && campaign.jsonContent) {
        const updateCampaign =
          isEditing &&
          formField.category.value !== "System" &&
          formField.isUserTemplate.value
            ? await updateCampaignTemplate(campaign)
            : await addCampaignTemplate(campaign);
        openSuccessNotification(updateCampaign.message);
        exitCallBack();
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidation = () => {
    const { isValid, errors }: { isValid: boolean; errors: errorsType } =
      SearchFieldValidation(formField);
    setFormField({ ...errors });
    return isValid;
  };

  const loadDesign = () => {
    if (emailEditorRef.current) {
      if (draftJson) {
        // Edit Template
        emailEditorRef.current.loadDesign(draftJson);
      } else {
        // New Template with Default White Background
        emailEditorRef?.current?.loadDesign(defaultFooterTemplate);
      }
    }
  };

  const exportHtml = (isSaveAndExit: boolean = false) => {
    if (handleValidation() && formField.templateName.value.length <= 50) {
      setShowPrompt(false);
      emailEditorRef.current.editor.exportHtml(async (data: exportHtmlType) => {
        const { design, html } = data;
        if (!isBackButtonClicked && isSaveAndExit) {
          await handleSave(design, exit);
        } else {
          await handleSave(design, handleExitUnlayer);
        }
      });
    }
  };

  const handleViewTemplate = (
    selectedTemplate: HandleTemplateType,
    isUserTemplate: boolean
  ) => {
    const jsonData = JSON.parse(selectedTemplate.jsonContent);
    setIsEditing(true);
    setDraftJson(jsonData);
    setFormField({
      ...formField,
      templateName: {
        ...formField.templateName,
        value:
          selectedTemplate.owner === userId
            ? selectedTemplate.name
            : `Copy of ${selectedTemplate.name}`,
      },
      category: {
        value: selectedTemplate.category,
      },
      isUserTemplate: {
        value: isUserTemplate,
      },
    });
    setTemplateAccess(selectedTemplate.users);
    setIsLoading(false);
  };

  const handleExitUnlayer = () => {
    setFormField(searchField());
    setIsBlocking(false);
    setIsEditing(false);
    setDraftJson(null);
    setTimeout(() => history.push(urls.templateViewPath), 0);
  };

  const exit = () => {
    if (isBackButtonClicked) {
      handleExitUnlayer();
    } else {
      setIsBlocking(false);
      setTimeout(() => history.push(routerPromptExitMode), 0);
    }
  };
  const continueEditing = () => {
    setShowPrompt(false);
  };

  const handleOnchange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value !== undefined) {
      setFormField({
        ...formField,
        [event.target.name]: {
          ...formField[event.target.name],
          value: event.target.value,
          error: "",
        },
      });
    }
  };

  const title = () => {
    return (
      <>
        <Box sx={classes.titleWrapper}>
          <Typography sx={classes.title}>Hold On!</Typography>
        </Box>
      </>
    );
  };

  const getFooter = () => {
    return (
      <Box sx={classes.footerWrapper}>
        <CustomButton
          label="Discard Changes"
          onClick={exit}
          customClasses={classes.buttonWhiteBg}
          id="template_discard_change_button"
        />
        <CustomButton
          label="Save & Exit"
          onClick={() => exportHtml(true)}
          loading={isLoading}
          customClasses={classes.buttonGrayBg}
          id="template_save_change_button"
        />
        <CustomButton
          label="Continue Editing"
          onClick={continueEditing}
          id="template_continue_change_button"
        />
      </Box>
    );
  };

  const bodyContent = () => {
    return (
      <Box sx={classes.contentWrapper}>
        <Typography sx={classes.contentStyle}>
          You may lose your changes
        </Typography>
      </Box>
    );
  };
  return (
    <>
      <Box sx={classes.mainSection}>
        <IconButton
          onClick={() => {
            setIsBackButtonClicked(true);
            setShowPrompt(true);
          }}
        >
          <KeyboardBackspace />
        </IconButton>
        <Grid container spacing={3}>
          <Grid
            item
            xl={2}
            lg={3}
            md={4}
            sm={4}
            xs={10}
            sx={classes.labelSection}
          >
            <CustomInput
              id="templates_name_field"
              placeHolder="Enter template name"
              required
              label="Template Name"
              type="text"
              name="templateName"
              value={formField.templateName.value}
              onChange={(event: any) => handleOnchange(event)}
              error={
                (!isTruthy(formField.templateName.value) &&
                  formField.templateName.error) ||
                (formField.templateName.value.length > 50 &&
                  formField.templateName.error)
              }
            />
          </Grid>
          <Grid
            item
            xl={2}
            lg={3}
            md={4}
            sm={4}
            xs={10}
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              id="templates_submit_button"
              customClasses={classes.saveButtonStyle}
              label={pathname === urls.templateAddPath ? "Save" : "Update"}
              disabled={isLoading && !draftJson}
              onClick={() => exportHtml(false)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography variant="body1" sx={classes.dragDropTextColor}>
          * Drag and Drop HTML Block &#10094;/&#10095; from Unlayer Content to
          paste your html.
        </Typography>
      </Box>
      {((templateId && draftJson) ||
        (pathname === urls.templateAddPath &&
          isTruthy(defaultFooterTemplate))) && (
        <Box sx={classes.unlayerScrollStyle}>
          <EmailEditor
            ref={emailEditorRef}
            onReady={() => loadDesign()}
            projectId={strings.UNLAYER_PROJECT_ID}
            minHeight={800}
            options={{
              features: {
                textEditor: {
                  tables: true,
                },
              },
              customJS: [urls.customSocialTool, urls.customVideoTool],
            }}
          />
        </Box>
      )}
      <RouterPrompt
        closeModal={true}
        when={isBlocking}
        titleContent={title()}
        footerContent={getFooter()}
        showPrompt={showPrompt}
        bodyContent={bodyContent()}
        setShowPrompt={setShowPrompt}
        setRouterPromptExitMode={setRouterPromptExitMode}
      />
      <CustomLoader isLoading={isLoading} />
    </>
  );
};
export default TemplateEditor;
