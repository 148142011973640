import { useEffect, useState } from "react";
import { getClickActionDetails } from "screens/CampaignsNew/Campaigns/Campaign.services";
import { isTruthy, openErrorNotification } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { Box, Stack, Typography } from "@mui/material";
import CustomSimpleTable from "global/components/CustomSimpleTable/CustomSimpleTable";
import { clickedUrlsData, simpleTableHeader } from "models/interfaces";
import StatsStyles from "screens/Stats/Stats.styles";
import noClickedLinks from "assets/images/noClickedLinks.svg";
import { HtmlTooltip } from "utils/styles";
import CustomInfoTooltip from "global/components/CustomInfoTooltip/CustomInfoTooltip";
import strings from "global/constants/StringConstants";

interface TableProps {
  id: string;
  isLoading: boolean;
  setIsLoading: Function;
  isRefresh: boolean;
}

const ClickedLinksTable = (props: TableProps) => {
  const classes = StatsStyles;
  const [tableFormattedRow, setTableFormattedRow] = useState<any[]>([]);
  const [tableFooterData, setTableFooterData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const columns: simpleTableHeader[] = [
    {
      name: "Rank",
      field: "rank",
      align: "left",
    },
    {
      name: "Link (URL)",
      field: "url",
      align: "left",
    },
    {
      name: "Clicks",
      field: "count",
      align: "center",
    },
  ];

  useEffect(() => {
    clickedUrls();
    setTableFooterData([]);
  }, [props.id, props.isRefresh]);

  const clickedUrls = async () => {
    try {
      props.setIsLoading(true);
      const response: clickedUrlsData[] = await getClickActionDetails(props.id);
      let totalCountUpdated = 0;
      const formattedRowData = response.map((item: any, index: number) => {
        totalCountUpdated += item?.count;
        return getTableFormattedRows(item, index);
      });

      isTruthy(response) &&
        setTableFooterData([
          {
            rank: (
              <Box>
                <Typography sx={classes.clickedUrlTextStyles}>
                  Total Clicks
                </Typography>
              </Box>
            ),
            count: (
              <Box>
                <Typography sx={classes.clickedUrlCountStyles}>
                  {totalCountUpdated}
                </Typography>
              </Box>
            ),
          },
        ]);

      setTableFormattedRow(formattedRowData);
      setTotalCount(totalCountUpdated);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.messages : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const getTableFormattedRows = (urlsData: any, index: number) => {
    return {
      rank: (
        <Box>
          <Typography sx={classes.clickedUrlTextStyles}>{index + 1}</Typography>
        </Box>
      ),
      url: (
        <>
          <HtmlTooltip
            title={urlsData.url}
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  width: "auto",
                  background: "none",
                },
              },
            }}
          >
            <Box>
              <Typography
                component="a"
                href={urlsData.url}
                target="_blank"
                sx={classes.clickedUrlTextStyles}
              >
                {urlsData.url}
              </Typography>
            </Box>
          </HtmlTooltip>
        </>
      ),
      count: (
        <Box>
          <Typography sx={classes.clickedUrlCountStyles}>
            {urlsData.count}
          </Typography>
        </Box>
      ),
    };
  };
  return (
    <Box sx={classes.clickedLinksMainContainer}>
      <Stack display={"flex"} direction={"row"}>
        <Box sx={classes.linkTrackingBox}>
          <Typography py={2} sx={classes.clickedLinksTableTitle}>
            Link Tracking
          </Typography>
        </Box>
        <Box ml={1} pt={"5px"} sx={classes.linkTrackingBox}>
          <CustomInfoTooltip
            placement="top"
            infoMessage={strings.Link_Tracking_Table_Info}
          />
        </Box>
      </Stack>

      <CustomSimpleTable
        tableHeader={columns}
        tableFooter={tableFooterData}
        tableRows={tableFormattedRow}
        emptyTableMessage={"No data to display!"}
        emptyTableImage={noClickedLinks}
        isLoading={props.isLoading}
      />
    </Box>
  );
};

export default ClickedLinksTable;
