import { useState, useEffect } from "react";
import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import DomainDetailsStyles from "screens/Settings/Domains/DomainDetails/DomainDetails.styles";
import verifyStatus from "assets/icons/verifyStatus.svg";
import { CustomButton } from "global/components";
import {
  copyTextHandler,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { getDomainDetails } from "screens/Settings/Domains/Domains.service";
import CustomSimpleTable from "global/components/CustomSimpleTable/CustomSimpleTable";
import { domainDetailsTableHeader } from "screens/Settings/Domains/DomainsHelpers";
import copyIcon from "assets/icons/copyIcon.svg";
import redInfoIcon from "assets/icons/redInfoIcon.svg";

interface CustomProps {
  domainToBeDisplayed: string;
  isLoading: boolean;
  setIsLoading: Function;
}

const DomainDetails = (props: CustomProps) => {
  const classes = DomainDetailsStyles;

  const [domainDetails, setDomainDetails] = useState<any>({});

  useEffect(() => {
    fetchDomainStatus();
  }, []);

  const fetchDomainStatus = async () => {
    try {
      props.setIsLoading(true);
      const response = await getDomainDetails(props.domainToBeDisplayed);
      const formattedRows = getFormattedDomainDetails(response);
      setDomainDetails(formattedRows);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const getFormattedDomainDetails = (rawDomainDetails: any) => {
    const formattedRowArray: any[] = [
      {
        ...getTableRow(
          rawDomainDetails.data.custom_envelope.host,
          rawDomainDetails.data.custom_envelope.type,
          rawDomainDetails.data.custom_envelope.required_value,
          rawDomainDetails.data.custom_envelope.valid
        ),
      },
      {
        ...getTableRow(
          rawDomainDetails.data.dkim.host,
          rawDomainDetails.data.dkim.type,
          rawDomainDetails.data.dkim.required_value,
          rawDomainDetails.data.dkim.valid
        ),
      },
    ];
    return formattedRowArray;
  };

  const getStatus = (status: number) => {
    switch (status) {
      case 0:
        return (
          <Chip
            sx={[classes.statusChip, { backgroundColor: "#FEE2DB" }]}
            label="Not Updated"
          />
        );
      case 1:
        return (
          <Chip
            sx={[classes.statusChip, { backgroundColor: "#DCF6EA" }]}
            label="Updated"
          />
        );
    }
  };

  const getTableRow = (
    hostName: string,
    type: String,
    requiredValue: string,
    status: number
  ) => {
    return {
      hostName: (
        <Box>
          <Typography sx={classes.hostNameTextStyles}>{hostName}</Typography>
        </Box>
      ),
      type: (
        <Box>
          <Typography sx={classes.typeTextStyles}>{type}</Typography>
        </Box>
      ),
      requiredValue: (
        <Box>
          <TextField
            disabled
            sx={classes.textField}
            variant="outlined"
            value={requiredValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={copyIcon}
                    onClick={() => {
                      copyTextHandler(requiredValue);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={classes.requiredValueDescriptionBox}>
            <img src={redInfoIcon} style={classes.redInfoIconStyles} />
            <Typography sx={classes.requiredValueInfo}>
              Copy the record and place it in your DNS settings
            </Typography>
          </Box>
        </Box>
      ),
      status: (
        <Box>
          <Typography>{getStatus(status)}</Typography>
        </Box>
      ),
    };
  };

  const verifyStatusButtonHandler = () => {
    fetchDomainStatus();
  };

  const getSubHeading = () => {
    return (
      <Box sx={classes.subHeadingBox}>
        <Box sx={{ paddingRight: "10px" }}>
          <Typography sx={classes.subHeadingText}>
            Authenticate Domain
          </Typography>
          <Typography sx={classes.subHeadingDescription}>
            You will need to add the following records to complete the
            authentication process. Once added, hit the "Verify Status" button.{" "}
          </Typography>
        </Box>
        <Box sx={classes.verifyButtonContainer}>
          <CustomButton
            id="domains_status_verify_button"
            label={"Verify Status"}
            icon={<img src={verifyStatus} />}
            onClick={verifyStatusButtonHandler}
            customClasses={classes.verifyButtonStyles}
          />
        </Box>
      </Box>
    );
  };

  const getDomainDetailsTable = () => {
    return (
      <Box sx={classes.domainDetailsTable}>
        <CustomSimpleTable
          tableHeader={domainDetailsTableHeader}
          tableRows={domainDetails}
          emptyTableMessage={`Sorry, we don't have any details regarding ${props.domainToBeDisplayed} domain.`}
          isLoading={props.isLoading}
        />
      </Box>
    );
  };

  const getDomainDetailsPage = () => {
    return (
      <Box sx={classes.mainContainer}>
        {getSubHeading()}
        {getDomainDetailsTable()}
      </Box>
    );
  };

  return getDomainDetailsPage();
};

export default DomainDetails;
