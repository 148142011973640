import {
  getRelativeFontSize,
  boldFont,
  primaryHeadingColor,
  theme,
  regularFont,
  mediumFont,
  inputLabelRequiredColor,
  centerItemFlex,
  pinkDarkColor,
  primaryBlackColor,
  borderRadius,
} from "utils/styles";

const StatsStyles = {
  CampaignTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    // [theme.breakpoints.down("md")]: {
    //   marginTop: theme.spacing(4),
    //   marginBottom: theme.spacing(1),
    // },
  },
  campaignHeader: {
    background: "#FFFFFF",
    border: " 1px solid #E7E7E7",
    borderRadius: "10px",
    padding: 2,
  },
  heading: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
  },
  statsheading: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
  },
  percentagetext: {
    ...mediumFont,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: getRelativeFontSize(2),
    marginLeft: 1,
  },
  date: {
    color: "#666666",
    ...regularFont,
  },
  button: {
    background: "#1A0224",
  },
  camapignBox: {
    width: "100%",
    backgroundColor: "#FFF",
    borderRadius: "10px",
    border: "1px solid #E7E7E7",
    padding: 2,
    display: "flex",
    flexDirection: "column",
  },
  mediumFont: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
  },
  label: { display: "flex" },
  labelText: {
    fontSize: "16px",
    ...boldFont,
    marginBottom: "3px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  star: {
    color: inputLabelRequiredColor,
    marginLeft: "2px",
    marginBottom: "10px",
    ...boldFont,
  },
  selectMenu: {
    width: "100% !important",
    height: "47px",
    backgroundColor: "none",
    paddingBottom: "5px",
  },
  dropdownStyle: {
    "& .MuiOutlinedInput-root": {
      height: "47px",
      borderRadius: "12px",
      "&.Mui-focused fieldset": {
        borderColor: "#4B0150",
      },
      "& .MuiAutocomplete-input  ": {
        padding: "0px",
      },
    },
  },
  headerBackgroundColor: {
    backgroundColor: "#fcf5ff",
    padding: "35px 20px 18px 20px",
  },
  coloredFont: {
    ...regularFont,
    color: pinkDarkColor,
  },
  regularFont: {
    textAlign: "center",
    ...regularFont,
    ...centerItemFlex,
    fontSize: getRelativeFontSize(1),
  },
  retargetTableTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    marginBottom: "10px",
  },
  clickedLinksMainContainer: {
    minWidth: "300px",
    overflow: "auto",
    marginTop: "20px",
  },
  clickedLinksTableTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
  },
  clickedUrlTextStyles: {
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    cursor: "pointer",
    color: "#212121",
    display: "inline-block",
    wordBreak: "break-word",
    maxWidth: "350px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textDecoration: 'none',
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100px",
    },
  },
  clickedUrlCountStyles: {
    ...mediumFont,
    color: "#212121",
    fontSize: getRelativeFontSize(2),
    paddingRight: "15px",
    textAlign: "center",
  },
  totalCountStyles: {
    padding: 2,
    margin: "10px 0",
    borderTop: "1px solid #F0F0F0",
    borderRadius: "10px",
    backgroundColor: "rgba(231, 231, 231, 1)",
  },
  totalCountTextStyles: {
    ...mediumFont,
    color: "#212121",
    fontSize: getRelativeFontSize(2),
  },
  urlTooltipText: {
    ...mediumFont,
    padding: "10px",
    color: "#212121",
    fontSize: "14px",
  },
  linkTrackingBox: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
  refreshBtn: {
    height: "47px",
    backgroundColor: "#ffffff",
    border: "1px solid #ced4da",
    minWidth: "50px",
    borderRadius: "10px",
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContact: "center",
    fontSize: "50px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
} as const;

export default StatsStyles;
