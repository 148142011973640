import {
  boldFont,
  buttonWhiteBg,
  getRelativeFontSize,
  lightPinkColor,
  mediumFont,
  pinkDarkColor,
  pureWhiteColor,
} from "utils/styles";

const UserTourStyles = {
  gridItemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circularBox: {
    border: `2px solid ${pureWhiteColor}`,
    borderRadius: "50%",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100px",
    backgroundColor: pinkDarkColor,
    color: pureWhiteColor,
  },
  stepsTextStyles: { ...boldFont, fontSize: getRelativeFontSize(4) },
  tooltipBoxStyles: {
    backgroundColor: lightPinkColor,
    padding: "20px",
    borderRadius: "10px",
  },
  tooltipContentStyles: {
    whiteSpace: "pre-wrap",
    color: "#BB0070",
    ...mediumFont,
    fontSize: getRelativeFontSize(4),
  },
  buttonGridItem: {
    display: "flex",
    justifyContent: "space-around",
  },
  purpleButtonStyles: { width: "20%" },
  skipButtonStyles: {
    width: "20%",
    border: `2px solid ${buttonWhiteBg}`,
    backgroundColor: lightPinkColor,
    color: "#BB0070",
    "&:hover": {
      color: pureWhiteColor,
      border: "2px solid #BB0070",
    },
  },
} as const;

export default UserTourStyles;
