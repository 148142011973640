import strings from "global/constants/StringConstants";

export const postFeedFaceBook = () => {
  return {
    socialCampaignName: {
      value: "",
      error: "",
    },
    scheduleTime: {
      value: "",
      error: "",
    },
    publish: {
      value: "",
      error: "",
    },
    content: {
      value: "",
      error: "",
    },
    file: {
      value: [],
      error: "",
    },
  } as any;
};

export const instagramAccountInitialState = () => {
  return {
    profileTitle: "",
    profilePicture: "",
  } as any;
};
export const postEditFacebook = () => {
  return {
    content: {
      value: "",
      error: "",
    },
  };
};

export const postFeedValidation = (data: any, selectedSocialApp: string) => {
  let errors = data;
  let isValid = true;
  const socialCampaignName = data.socialCampaignName.value;
  const publish = data.publish.value;
  const content = data.content.value;
  const file = data.file.value;

  if (!socialCampaignName) {
    errors.socialCampaignName.error = "Please enter social campaign name";
    isValid = false;
  }
  if (!publish) {
    errors.publish.error = "Please select publish page";
    isValid = false;
  }
  if (selectedSocialApp === strings.FACEBOOK && !content && file.length === 0) {
    errors.content.error = strings.emptyContentAndImageError;
    errors.file.error = strings.emptyContentAndImageError;
    isValid = false;
  }
  if (selectedSocialApp === strings.INSTAGRAM && file.length === 0) {
    errors.file.error = "Please upload images for Instagram campaign";
    isValid = false;
  }
  if (content.length > 60000) {
    errors.content.error = "Please keep the content below 60,000 characters";
    isValid = false;
  }
  if (/#/.test(content)) {
    errors.content.error = "Please remove the '#' tags";
    isValid = false;
  }
  return { isValid, errors };
};

export const editFeedValidation = (data: any) => {
  let errors = data;
  let isValid = true;
  const content = data.content.value;
  if (content.length > 60000) {
    errors.content.error = "Please keep the content below 60,000 characters";
    isValid = false;
  }
  if (/#/.test(content)) {
    errors.content.error = "Please remove the '#' tags";
    isValid = false;
  }
  return { isValid, errors };
};
