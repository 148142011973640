import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  IconButton,
} from "@mui/material";
import { CustomButton } from "global/components";
import IntegrationStyles from "screens/Integration/Integration.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import linkedinIcon from "assets/icons/linkedin.svg";
interface LinkedInProps {
  previousStep: Function;
}
const LinkedIn = (props: LinkedInProps) => {
  const classes = IntegrationStyles;

  const connectLinkedIn = () => {
    window.location.href =
      "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78mskjus58iwft&redirect_uri=http://localhost:5000/integration&state=foobar&scope=r_liteprofile%20r_emailaddress%20w_member_social";
  };

  return (
    <>
      <Grid>
        <Grid item>
          <Box sx={classes.integrationsWrapper}>
            <IconButton>
              <ArrowBackIcon onClick={() => props.previousStep()} />
            </IconButton>

            <Typography sx={classes.integrationsTitle}>Integrations</Typography>
          </Box>
        </Grid>

        <Grid item mt={1} marginLeft={{ sm: "10px", lg: "20px" }}>
          <Box sx={classes.connectWrapper}>
            <Box
              component="img"
              src={linkedinIcon}
              height={80}
              width={80}
              mt={1}
              ml={2}
            />
            <Box sx={classes.connectContent}>
              <Typography sx={classes.socialTitle}>LinkedIn</Typography>
              <Typography sx={classes.connectPara}>
                Connect LinkedIn to publish ads, add signup forms, and access
                your Instagram posts in Mailzzy’s Content Studio
              </Typography>
              <Typography sx={classes.connectPara}>
                By Clicking Connect,you agree to our Terms & Conditions
              </Typography>
              <Box mt={2} width={{ sm: "100%", lg: "20%" }}>
                <CustomButton
                  label="Connect"
                  onClick={() => connectLinkedIn()}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item marginLeft={{ sm: "10px", lg: "20px" }}>
          <Typography fontWeight="bold" fontSize={23} mt={6}>
            Features
          </Typography>
          <Box ml={7}>
            <List sx={classes.listStyle}>
              <ListItem sx={classes.listItemStyle}>
                Create Facebook and Instagram ads Social content blocks
              </ListItem>
              <ListItem sx={classes.listItemStyle}>
                Publish and schedule social posts
              </ListItem>
              <ListItem sx={classes.listItemStyle}>
                Auto-post to Facebook and Instagram
              </ListItem>
              <ListItem sx={classes.listItemStyle}>
                Add signup forms to Facebook
              </ListItem>
              <ListItem sx={classes.listItemStyle}>
                Use Instagram images in Mailzzy’s Content Studio
              </ListItem>
              <ListItem sx={classes.listItemStyle}>
                Social content blocks
              </ListItem>
            </List>
          </Box>
        </Grid>

        <Grid item marginLeft={{ sm: "10px", lg: "20px" }}>
          <Typography sx={classes.stepsStyle}>Installation steps</Typography>
          <Box ml={7}>
            <List sx={classes.listStyle}>
              <ListItem sx={classes.listItemStyle}>
                Click Connect to get started
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default LinkedIn;
