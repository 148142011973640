import strings from "global/constants/StringConstants";
import { PhoneNumberUtil } from "google-libphonenumber";
import { isTruthy } from "helpers/methods";
export const getInitialProfileData = (data?: any) => ({
  name: data?.name ?? "",
  email: data?.email ?? "",
  contactNo: data?.contactNo ?? "",
  role: data?.role ?? "",
  roleIds: data?.roleIds ?? "",
});

export const getProfileFormData = (data?: any) => {
  return {
    name: {
      value: data?.name ?? "",
      error: "",
    },
    email: {
      value: data?.email ?? "",
      error: "",
    },
    contactNo: {
      value: data?.contactNo ?? "",
      error: "",
    },
    role: {
      value: data?.role ?? "",
      error: "",
    },
    roleIds: {
      value: data?.roleIds ?? "",
      error: "",
    },
    // gender: {
    //   value: "male",
    //   error: "",
    // },
    // companyName: {
    //   value: "",
    //   error: "",
    // },
    // companyEmail: {
    //   value: "",
    //   error: "",
    // },
    // address: {
    //   value: "",
    //   error: "",
    // },
    // city: {
    //   value: "",
    //   error: "",
    // },
    // state: {
    //   value: "",
    //   error: "",
    // },
    // country: {
    //   value: "",
    //   error: "",
    // },
  };
};
const isPhoneValid = (phone: string) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error: any) {
    return false;
  }
};

export const validateProfileForm = (profileFormData: any) => {
  let isValid = true;
  let errors = profileFormData;

  if (!errors.name.value) {
    errors.name.error = "Please enter name";
    isValid = false;
  }
  if (!strings.regex.test(profileFormData.email.value)) {
    profileFormData.email.error = "Please enter valid email";
    isValid = false;
  }
  if (
    isTruthy(profileFormData.contactNo.value) &&
    !isPhoneValid(profileFormData.contactNo.value)
  ) {
    errors.contactNo.error = "Please enter a valid contact";
    isValid = false;
  }
  if (!errors.role.value) {
    errors.role.error = "Please enter role";
    isValid = false;
  }
  return { isValid, errors };
};

export const mapFormDataToValues = (profileFormData: any) => {
  let profileData: any = {
    name: profileFormData.name.value,
    email: profileFormData.email.value,
    contactNo: profileFormData.contactNo.value,
    role: profileFormData.role.value,
    roleIds: profileFormData.roleIds.value,
  };

  return profileData;
};
