import {
  getRelativeFontSize,
  primaryHeadingColor,
  theme,
  boldFont,
  pinkDarkColor,
  inputLabelRequiredColor,
} from "utils/styles";

const StepTwoStyles = {
  mainContainerBox: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  star: {
    fontSize: getRelativeFontSize(7),
    color: inputLabelRequiredColor,
  },
  headerSection: {
    padding: "40px 20px 18px 20px",
  },
  headerGridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerButtonStackStyles: {
    display: "flex",
    flexDirection: "row",
  },
  bodyGridContainer: {
    height: "100%",
  },
  dividerStyles: { margin: "25px 0" },
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  },
  headerButtons: {
    width: "100%",
    margin: "0 5px",
    [theme.breakpoints.down("md")]: { marginTop: "20px" },
    [theme.breakpoints.down("sm")]: { marginTop: 0 },
  },
  switchStyles: {
    "& .MuiSwitch-switchBase": {
      transitionDuration: "500ms",
      "&.Mui-checked": {
        "& .MuiSwitch-thumb": {
          backgroundColor: pinkDarkColor,
        },
        "& + .MuiSwitch-track": {
          backgroundColor: pinkDarkColor,
        },
      },
    },
  },
  templateComponentStack: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  templateComponentTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(5),
    display: "flex",
    alignItems: "center",
  },
  templateComponentInputContainer: {
    marginTop: "10px",
    paddingLeft: "6px",
    display: "flex",
    flexDirection: "column",
  },
  templateComponentTextarea: {
    width: "95%",
    borderRadius: "10px",
    padding: "10px",
    font: "inherit",
  },
  templateComponentHelperText: {
    margin: "0 5px",
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("lg")]: {
      marginTop: "5px",
    },
  },
  characterCountContainer: {
    display: "flex",
    alignItems: "center",
  },
  iconButtonStyles: { borderRadius: "5px" },
  errorStyles: { marginRight: "15px" },
  previewContainerBox: {
    paddingTop: "20px",
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
} as const;

export default StepTwoStyles;
