import React, { useState } from "react";
import jpg from "assets/icons/AttachmentIcons/JPG.svg";
import png from "assets/icons/AttachmentIcons/PNG.svg";
import gif from "assets/icons/AttachmentIcons/GIFF.svg";
import bmp from "assets/icons/AttachmentIcons/BMP.svg";
import tiff from "assets/icons/AttachmentIcons/TIFF.svg";
import pdf from "assets/icons/AttachmentIcons/PDF.svg";
import doc from "assets/icons/AttachmentIcons/DOC.svg";
import docx from "assets/icons/AttachmentIcons/DOCX.svg";
import txt from "assets/icons/AttachmentIcons/TXT.svg";
import xls from "assets/icons/AttachmentIcons/XLS.svg";
import xlsx from "assets/icons/AttachmentIcons/XLSX.svg";
import heic from "assets/icons/AttachmentIcons/HEIC.svg";
import jpeg from "assets/icons/AttachmentIcons/JPEG.svg";
import csv from "assets/icons/AttachmentIcons/CSV.svg";
import defaultIcon from "assets/icons/AttachmentIcons/Default.svg";
import { isTruthy, openErrorNotification, setFileName } from "helpers/methods";
import strings from "global/constants/StringConstants";
import notifiers from "global/constants/NotificationConstants";
import { doViewTicketAttachment } from "../ViewTicketService";
import { Box, Grid, Stack, Typography } from "@mui/material";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { CustomButton, CustomDialog } from "global/components";
import ViewTicketStyles from "../ViewTicket.styles";
import { boldFont, getRelativeFontSize, regularFont } from "utils/styles";

interface CustomProps {
  ticketDetails: any;
  isLoading: boolean;
}

const Attachments = (props: CustomProps) => {
  const classes = ViewTicketStyles;
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [uploadedDocument, setUploadedDocument] = useState<string>("");
  const [downloadFile, setDownloadFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fileIconMapping: { [key: string]: any } = {
    pdf: pdf,
    docx: docx,
    doc: doc,
    csv: csv,
    txt: txt,
    jpg: jpg,
    jpeg: jpeg,
    png: png,
    xls: xls,
    xlsx: xlsx,
    gif: gif,
    bmp: bmp,
    tiff: tiff,
    heic: heic,
  };

  const getIcons = (file: string) => {
    const fileExtension: any = file?.split(".").pop()?.toLowerCase();
    return fileIconMapping[fileExtension] || defaultIcon;
  };

  const checkFileExtension = (filename: string): boolean => {
    const extension = filename.split(".").pop()?.toLowerCase();
    const excludedExtensions = ["csv", "xls", "xlsx"];
    return !excludedExtensions.includes(extension || "");
  };

  const handleViewDocument = async (path: string) => {
    setDownloadFile(path);
    try {
      setIsLoading(true);
      const body = {
        app: "mailzzy",
        path,
      };
      const data = await doViewTicketAttachment(body);
      if (isTruthy(data.message)) {
        const isAllowedFileType = strings.ALLOWED_ATTACHMENT_TYPE.some(
          (type: string) => data.message.indexOf(type) >= 0
        );
        if (isAllowedFileType) {
          setUploadedDocument(data.message);
        } else {
          setUploadedDocument(
            strings.FileUrlPrefix + encodeURIComponent(data.message)
          );
        }
      }
      setIsLoading(false);
      setShowDialog(true);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const getDialogTitle = () => {
    return (
      <Typography
        sx={{
          ...boldFont,
          fontSize: getRelativeFontSize(7),
        }}
      >
        View File
      </Typography>
    );
  };

  const getDialogBody = () => {
    return (
      <iframe
        src={`${uploadedDocument}#toolbar=0&view=fitH`}
        width="100%"
        height="650px"
        frameBorder="0"
        className="responsive-iframe"
        scrolling="yes"
      ></iframe>
    );
  };

  const getDialogFooter = () => {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        mb={1}
      >
        <CustomButton label={"Close"} onClick={() => setShowDialog(false)} />
      </Stack>
    );
  };

  const handleClose = () => {
    setShowDialog!(false);
    setUploadedDocument("");
  };

  return (
    <>
      <Typography mt={1} sx={{ ...classes.heading, color: "#242A3A" }}>
        Attachments
      </Typography>
      <>
        <Grid
          container
          display="flex"
          flexDirection="row"
          direction="row"
          spacing={1}
          mt={1}
        >
          {props.ticketDetails?.docUrls.map((file: string, index: number) => {
            return (
              <Grid item xl={3} lg={3}>
                <Box
                  sx={{
                    padding: "12px 12px",
                    "&.MuiCardContent-root:last-child": {
                      paddingBottom: "12px",
                    },
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "0.5px solid #E9E9E9",
                    boxShadow: "0px 5px 22px 0px #242A3A0D",
                    borderRadius: "20px",
                    cursor: "pointer",
                    "&:hover": {
                      opacity: 0.9,
                      height: "100px",
                      wordBreak: "break-all",
                      background: "#ebebeb",
                      transition: "all 0.4s ease-in-out 0s",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      "&.addDesc": {
                        opacity: 1,
                      },
                    },
                  }}
                  onClick={() =>
                    checkFileExtension(file) ? handleViewDocument(file) : null
                  }
                >
                  <Box
                    sx={{
                      background: "url(" + getIcons(file) + ")",
                      height: "50px",
                      width: "44px",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "relative",
                    }}
                  />

                  <Typography
                    fontSize="13px"
                    sx={{
                      ...regularFont,
                      maxWidth: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    mt={1}
                  >
                    {setFileName(file)}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <CustomLoader isLoading={isLoading} />
        <CustomDialog
          isDialogOpen={showDialog}
          handleDialogClose={handleClose}
          dialogTitleContent={getDialogTitle()}
          dialogBodyContent={getDialogBody()}
          dialogFooterContent={getDialogFooter()}
          closable
          closeButtonVisibility={true}
          width="600px"
        />
      </>
    </>
  );
};

export default Attachments;
