import { useState } from "react";
import { Box, Grid, Stack, Typography, Switch, Divider } from "@mui/material";
import StepTwoStyles from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/StepTwo/StepTwo.styles";
import { CustomAppHeader, CustomButton } from "global/components";
import {
  allowedDocumentFormats,
  allowedImageFormats,
  allowedVideoFormats,
  headerFormatTabContent,
  stepTwoValidation,
  templateHeaderFormat,
} from "screens/WhatsAppCampaign/CreateWhatsAppTemplate/CreateWhatsAppTemplateHelpers";
import WhatsAppMessagePreview from "global/components/WhatsAppMessagePreview/WhatsAppMessagePreview";
import CustomTextarea from "global/components/CustomTextarea/CustomTextarea";

interface CustomProps {
  templateData: any;
  setTemplateData: Function;
  setActiveStep: Function;
  submitTemplate: Function;
}

const StepTwo = (props: CustomProps) => {
  const classes = StepTwoStyles;

  const [isHeaderIncluded, setIsHeaderIncluded] = useState<boolean>(false);
  const [isFooterIncluded, setIsFooterIncluded] = useState<boolean>(false);
  const [headerFormat, setHeaderFormat] = useState<string>(
    headerFormatTabContent[0].label
  );

  const backButtonHandler = (previousStep: number) => {
    props.setActiveStep(previousStep);
  };

  const handleHeaderSwitch = (event: any) => {
    setIsHeaderIncluded(event.target.checked);
  };

  const handleFooterSwitch = (event: any) => {
    setIsFooterIncluded(event.target.checked);
  };

  const mediaFormatDecider = (
    allowedFormats: string[],
    inputFormat: string
  ) => {
    return allowedFormats.includes(inputFormat);
  };

  const getHeaderFormat = (value: string) => {
    switch (true) {
      case mediaFormatDecider(allowedImageFormats, value):
        return templateHeaderFormat[1];
      case mediaFormatDecider(allowedVideoFormats, value):
        return templateHeaderFormat[2];
      case mediaFormatDecider(allowedDocumentFormats, value):
        return templateHeaderFormat[3];
      default:
        return templateHeaderFormat[0];
    }
  };

  const handleHeaderFormatTabs = (newValue: string) => {
    setHeaderFormat(newValue);
    props.setTemplateData({
      ...props.templateData,
      header: {
        ...props.templateData.header,
        format: getHeaderFormat(newValue),
      },
    });
  };

  const onChangeHandler = (event: any) => {
    props.setTemplateData({
      ...props.templateData,
      [event.target.name]: {
        ...props.templateData[event.target.name],
        text: {
          value: event.target.value,
          error: "",
        },
      },
    });
  };

  const submitHandler = () => {
    const { isValid, errorLadenState } = stepTwoValidation(
      props.templateData,
      isHeaderIncluded,
      isFooterIncluded
    );
    props.setTemplateData({ ...errorLadenState });
    if (isValid) {
      props.submitTemplate(isHeaderIncluded, isFooterIncluded);
    }
  };

  const getHeaderTitle = () => {
    return (
      <Typography sx={classes.pageTitle}>Create WhatsApp Template</Typography>
    );
  };

  const getHeaderButtonStack = () => {
    return (
      <Stack sx={classes.headerButtonStackStyles}>
        <CustomButton
          id="whatsapp_template_step2_back"
          label="Back"
          onClick={() => {
            backButtonHandler(1);
          }}
          customClasses={classes.headerButtons}
        />
        <CustomButton
          id="whatsapp_template_step2_submit"
          label="Submit"
          onClick={() => {
            submitHandler();
          }}
          customClasses={classes.headerButtons}
        />
      </Stack>
    );
  };

  const templateDataObjectSetter = (
    inputName: string,
    manipulatedString: string
  ) => {
    props.setTemplateData({
      ...props.templateData,
      [inputName]: {
        ...props.templateData[inputName],
        text: {
          value: manipulatedString,
          error: "",
        },
      },
    });
  };

  const getTemplateHeader = () => {
    return (
      <Box>
        <Stack sx={classes.templateComponentStack}>
          <Typography sx={classes.templateComponentTitle}>Header</Typography>
          <Switch
            checked={isHeaderIncluded}
            onChange={handleHeaderSwitch}
            sx={classes.switchStyles}
          />
        </Stack>
        {isHeaderIncluded && (
          <>
            {/* <Box
              }
            >
              <CustomTabs
                tabConfig={headerFormatTabContent}
                changeValue={handleHeaderFormatTabs}
                selected={headerFormat}
              />
            </Box> */}
            <Box sx={{ paddingTop: "10px" }}>
              <CustomTextarea
                inputName={"header"}
                value={props.templateData.header.text.value}
                error={props.templateData.header.text.error}
                onChangeHandler={onChangeHandler}
                maxLength={45}
                textareaRows={2}
                allowTextFormatting={false}
              />
            </Box>
          </>
        )}
      </Box>
    );
  };

  const getTemplateBody = () => {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Typography sx={classes.templateComponentTitle}>Body</Typography>
          <Box ml={0.4} style={classes.star}>
            *
          </Box>
        </Box>
        <Box sx={{ paddingTop: "10px" }}>
          <CustomTextarea
            inputName={"body"}
            value={props.templateData.body.text.value}
            error={props.templateData.body.text.error}
            onChangeHandler={onChangeHandler}
            maxLength={950}
            textareaRows={10}
            allowTextFormatting={true}
            dataObjectSetter={templateDataObjectSetter}
          />
        </Box>
      </>
    );
  };

  const getTemplateFooter = () => {
    return (
      <Box>
        <Stack sx={classes.templateComponentStack}>
          <Typography sx={classes.templateComponentTitle}>Footer</Typography>
          <Switch
            checked={isFooterIncluded}
            onChange={handleFooterSwitch}
            sx={classes.switchStyles}
          />
        </Stack>
        {isFooterIncluded && (
          <Box sx={{ paddingTop: "10px" }}>
            <CustomTextarea
              inputName={"footer"}
              value={props.templateData.footer.text.value}
              error={props.templateData.footer.text.error}
              onChangeHandler={onChangeHandler}
              maxLength={45}
              textareaRows={2}
              allowTextFormatting={false}
            />
          </Box>
        )}
      </Box>
    );
  };

  const getPreview = () => {
    return (
      <Box sx={classes.previewContainerBox}>
        <WhatsAppMessagePreview
          isHeaderIncluded={isHeaderIncluded}
          isFooterIncluded={isFooterIncluded}
          headerTextValue={props.templateData.header.text.value}
          bodyTextValue={props.templateData.body.text.value}
          footerTextValue={props.templateData.footer.text.value}
          headerFormat={headerFormat}
        />
      </Box>
    );
  };

  return (
    <Box sx={classes.mainContainerBox}>
      <CustomAppHeader className={classes.headerSection}>
        <Grid container sx={classes.headerGridContainer}>
          {getHeaderTitle()}
          {getHeaderButtonStack()}
        </Grid>
      </CustomAppHeader>
      <Grid container sx={classes.bodyGridContainer}>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
          <Box sx={{ padding: "20px" }}>
            {getTemplateHeader()}
            <Divider sx={classes.dividerStyles} />
            {getTemplateBody()}
            <Divider sx={classes.dividerStyles} />
            {getTemplateFooter()}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          {getPreview()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default StepTwo;
