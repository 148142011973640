import strings from "global/constants/StringConstants";
import { isTruthy } from "helpers/methods";
import { simpleTableHeader } from "models/interfaces";

export const getInitialDomainState = () => {
  return {
    domainName: {
      value: "",
      error: "",
    },
    senderId: {
      value: "",
      error: "",
    },
    senderName: {
      value: "",
      error: "",
    },
  };
};

export const domainNameValidation = (domainToBeAdded: any) => {
  let isValid = true;
  let errorLadenState = { ...domainToBeAdded };

  if (!strings.domainNameRegex.test(errorLadenState.domainName.value)) {
    errorLadenState.domainName.error = "Please enter a valid domain name";
    isValid = false;
  }
  if (!strings.localPartRegex.test(errorLadenState.senderId.value)) {
    errorLadenState.senderId.error =
      "Please enter a valid local part of the sender email ID";
    isValid = false;
  }
  if (!isTruthy(errorLadenState.senderName.value)) {
    errorLadenState.senderName.error = "Please enter sender name.";
    isValid = false;
  }

  return { isValid, errorLadenState };
};

export const getAddDomainPayload = (domainToBeAdded: any) => {
  return {
    name: domainToBeAdded.domainName.value,
    senderId: `${domainToBeAdded.senderId.value}@${domainToBeAdded.domainName.value}`,
    senderName: domainToBeAdded.senderName.value,
  };
};

export const domainsListTableHeader: simpleTableHeader[] = [
  {
    name: "Domain Name",
    field: "name",
    align: "left",
  },
  {
    name: "Action",
    field: "action",
    align: "right",
    styles: { paddingRight: "48px" },
  },
];

export const domainDetailsTableHeader: simpleTableHeader[] = [
  {
    name: "Host Name",
    field: "hostName",
    align: "left",
  },
  {
    name: "Type",
    field: "type",
    align: "left",
  },
  {
    name: "Enter this value",
    field: "requiredValue",
    align: "left",
  },
  {
    name: "Status",
    field: "status",
    align: "left",
  },
];
