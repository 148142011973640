import { ACTIONS, EVENTS, STATUS, CallBackProps } from "react-joyride";
import history from "utils/history";
import {
  changeIsTourActive,
  changeRun,
  changeStepIndex,
} from "redux/tourSlice";
import { campaignTourSteps } from "screens/CampaignsNew/Campaigns/CampaignsData";
import { dashboardTourSteps } from "screens/Dashboard/DashboardData";
import { store } from "utils/store";
import urls from "global/constants/UrlConstants";

const dashboardSwitchingIndex = dashboardTourSteps.length;
const campaignsSwitchingIndex =
  dashboardSwitchingIndex + campaignTourSteps.length;

export const tourHandler = (data: CallBackProps) => {
  const { action, index, type, status } = data;
  const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
  const events: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];
  if (events.includes(type)) {
    store.dispatch(changeStepIndex(index + (action === ACTIONS.PREV ? -1 : 1)));
    tourPageChangeHandler(index, action, type);
  } else if (finishedStatuses.includes(status)) {
    store.dispatch(changeRun(false));
    store.dispatch(changeIsTourActive(false));
  }
};

export const tourPageChangeHandler = (
  index: number,
  action: any,
  type: string
) => {
  switch (true) {
    case shouldTourSwitchPage(index, type, dashboardSwitchingIndex):
      pageSwitcher(urls.campaignsListViewPath, urls.dashboardViewPath, action);
      break;

    case shouldTourSwitchPage(index, type, campaignsSwitchingIndex):
      pageSwitcher(urls.campaignGroupsViewPath, urls.campaignsListViewPath, action);
      break;

    default:
      break;
  }
};

const shouldTourSwitchPage = (
  index: number,
  type: string,
  switchingIndex: number
) => {
  return (
    (index === switchingIndex - 1 || index === switchingIndex) &&
    (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND)
  );
};

const pageSwitcher = (
  forwardNavigationUrl: string,
  backwardNavigationUrl: string,
  action: any
) => {
  store.dispatch(changeRun(false));
  history.push(
    action === ACTIONS.NEXT ? forwardNavigationUrl : backwardNavigationUrl
  );
  store.dispatch(changeRun(true));
};
