import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useAppSelector } from "utils/hooks";
import { selectIsManager } from "redux/authSlice";
import { ViewContactStyles } from "screens/Contact/ViewContact.styles";
import { CustomAppHeader, CustomInput } from "global/components";
import { SortingConfig, contactsFilters } from "models/interfaces";
import {
  contactsDropdownOptions,
  initialContactsFilterState,
} from "screens/Contact/ContactListData";
import {
  debounceEventHandler,
  isTruthy,
  openErrorNotification,
} from "helpers/methods";
import strings from "global/constants/StringConstants";
import { GridSearchIcon } from "@mui/x-data-grid";
import ContactTable from "screens/Contact/ContactTable";
import notifiers from "global/constants/NotificationConstants";
import {
  getContacts,
  getContactsCount,
  getSearchedContacts,
  getSearchedContactsCount,
} from "screens/Contact/ViewContactService";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import { useTitle } from "utils/UseTitle";

const ViewContact = () => {
  useTitle(strings.ContactTitle);
  const classes = ViewContactStyles;
  const isManager = useAppSelector(selectIsManager);

  const [contactsFilters, setContactsFilters] = useState<contactsFilters>(
    initialContactsFilterState()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contactCount, setContactCount] = useState<number>(0);
  const [contacts, setContacts] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<SortingConfig[]>([]);

  useEffect(() => {
    getTableData();
  }, [contactsFilters, sortConfig]);

  const getTableData = async () => {
    const { searchText, dropdownValue, page, rowsPerPage } = contactsFilters;
    try {
      setIsLoading(true);
      const count = isTruthy(searchText)
        ? await getSearchedContactsCount(searchText, dropdownValue)
        : await getContactsCount(dropdownValue!);
      setContactCount(count);
      await getTableDataAccordingToPage(
        count,
        dropdownValue!,
        page,
        searchText,
        rowsPerPage
      );
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getTableDataAccordingToPage = async (
    count: number,
    dropdownValue: string,
    page: number,
    searchText: string,
    rowsPerPage: number
  ) => {
    try {
      let tableData: any[] = [];
      const expectedPages = Math.ceil(count / rowsPerPage);
      if (page <= expectedPages || count === 0) {
        tableData = isTruthy(searchText)
          ? await getSearchedContacts(
              searchText,
              dropdownValue,
              page,
              rowsPerPage,
              sortConfig
            )
          : await getContacts(dropdownValue, page, rowsPerPage, sortConfig);
        setContacts(tableData);
      } else {
        setContactsFilters({
          ...contactsFilters,
          page: page - 1,
        });
      }
    } catch (error: any) {
      throw error;
    }
  };

  const dropdownHandler = (event: any) => {
    setContactsFilters({
      ...contactsFilters,
      page: 1,
      dropdownValue: event.target.value,
    });
  };

  const searchChangeHandler = (event: any) => {
    const searchText = event.target.value;
    setContactsFilters({
      ...contactsFilters,
      page: 1,
      searchText,
    });
  };

  const pageChangeHandler = (event: any, newPage: number) => {
    setContactsFilters({
      ...contactsFilters,
      page: newPage,
    });
  };

  const perPageRowsChangeHandler = (event: any) => {
    setContactsFilters({
      ...contactsFilters,
      rowsPerPage: event.target.value,
      page: 1,
    });
  };

  const searchAndDropdownField = () => {
    return (
      isManager && (
        <FormControl sx={classes.inputWrapper}>
          <Select
            id="contact_category_dropdown"
            sx={classes.dropdown}
            value={contactsFilters.dropdownValue}
            onChange={dropdownHandler}
          >
            {contactsDropdownOptions.map((item, index) => (
              <MenuItem key={index} value={item.value} sx={classes.optionStyle}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <CustomInput
            id="contact_search_field"
            placeHolder="Search text"
            onChange={debounceEventHandler(
              searchChangeHandler,
              strings.SEARCH_TIME_OUT
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <GridSearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      )
    );
  };

  const getContactTable = () => {
    return (
      <Box sx={classes.contactTableContainer}>
        <ContactTable
          rawContactData={contacts}
          contactsFilters={contactsFilters}
          contactCount={contactCount}
          pageChangeHandler={pageChangeHandler}
          perPageRowsChangeHandler={perPageRowsChangeHandler}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          fetchTableData={getTableData}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
          hideActions={false}
          dropDownValue={contactsFilters.dropdownValue}
        />
      </Box>
    );
  };

  const getContactsPage = () => {
    return (
      <>
        <CustomAppHeader className={classes.headerBackgroundColor}>
          <Grid container xs={12} md={12} lg={12} xl={12} alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <Typography sx={classes.campaignerHeading}>Contacts</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={8}
              xl={9}
              display="flex"
              justifyContent={{ md: "flex-start", lg: "flex-end" }}
            >
              {searchAndDropdownField()}
            </Grid>
          </Grid>
        </CustomAppHeader>
        {getContactTable()}
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };

  return getContactsPage();
};

export default ViewContact;
