import { Card, CardContent, Typography, Box } from "@mui/material";
import CreateCampaignsCardStyles from "screens/CampaignsNew/CreateCampaigns/components/CreateCampaignCard/CreateCampaignCard.styles";

interface CustomProps {
  title: string;
  icon: any;
  description: string;
  isUserPermitted: boolean;
}

const CreateCampaignCard = (props: CustomProps) => {
  const classes = CreateCampaignsCardStyles;
  return (
    <Card
      sx={[
        classes.mainCardStyles,
        ...(props.isUserPermitted
          ? [classes.permittedCardStyles]
          : [classes.notPermittedCardStyles]),
      ]}
    >
      <CardContent>
        <Box sx={classes.iconBox}>
          <Box component="img" src={props.icon} height={29} width={29} />
        </Box>
        <Box sx={classes.titleBox}>
          <Typography sx={classes.titleTextStyles}>{props.title}</Typography>
        </Box>
        <Box>
          {props.isUserPermitted ? (
            <Typography sx={classes.descriptionTextStyles}>
              {props.description}
            </Typography>
          ) : (
            <Typography variant="body1" sx={classes.unauthorizedUserMessage}>
              * You don't have necessary permissions to view the {props.title}{" "}
              page. Please contact your account administrator.
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CreateCampaignCard;
