import { Box, Typography } from "@mui/material";
import { CustomButton, CustomDialog } from "global/components";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import { deleteContactFromGroups } from "screens/Contact/ViewContactService";
import { ViewContactStyles } from "screens/Contact/ViewContact.styles";
import notifiers from "global/constants/NotificationConstants";
import { useState } from "react";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import DeleteImg from "assets/images/DeleteImg.svg";

interface CustomProps {
  groupDeleteModal: boolean;
  setGroupDeleteModal: (openEmailModal: boolean) => void;
  groupEmail: string;
  name?: string;
  groupId: string | null;
  table: Function;
  getGroupInfo?: Function;
}

const GroupModal = (props: CustomProps) => {
  const classes = ViewContactStyles;
  const [loading, setLoading] = useState(false);

  const deleteGroupModel = async () => {
    setLoading(true);
    const emptyArray: any[] = [];
    emptyArray.push(props.groupId);
    try {
      const response = await deleteContactFromGroups(
        props.groupEmail,
        emptyArray
      );
      props.setGroupDeleteModal(false);
      openSuccessNotification(response.message);
      props.table();
      props.getGroupInfo?.();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    }
  };

  const deleteGroupBody = () => {
    return (
      <Typography sx={classes.deleteGroupBodyStyle}>
        Are you sure want to delete this email from
        <Typography sx={classes.textBold}>
          {props.name}
          <Box component="span" sx={classes.fontText}>
            campaign group?
          </Box>
        </Typography>
      </Typography>
    );
  };

  const closeGroupDialog = () => {
    props.setGroupDeleteModal(false);
  };

  const deleteCloseButton = () => {
    return (
      <Box sx={classes.deleteCloseStyle}>
        <CustomButton
          id="group_delete_cancel_button"
          label="Cancel"
          onClick={closeConfirmModel}
          customClasses={classes.buttonWhiteBg}
        />
        <CustomButton
          id="group_delete_submit_button"
          label="Delete"
          loading={loading}
          onClick={deleteGroupModel}
        />
      </Box>
    );
  };

  const dialogTitleContent = () => {
    return <Typography sx={classes.modalTitle}>Delete Recipient</Typography>;
  };

  const closeConfirmModel = () => {
    props.setGroupDeleteModal(false);
  };

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={DeleteImg} alt="delete Image" />
      </Box>
    );
  };

  return (
    <>
      <CustomDialog
        dialogHeaderContent={dialogHeaderContent()}
        isDialogOpen={props.groupDeleteModal}
        dialogBodyContent={deleteGroupBody()}
        dialogTitleContent={dialogTitleContent()}
        closable={true}
        width="400px"
        closeButtonVisibility={true}
        handleDialogClose={closeGroupDialog}
        dialogFooterContent={deleteCloseButton()}
      />
      <CustomLoader isLoading={loading} />
    </>
  );
};

export default GroupModal;
