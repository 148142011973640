import {
  getRelativeFontSize,
  boldFont,
  primaryHeadingColor,
  pureWhiteColor,
  regularFont,
  purpleThemedSelectComponent,
  pinkThemedMenuItems,
  mediumFont,
  theme,
  pinkDarkColor,
  buttonWhiteBg,
  purplePrimaryColor,
} from "utils/styles";

const SocialCampaignsStyles = {
  mainHeader: {},
  pageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
  },
  dateBox: {
    [theme.breakpoints.up("sm")]: {
      margin: "0 10px",
    },
    display: "flex",
    alignItems: "center",
  },
  date: {
    color: "#666666",
    ...regularFont,
    fontSize: getRelativeFontSize(0),
    fontStyle: "italic",
  },
  refreshBtn: {
    height: "47px",
    backgroundColor: "#ffffff",
    border: "1px solid #ced4da",
    minWidth: "50px",
    borderRadius: "10px",
    width: "50px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  dropDownStyle: {
    ...regularFont,
    ...purpleThemedSelectComponent,
    backgroundColor: pureWhiteColor,
    height: "47px",
    padding: "2px",
    minWidth: "180px",
    width: "100%",
    borderRadius: "12px",
    fontSize: "14px",
  },
  optionStyle: {
    ...regularFont,
    ...pinkThemedMenuItems,
  },
  searchInput: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "12px",
    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        borderColor: "#4B0150",
      },
    },
  },
  searchBox: {
    margin: "5px",
  },
  paperInnerWrapper: {
    padding: theme.spacing(2),
    paddingTop: "2px",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(0),
    },
  },
  campaignNameStyle: {
    ...boldFont,
    color: pinkDarkColor,
    fontSize: "14px",
    padding: " 0",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  ActionBtn: {
    width: "100px",
    border: "1px solid #ccc",
    background: "none",
    display: "flex",
    gap: "5px ",
    fontSize: "12px",
    padding: "2px 15px",
    alignItem: "center",
    color: "rgba(0,0,0,0.7)",
    ...boldFont,
  },
  dialogTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "10px",
    textAlign: "center",
  },
  titleLeft: {
    color: "#faad14",
  },
  titleRight: {
    color: " rgba(0,0,0,.85)",
    ...boldFont,
    fontSize: getRelativeFontSize(14),
  },
  dialogContent: {
    fontSize: getRelativeFontSize(2),
    ...mediumFont,
    textAlign: "center",
    "& span": {
      ...boldFont,
    },
  },
  dialogFooter: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "10px",
    "& button": {
      width: "120px",
    },
  },
  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  popOverHeading: {
    display: "flex",
    justifyContent: "center",
    padding: "5px 16px 5px",
    color: "rgba(0,0,0,.85)",
    ...boldFont,
  },
} as const;

export default SocialCampaignsStyles;
