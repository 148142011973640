import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import { DropzoneAreaBase } from "react-mui-dropzone";
import strings from "global/constants/StringConstants";
import { isTruthy, openErrorNotification } from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import { ReactComponent as dropZoneDropZone } from "assets/icons/attach.svg";
import Step2Styles from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/StepTwo/StepTwo.styles";
import { attachFileUpload } from "screens/CampaignsNew/CreateCampaigns/CreateSequentialCampaigns/CreateSequentialCampaignsServices";

interface CustomProps {
  index: number;
  setIsLoading: Function;
  mainCampaignDataArray: any[];
  setMainCampaignDataArray: Function;
  attachmentFileSizeArray: any[];
  setAttachmentFileSize: Function;
  totalAttachmentSizeArray: number[];
  setTotalAttachmentSize: Function;
}

const classes = Step2Styles;
const AttachmentDropzone = (props: CustomProps) => {
  const attachmentNames = Object.keys(
    props.mainCampaignDataArray[props.index].attachments
  );

  const dropzoneUploadFile = async (event: any) => {
    const fileSizeArray: any[] = [
      ...props.attachmentFileSizeArray[props.index],
    ];
    const rejectedFiles: string[] = [];
    const individualOversizedRejectedFile: string[] = [];
    try {
      props.setIsLoading(true);
      const uniqueAttachments = event.filter(
        (attachment: any) => !attachmentNames.includes(attachment.file.name)
      );
      checkDuplicateAttachments(event);
      let sizeTotal = 0;
      let newAttachments: any = {};
      for (const attachment of uniqueAttachments) {
        const attachmentSize = attachment.file.size;
        const formData = new FormData();
        formData.append("file", attachment.file);
        switch (true) {
          case attachmentSize > 10000000: {
            individualOversizedRejectedFile.push(attachment.file.name);
            break;
          }
          case props.totalAttachmentSizeArray[props.index] +
            sizeTotal +
            attachmentSize <
            20000000: {
            const response = await attachFileUpload(formData);
            sizeTotal += attachmentSize;
            newAttachments[`${attachment.file.name}`] = response.message;
            fileSizeArray.push({
              name: attachment.file.name,
              size: attachmentSize,
            });
            break;
          }
          default: {
            rejectedFiles.push(attachment.file.name);
          }
        }
      }
      updateAttachmentFileSizeArray(fileSizeArray);
      updateTotalAttachmentSizeArray(
        props.totalAttachmentSizeArray[props.index] + sizeTotal
      );
      updateMainCampaignData({
        ...props.mainCampaignDataArray[props.index].attachments,
        ...newAttachments,
      });
      rejectedFilesHandler(rejectedFiles, individualOversizedRejectedFile);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const handleDeleteDropZoneFile = (event: any) => {
    const updatedAttachmentNames = attachmentNames.filter(
      (key) => key !== event
    );

    const updatedAttachments = updatedAttachmentNames.reduce(
      (acc: any, key: any) => {
        acc[key] = props.mainCampaignDataArray[props.index].attachments[key];
        return acc;
      },
      {}
    );

    const removedAttachment = props.attachmentFileSizeArray[props.index].find(
      (item: any) => item.name === event
    );

    const updatedAttachmentFileSize = props.attachmentFileSizeArray[
      props.index
    ].filter((item: any) => item.name !== event);

    const updatedTotalAttachmentSize = isTruthy(removedAttachment)
      ? props.totalAttachmentSizeArray[props.index] - removedAttachment.size
      : props.totalAttachmentSizeArray[props.index];
    updateAttachmentFileSizeArray(updatedAttachmentFileSize);
    updateTotalAttachmentSizeArray(updatedTotalAttachmentSize);
    updateMainCampaignData(updatedAttachments);
  };

  const updateAttachmentFileSizeArray = (updatedFileSizeArray: any[]) => {
    const mainArray: any = [...props.attachmentFileSizeArray];
    const updatedArray: any = [...updatedFileSizeArray];
    mainArray.splice(props.index, 1, updatedArray);
    props.setAttachmentFileSize([...mainArray]);
  };

  const updateTotalAttachmentSizeArray = (
    updateTotalAttachmentSize: number
  ) => {
    const mainArray: any[] = [...props.totalAttachmentSizeArray];
    const updatedElement: number = updateTotalAttachmentSize;
    mainArray.splice(props.index, 1, updatedElement);
    props.setTotalAttachmentSize([...mainArray]);
  };

  const updateMainCampaignData = (newAttachments: any) => {
    const mainArray: any = [...props.mainCampaignDataArray];
    const objectToBeUpdated: any = {
      ...props.mainCampaignDataArray[props.index],
    };
    const updatedObject: any = {
      ...objectToBeUpdated,
      ["attachments"]: newAttachments,
    };
    mainArray.splice(props.index, 1, updatedObject);
    props.setMainCampaignDataArray([...mainArray]);
  };

  const checkDuplicateAttachments = (attachments: any) => {
    const existingAttachmentNames = new Set(attachmentNames);
    const duplicateFileNames = attachments
      .map((attachment: any) => attachment.file.name)
      .filter((name: any) => existingAttachmentNames.has(name));

    if (duplicateFileNames.length > 0) {
      openErrorNotification(
        `${duplicateFileNames.join(", ")} ${
          duplicateFileNames.length > 1 ? "are" : "is"
        } already uploaded`
      );
    }
  };

  const getFileRejectionErrorMessage = (
    rejectedAttachments: string[],
    individualOversizedAttachments: string[]
  ) => {
    let errorMessage: string = "";
    const rejectedIndividualFileNames: string =
      individualOversizedAttachments.join(", ");
    const rejectedFileNames: string = rejectedAttachments.join(", ");

    switch (true) {
      case rejectedAttachments.length > 0 &&
        individualOversizedAttachments.length > 0: {
        errorMessage = `${rejectedIndividualFileNames} individually exceed the 10 mb limit and ${rejectedFileNames} altogether exceeds the 20 mb limit for attachment upload size.`;
        break;
      }
      case rejectedAttachments.length > 0: {
        errorMessage = `${rejectedFileNames} exceed the 20 mb limit for attachment upload size.`;
        break;
      }
      case individualOversizedAttachments.length > 0: {
        errorMessage = `${individualOversizedAttachments} individually exceed the 10 mb limit for attachment upload size.`;
        break;
      }
    }
    return errorMessage;
  };

  const rejectedFilesHandler = (
    rejectedAttachments: string[],
    individualOversizedAttachments: string[]
  ) => {
    const errorMessage = getFileRejectionErrorMessage(
      rejectedAttachments,
      individualOversizedAttachments
    );
    if (isTruthy(errorMessage)) {
      openErrorNotification(errorMessage);
    }
  };

  return (
    <Box sx={classes.dropZoneWrapper} mt={2}>
      <DropzoneAreaBase
        fileObjects={[]}
        dropzoneText="Attach files here"
        onAdd={dropzoneUploadFile}
        maxFileSize={20000000} //20 MB into Bytes in MaxFileSize 20000000
        showPreviews={true}
        filesLimit={10}
        showAlerts={false}
        showPreviewsInDropzone={true}
        showFileNames={true}
        Icon={dropZoneDropZone}
        acceptedFiles={[
          strings.jpeg,
          strings.jpg,
          strings.png,
          strings.gif,
          strings.bmp,
          strings.tiff,
          strings.pdf,
          strings.doc,
          strings.docx,
          strings.txt,
          strings.xls,
          strings.xlsx,
        ]}
      />
      <Typography sx={classes.warningContent}>
        The files should be in .doc/.docx/.txt/.pdf/.png/.jpg/.jpeg/.gif/.xlsx/.xls and the
        size should not be more than 10 MB individually and 20 MB altogether.
      </Typography>
      <Box
        sx={{
          overflow: "auto",
          maxHeight: "80px",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {attachmentNames
          ?.filter(
            (item: any, index: any) => attachmentNames.indexOf(item) === index
          )
          ?.map((name: string) => {
            return (
              <Chip
                sx={classes.uploadFileStyle}
                label={name}
                variant="filled"
                onDelete={() => handleDeleteDropZoneFile(name)}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default React.memo(AttachmentDropzone);
