import { isTruthy } from "helpers/methods";

export const searchField = () => {
  return {
    templateName: {
      value: "",
      error: "",
    },
    category: {
      value: "",
    },
    isUserTemplate: {
      value: "",
    },
  };
};

export const SearchFieldValidation = (inputUser: any) => {
  const errors = inputUser;
  let isValid = true;
  const templateName = inputUser.templateName.value.trim();
  if (!isTruthy(templateName)) {
    errors.templateName.error = "Please enter template name";
    isValid = false;
  }
  if (templateName.length > 50) {
    errors.templateName.error =
      "Template name should be less than 50 characters";
    isValid = false;
  }
  return { isValid, errors };
};
