import { useEffect, useState } from "react";
import history from "utils/history";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { User } from "models/interfaces";
import { Box, Container } from "@mui/material";
import MailzzyLogo from "assets/icons/mailzzy-logo.png";
import { Elements } from "@stripe/react-stripe-js";
import OnboardingStyles from "screens/Onboarding/Onboard.styles";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import NewOnboardStep1 from "screens/Onboarding/components/NewOnboardStep1";
import {
  getUserDetails,
  getPlanFeatures,
  activateUser,
} from "screens/Onboarding/OnboardServices";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import notifiers from "global/constants/NotificationConstants";
import urls from "global/constants/UrlConstants";
import {
  initialCardUserDetails,
  onboardValidation,
} from "screens/Onboarding/OnboardData";
import strings from "global/constants/StringConstants";

const OnboardNew = () => {
  const classes = OnboardingStyles;
  const context = new URLSearchParams(useLocation().search).get("context");
  const stripePromise = loadStripe(urls.STRIPE_PUBLIC_KEY);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [userDetails, setUserDetails] = useState<any>();
  const [planFeatures, setPlanFeatures] = useState<any>();
  const [cardUserDetails, setCardUserDetails] = useState<any>();

  useEffect(() => {
    getUserAndPlan(context!);
  }, []);

  useEffect(() => {
    setCardUserDetails(
      initialCardUserDetails(userDetails?.name, userDetails?.email)
    );
  }, [userDetails]);

  const getUserAndPlan = async (token: string) => {
    try {
      setIsLoading(true);
      const userResponse: any = await getUserDetails(token);
      const planFeaturesResponse = await getPlanFeatures(
        userResponse.account,
        userResponse.authToken
      );
      setUserDetails(userResponse);
      setPlanFeatures(planFeaturesResponse);
      if (planFeaturesResponse.id === strings.FREE_PLAN_ID) {
        await finalUserActivation(false, planFeaturesResponse.id, userResponse);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
      );
      history.push(urls.loginViewPath);
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidation = () => {
    const { isValid, erroneousFormFields } = onboardValidation(cardUserDetails);
    setCardUserDetails({ ...erroneousFormFields });
    return isValid;
  };

  const displaySuccessNotification = (
    successResponse: string[],
    userActivationResponse: string,
    planId: string
  ) => {
    if (isTruthy(userActivationResponse)) {
      successResponse.push(
        planId === strings.FREE_PLAN_ID
          ? "Your account has been activated."
          : "You will be sent an email when your account is activated."
      );
    }
    openSuccessNotification(successResponse.join(" "));
  };

  const finalUserActivation = async (
    shouldDisplayError: boolean,
    inputPlanId: string,
    userDetailsResponse?: any,
    paymentId?: string
  ) => {
    let successResponse: string[] = [];
    try {
      setIsLoading(true);
      const userObject = userDetails ?? userDetailsResponse;
      const finalUserDetails: User = {
        pwd: userObject.pwd,
        authToken: userObject.authToken,
        name: userObject.name,
        contactNo: userObject.contactNo,
        email: userObject.email,
        role: "",
        resources: [],
        account: userObject.account,
        company: "",
        planId: inputPlanId,
        paymentMethodId: paymentId ?? "",
      };
      const userActivationResponse = await activateUser(
        finalUserDetails,
        userObject.authToken
      );
      displaySuccessNotification(
        successResponse,
        userActivationResponse,
        inputPlanId
      );
      setIsLoading(false);
      history.push(urls.loginViewPath);
    } catch (error: any) {
      setIsLoading(false);
      if (shouldDisplayError) {
        openErrorNotification(
          isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR
        );
      } else {
        throw error;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getContent = () => {
    switch (activeStep) {
      case 1:
        return (
          <Elements stripe={stripePromise}>
            <NewOnboardStep1
              planFeatures={planFeatures}
              cardUserDetails={cardUserDetails}
              setCardUserDetails={setCardUserDetails}
              userName={userDetails?.name}
              handleValidation={handleValidation}
              finalUserActivation={finalUserActivation}
              setIsLoading={setIsLoading}
            />
          </Elements>
        );
    }
  };

  return (
    <>
      <Box sx={classes.logoBox}>
        <img src={MailzzyLogo} />
      </Box>
      <Container maxWidth="lg">{getContent()}</Container>
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default OnboardNew;
