import {
  boldFont,
  getRelativeFontSize,
  regularFont,
  theme,
  mediumFont,
  lightPinkColor,
  textLightColor,
  blackFont,
  activeMenuColor,
  primaryHeadingColor,
  purplePrimaryColor,
  warningColor,
  buttonWhiteBg,
  inputLabelRequiredColor,
  pinkDarkColor,
  pinkThemedMenuItems,
  purpleThemedSelectComponent,
  centerItemFlex,
  primaryBlackColor,
  disabledFormControlColor,
  darkPurpledColor,
  disabledBackgroundColor,
  pureWhiteColor,
} from "utils/styles";

const socialStyles = {
  // create post page css
  createPostSideBar: {
    background: "#FFFFFF",
    height: "100vh",
    position: "sticky",
    top: "0",
    borderLeft: "2px solid #F5F5F5",
    [theme.breakpoints.down("lg")]: {
      display: "none",
      top: "300px",
    },
  },
  stepperResponsive: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
  dividerResponsive: {
    width: "50px",
    borderColor: "#828282",
    margin: "5px",
    [theme.breakpoints.down("lg")]: {
      width: "25px",
      margin: "0px",
    },
    [theme.breakpoints.down("md")]: {
      width: "0px",
      margin: "0px",
    },
  },
  buttonWhiteBg: {
    background: "none",
    border: "1px solid",
    borderColor: buttonWhiteBg,
    color: purplePrimaryColor,
    "&:hover": {
      background: "none",
    },
  },
  sideBarTop: {
    display: "flex",
    paddingTop: "40px",
    justifyContent: "center",
  },
  test: {
    background: "#FCF5FF",
  },
  normal: {
    background: "",
  },
  sidebarBtnGroup: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 10px",
  },

  sideBarTitle: {
    fontSize: "17px",
    ...boldFont,
    marginLeft: "5px",
    marginTop: "5px",
    cursor: "pointer",
  },

  sideBarMobileWrapper: {
    display: "none",
    width: "100%",
    background: "lightPinkColor",
    height: "100px",
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
    },
  },

  checkboxStyles: {
    "&.MuiCheckbox-colorPrimary": { color: pinkDarkColor },
    "&.Mui-disabled": { color: disabledFormControlColor },
  },

  star: {
    color: inputLabelRequiredColor,
    marginLeft: "5px",
  },

  input: {
    fontSize: getRelativeFontSize(7),
    color: primaryBlackColor,
    ...boldFont,
    marginTop: "10px",
    display: "flex",
    "& .star": {
      color: inputLabelRequiredColor,
    },
  },

  mobileCreatePostStyle: {
    width: "155px",
    background: "#EDC0FF",
    color: activeMenuColor,
    ...boldFont,
  },

  mainCardHeading: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    color: primaryHeadingColor,
  },

  socialWrapper: {
    padding: "5px 20px",
  },
  sideBarMobilBottom: {
    display: "flex",
    padding: "0 20px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
    },
  },
  sideBarMobileLeft: {
    display: "flex",
    gap: "15px",
  },

  createPstBtn: {
    borderRadius: "35%",
    background: purplePrimaryColor + " !important",
    width: "15px",
    height: "32px",
    fontSize: "10px",
    minWidth: "32px",
  },
  getCreatePostHeader: {
    height: "120px",
    background: lightPinkColor,
    position: "sticky",
    top: 0,
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around",
      paddingBottom: "80px",
    },

    [theme.breakpoints.down("lg")]: {
      height: "200px",
    },
  },

  customHeader: {
    width: "100%",
    zIndex: "1",
    top: "0",
    position: "sticky",
  },

  dropDownStyle: {
    backgroundColor: "#FFFFFF",
    height: "40px",
    width: " 250px",
    borderRadius: "12px",
    ...purpleThemedSelectComponent,
    ...mediumFont,
    "& img": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: " 200px",
    },
  },

  dropdownLogoStyle: {
    ...mediumFont,
    ...pinkThemedMenuItems,
    "& img": {
      width: "30px",
      height: "auto",
      marginRight: "10px",
      fontSize: "14px",
    },
  },

  mediumFonts: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
  },

  noPostsMainBox: {
    border: "1px solid #F0F0F0",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    margin: "20px ",
    padding: "20px",
  },

  headerContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "20px",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      height: "50%",
      alignItems: "center",
    },
  },

  headerTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(14),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },

  postContent: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "14px",
    },
  },

  postHeader: {},

  itemCard: {
    padding: "20px",
    margin: "20px 0",
  },

  logoStyle: {
    "& img": {
      width: "60px",
      height: "auto",
    },
  },

  cardRightTitle: {
    ...boldFont,
    fontSize: "20px",
  },

  cardRightTime: {
    color: "#666666",
    fontSize: "16px",
  },

  cardContent: {
    marginTop: "20px",
    fontSize: "16px",
  },

  cardTags: {
    color: "#000",
    LineHeight: "22.78px",
    whiteSpace: "pre-wrap",
  },

  postImg: {
    width: "100%",
    marginTop: "20px",
    "& img": {
      width: "100%",
      height: "auto",
    },
  },

  socialBtn: {
    marginTop: "20px",
    background: "#EAF3FF",
    borderRadius: "10px",
    height: "50px",
    gap: "5px",
    "& img": {
      width: "22px",
      height: "auto",
    },
  },

  // stepHeader css
  stepHeader: {
    padding: "40px 0",
    [theme.breakpoints.down("md")]: { padding: "60px 0 20px 0" },
  },

  backSpaceIcon: { paddingLeft: "15px" },

  stepperContainer: {
    justifyContent: "center",
    paddingRight: "150px",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "100px",
    },
  },
  // socialPost css

  socialPostWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh ",
    padding: "0 20px",
  },

  socialPostTitle: {
    fontSize: "38px",
    ...mediumFont,
    letterSpacing: "0em",
  },

  socialPostText: {
    color: "#666666",
    fontSize: "16px",
  },

  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    gap: "20px",
  },

  startButtonStyle: {
    width: "120px",
  },

  stepLabelStyle: {
    ...boldFont,
  },

  // network css
  networkWrapper: {
    padding: " 0 20px",
  },

  networkContentHeading: {
    padding: "20px",
  },

  networkSocialCardsContainer: {
    display: "flex",
    justifyContent: "center",
  },

  networkPageContentHeading: {
    display: "flex",
    justifyContent: "center",
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    margin: "5px 0",
  },

  networkPageContent: {
    display: "flex",
    justifyContent: "center",
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    margin: "5px 0",
  },

  cardWrapper: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      width: "80%",
    },
  },

  networkSocialCardGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 10px",
  },

  netWorkHeading: {
    textAlign: "center",
    fontSize: getRelativeFontSize(14),
    ...boldFont,
  },

  networkText: {
    textAlign: "center",
    fontSize: getRelativeFontSize(2),
    color: textLightColor,
    mediumFont,
  },

  checkBox: {
    ...blackFont,
    color: "#212121",
    fontSize: "5px",
  },

  socialCardContainer: {
    width: "380px",
    cursor: "pointer",
  },

  socialCard: {
    maxWidth: "380px",
    borderRadius: "10px",
    width: "100%",
    position: "relative",
    minHeight: "500px",
    [theme.breakpoints.down("md")]: {
      width: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  socialCardSelected: {
    outline: `1px solid ${darkPurpledColor}`,
  },

  socialCardClickable: {
    "&:hover": {
      boxShadow: "0px 10px 40px 5px #0000001F",
    },
  },

  disabledStackItem: {
    position: "absolute",
    height: "100%",
    borderRadius: "4px",
    top: 0,
    background: "rgba(255,255,255,0.8)",
    justifyContent: "center",
  },

  socialCardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-between",
    padding: "10px",
    "& img": {
      width: "50px",
      height: "auto",
      marginRight: "10px",
    },
  },

  socialCardHeaderLeft: {
    display: "flex",
    width: "100%",
  },

  profileName: {
    fontSize: getRelativeFontSize(2),
    marginTop: "10px",
  },

  postTime: {
    fontSize: "14px",
    color: "#666666",
  },

  socialCardContent: {
    padding: "10px",
  },

  publishPost: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    fontSize: "12px",
    color: "#666666",
    "& span": {
      ...boldFont,
      fontSize: "12px",
      color: "black",
    },
  },

  instagramProfileTitle: {
    ...boldFont,
    fontSize: "12px",
    color: primaryBlackColor,
    marginRight: "5px",
  },
  socialCardLayout: {
    "& img": {
      width: "100%",
      maxHeight: "350px",
      height: "350px",
    },
  },

  socialCardFooter: {
    padding: "0 10px",
  },

  footerLikes: {
    width: "100%",
  },

  userReactIcon: {
    "& img": {
      width: "15px",
      height: "auto",
    },
  },

  reactIcon: {},

  footerActions: {
    width: "100%",
    display: "flex",
    marginBottom: "10px",
    justifyContent: "space-between",
    height: "100%",
  },

  actionBtn: {
    width: "70px",
    height: "30px",
    borderRadius: "15px",
    background: "#F7F7F7",
    margin: " 10px 0 ",
    "& img": {
      width: "15px",
      height: "auto",
    },
  },

  instagramShareOptions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "3px",
  },

  instagramOptionWrapper: {
    display: "flex",
    width: "100%",
    gap: "10px",
  },

  instagramOption: {
    "& img": {
      width: "15px",
      height: "auto",
    },
  },

  instagramLikesCounts: {
    ...boldFont,
    fontSize: "14px",
  },

  lastPostTime: {
    fontFamily: "Source Sans 3",
    fontSize: "11px",
    display: "flex",
    color: "#666666",
    margin: "5px 0",
    "& span": {
      marginLeft: "20px",
      ...boldFont,
    },
  },

  linkedinFooterActions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 0 ",
  },

  linkedinActionBtn: {
    fontSize: "12px",
    color: "#666666",
    "& img": {
      width: "15px",
      height: "auto",
      marginRight: "5px",
    },
  },

  checkBoxWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  // design post css
  DesignPostHeader: { marginTop: "30px" },
  designLeft: {
    padding: "0 20px",
  },
  DesignPostTitle: {
    ...boldFont,
    fontSize: getRelativeFontSize(14),
  },

  formWrapper: {
    marginTop: "10px",
    width: "100%",
  },

  formInput: {
    width: "100%",
    marginTop: "20px",
  },

  warningContent: {
    color: warningColor,
    fontSize: getRelativeFontSize(),
    margin: "10px 0",
    ...mediumFont,
    paddingLeft: "10px",
  },

  label: {
    ...boldFont,
    marginBottom: "5px",
    display: "flex",
  },

  previewChip: {
    minWidth: 150,
    maxWidth: 150,
    marginTop: 1,
    marginLeft: 1,
    padding: "15px",
    display: "flex",
    justifyContent: "left",
  },

  selectStyle: {
    ...purpleThemedSelectComponent,
    width: "100%",
    height: "50px",
    borderRadius: "10px",
  },

  textAreaStyle: {
    width: "100%",
    background: "none",
    "&::placeholder": {
      color: "red",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important",
      borderColor: "rgba(0,0,0,0.2)",
      "&.Mui-focused fieldset": {
        borderColor: "#4B0150",
      },
    },
  },

  placeHolderStyle: {
    ...regularFont,
    color: "#CBCBCB",
  },

  layoutWrapper: {
    marginTop: "50px",
  },
  customButtonStyle: {
    width: "200px",
  },
  socialCardGridItem: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "30px",
    [theme.breakpoints.down("lg")]: {
      padding: "20px",
    },
  },

  miniCardsContainer: { marginTop: "30px", padding: "0 20px" },
  //  post css

  getPostWrapper: {
    width: "100%",
    height: "calc(100vh - 129px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  postCustomPaperStyle: {
    // border: "1px solid #E7E7E7",
    width: "760px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "40px",
    gap: "40px",
    textAlign: "center",
    marginBottom: "200px",
    boxShadow: "none",
    border: "none",
  },

  postBoldText: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
  },

  postButtonStyle: {
    width: "100%",
  },

  lightText: {
    color: textLightColor,
  },

  root: {
    backgroundColor: "#eaeaf0",
    padding: 8,
    borderRadius: "50%",
  },
  active: {
    color: "red",
  },
  completed: {
    color: "green",
  },
  errorStyle: {
    paddingLeft: "10px",
  },

  testAreaStyleError: {
    borderColor: warningColor,
    borderRadius: "12px",
    background: "none",
    padding: "10px",
    width: "95%",
  },
  dialogTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "10px",
    textAlign: "center",
  },
  titleLeft: {
    color: "#faad14",
  },
  titleRight: {
    color: " rgba(0,0,0,.85)",
    ...boldFont,
    fontSize: getRelativeFontSize(14),
  },
  dialogContent: {
    fontSize: getRelativeFontSize(2),
    ...mediumFont,

    "& span": {
      ...boldFont,
    },
  },
  networkStepSaveButtonBox: {
    display: "flex",
    justifyContent: "right",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  socialButtonStyle: {
    width: "145px",
  },
  dialogFooter: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "10px",
    "& button": {
      width: "120px",
    },
  },
  likeCommentBox: {
    color: "#65676B",
    fontSize: "17px",
    fontWeight: 600,
    fontFamily: "inherit",
  },
  iconButton: {
    backgroundColor: "transparent",
    borderRadius: "10%",
  },
  radioChecked: {
    "&.Mui-checked": {
      color: pinkDarkColor,
    },
  },
  scheduleModalTitle: {
    ...centerItemFlex,
    mt: 1,
  },
  scheduleBtn: {
    width: "170px !important",
  },
  dialogFooterWrapper: { ...centerItemFlex, paddingBottom: "20px" },
  cancelButtonStyle: {
    color: "#212121 !important",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E7E7E7",
    "&:hover": {
      background: "none",
    },
  },
  titleStyles: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
    textAlign: "center",
  },
  networkPageHeaderSection: {
    padding: "40px 20px 18px 20px",
  },
  networkPageTitle: {
    fontSize: getRelativeFontSize(10),
    ...boldFont,
    color: primaryHeadingColor,
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  headerGridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  backArrowKeyContainer: {
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
  },
  tooltipBackgroundContainer: {
    backgroundColor: disabledBackgroundColor,
  },
  tooltipText: {
    padding: "10px",
    color: pureWhiteColor,
    fontSize: "14px",
    ...regularFont,
  },
  backArrowKeyIcon: { cursor: "pointer" },
  headerContentGridItem: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  headerButtonStackStyles: {
    display: "flex",
    flexDirection: "row",
  },
  headerButtons: {
    width: "100%",
    margin: "0 5px",
    [theme.breakpoints.down("md")]: { marginTop: "20px" },
    [theme.breakpoints.down("sm")]: { marginTop: 0 },
  },
};

export default socialStyles;
